"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSubmissionVerifies = void 0;
const index_1 = require("api/index");
const submissionVerifies_1 = require("../store/submissionVerifies");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const queryString_1 = require("core/helpers/queryString");
const purchase_1 = require("../../../../common/helper/purchase");
const helper_1 = require("../../../../common/helper");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useSubmissionVerifies = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, submissionVerifies_1.useSubmissionVerifiesReducer)();
    const [showFilter, setShowFilter] = (0, react_1.useState)(false);
    const [linkParams, setLinkParams] = (0, react_1.useState)('');
    const [verifySubmissionId, setVerifySubmissionId] = (0, react_1.useState)(null);
    const isMounted = (0, react_1.useRef)(false);
    const navigatePagination = (page, filterValues) => {
        getSubmissionVerifies({ ...filterValues, page }).then();
    };
    const selectPerPage = (perPage, filterValues) => {
        dispatch({ type: 'CHANGE_PER_PAGE', payload: perPage });
        getSubmissionVerifies({ ...filterValues, perPage, page: 1 }).then();
    };
    const filter = (name, value, filterValues) => {
        getSubmissionVerifies({ ...filterValues, [name]: value, page: 1 }).then();
    };
    const debouncedSearch = (name, value, filterValues) => {
        (0, debouncedMethods_1.debouncedOnFunc)({ ...filterValues, [name]: value, page: 1 }, getSubmissionVerifies);
    };
    const getSubmissionVerifies = async (values) => {
        dispatch({ type: 'SEND_SUBMISSION_VERIFIES_REQUEST' });
        const filterParams = (0, helpers_1.transformVerifySubmissionParamsToFilterParams)(values);
        const submissionVerifies = await index_1.SubmissionVerifiesApi.getSubmissionVerifies(filterParams);
        if (submissionVerifies.data !== null)
            setLinkParams((0, queryString_1.stringify)((0, helpers_1.transformVerifyFilterParamsToUrl)(values)));
        if (isMounted?.current) {
            dispatch({ type: 'DONE_SUBMISSION_VERIFIES_REQUEST', payload: submissionVerifies });
            navigate('/documents/purchases/submission-verifies' + (0, queryString_1.stringify)((0, helpers_1.transformVerifyFilterParamsToUrl)(values)), { replace: true });
        }
    };
    const verifySubmissionStatusOptions = (0, react_1.useMemo)(() => (0, helper_1.transformObjectForSelect)(purchase_1.submissionVerifyStatus), []);
    const submissionVerifyItemStatusOptions = (0, react_1.useMemo)(() => (0, helper_1.transformObjectForSelect)(purchase_1.submissionVerifyItemStatus), []);
    const initializeFilterForm = async () => {
        const params = (0, helpers_1.transformVerifyUrlToFilterParams)(location.search);
        if (params.status || params.keyword || params.itemPurchaseStatus || params.itemBudgetingStatus
            || params.submissionCreatedBy || params.userIdForProjects || params.budgetArticleId || params.toSumPrice
            || params.fromSumPrice || params.budgetingStatusUpdatedBy || params.subjectName || params.itemStatus)
            setShowFilter(true);
        const paginatedStorage = JSON.parse(localStorage.getItem('paginated') || '""');
        const storagePerPage = paginatedStorage?.procurementListing?.perPage;
        params.perPage = params.perPage || Number(storagePerPage) || 25;
        const verifySubmissionListId = (0, queryString_1.parse)(location.search).verifySubmissionId;
        getSubmissionVerifies(params).then(() => dispatch({ type: 'INITIALIZE_FILTER_FORM', payload: params }));
        setVerifySubmissionId(verifySubmissionListId);
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        initializeFilterForm().then();
        return () => { isMounted.current = false; };
    }, []);
    return {
        state,
        filter,
        showFilter,
        setShowFilter,
        debouncedSearch,
        submissionVerifyItemStatusOptions,
        verifySubmissionStatusOptions,
        selectPerPage,
        navigatePagination,
        getSubmissionVerifies,
        linkParams,
        verifySubmissionId
    };
};
exports.useSubmissionVerifies = useSubmissionVerifies;
