"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useConditionalItemsReducer = exports.initialConditionalSubmissionItemsStore = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
exports.initialConditionalSubmissionItemsStore = {
    data: [],
    meta: store_1.initialMeta,
    errors: null,
    isFetching: true
};
const useConditionalItemsReducer = () => {
    return (0, react_1.useReducer)(conditionalItemsReducer, exports.initialConditionalSubmissionItemsStore);
};
exports.useConditionalItemsReducer = useConditionalItemsReducer;
const conditionalItemsReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_CONDITIONAL_ITEMS_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_CONDITIONAL_ITEMS_REQUEST':
            return {
                ...state,
                errors: action.payload.errors,
                data: action.payload.data || [],
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
