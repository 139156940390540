"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useContractComments = void 0;
const comment_v2_1 = require("src/components/comments/store/comment_v2");
const index_1 = require("api/index");
const react_1 = require("react");
const useContractComments = (props) => {
    const [state, dispatch] = (0, comment_v2_1.useCommentsReducer)();
    const getComments = async () => {
        dispatch({ type: 'SEND_COMMENT_REQUEST' });
        const comments = await index_1.CommonApi.getPublicContractComments(props.hash);
        dispatch({ type: 'DONE_COMMENT_REQUEST', payload: comments });
    };
    const addComment = async (comment) => {
        dispatch({ type: 'SEND_ADD_COMMENT' });
        const responseComment = await index_1.CommonApi.addPublicContractComments(props.hash, { comment });
        dispatch({ type: 'DONE_ADD_COMMENT', payload: responseComment });
        return responseComment;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => {
        props.contractId && getComments().then();
    }, []);
    return {
        addComment,
        state,
        closeErrorMessage
    };
};
exports.useContractComments = useContractComments;
