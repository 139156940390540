"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.conditionalSubmissionReducer = exports.useConditionalSubmissionReducer = exports.initialConditionalSubmissionNavigate = exports.initialConditionalSubmissionState = exports.initialConditionalSubmissionItemValue = void 0;
const types_1 = require("api/documents/purchases/conditional-submissions/types");
const react_1 = require("react");
const initialConditionalSubmissionValue = {
    edocNumber: null,
    requestRegistrationDate: null,
    requestRegistrationDateTime: null,
    itemsCount: 0,
    usedItemsCount: 0,
    number: '',
    author: null,
    structuralUnit: null,
    termDate: null,
    contactPerson: null,
    email: null,
    phoneNumber: null,
    sentDate: null,
    createdAt: '',
    createdDateTimeAt: '',
    creator: null,
    status: types_1.ConditionalSubmissionsStatuses.DRAFT,
    subStatus: types_1.ConditionalSubmissionSubStatuses.UNFULFILLED,
    disabledStatus: false,
    purpose: null,
    files: [],
    additionalFiles: [],
    declineComment: null,
    suspendComment: null,
    visualStatus: { name: 'პროექტი', color: 'secondary' },
    visualSubStatus: { name: 'შეუსრულებელი', color: 'secondary' },
    sentBy: null,
    visualSentBy: null,
    hasDeterminations: false,
    isOriginal: true,
    updatedAt: null, //TODO @Merab todo should be removed after Tornike migrate database
    forceMajeureComment: null
};
exports.initialConditionalSubmissionItemValue = {
    id: null,
    purchaseSubject: {
        subjectName: '',
        specificationName: '',
        categoryName: '',
        verified: true
    },
    bookAuthor: null,
    bookTitle: null,
    bookIsbnCode: null,
    bookLink: null,
    inventoryConfigurationType: null,
    shortCharacteristics: null,
    shortAssignment: null,
    dimensionUnit: null,
    quantity: null,
    project: null,
    characteristics: '',
    assignment: '',
    inventoryConfigurationId: null,
    submission: null,
    sort: 1,
    checked: false
};
exports.initialConditionalSubmissionState = {
    determinations: [],
    currentVersion: initialConditionalSubmissionValue,
    original: null,
    status: null,
    errors: null,
    determinationErrors: null,
    isFetching: false,
    isLoading: false,
    isCreating: false,
    isUpdating: false,
    isSending: false,
    isCreatingSubmission: false,
    isDeclining: false,
    isSuspending: false,
    isFetchingDeterminations: false
};
exports.initialConditionalSubmissionNavigate = {
    next: false,
    previous: false,
    isLoading: false,
    lastElement: false,
    firstElement: false
};
const useConditionalSubmissionReducer = () => {
    return (0, react_1.useReducer)(exports.conditionalSubmissionReducer, exports.initialConditionalSubmissionState);
};
exports.useConditionalSubmissionReducer = useConditionalSubmissionReducer;
const conditionalSubmissionReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_CONDITIONAL_SUBMISSION_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_CONDITIONAL_SUBMISSION_REQUEST':
            return {
                ...state,
                currentVersion: action.payload.data || state.currentVersion,
                original: action.payload.data || state.original,
                status: action.payload.status,
                errors: action.payload.errors,
                isFetching: false,
                isLoading: state.isCreating
                    || state.isUpdating
                    || state.isSending
                    || state.isCreatingSubmission
                    || state.isDeclining
                    || state.isSuspending
            };
        case 'SEND_CONDITIONAL_SUBMISSION_CREATE':
            return {
                ...state,
                errors: null,
                isCreating: true,
                isLoading: true
            };
        case 'DONE_CONDITIONAL_SUBMISSION_CREATE':
            return {
                ...state,
                currentVersion: action.payload.data || state.currentVersion,
                original: action.payload.data || state.original,
                status: action.payload.status,
                errors: action.payload.errors,
                isCreating: false,
                isLoading: state.isFetching
                    || state.isUpdating
                    || state.isSending
                    || state.isCreatingSubmission
                    || state.isDeclining
                    || state.isSuspending
            };
        case 'SEND_CONDITIONAL_SUBMISSION_UPDATE':
            return {
                ...state,
                errors: null,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_CONDITIONAL_SUBMISSION_UPDATE':
            return {
                ...state,
                currentVersion: action.payload.data || state.currentVersion,
                original: action.payload.data || state.original,
                errors: action.payload.errors,
                status: action.payload.status,
                isUpdating: false,
                isLoading: state.isCreating
                    || state.isFetching
                    || state.isSending
                    || state.isCreatingSubmission
                    || state.isDeclining
                    || state.isSuspending
            };
        case 'SEND_CONDITIONAL_SUBMISSION_SEND_REQUEST':
            return {
                ...state,
                isSending: true,
                isLoading: true
            };
        case 'DONE_CONDITIONAL_SUBMISSION_SEND_REQUEST':
            return {
                ...state,
                currentVersion: action.payload.data || state.currentVersion,
                original: action.payload.data || state.original,
                errors: action.payload.errors,
                status: action.payload.status,
                isSending: false,
                isLoading: state.isCreating
                    || state.isFetching
                    || state.isUpdating
                    || state.isCreatingSubmission
                    || state.isDeclining
                    || state.isSuspending
            };
        case 'SEND_CONDITIONAL_SUBMISSION_DECLINE_REQUEST':
            return {
                ...state,
                errors: null,
                isDeclining: true,
                isLoading: true
            };
        case 'DONE_CONDITIONAL_SUBMISSION_DECLINE_REQUEST':
            return {
                ...state,
                currentVersion: action.payload.data || state.currentVersion,
                original: action.payload.data || state.original,
                status: action.payload.status,
                errors: action.payload.errors,
                isDeclining: false,
                isLoading: state.isCreating
                    || state.isUpdating
                    || state.isSending
                    || state.isCreatingSubmission
                    || state.isFetching
                    || state.isSuspending
            };
        case 'SEND_CONDITIONAL_SUBMISSION_SUSPEND_REQUEST':
            return {
                ...state,
                errors: null,
                isSuspending: true,
                isLoading: true
            };
        case 'DONE_CONDITIONAL_SUBMISSION_SUSPEND_REQUEST':
            return {
                ...state,
                currentVersion: action.payload.data || state.currentVersion,
                original: action.payload.data || state.original,
                status: action.payload.status,
                errors: action.payload.errors,
                isSuspending: false,
                isLoading: state.isCreating
                    || state.isUpdating
                    || state.isSending
                    || state.isCreatingSubmission
                    || state.isFetching
                    || state.isDeclining
            };
        case 'SEND_PROGRESS_IN_PURCHASE_REQUEST':
            return {
                ...state,
                isCreatingSubmission: true,
                isLoading: true
            };
        case 'DONE_PROGRESS_IN_PURCHASE_REQUEST':
            return {
                ...state,
                currentVersion: action.payload.data || state.currentVersion,
                status: action.payload.status,
                errors: action.payload.errors,
                isCreatingSubmission: false,
                isLoading: state.isCreating
                    || state.isFetching
                    || state.isUpdating
                    || state.isSending
                    || state.isSuspending
                    || state.isDeclining
            };
        case 'SEND_DETERMINATION_UPDATE':
            return {
                ...state,
                errors: null,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_DETERMINATION_UPDATE':
            return {
                ...state,
                currentVersion: action.payload.data || state.currentVersion,
                errors: action.payload.errors,
                status: action.payload.status,
                isUpdating: false,
                isLoading: state.isCreating
                    || state.isFetching
                    || state.isSending
                    || state.isCreatingSubmission
                    || state.isDeclining
                    || state.isSuspending
            };
        case 'SEND_DETERMINATION_SEND_REQUEST':
            return {
                ...state,
                isSending: true,
                isLoading: true
            };
        case 'DONE_DETERMINATION_SEND_REQUEST':
            return {
                ...state,
                currentVersion: action.payload.data || state.currentVersion,
                errors: action.payload.errors,
                status: action.payload.status,
                isSending: false,
                isLoading: state.isCreating
                    || state.isFetching
                    || state.isUpdating
                    || state.isCreatingSubmission
                    || state.isDeclining
                    || state.isSuspending
            };
        case 'SEND_DETERMINATION_SUSPEND_REQUEST':
            return {
                ...state,
                errors: null,
                isSuspending: true,
                isLoading: true
            };
        case 'DONE_DETERMINATION_SUSPEND_REQUEST':
            return {
                ...state,
                currentVersion: action.payload.data || state.currentVersion,
                status: action.payload.status,
                errors: action.payload.errors,
                isSuspending: false,
                isLoading: state.isCreating
                    || state.isUpdating
                    || state.isSending
                    || state.isCreatingSubmission
                    || state.isFetching
                    || state.isDeclining
            };
        case 'SEND_DETERMINATION_DECLINE_REQUEST':
            return {
                ...state,
                errors: null,
                isDeclining: true,
                isLoading: true
            };
        case 'DONE_DETERMINATION_DECLINE_REQUEST':
            return {
                ...state,
                currentVersion: action.payload.data || state.currentVersion,
                status: action.payload.status,
                errors: action.payload.errors,
                isDeclining: false,
                isLoading: state.isCreating
                    || state.isUpdating
                    || state.isSending
                    || state.isCreatingSubmission
                    || state.isFetching
                    || state.isSuspending
            };
        case 'SEND_DETERMINATION_PROGRESS_IN_PURCHASE_REQUEST':
            return {
                ...state,
                isCreatingSubmission: true,
                isLoading: true
            };
        case 'DONE_DETERMINATION_PROGRESS_IN_PURCHASE_REQUEST':
            return {
                ...state,
                currentVersion: action.payload.data || state.currentVersion,
                status: action.payload.status,
                errors: action.payload.errors,
                isCreatingSubmission: false,
                isLoading: state.isCreating
                    || state.isFetching
                    || state.isUpdating
                    || state.isSending
                    || state.isSuspending
                    || state.isDeclining
            };
        case 'SEND_DETERMINATIONS_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_DETERMINATIONS_REQUEST':
            return {
                ...state,
                determinationErrors: action.payload.errors,
                determinations: action.payload.data || [],
                isFetching: false
            };
        case 'LOAD_ORIGINAL_VERSION':
            return {
                ...state,
                currentVersion: state.original || state.currentVersion
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.conditionalSubmissionReducer = conditionalSubmissionReducer;
