"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const api_1 = require("@react-google-maps/api");
const react_1 = require("react");
const GoogleMaps = (props) => {
    const [markerPosition, setMarkerPosition] = (0, react_1.useState)();
    const isMounted = (0, react_1.useRef)(false);
    const center = {
        lat: 41.72,
        lng: 44.79
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        return () => { isMounted.current = false; };
    }, []);
    (0, react_1.useEffect)(() => {
        if (props.marked)
            setMarkerPosition(props.marked);
    }, [props.marked]);
    const handleMapClick = (event) => {
        const { latLng } = event;
        if (latLng) {
            const lat = latLng.lat();
            const lng = latLng.lng();
            props.getCoordinates(lat, lng);
            setMarkerPosition({ lat, lng });
        }
    };
    if (!isMounted.current)
        return null;
    return ((0, jsx_runtime_1.jsx)(api_1.LoadScript, { googleMapsApiKey: process.env.GOOGLE_MAP_API_KEY || '', children: (0, jsx_runtime_1.jsx)(api_1.GoogleMap, { mapContainerStyle: { width: '100%', height: 400 }, center: center, zoom: 10, onClick: handleMapClick, children: markerPosition && (0, jsx_runtime_1.jsx)(api_1.Marker, { position: markerPosition }) }) }));
};
exports.default = GoogleMaps;
