"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStructuralUnitPositionsAutocomplete = exports.getStructuralUnitsForSelect = exports.getAncestors = exports.attachOnlyStructuralUnit = exports.detachStructuralUnit = exports.getAttachedStructuralUnits = exports.getStructuralUnitsForAttaching = exports.detachEmployee = exports.attachEmployee = exports.getAttachedEmployees = exports.getHrEmployee = exports.updateFundingSources = exports.getFundingSources = exports.updateOrders = exports.getOrders = exports.createPositions = exports.createStructuralUnit = exports.updatePosition = exports.updateStructuralUnit = exports.sortStructuralUnits = exports.getStructuralUnitForSelect = exports.deleteStructuralUnit = exports.getStructuralUnit = exports.getStructuralUnits = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/references/structuralUnitsUpgraded/parsers");
const react_toastify_1 = require("react-toastify");
const getStructuralUnits = async (params) => {
    const response = await Api.get('references/structural-units', params);
    return (0, parsers_1.parseStructuralUnitsTree)(response);
};
exports.getStructuralUnits = getStructuralUnits;
const getStructuralUnit = async (id) => {
    const response = await Api.get('references/structural-units/' + id);
    return (0, parsers_1.parseStructuralUnit)(response);
};
exports.getStructuralUnit = getStructuralUnit;
const deleteStructuralUnit = async (unitId) => {
    return await Api.deleteItem('references/structural-units/' + unitId);
};
exports.deleteStructuralUnit = deleteStructuralUnit;
const getStructuralUnitForSelect = async (id) => {
    const response = await Api.get('references/structural-units/' + id);
    return (0, parsers_1.parseStructuralUnitForSelect)(response);
};
exports.getStructuralUnitForSelect = getStructuralUnitForSelect;
const sortStructuralUnits = async (params) => {
    return await Api.patch('references/structural-units/sort', params);
};
exports.sortStructuralUnits = sortStructuralUnits;
const updateStructuralUnit = async (id, params) => {
    const response = await Api.patch('references/structural-units/' + id, params);
    return (0, parsers_1.parseStructuralUnit)(response);
};
exports.updateStructuralUnit = updateStructuralUnit;
const updatePosition = async (id, params) => {
    const response = await Api.patch(`references/structural-units/${id}/position`, params);
    return (0, parsers_1.parseStructuralUnit)(response);
};
exports.updatePosition = updatePosition;
const createStructuralUnit = async (params) => {
    const response = await Api.post('references/structural-units', params);
    return (0, parsers_1.parseStructuralUnit)(response);
};
exports.createStructuralUnit = createStructuralUnit;
const createPositions = async (params) => {
    const response = await Api.post('references/structural-units/position-batch', params);
    return (0, parsers_1.parsePositions)(response);
};
exports.createPositions = createPositions;
const getOrders = async (structuralUnitId) => {
    const response = await Api.get(`references/structural-units/administrative-orders/${structuralUnitId}`);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, parsers_1.parseOrders)(response);
};
exports.getOrders = getOrders;
const updateOrders = async (structuralUnitId, params) => {
    const response = await Api.patch(`references/structural-units/administrative-orders/${structuralUnitId}`, params);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, parsers_1.parseOrders)(response);
};
exports.updateOrders = updateOrders;
const getFundingSources = async (structuralUnitId) => {
    const response = await Api.get(`references/structural-units/${structuralUnitId}/projects`);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, parsers_1.parseFundingSources)(response);
};
exports.getFundingSources = getFundingSources;
const updateFundingSources = async (structuralUnitId, params) => {
    const response = await Api.patch(`references/structural-units/${structuralUnitId}/projects`, params);
    return (0, parsers_1.parseFundingSources)(response);
};
exports.updateFundingSources = updateFundingSources;
const getHrEmployee = async (params) => {
    const response = await Api.get('references/employees/employees-by-structural-unit-abbr', params);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, parsers_1.parseHrEmployeeForStructuralUnit)(response);
};
exports.getHrEmployee = getHrEmployee;
const getAttachedEmployees = async (structuralUnitId) => {
    const response = await Api.get(`references/structural-units/${structuralUnitId}/attach-accounts`);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, parsers_1.parseAttachedEmployees)(response);
};
exports.getAttachedEmployees = getAttachedEmployees;
const attachEmployee = async (structuralUnitId, params) => {
    const response = await Api.patch(`references/structural-units/${structuralUnitId}/attach-accounts`, params);
    return (0, parsers_1.parseAttachedEmployees)(response);
};
exports.attachEmployee = attachEmployee;
const detachEmployee = async (structuralUnitId, params) => {
    const response = await Api.patch(`references/structural-units/${structuralUnitId}/detach-accounts`, params);
    return { data: null, errors: response.errors, status: response.status };
};
exports.detachEmployee = detachEmployee;
const getStructuralUnitsForAttaching = async (params) => {
    const response = await Api.get('references/structural-units', params);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, parsers_1.parseStructuralUnitsToAttach)(response);
};
exports.getStructuralUnitsForAttaching = getStructuralUnitsForAttaching;
const getAttachedStructuralUnits = async (params) => {
    const response = await Api.get('references/structural-units', params);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, parsers_1.parseAttachedStructuralUnits)(response);
};
exports.getAttachedStructuralUnits = getAttachedStructuralUnits;
const detachStructuralUnit = async (structuralUnitId, params) => {
    return await Api.patch(`references/structural-units/${structuralUnitId}/detach-accounts`, params);
};
exports.detachStructuralUnit = detachStructuralUnit;
const attachOnlyStructuralUnit = async (structuralUnitId, params) => {
    return await Api.patch(`references/structural-units/${structuralUnitId}/attach-accounts`, params);
};
exports.attachOnlyStructuralUnit = attachOnlyStructuralUnit;
const getAncestors = async (id) => {
    const response = await Api.get('references/structural-units/ancestors/' + id);
    return (0, parsers_1.parseAncestors)(response);
};
exports.getAncestors = getAncestors;
const getStructuralUnitsForSelect = async (params) => {
    const response = await Api.get('references/structural-units', params);
    return (0, parsers_1.parseStructuralUnitsForSelect)(response);
};
exports.getStructuralUnitsForSelect = getStructuralUnitsForSelect;
const getStructuralUnitPositionsAutocomplete = async (params) => {
    const response = await Api.get('references/structural-units/positions', params);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, parsers_1.parseStructuralUnitPositions)(response);
};
exports.getStructuralUnitPositionsAutocomplete = getStructuralUnitPositionsAutocomplete;
