"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const ProcurementItemsStatisticCategory = (props) => {
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: props.categories.length === 0
            ? ((0, jsx_runtime_1.jsx)("div", { className: 'alert alert-warning text-center', children: "\u10E9\u10D0\u10DC\u10D0\u10EC\u10D4\u10E0\u10D8 \u10D5\u10D4\u10E0 \u10DB\u10DD\u10D8\u10EB\u10D4\u10D1\u10DC\u10D0" }))
            : ((0, jsx_runtime_1.jsxs)("table", { className: 'table', children: [(0, jsx_runtime_1.jsx)("thead", { children: (0, jsx_runtime_1.jsxs)("tr", { children: [(0, jsx_runtime_1.jsx)("th", { scope: 'col', children: "#" }), (0, jsx_runtime_1.jsx)("th", { scope: 'col', children: "\u10D9\u10D0\u10E2\u10D4\u10D2\u10DD\u10E0\u10D8\u10D0" }), (0, jsx_runtime_1.jsx)("th", { scope: 'col', children: "\u10E0\u10D0\u10DD\u10D3\u10D4\u10DC\u10DD\u10D1\u10D0 #" }), (0, jsx_runtime_1.jsx)("th", { scope: 'col', children: "\u10E4\u10D0\u10E1\u10D8" })] }) }), (0, jsx_runtime_1.jsx)("tbody", { children: props.categories.map((el, index) => {
                            return ((0, jsx_runtime_1.jsxs)("tr", { children: [(0, jsx_runtime_1.jsx)("th", { scope: 'row', children: index + 1 }), (0, jsx_runtime_1.jsx)("td", { children: el.categoryName }), (0, jsx_runtime_1.jsx)("td", { children: el.itemCount }), (0, jsx_runtime_1.jsx)("td", { children: el.totalPrice.toFixed(2).valueOf() })] }, el.id));
                        }) })] })) }));
};
exports.default = ProcurementItemsStatisticCategory;
