"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const userSettings_1 = require("../store/userSettings");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const useUserSettings = () => {
    const [state, dispatch] = (0, userSettings_1.useUserSettingsReducer)();
    const getUserSettings = async () => {
        dispatch({ type: 'SEND_USER_SETTINGS_REQUEST' });
        const userSettings = await index_1.NotificationsApi.getUserSettings();
        dispatch({ type: 'DONE_USER_SETTINGS_REQUEST', payload: userSettings });
    };
    const updateUserSettings = async (values) => {
        const params = (0, helpers_1.transformUserSettingsParams)(values);
        dispatch({ type: 'SEND_USER_SETTINGS_UPDATE' });
        const userSettings = await index_1.NotificationsApi.updateUserSettings(params);
        dispatch({ type: 'DONE_USER_SETTINGS_UPDATE', payload: userSettings });
        return userSettings;
    };
    (0, react_1.useEffect)(() => {
        getUserSettings().then();
    }, []);
    return { state, updateUserSettings, getUserSettings };
};
exports.default = useUserSettings;
