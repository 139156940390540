"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const news_1 = require("../store/news");
const index_1 = require("api/index");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useNews = (props) => {
    const [state, dispatch] = (0, news_1.useNewsReducer)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const getNews = async (id) => {
        dispatch({ type: 'SEND_NEWS_REQUEST' });
        const news = await index_1.NewsesApi.getNews(id);
        dispatch({ type: 'DONE_NEWS_REQUEST', payload: news });
    };
    const createNews = async (values) => {
        const params = (0, helpers_1.transformNewsParams)(values);
        dispatch({ type: 'SEND_NEWS_CREATE' });
        const news = await index_1.NewsesApi.createNews(params);
        dispatch({ type: 'DONE_NEWS_CREATE', payload: news });
        if (news.data !== null) {
            navigate(`/administer/news/${news.data.id}/edit`);
        }
        return news;
    };
    const updateNews = async (id, values) => {
        const params = (0, helpers_1.transformNewsParams)(values);
        dispatch({ type: 'SEND_NEWS_UPDATE' });
        const news = await index_1.NewsesApi.updateNews(id, params);
        dispatch({ type: 'DONE_NEWS_UPDATE', payload: news });
        return news;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getNews(props.id).then(); }, [props.id]);
    return {
        getNews,
        state,
        createNews,
        updateNews,
        closeErrorMessage
    };
};
exports.default = useNews;
