"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useCompanySidebar = () => {
    const [openedCompanySidebar, setOpenedCompanySidebar] = (0, react_1.useState)(false);
    const setCompanySideBar = (isOpen) => {
        setOpenedCompanySidebar(() => {
            if (isOpen)
                window.addEventListener('click', closeInvoiceCompanySideBar, true);
            return isOpen;
        });
    };
    const closeInvoiceCompanySideBar = (event) => {
        const isCompany = document.querySelector('#company-menu')?.contains(event.target);
        const isCompanySidebarAlert = document.querySelector('#companySideBarAlert')?.contains(event.target);
        if (isCompany || isCompanySidebarAlert)
            return;
        window.removeEventListener('click', closeInvoiceCompanySideBar, true);
        setOpenedCompanySidebar(false);
    };
    return {
        setCompanySideBar,
        openedCompanySidebar
    };
};
exports.default = useCompanySidebar;
