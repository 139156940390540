"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPositionSubTypesForSelect = exports.updatePositionSubType = exports.createPositionSubType = exports.getPositionSubType = exports.deletePositionSubTypes = exports.getPositionSubTypes = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/references/humanResources/positionSubTypes/parsers");
const getPositionSubTypes = async (params) => {
    const response = await Api.get('references/human-resources/position-sub-types', params);
    return (0, parsers_1.parsePositionSubTypes)(response);
};
exports.getPositionSubTypes = getPositionSubTypes;
const deletePositionSubTypes = async (id) => {
    return await Api.deleteItem('references/human-resources/position-sub-types/' + id);
};
exports.deletePositionSubTypes = deletePositionSubTypes;
const getPositionSubType = async (id) => {
    const response = await Api.get('references/human-resources/position-sub-types/' + id);
    return (0, parsers_1.parsePositionSubType)(response);
};
exports.getPositionSubType = getPositionSubType;
const createPositionSubType = async (params) => {
    const response = await Api.post('references/human-resources/position-sub-types', params);
    return (0, parsers_1.parsePositionSubType)(response);
};
exports.createPositionSubType = createPositionSubType;
const updatePositionSubType = async (id, params) => {
    const response = await Api.patch('references/human-resources/position-sub-types/' + id, params);
    return (0, parsers_1.parsePositionSubType)(response);
};
exports.updatePositionSubType = updatePositionSubType;
const getPositionSubTypesForSelect = async (params) => {
    const response = await Api.get('references/human-resources/position-sub-types', params);
    return (0, parsers_1.parsePositionSubTypesForSelect)(response);
};
exports.getPositionSubTypesForSelect = getPositionSubTypesForSelect;
