"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allowedZoomExtensions = exports.imageExtensions = exports.fileExtensions = void 0;
exports.fileExtensions = [
    { extension: 'doc', className: 'word text-primary', allowDownload: true },
    { extension: 'docx', className: 'word text-primary', allowDownload: true },
    { extension: 'xls', className: 'excel text-success', allowDownload: true },
    { extension: 'xlsx', className: 'excel text-success', allowDownload: true },
    { extension: 'ppt', className: 'powerpoint text-warning', allowDownload: true },
    { extension: 'pptx', className: 'powerpoint text-warning', allowDownload: true },
    { extension: 'pdf', className: 'pdf text-danger', allowDownload: false },
    { extension: 'bmp', className: 'image text-primary', allowDownload: false },
    { extension: 'webp', className: 'image text-primary', allowDownload: false },
    { extension: 'jpeg', className: 'image text-primary', allowDownload: false },
    { extension: 'jpg', className: 'image text-primary', allowDownload: false },
    { extension: 'png', className: 'image text-primary', allowDownload: false }
];
exports.imageExtensions = ['png', 'jpg', 'jpeg']; // 'gif'
exports.allowedZoomExtensions = ['png', 'jpg', 'jpeg', 'pdf']; // 'gif'
