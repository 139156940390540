"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const PositionTypeConfig_1 = require("../store/PositionTypeConfig");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const usePositionTypeConfig = (props) => {
    const [state, dispatch] = (0, PositionTypeConfig_1.usePositionTypeConfigReducer)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const getPositionTypeConfig = async (id) => {
        dispatch({ type: 'SEND_POSITION_TYPE_CONFIG_REQUEST' });
        const positionTypeConfig = await index_1.PositionTypeConfigApi.getPositionTypeConfig(id);
        dispatch({ type: 'DONE_POSITION_TYPE_CONFIG_REQUEST', payload: positionTypeConfig });
    };
    const createPositionTypeConfig = async (values) => {
        const params = (0, helpers_1.transformPositionTypeConfigParams)(values);
        dispatch({ type: 'SEND_POSITION_TYPE_CONFIG_CREATE' });
        const positionTypeConfig = await index_1.PositionTypeConfigApi.createPositionTypeConfig(params);
        dispatch({ type: 'DONE_POSITION_TYPE_CONFIG_REQUEST', payload: positionTypeConfig });
        if (positionTypeConfig.data !== null) {
            navigate(`/references/human-resources/position-type-configs/${positionTypeConfig.data.id}/edit`);
        }
        return positionTypeConfig;
    };
    const updatePositionTypeConfig = async (id, values) => {
        const params = (0, helpers_1.transformPositionTypeConfigParams)(values);
        dispatch({ type: 'SEND_POSITION_TYPE_CONFIG_UPDATE' });
        const positionTypeConfig = await index_1.PositionTypeConfigApi.updatePositionTypeConfig(id, params);
        dispatch({ type: 'DONE_POSITION_TYPE_CONFIG_UPDATE', payload: positionTypeConfig });
        return positionTypeConfig;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getPositionTypeConfig(props.id).then(); }, [props.id]);
    return { state, getPositionTypeConfig, createPositionTypeConfig, updatePositionTypeConfig, closeErrorMessage };
};
exports.default = usePositionTypeConfig;
