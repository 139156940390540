"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSubmissionItemsForm = void 0;
const index_1 = require("api/index");
const react_1 = require("react");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const submissionFormItems_1 = require("../store/submissionFormItems");
const helpers_1 = require("core/helpers");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helpers_2 = require("../helpers");
const queryString_1 = require("core/helpers/queryString");
const react_router_dom_1 = require("react-router-dom");
const useSubmissionItemsForm = (props) => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [keyword, setKeyword] = (0, react_1.useState)('');
    const [dimensionUnits, setDimensionUnits] = (0, react_1.useState)([]);
    const [currencies, setCurrencies] = (0, react_1.useState)([]);
    const [isCurrenciesLoading, setIsCurrenciesLoading] = (0, react_1.useState)(false);
    const [cascadedItems, setCascadedItems] = (0, react_1.useState)([]);
    const [isSticky, setSticky] = (0, react_1.useState)(false);
    const [width, setWidth] = (0, react_1.useState)(undefined);
    const ref = (0, react_1.useRef)(null);
    const [state, dispatch] = (0, submissionFormItems_1.useSubmissionItemsFormReducer)();
    const [elRefs, setElRefs] = (0, react_1.useState)([]);
    const [selectedItemsIndex, setSelectedItems] = (0, react_1.useState)([]);
    const [deletingItem, setDeletingItem] = (0, react_1.useState)({ showAlert: false, ids: [] });
    const [showCalculateCpvCodes, setShowCalculateCpvCodes] = (0, react_1.useState)(false);
    const [showItemsList, setShowItemsList] = (0, react_1.useState)(false);
    const [isListDirty, setIsListDirty] = (0, react_1.useState)(false);
    const getSubmissionItems = async (params, sorted = true) => {
        const itemsParams = sorted ? { ...params, sort: '+sort' } : params;
        dispatch({ type: 'SEND_SUBMISSION_ITEMS_FORM_REQUEST' });
        const submissionItems = await index_1.SubmissionsApi.getSubmissionItemsForm(props.submissionId, itemsParams);
        navigate(`/documents/purchases/submissions/${props.submissionId}/edit` + (0, queryString_1.stringify)(params));
        dispatch({ type: 'DONE_SUBMISSION_ITEMS_FORM_REQUEST', payload: submissionItems });
    };
    const deleteSubmissionItem = async () => {
        setDeletingItem({ ...deletingItem, showAlert: false });
        dispatch({ type: 'SEND_SUBMISSION_ITEM_DELETE_REQUEST' });
        const response = await index_1.SubmissionsApi.deleteSubmissionItem(props.submissionId, { ids: deletingItem.ids });
        const itemPage = (0, helpers_1.calculateCurrentPage)(state.meta.pagination, 1);
        if (itemPage && response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS) {
            const params = (0, queryString_1.parse)(location.search);
            getSubmissionItems({ ...params, page: itemPage }).then();
        }
        else {
            dispatch({ type: 'DONE_SUBMISSION_ITEM_DELETE_REQUEST', payload: response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS ? deletingItem.ids : [] });
        }
        setDeletingItem({ showAlert: false, ids: [] });
        return response;
    };
    const handleDeleteSubmissionItem = (itemId, isDirty, items, itemIndex, itemDirty) => {
        if (itemId || (itemId === null && isDirty)) {
            setDeletingItem({ ids: [itemId], showAlert: true });
        }
        else {
            const newSubmissionItems = [...items];
            newSubmissionItems.splice(itemIndex, 1);
            updateStateItems(newSubmissionItems);
            if (props.setShowSendButton)
                props.setShowSendButton(!itemDirty);
        }
    };
    const handleCopySubmissionItem = (item, items, itemIndex) => {
        const newValue = {
            ...item,
            parentId: item.id ?? item.parentId,
            id: null
        };
        const newSubmissionItems = [...items];
        newSubmissionItems.splice(itemIndex + 1, 0, newValue);
        updateStateItems(newSubmissionItems);
        setIsListDirty(true);
        if (props.setShowSendButton)
            props.setShowSendButton(false);
    };
    const handleCascadeItem = (id) => {
        cascadedItems.includes(id)
            ? setCascadedItems(cascadedItems.filter(el => el !== id))
            : setCascadedItems([...cascadedItems, id]);
    };
    const selectItem = (checked, itemId) => {
        if (checked) {
            setSelectedItems([...selectedItemsIndex, itemId]);
        }
        else {
            setSelectedItems(selectedItemsIndex.filter(el => el !== itemId));
        }
    };
    const selectAllItem = (checked, itemIds) => {
        if (checked) {
            setSelectedItems(itemIds);
        }
        else {
            setSelectedItems([]);
        }
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    const selectPerPage = (perPage) => {
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'CHANGE_PER_PAGE', payload: perPage });
        getSubmissionItems({ ...params, perPage, page: undefined }).then();
    };
    const debouncedSearch = (value) => {
        setKeyword(value);
        const searchParams = (0, queryString_1.parse)(location.search);
        const params = value
            ? { perPage: searchParams.perPage, filters: { keyword: value } }
            : { page: searchParams.page };
        (0, debouncedMethods_1.debouncedOnFunc)(params, getSubmissionItems);
    };
    const navigatePagination = (page) => {
        const itemPage = Math.ceil(state.meta.pagination.total / state.meta.pagination.perPage) >= page ? page : page - 1;
        const params = (0, queryString_1.parse)(location.search);
        getSubmissionItems({ ...params, page: itemPage }).then();
    };
    const updateStateItems = (items) => {
        dispatch({ type: 'UPDATE_STATE_ITEMS', payload: items });
    };
    const updateSubmissionItemsList = async (id, values) => {
        const params = (0, helpers_2.transformSubmissionItemsListParams)(values);
        dispatch({ type: 'SEND_ITEMS_LIST_UPDATE_REQUEST' });
        const submissionItems = await index_1.SubmissionsApi.updateSubmissionItem(id, params);
        dispatch({ type: 'DONE_ITEMS_LIST_UPDATE_REQUEST', payload: submissionItems });
        if (submissionItems.data !== null)
            setIsListDirty(false);
        return submissionItems;
    };
    const handleDeleteItem = (index, items) => {
        const newSubmissionItems = [...items];
        newSubmissionItems.splice(index, 1);
        return newSubmissionItems;
    };
    const getDimensionUnitsForSelect = async () => {
        const data = await index_1.DimensionUnitsApi.getDimensionUnitsForSelect({ perPage: 300 });
        setDimensionUnits(data);
    };
    const getCurrenciesForSelect = async (params) => {
        setIsCurrenciesLoading(true);
        const data = await index_1.CurrenciesApi.getCurrencyForSelect(params);
        setCurrencies(data);
        setIsCurrenciesLoading(false);
    };
    const handleScroll = () => {
        if (ref.current) {
            setWidth(ref.current.clientWidth);
            setSticky(ref.current.getBoundingClientRect().top <= 122);
        }
    };
    (0, react_1.useEffect)(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', () => handleScroll);
        };
    }, []);
    (0, react_1.useEffect)(() => {
        getDimensionUnitsForSelect().then();
        // getCurrenciesForSelect({ perPage: 300, sort: '+sort,-id' }).then()
    }, []);
    (0, react_1.useEffect)(() => {
        const params = (0, helpers_2.getItemsParamsFromUrl)(location.search);
        const paginatedStorage = JSON.parse(localStorage.getItem('paginated') || '""');
        const storagePerPage = paginatedStorage?.submissionItems?.perPage;
        params.perPage = params.perPage || Number(storagePerPage) || 50;
        dispatch({ type: 'CHANGE_PER_PAGE', payload: params.perPage });
        setKeyword(params.filters?.keyword || '');
        if (props.submissionId)
            getSubmissionItems(params).then();
    }, [props.submissionId]);
    return {
        state,
        dimensionUnits,
        handleDeleteItem,
        currencies,
        isSticky,
        width,
        ref,
        keyword,
        cascadedItems,
        handleCascadeItem,
        elRefs,
        setElRefs,
        selectedItemsIndex,
        selectItem,
        selectAllItem,
        selectPerPage,
        navigatePagination,
        deleteSubmissionItem,
        deletingItem,
        setDeletingItem,
        updateStateItems,
        setCascadedItems,
        setShowCalculateCpvCodes,
        showCalculateCpvCodes,
        updateSubmissionItemsList,
        debouncedSearch,
        showItemsList,
        setShowItemsList,
        isListDirty,
        setIsListDirty,
        handleDeleteSubmissionItem,
        handleCopySubmissionItem,
        getCurrenciesForSelect,
        isCurrenciesLoading,
        closeErrorMessage
    };
};
exports.useSubmissionItemsForm = useSubmissionItemsForm;
