"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSubmissionItems = void 0;
const submissionItems_1 = require("../store/submissionItems");
const index_1 = require("api/index");
const react_1 = require("react");
const useSubmissionItems = (id) => {
    const [state, dispatch] = (0, submissionItems_1.useSubmissionItemsReducer)();
    const getSubmissionItems = async (page) => {
        dispatch({ type: 'SEND_SUBMISSION_ITEMS_REQUEST' });
        const items = await index_1.SubmissionsApi.getSubmissionItems(id, page);
        dispatch({ type: 'DONE_SUBMISSION_ITEMS_REQUEST', payload: items });
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => {
        getSubmissionItems().then();
    }, []);
    return {
        state,
        getSubmissionItems,
        closeErrorMessage
    };
};
exports.useSubmissionItems = useSubmissionItems;
