"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformFilterParamsToUrl = exports.transformUrlToFilterParams = exports.transformVacationParamsToFilterParams = exports.transformVacationParams = exports.getParamsFromUrl = void 0;
const types_1 = require("api/documents/human-resources/vacations/types");
const queryString_1 = require("core/helpers/queryString");
const helper_1 = require("src/modules/common/helper");
const getParamsFromUrl = (url) => {
    try {
        const params = (0, queryString_1.parse)(url);
        return {
            page: params.page,
            perPage: params.perPage,
            filters: {
                startDate: params.filters?.startDate,
                endDate: params.filters?.endDate,
                usersId: params.filters?.usersId
            }
        };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.getParamsFromUrl = getParamsFromUrl;
const transformVacationParams = (params) => {
    return {
        accountId: params.structuralUnit.accountId,
        substituteAccountId: params.substituteStructuralUnit.accountId,
        comment: params.comment,
        endDate: (0, helper_1.transformParamsDate)(params.endDate),
        startDate: (0, helper_1.transformParamsDate)(params.startDate),
        type: types_1.VacationType.VACATION
    };
};
exports.transformVacationParams = transformVacationParams;
const transformVacationParamsToFilterParams = (params) => {
    const filters = {};
    const paginate = {};
    const filterParams = {
        usersId: params.user?.id,
        structuralUnitId: params.structuralUnit?.id,
        startDate: params.startDate && (0, helper_1.transformParamsDate)(params.startDate),
        endDate: params.endDate && (0, helper_1.transformParamsDate)(params.endDate),
        page: params.page,
        perPage: params.perPage
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if (value && key !== 'page' && key !== 'perPage')
                filters[key] = value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(filters).length === 0)
            return { ...paginate };
        return { ...paginate, filters };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformVacationParamsToFilterParams = transformVacationParamsToFilterParams;
const transformUrlToFilterParams = (url) => {
    const values = (0, queryString_1.parse)(url);
    return {
        user: values.usersId && values.usersLabel ? { id: values.usersId, label: values.usersLabel } : undefined,
        startDate: values.startDate,
        endDate: values.endDate,
        structuralUnit: values.structuralUnitId && values.structuralUnitLabel ? { id: values.structuralUnitId, label: values.structuralUnitLabel } : undefined,
        page: values.page,
        perPage: values.perPage
    };
};
exports.transformUrlToFilterParams = transformUrlToFilterParams;
const transformFilterParamsToUrl = (values) => {
    const { page, perPage, structuralUnit, user, startDate, endDate } = values;
    return {
        page,
        perPage,
        usersId: user && user.id,
        usersLabel: user && user.label,
        structuralUnitId: structuralUnit && structuralUnit.id,
        structuralUnitLabel: structuralUnit && structuralUnit.label,
        startDate,
        endDate
    };
};
exports.transformFilterParamsToUrl = transformFilterParamsToUrl;
