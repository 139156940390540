"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// import {
//   ConditionalSubmissionItem,
// } from 'api/documents/purchases/conditional-submissions/types'
// import { ConditionalSubmissionsApi } from 'api/index'
const react_1 = require("react");
const useConditionalSubmissionItemHook = (props) => {
    const [startTransition, setStartTransition] = (0, react_1.useState)(false);
    const itemRef = (0, react_1.useRef)(null);
    const [foldingHeight, setFoldingHeight] = (0, react_1.useState)(props.foldingHeight);
    // const updateOrCreateConditionalSubmissionItems = async (id: ID, items: ConditionalSubmissionItem): Promise<ConditionalSubmissionItem | null> => {
    //   const params = transformConditionalSubmissionItem(items,)
    //   if (params === null) {
    //     toast.error('something went wrong')x
    //     return null
    //   }
    //   dispatch({ type: 'SEND_CONDITIONAL_SUBMISSION_ITEM_UPDATE_OR_CREATE' })
    //   const conditionalSubmissionItem = await ConditionalSubmissionsApi.updateOrCreateConditionalSubmissionItem(id, params)
    //   dispatch({ type: 'DONE_CONDITIONAL_SUBMISSION_ITEM_UPDATE_OR_CREATE' })
    //   if (conditionalSubmissionItem.data !== null) {
    //     toast.success('მონაცემები განახლდა წარმატებით')
    //     props.setShowSendButton(true)
    //     return conditionalSubmissionItem.data[0] as ConditionalSubmissionItem
    //   } else {
    //     return null
    //   }
    // }
    (0, react_1.useEffect)(() => {
        setFoldingHeight(437);
        setTimeout(() => setFoldingHeight(undefined), 300);
    }, []);
    return {
        setStartTransition,
        startTransition,
        // updateOrCreateConditionalSubmissionItems,
        itemRef,
        foldingHeight,
        setFoldingHeight
    };
};
exports.default = useConditionalSubmissionItemHook;
