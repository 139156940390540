"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const role_1 = require("../store/role");
const index_1 = require("api/index");
const queryString_1 = require("core/helpers/queryString");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useRole = (props) => {
    const [state, dispatch] = (0, role_1.useRoleReducer)();
    const [listingParams, setListingParams] = (0, react_1.useState)('');
    const navigate = (0, react_router_dom_1.useNavigate)();
    const getRole = async (id) => {
        dispatch({ type: 'SEND_ROLE_REQUEST' });
        const role = await index_1.RolesApi.getRole(id);
        dispatch({ type: 'DONE_ROLE_REQUEST', payload: role });
    };
    const createRole = async (values) => {
        const params = (0, helpers_1.transformRoleParams)(values);
        dispatch({ type: 'SEND_ROLE_CREATE' });
        const role = await index_1.RolesApi.createRole(params);
        dispatch({ type: 'DONE_ROLE_CREATE', payload: role });
        if (role.data !== null)
            navigate(`/administer/roles/${role.data.id}/edit`);
        return role;
    };
    const updateRole = async (id, values) => {
        const params = (0, helpers_1.transformRoleParams)(values);
        dispatch({ type: 'SEND_ROLE_UPDATE' });
        const role = await index_1.RolesApi.updateRole(id, params);
        dispatch({ type: 'DONE_ROLE_UPDATE', payload: role });
        return role;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => {
        const params = (0, helpers_1.transformRolePermissionFilterParamsToUrl)((0, helpers_1.transformUrlToRolePermissionFilterParams)(location.search));
        const searchParams = (0, queryString_1.stringify)({ ...params });
        setListingParams(searchParams || '?');
        if (props.id)
            getRole(props.id).then();
    }, [props.id]);
    return { state, createRole, updateRole, getRole, closeErrorMessage, listingParams };
};
exports.default = useRole;
