"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const api_1 = require("src/api");
const react_1 = require("react");
const useVersionControl = () => {
    const [revision, setRevision] = (0, react_1.useState)(null);
    const checkVersion = async () => {
        return api_1.VersionApi.getMetadata();
    };
    let delayTimeIdentifier;
    (0, react_1.useEffect)(() => {
        checkVersion().then(res => {
            // if (!version.revision && res) updateVersion(res)
            if (res)
                setRevision(res.revision);
        });
        interval();
        return () => {
            clearInterval(interval());
            clearTimeout(delayTimeIdentifier);
        };
    }, []);
    const interval = () => setInterval(() => {
        checkVersion().then(res => setRevision(res.revision));
    }, 10 * 60 * 1000);
    return { revision };
};
exports.default = useVersionControl;
