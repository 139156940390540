"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_router_dom_1 = require("react-router-dom");
const breadCrumb = (props) => {
    return ((0, jsx_runtime_1.jsx)("div", { className: 'subheader py-2 py-lg-4  subheader-solid d-print-none', children: (0, jsx_runtime_1.jsxs)("div", { className: 'container-fluid  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap', children: [(0, jsx_runtime_1.jsx)("div", { className: 'd-flex align-items-center flex-wrap mr-1', children: (0, jsx_runtime_1.jsx)("div", { className: 'd-flex align-items-baseline mr-5', children: (0, jsx_runtime_1.jsxs)("ul", { className: 'breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm', children: [(0, jsx_runtime_1.jsx)("li", { className: 'breadcrumb-item', children: props.path.length > 0
                                        ? (0, jsx_runtime_1.jsx)(react_router_dom_1.Link, { to: '/', className: 'kt-subheader__breadcrumbs-link', children: "\u10E1\u10D0\u10EC\u10E7\u10D8\u10E1\u10D8" })
                                        : (0, jsx_runtime_1.jsx)("a", { href: '#', className: 'text-muted', onClick: e => e.preventDefault(), children: "\u10E1\u10D0\u10EC\u10E7\u10D8\u10E1\u10D8" }) }), props.path.map((item, index) => {
                                    if ((index + 1) === props.path.length) {
                                        return ((0, jsx_runtime_1.jsxs)("li", { className: 'breadcrumb-item', children: [(0, jsx_runtime_1.jsx)("a", { href: '#', onClick: e => e.preventDefault(), className: 'text-muted' }), item.text] }, item.text));
                                    }
                                    else {
                                        return ((0, jsx_runtime_1.jsxs)("li", { className: 'breadcrumb-item', children: [(0, jsx_runtime_1.jsx)("span", { className: 'kt-subheader__breadcrumbs-separator' }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Link, { to: item.link, className: 'kt-subheader__breadcrumbs-link', children: item.text }, item.text)] }, item.text));
                                    }
                                })] }) }) }), props.addItemRoute && props.canCreate
                    ? ((0, jsx_runtime_1.jsx)("div", { className: 'd-flex align-items-center', children: (0, jsx_runtime_1.jsx)(react_router_dom_1.Link, { to: props.addItemRoute, className: 'btn btn-light font-weight-bold btn-sm', children: "\u10D0\u10EE\u10DA\u10D8\u10E1 \u10D3\u10D0\u10DB\u10D0\u10E2\u10D4\u10D1\u10D0" }) })) : null] }) }));
};
exports.default = breadCrumb;
