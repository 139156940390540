"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const react_1 = require("react");
const useCalculateFundingSources = (props) => {
    const [groupedItems, setGroupedItems] = (0, react_1.useState)([]);
    const [summery, setSummery] = (0, react_1.useState)(0);
    const [summerByProcurementItem, setSummerByProcurementItem] = (0, react_1.useState)(0);
    const [diffSummery, setDiffSummery] = (0, react_1.useState)(0);
    const [error, setError] = (0, react_1.useState)('');
    const [procurements, setProcurements] = (0, react_1.useState)([]);
    const [loadingItems, setLoadingItems] = (0, react_1.useState)(false);
    const Calculate = async (page = 1, data = []) => {
        setLoadingItems(true);
        const submissionVerifyItemsV2 = await index_1.SubmissionVerifiesApi.getSubmissionVerifyItemsV2(props.verifySubmissionId, { page });
        if (submissionVerifyItemsV2.data === null)
            return;
        const newData = data.concat(submissionVerifyItemsV2.data);
        if (page < submissionVerifyItemsV2.meta.pagination.totalPages) {
            return Calculate(page + 1, newData);
        }
        setLoadingItems(false);
        const procurementItems = [];
        const newArray = newData.map(el => {
            if (el.procurement && !procurementItems.find(procurement => procurement.id === el.procurement.id)) {
                procurementItems.push(el.procurement);
            }
            const rate = !el.currencyExchangeRate ? 1 : el.currencyExchangeRate;
            if (!el.quantity || !el.price) {
                setError('გთხოვთ შეავსოთ გამოტოვებული ველები. აუცილებელია ველებია: ოდენობა, ერთეულის ფასი, ვალუტა და გაცვლითი კურსი');
            }
            const sumpProcurementItemPrice = !el.quantity ? 0 : el.quantity * rate * (el.procurement?.itemPrice || 0);
            const sumPrice = !el.quantity || !el.price ? 0 : el.quantity * rate * el.price;
            const diff = sumPrice - sumpProcurementItemPrice;
            return {
                id: el.project?.id || 'missing',
                label: el.project?.label || 'გამოტოვებული',
                sumpProcurementItemPrice,
                sumPrice,
                diff,
                count: 1,
                budgetArticles: [{
                        id: el.budgetArticle?.id || 'missing',
                        label: el.budgetArticle?.label || 'გამოტოვებული',
                        diff,
                        sumpProcurementItemPrice,
                        sumPrice,
                        count: 1
                    }]
            };
        });
        let sum = 0;
        let sumByProcurementItem = 0;
        let diff = 0;
        const groupedFundingSources = newArray.reduce((previousValue, currentValue) => {
            const item = previousValue.find(el => el.id === currentValue.id);
            if (item) {
                const newItem = {
                    ...item,
                    budgetArticles: [...item.budgetArticles, ...currentValue.budgetArticles],
                    count: item.count + 1,
                    sumpProcurementItemPrice: item.sumpProcurementItemPrice + currentValue.sumpProcurementItemPrice,
                    sumPrice: item.sumPrice + currentValue.sumPrice,
                    diff: item.diff + currentValue.diff
                };
                const index = previousValue.findIndex(el => el.id === currentValue.id);
                previousValue[index] = newItem;
            }
            else {
                previousValue.push(currentValue);
            }
            sum = sum + currentValue.sumPrice;
            sumByProcurementItem = sumByProcurementItem + currentValue.sumpProcurementItemPrice;
            diff = diff + currentValue.diff;
            return previousValue;
        }, []);
        const groupedItems = groupedFundingSources.map(el => {
            let missingSum = 0;
            let missingSumByProcurementItem = 0;
            let missingDiff = 0;
            const groupedBudgetArticles = el.budgetArticles.reduce((previousValue, currentValue) => {
                const item = previousValue.find(el => el.id === currentValue.id);
                if (currentValue.id === 'missing') {
                    missingSum = missingSum + currentValue.sumPrice;
                    missingDiff = missingDiff + currentValue.diff;
                    missingSumByProcurementItem = missingSumByProcurementItem + currentValue.sumpProcurementItemPrice;
                }
                if (item) {
                    const newItem = {
                        ...item,
                        count: item.count + 1,
                        diff: item.diff + currentValue.diff,
                        sumpProcurementItemPrice: item.sumpProcurementItemPrice + currentValue.sumpProcurementItemPrice,
                        sumPrice: item.sumPrice + currentValue.sumPrice
                    };
                    const index = previousValue.findIndex(el => el.id === currentValue.id);
                    previousValue[index] = newItem;
                }
                else {
                    previousValue.push(currentValue);
                }
                return previousValue;
            }, []);
            sum = sum - missingSum;
            diff = diff - missingDiff;
            sumByProcurementItem = sumByProcurementItem - missingSumByProcurementItem;
            return {
                ...el,
                sumPrice: el.sumPrice - missingSum,
                diff: el.diff - missingDiff,
                sumpProcurementItemPrice: el.sumpProcurementItemPrice - missingSumByProcurementItem,
                budgetArticles: groupedBudgetArticles
            };
        });
        setSummery(sum);
        setDiffSummery(diff);
        setSummerByProcurementItem(sumByProcurementItem);
        setGroupedItems(groupedItems);
        setProcurements(procurementItems);
    };
    (0, react_1.useEffect)(() => { Calculate().then(); }, []);
    return { groupedItems, summery, summerByProcurementItem, procurements, diffSummery, error, loadingItems };
};
exports.default = useCalculateFundingSources;
