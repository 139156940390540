"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VacationType = void 0;
var VacationType;
(function (VacationType) {
    VacationType[VacationType["VACATION"] = 1] = "VACATION";
    VacationType[VacationType["UNUSED_VACATION"] = 2] = "UNUSED_VACATION";
    VacationType[VacationType["MATERNITY_VACATION"] = 3] = "MATERNITY_VACATION";
    VacationType[VacationType["FREE_VACATION"] = 4] = "FREE_VACATION";
    VacationType[VacationType["HOSPITAL_SHEET"] = 5] = "HOSPITAL_SHEET";
    VacationType[VacationType["BUSINESS_TRIP"] = 6] = "BUSINESS_TRIP";
})(VacationType || (exports.VacationType = VacationType = {}));
