"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getParamsFromUrl = exports.transformHolidayParams = void 0;
const queryString_1 = require("core/helpers/queryString");
const parsers_1 = require("api/parsers/parsers");
const transformHolidayParams = (params) => {
    return {
        date: (0, parsers_1.transformDateParams)(params.date),
        title: params.title
    };
};
exports.transformHolidayParams = transformHolidayParams;
const getParamsFromUrl = (url) => {
    try {
        const params = (0, queryString_1.parse)(url);
        return {
            page: params.page,
            perPage: params.perPage,
            filters: params.filters?.keyword ? { keyword: params.filters.keyword } : undefined
        };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.getParamsFromUrl = getParamsFromUrl;
