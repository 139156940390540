"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const budgetArticles_1 = require("../store/budgetArticles");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helpers_1 = require("../helpers");
const queryString_1 = require("core/helpers/queryString");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useBudgetArticles = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, budgetArticles_1.useBudgetArticlesReducer)();
    const [keyword, setKeyword] = (0, react_1.useState)('');
    const isMounted = (0, react_1.useRef)(false);
    const getBudgetArticles = async (params) => {
        dispatch({ type: 'SEND_BUDGET_ARTICLES_REQUEST' });
        const budgetArticles = await index_1.BudgetArticlesApi.getBudgetArticles(params);
        if (isMounted) {
            navigate('/references/budget-articles' + (0, queryString_1.stringify)(params), { replace: true });
            dispatch({ type: 'DONE_BUDGET_ARTICLES_REQUEST', payload: budgetArticles });
        }
    };
    const handleOpenClose = async (budgetArticle) => {
        if (!budgetArticle.hasChildren)
            return;
        const newStateData = [...state.data];
        const foundItem = (0, helpers_1.findNestedItem)(newStateData, budgetArticle);
        if (!foundItem)
            return;
        if (foundItem.children.length > 0) {
            foundItem.closed = !foundItem.closed;
            dispatch({ type: 'UPDATE_STATE', payload: newStateData });
            return;
        }
        const params = { filters: { parentId: budgetArticle.id }, perPage: 100 };
        dispatch({ type: 'SET_LOADING_ITEM', payload: foundItem });
        const budgetArticles = await index_1.BudgetArticlesApi.getBudgetArticles(params);
        dispatch({ type: 'UN_SET_LOADING_ITEM', payload: foundItem });
        foundItem.closed = !foundItem.closed;
        foundItem.children = budgetArticles.data?.map(child => {
            child.level = foundItem.level + 1;
            return child;
        }) || [];
        dispatch({ type: 'UPDATE_STATE', payload: newStateData });
    };
    const debouncedSearch = (value) => {
        setKeyword(value);
        const params = value ? { filters: { keyword: value }, perPage: 100 } : { filters: { parentId: 0 }, perPage: 100 };
        (0, debouncedMethods_1.debouncedOnFunc)(params, getBudgetArticles);
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        const params = (0, helpers_1.getParamsFromUrl)(location.search);
        setKeyword(params.filters?.keyword || '');
        getBudgetArticles(params).then();
        return () => { isMounted.current = false; };
    }, []);
    return { state, keyword, debouncedSearch, handleOpenClose, getBudgetArticles };
};
exports.default = useBudgetArticles;
