"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAssignSubmissionVerifyItemReducer = exports.initialAssignSubmissionVerifyIteStore = void 0;
const react_1 = require("react");
exports.initialAssignSubmissionVerifyIteStore = {
    data: [],
    isUpdating: false,
    creatingWorkType: false
};
const useAssignSubmissionVerifyItemReducer = () => {
    return (0, react_1.useReducer)(assignSubmissionVerifyItemReducer, exports.initialAssignSubmissionVerifyIteStore);
};
exports.useAssignSubmissionVerifyItemReducer = useAssignSubmissionVerifyItemReducer;
const assignSubmissionVerifyItemReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_CREATE_WORK_TYPE_REQUEST':
            return {
                ...state,
                creatingWorkType: true
            };
        case 'DONE_CREATE_WORK_TYPE_REQUEST':
            return {
                ...state,
                creatingWorkType: false
            };
    }
};
