"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useScroll = (id) => {
    (0, react_1.useEffect)(() => {
        const scrollTo = document.getElementById(`row-${id}`);
        if (scrollTo) {
            scrollTo.scrollIntoView({ behavior: 'smooth', block: 'center' });
            scrollTo.focus({ preventScroll: true });
        }
    }, [id]);
};
exports.default = useScroll;
