"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmployeeDocumentsFiles = exports.transformCreateHRVerifyParams = exports.transformWorkInfoItemUnitFee = exports.transformWorkInfoItem = exports.transformAssignSubmissionPosition = exports.transformAssignSubmissionItemsListParams = exports.transformAssignSubmissionItem = exports.transformHRAssignSubmissionParams = exports.getItemsParamsFromUrl = exports.getParamsFromUrl = exports.transformAssignSubmissionFilterParamsToUrl = exports.transformAssignSubmissionParamsToFilterParams = exports.transformUrlToAssignSubmissionFilterParams = void 0;
const types_1 = require("api/documents/human-resources/assign-submissions/types");
const types_2 = require("api/references/structuralUnitsUpgraded/types");
const helper_1 = require("src/modules/common/helper");
const queryString_1 = require("core/helpers/queryString");
const helper_2 = require("src/modules/references/employees/helper");
const parsers_1 = require("api/parsers/parsers");
const transformUrlToAssignSubmissionFilterParams = (url) => {
    const values = (0, queryString_1.parse)(url);
    return {
        keyword: values.keyword,
        page: values.page,
        perPage: values.perPage
    };
};
exports.transformUrlToAssignSubmissionFilterParams = transformUrlToAssignSubmissionFilterParams;
const transformAssignSubmissionParamsToFilterParams = (params) => {
    const filters = {};
    const paginate = {};
    const filterParams = {
        keyword: params.keyword,
        page: params.page,
        perPage: params.perPage
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if (value && (key !== 'page' && key !== 'perPage'))
                filters[key] = value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(filters).length === 0)
            return { ...paginate };
        return { ...paginate, filters };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformAssignSubmissionParamsToFilterParams = transformAssignSubmissionParamsToFilterParams;
const transformAssignSubmissionFilterParamsToUrl = (values) => {
    const { page, perPage, keyword } = values;
    return {
        page,
        perPage,
        keyword
    };
};
exports.transformAssignSubmissionFilterParamsToUrl = transformAssignSubmissionFilterParamsToUrl;
const getParamsFromUrl = (url) => {
    try {
        const params = (0, queryString_1.parse)(url);
        return {
            page: params.page,
            perPage: params.perPage,
            filters: params.filters?.keyword ? { keyword: params.filters.keyword } : undefined
        };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.getParamsFromUrl = getParamsFromUrl;
const getItemsParamsFromUrl = (url) => {
    try {
        const params = (0, queryString_1.parse)(url);
        return {
            page: params.page,
            perPage: params.perPage,
            filters: params.filters?.keyword ? { keyword: params.filters.keyword } : undefined
        };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.getItemsParamsFromUrl = getItemsParamsFromUrl;
const transformHRAssignSubmissionParams = (params) => {
    const updateEdoc = params.status === types_1.HRAssignSubmissionStatuses.SENT;
    return {
        requesterAccountId: params.authorStructuralUnit.accountId,
        edocNumber: updateEdoc ? params.edocNumber : undefined,
        requestRegistrationDate: params.id && updateEdoc ? (0, helper_1.nullableTransformParamsDate)(params.requestRegistrationDate) : undefined,
        contactPersonId: params.contactPerson?.id,
        contactPersonPhoneNumber: params.phoneNumber,
        contactPersonEmail: params.contactPersonEmail,
        purpose: params.purpose,
        addressees: params.addressees.map(el => {
            return {
                accountId: el.addresseeStructuralUnit.accountId
            };
        }),
        fileUids: params.files.map(el => ({ uid: el.id, type: el.type }))
    };
};
exports.transformHRAssignSubmissionParams = transformHRAssignSubmissionParams;
const transformAssignSubmissionItem = (value) => {
    const cv = value.files.cv.filter(el => el.id);
    const bankingRequisites = value.files.bankingRequisites.filter(el => el.id);
    const taxCertificate = value.files.taxCertificate.filter(el => el.id);
    const diploma = value.files.diploma.filter(el => el.id);
    const conviction = value.files.conviction.filter(el => el.id);
    const other = value.files.other.filter(el => el.id);
    const passport = value.files.passport.filter(el => el.id);
    const files = [
        ...passport, ...cv, ...bankingRequisites,
        ...taxCertificate, ...diploma, ...conviction, ...other
    ];
    return {
        items: [{
                id: value.id,
                foreignCitizen: value.citizen === types_1.Citizen.FOREIGN,
                personalNumber: value.personalNumber && value.personalNumber.length > 0 ? value.personalNumber : null,
                passportNumber: value.passportNumber && value.passportNumber.length > 0 ? value.passportNumber : null,
                firstname: value.firstname,
                lastname: value.lastname,
                phoneNumber: value.phoneNumber,
                email: value.email,
                legalAddress: value.legalAddress,
                actualAddress: value.isDifferentAddress ? value.physicalAddress : value.legalAddress,
                fileUids: files.map(file => ({ uid: file.id, type: file.type })),
                isEdocUser: value.isEdocUser,
                openBankAccount: value.openBankAccount,
                openCorporateEmail: value.openCorporateEmail,
                positionInfo: value.positionInfos.map(position => (0, exports.transformAssignSubmissionPosition)(position, null))
            }]
    };
};
exports.transformAssignSubmissionItem = transformAssignSubmissionItem;
const transformAssignSubmissionItemsListParams = (values) => {
    return { items: values.map(el => transformAssignSubmissionItemListParams(el)) };
};
exports.transformAssignSubmissionItemsListParams = transformAssignSubmissionItemsListParams;
const transformAssignSubmissionItemListParams = (values) => {
    const cv = values.files.cv.filter(el => el.id);
    const bankingRequisites = values.files.bankingRequisites.filter(el => el.id);
    const taxCertificate = values.files.taxCertificate.filter(el => el.id);
    const diploma = values.files.diploma.filter(el => el.id);
    const conviction = values.files.conviction.filter(el => el.id);
    const other = values.files.other.filter(el => el.id);
    const passport = values.files.passport.filter(el => el.id);
    const files = [
        ...passport, ...cv, ...bankingRequisites,
        ...taxCertificate, ...diploma, ...conviction, ...other
    ];
    return {
        id: values.id,
        foreignCitizen: values.citizen === types_1.Citizen.FOREIGN,
        personalNumber: values.personalNumber && values.personalNumber.length > 0 ? values.personalNumber : null,
        passportNumber: values.passportNumber && values.passportNumber.length > 0 ? values.passportNumber : null,
        firstname: values.firstname,
        lastname: values.lastname,
        phoneNumber: values.phoneNumber,
        email: values.email,
        legalAddress: values.legalAddress,
        actualAddress: values.isDifferentAddress ? values.physicalAddress : values.legalAddress,
        fileUids: !values.openBankAccount && bankingRequisites ? files.map(file => ({ uid: file.id, type: file.type })) : null,
        isEdocUser: values.isEdocUser,
        openBankAccount: values.openBankAccount,
        openCorporateEmail: values.openCorporateEmail,
        positionInfo: values.positionInfos.map(position => (0, exports.transformAssignSubmissionPosition)(position, null))
    };
};
const transformAssignSubmissionPosition = (position, correcting, positionType) => {
    return {
        id: !correcting ? position.id : undefined,
        structuralUnitId: position.structuralUnit.id,
        position: position.position,
        startDate: (0, parsers_1.transformDateParams)(position.startDate),
        endDate: (0, parsers_1.transformDateParams)(position.endDate),
        workPlaces: position.workPlaces.map(workPlace => workPlace.id),
        workDirection: position.workDirection,
        workDirectionId: position.selectWorkDirection?.id || null,
        additionalInfo: position.additionalInfo,
        basicFunctionDuties: position.basicFunctionDuties,
        projects: position.projectInfos.map(projectInfo => (0, helper_2.parseProjectsInfo)(projectInfo, correcting)),
        positionTypeId: positionType ? position.positionType?.id : undefined
    };
};
exports.transformAssignSubmissionPosition = transformAssignSubmissionPosition;
const transformWorkInfoItem = (type, workInfo) => {
    switch (type) {
        case types_1.SalaryType.FIXED_RESERVATION:
            return workInfo.fixedReservations.map(item => transformFixedReservation(item));
        case types_1.SalaryType.SUPPLEMENT:
            return workInfo.supplements.map(item => transformSupplements(item));
        case types_1.SalaryType.BY_JOB:
            return workInfo.remunerationWorks.map(item => transformRemunerationWork(item));
        case types_1.SalaryType.FIXED:
        case types_1.SalaryType.UNPAID:
            return null;
    }
};
exports.transformWorkInfoItem = transformWorkInfoItem;
const transformWorkInfoItemUnitFee = (workInfo) => {
    return workInfo.unitFees.map(item => {
        return {
            currencyId: item.currency.id,
            articlePrice: item.tariff,
            equivalentCurrencyId: item.equivalentCurrency.id,
            salaryIssuanceType: Number(item.salaryGivenType.id),
            articleId: item.administrativeOrdersArticle ? item.administrativeOrdersArticle.id : undefined,
            volumeWorkPerformed: item.volumeWorkPerformed,
            unitType: item.unitName.id,
            amount: Number(item.unitName.id) !== types_2.UnitNames.ORDER_OR_IA_PRICE_LIST ? item.amount : undefined
        };
    });
};
exports.transformWorkInfoItemUnitFee = transformWorkInfoItemUnitFee;
const transformFixedReservation = (item) => {
    const isDate = !item.isDate ? item.dateRange?.split(' — ') : null;
    return {
        isDate: !item.isDate,
        isContractFullPeriod: item.isContractFullPeriod,
        startDate: isDate ? (0, parsers_1.transformDateParams)(isDate[0]) : undefined,
        endDate: isDate ? isDate[1] ? (0, parsers_1.transformDateParams)(isDate[1]) : (0, parsers_1.transformDateParams)(isDate[0]) : undefined,
        salaryAmount: item.salaryAmount,
        currencyId: item.currency.id,
        equivalentCurrencyId: item.equivalentCurrency.id,
        salaryIssuanceType: Number(item.salaryGivenType.id),
        months: item.isContractFullPeriod ? item.months.map(month => {
            return Number(month.id);
        })
            : item.isDate ? item.months.map(month => {
                return {
                    startDate: transformStartDate(item.year, month.id),
                    endDate: transformEndDate(item.year, month.id)
                };
            }) : undefined
    };
};
const transformSupplements = (item) => {
    const isDate = !item.isDate ? item.dateRange?.split(' — ') : null;
    return {
        isDate: !item.isDate,
        isContractFullPeriod: item.isContractFullPeriod,
        startDate: isDate ? (0, parsers_1.transformDateParams)(isDate[0]) : undefined,
        endDate: isDate ? isDate[1] ? (0, parsers_1.transformDateParams)(isDate[1]) : (0, parsers_1.transformDateParams)(isDate[0]) : undefined,
        supplementAmount: item.supplementAmount,
        currencyId: item.currency.id,
        equivalentCurrencyId: item.equivalentCurrency.id,
        salaryIssuanceType: Number(item.salaryGivenType.id),
        months: item.isContractFullPeriod ? item.months[0].id === '0'
            ? new Array(12).join().split(',').map((_, index) => index + 1)
            : item.months.map(month => {
                return Number(month.id);
            })
            : item.isDate ? item.months.map(month => {
                if (month.id === '0')
                    return { startDate: `${item.year}-01-01`, endDate: `${item.year}-12-31` };
                return {
                    startDate: transformStartDate(item.year, month.id),
                    endDate: transformEndDate(item.year, month.id)
                };
            }) : undefined
    };
};
const transformRemunerationWork = (item) => {
    const isDate = !item.isDate ? item.dateRange?.split(' — ') : null;
    return {
        isDate: !item.isDate,
        isContractFullPeriod: item.isContractFullPeriod,
        startDate: isDate ? (0, parsers_1.transformDateParams)(isDate[0]) : undefined,
        endDate: isDate ? isDate[1] ? (0, parsers_1.transformDateParams)(isDate[1]) : (0, parsers_1.transformDateParams)(isDate[0]) : undefined,
        salaryAmount: item.salaryAmount,
        currencyId: item.currency.id,
        volumeWorkPerformed: item.volumeWorkPerformed,
        equivalentCurrencyId: item.equivalentCurrency.id,
        salaryIssuanceType: Number(item.salaryGivenType.id),
        months: item.isContractFullPeriod ? item.months[0].id === '0'
            ? new Array(12).join().split(',').map((_, index) => index + 1)
            : item.months.map(month => {
                return Number(month.id);
            })
            : item.isDate ? item.months.map(month => {
                if (month.id === '0')
                    return { startDate: `${item.year}-01-01`, endDate: `${item.year}-12-31` };
                return {
                    startDate: transformStartDate(item.year, month.id),
                    endDate: transformEndDate(item.year, month.id)
                };
            }) : undefined
    };
};
const transformStartDate = (year, month) => {
    const newMonth = month.length > 1 ? month : '0' + month;
    return String(`${year}-${newMonth}-01`);
};
const transformEndDate = (year, month) => {
    const newMonth = month.length > 1 ? month : '0' + month;
    return String(`${year}-${newMonth}-${new Date(year, Number(month), 0).getDate()}`);
};
const transformCreateHRVerifyParams = (values) => {
    return { items: values.map(el => el) };
};
exports.transformCreateHRVerifyParams = transformCreateHRVerifyParams;
exports.EmployeeDocumentsFiles = [
    {
        name: 'diploma',
        title: 'დიპლომი',
        type: types_1.AssignSubmissionItemFileTypes.DIPLOMA
    },
    {
        name: 'cv',
        title: 'CV',
        type: types_1.AssignSubmissionItemFileTypes.CV
    },
    {
        name: 'conviction',
        title: 'ცნობა (ნასამართლეობა)',
        type: types_1.AssignSubmissionItemFileTypes.CONVICTION
    },
    {
        name: 'taxCertificate',
        title: 'ცნობა (საგადასახადო შეღავათი)',
        type: types_1.AssignSubmissionItemFileTypes.TAX_CERTIFICATE
    },
    {
        name: 'other',
        title: 'სხვა',
        type: types_1.AssignSubmissionItemFileTypes.OTHER
    }
];
