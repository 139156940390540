"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthProvider = exports.AuthContext = exports.useAuth = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const api_1 = require("../api");
const useAuthReducer_1 = require("../stores/useAuthReducer");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const react_1 = require("react");
const helpers_1 = require("src/modules/auth/login/helpers");
const react_router_dom_1 = require("react-router-dom");
const MainProvider_1 = require("src/providers/MainProvider");
function useAuth() {
    return (0, react_1.useContext)(exports.AuthContext);
}
exports.useAuth = useAuth;
const initialAuthContext = {
    state: useAuthReducer_1.initialAuthState,
    signin: async () => ({ errors: null, status: apiGlobalTypes_1.ResponseStatuses.UNEXPECTED }),
    googleAuth: () => ({}),
    signout: () => ({}),
    getAuthUser: async () => true
};
exports.AuthContext = (0, react_1.createContext)(initialAuthContext);
const AuthProvider = ({ children }) => {
    const [state, dispatch] = (0, useAuthReducer_1.useAuthReducer)();
    const mainProvider = (0, MainProvider_1.useMain)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const googleAuth = async () => {
        await client.requestAccessToken();
    };
    let client;
    function initClient() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        client = google.accounts.oauth2.initTokenClient({
            client_id: process.env.GOOGLE_CLIENT_ID,
            scope: process.env.GOOGLE_SCOPE,
            callback: (tokenResponse) => {
                // console.info(tokenResponse.access_token)
                if (!tokenResponse || !tokenResponse.access_token) {
                    // toast.error('Google Auth library is not initialized!')
                }
                else {
                    onGoogleTokenResponse(tokenResponse.access_token).then();
                }
            }
        });
    }
    const onGoogleTokenResponse = async (googleToken) => {
        const params = (0, helpers_1.transformGoogleAuthUserParams)(googleToken);
        dispatch({ type: 'SEND_TOKEN_REQUEST' });
        const response = await api_1.Auth.login(params);
        dispatch({ type: 'DONE_TOKEN_REQUEST', payload: response });
        if (response.data !== null) {
            localStorage.removeItem('activeStructuralUnitId');
            localStorage.setItem('token', JSON.stringify(response.data));
            if (response.data)
                getAuthUser(true).then();
        }
    };
    if (process.env.GOOGLE_CLIENT_ID && process.env.GOOGLE_SCOPE)
        initClient();
    const signin = async (value) => {
        const params = (0, helpers_1.transformAuthUserParams)(value);
        dispatch({ type: 'SEND_TOKEN_REQUEST' });
        const response = await api_1.Auth.login(params);
        dispatch({ type: 'DONE_TOKEN_REQUEST', payload: response });
        if (response.data !== null) {
            localStorage.removeItem('activeStructuralUnitId');
            localStorage.setItem('token', JSON.stringify(response.data));
            if (response.data)
                getAuthUser(true).then();
        }
        return { errors: response.errors, status: response.status };
    };
    const getAuthUser = async (redirectFrom = false) => {
        dispatch({ type: 'SEND_AUTH_USER_REQUEST' });
        const response = await api_1.Auth.getAuthUser();
        dispatch({ type: 'DONE_AUTH_USER_REQUEST', payload: response });
        if (response.data) {
            localStorage.setItem('activeStructuralUnitId', response.data.accountId || '');
        }
        else {
            localStorage.removeItem('token');
            localStorage.removeItem('activeStructuralUnitId');
            localStorage.removeItem('authenticate');
        }
        if (redirectFrom)
            navigate(location.state?.from?.pathname || '/', { replace: true });
        return response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS;
    };
    const signout = async () => {
        dispatch({ type: 'SEND_DELETE_TOKEN' });
        const response = await api_1.Auth.loginOut();
        dispatch({ type: 'DONE_DELETE_TOKEN', payload: response });
        if (response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS) {
            navigate('/login');
            mainProvider.setGoogleAuthWarn(true);
        }
    };
    const value = { state, signin, signout, getAuthUser, googleAuth };
    return (0, jsx_runtime_1.jsx)(exports.AuthContext.Provider, { value: value, children: children });
};
exports.AuthProvider = AuthProvider;
