"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const administrativeOrderArticles_1 = require("../store/administrativeOrderArticles");
const react_1 = require("react");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helpers_1 = require("../helpers");
const useAdministrativeOrderArticles = (props) => {
    const [state, dispatch] = (0, administrativeOrderArticles_1.useAdministrativeOrderArticlesReducer)();
    const [keyword, setKeyword] = (0, react_1.useState)('');
    const [showDeleteAlert, setShowDeleteAlert] = (0, react_1.useState)(null);
    const [showAdministrativeOrderForm, setShowAdministrativeOrderForm] = (0, react_1.useState)(false);
    const [openArticleForEdit, setOpenArticleForEdit] = (0, react_1.useState)(null);
    const isMounted = (0, react_1.useRef)(false);
    const getArticles = async (params) => {
        dispatch({ type: 'SEND_ARTICLES_REQUEST' });
        const administrativeOrderArticles = await index_1.AdministrativeOrdersApi.getAdministrativeOrdersArticles(params);
        dispatch({ type: 'DONE_ARTICLES_REQUEST', payload: administrativeOrderArticles });
    };
    const setAddAdministrativeForm = (data) => {
        setShowAdministrativeOrderForm(true);
        const parent = data && {
            id: data.id,
            label: data.articleType.label + ': ' + data.number
                + ' - ' + data.name
                + (!data.price ? '' : ' - ' + data.price + 'ლ')
                + ' - ბრძანების #' + data.orderNumber
                + ' - გამოცემის თარიღი:' + data.publicationDate
        }; //TODO @Merab გადასახედია ლებელის გაპარსვის მეთოდი: parseArticleParentName
        dispatch({ type: 'ADD_ARTICLE_FORM', payload: parent });
    };
    const setAdministrativeForm = (data) => {
        setShowAdministrativeOrderForm(!!data);
        const articleForm = data && {
            id: data.id,
            administrativeOrdersArticle: data.parent,
            name: data.name,
            number: data.number,
            price: data.price
        };
        dispatch({ type: 'SET_ARTICLE_FORM', payload: articleForm });
    };
    const createOrUpdateArticles = async (administrativeOrderId, values) => {
        const params = (0, helpers_1.transformArticlesParams)(values);
        dispatch({ type: 'SEND_CREATE_OR_UPDATE_ARTICLES' });
        const articles = await index_1.AdministrativeOrdersApi.createOrUpdateArticles(administrativeOrderId, params);
        dispatch({ type: 'DONE_CREATE_OR_UPDATE_ARTICLES', payload: { articles, parentId: values.administrativeOrdersArticle?.id || null } });
        return articles;
    };
    const handleOpenClose = async (article) => {
        if (article.isOpen) {
            dispatch({ type: 'CLOSE_ARTICLE', payload: article });
            return null;
        }
        if (!article.hasChildren)
            return null;
        dispatch({ type: 'SEND_ARTICLE_CHILDREN_REQUEST', payload: article });
        const params = {
            filters: {
                // corrected: false,
                parentId: article.id,
                administrativeOrderId: props.administrativeOrderId
            },
            perPage: 1000
        };
        const administrativeOrderArticles = await index_1.AdministrativeOrdersApi.getAdministrativeOrdersArticles(params);
        if (isMounted?.current) {
            dispatch({ type: 'DONE_ARTICLE_CHILDREN_REQUEST', payload: { articles: administrativeOrderArticles, loadingItem: article } });
        }
        return { status: administrativeOrderArticles.status, errors: administrativeOrderArticles.errors };
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    const debouncedSearch = (value) => {
        setKeyword(value);
        const params = {
            filters: {
                keyword: value,
                // corrected: false,
                parentId: value ? undefined : '0',
                administrativeOrderId: props.administrativeOrderId
            }
        };
        (0, debouncedMethods_1.debouncedOnFunc)({ ...params, perPage: 1000 }, getArticles);
    };
    const deleteArticle = async (article) => {
        const administrativeOrderArticles = await index_1.AdministrativeOrdersApi.deleteArticle(props.administrativeOrderId, { ids: [article.id] });
        if (administrativeOrderArticles.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS) {
            dispatch({ type: 'DONE_DELETE_ARTICLE', payload: article });
        }
        return { status: administrativeOrderArticles.status, errors: administrativeOrderArticles.errors };
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        getArticles({ filters: { administrativeOrderId: props.administrativeOrderId, parentId: '0' } }).then();
        return () => { isMounted.current = false; };
    }, []);
    return {
        state,
        keyword,
        debouncedSearch,
        getAdministrativeOrderArticles: getArticles,
        createOrUpdateArticles,
        handleOpenClose,
        deleteArticle,
        showDeleteAlert,
        setShowDeleteAlert,
        showAdministrativeOrderForm,
        setAddAdministrativeForm,
        setAdministrativeForm,
        openArticleForEdit,
        setOpenArticleForEdit,
        setShowAdministrativeOrderForm,
        closeErrorMessage
    };
};
exports.default = useAdministrativeOrderArticles;
