"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const administrativBulding_1 = require("../store/administrativBulding");
const helper_1 = require("../helper");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useAdministrativeBuildings = (props) => {
    const [state, dispatch] = (0, administrativBulding_1.useAdministrativeBuildingReducer)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const getAdministrativeBuilding = async (id) => {
        dispatch({ type: 'SEND_ADMINISTRATIVE_BUILDING_REQUEST' });
        const administrativeBuilding = await index_1.AdministrativeBuildingsApi.getAdministrativeBuilding(id);
        dispatch({ type: 'DONE_ADMINISTRATIVE_BUILDING_REQUEST', payload: administrativeBuilding });
    };
    const createAdministrativeBuilding = async (values) => {
        const params = (0, helper_1.transformAdministrativeBuildingParams)(values);
        dispatch({ type: 'SEND_ADMINISTRATIVE_BUILDING_CREATE' });
        const administrativeBuilding = await index_1.AdministrativeBuildingsApi.createAdministrativeBuilding(params);
        dispatch({ type: 'DONE_ADMINISTRATIVE_BUILDING_CREATE', payload: administrativeBuilding });
        if (administrativeBuilding.data !== null) {
            navigate(`/references/addresses/administrative-buildings/${administrativeBuilding.data.id}/edit`);
        }
        return administrativeBuilding;
    };
    const updateAdministrativeBuilding = async (id, values) => {
        const params = (0, helper_1.transformAdministrativeBuildingParams)(values);
        dispatch({ type: 'SEND_ADMINISTRATIVE_BUILDING_UPDATE' });
        const administrativeBuilding = await index_1.AdministrativeBuildingsApi.updateAdministrativeBuilding(id, params);
        dispatch({ type: 'DONE_ADMINISTRATIVE_BUILDING_UPDATE', payload: administrativeBuilding });
        return administrativeBuilding;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getAdministrativeBuilding(props.id).then(); }, [props.id]);
    return {
        getAdministrativeBuilding,
        state,
        createAdministrativeBuilding,
        updateAdministrativeBuilding,
        closeErrorMessage
    };
};
exports.default = useAdministrativeBuildings;
