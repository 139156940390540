"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const attachStructuralUnitToAccount_1 = require("../store/attachStructuralUnitToAccount");
const types_1 = require("api/references/structuralUnitsUpgraded/types");
const index_1 = require("api/index");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const react_1 = require("react");
const useAttachAccountToStructuralUnit = (props) => {
    const [state, dispatch] = (0, attachStructuralUnitToAccount_1.useAttachStructuralUnitToAccountsReducer)();
    const [keyword, setKeyword] = (0, react_1.useState)('');
    const getStructuralUnits = async (params) => {
        dispatch({ type: 'SEND_GET_STRUCTURAL_UNITS_REQUEST' });
        const structuralUnitList = await index_1.StructuralUnitsUpgradedApi.getStructuralUnitsForAttaching({ ...params, perPage: 15, sort: '+sort,-id', filters: { ...params.filters, type: types_1.StructuralUnitType.STRUCTURAL_UNIT, sortByAccountId: props.accountId } });
        const attachedStructuralUnits = await index_1.StructuralUnitsUpgradedApi.getAttachedStructuralUnits({ filters: { type: types_1.StructuralUnitType.STRUCTURAL_UNIT, hrEmployeeAccountId: props.accountId } });
        dispatch({ type: 'DONE_GET_STRUCTURAL_UNITS_REQUEST', payload: {
                structuralUnitList,
                attachedStructuralUnits,
                meta: structuralUnitList.meta
            } });
    };
    const attachStructuralUnit = async (structuralUnitId) => {
        dispatch({ type: 'SEND_STRUCTURAL_UNIT_ATTACH_REQUEST', payload: structuralUnitId });
        const attachResponse = await index_1.StructuralUnitsUpgradedApi.attachOnlyStructuralUnit(structuralUnitId, { accountIds: [props.accountId] });
        if (attachResponse.errors !== null) {
            dispatch({ type: 'DONE_STRUCTURAL_UNIT_ATTACH_REQUEST', payload: { structuralUnitId } });
            return { errors: attachResponse.errors, status: attachResponse.status };
        }
        const attachedStructuralUnits = await index_1.StructuralUnitsUpgradedApi.getAttachedStructuralUnits({ filters: { type: types_1.StructuralUnitType.STRUCTURAL_UNIT, hrEmployeeAccountId: props.accountId } });
        dispatch({ type: 'DONE_STRUCTURAL_UNIT_ATTACH_REQUEST', payload: { structuralUnitId, attachedStructuralUnits } });
        return { errors: attachedStructuralUnits.errors, status: attachedStructuralUnits.status };
    };
    const detachStructuralUnit = async (structuralUnitId) => {
        dispatch({ type: 'SEND_STRUCTURAL_UNIT_DETACH_REQUEST', payload: structuralUnitId });
        const response = await index_1.StructuralUnitsUpgradedApi.detachStructuralUnit(structuralUnitId, { accountIds: [props.accountId] });
        dispatch({ type: 'DONE_STRUCTURAL_UNIT_DETACH_REQUEST', payload: { structuralUnitId, status: response.status } });
        return response;
    };
    const debouncedSearch = (value) => {
        setKeyword(value);
        (0, debouncedMethods_1.debouncedOnFunc)({ filters: { keyword: value } }, getStructuralUnits);
    };
    const navigatePagination = (page) => {
        getStructuralUnits({ page, filters: { keyword } }).then();
    };
    (0, react_1.useEffect)(() => {
        if (props.accountId)
            getStructuralUnits({ page: 1 }).then();
    }, [props.accountId]);
    return {
        state,
        attachStructuralUnit,
        detachStructuralUnit,
        debouncedSearch,
        navigatePagination,
        keyword
    };
};
exports.default = useAttachAccountToStructuralUnit;
