"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createCorrection = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/documents/orders/correction/parsers");
const createCorrection = async (id, params) => {
    const response = await Api.post('documents/administrative-orders/correction/' + id, params);
    return (0, parsers_1.parseCorrection)(response);
};
exports.createCorrection = createCorrection;
