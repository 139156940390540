"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCompany = void 0;
const index_1 = require("api/index");
const createOrEditCompany_1 = require("../store/createOrEditCompany");
const react_1 = require("react");
const queryString_1 = require("core/helpers/queryString");
const helpers_1 = require("../helpers");
const react_router_dom_1 = require("react-router-dom");
const useCompany = (props) => {
    const [deleteSignatoryAlert, setDeleteSignatoryAlert] = (0, react_1.useState)({ showAlert: false, id: null });
    const [listingParams, setListingParams] = (0, react_1.useState)('');
    const [state, dispatch] = (0, createOrEditCompany_1.useCompanyReducer)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const createCompanyAttributes = async (values) => {
        const params = (0, helpers_1.transformCompanyParams)(values);
        dispatch({ type: 'SEND_COMPANY_REQUEST' });
        const companyData = await index_1.CompaniesApi.createCompany(params);
        dispatch({ type: 'DONE_COMPANY_REQUEST', payload: companyData });
        if (companyData.data !== null && !props.openedCompanySidebar) {
            navigate(`/references/companies/${companyData.data.id}/edit`);
        }
        return companyData;
    };
    const getCompany = async (id) => {
        dispatch({ type: 'SEND_COMPANY_REQUEST' });
        const companyData = await index_1.CompaniesApi.getCompany(id);
        dispatch({ type: 'DONE_COMPANY_REQUEST', payload: companyData });
    };
    const addSignatory = async (values, companyId) => {
        const params = (0, helpers_1.transformCompanySignatoryParams)(values);
        dispatch({ type: 'SEND_ADD_COMPANY_SIGNATORY' });
        const companySignatoryData = await index_1.CompaniesApi.addSignatory(params, companyId);
        dispatch({ type: 'DONE_ADD_COMPANY_SIGNATORY', payload: companySignatoryData });
        return companySignatoryData;
    };
    const deleteSignatory = async (confirm) => {
        if (!confirm || !deleteSignatoryAlert.id)
            return setDeleteSignatoryAlert({ showAlert: false, id: null });
        setDeleteSignatoryAlert({ ...deleteSignatoryAlert, showAlert: false });
        dispatch({ type: 'SEND_DELETE_SIGNATORY' });
        const company = await index_1.CompaniesApi.deleteSignatory(deleteSignatoryAlert.id);
        dispatch({ type: 'DONE_DELETE_SIGNATORY', payload: { errors: company.errors } });
        setDeleteSignatoryAlert({ showAlert: false, id: null });
        if (props.companyId)
            getCompany(props.companyId).then();
    };
    const updateRequisiteStatus = async (id) => {
        dispatch({ type: 'SET_LOADING_ITEM', payload: id });
        const requisite = await index_1.CompaniesApi.updateRequisiteStatus(id);
        if (props.companyId)
            await getCompany(props.companyId);
        dispatch({ type: 'UN_SET_LOADING_ITEM', payload: id });
        return requisite;
    };
    const updateAddressStatus = async (id) => {
        dispatch({ type: 'SET_LOADING_ITEM', payload: id });
        const address = await index_1.CompaniesApi.updateAddressStatus(id);
        if (props.companyId)
            await getCompany(props.companyId);
        dispatch({ type: 'UN_SET_LOADING_ITEM', payload: id });
        return address;
    };
    const deleteAddress = async (id) => {
        dispatch({ type: 'SEND_DELETE_ADDRESS', payload: id });
        const response = await index_1.CompaniesApi.deleteAddress(id);
        if (props.companyId)
            await getCompany(props.companyId);
        dispatch({ type: 'DONE_DELETE_ADDRESS', payload: { id, response } });
        return response;
    };
    const addAddress = async (values, companyId) => {
        const params = (0, helpers_1.transformCompanyAddressParams)(values);
        dispatch({ type: 'SEND_ADD_COMPANY_ADDRESS' });
        const companyAddressData = await index_1.CompaniesApi.addAddress(params, companyId);
        dispatch({ type: 'DONE_ADD_COMPANY_ADDRESS', payload: companyAddressData });
        return companyAddressData;
    };
    const addBankAccount = async (values, companyId) => {
        const params = (0, helpers_1.transformCompanyBankAccountParams)(values);
        dispatch({ type: 'SEND_ADD_COMPANY_BANK_ACCOUNT' });
        const companyAccountData = await index_1.CompaniesApi.addBankAccount(params, companyId);
        dispatch({ type: 'DONE_ADD_COMPANY_BANK_ACCOUNT', payload: companyAccountData });
        return companyAccountData;
    };
    const updateCompanyAttributes = async (values, companyId) => {
        const params = (0, helpers_1.transformCompanyParams)(values);
        dispatch({ type: 'SEND_COMPANY_UPDATE' });
        const companyData = await index_1.CompaniesApi.updateCompany(params, companyId);
        dispatch({ type: 'DONE_COMPANY_UPDATE', payload: companyData });
        return companyData;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => {
        const params = (0, helpers_1.transformCompanyFilterParamsToUrl)((0, helpers_1.transformUrlToCompanyFilterParams)(location.search));
        const searchParams = (0, queryString_1.stringify)({ ...params });
        setListingParams(searchParams || '?');
        if (props.companyId)
            getCompany(props.companyId).then();
    }, [props.companyId]);
    return {
        createCompanyAttributes,
        updateCompanyAttributes,
        addSignatory,
        deleteSignatory,
        updateRequisiteStatus,
        deleteSignatoryAlert,
        setDeleteSignatoryAlert,
        addAddress,
        addBankAccount,
        state,
        closeErrorMessage,
        listingParams,
        updateAddressStatus,
        deleteAddress
    };
};
exports.useCompany = useCompany;
