"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateContract = exports.createContract = exports.getContract = exports.deleteContract = exports.getContracts = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/documents/human-resources/hr-contracts/parsers");
const getContracts = async (params) => {
    const response = await Api.get('documents/human-resources/contracts', params);
    return (0, parsers_1.parseContracts)(response);
};
exports.getContracts = getContracts;
const deleteContract = async (id) => {
    return await Api.deleteItem('documents/human-resources/contracts/' + id);
};
exports.deleteContract = deleteContract;
const getContract = async (id) => {
    const response = await Api.get('documents/human-resources/contracts/' + id);
    return (0, parsers_1.parseContract)(response);
};
exports.getContract = getContract;
const createContract = async (params) => {
    const response = await Api.post('documents/human-resources/contracts', params);
    return (0, parsers_1.parseContract)(response);
};
exports.createContract = createContract;
const updateContract = async (id, params) => {
    const response = await Api.patch('documents/human-resources/contracts/' + id, params);
    return (0, parsers_1.parseContract)(response);
};
exports.updateContract = updateContract;
