"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const react_1 = require("react");
const signContract_1 = require("../store/signContract");
const store_1 = require("core/store");
const queryString_1 = require("core/helpers/queryString");
const queryString_2 = require("core/helpers/queryString");
const helpers_1 = require("../helpers");
const react_router_dom_1 = require("react-router-dom");
const react_router_1 = require("react-router");
const useSignContract = (props) => {
    const location = (0, react_router_dom_1.useLocation)();
    const navigate = (0, react_router_1.useNavigate)();
    const [state, dispatch] = (0, signContract_1.useSignContractListReducer)();
    const [alert, setAlert] = (0, react_1.useState)(null);
    const [listingParams, setListingParams] = (0, react_1.useState)('');
    const [buttonNavigate, setButtonNavigate] = (0, react_1.useState)(signContract_1.initialContractNavigate);
    const [contractParamsIds, setContractParamsIds] = (0, react_1.useState)({ contractIds: [], meta: store_1.initialMeta });
    const getSingleContract = async (contractId) => {
        dispatch({ type: 'SEND_SINGLE_CONTRACT_REQUEST' });
        const response = await index_1.ContractSigningApi.getSingleContractSigning(contractId);
        dispatch({ type: 'DONE_SINGLE_CONTRACT_REQUEST', payload: response });
    };
    const signContract = async (signAlert) => {
        dispatch({ type: 'SEND_CONTRACT_SIGN_REQUEST' });
        const response = await index_1.ContractSigningApi.signContract(signAlert.contractId);
        if (signAlert.correction) {
            dispatch({ type: 'DONE_CORRECTION_SIGN_REQUEST', payload: response });
        }
        else {
            dispatch({ type: 'DONE_CONTRACT_SIGN_REQUEST', payload: response });
        }
        return response;
    };
    const changeActiveContract = (activeContractId) => {
        dispatch({ type: 'CHANGE_ACTIVE_CONTRACT', payload: activeContractId });
        const params = (0, queryString_1.parse)(location.search);
        params.activeId = activeContractId;
        navigate('/documents/human-resources/assign-contracts/' + props.contractId + '/edit/' + (0, queryString_2.stringify)(params));
    };
    const getContractIDs = async (value) => {
        const onlyMine = localStorage.getItem('contractOnlyMine') || undefined;
        const contractsParams = (0, helpers_1.transformContractSigningParamsToFilterParams)({ ...value, onlyMine }, props.contractId);
        const contracts = await index_1.ContractSigningApi.getContractsSigningList(contractsParams);
        if (contracts.data !== null && contracts.meta) {
            const responseIds = {
                contractIds: contracts.data.map(el => el.id), meta: contracts.meta
            };
            const searchParams = (0, queryString_2.stringify)({ ...(0, helpers_1.transformContractSigningFilterParamsToUrl)(value, props.contractId) });
            setListingParams(searchParams || '?');
            setContractParamsIds(responseIds);
            return responseIds;
        }
        return null;
    };
    const nextContract = async () => {
        setButtonNavigate(prevState => ({ ...prevState, next: true, isLoading: true }));
        const currentIndex = contractParamsIds.contractIds.indexOf(props.contractId);
        const nextContract = contractParamsIds.contractIds[currentIndex + 1];
        const { pagination } = contractParamsIds.meta;
        const params = ((0, helpers_1.transformContractSigningFilterParamsToUrl)((0, helpers_1.transformUrlToContractSigningFilterParams)(location.search), props.contractId));
        if (nextContract) {
            navigate('/documents/human-resources/assign-contracts/' + nextContract + '/edit/' + (0, queryString_2.stringify)({ ...params }));
            const lastElement = pagination.currentPage === pagination.totalPages && contractParamsIds.contractIds.length === currentIndex + 2;
            setButtonNavigate(prevState => ({ ...prevState, next: false, firstElement: false, lastElement, isLoading: false }));
        }
        else {
            if (pagination.currentPage === pagination.totalPages) {
                setButtonNavigate(prevState => ({ ...prevState, next: false, lastElement: true, isLoading: false }));
                return;
            }
            const formValues = (0, helpers_1.transformUrlToContractSigningFilterParams)(location.search);
            const response = await getContractIDs({ ...formValues, page: pagination.currentPage + 1 });
            if (response && response.contractIds[0]) {
                formValues.page = response.meta.pagination.currentPage;
                const urlParams = { ...(0, helpers_1.transformContractSigningFilterParamsToUrl)(formValues, props.contractId), page: response.meta.pagination.currentPage };
                navigate('/documents/human-resources/assign-contracts/' + response.contractIds[0] + '/edit/' + (0, queryString_2.stringify)(urlParams));
                const lastElement = response.meta.pagination.currentPage === response.meta.pagination.totalPages && response.contractIds.length === 1;
                setButtonNavigate(prevState => ({ ...prevState, next: false, firstElement: false, lastElement, isLoading: false }));
            }
        }
    };
    const previousContract = async () => {
        setButtonNavigate(prevState => ({ ...prevState, previous: true, isLoading: true }));
        const currentIndex = contractParamsIds.contractIds.indexOf(props.contractId);
        const prevContract = contractParamsIds.contractIds[currentIndex - 1];
        const { pagination } = contractParamsIds.meta;
        const params = ((0, helpers_1.transformContractSigningFilterParamsToUrl)((0, helpers_1.transformUrlToContractSigningFilterParams)(location.search), props.contractId));
        if (prevContract) {
            navigate('/documents/human-resources/assign-contracts/' + prevContract + '/edit/' + (0, queryString_2.stringify)({ ...params }));
            const firstElement = pagination.currentPage === 1 && currentIndex === 1;
            setButtonNavigate(prevState => ({ ...prevState, previous: false, lastElement: false, firstElement, isLoading: false }));
        }
        else {
            if (pagination.currentPage === 1) {
                setButtonNavigate(prevState => ({ ...prevState, previous: false, firstElement: true, isLoading: false }));
                return;
            }
            const formValues = (0, helpers_1.transformUrlToContractSigningFilterParams)(location.search);
            const response = await getContractIDs({ ...formValues, page: pagination.currentPage - 1 });
            if (response) {
                const previousContract = response.contractIds[response.contractIds.length - 1];
                if (previousContract) {
                    formValues.page = response.meta.pagination.currentPage;
                    const urlParams = { ...(0, helpers_1.transformContractSigningFilterParamsToUrl)(formValues, props.contractId), page: response.meta.pagination.currentPage };
                    navigate('/documents/human-resources/assign-contracts/' + previousContract + '/edit/' + (0, queryString_2.stringify)(urlParams));
                    const firstElement = response.meta.pagination.currentPage === 1 && currentIndex === 1;
                    setButtonNavigate(prevState => ({ ...prevState, previous: false, lastElement: false, firstElement, isLoading: false }));
                }
            }
        }
    };
    (0, react_1.useEffect)(() => {
        const params = (0, helpers_1.transformUrlToContractSigningFilterParams)(location.search);
        params.onlyMine = localStorage.getItem('contractOnlyMine') || undefined;
        getContractIDs(params).then(response => {
            if (response) {
                const { currentPage, totalPages } = response.meta.pagination;
                const contractIndex = response.contractIds.indexOf(props.contractId);
                if (contractIndex === 0 && currentPage === 1)
                    setButtonNavigate(prevState => ({ ...prevState, firstElement: true }));
                if (currentPage === totalPages && contractIndex === response.contractIds.length - 1) {
                    setButtonNavigate(prevState => ({ ...prevState, lastElement: true }));
                }
            }
        });
    }, []);
    const handleErrorClose = () => {
        dispatch({ type: 'CLOSE_ERROR' });
    };
    (0, react_1.useEffect)(() => {
        const params = (0, queryString_1.parse)(location.search);
        if (props.contractId)
            getSingleContract(props.contractId).then(() => params.activeId && changeActiveContract(params.activeId));
    }, [props.contractId]);
    return {
        state,
        alert,
        setAlert,
        signContract,
        handleErrorClose,
        listingParams,
        buttonNavigate,
        nextContract,
        previousContract,
        changeActiveContract
    };
};
exports.default = useSignContract;
