"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const procurementType_1 = require("../store/procurementType");
const api_1 = require("src/api");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const useProcurementType = (props) => {
    const [state, dispatch] = (0, procurementType_1.useProcurementTypeReducer)();
    // const navigate = useNavigate()
    const getProcurementType = async (id) => {
        dispatch({ type: 'SEND_PROCUREMENT_TYPE_REQUEST' });
        const procurementType = await api_1.ProcurementTypesApi.getProcurementType(id);
        dispatch({ type: 'DONE_PROCUREMENT_TYPE_REQUEST', payload: procurementType });
    };
    // const createProcurementType = async (values: ProcurementTypeFormValue) => {
    //   const params = transformProcurementTypeParams(values)
    //   if (params === null) {
    //     toast.error('something went wrong')
    //     return
    //   }
    //   dispatch({ type: 'SEND_PROCUREMENT_TYPE_CREATE' })
    //   const procurementType = await ProcurementTypesApi.createProcurementType(params)
    //   dispatch({ type: 'DONE_PROCUREMENT_TYPE_CREATE', payload:  procurementType })
    //   if (procurementType.data !== null) {
    //     toast.success('მონაცემები შეიქმნა წარმატებით')
    //     navigate(`/references/procurement-types/${procurementType.data.id}/edit`)
    //   }
    // }
    const updateProcurementType = async (id, values) => {
        const params = (0, helpers_1.transformProcurementTypeParams)(values);
        dispatch({ type: 'SEND_PROCUREMENT_TYPE_UPDATE' });
        const procurementType = await api_1.ProcurementTypesApi.updateProcurementType(id, params);
        dispatch({ type: 'DONE_PROCUREMENT_TYPE_UPDATE', payload: procurementType });
        return procurementType;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getProcurementType(props.id).then(); }, [props.id]);
    return {
        state,
        // createProcurementType,
        updateProcurementType,
        getProcurementType,
        closeErrorMessage
    };
};
exports.default = useProcurementType;
