"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useExpirationDateHistory = void 0;
const addEditSubmission_1 = require("../store/addEditSubmission");
const index_1 = require("api/index");
const react_1 = require("react");
const useExpirationDateHistory = (props) => {
    const [state, dispatch] = (0, addEditSubmission_1.useAddEditSubmissionReducer)();
    const expirationDateHistory = async (id) => {
        dispatch({ type: 'SEND_EXPIRATION_DATE_HISTORY_REQUEST' });
        const submission = await index_1.SubmissionsApi.expirationDateHistory(id);
        dispatch({ type: 'DONE_EXPIRATION_DATE_HISTORY_REQUEST', payload: submission });
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.submissionId)
        expirationDateHistory(props.submissionId).then(); }, [props.submissionId]);
    return { state, closeErrorMessage };
};
exports.useExpirationDateHistory = useExpirationDateHistory;
