"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseWarehousesForSelect = exports.parseWarehouse = exports.parseWarehouses = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseWarehouses = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const warehouses = response.content;
    try {
        return {
            data: warehouses.data.map(el => {
                return {
                    id: el.id,
                    moduleType: el.type,
                    address: el.attributes.address,
                    city: el.attributes.city,
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    createdDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt),
                    updatedDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.updatedAt)
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(warehouses.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseWarehouses = parseWarehouses;
const parseWarehouse = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const warehouse = response.content;
    try {
        return {
            data: {
                id: warehouse.data.id,
                moduleType: warehouse.data.type,
                address: warehouse.data.attributes.address,
                city: warehouse.data.attributes.city,
                createdAt: (0, parsers_1.transformDate)(warehouse.data.attributes.createdAt),
                updatedAt: (0, parsers_1.transformDate)(warehouse.data.attributes.updatedAt)
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseWarehouse = parseWarehouse;
const parseWarehousesForSelect = (response) => {
    if (response.content === null)
        return [];
    const warehouses = response.content;
    try {
        return warehouses.data.map(el => ({
            id: el.id,
            label: el.attributes.city
        }));
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return [];
    }
};
exports.parseWarehousesForSelect = parseWarehousesForSelect;
