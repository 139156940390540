"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.languageLevel = exports.parseLanguages = void 0;
const types_1 = require("api/references/employees/types");
const helper_1 = require("api/helper");
const parseLanguages = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const languages = response.content;
    try {
        return {
            data: languages.data.map(language => {
                return {
                    id: language.id,
                    language: {
                        id: language.relationships.language.data.id,
                        label: language.relationships.language.data.attributes.nameKa
                            ?? language.relationships.language.data.attributes.nameEn
                    },
                    level: exports.languageLevel.find(level => level.id === String(language.attributes.level))
                };
            }),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseLanguages = parseLanguages;
exports.languageLevel = [
    { id: String(types_1.LanguageLevel.A1), label: 'A1' },
    { id: String(types_1.LanguageLevel.A2), label: 'A2' },
    { id: String(types_1.LanguageLevel.B1), label: 'B1' },
    { id: String(types_1.LanguageLevel.B2), label: 'B2' },
    { id: String(types_1.LanguageLevel.C1), label: 'C1' },
    { id: String(types_1.LanguageLevel.C2), label: 'C2' },
    { id: String(types_1.LanguageLevel.NATIVE), label: 'მშობლიური' }
];
