"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useChooseSubject = void 0;
const react_1 = require("react");
const api_1 = require("../../../../../api");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helper_1 = require("../../../../common/helper");
const useChooseSubject = () => {
    const [subjects, setSubjects] = (0, react_1.useState)(null);
    const [loading, setLoading] = (0, react_1.useState)(false);
    const [creatingSubject, setCreatingSubject] = (0, react_1.useState)(false);
    const [subjectFilters, setSubjectFilters] = (0, react_1.useState)({});
    const [metaData, setMetaData] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        setLoading(true);
        api_1.PurchaseSubjectsApi.getPurchaseSubjectCollection({}).then(response => {
            setSubjects(response.data);
            setMetaData(response.meta || null);
            setLoading(false);
        });
    }, []);
    const createSubject = () => {
        if (!subjectFilters.keyword)
            return;
        setCreatingSubject(true);
        const params = {
            itemTitle: subjectFilters.keyword
        };
        api_1.PurchaseSubjectsApi.createUnverifiedPurchaseSubject(params).then(() => {
            setCreatingSubject(false);
            setSubjectFilters({});
            searchSubject({});
        });
    };
    const searchByKeyword = async (filters) => {
        setSubjectFilters(() => filters);
        (0, helper_1.debouncedOnFunc)({ filters }, searchSubject);
    };
    const searchSubject = (params) => {
        setLoading(true);
        api_1.PurchaseSubjectsApi.getPurchaseSubjectCollection(params).then(response => {
            setSubjects(response.data);
            setMetaData(response.meta || null);
            setLoading(false);
        });
    };
    const navigatePagination = (page) => {
        searchSubject({ filters: { keyword: subjectFilters.keyword }, page });
    };
    return {
        loading,
        metaData,
        navigatePagination,
        setLoading,
        subjects,
        subjectFilters,
        searchByKeyword,
        createSubject,
        creatingSubject,
        debouncedSubjectSpecifications: api_1.PurchasesSpecificationsApi.debouncedSubjectSpecifications,
        debouncedCategories: debouncedMethods_1.debouncedCategories
    };
};
exports.useChooseSubject = useChooseSubject;
