"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteVacation = exports.updateVacation = exports.createVacation = exports.getVacation = exports.getVacations = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/documents/human-resources/vacations/parsers");
const getVacations = async (params) => {
    const response = await Api.get('documents/human-resources/vacations', params);
    return (0, parsers_1.parseVacations)(response);
};
exports.getVacations = getVacations;
const getVacation = async (id) => {
    const response = await Api.get('documents/human-resources/vacations/' + id);
    return (0, parsers_1.parseVacation)(response);
};
exports.getVacation = getVacation;
const createVacation = async (params) => {
    const response = await Api.post('documents/human-resources/vacations', params);
    return (0, parsers_1.parseVacation)(response);
};
exports.createVacation = createVacation;
const updateVacation = async (id, params) => {
    const response = await Api.patch('documents/human-resources/vacations/' + id, params);
    return (0, parsers_1.parseVacation)(response);
};
exports.updateVacation = updateVacation;
const deleteVacation = async (id) => {
    return await Api.deleteItem(`documents/human-resources/vacations/${id}`);
};
exports.deleteVacation = deleteVacation;
