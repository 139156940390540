"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContractTypesForSelect = exports.updateContractType = exports.createContractType = exports.getContractType = exports.deleteContractTypes = exports.getContractTypes = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/references/humanResources/contractTypes/parsers");
const getContractTypes = async (params) => {
    const response = await Api.get('documents/human-resources/assign/contract-types', params);
    return (0, parsers_1.parseContractTypes)(response);
};
exports.getContractTypes = getContractTypes;
const deleteContractTypes = async (id) => {
    return await Api.deleteItem('documents/human-resources/assign/contract-types/' + id);
};
exports.deleteContractTypes = deleteContractTypes;
const getContractType = async (id) => {
    const response = await Api.get('documents/human-resources/assign/contract-types/' + id);
    return (0, parsers_1.parseContractType)(response);
};
exports.getContractType = getContractType;
const createContractType = async (params) => {
    const response = await Api.post('documents/human-resources/assign/contract-types', params);
    return (0, parsers_1.parseContractType)(response);
};
exports.createContractType = createContractType;
const updateContractType = async (id, params) => {
    const response = await Api.patch('documents/human-resources/assign/contract-types/' + id, params);
    return (0, parsers_1.parseContractType)(response);
};
exports.updateContractType = updateContractType;
const getContractTypesForSelect = async (params) => {
    const response = await Api.get('documents/human-resources/assign/contract-types', params);
    return (0, parsers_1.parseContractTypesForSelect)(response);
};
exports.getContractTypesForSelect = getContractTypesForSelect;
