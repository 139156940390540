"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGeneralHook = void 0;
const react_1 = require("react");
const useGeneralHook = (props) => {
    (0, react_1.useEffect)(() => {
        if (props.title)
            document.title = props.loading ? 'იტვირთება ...' : props.title;
    }, [props.loading]);
    (0, react_1.useEffect)(() => {
        if (props.scrollToTop)
            window.scrollTo(0, 0);
    }, []);
};
exports.useGeneralHook = useGeneralHook;
