"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const index_1 = require("api/index");
const workDirections_1 = require("../store/workDirections");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helpers_1 = require("../helpers");
const queryString_1 = require("core/helpers/queryString");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useWorkDirections = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, workDirections_1.useWorkDirectionsReducer)();
    const [keyword, setKeyword] = (0, react_1.useState)('');
    const [showDeleteAlert, setShowDeleteAlert] = (0, react_1.useState)(false);
    const [toDeleteId, setToDeleteId] = (0, react_1.useState)(null);
    const isMounted = (0, react_1.useRef)(false);
    const navigatePagination = (page) => {
        const params = (0, queryString_1.parse)(location.search);
        getWorkDirections({ ...params, page }).then();
    };
    const selectPerPage = (perPage) => {
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'CHANGE_PER_PAGE', payload: perPage });
        getWorkDirections({ ...params, perPage, page: undefined }).then();
    };
    const deleteAlert = (id) => {
        setShowDeleteAlert(true);
        setToDeleteId(id);
    };
    const debouncedSearch = (value) => {
        setKeyword(value);
        const searchParams = (0, queryString_1.parse)(location.search);
        const params = value
            ? { perPage: searchParams.perPage, filters: { keyword: value } }
            : { page: searchParams.page };
        (0, debouncedMethods_1.debouncedOnFunc)(params, getWorkDirections);
    };
    const getWorkDirections = async (params) => {
        dispatch({ type: 'SEND_WORK_DIRECTIONS_REQUEST' });
        const workDirections = await index_1.WorkDirectionApi.getWorkDirections(params);
        if (isMounted?.current) {
            navigate('/references/human-resources/work-directions' + (0, queryString_1.stringify)(params));
            dispatch({ type: 'DONE_WORK_DIRECTIONS_REQUEST', payload: workDirections });
        }
    };
    const deleteWorkDirection = async (confirm) => {
        setShowDeleteAlert(false);
        if (!toDeleteId || !confirm)
            return setToDeleteId(null);
        dispatch({ type: 'SEND_WORK_DIRECTION_DELETE_REQUEST' });
        const workDirections = await index_1.WorkDirectionApi.deleteItem(toDeleteId);
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'DONE_WORK_DIRECTION_DELETE_REQUEST', payload: { errors: workDirections.errors } });
        if (workDirections.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            getWorkDirections(params).then();
        setToDeleteId(null);
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        const params = (0, helpers_1.getParamsFromUrl)(location.search);
        if (params.perPage)
            dispatch({ type: 'CHANGE_PER_PAGE', payload: params.perPage });
        setKeyword(params.filters?.keyword || '');
        getWorkDirections(params).then();
        return () => { isMounted.current = false; };
    }, []);
    return {
        state,
        navigatePagination,
        selectPerPage,
        debouncedSearch,
        deleteAlert,
        deleteWorkDirection,
        toDeleteId,
        showDeleteAlert,
        keyword,
        getWorkDirections
    };
};
exports.default = useWorkDirections;
