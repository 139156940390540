"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const banks_1 = require("../store/banks");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const queryString_1 = require("core/helpers/queryString");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const react_router_1 = require("react-router");
const useBanks = () => {
    const navigate = (0, react_router_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, banks_1.useBanksReducer)();
    const [showDeleteAlert, setShowDeleteAlert] = (0, react_1.useState)(false);
    const [toDeleteId, setToDeleteId] = (0, react_1.useState)(null);
    const [showFilter, setShowFilter] = (0, react_1.useState)(false);
    const [linkParams, setLinkParams] = (0, react_1.useState)('');
    const [bankId, setBankId] = (0, react_1.useState)(null);
    const isMounted = (0, react_1.useRef)(false);
    const navigatePagination = (page, values) => {
        getBanks({ ...values, page }).then();
    };
    const filter = (name, value, filterValues) => {
        getBanks({ ...filterValues, [name]: value, page: 1 }).then();
    };
    const selectPerPage = (perPage, values) => {
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'CHANGE_PER_PAGE', payload: { ...values, perPage } });
        getBanks({ ...params, perPage, page: undefined }).then();
    };
    const deleteAlert = (id) => {
        setShowDeleteAlert(true);
        setToDeleteId(id);
    };
    const debouncedSearch = (name, value, filterValues) => {
        (0, debouncedMethods_1.debouncedOnFunc)({ ...filterValues, [name]: value, page: 1 }, getBanks);
    };
    const deleteBank = async (confirm) => {
        setShowDeleteAlert(false);
        if (!toDeleteId || !confirm)
            return setToDeleteId(null);
        dispatch({ type: 'SEND_BANK_DELETE_REQUEST' });
        const banks = await index_1.BankApi.deleteItem(toDeleteId);
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'DONE_BANK_DELETE_REQUEST', payload: { errors: banks.errors } });
        if (banks.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            getBanks(params).then();
        setToDeleteId(null);
    };
    const getBanks = async (values) => {
        dispatch({ type: 'SEND_BANKS_REQUEST' });
        const filterParams = (0, helpers_1.transformBankParamsToFilterParams)(values);
        const banks = await index_1.BankApi.getBanks(filterParams);
        if (banks.data !== null)
            setLinkParams((0, queryString_1.stringify)((0, helpers_1.transformBankFilterParamsToUrl)(values)));
        if (isMounted?.current) {
            dispatch({ type: 'DONE_BANKS_REQUEST', payload: banks });
            navigate('/references/banks' + (0, queryString_1.stringify)((0, helpers_1.transformBankFilterParamsToUrl)(values)), { replace: true });
        }
    };
    const initializeFilterForm = async () => {
        const params = (0, helpers_1.transformUrlToBankFilterParams)(location.search);
        const isAnyFilterValue = Object.entries(params).some(([key, value]) => {
            if (key === 'page' || key === 'perPage')
                return false;
            return !!value;
        });
        if (isAnyFilterValue)
            setShowFilter(true);
        const bankListId = (0, queryString_1.parse)(location.search).bankId;
        getBanks(params).then(() => dispatch({ type: 'INITIALIZE_FILTER_FORM', payload: params }));
        setBankId(bankListId);
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        initializeFilterForm().then();
        return () => { isMounted.current = false; };
    }, []);
    return {
        state,
        navigatePagination,
        selectPerPage,
        debouncedSearch,
        linkParams,
        filter,
        deleteAlert,
        deleteBank,
        toDeleteId,
        showDeleteAlert,
        showFilter,
        setShowFilter,
        getBanks,
        bankId
    };
};
exports.default = useBanks;
