"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseSpecificationForSelect = exports.parseSpecificationsForSelect = exports.parseInventorySpecification = exports.parseInventorySpecifications = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseInventorySpecifications = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const specifications = response.content;
    try {
        return {
            data: specifications.data.map(el => {
                return {
                    id: el.id,
                    name: el.attributes.specification,
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    createdDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt),
                    updatedDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.updatedAt)
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(specifications.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseInventorySpecifications = parseInventorySpecifications;
const parseInventorySpecification = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const specification = response.content;
    try {
        return {
            data: {
                id: specification.data.id,
                name: specification.data.attributes.specification
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseInventorySpecification = parseInventorySpecification;
const parseSpecificationsForSelect = async (data) => {
    if (data === null)
        return [];
    return data.map(el => {
        return {
            id: el.id,
            label: el.name
        };
    });
};
exports.parseSpecificationsForSelect = parseSpecificationsForSelect;
const parseSpecificationForSelect = async (response) => {
    const responseSpecification = response.data;
    return {
        id: responseSpecification.id,
        label: responseSpecification.attributes.specification
    };
};
exports.parseSpecificationForSelect = parseSpecificationForSelect;
