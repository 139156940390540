"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseLegalBasicsForSelect = exports.parseLegalBasis = exports.parseLegalBasics = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseLegalBasics = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const legalBasics = response.content;
    try {
        return {
            data: legalBasics.data.map(el => {
                return {
                    id: el.id,
                    text: el.attributes.text,
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    createdDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt),
                    updatedDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.updatedAt)
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(legalBasics.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseLegalBasics = parseLegalBasics;
const parseLegalBasis = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const legalBasis = response.content;
    try {
        return {
            data: {
                id: legalBasis.data.id,
                text: legalBasis.data.attributes.text
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseLegalBasis = parseLegalBasis;
const parseLegalBasicsForSelect = (response) => {
    if (response.content === null)
        return [];
    const districts = response.content;
    try {
        return districts.data.map(el => {
            return {
                id: el.id,
                label: el.attributes.text
            };
        });
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return [];
    }
};
exports.parseLegalBasicsForSelect = parseLegalBasicsForSelect;
