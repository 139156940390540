"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const useHRSubmissionVerifies_1 = require("../store/useHRSubmissionVerifies");
const index_1 = require("api/index");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const queryString_1 = require("core/helpers/queryString");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useHumanResourceSubmissionVerifies = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, useHRSubmissionVerifies_1.useHumanResourceSubmissionVerifiesReducer)();
    const [linkParams, setLinkParams] = (0, react_1.useState)('');
    const [showFilter, setShowFilter] = (0, react_1.useState)(false);
    const [assignSubmissionVerifyId, setAssignSubmissionVerifyId] = (0, react_1.useState)(null);
    const isMounted = (0, react_1.useRef)(false);
    const navigatePagination = (page, filterValues) => {
        getHumanResourceSubmissionVerifies({ ...filterValues, page }).then();
    };
    const selectPerPage = (perPage, values) => {
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'CHANGE_PER_PAGE', payload: { ...values, perPage } });
        getHumanResourceSubmissionVerifies({ ...params, perPage, page: undefined }).then();
    };
    const filter = (name, value, filterValues) => {
        getHumanResourceSubmissionVerifies({ ...filterValues, [name]: value, page: 1 }).then();
    };
    const debouncedSearch = (name, value, filterValues) => {
        (0, debouncedMethods_1.debouncedOnFunc)({ ...filterValues, [name]: value, page: 1 }, getHumanResourceSubmissionVerifies);
    };
    const getHumanResourceSubmissionVerifies = async (values) => {
        dispatch({ type: 'SEND_HUMAN_RESOURCE_SUBMISSION_VERIFIES_REQUEST' });
        const filterParams = (0, helpers_1.transformAssignSubmissionVerifyParamsToFilterParams)(values);
        const humanResourceSubmissionVerifies = await index_1.HumanResourcesSubmissionVerifiesApi.getHumanResourceSubmissionVerifies(filterParams);
        if (humanResourceSubmissionVerifies.data !== null)
            setLinkParams((0, queryString_1.stringify)((0, helpers_1.transformAssignSubmissionVerifyFilterParamsToUrl)(values)));
        if (isMounted?.current) {
            dispatch({ type: 'DONE_HUMAN_RESOURCE_SUBMISSION_VERIFIES_REQUEST', payload: humanResourceSubmissionVerifies });
            navigate('/documents/human-resources/assign-submission-verifies' + (0, queryString_1.stringify)((0, helpers_1.transformAssignSubmissionVerifyFilterParamsToUrl)(values)), { replace: true });
        }
    };
    const initializeFilterForm = async () => {
        const params = (0, helpers_1.transformUrlToAssignSubmissionVerifyFilterParams)(location.search);
        const isAnyFilterValue = Object.entries(params).some(([key, value]) => {
            if (key === 'page' || key === 'perPage')
                return false;
            return !!value;
        });
        if (isAnyFilterValue)
            setShowFilter(true);
        dispatch({ type: 'INITIALIZE_FILTER_FORM', payload: params });
        const assignSubmissionVerifyListId = (0, queryString_1.parse)(location.search).assignSubmissionVerifyId;
        getHumanResourceSubmissionVerifies(params).then(() => dispatch({ type: 'INITIALIZE_FILTER_FORM', payload: params }));
        setAssignSubmissionVerifyId(assignSubmissionVerifyListId);
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        initializeFilterForm().then();
        return () => { isMounted.current = false; };
    }, []);
    return {
        state,
        filter,
        getHumanResourceSubmissionVerifies,
        debouncedSearch,
        selectPerPage,
        navigatePagination,
        linkParams,
        showFilter,
        setShowFilter,
        assignSubmissionVerifyId
    };
};
exports.default = useHumanResourceSubmissionVerifies;
