"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSubmissionsReducer = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
const initialFilterForm = {
    keyword: '',
    structuralUnit: null,
    position: null,
    employee: null,
    status: null,
    fundingSourceId: null,
    createdBy: null
};
const initialSubmissions = {
    data: [],
    status: null,
    itemStatuses: [],
    meta: store_1.initialMeta,
    errors: null,
    filterFormValue: initialFilterForm,
    isFetching: true,
    loadingItemId: null,
    isDeleting: false
};
const useSubmissionsReducer = () => {
    return (0, react_1.useReducer)(SubmissionsReducer, initialSubmissions);
};
exports.useSubmissionsReducer = useSubmissionsReducer;
const SubmissionsReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_SUBMISSIONS_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_SUBMISSIONS_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                status: action.payload.status,
                errors: action.payload.errors,
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'SEND_SUBMISSIONS_ITEMS_STATUS_REQUEST':
            return {
                ...state,
                loadingItemId: action.payload,
                itemStatuses: []
            };
        case 'DONE_SUBMISSIONS_ITEMS_STATUS_REQUEST':
            return {
                ...state,
                errors: action.payload.errors,
                status: action.payload.status,
                itemStatuses: action.payload.data || [],
                loadingItemId: null
            };
        case 'SEND_DELETE_REQUEST':
            return {
                ...state,
                isDeleting: true
            };
        case 'DONE_DELETE_REQUEST':
            return {
                ...state,
                errors: action.payload.errors,
                isDeleting: false
            };
        case 'INITIALIZE_FILTER_FORM':
            return {
                ...state,
                filterFormValue: action.payload
            };
        default:
            return state;
    }
};
