"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDistrictsForSelect = exports.updateDistrict = exports.createDistrict = exports.getDistrict = exports.getDistricts = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("../../../privateRequest"));
const parsers_1 = require("api/references/addresses/districts/parsers");
const react_toastify_1 = require("react-toastify");
const getDistricts = async (params) => {
    const response = await Api.get('references/geography/districts', params);
    return (0, parsers_1.parseDistricts)(response);
};
exports.getDistricts = getDistricts;
const getDistrict = async (id) => {
    const response = await Api.get('references/geography/districts/' + id);
    return (0, parsers_1.parseDistrict)(response);
};
exports.getDistrict = getDistrict;
const createDistrict = async (params) => {
    const response = await Api.post('references/geography/districts', params);
    return (0, parsers_1.parseDistrict)(response);
};
exports.createDistrict = createDistrict;
const updateDistrict = async (id, params) => {
    const response = await Api.patch('references/geography/districts/' + id, params);
    return (0, parsers_1.parseDistrict)(response);
};
exports.updateDistrict = updateDistrict;
const getDistrictsForSelect = async (params) => {
    const response = await Api.get('references/geography/districts', params);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, parsers_1.parseDistrictsForSelect)(response);
};
exports.getDistrictsForSelect = getDistrictsForSelect;
