"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const index_1 = require("api/index");
const legalBasics_1 = require("../store/legalBasics");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helpers_1 = require("../helpers");
const queryString_1 = require("core/helpers/queryString");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useLegalBasics = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, legalBasics_1.useLegalBasicsReducer)();
    const [keyword, setKeyword] = (0, react_1.useState)('');
    const [showDeleteAlert, setShowDeleteAlert] = (0, react_1.useState)(false);
    const [toDeleteId, setToDeleteId] = (0, react_1.useState)(null);
    const isMounted = (0, react_1.useRef)(false);
    const getLegalBasics = async (params) => {
        dispatch({ type: 'SEND_LEGAL_BASICS_REQUEST' });
        const legalBasics = await index_1.LegalBasicsApi.getLegalBasics(params);
        if (isMounted?.current) {
            navigate('/references/legal-basics' + (0, queryString_1.stringify)(params), { replace: true });
            dispatch({ type: 'DONE_LEGAL_BASICS_REQUEST', payload: legalBasics });
        }
    };
    const navigatePagination = (page) => {
        const params = (0, queryString_1.parse)(location.search);
        getLegalBasics({ ...params, page }).then();
    };
    const selectPerPage = (perPage) => {
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'CHANGE_PER_PAGE', payload: perPage });
        getLegalBasics({ ...params, perPage, page: undefined }).then();
    };
    const deleteAlert = (id) => {
        setShowDeleteAlert(true);
        setToDeleteId(id);
    };
    const debouncedSearch = (value) => {
        setKeyword(value);
        const searchParams = (0, queryString_1.parse)(location.search);
        const params = value
            ? { perPage: searchParams.perPage, filters: { keyword: value } }
            : { page: searchParams.page };
        (0, debouncedMethods_1.debouncedOnFunc)(params, getLegalBasics);
    };
    const deleteLegalBasis = async (confirm) => {
        setShowDeleteAlert(false);
        if (!toDeleteId || !confirm)
            return setToDeleteId(null);
        dispatch({ type: 'SEND_LEGAL_BASIS_DELETE_REQUEST' });
        const legalBasics = await index_1.LegalBasicsApi.deleteItem(toDeleteId);
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'DONE_LEGAL_BASIS_DELETE_REQUEST', payload: { errors: legalBasics.errors } });
        if (legalBasics.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            getLegalBasics(params).then();
        setToDeleteId(null);
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        const params = (0, helpers_1.getParamsFromUrl)(location.search);
        if (params.perPage)
            dispatch({ type: 'CHANGE_PER_PAGE', payload: params.perPage });
        setKeyword(params.filters?.keyword || '');
        getLegalBasics(params).then();
        return () => {
            isMounted.current = false;
        };
    }, []);
    return {
        state,
        navigatePagination,
        selectPerPage,
        deleteAlert,
        debouncedSearch,
        getLegalBasics,
        deleteLegalBasis,
        keyword,
        showDeleteAlert,
        toDeleteId
    };
};
exports.default = useLegalBasics;
