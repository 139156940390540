"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWarrantyTerms = void 0;
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const index_1 = require("api/index");
const warrantyTerms_1 = require("../store/warrantyTerms");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helpers_1 = require("../helpers");
const queryString_1 = require("core/helpers/queryString");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useWarrantyTerms = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, warrantyTerms_1.useWarrantyTermsReducer)();
    const [keyword, setKeyword] = (0, react_1.useState)('');
    const [toDeleteId, setToDeleteId] = (0, react_1.useState)(null);
    const [showDeleteAlert, setShowDeleteAlert] = (0, react_1.useState)(false);
    const isMounted = (0, react_1.useRef)(false);
    const navigatePagination = (page) => {
        const params = (0, queryString_1.parse)(location.search);
        getWarrantyTerms({ ...params, page }).then();
    };
    const selectPerPage = (perPage) => {
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'CHANGE_PER_PAGE', payload: perPage });
        getWarrantyTerms({ ...params, perPage, page: undefined }).then();
    };
    const deleteAlert = (id) => {
        setShowDeleteAlert(true);
        setToDeleteId(id);
    };
    const debouncedSearch = (value) => {
        setKeyword(value);
        const searchParams = (0, queryString_1.parse)(location.search);
        const params = value
            ? { perPage: searchParams.perPage, filters: { keyword: value } }
            : { page: searchParams.page };
        (0, debouncedMethods_1.debouncedOnFunc)(params, getWarrantyTerms);
    };
    const deleteWarrantyTerm = async (confirm) => {
        setShowDeleteAlert(false);
        if (!toDeleteId || !confirm)
            return setToDeleteId(null);
        dispatch({ type: 'SEND_DELETE_REQUEST' });
        const warrantyTerm = await index_1.WarrantyTermsApi.deleteWarrantyTerm(toDeleteId);
        dispatch({ type: 'DONE_DELETE_REQUEST', payload: { errors: warrantyTerm.errors } });
        const params = (location.search);
        if (warrantyTerm.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            getWarrantyTerms(params).then();
        setToDeleteId(null);
    };
    const getWarrantyTerms = async (params) => {
        dispatch({ type: 'SEND_WARRANTY_TERMS_REQUEST' });
        const warrantyTerms = await index_1.WarrantyTermsApi.getWarrantyTerms(params);
        if (isMounted?.current) {
            navigate('/references/warranty-terms' + (0, queryString_1.stringify)(params), { replace: true });
            dispatch({ type: 'DONE_WARRANTY_TERMS_REQUEST', payload: warrantyTerms });
        }
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        const params = (0, helpers_1.getParamsFromUrl)(location.search);
        if (params.perPage)
            dispatch({ type: 'CHANGE_PER_PAGE', payload: params.perPage });
        setKeyword(params.filters?.keyword || '');
        getWarrantyTerms(params).then();
        return () => { isMounted.current = false; };
    }, []);
    return {
        state,
        navigatePagination,
        selectPerPage,
        deleteWarrantyTerm,
        debouncedSearch,
        deleteAlert,
        toDeleteId,
        showDeleteAlert,
        keyword,
        getWarrantyTerms
    };
};
exports.useWarrantyTerms = useWarrantyTerms;
