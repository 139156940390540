"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HRAssignSubmissionReducer = exports.useHRAssignSubmissionReducer = exports.initialAssignSubmissionNavigate = void 0;
const react_1 = require("react");
const types_1 = require("api/documents/human-resources/assign-submissions/types");
const initialHRAssignSubmissionState = {
    data: {
        id: '',
        number: '',
        addressees: [{ id: '', label: '', addresseeStructuralUnit: null }],
        disabledStatus: false,
        submissionAuthor: null,
        authorStructuralUnit: null,
        files: [],
        contactPersonEmail: null,
        contactPerson: null,
        purpose: '',
        phoneNumber: null,
        createdAt: '',
        creator: null,
        edocNumber: '',
        dateOfConclusion: '',
        status: types_1.HRAssignSubmissionStatuses.DRAFT,
        sentDate: null,
        sentBy: null,
        requestRegistrationDate: null,
        visualStatus: { name: 'პროექტი', color: 'secondary' },
        visualSentBy: null,
        declineComment: null,
        itemsCount: 0,
        usedItemsCount: 0
    },
    errors: null,
    isCreating: false,
    isFetching: false,
    isUpdating: false,
    isLoading: false,
    isSending: false,
    isDeclining: false,
    isCreatingVerifySubmission: false
};
exports.initialAssignSubmissionNavigate = {
    next: false,
    previous: false,
    isLoading: false,
    lastElement: false,
    firstElement: false
};
const useHRAssignSubmissionReducer = () => {
    return (0, react_1.useReducer)(exports.HRAssignSubmissionReducer, initialHRAssignSubmissionState);
};
exports.useHRAssignSubmissionReducer = useHRAssignSubmissionReducer;
const HRAssignSubmissionReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_HR_SUBMISSION_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_HR_SUBMISSION_REQUEST':
            return {
                ...state,
                data: action.payload.data || initialHRAssignSubmissionState.data,
                errors: action.payload.errors,
                isFetching: false,
                isLoading: state.isCreating
                    || state.isUpdating
                    || state.isSending
                    || state.isCreatingVerifySubmission
                    || state.isDeclining
            };
        case 'SEND_HR_SUBMISSION_UPDATE':
            return {
                ...state,
                errors: null,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_HR_SUBMISSION_UPDATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                isUpdating: false,
                isLoading: state.isCreating
                    || state.isFetching
                    || state.isSending
                    || state.isCreatingVerifySubmission
                    || state.isDeclining
            };
        case 'SEND_CREATE_HR_SUBMISSION':
            return {
                ...state,
                errors: null,
                isCreating: true,
                isLoading: true
            };
        case 'DONE_CREATE_HR_SUBMISSION':
            return {
                ...state,
                errors: action.payload.errors,
                data: action.payload.data || state.data,
                isCreating: false,
                isLoading: state.isFetching
                    || state.isUpdating
                    || state.isSending
                    || state.isCreatingVerifySubmission
                    || state.isDeclining
            };
        case 'SEND_HR_SUBMISSION_SEND_REQUEST':
            return {
                ...state,
                isSending: true,
                isLoading: true
            };
        case 'DONE_HR_SUBMISSION_SEND_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                isSending: false,
                isLoading: state.isCreating
                    || state.isFetching
                    || state.isUpdating
                    || state.isCreatingVerifySubmission
                    || state.isDeclining
            };
        case 'SEND_HR_SUBMISSION_DECLINE_REQUEST':
            return {
                ...state,
                errors: null,
                isDeclining: true,
                isLoading: true
            };
        case 'DONE_HR_SUBMISSION_DECLINE_REQUEST':
            return {
                ...state,
                data: action.payload.data || initialHRAssignSubmissionState.data,
                errors: action.payload.errors,
                isDeclining: false,
                isLoading: state.isCreating
                    || state.isUpdating
                    || state.isSending
                    || state.isCreatingVerifySubmission
                    || state.isFetching
            };
        case 'SEND_PROGRESS_IN_VERIFY_REQUEST':
            return {
                ...state,
                errors: null,
                isCreatingVerifySubmission: true,
                isLoading: true
            };
        case 'DONE_PROGRESS_IN_VERIFY_REQUEST':
            return {
                ...state,
                data: action.payload.data || initialHRAssignSubmissionState.data,
                errors: action.payload.errors,
                isCreatingVerifySubmission: false,
                isLoading: state.isCreating
                    || state.isUpdating
                    || state.isSending
                    || state.isDeclining
                    || state.isFetching
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.HRAssignSubmissionReducer = HRAssignSubmissionReducer;
