"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PositionTypeFormValidation = void 0;
const validates_1 = require("core/helpers/validates");
const PositionTypeFormValidation = (values) => {
    const formErrors = {
        name: (0, validates_1.required)(values.name)
    };
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.PositionTypeFormValidation = PositionTypeFormValidation;
