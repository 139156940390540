"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const date_fns_1 = require("date-fns");
const react_1 = require("react");
const AgeCalculator = (props) => {
    const [age, setAge] = (0, react_1.useState)('');
    (0, react_1.useEffect)(() => {
        if (props.birthDate) {
            const [nowDay, nowMonth, nowYear] = (0, date_fns_1.format)(new Date(), 'dd-MM-yyyy').split('-');
            const [birthDay, birthMonth, birthYear] = props.birthDate.split('-');
            let age = Number(nowYear) - Number(birthYear);
            const m = Number(nowMonth) - Number(birthMonth);
            if (m < 0 || (m === 0 && Number(nowDay) < Number(birthDay))) {
                age--;
            }
            age >= 0 ? setAge(age.toString()) : setAge('');
        }
    }, [props.birthDate]);
    return ((0, jsx_runtime_1.jsx)("input", { id: props.id, name: 'age', type: 'text', disabled: true, value: age, className: props.className || `form-control${props.small ? ' form-control-sm' : ''}` }));
};
exports.default = AgeCalculator;
