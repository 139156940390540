"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateContractType = exports.createContractType = exports.getContractType = exports.deleteContractTypes = exports.getContactTypes = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/references/contractTypes/parsers");
const getContactTypes = async (params) => {
    const response = await Api.get('references/contract-types', params);
    return (0, parsers_1.parseContractTypes)(response);
};
exports.getContactTypes = getContactTypes;
const deleteContractTypes = async (id) => {
    return await Api.deleteItem('references/contract-types/' + id);
};
exports.deleteContractTypes = deleteContractTypes;
const getContractType = async (id) => {
    const response = await Api.get('references/contract-types/' + id);
    return (0, parsers_1.parseContractType)(response);
};
exports.getContractType = getContractType;
const createContractType = async (params) => {
    const response = await Api.post('references/contract-types', params);
    return (0, parsers_1.parseContractType)(response);
};
exports.createContractType = createContractType;
const updateContractType = async (id, params) => {
    const response = await Api.patch('references/contract-types/' + id, params);
    return (0, parsers_1.parseContractType)(response);
};
exports.updateContractType = updateContractType;
