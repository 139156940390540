"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.newsesReducer = exports.useNewsesReducer = exports.initialNewsesState = void 0;
const react_1 = require("react");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const store_1 = require("core/store");
exports.initialNewsesState = {
    data: [],
    status: null,
    meta: store_1.initialMeta,
    errors: null,
    isFetching: true,
    isDeleting: false,
    markingAsRead: null,
    isLoadingMore: false
};
const useNewsesReducer = () => {
    return (0, react_1.useReducer)(exports.newsesReducer, exports.initialNewsesState);
};
exports.useNewsesReducer = useNewsesReducer;
const newsesReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_NEWSES_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_NEWSES_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                status: action.payload.status,
                errors: action.payload.errors,
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'SEND_NEWS_DELETE_REQUEST':
            return {
                ...state,
                isDeleting: true
            };
        case 'DONE_NEWS_DELETE_REQUEST':
            return {
                ...state,
                errors: action.payload.errors,
                isDeleting: false
            };
        case 'SEND_MARK_AS_READ_REQUEST':
            return {
                ...state,
                markingAsRead: action.payload
            };
        case 'DONE_MARK_AS_READ_REQUEST':
            return {
                ...state,
                data: action.payload.response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS
                    ? state.data.map(news => {
                        if (news.id === action.payload.id) {
                            return {
                                ...news,
                                seen: true
                            };
                        }
                        return news;
                    })
                    : state.data,
                errors: action.payload.response.errors,
                markingAsRead: null
            };
        case 'SEND_MARK_ALL_AS_READ_REQUEST':
            return {
                ...state
            };
        case 'DONE_MARK_ALL_AS_READ_REQUEST':
            return {
                ...state,
                data: action.payload.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS
                    ? state.data.map(news => {
                        return {
                            ...news,
                            seen: true
                        };
                    })
                    : state.data,
                errors: action.payload.errors
            };
        case 'CHANGE_PER_PAGE':
            return {
                ...state,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        perPage: action.payload
                    }
                }
            };
        case 'SEND_LOAD_MORE':
            return {
                ...state,
                isLoadingMore: true
            };
        case 'DONE_LOAD_MORE':
            return {
                ...state,
                data: action.payload.data ? [...state.data, ...action.payload.data] : state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                meta: action.payload.meta || store_1.initialMeta,
                isLoadingMore: false
            };
        default:
            return state;
    }
};
exports.newsesReducer = newsesReducer;
