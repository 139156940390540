"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.groupedCpvCodesReducer = exports.useGroupedCpvCodesReducer = void 0;
const react_1 = require("react");
const initialGroupedCpvCodesState = {
    data: [],
    errors: null,
    isFetching: true
};
const useGroupedCpvCodesReducer = () => {
    return (0, react_1.useReducer)(exports.groupedCpvCodesReducer, initialGroupedCpvCodesState);
};
exports.useGroupedCpvCodesReducer = useGroupedCpvCodesReducer;
const groupedCpvCodesReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_GROUPED_CPV_CODES_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_GROUPED_CPV_CODES_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                isFetching: false
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.groupedCpvCodesReducer = groupedCpvCodesReducer;
