"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReadStatus = exports.Publicity = void 0;
var Publicity;
(function (Publicity) {
    Publicity[Publicity["ALL"] = 0] = "ALL";
    Publicity[Publicity["PUBLISHED"] = 1] = "PUBLISHED";
})(Publicity || (exports.Publicity = Publicity = {}));
var ReadStatus;
(function (ReadStatus) {
    ReadStatus[ReadStatus["ALL"] = 0] = "ALL";
    ReadStatus[ReadStatus["UNREAD"] = 1] = "UNREAD";
})(ReadStatus || (exports.ReadStatus = ReadStatus = {}));
