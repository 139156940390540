"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProcurementTypes = void 0;
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const index_1 = require("api/index");
const procurementTypes_1 = require("../store/procurementTypes");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helpers_1 = require("../helpers");
const queryString_1 = require("core/helpers/queryString");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useProcurementTypes = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, procurementTypes_1.useProcurementTypesReducer)();
    const [keyword, setKeyword] = (0, react_1.useState)('');
    const [showDeleteAlert, setShowDeleteAlert] = (0, react_1.useState)(false);
    const [toDeleteId, setToDeleteId] = (0, react_1.useState)(null);
    const navigatePagination = (page) => {
        const params = (0, queryString_1.parse)(location.search);
        getProcurementTypes({ ...params, page }).then();
    };
    const selectPerPage = (perPage) => {
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'CHANGE_PER_PAGE', payload: perPage });
        getProcurementTypes({ ...params, perPage, page: undefined }).then();
    };
    const deleteAlert = (id) => {
        setShowDeleteAlert(true);
        setToDeleteId(id);
    };
    const debouncedSearch = (value) => {
        setKeyword(value);
        const perPage = (0, queryString_1.parse)(location.search).perPage;
        const params = value ? { perPage, filters: { keyword: value } } : { perPage };
        (0, debouncedMethods_1.debouncedOnFunc)(params, getProcurementTypes);
    };
    const deleteProcurementType = async (confirm) => {
        setShowDeleteAlert(false);
        if (!toDeleteId || !confirm)
            return setToDeleteId(null);
        dispatch({ type: 'SEND_DELETE_REQUEST' });
        const procurementTypes = await index_1.ProcurementTypesApi.deleteProcurementType(toDeleteId);
        const params = (0, queryString_1.parse)(location.search);
        if (procurementTypes.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            getProcurementTypes(params).then();
        dispatch({ type: 'DONE_DELETE_REQUEST', payload: { errors: procurementTypes.errors } });
        setToDeleteId(null);
    };
    const getProcurementTypes = async (params) => {
        dispatch({ type: 'SEND_PROCUREMENT_TYPES_REQUEST' });
        const procurementTypes = await index_1.ProcurementTypesApi.getProcurementTypes(params);
        navigate('/references/procurement-types' + (0, queryString_1.stringify)(params), { replace: true });
        dispatch({ type: 'DONE_PROCUREMENT_TYPES_REQUEST', payload: procurementTypes });
    };
    (0, react_1.useEffect)(() => {
        const params = (0, helpers_1.getParamsFromUrl)(location.search);
        if (params.perPage)
            dispatch({ type: 'CHANGE_PER_PAGE', payload: params.perPage });
        setKeyword(location.search || '');
        getProcurementTypes(params).then();
    }, []);
    return {
        state,
        navigatePagination,
        selectPerPage,
        deleteProcurementType,
        deleteAlert,
        debouncedSearch,
        toDeleteId,
        showDeleteAlert,
        keyword
    };
};
exports.useProcurementTypes = useProcurementTypes;
