"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAssignSubmissionItemsReducer = exports.initialAssignSubmissionItemsStore = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
exports.initialAssignSubmissionItemsStore = {
    data: [],
    meta: store_1.initialMeta,
    errors: null,
    isFetching: true
};
const useAssignSubmissionItemsReducer = () => {
    return (0, react_1.useReducer)(assignSubmissionItemsReducer, exports.initialAssignSubmissionItemsStore);
};
exports.useAssignSubmissionItemsReducer = useAssignSubmissionItemsReducer;
const assignSubmissionItemsReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_ASSIGN_SUBMISSION_ITEMS_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_ASSIGN_SUBMISSION_ITEMS_REQUEST':
            return {
                ...state,
                errors: action.payload.errors,
                data: action.payload.data || [],
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'CHANGE_PER_PAGE':
            return {
                ...state,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        perPage: action.payload
                    }
                }
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
