"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FundingSourcesFormReducer = exports.useFundingSourcesFormReducer = void 0;
const react_1 = require("react");
const initialFundingSourcesFormValues = {
    attachingFundingSource: null,
    attachedFundingSources: []
};
const initialFundingSourcesForm = {
    data: initialFundingSourcesFormValues,
    errors: null,
    isFetching: false,
    isUpdating: false,
    isLoading: false
};
const useFundingSourcesFormReducer = () => {
    return (0, react_1.useReducer)(exports.FundingSourcesFormReducer, initialFundingSourcesForm);
};
exports.useFundingSourcesFormReducer = useFundingSourcesFormReducer;
const FundingSourcesFormReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_FUNDING_SOURCES_REQUEST':
            return {
                ...state,
                isFetching: true,
                isLoading: false
            };
        case 'DONE_FUNDING_SOURCES_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                isFetching: false,
                isLoading: state.isUpdating
            };
        case 'SEND_UPDATE_FUNDING_SOURCES':
            return {
                ...state,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_UPDATE_FUNDING_SOURCES':
            return {
                ...state,
                data: action.payload.data || state.data,
                isUpdating: false,
                isLoading: state.isFetching
            };
        default:
            return state;
    }
};
exports.FundingSourcesFormReducer = FundingSourcesFormReducer;
