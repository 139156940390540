"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HRAssignSubmissionVerifyReducer = exports.useHRAssignSubmissionVerifyReducer = exports.initialAssignSubmissionVerifyNavigate = void 0;
const react_1 = require("react");
const types_1 = require("api/documents/human-resources/assign-submission-verifies/types");
exports.initialAssignSubmissionVerifyNavigate = {
    next: false,
    previous: false,
    isLoading: false,
    lastElement: false,
    firstElement: false
};
const initialHRAssignSubmissionVerifyItem = {
    number: '',
    isVerifySend: false,
    addressees: [{ id: '', label: '', addresseeStructuralUnit: null }],
    submissionAuthor: null,
    authorStructuralUnit: null,
    files: [],
    contactPersonEmail: null,
    contactPerson: null,
    purpose: '',
    phoneNumber: null,
    createdAt: '',
    disabledStatus: false,
    creator: null,
    edocNumber: '',
    dateOfConclusion: '',
    status: types_1.HRAssignSubmissionVerifyStatuses.DRAFT,
    remainingItemCount: null,
    requestRegistrationDate: null,
    visualStatus: { name: 'პროექტი', color: 'secondary' },
    termDate: null,
    itemsCount: 0,
    usedItemsCount: 0,
    declineComment: null
};
const initialHRAssignSubmissionVerifyState = {
    data: initialHRAssignSubmissionVerifyItem,
    errors: null,
    isFetching: false,
    isLoading: false,
    isDeclining: false,
    isSending: false
};
const useHRAssignSubmissionVerifyReducer = () => {
    return (0, react_1.useReducer)(exports.HRAssignSubmissionVerifyReducer, initialHRAssignSubmissionVerifyState);
};
exports.useHRAssignSubmissionVerifyReducer = useHRAssignSubmissionVerifyReducer;
const HRAssignSubmissionVerifyReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_HR_SUBMISSION_VERIFY_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_HR_SUBMISSION_VERIFY_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                isFetching: false,
                isLoading: state.isDeclining || state.isSending
            };
        case 'SEND_HR_SUBMISSION_VERIFY_SEND':
            return {
                ...state,
                errors: null,
                isSending: true,
                isLoading: true
            };
        case 'DONE_HR_SUBMISSION_VERIFY_SEND':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                isSending: false,
                isLoading: state.isDeclining || state.isFetching
            };
        case 'SEND_HR_SUBMISSION_VERIFY_DECLINE':
            return {
                ...state,
                errors: null,
                isDeclining: true,
                isLoading: true
            };
        case 'DONE_HR_SUBMISSION_VERIFY_DECLINE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                isDeclining: false,
                isLoading: state.isFetching || state.isSending
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.HRAssignSubmissionVerifyReducer = HRAssignSubmissionVerifyReducer;
