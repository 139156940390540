"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseCurrencyForSelect = exports.parseCurrency = exports.parseCurrencyRates = exports.parseCurrencies = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseCurrencies = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const currencies = response.content;
    try {
        return {
            data: currencies.data.map(el => {
                return {
                    id: el.id,
                    moduleType: el.type,
                    name: el.attributes.name,
                    code: el.attributes.code,
                    sort: el.attributes.sort,
                    quantity: el.attributes.quantity,
                    currentExchangeRate: el.attributes.rate,
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    createdDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt),
                    updatedDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.updatedAt)
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(currencies.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseCurrencies = parseCurrencies;
const parseCurrencyRates = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const currencyRates = response.content;
    try {
        return {
            data: currencyRates.data.map(el => {
                return {
                    id: el.id,
                    moduleType: el.type,
                    currencyId: el.attributes.currencyId,
                    exchangeRate: el.attributes.rate,
                    quantity: el.attributes.quantity,
                    change: el.attributes.change,
                    changeDirection: el.attributes.changeDirection,
                    date: (0, parsers_1.transformDate)(el.attributes.date),
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt)
                };
            }),
            status: response.status,
            metaForRates: (0, parsers_1.transformPagination)(currencyRates.meta),
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseCurrencyRates = parseCurrencyRates;
const parseCurrency = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const currency = response.content;
    try {
        return {
            data: {
                id: currency.data.id,
                name: currency.data.attributes.name,
                code: currency.data.attributes.code,
                quantity: currency.data.attributes.quantity,
                rate: currency.data.attributes.rate,
                sort: currency.data.attributes.sort
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseCurrency = parseCurrency;
const parseCurrencyForSelect = (response) => {
    const currencies = response.content;
    if (response.content === null)
        return [];
    try {
        return currencies.data.map(el => {
            return {
                label: el.attributes.code,
                id: el.id,
                currentExchangeRate: el.attributes.rate,
                quantity: el.attributes.quantity,
                transformedRate: el.attributes.rate ? el.attributes.rate / (el.attributes.quantity || 1) : 1
            };
        });
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return [];
    }
};
exports.parseCurrencyForSelect = parseCurrencyForSelect;
