"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformSpecificationFilterParamsToUrl = exports.transformSpecificationParamsToFilterParams = exports.transformUrlToSpecificationFilterParams = exports.transformInventorySpecificationParams = void 0;
const queryString_1 = require("core/helpers/queryString");
const transformInventorySpecificationParams = (params) => {
    return { specification: params.name };
};
exports.transformInventorySpecificationParams = transformInventorySpecificationParams;
// export const parseInventorySpecificationData = (response: any) => {
//   const data = response.data.map((el: any) => {
//     return {
//       id: el.id,
//       attributes: {
//         name: el.attributes.name,
//         createdAt: el.attributes.createdAt && format(new Date(el.attributes.createdAt), 'dd-MM-yyyy'),
//         updatedAt: el.attributes.updatedAt && format(new Date(el.attributes.updatedAt), 'dd-MM-yyyy')
//       }
//     }
//   })
//   return { data, meta: response.meta }
// }
const transformUrlToSpecificationFilterParams = (url) => {
    const values = (0, queryString_1.parse)(url);
    return {
        keyword: values.keyword,
        page: values.page,
        perPage: values.perPage
    };
};
exports.transformUrlToSpecificationFilterParams = transformUrlToSpecificationFilterParams;
const transformSpecificationParamsToFilterParams = (params) => {
    const filters = {};
    const paginate = {};
    const filterParams = {
        keyword: params.keyword,
        page: params.page,
        perPage: params.perPage
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if (value && key !== 'page' && key !== 'perPage')
                filters[key] = value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(filters).length === 0)
            return { ...paginate };
        return { ...paginate, filters };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformSpecificationParamsToFilterParams = transformSpecificationParamsToFilterParams;
const transformSpecificationFilterParamsToUrl = (values) => {
    const { page, perPage, keyword } = values;
    return {
        page,
        perPage,
        keyword
    };
};
exports.transformSpecificationFilterParamsToUrl = transformSpecificationFilterParamsToUrl;
