"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const calculateCpvCodesReducer_1 = require("../store/calculateCpvCodesReducer");
const index_1 = require("api/index");
const react_1 = require("react");
const useCalculateCpvCodes = (props) => {
    const [state, dispatch] = (0, calculateCpvCodesReducer_1.useGroupedCpvCodesReducer)();
    const getGroupedCpvCodes = async () => {
        dispatch({ type: 'SEND_GROUPED_CPV_CODES_REQUEST' });
        const groupedCpvCodes = await index_1.SubmissionVerifiesApi.getGroupedCpvCodes(props.verifySubmissionId, { filters: { status: props.filters } });
        dispatch({ type: 'DONE_GROUPED_CPV_CODES_REQUEST', payload: groupedCpvCodes });
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { getGroupedCpvCodes().then(); }, []);
    return { state, closeErrorMessage };
};
exports.default = useCalculateCpvCodes;
