"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformUserFilterParamsToUrl = exports.transformUserParamsToFilterParams = exports.transformUrlToUserFilterParams = exports.getParamsFromUrl = exports.transformUserParams = exports.parseUserPermissionParams = void 0;
// import { allowedMenuItems, transformAuthUserData } from '../../../auth/helpers'
// import { fetchUserRequestAction, fetchUserSuccessAction } from '../../../auth/actions'
// import { filterMainMenuAction } from '../../../menu/actions'
// import { menuItems } from '../../../menu/helpers/menuItmes'
const queryString_1 = require("core/helpers/queryString");
// import { store } from 'core/stateOfArt'
const helper_1 = require("src/modules/common/helper");
// import { transformDate } from '../../../common/helper'
// import api from 'api/index'
const parseUserPermissionParams = (values) => {
    const permissionIds = [];
    values.forEach(group => {
        group.groupPermissions.forEach(el => {
            if (el.checked)
                permissionIds.push(el.id);
        });
    });
    return { permissions: permissionIds };
};
exports.parseUserPermissionParams = parseUserPermissionParams;
const transformUserParams = (params) => {
    return {
        firstname: params.firstname,
        lastname: params.lastname,
        fathersName: params.fathersName,
        identityNumber: params.identityNumber,
        idCardValidTo: params.idCardValidTo,
        passportNumber: params.passportNumber,
        gender: params.gender,
        countryId: params.country?.id,
        birthDate: (0, helper_1.nullableTransformParamsDate)(params.birthDate),
        address: params.address,
        mobileNumber: params.mobileNumber,
        workPhoneNumber: params.workPhoneNumber,
        personalEmail: params.personalEmail,
        email: params.email,
        status: params.status,
        password: params.password,
        password_confirmation: params.password_confirmation
        // edocUser: params.edocUser ? EdocUserStatus.USER : EdocUserStatus.NOT_USER
    };
};
exports.transformUserParams = transformUserParams;
const getParamsFromUrl = (url) => {
    try {
        const params = (0, queryString_1.parse)(url);
        return {
            page: params.page,
            perPage: params.perPage,
            filters: {
                keyword: params.filters?.keyword,
                // permission: params.filters?.permission,
                structuralUnitId: params.filters?.structuralUnitId,
                // positionId: params.filters?.positionId,
                status: params.filters?.status
            }
        };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.getParamsFromUrl = getParamsFromUrl;
const transformUrlToUserFilterParams = (url) => {
    const values = (0, queryString_1.parse)(url);
    return {
        position: values.positionId && values.positionLabel ? { id: values.positionId, label: values.positionLabel } : undefined,
        structuralUnit: values.structuralUnitId && values.structuralUnitLabel ? { id: values.structuralUnitId, label: values.structuralUnitLabel } : undefined,
        country: values.countryId && values.countryLabel ? { id: values.countryId, label: values.countryLabel } : undefined,
        dualCitizenshipCountry: values.dualCitizenshipCountryId && values.dualCitizenshipCountryLabel ? { id: values.dualCitizenshipCountryId, label: values.dualCitizenshipCountryLabel } : undefined,
        status: values.statusId && values.statusLabel ? { id: values.statusId, label: values.statusLabel } : undefined,
        permission: values.permission,
        keyword: values.keyword,
        page: values.page,
        perPage: values.perPage
    };
};
exports.transformUrlToUserFilterParams = transformUrlToUserFilterParams;
const transformUserParamsToFilterParams = (params) => {
    const filters = {};
    const paginate = {};
    const filterParams = {
        keyword: params.keyword,
        dualCitizenshipCountryId: params.dualCitizenshipCountry?.id,
        permission: params.permission,
        countryId: params.country?.id,
        structuralUnitId: params.structuralUnit?.id,
        positionId: params.position?.id,
        status: params.status?.id,
        page: params.page,
        perPage: params.perPage
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if (value && key !== 'page' && key !== 'perPage')
                filters[key] = value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(filters).length === 0)
            return { ...paginate };
        return { ...paginate, filters };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformUserParamsToFilterParams = transformUserParamsToFilterParams;
const transformUserFilterParamsToUrl = (values) => {
    const { page, perPage, keyword, status, permission, structuralUnit, position, dualCitizenshipCountry, country } = values;
    return {
        page,
        perPage,
        keyword,
        statusId: status?.id,
        statusLabel: status?.label,
        permission,
        structuralUnitId: structuralUnit?.id,
        structuralUnitLabel: structuralUnit?.label,
        positionId: position?.id,
        positionLabel: position?.label,
        dualCitizenshipCountryId: dualCitizenshipCountry?.id,
        dualCitizenshipCountryLabel: dualCitizenshipCountry?.label,
        countryId: country?.id,
        countryLabel: country?.label
    };
};
exports.transformUserFilterParamsToUrl = transformUserFilterParamsToUrl;
