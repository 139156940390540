"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUnreadQuantities = exports.markAllAsRead = exports.markAsRead = exports.updateNews = exports.createNews = exports.getNews = exports.deleteItem = exports.getNewses = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parser_1 = require("api/administer/news/parser");
const getNewses = async (params) => {
    const response = await Api.get('references/news', params);
    return (0, parser_1.parseNewses)(response);
};
exports.getNewses = getNewses;
const deleteItem = async (id) => {
    return await Api.deleteItem(`references/news/${id}`);
};
exports.deleteItem = deleteItem;
const getNews = async (id) => {
    const response = await Api.get('references/news/' + id);
    return (0, parser_1.parseNews)(response);
};
exports.getNews = getNews;
const createNews = async (params) => {
    const response = await Api.post('references/news', params);
    return (0, parser_1.parseNews)(response);
};
exports.createNews = createNews;
const updateNews = async (id, params) => {
    const response = await Api.patch('references/news/' + id, params);
    return (0, parser_1.parseNews)(response);
};
exports.updateNews = updateNews;
const markAsRead = async (params) => {
    const response = await Api.post('references/news/read', params);
    return response;
};
exports.markAsRead = markAsRead;
const markAllAsRead = async () => {
    const response = await Api.post('references/news/read-all', {});
    return response;
};
exports.markAllAsRead = markAllAsRead;
const getUnreadQuantities = async () => {
    const response = await Api.get('auth/user/state');
    return (0, parser_1.parseUnreadQuantities)(response);
};
exports.getUnreadQuantities = getUnreadQuantities;
