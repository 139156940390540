"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const react_1 = require("react");
const mediatorBanks_1 = require("../store/mediatorBanks");
const helpers_1 = require("../helpers");
const useMediatorBanks = (props) => {
    const [state, dispatch] = (0, mediatorBanks_1.useMediatorBanksReducer)();
    const [mediatorBankModal, setMediatorBankModal] = (0, react_1.useState)(false);
    const getMediatorBanks = async (bankId) => {
        dispatch({ type: 'SEND_MEDIATOR_BANKS_REQUEST' });
        const mediatorBanks = await index_1.BankApi.getMediatorBanks(bankId);
        dispatch({ type: 'DONE_MEDIATOR_BANKS_REQUEST', payload: mediatorBanks });
    };
    const createOrUpdateMediatorBanks = async (bankId, values) => {
        const params = (0, helpers_1.transformMediatorBanksParams)(values);
        dispatch({ type: 'SEND_MEDIATOR_BANKS_CREATE_OR_UPDATE' });
        const mediatorBanks = await index_1.BankApi.createOrUpdateMediatorBanks(bankId, params);
        dispatch({ type: 'DONE_MEDIATOR_BANKS_CREATE_OR_UPDATE', payload: mediatorBanks });
        return mediatorBanks;
    };
    (0, react_1.useEffect)(() => { if (props.bankId)
        getMediatorBanks(props.bankId).then(); }, [props.bankId]);
    return { mediatorBankModal, setMediatorBankModal, state, createOrUpdateMediatorBanks };
};
exports.default = useMediatorBanks;
