"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assignContractsReducer = exports.useSignContractListReducer = exports.initialContractNavigate = exports.initialSignContractState = void 0;
const react_1 = require("react");
exports.initialSignContractState = {
    data: null,
    activeContract: null,
    status: null,
    errors: null,
    isFetching: false,
    isSigning: false,
    isLoading: false,
    isSendingComment: false
};
exports.initialContractNavigate = {
    next: false,
    previous: false,
    isLoading: false,
    lastElement: false,
    firstElement: false
};
const useSignContractListReducer = () => {
    return (0, react_1.useReducer)(exports.assignContractsReducer, exports.initialSignContractState);
};
exports.useSignContractListReducer = useSignContractListReducer;
const assignContractsReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_SINGLE_CONTRACT_REQUEST':
            return {
                ...state,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_SINGLE_CONTRACT_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                activeContract: action.payload.data
                    ? (action.payload.data.corrections.length > 0
                        ? action.payload.data.corrections[action.payload.data.corrections.length - 1]
                        : action.payload.data)
                    : state.activeContract,
                status: action.payload.status,
                errors: action.payload.errors,
                isFetching: false,
                isLoading: false
            };
        case 'SEND_CONTRACT_SIGN_REQUEST':
            return {
                ...state,
                isSigning: true,
                isLoading: true
            };
        case 'DONE_CONTRACT_SIGN_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                activeContract: action.payload.data
                    ? parseActiveContract(action.payload.data) :
                    state.activeContract,
                status: action.payload.status,
                errors: action.payload.errors,
                isSigning: false,
                isLoading: false
            };
        case 'DONE_CORRECTION_SIGN_REQUEST':
            return {
                ...state,
                data: action.payload.data && state.data
                    ? { ...state.data,
                        corrections: parseCorrectionChange(action.payload.data, state.data)
                    }
                    : state.data,
                activeContract: action.payload.data && state.data
                    ? parseActiveContract(action.payload.data)
                    : state.activeContract,
                status: action.payload.status,
                errors: action.payload.errors,
                isSigning: false
            };
        case 'CHANGE_ACTIVE_CONTRACT':
            return {
                ...state,
                data: state.data,
                activeContract: state.data?.corrections.find(el => el.id === action.payload) || state.data,
                status: state.status,
                errors: state.errors
            };
        case 'CLOSE_ERROR':
            return {
                ...state,
                errors: null
            };
        default:
            return state;
    }
};
exports.assignContractsReducer = assignContractsReducer;
const parseCorrectionChange = (payloadData, stateData) => {
    return stateData.corrections.map(el => {
        if (el.id === payloadData.id) {
            return {
                id: payloadData.id,
                contractFileUid: payloadData.contractFileUid,
                contractStatus: payloadData.contractStatus,
                contractStatusHistory: payloadData.contractStatusHistory,
                signatoryIds: payloadData.signatoryIds,
                contractCreatedShortDate: payloadData.contractCreatedShortDate,
                contractCreatedDateTime: payloadData.contractCreatedDateTime,
                contractUpdatedDateTime: payloadData.contractUpdatedDateTime,
                contractUpdatedShortDate: payloadData.contractUpdatedShortDate,
                contractVisualStatus: payloadData.contractVisualStatus,
                corrections: [],
                fullName: payloadData.fullName,
                employeeId: payloadData.employeeId,
                isCorrection: payloadData.isCorrection
            };
        }
        return el;
    });
};
const parseActiveContract = (payload) => {
    return {
        id: payload.id,
        contractFileUid: payload.contractFileUid,
        contractStatus: payload.contractStatus,
        contractStatusHistory: payload.contractStatusHistory,
        signatoryIds: payload.signatoryIds,
        contractCreatedShortDate: payload.contractCreatedShortDate,
        contractCreatedDateTime: payload.contractCreatedDateTime,
        contractUpdatedDateTime: payload.contractUpdatedDateTime,
        contractUpdatedShortDate: payload.contractUpdatedShortDate,
        contractVisualStatus: payload.contractVisualStatus,
        corrections: [],
        fullName: payload.fullName,
        employeeId: payload.employeeId,
        isCorrection: payload.isCorrection
    };
};
