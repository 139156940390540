"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useVerifiedItemsReducer = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
const initialVerifiedItems = {
    data: [],
    cpvCodes: [],
    submissions: [],
    meta: store_1.initialMeta,
    errors: null,
    isFetching: true,
    cpvCodesIsFetching: true,
    submissionIsFetching: true
};
const useVerifiedItemsReducer = () => {
    return (0, react_1.useReducer)(verifiedItemsReducer, initialVerifiedItems);
};
exports.useVerifiedItemsReducer = useVerifiedItemsReducer;
const verifiedItemsReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_VERIFIED_ITEMS_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_VERIFIED_ITEMS_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'SEND_CPV_CODES_REQUEST':
            return {
                ...state,
                cpvCodesIsFetching: true
            };
        case 'DONE_CPV_CODES_REQUEST':
            return {
                ...state,
                cpvCodes: action.payload.data || [],
                cpvCodesIsFetching: false
            };
        case 'SEND_SUBMISSIONS_REQUEST':
            return {
                ...state,
                submissionIsFetching: true
            };
        case 'DONE_SUBMISSIONS_REQUEST':
            return {
                ...state,
                submissions: action.payload.data || [],
                submissionIsFetching: false
            };
        default:
            return state;
    }
};
