"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getParamsFromUrl = exports.transformCountryParams = void 0;
const queryString_1 = require("core/helpers/queryString");
const transformCountryParams = (params) => {
    return {
        nameEn: params.nameEn,
        nameKa: params.nameKa
    };
};
exports.transformCountryParams = transformCountryParams;
const getParamsFromUrl = (url) => {
    try {
        const params = (0, queryString_1.parse)(url);
        return {
            page: params.page,
            perPage: params.perPage,
            filters: params.filters?.keyword ? { keyword: params.filters.keyword } : undefined
        };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.getParamsFromUrl = getParamsFromUrl;
