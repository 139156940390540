"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getParamsFromUrl = exports.transformProcurementTypeParams = void 0;
const types_1 = require("api/documents/purchases/procurements/types");
const queryString_1 = require("core/helpers/queryString");
const transformProcurementTypeParams = (params) => {
    return {
        electronicTender: params.electronicTender,
        simplifiedProcurement: params.type === types_1.ContractType.CORRECTION ? params.electronicTender : params.simplifiedProcurement,
        type: params.type
    };
};
exports.transformProcurementTypeParams = transformProcurementTypeParams;
const getParamsFromUrl = (url) => {
    try {
        const params = (0, queryString_1.parse)(url);
        return {
            page: params.page,
            perPage: params.perPage,
            filters: params.filters?.keyword ? { keyword: params.filters.keyword } : undefined
        };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.getParamsFromUrl = getParamsFromUrl;
