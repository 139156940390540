"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const index_1 = require("api/index");
const training_1 = require("../store/training");
const helper_1 = require("../helper");
const useTraining = (props) => {
    const [state, dispatch] = (0, training_1.useTrainingReducer)();
    const [deleteTrainingAlert, setDeleteTrainingAlert] = (0, react_1.useState)({ showAlert: false, id: null });
    const getTrainings = async (id) => {
        dispatch({ type: 'SEND_TRAININGS_REQUEST' });
        const trainings = await index_1.EmployeesApi.getTrainings(id);
        dispatch({ type: 'DONE_TRAININGS_REQUEST', payload: trainings });
    };
    const addTraining = async (values, id) => {
        const params = (0, helper_1.transformEmployeeTrainingsParams)(values);
        dispatch({ type: 'SEND_TRAINING_CREATE_OR_UPDATE' });
        const employeeTrainingsData = await index_1.EmployeesApi.addTraining(params, id);
        dispatch({ type: 'DONE_TRAINING_CREATE_OR_UPDATE', payload: employeeTrainingsData });
        return employeeTrainingsData;
    };
    const deleteTraining = async (confirm) => {
        if (!confirm || !deleteTrainingAlert.id)
            return setDeleteTrainingAlert({ showAlert: false, id: null });
        setDeleteTrainingAlert({ ...deleteTrainingAlert, showAlert: false });
        dispatch({ type: 'SEND_DELETE_TRAINING' });
        const employee = await index_1.EmployeesApi.deleteTraining(props.id, deleteTrainingAlert.id);
        dispatch({ type: 'DONE_DELETE_TRAINING', payload: { errors: employee.errors } });
        setDeleteTrainingAlert({ showAlert: false, id: null });
        if (props.id)
            getTrainings(props.id).then();
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getTrainings(props.id).then(); }, []);
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    return {
        addTraining,
        closeErrorMessage,
        state,
        setDeleteTrainingAlert,
        deleteTrainingAlert,
        deleteTraining
    };
};
exports.default = useTraining;
