"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const structuralUnitRoles_1 = require("../store/structuralUnitRoles");
const react_1 = require("react");
const useAttachRoles = (props) => {
    const [state, dispatch] = (0, structuralUnitRoles_1.useStructuralUnitRolesReducer)();
    const getRoles = async (params) => {
        dispatch({ type: 'SEND_ROLES_REQUEST' });
        const roles = await index_1.RolesApi.getRoles({ ...params, perPage: 500 });
        dispatch({ type: 'DONE_ROLES_REQUEST', payload: roles });
    };
    const attachRoleToStructuralUnit = async (roleId) => {
        dispatch({ type: 'SEND_ATTACH_ROLE_TO_STRUCTURAL_UNITS', payload: [roleId] });
        const roles = await index_1.RolesApi.attachRoleToStructuralUnit(props.structuralUnitId, { roles: [roleId] });
        dispatch({ type: 'DONE_ATTACH_ROLE_TO_STRUCTURAL_UNITS', payload: { status: roles.status, attachedRoles: [roleId] } });
        return roles;
    };
    const detachRoleToStructuralUnit = async (roleId) => {
        dispatch({ type: 'SEND_DETACH_ROLE_TO_STRUCTURAL_UNITS', payload: [roleId] });
        const roles = await index_1.RolesApi.detachRoleToStructuralUnit(props.structuralUnitId, { roles: [roleId] });
        dispatch({ type: 'DONE_DETACH_ROLE_TO_STRUCTURAL_UNITS', payload: { status: roles.status, attachedRoles: [roleId] } });
        return roles;
    };
    const getStructuralUnitRoles = async (structuralUnitId) => {
        dispatch({ type: 'SEND_STRUCTURAL_UNITS_ROLE_REQUEST' });
        const roles = await index_1.RolesApi.getStructuralUnitRoles({ filters: { structuralUnitId }, perPage: 500 });
        dispatch({ type: 'DONE_STRUCTURAL_UNITS_ROLE_REQUEST', payload: roles });
    };
    (0, react_1.useEffect)(() => {
        getRoles({}).then(() => {
            getStructuralUnitRoles(props.structuralUnitId).then();
        });
    }, []);
    return { state, attachRoleToStructuralUnit, detachRoleToStructuralUnit, getRoles };
};
exports.default = useAttachRoles;
