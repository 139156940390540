"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformConditionalSubmissionItemsFilterParams = exports.transformFilterParamsToUrl = exports.transformChangeExpirationDateParams = exports.transformSubmissionParams = exports.transformParamsToFilterParams = exports.transformSubmissionItemsListParams = exports.getItemsParamsFromUrl = exports.transformUrlToFilterParams = void 0;
const types_1 = require("api/references/purchase-subject/types");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const queryString_1 = require("core/helpers/queryString");
const parsers_1 = require("api/parsers/parsers");
const transformUrlToFilterParams = (url) => {
    const values = (0, queryString_1.parse)(url);
    return {
        keyword: values.keyword,
        status: values.statusLabel && values.statusId ? { id: values.statusId, label: values.statusLabel } : null,
        createdBy: values.createdById && values.createdByLabel ? { id: values.createdById, label: values.createdByLabel } : null,
        position: values.positionId && values.positionLabel ? { id: values.positionId, label: values.positionLabel } : null,
        structuralUnit: values.structuralUnitId && values.structuralUnitLabel ? { id: values.structuralUnitId, label: values.structuralUnitLabel } : null,
        employee: values.employeeId && values.employeeLabel ? { id: values.employeeId, label: values.employeeLabel } : null,
        fundingSourceId: values.fundingSourceId && values.fundingSourceLabel ? { id: values.fundingSourceId, label: values.fundingSourceLabel } : null,
        isForceMajeure: values.isForceMajeure ? Number(values.isForceMajeure) === apiGlobalTypes_1.YesNoStatus.YES : undefined,
        page: values.page,
        perPage: values.perPage
    };
};
exports.transformUrlToFilterParams = transformUrlToFilterParams;
const getItemsParamsFromUrl = (url) => {
    try {
        const params = (0, queryString_1.parse)(url);
        return {
            page: params.page,
            perPage: params.perPage,
            filters: params.filters?.keyword ? { keyword: params.filters.keyword } : undefined
        };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.getItemsParamsFromUrl = getItemsParamsFromUrl;
// export const transformSubmissionItemFormParams = (values: SubmissionItemType): SubmissionItemsFormParams => {
//   return {
//     items: [{
//       id: values.id,
//       inventoryConfigurationId: values.inventoryConfigurationId,
//       cpvCodeId: (values.cpvCode as SelectType).id,
//       dimensionUnitId: (values.dimensionUnit as SelectType).id,
//       currencyId: (values.currency as SelectType).id,
//       projectId: (values.project as SelectType).id,
//       currencyExchangeRate: values.currencyExchangeRate,
//       quantity: values.quantity as number,
//       price: values.price as number,
//       characteristics: values.characteristics,
//       assignment: values.assignment,
//       conditionalSubmissionItemId: values.conditionalSubmissionItemId,
//       templateType: Number((values.templateType as SelectType).id)
//     }]
//   }
// }
const transformSubmissionItemsListParams = (values) => {
    return { items: values.map(el => transformSubmissionItemListParams(el)) };
};
exports.transformSubmissionItemsListParams = transformSubmissionItemsListParams;
const transformSubmissionItemListParams = (item) => {
    return {
        id: item.id,
        inventoryConfigurationId: item.inventoryConfigurationId,
        cpvCodeId: item.cpvCode.id,
        dimensionUnitId: item.dimensionUnit.id,
        currencyId: item.currency.id,
        projectId: item.project.id,
        budgetArticleId: item.budgetArticle?.id || null,
        currencyExchangeRate: item.currencyExchangeRate,
        quantity: item.quantity,
        price: item.price,
        characteristics: item.characteristics,
        assignment: item.assignment,
        conditionalSubmissionItemId: item.conditionalSubmissionItemId,
        templateType: Number(item.templateType.id),
        bookTitle: item.inventoryConfigurationType === types_1.InventoryConfigurationType.BOOK || item.inventoryConfigurationType === types_1.InventoryConfigurationType.NATIONWIDE ? item.bookTitle : null,
        bookAuthor: item.inventoryConfigurationType === types_1.InventoryConfigurationType.BOOK || item.inventoryConfigurationType === types_1.InventoryConfigurationType.NATIONWIDE ? item.bookAuthor : null,
        bookIsbnCode: item.inventoryConfigurationType === types_1.InventoryConfigurationType.BOOK || item.inventoryConfigurationType === types_1.InventoryConfigurationType.NATIONWIDE ? item.bookIsbnCode : null,
        bookLink: item.inventoryConfigurationType === types_1.InventoryConfigurationType.BOOK || item.inventoryConfigurationType === types_1.InventoryConfigurationType.NATIONWIDE ? item.bookLink : null
    };
};
const transformParamsToFilterParams = (params) => {
    const filters = {};
    const paginate = {};
    const filterParams = {
        keyword: params.keyword,
        structuralUnit: params.structuralUnit?.id,
        position: params.position?.id,
        createdBy: params.createdBy?.id || params.onlyMine,
        employee: params.employee?.id,
        fundingSourceId: params.fundingSourceId?.id,
        status: params.status?.id,
        isForceMajeure: params.isForceMajeure,
        page: params.page,
        perPage: params.perPage
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if (value && key !== 'page' && key !== 'perPage')
                filters[key] = value.id ?? value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(filters).length === 0)
            return { ...paginate };
        return { ...paginate, filters };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformParamsToFilterParams = transformParamsToFilterParams;
const transformSubmissionParams = (params) => {
    return {
        number: params.number,
        accountId: params.structuralUnit.accountId,
        date: (0, parsers_1.transformDateParams)(params.date),
        executionDate: (0, parsers_1.transformDateParams)(params.executionDate),
        expirationDate: params.id ? undefined : (0, parsers_1.transformDateParams)(params.expirationDate),
        fileUids: params.files.map(el => el.id)
    };
};
exports.transformSubmissionParams = transformSubmissionParams;
const transformChangeExpirationDateParams = (params) => {
    return {
        comment: params.comment,
        expirationDate: (0, parsers_1.transformDateParams)(params.expirationDate),
        executionDate: (0, parsers_1.transformDateParams)(params.executionDate)
    };
};
exports.transformChangeExpirationDateParams = transformChangeExpirationDateParams;
const transformFilterParamsToUrl = (values) => {
    const { keyword, structuralUnit, createdBy, employee, status, page, perPage, fundingSourceId, isForceMajeure } = values;
    return {
        keyword,
        employeeId: employee?.id,
        employeeLabel: employee?.label,
        createdById: createdBy?.id,
        createdByLabel: createdBy?.label,
        structuralUnitId: structuralUnit?.id,
        structuralUnitLabel: structuralUnit?.label,
        statusId: status?.id,
        statusLabel: status?.label,
        fundingSourceId: fundingSourceId?.id,
        fundingSourceLabel: fundingSourceId?.label,
        isForceMajeure: isForceMajeure ? 1 : undefined,
        page,
        perPage
    };
};
exports.transformFilterParamsToUrl = transformFilterParamsToUrl;
const transformConditionalSubmissionItemsFilterParams = (values) => {
    const filters = {};
    const paginate = {};
    const filterParams = {
        keyword: values.keyword,
        page: values.page,
        perPage: values.perPage
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if (value && key !== 'page' && key !== 'perPage')
                filters[key] = value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(filters).length === 0)
            return { ...paginate };
        return { ...paginate, filters };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformConditionalSubmissionItemsFilterParams = transformConditionalSubmissionItemsFilterParams;
