"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformAdministrativeBuildingParams = exports.getParamsFromUrl = void 0;
const queryString_1 = require("core/helpers/queryString");
const getParamsFromUrl = (url) => {
    try {
        const params = (0, queryString_1.parse)(url);
        return {
            page: params.page,
            perPage: params.perPage,
            filters: params.filters?.keyword
                ? { keyword: params.filters.keyword } : undefined
        };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.getParamsFromUrl = getParamsFromUrl;
const transformAdministrativeBuildingParams = (params) => {
    return {
        name: params.name,
        address: params.address,
        districtId: params.district.id,
        lat: params.lat,
        lng: params.lng
    };
};
exports.transformAdministrativeBuildingParams = transformAdministrativeBuildingParams;
