"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const contractAndAnnex_1 = require("../store/contractAndAnnex");
const index_1 = require("api/index");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const helper_1 = require("../helper");
const react_1 = require("react");
const useContractAndAnnex = (props) => {
    const [state, dispatch] = (0, contractAndAnnex_1.useContractAndAnnexReducer)();
    const [copied, setCopied] = (0, react_1.useState)(null);
    const [isDeletingFile, setIsDeletingFile] = (0, react_1.useState)(null);
    const [isDeletingRecord, setIsDeletingRecord] = (0, react_1.useState)(null);
    const getContractAndAnnex = async () => {
        dispatch({ type: 'SEND_CONTRACT_FILES_REQUEST' });
        const response = await index_1.EmployeesApi.getContractAndAnnex(props.employeeId);
        dispatch({ type: 'DONE_CONTRACT_FILES_REQUEST', payload: response });
    };
    const createContractAndAnnex = async (values) => {
        const params = (0, helper_1.transformContractAndAnnexParams)(values);
        dispatch({ type: 'SEND_CREATE_UPDATE_CONTRACT_REQUEST', payload: { id: values.id } });
        const response = await index_1.EmployeesApi.createContractAndAnnex(props.employeeId, params);
        dispatch({ type: 'DONE_CREATE_UPDATE_CONTRACT_REQUEST', payload: { response, id: values.id } });
        return response;
    };
    const updateContractAndAnnex = async (values) => {
        const params = (0, helper_1.transformContractAndAnnexParams)(values);
        dispatch({ type: 'SEND_CREATE_UPDATE_CONTRACT_REQUEST', payload: { id: values.id } });
        const response = await index_1.EmployeesApi.updateContractAndAnnex(values.id, params);
        dispatch({ type: 'DONE_CREATE_UPDATE_CONTRACT_REQUEST', payload: { response, id: values.id } });
        return response;
    };
    const createContractAndAnnexCorrection = async (values, originalContractId) => {
        const params = (0, helper_1.transformContractAndAnnexCorrectionParams)(values);
        dispatch({ type: 'SEND_CREATE_UPDATE_CORRECTION_REQUEST', payload: { id: values.correctionId } });
        const response = await index_1.EmployeesApi.createContractAndAnnexCorrection(originalContractId, params);
        dispatch({ type: 'DONE_CREATE_UPDATE_CORRECTION_REQUEST', payload: { response, id: values.correctionId, originalContractId } });
        return response;
    };
    const updateContractAndAnnexCorrection = async (values, originalContractId) => {
        const params = (0, helper_1.transformContractAndAnnexCorrectionParams)(values);
        dispatch({ type: 'SEND_CREATE_UPDATE_CORRECTION_REQUEST', payload: { id: values.correctionId } });
        const response = await index_1.EmployeesApi.updateContractAndAnnexCorrection(values.correctionId, params);
        dispatch({ type: 'DONE_CREATE_UPDATE_CORRECTION_REQUEST', payload: { response, id: values.correctionId, originalContractId } });
        return response;
    };
    const sendContractOrCorrection = async (text, originalContractId, correctionId) => {
        const params = (0, helper_1.transformSendContractParams)(text, correctionId || originalContractId);
        dispatch({ type: 'SEND_SENDING_CONTRACT_REQUEST' });
        const response = await index_1.EmployeesApi.sendContractAndAnnex(params);
        dispatch({ type: 'DONE_SENDING_CONTRACT_REQUEST', payload: { response, originalContractId, correctionId } });
        return response;
    };
    const copyTextElement = (text, id) => {
        const tempInputElement = document.createElement('input');
        setCopied(id);
        tempInputElement.type = 'text';
        tempInputElement.value = text || '';
        document.body.appendChild(tempInputElement);
        tempInputElement.select();
        document.execCommand('copy');
        document.body.removeChild(tempInputElement);
        setTimeout(() => setCopied(null), 1000);
    };
    const deleteContractOrAnnexFile = async (uid) => {
        setIsDeletingFile(uid);
        const response = await index_1.EmployeesApi.deleteContractOrAnnexFile(uid);
        if (response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            await getContractAndAnnex();
        setIsDeletingFile(null);
        return response;
    };
    const deleteContractOrAnnexFileRecord = async (id) => {
        setIsDeletingRecord(id);
        const response = await index_1.EmployeesApi.deleteContractOrAnnexRecord(id);
        if (response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            await getContractAndAnnex();
        setIsDeletingRecord(null);
        return response;
    };
    const createNewContract = () => {
        dispatch({ type: 'CREATE_NEW_CONTRACT' });
    };
    const deleteUnsavedContracts = () => {
        dispatch({ type: 'DELETE_UNSAVED_CONTRACTS' });
    };
    (0, react_1.useEffect)(() => {
        getContractAndAnnex().then();
    }, [props.employeeId]);
    return {
        state,
        createContractAndAnnex,
        updateContractAndAnnex,
        sendContractOrCorrection,
        createContractAndAnnexCorrection,
        updateContractAndAnnexCorrection,
        copied,
        copyTextElement,
        deleteContractOrAnnexFile,
        deleteContractOrAnnexFileRecord,
        isDeletingFile,
        isDeletingRecord,
        createNewContract,
        deleteUnsavedContracts
    };
};
exports.default = useContractAndAnnex;
