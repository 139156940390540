"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const api_1 = require("src/api");
const react_1 = require("react");
const useDebugPage = (props) => {
    const [debugAccountId, setDebugAccountId] = (0, react_1.useState)('');
    const handleNotification = async (accountId) => {
        const testedAccountId = /^\d+$/.test(accountId);
        if (!testedAccountId) {
            return {
                errors: [{ name: 'Account Id not found', message: 'ავტორიზებული იუზერის ID არ არის ვალიდური ციფრი' }],
                status: apiGlobalTypes_1.ResponseStatuses.UNEXPECTED
            };
        }
        const res = await api_1.DebuggingApi.debugNotification(accountId);
        return { errors: res.errors, status: res.status };
    };
    (0, react_1.useEffect)(() => {
        if (props.authUser)
            setDebugAccountId(props.authUser.accountId);
    }, [props.authUser]);
    return { debugAccountId, setDebugAccountId, handleNotification };
};
exports.default = useDebugPage;
