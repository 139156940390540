"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const types_1 = require("api/documents/purchases/procurements/types");
const constants_1 = require("../helpers/constants");
const ReadOnlyProcurementContract = (props) => {
    return ((0, jsx_runtime_1.jsx)("div", { className: 'card-body p-3', children: (0, jsx_runtime_1.jsxs)("div", { className: 'submission-item-row', children: [(0, jsx_runtime_1.jsxs)("div", { className: 'ml-3', children: [(0, jsx_runtime_1.jsx)("strong", { children: "\u10D9\u10DD\u10DC\u10E2\u10E0\u10D0\u10E5\u10E2\u10D8\u10E1 \u10E2\u10D8\u10DE\u10D8:\u00A0" }), props.procurementType?.label] }), (0, jsx_runtime_1.jsxs)("div", { className: 'ml-3', children: [(0, jsx_runtime_1.jsx)("strong", { children: "\u10E8\u10D0\u10D1\u10DA\u10DD\u10DC\u10D8\u10E1 \u10E2\u10D8\u10DE\u10D8:\u00A0" }), props.templateType === types_1.TemplateTypes.ELECTRONIC
                            ? constants_1.procurementType[props.templateType]
                            : props.templateType === types_1.TemplateTypes.CONSOLIDATED
                                ? 'კონსოლიდირებული'
                                : 'გამარტივებული'] })] }) }));
};
exports.default = ReadOnlyProcurementContract;
