"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useToastContext = exports.ToastContext = void 0;
const react_1 = require("react");
exports.ToastContext = (0, react_1.createContext)(null);
const useToastContext = () => {
    const context = (0, react_1.useContext)(exports.ToastContext);
    if (!context) {
        throw new Error('ToastContext უნდა გამოიყენოთ მხოლოდ პროვაიდერის შვილ კომონენტებში');
    }
    return context;
};
exports.useToastContext = useToastContext;
