"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parsePositionTypeConfig = exports.parsePositionTypeConfigs = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parsePositionTypeConfigs = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const positionTypeConfigs = response.content;
    try {
        return {
            data: positionTypeConfigs.data.map(el => {
                return {
                    id: el.id,
                    positionNames: el.attributes.data.map(item => item) || [],
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    createdDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt),
                    updatedDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.updatedAt),
                    positionSubTypeName: el.relationships.positionSubType?.data.attributes.name || null,
                    positionTypeName: el.relationships.positionType.data.attributes.name
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(positionTypeConfigs.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parsePositionTypeConfigs = parsePositionTypeConfigs;
const parsePositionTypeConfig = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const positionTypeConfig = response.content;
    try {
        return {
            data: {
                id: positionTypeConfig.data.id,
                positionNames: positionTypeConfig.data.attributes.data.map(el => el),
                positionType: {
                    id: positionTypeConfig.data.relationships.positionType.data.id,
                    label: positionTypeConfig.data.relationships.positionType.data.attributes.name
                },
                positionSubType: positionTypeConfig.data.relationships.positionSubType && {
                    id: positionTypeConfig.data.relationships.positionSubType.data.id,
                    label: positionTypeConfig.data.relationships.positionSubType.data.attributes.name
                } || null
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parsePositionTypeConfig = parsePositionTypeConfig;
