"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProcurementItemsData = void 0;
const procurementItems_1 = require("../store/procurementItems");
const index_1 = require("api/index");
const react_1 = require("react");
const useProcurementItemsData = (id) => {
    const [state, dispatch] = (0, procurementItems_1.useProcurementItemsReducer)();
    const getProcurementItems = async (page) => {
        dispatch({ type: 'SEND_PROCUREMENT_ITEMS_REQUEST' });
        const items = await index_1.ProcurementsApi.getProcurementItems(id, page);
        dispatch({ type: 'DONE_PROCUREMENT_ITEMS_REQUEST', payload: items });
    };
    (0, react_1.useEffect)(() => {
        getProcurementItems().then();
    }, []);
    return { state, getProcurementItems };
};
exports.useProcurementItemsData = useProcurementItemsData;
exports.default = exports.useProcurementItemsData;
