"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeterminationItemFileTypes = exports.ConditionalSubmissionItemFileTypes = exports.ConditionalSubmissionsSentBy = exports.ConditionalSubmissionSubStatuses = exports.DeterminationsStatuses = exports.ConditionalSubmissionsStatuses = void 0;
var ConditionalSubmissionsStatuses;
(function (ConditionalSubmissionsStatuses) {
    ConditionalSubmissionsStatuses[ConditionalSubmissionsStatuses["DRAFT"] = 1] = "DRAFT";
    // SENT_E_DOC = 2,
    // SENT_CHANCELLERY = 3,
    ConditionalSubmissionsStatuses[ConditionalSubmissionsStatuses["PROGRESS_IN_PURCHASE"] = 4] = "PROGRESS_IN_PURCHASE";
    ConditionalSubmissionsStatuses[ConditionalSubmissionsStatuses["SENT"] = 5] = "SENT";
    ConditionalSubmissionsStatuses[ConditionalSubmissionsStatuses["FINISHED"] = 6] = "FINISHED";
    ConditionalSubmissionsStatuses[ConditionalSubmissionsStatuses["DECLINE"] = 7] = "DECLINE";
    ConditionalSubmissionsStatuses[ConditionalSubmissionsStatuses["SUSPEND"] = 8] = "SUSPEND";
})(ConditionalSubmissionsStatuses || (exports.ConditionalSubmissionsStatuses = ConditionalSubmissionsStatuses = {}));
var DeterminationsStatuses;
(function (DeterminationsStatuses) {
    DeterminationsStatuses[DeterminationsStatuses["DRAFT"] = 1] = "DRAFT";
    DeterminationsStatuses[DeterminationsStatuses["SENT"] = 2] = "SENT";
    DeterminationsStatuses[DeterminationsStatuses["PROGRESS_IN_PURCHASE"] = 3] = "PROGRESS_IN_PURCHASE";
    DeterminationsStatuses[DeterminationsStatuses["DECLINE"] = 4] = "DECLINE";
    DeterminationsStatuses[DeterminationsStatuses["SUSPEND"] = 5] = "SUSPEND";
    DeterminationsStatuses[DeterminationsStatuses["FINISHED"] = 6] = "FINISHED";
})(DeterminationsStatuses || (exports.DeterminationsStatuses = DeterminationsStatuses = {}));
var ConditionalSubmissionSubStatuses;
(function (ConditionalSubmissionSubStatuses) {
    ConditionalSubmissionSubStatuses[ConditionalSubmissionSubStatuses["UNFULFILLED"] = 1] = "UNFULFILLED";
    ConditionalSubmissionSubStatuses[ConditionalSubmissionSubStatuses["HALF_DONE"] = 2] = "HALF_DONE";
    ConditionalSubmissionSubStatuses[ConditionalSubmissionSubStatuses["FULFILLED"] = 3] = "FULFILLED";
})(ConditionalSubmissionSubStatuses || (exports.ConditionalSubmissionSubStatuses = ConditionalSubmissionSubStatuses = {}));
var ConditionalSubmissionsSentBy;
(function (ConditionalSubmissionsSentBy) {
    ConditionalSubmissionsSentBy[ConditionalSubmissionsSentBy["EDOC"] = 1] = "EDOC";
    ConditionalSubmissionsSentBy[ConditionalSubmissionsSentBy["CHANCELLERY"] = 2] = "CHANCELLERY";
})(ConditionalSubmissionsSentBy || (exports.ConditionalSubmissionsSentBy = ConditionalSubmissionsSentBy = {}));
var ConditionalSubmissionItemFileTypes;
(function (ConditionalSubmissionItemFileTypes) {
    ConditionalSubmissionItemFileTypes[ConditionalSubmissionItemFileTypes["EDOC_DOCUMENT"] = 1] = "EDOC_DOCUMENT";
    ConditionalSubmissionItemFileTypes[ConditionalSubmissionItemFileTypes["ADDITIONAL"] = 2] = "ADDITIONAL";
})(ConditionalSubmissionItemFileTypes || (exports.ConditionalSubmissionItemFileTypes = ConditionalSubmissionItemFileTypes = {}));
var DeterminationItemFileTypes;
(function (DeterminationItemFileTypes) {
    DeterminationItemFileTypes[DeterminationItemFileTypes["ADDITIONAL"] = 1] = "ADDITIONAL";
    DeterminationItemFileTypes[DeterminationItemFileTypes["EDOC_DOCUMENT"] = 2] = "EDOC_DOCUMENT";
})(DeterminationItemFileTypes || (exports.DeterminationItemFileTypes = DeterminationItemFileTypes = {}));
