"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useErrorScroll = (props) => {
    (0, react_1.useEffect)(() => {
        if (Object.keys(props.errors).length > 0) {
            const firstInvalid = document.getElementsByClassName('is-invalid')[0];
            if (firstInvalid) {
                firstInvalid.scrollIntoView({ behavior: 'smooth', block: 'center' });
                firstInvalid.focus({ preventScroll: true });
            }
        }
    }, [props.errors]);
};
exports.default = useErrorScroll;
