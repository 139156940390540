"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.structuralUnitValidations = exports.contractFormValuesValidations = void 0;
const types_1 = require("api/references/structuralUnitsUpgraded/types");
const validates_1 = require("core/helpers/validates");
const contractFormValuesValidations = (values) => {
    const formErrors = {
        structuralUnit: (0, validates_1.required)(values.structuralUnit),
        user: (0, validates_1.required)(values.user),
        startDate: (0, validates_1.required)(values.startDate)
    };
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.contractFormValuesValidations = contractFormValuesValidations;
const structuralUnitValidations = (values) => {
    const formErrors = {
        parents: values.type.id === types_1.StructuralUnitType.STRUCTURAL_UNIT ? (0, validates_1.requiredNotEmptyArray)(values.parents) : undefined,
        parent: values.type.id === types_1.StructuralUnitType.POSITION ? (0, validates_1.required)(values.parent) : undefined,
        type: (0, validates_1.required)(values.type),
        useUnitPrefix: values.type.id === types_1.StructuralUnitType.POSITION ? (0, validates_1.required)(values.useUnitPrefix) : undefined,
        name: (0, validates_1.required)(values.name),
        positionType: values.type.id === types_1.StructuralUnitType.POSITION ? (0, validates_1.required)(values.positionType) : undefined,
        positionStatus: values.type.id === types_1.StructuralUnitType.POSITION ? (0, validates_1.required)(values.positionStatus) : undefined,
        basicFunctionDuties: values.type.id === types_1.StructuralUnitType.POSITION ? (0, validates_1.required)(values.basicFunctionDuties) : undefined,
        quantity: values.quantity ? (0, validates_1.requiredPositiveNumber)(values.quantity) : undefined
    };
    const errors = {};
    Object.entries(formErrors).map(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.structuralUnitValidations = structuralUnitValidations;
