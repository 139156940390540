"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const correction_1 = require("../store/correction");
const helpers_1 = require("../helpers");
const react_router_dom_1 = require("react-router-dom");
const useCorrection = () => {
    const [state, dispatch] = (0, correction_1.useCorrectionReducer)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const createCorrection = async (correctedArticleId, values) => {
        const params = (0, helpers_1.transformCorrectionParams)(values);
        dispatch({ type: 'SEND_CORRECTION_CREATE' });
        const correction = await index_1.CorrectionApi.createCorrection(correctedArticleId, params);
        dispatch({ type: 'DONE_CORRECTION_CREATE', payload: correction });
        if (correction.data !== null)
            navigate(`/documents/administrative-orders/${correctedArticleId}/edit`);
        return correction;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    return { state, createCorrection, closeErrorMessage };
};
exports.default = useCorrection;
