"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VerifySubmissionStatus = exports.ProcurementTerminationReasonStatus = exports.CpvStatuses = exports.SubmissionVerifyItemStatus = exports.VerifyActionType = exports.VerifySubmissionProcurementStatus = void 0;
var VerifySubmissionProcurementStatus;
(function (VerifySubmissionProcurementStatus) {
    VerifySubmissionProcurementStatus[VerifySubmissionProcurementStatus["NOT_IN_PROCESS"] = 1] = "NOT_IN_PROCESS";
    VerifySubmissionProcurementStatus[VerifySubmissionProcurementStatus["PARTIALLY_IN_PROCESS"] = 2] = "PARTIALLY_IN_PROCESS";
    VerifySubmissionProcurementStatus[VerifySubmissionProcurementStatus["IN_PROCESS"] = 3] = "IN_PROCESS";
})(VerifySubmissionProcurementStatus || (exports.VerifySubmissionProcurementStatus = VerifySubmissionProcurementStatus = {}));
var VerifyActionType;
(function (VerifyActionType) {
    VerifyActionType[VerifyActionType["PURCHASES"] = 1] = "PURCHASES";
    VerifyActionType[VerifyActionType["BUDGETING"] = 2] = "BUDGETING";
})(VerifyActionType || (exports.VerifyActionType = VerifyActionType = {}));
var SubmissionVerifyItemStatus;
(function (SubmissionVerifyItemStatus) {
    SubmissionVerifyItemStatus[SubmissionVerifyItemStatus["UNPREDICTED"] = 0] = "UNPREDICTED";
    SubmissionVerifyItemStatus[SubmissionVerifyItemStatus["DRAFT"] = 1] = "DRAFT";
    SubmissionVerifyItemStatus[SubmissionVerifyItemStatus["APPROVED"] = 2] = "APPROVED";
    SubmissionVerifyItemStatus[SubmissionVerifyItemStatus["DECLINED"] = 3] = "DECLINED";
    SubmissionVerifyItemStatus[SubmissionVerifyItemStatus["SUSPENDED"] = 4] = "SUSPENDED";
})(SubmissionVerifyItemStatus || (exports.SubmissionVerifyItemStatus = SubmissionVerifyItemStatus = {}));
var CpvStatuses;
(function (CpvStatuses) {
    CpvStatuses[CpvStatuses["PENDING"] = 1] = "PENDING";
    CpvStatuses[CpvStatuses["CONFIRMED"] = 2] = "CONFIRMED";
    CpvStatuses[CpvStatuses["DECLINED"] = 3] = "DECLINED";
    CpvStatuses[CpvStatuses["SUSPENDED"] = 4] = "SUSPENDED";
})(CpvStatuses || (exports.CpvStatuses = CpvStatuses = {}));
var ProcurementTerminationReasonStatus;
(function (ProcurementTerminationReasonStatus) {
    ProcurementTerminationReasonStatus[ProcurementTerminationReasonStatus["BILATERAL_TERMINATION_OF_CONTRACT_REASON"] = 1] = "BILATERAL_TERMINATION_OF_CONTRACT_REASON";
    ProcurementTerminationReasonStatus[ProcurementTerminationReasonStatus["UNILATERAL_TERMINATION_OF_CONTRACT_REASON"] = 2] = "UNILATERAL_TERMINATION_OF_CONTRACT_REASON";
})(ProcurementTerminationReasonStatus || (exports.ProcurementTerminationReasonStatus = ProcurementTerminationReasonStatus = {}));
var VerifySubmissionStatus;
(function (VerifySubmissionStatus) {
    VerifySubmissionStatus[VerifySubmissionStatus["CHECKING_PROCESS"] = 1] = "CHECKING_PROCESS";
    VerifySubmissionStatus[VerifySubmissionStatus["VERIFICATION_PROCESS"] = 2] = "VERIFICATION_PROCESS";
    VerifySubmissionStatus[VerifySubmissionStatus["CONFIRMATION_PROCESS"] = 3] = "CONFIRMATION_PROCESS";
    VerifySubmissionStatus[VerifySubmissionStatus["CONFIRMED"] = 4] = "CONFIRMED";
    VerifySubmissionStatus[VerifySubmissionStatus["PARTIALLY_CONFIRMED"] = 5] = "PARTIALLY_CONFIRMED";
    VerifySubmissionStatus[VerifySubmissionStatus["DECLINED"] = 6] = "DECLINED";
    VerifySubmissionStatus[VerifySubmissionStatus["SUSPENDED"] = 7] = "SUSPENDED";
})(VerifySubmissionStatus || (exports.VerifySubmissionStatus = VerifySubmissionStatus = {}));
