"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const room_1 = require("../store/room");
const index_1 = require("api/index");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useRoom = (props) => {
    const [state, dispatch] = (0, room_1.useRoomReducer)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const getRoom = async (id) => {
        dispatch({ type: 'SEND_ROOM_REQUEST' });
        const room = await index_1.RoomsApi.getRoom(id);
        dispatch({ type: 'DONE_ROOM_REQUEST', payload: room });
    };
    const createRoom = async (values) => {
        const params = (0, helpers_1.transformRoomParams)(values);
        dispatch({ type: 'SEND_ROOM_CREATE' });
        const room = await index_1.RoomsApi.createRoom(params);
        dispatch({ type: 'DONE_ROOM_CREATE', payload: room });
        if (room.data !== null) {
            navigate(`/references/addresses/rooms/${room.data.id}/edit`);
        }
        return room;
    };
    const updateRoom = async (id, values) => {
        const params = (0, helpers_1.transformRoomParams)(values);
        dispatch({ type: 'SEND_ROOM_UPDATE' });
        const room = await index_1.RoomsApi.updateRoom(id, params);
        dispatch({ type: 'DONE_ROOM_UPDATE', payload: room });
        return room;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getRoom(props.id).then(); }, [props.id]);
    return {
        state,
        createRoom,
        updateRoom,
        getRoom,
        closeErrorMessage
    };
};
exports.default = useRoom;
