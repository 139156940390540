"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAddComment = void 0;
const comment_v2_1 = require("../store/comment_v2");
const index_1 = require("api/index");
const react_1 = require("react");
const useAddComment = (props) => {
    const [state, dispatch] = (0, comment_v2_1.useCommentsReducer)();
    const [comment, setComment] = (0, react_1.useState)('');
    const getComments = async () => {
        dispatch({ type: 'SEND_COMMENT_REQUEST' });
        const comments = await index_1.CommonApi.getComments_v2(props.getEndpoint);
        dispatch({ type: 'DONE_COMMENT_REQUEST', payload: comments });
    };
    const addComment = async () => {
        dispatch({ type: 'SEND_ADD_COMMENT' });
        const responseComment = await index_1.CommonApi.addComment_v2(props.addEndpoint, { comment });
        if (responseComment.data !== null)
            setComment('');
        dispatch({ type: 'DONE_ADD_COMMENT', payload: responseComment });
        return { errors: responseComment.errors, status: responseComment.status };
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => {
        if (props.itemId)
            getComments().then();
    }, [props.itemId]);
    return {
        addComment,
        comment,
        setComment,
        state,
        closeErrorMessage
    };
};
exports.useAddComment = useAddComment;
