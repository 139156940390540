"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const react_1 = require("react");
const useFileDownload = (props) => {
    const [link, setLink] = (0, react_1.useState)('');
    const [originalName, setOriginalName] = (0, react_1.useState)('');
    const [fileUid, setFileUid] = (0, react_1.useState)('');
    const [isPageLoading, setIsPageLoading] = (0, react_1.useState)(false);
    const [loadingFile, setLoadingFile] = (0, react_1.useState)(false);
    const downloadFile = async (uid, pageLoading = true) => {
        if (pageLoading)
            setIsPageLoading(true);
        setLoadingFile(true);
        const file = await index_1.FilesApi.downloadFile(uid);
        setLoadingFile(false);
        if (pageLoading)
            setIsPageLoading(false);
        if (file.data) {
            setLink(file.data.link);
            setOriginalName(file.data.originalName);
            setFileUid(file.data.id);
            const link = document.createElement('a');
            link.href = file.data.link;
            link.download = file.data.originalName;
            link.click();
        }
    };
    (0, react_1.useEffect)(() => { if (props.uid)
        downloadFile(props.uid).then(); }, [props.uid]);
    return {
        downloadFile,
        link,
        originalName,
        fileUid,
        loadingFile,
        isPageLoading
    };
};
exports.default = useFileDownload;
