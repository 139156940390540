"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.subStatuses = exports.contractStatuses = exports.transformContractSigningFilterParamsToUrl = exports.transformUrlToContractSigningFilterParams = exports.transformContractSigningParamsToFilterParams = void 0;
const types_1 = require("api/references/employees/types");
const queryString_1 = require("core/helpers/queryString");
const helper_1 = require("src/modules/common/helper");
const transformContractSigningParamsToFilterParams = (params, signatoryAccountId) => {
    const filters = {};
    const paginate = {};
    const filterParams = {
        keyword: params.keyword,
        status: (params.status && Number(params.status.id)) || undefined,
        subStatus: (params.subStatus && Number(params.subStatus.id)) || undefined,
        creator: params.creator?.id,
        authUserAccountId: params.onlyMine ? params.onlyMine : undefined,
        mySignature: params.mySignature ? signatoryAccountId : undefined,
        recorderId: params.myRecorder ? signatoryAccountId : undefined,
        latestSignatoryFrom: params.latestSignatoryFrom ? (0, helper_1.transformParamsDate)(params.latestSignatoryFrom) : undefined,
        latestSignatoryTo: params.latestSignatoryTo ? (0, helper_1.transformParamsDate)(params.latestSignatoryTo) : undefined,
        page: params.page,
        perPage: params.perPage
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if (value && key !== 'page' && key !== 'perPage')
                filters[key] = value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(filters).length === 0)
            return { ...paginate };
        return { ...paginate, filters };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformContractSigningParamsToFilterParams = transformContractSigningParamsToFilterParams;
const transformUrlToContractSigningFilterParams = (url) => {
    const values = (0, queryString_1.parse)(url);
    return {
        status: values.statusId && values.statusLabel ? { id: values.statusId, label: values.statusLabel } : undefined,
        subStatus: values.subStatusId && values.subStatusLabel ? { id: values.subStatusId, label: values.subStatusLabel } : undefined,
        keyword: values.keyword,
        creator: values.creatorId && values.creatorLabel ? { id: values.creatorId, label: values.creatorLabel } : undefined,
        latestSignatoryFrom: values.latestSignatoryFrom,
        latestSignatoryTo: values.latestSignatoryTo,
        onlyMine: values.onlyMine,
        mySignature: !!values.mySignature,
        myRecorder: !!values.myRecorder,
        page: values.page,
        perPage: values.perPage
    };
};
exports.transformUrlToContractSigningFilterParams = transformUrlToContractSigningFilterParams;
//
// export const transformUrlWithoutStatus = (url: string): ContractSigningFilterFormValues => {
// 	const values = parse(url) as ContractSigningParamsForUrl
// 	return {
// 		status: undefined,
// 		keyword: values.keyword,
// 		latestSignatoryFrom: values.latestSignatoryFrom,
// 		latestSignatoryTo: values.latestSignatoryTo,
// 		mySignature: !!values.mySignature,
// 		onlyMine: values.onlyMine,
// 		page: 1,
// 		perPage: values.perPage
// 	}
// }
const transformContractSigningFilterParamsToUrl = (filterParams, signatoryAccountId) => {
    const { page, perPage, keyword, status, subStatus, creator, mySignature, myRecorder, latestSignatoryFrom, latestSignatoryTo, onlyMine } = filterParams;
    return {
        page,
        perPage,
        keyword,
        onlyMine: onlyMine ? signatoryAccountId : undefined,
        creatorId: creator?.id,
        creatorLabel: creator?.label,
        statusId: status && status.id,
        statusLabel: status && status.label,
        subStatusId: subStatus && subStatus.id,
        subStatusLabel: subStatus && subStatus.label,
        mySignature: mySignature ? signatoryAccountId : undefined,
        myRecorder: myRecorder ? signatoryAccountId : undefined,
        latestSignatoryFrom,
        latestSignatoryTo
    };
};
exports.transformContractSigningFilterParamsToUrl = transformContractSigningFilterParamsToUrl;
exports.contractStatuses = [
    { id: String(types_1.ContractAndAnnexStatuses.DRAFT), label: 'პროექტი' },
    { id: String(types_1.ContractAndAnnexStatuses.PENDING), label: 'გასაგზავნი' },
    { id: String(types_1.ContractAndAnnexStatuses.SENT), label: 'გაგზავნილი' },
    { id: String(types_1.ContractAndAnnexStatuses.UNILATERALLY_SIGNED), label: 'ცალმხრივად ხელმოწერილი' },
    { id: String(types_1.ContractAndAnnexStatuses.PARTIALLY_SIGNED), label: 'ნაწილობრივ ხელმოწერილი' },
    { id: String(types_1.ContractAndAnnexStatuses.SIGNED), label: 'ორმხრივად ხელმოწერილი' },
    { id: String(types_1.ContractAndAnnexStatuses.STATUS_FAULTED), label: 'დახარვეზებული' },
    { id: String(types_1.ContractAndAnnexStatuses.CORRECTED), label: 'ცვლილებით' }
];
exports.subStatuses = [
    { id: String(types_1.ContractAndAnnexSubStatuses.PENDING), label: 'აღსარიცხი' },
    { id: String(types_1.ContractAndAnnexSubStatuses.RECORDED), label: 'აღრიცხული' }
];
