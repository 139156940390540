"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sentEmailsReducer = exports.useSentEmailsReducer = exports.initialSentEmailsState = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
exports.initialSentEmailsState = {
    data: [],
    status: null,
    meta: store_1.initialMeta,
    filterForm: {},
    errors: null,
    isFetching: true,
    sendingIds: []
};
const useSentEmailsReducer = () => {
    return (0, react_1.useReducer)(exports.sentEmailsReducer, exports.initialSentEmailsState);
};
exports.useSentEmailsReducer = useSentEmailsReducer;
const sentEmailsReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_SENT_EMAILS_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_SENT_EMAILS_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                status: action.payload.status,
                errors: action.payload.errors,
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'SEND_EMAIL_REQUEST':
            return {
                ...state,
                sendingIds: [...state.sendingIds, action.payload]
            };
        case 'DONE_EMAIL_REQUEST':
            return {
                ...state,
                data: state.data.map(el => el.id === action.payload.sentEmailData.data?.id
                    ? action.payload.sentEmailData.data
                    : el) || [],
                status: action.payload.sentEmailData.status,
                errors: action.payload.sentEmailData.errors,
                sendingIds: state.sendingIds.filter(sendingId => sendingId !== action.payload.id)
            };
        case 'INITIALIZE_FILTER_FORM':
            return {
                ...state,
                filterForm: action.payload
            };
        case 'CHANGE_PER_PAGE':
            return {
                ...state,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        perPage: action.payload
                    }
                },
                filterForm: action.payload
            };
        default:
            return state;
    }
};
exports.sentEmailsReducer = sentEmailsReducer;
