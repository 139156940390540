"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProcurementCalculateItemsReducer = void 0;
const react_1 = require("react");
const initialProcurementCalculateItemsStore = {
    data: {
        projects: [],
        submissions: [],
        thisProcurementSum: 0,
        unusedProcurementSum: 0,
        otherProcurementSum: 0,
        alteredThisProcurementSum: 0,
        alteredOtherProcurementSum: 0
    },
    errors: null,
    isFetching: true
};
const useProcurementCalculateItemsReducer = () => {
    return (0, react_1.useReducer)(ProcurementCalculateItemsReducer, initialProcurementCalculateItemsStore);
};
exports.useProcurementCalculateItemsReducer = useProcurementCalculateItemsReducer;
const ProcurementCalculateItemsReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_PROCUREMENT_CALCULATE_ITEMS_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_PROCUREMENT_CALCULATE_ITEMS_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors || null,
                isFetching: false
            };
        default:
            return state;
    }
};
