"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const index_1 = require("api/index");
const useUnauthorizedMediaFile = () => {
    const [file, setFile] = (0, react_1.useState)(null);
    const [loading, setIsLoading] = (0, react_1.useState)(false);
    const getUnauthorizedMediaFile = async (uid) => {
        setIsLoading(true);
        const response = await index_1.EmployeesApi.downloadUnauthorizedMediaFile(uid);
        setIsLoading(false);
        return response;
    };
    return { file, loading, setFile, getUnauthorizedMediaFile };
};
exports.default = useUnauthorizedMediaFile;
