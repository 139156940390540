"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseFile = exports.parseShortName = exports.exchangeToGEL = exports.checkSubmissionAlert = exports.transformDateForDateObject = exports.transformDateParams = exports.gerDistanceInMilliseconds = exports.optionalTransformDate = exports.nullableTransformDate = exports.transformDate = exports.transformDateTime = exports.transformPagination = void 0;
const react_1 = require("@sentry/react");
const date_fns_1 = require("date-fns");
const transformPagination = (meta) => {
    if (!meta || !meta.pagination)
        return;
    return {
        pagination: {
            count: meta.pagination.count,
            currentPage: meta.pagination.currentPage,
            perPage: meta.pagination.perPage,
            total: meta.pagination.total,
            totalPages: meta.pagination.totalPages
        }
    };
};
exports.transformPagination = transformPagination;
const transformDateTime = (dateTime, dateFormat) => {
    if (!dateTime)
        return null;
    const formatStyle = dateFormat ?? 'dd-MM-yyyy HH:mm:ss';
    return (0, date_fns_1.format)(new Date(dateTime), formatStyle);
};
exports.transformDateTime = transformDateTime;
const transformDate = (dateTime, dateFormat) => {
    if (!dateTime) {
        (0, react_1.captureException)('dateTime mustn\'t be null');
        return '';
    }
    const formatStyle = dateFormat ?? 'dd-MM-yyyy';
    return (0, date_fns_1.format)(new Date(dateTime), formatStyle);
};
exports.transformDate = transformDate;
const nullableTransformDate = (dateTime, dateFormat) => {
    if (!dateTime)
        return null;
    const formatStyle = dateFormat ?? 'dd-MM-yyyy';
    return (0, date_fns_1.format)(new Date(dateTime), formatStyle);
};
exports.nullableTransformDate = nullableTransformDate;
const optionalTransformDate = (dateTime, dateFormat) => {
    if (!dateTime)
        return undefined;
    const formatStyle = dateFormat ?? 'dd-MM-yyyy';
    return (0, date_fns_1.format)(new Date(dateTime), formatStyle);
};
exports.optionalTransformDate = optionalTransformDate;
const gerDistanceInMilliseconds = (from, to) => {
    if (!from || !to)
        return null;
    return new Date(to).getTime() - new Date(from).getTime();
};
exports.gerDistanceInMilliseconds = gerDistanceInMilliseconds;
const transformDateParams = (date) => {
    if (!date)
        return null;
    const dateArray = date.split('-');
    return dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
};
exports.transformDateParams = transformDateParams;
const transformDateForDateObject = (date) => {
    if (!date)
        return null;
    const dateArray = date.split('-');
    return dateArray[1] + '-' + dateArray[0] + '-' + dateArray[2];
};
exports.transformDateForDateObject = transformDateForDateObject;
const checkSubmissionAlert = (expirationDate) => {
    if (!expirationDate)
        return false;
    const now = new Date();
    now.setDate(now.getDate() - 3);
    return now < new Date(expirationDate);
};
exports.checkSubmissionAlert = checkSubmissionAlert;
const exchangeToGEL = (price, currency, exchangeRate) => {
    const quantity = currency?.attributes.quantity || 1;
    const rate = exchangeRate || currency?.attributes.rate || 1;
    return price * rate / quantity;
};
exports.exchangeToGEL = exchangeToGEL;
const parseShortName = (name, length = 60) => {
    if (name.length > length) {
        return name.substring(0, length);
    }
    else {
        return null;
    }
};
exports.parseShortName = parseShortName;
const parseFile = (file) => {
    return {
        id: file.id,
        name: file.attributes.name,
        status: file.attributes.status,
        link: file.attributes.downloadUrl,
        originalName: file.attributes.originalName,
        extension: file.attributes.extension
    };
};
exports.parseFile = parseFile;
