"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HRAssignSubmissionReducer = exports.useCreateOrUpdateItemsReducer = exports.initialEmployee = exports.initialPositionInfo = exports.initialProjectInfo = exports.initialWorkInfo = exports.initialSupplement = exports.initialRemunerationWork = exports.initialUnitFee = exports.initialFixedReservation = void 0;
const types_1 = require("api/documents/human-resources/assign-submissions/types");
const react_1 = require("react");
const helper_1 = require("../../../../common/helper");
const store_1 = require("core/store");
const parsers_1 = require("api/files/parsers");
exports.initialFixedReservation = {
    id: null,
    year: new Date().getFullYear(),
    isDate: true,
    isContractFullPeriod: true,
    isValidYear: true,
    dateRange: '',
    months: [],
    currency: store_1.initialCurrency,
    salaryGivenType: null,
    equivalentCurrency: store_1.initialCurrency,
    salaryAmount: null
};
exports.initialUnitFee = {
    id: null,
    tariff: null,
    unitName: null,
    salaryAmount: null,
    volumeWorkPerformed: null,
    salaryGivenType: null,
    amount: null,
    currency: store_1.initialCurrency,
    equivalentCurrency: store_1.initialCurrency,
    administrativeOrdersArticle: null
};
exports.initialRemunerationWork = {
    id: null,
    year: new Date().getFullYear(),
    isDate: true,
    isContractFullPeriod: true,
    dateRange: '',
    currency: store_1.initialCurrency,
    months: [],
    equivalentCurrency: store_1.initialCurrency,
    salaryAmount: null,
    salaryGivenType: null,
    volumeWorkPerformed: null,
    isValidYear: true
};
exports.initialSupplement = {
    id: null,
    year: new Date().getFullYear(),
    dateRange: '',
    isDate: true,
    isContractFullPeriod: true,
    currency: store_1.initialCurrency,
    equivalentCurrency: store_1.initialCurrency,
    salaryGivenType: null,
    months: [],
    supplementAmount: null,
    isValidYear: true
};
exports.initialWorkInfo = {
    id: null,
    salaryAmount: null,
    salaryType: null,
    remunerationWork: null,
    workType: null,
    remunerationWorks: [],
    unitFees: [],
    fixedReservations: [],
    supplements: [],
    workload: null,
    currency: store_1.initialCurrency,
    equivalentCurrency: store_1.initialCurrency,
    salaryGivenType: null
};
exports.initialProjectInfo = {
    id: null,
    countComments: 0,
    project: null,
    needsAssignVerify: null,
    workInfos: [exports.initialWorkInfo]
};
exports.initialPositionInfo = {
    id: null,
    position: null,
    startDate: null,
    minStartDate: (0, helper_1.getTomorrow)(),
    endDate: null,
    positionType: null,
    alert: null,
    minDate: null,
    workPlaces: [],
    basicFunctionDuties: null,
    structuralUnit: null,
    additionalInfo: null,
    projectInfos: [exports.initialProjectInfo],
    maxContractEndDate: null,
    hasProject: false,
    workDirection: null,
    selectWorkDirection: null
};
exports.initialEmployee = {
    id: null,
    createdAt: '',
    updatedAt: '',
    personalNumber: null,
    citizen: types_1.Citizen.GEORGIAN,
    passportNumber: null,
    firstname: null,
    lastname: null,
    phoneNumber: null,
    isEdocUser: false,
    openBankAccount: false,
    openCorporateEmail: false,
    checked: false,
    email: null,
    hasNoEmail: false,
    files: {
        cv: [{ ...parsers_1.initialFile, type: types_1.AssignSubmissionItemFileTypes.CV }],
        diploma: [{ ...parsers_1.initialFile, type: types_1.AssignSubmissionItemFileTypes.DIPLOMA }],
        passport: [],
        bankingRequisites: [{ ...parsers_1.initialFile, type: types_1.AssignSubmissionItemFileTypes.BANKING_REQUISITES }],
        taxCertificate: [{ ...parsers_1.initialFile, type: types_1.AssignSubmissionItemFileTypes.TAX_CERTIFICATE }],
        conviction: [{ ...parsers_1.initialFile, type: types_1.AssignSubmissionItemFileTypes.CONVICTION }],
        other: [{ ...parsers_1.initialFile, type: types_1.AssignSubmissionItemFileTypes.OTHER }]
    },
    positionInfos: [exports.initialPositionInfo],
    legalAddress: null,
    physicalAddress: null,
    isDifferentAddress: true,
    assignSubmissionVerify: null
};
const initialCreateOrUpdateItemsState = {
    data: [],
    meta: store_1.initialMeta,
    errors: null,
    isCreating: false,
    isFetching: false,
    isDeleting: false,
    isLoading: false,
    isUpdatingItemsList: false,
    isUpdatingItem: false,
    isCreatingHRVerify: false
};
const useCreateOrUpdateItemsReducer = () => {
    return (0, react_1.useReducer)(exports.HRAssignSubmissionReducer, initialCreateOrUpdateItemsState);
};
exports.useCreateOrUpdateItemsReducer = useCreateOrUpdateItemsReducer;
const HRAssignSubmissionReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_HR_SUBMISSION_ITEMS_REQUEST':
            return {
                ...state,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_HR_SUBMISSION_ITEMS_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                meta: action.payload.meta || state.meta,
                errors: action.payload.errors,
                isFetching: false,
                isLoading: state.isCreating || state.isDeleting || state.isCreatingHRVerify
            };
        case 'SEND_CREATE_HR_SUBMISSION_ITEMS':
            return {
                ...state,
                isCreating: true,
                isLoading: true
            };
        case 'SEND_HR_SUBMISSION_ITEM_DELETE_REQUEST':
            return {
                ...state,
                isDeleting: true,
                isLoading: true
            };
        case 'DONE_HR_SUBMISSION_ITEM_DELETE_REQUEST':
            return {
                ...state,
                isDeleting: false,
                data: state.data.filter(el => !action.payload.includes(el.id)),
                isLoading: state.isFetching || state.isCreating || state.isCreatingHRVerify,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        total: state.meta.pagination.total - action.payload.length,
                        count: state.meta.pagination.count - action.payload.length
                    }
                }
            };
        case 'SEND_ITEMS_LIST_UPDATE_REQUEST':
            return {
                ...state,
                errors: null,
                isUpdatingItemsList: true
            };
        case 'DONE_ITEMS_LIST_UPDATE_REQUEST':
            return {
                ...state,
                data: action.payload.assignSubmissionItems.data || state.data,
                isUpdatingItemsList: false,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        total: state.meta.pagination.total + action.payload.newItemsLength,
                        count: state.meta.pagination.count + action.payload.newItemsLength
                    }
                }
            };
        case 'SEND_ITEM_UPDATE_REQUEST':
            return {
                ...state,
                errors: null,
                isUpdatingItem: true
            };
        case 'DONE_ITEM_UPDATE_REQUEST':
            return {
                ...state,
                data: action.payload.item ? state.data.map((el, index) => {
                    if (index === action.payload.index) {
                        return action.payload.item || el;
                    }
                    else {
                        return el;
                    }
                }) : state.data,
                isUpdatingItem: false
            };
        case 'DONE_ITEM_CREATE_REQUEST':
            return {
                ...state,
                data: action.payload.item ? insertItemInData(state.data, action.payload.item, action.payload.index) : state.data,
                isUpdatingItem: false,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        total: state.meta.pagination.total + 1,
                        count: state.meta.pagination.count + 1
                    }
                }
            };
        case 'DONE_CREATE_HR_SUBMISSION_ITEMS':
            return {
                ...state,
                data: action.payload.data || state.data,
                meta: action.payload.meta || state.meta,
                isCreating: false,
                isLoading: state.isFetching || state.isDeleting || state.isCreatingHRVerify
            };
        case 'SEND_CREATE_HR_VERIFY_REQUEST':
            return {
                ...state,
                isCreatingHRVerify: true
            };
        case 'DONE_CREATE_HR_VERIFY_REQUEST':
            return {
                ...state,
                errors: action.payload.errors,
                isCreatingHRVerify: false
            };
        case 'UPDATE_STATE_ITEMS':
            return {
                ...state,
                data: action.payload
            };
        case 'CHANGE_PER_PAGE':
            return {
                ...state,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        perPage: action.payload
                    }
                }
            };
        default:
            return state;
    }
};
exports.HRAssignSubmissionReducer = HRAssignSubmissionReducer;
const insertItemInData = (data, item, index) => {
    const newData = [...data];
    newData[index] = item;
    return newData;
};
