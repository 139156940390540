"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inventorySpecificationReducer = exports.useInventorySpecificationReducer = exports.initialInventorySpecificationState = void 0;
const react_1 = require("react");
const initialInventorySpecificationValue = { name: '' };
exports.initialInventorySpecificationState = {
    data: initialInventorySpecificationValue,
    status: null,
    errors: null,
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isLoading: false
};
const useInventorySpecificationReducer = () => {
    return (0, react_1.useReducer)(exports.inventorySpecificationReducer, exports.initialInventorySpecificationState);
};
exports.useInventorySpecificationReducer = useInventorySpecificationReducer;
const inventorySpecificationReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_INVENTORY_SPECIFICATION_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_INVENTORY_SPECIFICATION_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isFetching: false,
                isLoading: state.isCreating || state.isUpdating
            };
        case 'SEND_INVENTORY_SPECIFICATION_CREATE':
            return {
                ...state,
                errors: null,
                isCreating: true,
                isLoading: true
            };
        case 'DONE_INVENTORY_SPECIFICATION_CREATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isCreating: false,
                isLoading: state.isFetching || state.isUpdating
            };
        case 'SEND_INVENTORY_SPECIFICATION_UPDATE':
            return {
                ...state,
                errors: null,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_INVENTORY_SPECIFICATION_UPDATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isCreating: false,
                isLoading: state.isCreating || state.isFetching
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.inventorySpecificationReducer = inventorySpecificationReducer;
