"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const PositionSubType_1 = require("../store/PositionSubType");
const index_1 = require("api/index");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const usePositionSubType = (props) => {
    const [state, dispatch] = (0, PositionSubType_1.usePositionSubTypeReducer)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const getPositionSubType = async (id) => {
        dispatch({ type: 'SEND_POSITION_SUB_TYPE_REQUEST' });
        const positionSubType = await index_1.PositionSubTypesApi.getPositionSubType(id);
        dispatch({ type: 'DONE_POSITION_SUB_TYPE_REQUEST', payload: positionSubType });
    };
    const createPositionSubType = async (values) => {
        const params = (0, helpers_1.transformPositionSubTypesParams)(values);
        dispatch({ type: 'SEND_POSITION_SUB_TYPE_CREATE' });
        const positionSubType = await index_1.PositionSubTypesApi.createPositionSubType(params);
        dispatch({ type: 'DONE_POSITION_SUB_TYPE_CREATE', payload: positionSubType });
        if (positionSubType.data !== null) {
            navigate(`/references/human-resources/position-sub-types/${positionSubType.data.id}/edit`);
        }
        return positionSubType;
    };
    const updatePositionSubType = async (id, values) => {
        const params = (0, helpers_1.transformPositionSubTypesParams)(values);
        dispatch({ type: 'SEND_POSITION_SUB_TYPE_UPDATE' });
        const positionSubType = await index_1.PositionSubTypesApi.updatePositionSubType(id, params);
        dispatch({ type: 'DONE_POSITION_SUB_TYPE_UPDATE', payload: positionSubType });
        return positionSubType;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getPositionSubType(props.id).then(); }, [props.id]);
    return { state, getPositionSubType, createPositionSubType, updatePositionSubType, closeErrorMessage };
};
exports.default = usePositionSubType;
