"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const correctionSubmissionOrLetter_1 = require("../store/correctionSubmissionOrLetter");
const react_1 = require("react");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const index_1 = require("api/index");
const helpers_1 = require("../helpers");
const react_router_dom_1 = require("react-router-dom");
const useCreateProcurementCorrection = (props) => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const [state, dispatch] = (0, correctionSubmissionOrLetter_1.useCorrectionSubmissionOrLetterReducer)();
    const [structuralUnits, setStructuralUnits] = (0, react_1.useState)([]);
    const [loading, setLoading] = (0, react_1.useState)(false);
    const [updating, setUpdating] = (0, react_1.useState)(false);
    const [submissionLoading, setSubmissionLoading] = (0, react_1.useState)(false);
    const [disableSubmissionAttribute, setDisableSubmissionAttributes] = (0, react_1.useState)(false);
    const isMounted = (0, react_1.useRef)(false);
    const getProcurementCorrection = async () => {
        dispatch({ type: 'SEND_CORRECTION_SUBMISSION_OR_LETTER_REQUEST' });
        const correction = await index_1.ProcurementCorrectionsApi.getLetterOrSubmissionCorrectionForm(props.letterOrSubmissionId);
        if (isMounted.current) {
            if (correction)
                dispatch({ type: 'DONE_CORRECTION_SUBMISSION_OR_LETTER_REQUEST', payload: { data: correction, errors: null } });
        }
    };
    const getSubmissionItemsForProcurement = async (submissionId) => {
        const submissionItems = await index_1.SubmissionsApi.getSubmissionItemsForm(submissionId, { perPage: 100, filters: { withoutProcurementItems: true } });
        return {
            status: submissionItems.status,
            errors: submissionItems.errors,
            data: submissionItems.data
                ? submissionItems.data.map(el => ({ ...el, checked: false, disabled: false }))
                : submissionItems.data
        };
    };
    const updateLetterOrSubmission = async (values, procurementId, letterOrSubmissionId, type) => {
        const params = (0, helpers_1.transformCreateCorrectionParams)(values, type);
        setUpdating(true);
        const response = await index_1.ProcurementCorrectionsApi.updateLetterOrSubmissionForm(letterOrSubmissionId, params);
        setUpdating(false);
        if (response !== null)
            navigate(`/documents/purchases/procurements/${procurementId}/corrections`);
        return response;
    };
    const createProcurementCorrection = async (values, procurementId, type) => {
        const params = (0, helpers_1.transformCreateCorrectionParams)(values, type);
        setLoading(true);
        const procurementCorrection = await index_1.ProcurementCorrectionsApi.creteProcurementCorrection(procurementId, params);
        setLoading(false);
        if (procurementCorrection.status === apiGlobalTypes_1.ResponseStatuses.CREATED)
            navigate(`/documents/purchases/procurements/${procurementId}/corrections`);
        return procurementCorrection;
    };
    const handleSelectSubmission = async (id) => {
        if (id)
            setDisableSubmissionAttributes(true);
        setSubmissionLoading(true);
        const submission = await index_1.SubmissionsApi.getSubmission(id);
        if (submission.data === null)
            return null;
        const submissionSelect = {
            id: null,
            submissionId: submission.data.id,
            submissionNumber: submission.data.number,
            letterOrSubmissionFiles: submission.data.files,
            submissionRequestRegistration: submission.data.date,
            submissionAuthor: submission.data.employee,
            submissionAuthorPosition: submission.data.structuralUnit,
            submissionDeadline: submission.data.expirationDate,
            submissionExecution: submission.data.executionDate,
            selectAll: false
        };
        setSubmissionLoading(false);
        return submissionSelect;
    };
    const setSubmissionAuthor = (_name, value) => {
        if (value === null)
            return setStructuralUnits([]);
        setStructuralUnits(value.structuralUnits || []);
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        if (props.letterOrSubmissionId)
            getProcurementCorrection().then();
        return () => { isMounted.current = false; };
    }, []);
    return {
        structuralUnits,
        setSubmissionAuthor,
        createProcurementCorrection,
        loading,
        handleSelectSubmission,
        submissionLoading,
        disableSubmissionAttribute,
        setDisableSubmissionAttributes,
        updateLetterOrSubmission,
        updating,
        state,
        getSubmissionItemsForProcurement
    };
};
exports.default = useCreateProcurementCorrection;
