"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useAssignSubmissionProjectsInfo = () => {
    const [showActions, setShowActions] = (0, react_1.useState)(null);
    const hideShowMenu = () => {
        setShowActions(null);
    };
    const handleShowActionMenu = (id) => {
        if (showActions === id)
            return setShowActions(null);
        setShowActions(id);
        if (id) {
            window.addEventListener('click', hideShowMenu, true);
        }
        else {
            window.removeEventListener('click', hideShowMenu, true);
        }
    };
    return {
        showActions,
        handleShowActionMenu
    };
};
exports.default = useAssignSubmissionProjectsInfo;
