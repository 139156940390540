"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const useAssignSubmissionItems_1 = require("../store/useAssignSubmissionItems");
const index_1 = require("api/index");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const useItemsListHook = (props) => {
    const [state, dispatch] = (0, useAssignSubmissionItems_1.useAssignSubmissionItemsReducer)();
    const getAssignSubmissionItems = async (params) => {
        dispatch({ type: 'SEND_ASSIGN_SUBMISSION_ITEMS_REQUEST' });
        const items = await index_1.HumanResourcesSubmissionVerifiesApi.getAssignSubmissionItemsForHrVerify(props.assignSubmissionId, params);
        dispatch({ type: 'DONE_ASSIGN_SUBMISSION_ITEMS_REQUEST', payload: items });
    };
    const navigatePagination = (page, values) => {
        const params = (0, helpers_1.transformAssignSubmissionItemsFilterParams)(values);
        getAssignSubmissionItems({ ...params, page }).then();
    };
    const selectPerPage = (perPage, values) => {
        const params = (0, helpers_1.transformAssignSubmissionItemsFilterParams)(values);
        getAssignSubmissionItems({ ...params, perPage }).then();
    };
    const debouncedSearch = (values) => {
        const params = (0, helpers_1.transformAssignSubmissionItemsFilterParams)(values);
        (0, debouncedMethods_1.debouncedOnFunc)(params, getAssignSubmissionItems);
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => {
        getAssignSubmissionItems({}).then();
    }, []);
    return {
        state,
        debouncedSearch,
        navigatePagination,
        selectPerPage,
        closeErrorMessage
    };
};
exports.default = useItemsListHook;
