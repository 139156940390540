"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const inventoryItems_1 = require("../store/inventoryItems");
const index_1 = require("api/index");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const queryString_1 = require("core/helpers/queryString");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const react_router_1 = require("react-router");
const useInventoryItems = () => {
    const navigate = (0, react_router_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, inventoryItems_1.useInventoryItemsReducer)();
    const [showDeleteAlert, setShowDeleteAlert] = (0, react_1.useState)(false);
    const [toDeleteId, setToDeleteId] = (0, react_1.useState)(null);
    const [linkParams, setLinkParams] = (0, react_1.useState)('');
    const [inventoryItemId, setInventoryItemId] = (0, react_1.useState)(null);
    const isMounted = (0, react_1.useRef)(false);
    const navigatePagination = (page, values) => {
        getInventoryItems({ ...values, page }).then();
    };
    const filter = (name, value, filterValues) => {
        getInventoryItems({ ...filterValues, [name]: value, page: 1 }).then();
    };
    const selectPerPage = (perPage, values) => {
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'CHANGE_PER_PAGE', payload: { ...values, perPage } });
        getInventoryItems({ ...params, perPage, page: undefined }).then();
    };
    const deleteAlert = (id) => {
        setShowDeleteAlert(true);
        setToDeleteId(id);
    };
    const debouncedSearch = (name, value, filterValues) => {
        (0, debouncedMethods_1.debouncedOnFunc)({ ...filterValues, [name]: value, page: 1 }, getInventoryItems);
    };
    const deleteInventoryItem = async (confirm) => {
        setShowDeleteAlert(false);
        if (!toDeleteId || !confirm)
            return setToDeleteId(null);
        dispatch({ type: 'SEND_INVENTORY_ITEM_DELETE_REQUEST' });
        const inventoryItems = await index_1.PurchasesInventoryItemsApi.deleteInventoryItem(toDeleteId);
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'DONE_INVENTORY_ITEM_DELETE_REQUEST', payload: { errors: inventoryItems.errors } });
        if (inventoryItems.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            getInventoryItems(params).then();
        setToDeleteId(null);
    };
    const getInventoryItems = async (values) => {
        dispatch({ type: 'SEND_INVENTORY_ITEMS_REQUEST' });
        const filterParams = (0, helpers_1.transformInventoryItemParamsToFilterParams)(values);
        const inventoryItems = await index_1.PurchasesInventoryItemsApi.getInventoryItems(filterParams);
        if (inventoryItems.data !== null)
            setLinkParams((0, queryString_1.stringify)((0, helpers_1.transformInventoryItemFilterParamsToUrl)(values)));
        if (isMounted?.current) {
            dispatch({ type: 'DONE_INVENTORY_ITEMS_REQUEST', payload: inventoryItems });
            navigate('/references/purchases/purchase-items' + (0, queryString_1.stringify)((0, helpers_1.transformInventoryItemFilterParamsToUrl)(values)), { replace: true });
        }
    };
    const initializeFilterForm = async () => {
        const params = (0, helpers_1.transformUrlToInventoryItemFilterParams)(location.search);
        const inventoryItemListId = (0, queryString_1.parse)(location.search).inventoryItemId;
        getInventoryItems(params).then(() => dispatch({ type: 'INITIALIZE_FILTER_FORM', payload: params }));
        setInventoryItemId(inventoryItemListId);
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        initializeFilterForm().then();
        return () => { isMounted.current = false; };
    }, []);
    return {
        state,
        navigatePagination,
        selectPerPage,
        debouncedSearch,
        linkParams,
        filter,
        deleteAlert,
        deleteInventoryItem,
        toDeleteId,
        showDeleteAlert,
        getInventoryItems,
        inventoryItemId
    };
};
exports.default = useInventoryItems;
