"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrenciesReducer = exports.useCurrenciesReducer = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
const initialCurrencies = {
    data: [],
    status: null,
    rates: [],
    errors: null,
    meta: store_1.initialMeta,
    metaForRates: store_1.initialMeta,
    isFetching: true,
    isDeleting: false,
    isRateFetching: false
};
const useCurrenciesReducer = () => {
    return (0, react_1.useReducer)(exports.CurrenciesReducer, initialCurrencies);
};
exports.useCurrenciesReducer = useCurrenciesReducer;
const CurrenciesReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_CURRENCIES_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_CURRENCIES_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                status: action.payload.status,
                errors: action.payload.errors,
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'SEND_DELETE_REQUEST':
            return {
                ...state,
                isDeleting: true
            };
        case 'DONE_DELETE_REQUEST':
            return {
                ...state,
                isDeleting: false,
                errors: action.payload.errors
            };
        case 'SEND_CURRENCY_RATE_REQUEST':
            return {
                ...state,
                isRateFetching: true
            };
        case 'DONE_CURRENCY_RATE_REQUEST':
            return {
                ...state,
                status: action.payload.status,
                errors: action.payload.errors,
                rates: action.payload.data || [],
                metaForRates: action.payload.metaForRates || store_1.initialMeta,
                isRateFetching: false
            };
        case 'CHANGE_PER_PAGE':
            return {
                ...state,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        perPage: action.payload
                    }
                }
            };
        case 'CHANGE_PER_PAGE_FOR_RATES':
            return {
                ...state,
                metaForRates: {
                    ...state.metaForRates,
                    pagination: {
                        ...state.metaForRates.pagination,
                        perPage: action.payload
                    }
                }
            };
        default:
            return state;
    }
};
exports.CurrenciesReducer = CurrenciesReducer;
