"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAssignSubmissionItemReducer = exports.initialAssignSubmissionItemStore = void 0;
const react_1 = require("react");
exports.initialAssignSubmissionItemStore = {
    data: [],
    creatingWorkType: false
};
const useAssignSubmissionItemReducer = () => {
    return (0, react_1.useReducer)(assignSubmissionItemReducer, exports.initialAssignSubmissionItemStore);
};
exports.useAssignSubmissionItemReducer = useAssignSubmissionItemReducer;
const assignSubmissionItemReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_CREATE_WORK_TYPE_REQUEST':
            return {
                ...state,
                creatingWorkType: true
            };
        case 'DONE_CREATE_WORK_TYPE_REQUEST':
            return {
                ...state,
                creatingWorkType: false
            };
    }
};
