"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loginAsUser = exports.loginAs = exports.updateUser = exports.createUser = exports.getUser = exports.deleteUser = exports.getUsers = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("./parsers");
const getUsers = async (params) => {
    const response = await Api.get('administer/users', params);
    return (0, parsers_1.parseUsers)(response);
};
exports.getUsers = getUsers;
const deleteUser = async (id) => {
    return await Api.deleteItem('administer/users/' + id);
};
exports.deleteUser = deleteUser;
const getUser = async (id) => {
    const response = await Api.get('administer/users/' + id);
    return (0, parsers_1.parseUser)(response);
};
exports.getUser = getUser;
const createUser = async (params) => {
    const response = await Api.post('administer/users', params);
    return (0, parsers_1.parseUser)(response);
};
exports.createUser = createUser;
const updateUser = async (id, params) => {
    const response = await Api.patch('administer/users/' + id, params);
    return (0, parsers_1.parseUser)(response);
};
exports.updateUser = updateUser;
const loginAs = async (id, params) => {
    const response = await Api.post(`auth/emulate/${id}`, params);
    return (0, parsers_1.parseLoginAs)(response);
};
exports.loginAs = loginAs;
const loginAsUser = async (params) => {
    const response = await Api.post('auth/emulate/finish', params);
    return (0, parsers_1.parseLoginAs)(response);
};
exports.loginAsUser = loginAsUser;
