"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const companiesListing_1 = require("../store/companiesListing");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const queryString_1 = require("core/helpers/queryString");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useCompanies = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, companiesListing_1.useCompaniesReducer)();
    const [showDeleteAlert, setShowDeleteAlert] = (0, react_1.useState)(false);
    const [deletedCompanyId, setDeletedCompanyId] = (0, react_1.useState)(null);
    const [linkParams, setLinkParams] = (0, react_1.useState)('');
    const [companyId, setCompanyId] = (0, react_1.useState)(null);
    const isMounted = (0, react_1.useRef)(false);
    const filter = (name, value, filterValues) => {
        getCompanies({ ...filterValues, [name]: value, page: 1 }).then();
    };
    const navigatePagination = (page, values) => {
        getCompanies({ ...values, page }).then();
    };
    const selectPerPage = (perPage, values) => {
        dispatch({ type: 'CHANGE_PER_PAGE', payload: { ...values, perPage } });
        getCompanies({ ...values, perPage, page: 1 }).then();
    };
    const deleteAlert = (id) => {
        setShowDeleteAlert(true);
        setDeletedCompanyId(id);
    };
    const debouncedSearch = (name, value, filterValues) => {
        (0, debouncedMethods_1.debouncedOnFunc)({ ...filterValues, [name]: value, page: 1 }, getCompanies);
    };
    const deleteCompany = async (confirm) => {
        setShowDeleteAlert(false);
        if (!deletedCompanyId || !confirm)
            return setDeletedCompanyId(null);
        dispatch({ type: 'SEND_DELETE_REQUEST' });
        const companies = await index_1.CompaniesApi.deleteCompany(deletedCompanyId);
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'DONE_DELETE_REQUEST', payload: { errors: companies.errors } });
        if (companies.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            getCompanies(params).then();
        setDeletedCompanyId(null);
    };
    const getCompanies = async (values) => {
        dispatch({ type: 'SEND_COMPANIES_REQUEST' });
        const filterParams = (0, helpers_1.transformCompanyParamsToFilterParams)(values);
        const companies = await index_1.CompaniesApi.getCompanies(filterParams);
        if (companies.data !== null)
            setLinkParams((0, queryString_1.stringify)((0, helpers_1.transformCompanyFilterParamsToUrl)(values)));
        if (isMounted) {
            dispatch({ type: 'DONE_COMPANIES_REQUEST', payload: companies });
            navigate('/references/companies' + (0, queryString_1.stringify)((0, helpers_1.transformCompanyFilterParamsToUrl)(values)), { replace: true });
        }
    };
    const initializeFilterForm = async () => {
        const params = (0, helpers_1.transformUrlToCompanyFilterParams)(location.search);
        const companyListId = (0, queryString_1.parse)(location.search).companyId;
        getCompanies(params).then(() => dispatch({ type: 'INITIALIZE_FILTER_FORM', payload: params }));
        setCompanyId(companyListId);
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        initializeFilterForm().then();
        return () => { isMounted.current = false; };
    }, []);
    return {
        state,
        navigatePagination,
        selectPerPage,
        debouncedSearch,
        deleteAlert,
        deleteCompany,
        deletedCompanyId,
        showDeleteAlert,
        linkParams,
        filter,
        getCompanies,
        companyId
    };
};
exports.default = useCompanies;
