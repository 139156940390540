"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGroupedCpvCodes = exports.getVerifySubmissionItemsActionLog = exports.updateVerifySubmissionItem = exports.deleteSubmissionVerifyItem = exports.getSubmissionVerifyItemsV2 = exports.suspendItems = exports.declineItems = exports.approveItems = exports.decline = exports.requestChange = exports.getSubmissionVerify = exports.getSubmissionVerifies = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const ApiV2 = tslib_1.__importStar(require("api/privateRequest-v2"));
const parsers_1 = require("api/documents/purchases/submissionVerifies/parsers");
const logParser_1 = require("api/documents/purchases/submissionVerifies/logParser");
const getSubmissionVerifies = async (params) => {
    const response = await Api.get('documents/purchases/submission-verifies', params);
    return (0, parsers_1.parseSubmissionVerifies)(response);
};
exports.getSubmissionVerifies = getSubmissionVerifies;
const getSubmissionVerify = async (id) => {
    const response = await Api.get('documents/purchases/submission-verifies/' + id);
    return (0, parsers_1.parseVerifySubmission)(response);
};
exports.getSubmissionVerify = getSubmissionVerify;
const requestChange = async (id) => {
    const response = await Api.patch(`documents/purchases/submission-verifies/request-change/${id}`, id);
    return (0, parsers_1.parseVerifySubmission)(response);
};
exports.requestChange = requestChange;
const decline = async (verifySubmissionId, params) => {
    const response = await Api.patch('documents/purchases/submission-verifies/decline/' + verifySubmissionId, params);
    return (0, parsers_1.parseVerifySubmission)(response);
};
exports.decline = decline;
const approveItems = async (params) => {
    const response = await Api.patch('documents/purchases/submission-verifies/approve-items', params);
    return (0, parsers_1.parseSubmissionVerifiesItemsActions)(response);
};
exports.approveItems = approveItems;
const declineItems = async (params) => {
    const response = await Api.patch('documents/purchases/submission-verifies/decline-items', params);
    return (0, parsers_1.parseSubmissionVerifiesItemsActions)(response);
};
exports.declineItems = declineItems;
const suspendItems = async (params) => {
    const response = await Api.patch('documents/purchases/submission-verifies/suspend-items', params);
    return (0, parsers_1.parseSubmissionVerifiesItemsActions)(response);
};
exports.suspendItems = suspendItems;
const getSubmissionVerifyItemsV2 = async (id, params) => {
    const response = await ApiV2.get(`documents/purchases/submission-verifies/${id}/items`, params);
    return (0, parsers_1.parseSubmissionVerifiesItemsV2)(response);
};
exports.getSubmissionVerifyItemsV2 = getSubmissionVerifyItemsV2;
const deleteSubmissionVerifyItem = async (verifySubmissionId, params) => {
    return await ApiV2.deleteItem(`documents/purchases/submission-verifies/${verifySubmissionId}/items`, params);
};
exports.deleteSubmissionVerifyItem = deleteSubmissionVerifyItem;
const updateVerifySubmissionItem = async (id, params) => {
    const response = await ApiV2.patch(`documents/purchases/submission-verifies/${id}/items`, params);
    return (0, parsers_1.parseSubmissionVerifiesItemsV2)(response);
};
exports.updateVerifySubmissionItem = updateVerifySubmissionItem;
const getVerifySubmissionItemsActionLog = async (id, params) => {
    const response = await Api.get(`action-logs/App%5CModels%5CDocuments%5CPurchases%5CVerifyItem/${id}`, params);
    return (0, logParser_1.parseVerifySubmissionItemActionLog)(response);
};
exports.getVerifySubmissionItemsActionLog = getVerifySubmissionItemsActionLog;
const getGroupedCpvCodes = async (verifySubmissionId, params) => {
    const response = await ApiV2.get(`documents/purchases/submission-verifies/${verifySubmissionId}/group/cpv-codes`, params);
    return (0, parsers_1.parseGroupedCpvCodes)(response);
};
exports.getGroupedCpvCodes = getGroupedCpvCodes;
