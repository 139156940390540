"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const StructuralUnits_1 = require("../store/StructuralUnits");
const api_1 = require("src/api");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helpers_1 = require("src/modules/references/structuralUnitsUpgraded/helpers");
const react_1 = require("react");
const useStructuralUnits = (props) => {
    const [state, dispatch] = (0, StructuralUnits_1.useStructuralUnitsReducer)();
    const [firstRendering, setFirstRendering] = (0, react_1.useState)(false);
    const [keyword, setKeyword] = (0, react_1.useState)('');
    const isMounted = (0, react_1.useRef)(false);
    const getStructuralUnits = async (params) => {
        dispatch({ type: 'SEND_STRUCTURAL_UNITS_REQUEST' });
        params.sort = '+sort,-id';
        const structuralUnits = await api_1.StructuralUnitsUpgradedApi.getStructuralUnits(params);
        if (isMounted?.current) {
            dispatch({ type: 'DONE_STRUCTURAL_UNITS_REQUEST', payload: structuralUnits });
        }
    };
    const getChildren = async (parentId, childrenFetched, parentIdentifier) => {
        if (childrenFetched) {
            dispatch({ type: 'OPEN_FETCHED_CHILDREN', payload: { parentIdentifier } });
        }
        else {
            dispatch({ type: 'SEND_CHILDREN_REQUEST', payload: { parentId, parentIdentifier } });
            const structuralUnits = await api_1.StructuralUnitsUpgradedApi.getStructuralUnits({ filters: { parentId }, perPage: 1000, sort: '+sort,-id' });
            dispatch({ type: 'DONE_CHILDREN_REQUEST', payload: { parentId, structuralUnits, parentIdentifier } });
        }
    };
    const reFetchParent = async (unitId, parentUnitId) => {
        dispatch({ type: 'SEND_FETCH_STRUCTURAL_UNIT', payload: { parentUnitId: parentUnitId || '0' } });
        const structuralUnits = await api_1.StructuralUnitsUpgradedApi.getStructuralUnits({ filters: { parentId: parentUnitId || '0' }, perPage: 1000, sort: '+sort,-id' });
        dispatch({ type: 'DONE_FETCH_STRUCTURAL_UNIT', payload: { unitId, parentUnitId: parentUnitId || '0', structuralUnits } });
    };
    const sortStructuralUnits = async (data) => {
        const params = (0, helpers_1.transformStructuralUnitSortingParams)(data);
        const response = await api_1.StructuralUnitsUpgradedApi.sortStructuralUnits(params);
        return { data: null, status: response.status, errors: response.errors };
    };
    const closeStructuralUnit = (parentIdentifier) => {
        dispatch({ type: 'CLOSE_STRUCTURAL_UNIT', payload: { parentIdentifier } });
    };
    const searchByEmployee = (value) => {
        setKeyword('');
        const filters = { userId: value?.id, parentId: !value ? '0' : undefined };
        getStructuralUnits({ filters, perPage: 100 }).then();
    };
    const searchByKeyword = (value) => {
        setKeyword(value);
        if (value.length < 3 && value.length > 0)
            return;
        const params = { filters: { keyword: value, parentId: !value ? '0' : undefined }, perPage: 100 };
        (0, debouncedMethods_1.debouncedOnFunc)(params, getStructuralUnits);
    };
    const deleteUnit = async (unitId, parentIds) => {
        dispatch({ type: 'SEND_DELETE_REQUEST', payload: { unitId } });
        const response = await api_1.StructuralUnitsUpgradedApi.deleteStructuralUnit(unitId);
        const structuredResponse = { data: null, errors: response.errors, status: response.status };
        dispatch({ type: 'DONE_DELETE_REQUEST', payload: { unitId, parentIds, structuredResponse } });
        return structuredResponse;
    };
    const getAncestors = async (id) => {
        return await api_1.StructuralUnitsUpgradedApi.getAncestors(id);
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        if (props.activeAccountStructuralUnitId) {
            setFirstRendering(true);
            getStructuralUnits({ filters: { type: props.type, parentId: props.activeAccountStructuralUnitId } }).then(() => {
                if (isMounted?.current)
                    setFirstRendering(false);
            });
        }
        return () => { isMounted.current = false; };
    }, [props.activeAccountStructuralUnitId]);
    return {
        state,
        firstRendering,
        getChildren,
        closeStructuralUnit,
        searchByEmployee,
        searchByKeyword,
        keyword,
        deleteUnit,
        reFetchParent,
        sortStructuralUnits,
        getAncestors
    };
};
exports.default = useStructuralUnits;
