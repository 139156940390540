"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const index_1 = require("api/index");
const submissionVerifyItem_v2_1 = require("../store/submissionVerifyItem-v2");
const helpers_1 = require("../helpers");
const UseSubmissionVerifyItemV2 = () => {
    const [state, dispatch] = (0, submissionVerifyItem_v2_1.useSubmissionVerifyItemV2Reducer)();
    const [startTransition, setStartTransition] = (0, react_1.useState)(false);
    const calculateSummery = (item, changedValue) => {
        const newValue = { ...item, ...changedValue };
        if (newValue.quantity === null || newValue.price === null)
            return 0;
        if (newValue.currency?.label === 'GEL')
            return newValue.quantity * newValue.price;
        const newRate = newValue.currency?.quantity && newValue.currencyExchangeRate
            ? newValue.currencyExchangeRate / newValue.currency.quantity
            : newValue.currencyExchangeRate ? newValue.currencyExchangeRate : 0;
        return newValue.currencyExchangeRate ? newValue.quantity * newValue.price * newRate : null;
    };
    const updateAndCreateVerifySubmissionItem = async (id, values, actionType) => {
        const params = (0, helpers_1.transformVerifySubmissionItemV2Params)(values, actionType);
        dispatch({ type: 'SEND_SUBMISSION_VERIFY_ITEM_UPDATE_OR_CREATE' });
        const submissionVerifyItemsV2 = await index_1.SubmissionVerifiesApi.updateVerifySubmissionItem(id, params);
        dispatch({ type: 'DONE_SUBMISSION_VERIFY_ITEM_UPDATE_OR_CREATE' });
        return submissionVerifyItemsV2;
    };
    return {
        calculateSummery,
        state,
        updateAndCreateVerifySubmissionItem,
        setStartTransition,
        startTransition
    };
};
exports.default = UseSubmissionVerifyItemV2;
