"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const roles_1 = require("../store/roles");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const queryString_1 = require("core/helpers/queryString");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useRoles = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, roles_1.useRolesReducer)();
    const [linkParams, setLinkParams] = (0, react_1.useState)('');
    const isMounted = (0, react_1.useRef)(false);
    const navigatePagination = (page, values) => {
        getRoles({ ...values, page }).then();
    };
    const filter = (name, value, filterValues) => {
        getRoles({ ...filterValues, [name]: value, page: 1 }).then();
    };
    const selectPerPage = (perPage, values) => {
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'CHANGE_PER_PAGE', payload: { ...values, perPage } });
        getRoles({ ...params, perPage, page: undefined }).then();
    };
    const debouncedSearch = (name, value, filterValues) => {
        (0, debouncedMethods_1.debouncedOnFunc)({ ...filterValues, [name]: value, page: 1 }, getRoles);
    };
    const getRoles = async (values, roleId) => {
        dispatch({ type: 'SEND_ROLES_REQUEST' });
        const filterParams = (0, helpers_1.transformRolePermissionParamsToFilterParams)(values);
        const roles = await index_1.RolesApi.getRoles(filterParams);
        if (roles.data !== null)
            setLinkParams((0, queryString_1.stringify)((0, helpers_1.transformRolePermissionFilterParamsToUrl)(values)));
        if (isMounted?.current) {
            navigate('/administer/roles' + (0, queryString_1.stringify)((0, helpers_1.transformRolePermissionFilterParamsToUrl)(values), { replace: true }));
            dispatch({ type: 'DONE_ROLES_REQUEST', payload: roles });
            if (roleId)
                animateRolePermissions(roleId);
        }
    };
    const animateRolePermissions = (roleId) => {
        const scrollableElement = document.getElementById('roleId-' + roleId);
        if (scrollableElement) {
            scrollableElement.className += 'bg-scroll-animation';
            scrollableElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            scrollableElement.focus({ preventScroll: true });
        }
    };
    const initializeFilterForm = async () => {
        const params = (0, helpers_1.transformUrlToRolePermissionFilterParams)(location.search);
        dispatch({ type: 'INITIALIZE_FILTER_FORM', payload: params });
        const roleId = (0, queryString_1.parse)(location.search).roleId;
        getRoles(params, roleId).then();
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        initializeFilterForm().then();
        return () => { isMounted.current = false; };
    }, []);
    return {
        state,
        navigatePagination,
        selectPerPage,
        debouncedSearch,
        filter,
        linkParams,
        getRoles
    };
};
exports.default = useRoles;
