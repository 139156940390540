"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const currencies_1 = require("../store/currencies");
const react_1 = require("react");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helpers_1 = require("../helpers");
const queryString_1 = require("core/helpers/queryString");
const react_router_dom_1 = require("react-router-dom");
const useCurrencies = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, currencies_1.useCurrenciesReducer)();
    const [keyword, setKeyword] = (0, react_1.useState)('');
    const [showDeleteAlert, setShowDeleteAlert] = (0, react_1.useState)(false);
    const [deletedCurrencyId, setDeletedCurrencyId] = (0, react_1.useState)(null);
    const [currencyRateId, setCurrencyRateId] = (0, react_1.useState)(null);
    const isMounted = (0, react_1.useRef)(false);
    const [showHistory, setShowHistory] = (0, react_1.useState)(false);
    const navigatePagination = (page) => {
        const params = (0, queryString_1.parse)(location.search);
        getCurrencies({ ...params, page }).then();
    };
    const navigatePaginationForRates = (page) => {
        getCurrencyRates(currencyRateId, { page, perPage: state.metaForRates.pagination.perPage }).then();
    };
    const selectPerPage = (perPage) => {
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'CHANGE_PER_PAGE', payload: perPage });
        getCurrencies({ ...params, perPage, page: undefined }).then();
    };
    const selectPerPageForCurrencyRate = (perPage) => {
        dispatch({ type: 'CHANGE_PER_PAGE_FOR_RATES', payload: perPage });
        getCurrencyRates(currencyRateId, { perPage, page: undefined }).then();
    };
    const deleteAlert = (id) => {
        setShowDeleteAlert(true);
        setDeletedCurrencyId(id);
    };
    const debouncedSearch = (value) => {
        setKeyword(value);
        const searchParams = (0, queryString_1.parse)(location.search);
        const params = value
            ? { perPage: searchParams.perPage, filters: { keyword: value } }
            : { page: searchParams.page };
        (0, debouncedMethods_1.debouncedOnFunc)(params, getCurrencies);
    };
    const getCurrencies = async (params) => {
        dispatch({ type: 'SEND_CURRENCIES_REQUEST' });
        const currencies = await index_1.CurrenciesApi.getCurrencies(params);
        if (isMounted?.current) {
            navigate('/references/currencies' + (0, queryString_1.stringify)(params), { replace: true });
            dispatch({ type: 'DONE_CURRENCIES_REQUEST', payload: currencies });
        }
    };
    const showCurrencyRate = (id) => {
        setShowHistory(true);
        setCurrencyRateId(id);
        if (id !== null)
            getCurrencyRates(id, { perPage: 15, page: undefined }).then();
    };
    const getCurrencyRates = async (id, params) => {
        dispatch({ type: 'SEND_CURRENCY_RATE_REQUEST' });
        const currencyRates = await index_1.CurrenciesApi.getCurrencyRate(id, params);
        dispatch({ type: 'DONE_CURRENCY_RATE_REQUEST', payload: currencyRates });
    };
    const deleteCurrency = async (confirm) => {
        setShowDeleteAlert(false);
        if (!deletedCurrencyId || !confirm)
            return setDeletedCurrencyId(null);
        dispatch({ type: 'SEND_DELETE_REQUEST' });
        const currencies = await index_1.CurrenciesApi.deleteCurrency(deletedCurrencyId);
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'DONE_DELETE_REQUEST', payload: { errors: currencies.errors } });
        if (currencies.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            getCurrencies(params).then();
        setDeletedCurrencyId(null);
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        const params = (0, helpers_1.getParamsFromUrl)(location.search);
        if (params.perPage)
            dispatch({ type: 'CHANGE_PER_PAGE', payload: params.perPage });
        setKeyword(params.filters?.keyword || '');
        getCurrencies(params).then();
        return () => { isMounted.current = false; };
    }, []);
    return {
        state,
        navigatePagination,
        selectPerPage,
        deleteCurrency,
        deleteAlert,
        showCurrencyRate,
        currencyRateId,
        deletedCurrencyId,
        debouncedSearch,
        showDeleteAlert,
        keyword,
        getCurrencies,
        setShowHistory,
        showHistory,
        selectPerPageForCurrencyRate,
        navigatePaginationForRates
    };
};
exports.default = useCurrencies;
