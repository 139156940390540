"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const workType_1 = require("../store/workType");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useWorkType = (props) => {
    const [state, dispatch] = (0, workType_1.useWorkTypeReducer)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const getWorkType = async (id) => {
        dispatch({ type: 'SEND_WORK_TYPE_REQUEST' });
        const workType = await index_1.WorkTypeApi.getWorkType(id);
        dispatch({ type: 'DONE_WORK_TYPE_REQUEST', payload: workType });
    };
    const createWorkType = async (values) => {
        const params = (0, helpers_1.transformWorkTypeParams)(values);
        dispatch({ type: 'SEND_WORK_TYPE_CREATE' });
        const workType = await index_1.WorkTypeApi.createWorkType(params);
        dispatch({ type: 'DONE_WORK_TYPE_CREATE', payload: workType });
        if (workType.data !== null) {
            navigate(`/references/human-resources/work-types/${workType.data.id}/edit`);
        }
        return workType;
    };
    const updateWorkType = async (id, values) => {
        const params = (0, helpers_1.transformWorkTypeParams)(values);
        dispatch({ type: 'SEND_WORK_TYPE_UPDATE' });
        const workType = await index_1.WorkTypeApi.updateWorkType(id, params);
        dispatch({ type: 'DONE_WORK_TYPE_UPDATE', payload: workType });
        return workType;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getWorkType(props.id).then(); }, [props.id]);
    return { state, createWorkType, updateWorkType, getWorkType, closeErrorMessage };
};
exports.default = useWorkType;
