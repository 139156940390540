"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUserEmulate = void 0;
const index_1 = require("api/index");
const AuthProvider_1 = require("src/providers/AuthProvider");
const react_router_dom_1 = require("react-router-dom");
const react_1 = require("react");
const useUserEmulate = () => {
    const auth = (0, AuthProvider_1.useAuth)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const [isLogoutEmulateUser, setIsLogoutEmulateUser] = (0, react_1.useState)(false);
    const [isEmulateUserLoading, setIsEmulateUserLoading] = (0, react_1.useState)(null);
    const loginAsEmulateUser = async (userId) => {
        setIsEmulateUserLoading(userId);
        const response = await index_1.UsersApi.loginAs(userId, { client_id: '1', grant_type: 'emulate' });
        if (response.data !== null) {
            localStorage.removeItem('activeStructuralUnitId');
            localStorage.setItem('token', JSON.stringify(response.data));
            await auth.getAuthUser();
            navigate('/', { replace: true });
        }
        else {
            setIsEmulateUserLoading(null);
        }
        return { errors: response.errors, status: response.status };
    };
    const logoutAsEmulateUser = async (userId) => {
        setIsLogoutEmulateUser(true);
        const response = await index_1.UsersApi.loginAsUser({ id: userId, client_id: '1', grant_type: 'emulate' });
        localStorage.removeItem('activeStructuralUnitId');
        localStorage.setItem('token', JSON.stringify(response.data));
        if (response.data !== null) {
            await auth.getAuthUser();
            setIsLogoutEmulateUser(false);
            navigate('/administer/users', { replace: true });
            return response;
        }
        setIsLogoutEmulateUser(false);
        return response;
    };
    return {
        isEmulateUserLoading,
        isLogoutEmulateUser,
        logoutAsEmulateUser,
        loginAsEmulateUser
    };
};
exports.useUserEmulate = useUserEmulate;
