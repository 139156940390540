"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useAsyncSelectHook = (props) => {
    const [defaultOptions, setDefaultOptions] = (0, react_1.useState)([]);
    const [loading, setLoading] = (0, react_1.useState)(false);
    const getOptionsOnTouched = () => {
        if (!props.options && defaultOptions.length === 0 || props.forceFetch) {
            setLoading(true);
            props.loadOptions('', async (options) => {
                await setDefaultOptions(options);
                setLoading(false);
            });
        }
    };
    return {
        getOptionsOnTouched,
        loading,
        defaultOptions
    };
};
exports.default = useAsyncSelectHook;
