"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.employeeAutocompleteReducer = exports.useEmployeeAutocompleteReducer = exports.initialEmployeeAutocompleteState = void 0;
const react_1 = require("react");
exports.initialEmployeeAutocompleteState = {
    data: [],
    isFetching: false
};
const useEmployeeAutocompleteReducer = () => {
    return (0, react_1.useReducer)(exports.employeeAutocompleteReducer, exports.initialEmployeeAutocompleteState);
};
exports.useEmployeeAutocompleteReducer = useEmployeeAutocompleteReducer;
const employeeAutocompleteReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_EMPLOYEE_AUTOCOMPLETE_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_EMPLOYEE_AUTOCOMPLETE_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                isFetching: false
            };
        case 'RESET_DATA':
            return {
                ...state,
                data: []
            };
        default:
            return state;
    }
};
exports.employeeAutocompleteReducer = employeeAutocompleteReducer;
