"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const PositionType_1 = require("../store/PositionType");
const index_1 = require("api/index");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const usePositionType = (props) => {
    const [state, dispatch] = (0, PositionType_1.usePositionTypeReducer)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const getPositionType = async (id) => {
        dispatch({ type: 'SEND_POSITION_TYPE_REQUEST' });
        const positionType = await index_1.PositionTypesApi.getPositionType(id);
        dispatch({ type: 'DONE_POSITION_TYPE_REQUEST', payload: positionType });
    };
    const createPositionType = async (values) => {
        const params = (0, helpers_1.transformPositionTypesParams)(values);
        dispatch({ type: 'SEND_POSITION_TYPE_CREATE' });
        const positionType = await index_1.PositionTypesApi.createPositionType(params);
        dispatch({ type: 'DONE_POSITION_TYPE_CREATE', payload: positionType });
        if (positionType.data !== null) {
            navigate(`/references/human-resources/position-types/${positionType.data.id}/edit`);
        }
        return positionType;
    };
    const updatePositionType = async (id, values) => {
        const params = (0, helpers_1.transformPositionTypesParams)(values);
        dispatch({ type: 'SEND_POSITION_TYPE_UPDATE' });
        const positionType = await index_1.PositionTypesApi.updatePositionType(id, params);
        dispatch({ type: 'DONE_POSITION_TYPE_UPDATE', payload: positionType });
        return positionType;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getPositionType(props.id).then(); }, [props.id]);
    return { state, getPositionType, createPositionType, updatePositionType, closeErrorMessage };
};
exports.default = usePositionType;
