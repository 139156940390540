"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProcurementTemplateSave = void 0;
const types_1 = require("api/documents/purchases/procurements/types");
const react_1 = require("react");
const useProcurementTemplateSave = (props) => {
    (0, react_1.useEffect)(() => {
        if (props.status === types_1.ProcurementStatuses.DRAFT && props.isTemplateFetched && props.isCreator && props.content && props.dirty) {
            props.updateProcurementTemplate({ content: props.content }).then();
        }
    }, [props.isTemplateFetched, props.content === undefined]);
};
exports.useProcurementTemplateSave = useProcurementTemplateSave;
