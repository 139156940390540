"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const warrantyTerm_1 = require("../store/warrantyTerm");
const index_1 = require("api/index");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_1 = require("react-router");
const useWarrantyTerm = (props) => {
    const [state, dispatch] = (0, warrantyTerm_1.useWarrantyTermReducer)();
    const navigate = (0, react_router_1.useNavigate)();
    const getWarrantyTerm = async (id) => {
        dispatch({ type: 'SEND_WARRANTY_TERM_REQUEST' });
        const warrantyTerm = await index_1.WarrantyTermsApi.getWarrantyTerm(id);
        dispatch({ type: 'DONE_WARRANTY_TERM_REQUEST', payload: warrantyTerm });
    };
    const createWarrantyTerm = async (values) => {
        const params = (0, helpers_1.transformWarrantyTermParams)(values);
        dispatch({ type: 'SEND_WARRANTY_TERM_CREATE' });
        const warrantyTerm = await index_1.WarrantyTermsApi.createWarrantyTerm(params);
        dispatch({ type: 'DONE_WARRANTY_TERM_CREATE', payload: warrantyTerm });
        if (warrantyTerm.data !== null)
            navigate(`/references/warranty-terms/${warrantyTerm.data.id}/edit`);
        return warrantyTerm;
    };
    const updateWarrantyTerm = async (id, values) => {
        const params = (0, helpers_1.transformWarrantyTermParams)(values);
        dispatch({ type: 'SEND_WARRANTY_TERM_UPDATE' });
        const warrantyTerm = await index_1.WarrantyTermsApi.updateWarrantyTerm(id, params);
        dispatch({ type: 'DONE_WARRANTY_TERM_UPDATE', payload: warrantyTerm });
        return warrantyTerm;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getWarrantyTerm(props.id).then(); }, [props.id]);
    return { state, createWarrantyTerm, updateWarrantyTerm, getWarrantyTerm, closeErrorMessage };
};
exports.default = useWarrantyTerm;
