"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.editProcurementCorrectionValidation = void 0;
const types_1 = require("api/documents/purchases/procurementCorrections/types");
const types_2 = require("api/documents/purchases/procurements/types");
const validates_1 = require("core/helpers/validates");
const editProcurementCorrectionValidation = (values) => {
    const letter = Number(values.correctionType.id) === types_1.ProcurementCorrectionType.LETTER;
    const formErrors = {
        correctionType: (0, validates_1.required)(values.correctionType),
        letterNumber: letter ? (0, validates_1.required)(values.letterNumber) : undefined,
        letterDate: letter ? (0, validates_1.required)(values.letterDate) : undefined,
        signedDocumentNumber: values.status === types_2.ProcurementStatuses.SIGN_PROGRESS ? (0, validates_1.required)(values.signedDocumentNumber) : undefined,
        dateOfConclusion: values.status === types_2.ProcurementStatuses.SIGN_PROGRESS ? (0, validates_1.required)(values.dateOfConclusion) : undefined,
        deliveryDate: (0, validates_1.required)(values.deliveryDate),
        company: (0, validates_1.required)(values.company),
        procurementFilesError: values.isOther || values.templateType === types_2.TemplateTypes.CONSOLIDATED ? (0, validates_1.requiredNotEmptyArray)(values.procurementFile) : undefined,
        companyAddress: (0, validates_1.required)(values.companyAddress),
        companyBankAccount: (0, validates_1.required)(values.companyBankAccount),
        companySignatory: (0, validates_1.required)(values.companySignatory),
        type: (0, validates_1.required)(values.type),
        templateType: (0, validates_1.required)(values.templateType),
        contractValidityDate: (0, validates_1.required)(values.contractValidityDate),
        fileErrors: values.status === types_2.ProcurementStatuses.SIGN_PROGRESS ? (0, validates_1.requiredNotEmptyArray)(values.files) : undefined
    };
    const errors = {};
    Object.entries(formErrors).map(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.editProcurementCorrectionValidation = editProcurementCorrectionValidation;
