"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformLocationParams = exports.getParamsFromUrl = void 0;
const queryString_1 = require("core/helpers/queryString");
const getParamsFromUrl = (url) => {
    try {
        const params = (0, queryString_1.parse)(url);
        return {
            page: params.page,
            perPage: params.perPage,
            filters: params.filters?.keyword
                ? { keyword: params.filters.keyword, keywordFullname: params.filters.keywordFullname } : undefined
        };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.getParamsFromUrl = getParamsFromUrl;
const transformLocationParams = (params) => {
    return {
        nameKa: params.nameKa,
        nameEn: params.nameEn,
        districtId: params.district.id
    };
};
exports.transformLocationParams = transformLocationParams;
