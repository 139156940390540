"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.administrativeOrderTypes = exports.parseAdministrativeOrdersForSelectType = exports.parseAdministrativeOrderForSelect = exports.parseArticlesForSelect = exports.parseArticles = exports.parseAdministrativeOrder = exports.parseAdministrativeOrders = void 0;
const types_1 = require("api/documents/orders/administrativeOrders/types");
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseAdministrativeOrders = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const administrativeOrders = response.content;
    try {
        return {
            data: administrativeOrders.data.map(el => {
                return {
                    id: el.id,
                    number: el.attributes.number,
                    name: el.attributes.name,
                    publicationDate: (0, parsers_1.transformDate)(el.attributes.publicationDate),
                    publicationDateTime: (0, parsers_1.transformDateTime)(el.attributes.publicationDate),
                    deletedAt: (0, parsers_1.nullableTransformDate)(el.attributes.deletedAt),
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    createdDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt),
                    updatedDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.updatedAt)
                };
            }),
            errors: null,
            status: response.status,
            meta: (0, parsers_1.transformPagination)(administrativeOrders.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseAdministrativeOrders = parseAdministrativeOrders;
const parseAdministrativeOrder = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const administrativeOrder = response.content;
    try {
        return {
            data: {
                id: administrativeOrder.data.id,
                number: administrativeOrder.data.attributes.number,
                name: administrativeOrder.data.attributes.name,
                publicationDate: (0, parsers_1.transformDate)(administrativeOrder.data.attributes.publicationDate),
                deletedAt: (0, parsers_1.nullableTransformDate)(administrativeOrder.data.attributes.deletedAt),
                createdAt: (0, parsers_1.transformDate)(administrativeOrder.data.attributes.createdAt),
                createdDateTimeAt: (0, parsers_1.transformDateTime)(administrativeOrder.data.attributes.createdAt),
                createdBy: administrativeOrder.data.relationships.createdBy?.data.relationships.user.data.attributes.fullname,
                files: administrativeOrder.data.relationships.mediaFile && [
                    {
                        id: administrativeOrder.data.relationships.mediaFile.data.id,
                        name: administrativeOrder.data.relationships.mediaFile.data.attributes.name,
                        status: administrativeOrder.data.relationships.mediaFile.data.attributes.status,
                        originalName: administrativeOrder.data.relationships.mediaFile.data.attributes.originalName,
                        link: administrativeOrder.data.relationships.mediaFile.data.attributes.downloadUrl,
                        extension: administrativeOrder.data.relationships.mediaFile.data.attributes.extension
                    }
                ] || []
            },
            errors: null,
            status: response.status
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseAdministrativeOrder = parseAdministrativeOrder;
const parseArticles = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const administrativeOrdersArticles = response.content;
    try {
        return {
            data: administrativeOrdersArticles.data.map(el => {
                const administrativeOrder = el.relationships.administrativeOrder || el.relationships.correctionAdministrativeOrder;
                const publicationDate = (0, parsers_1.transformDate)(administrativeOrder.data.attributes.publicationDate);
                const orderNumber = administrativeOrder.data.attributes.number;
                return {
                    id: el.id,
                    name: el.attributes.name,
                    hasChildren: el.attributes.hasChildren,
                    price: el.attributes.price,
                    number: el.attributes.number,
                    orderNumber,
                    corrected: el.attributes.corrected,
                    publicationDate,
                    deletedAt: (0, parsers_1.nullableTransformDate)(el.attributes.deletedAt),
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt),
                    isOpen: false,
                    articleType: exports.administrativeOrderTypes.find(type => Number(type.id) === el.attributes.type),
                    level: 0,
                    children: [],
                    parent: el.relationships.parent ? {
                        id: el.relationships.parent.data.id,
                        label: parseArticleParentName(el.relationships.parent.data, orderNumber, publicationDate)
                    } : null
                };
            }),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseArticles = parseArticles;
const parseArticlesForSelect = (response) => {
    if (response.content === null)
        return [];
    const filteredAdministrativeOrdersArticles = response.content;
    try {
        return filteredAdministrativeOrdersArticles.data.map(el => {
            const administrativeOrder = el.relationships.administrativeOrder || el.relationships.correctionAdministrativeOrder;
            return {
                id: el.id,
                price: el.attributes.price,
                label: parseArticleName(el.attributes.type) + ': '
                    + el.attributes.number
                    + ' - '
                    + el.attributes.name
                    + (!el.attributes.price ? '' : ' - ' + el.attributes.price + 'ლ')
                    + ' - ბრძანების #'
                    + administrativeOrder.data.attributes.number + ' - გამოცემის თარიღი:'
                    + (0, parsers_1.transformDate)(administrativeOrder.data.attributes.publicationDate)
            };
        });
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return [];
    }
};
exports.parseArticlesForSelect = parseArticlesForSelect;
const parseAdministrativeOrderForSelect = (response) => {
    if (response.content === null)
        return [];
    const administrativeOrders = response.content;
    try {
        return (0, exports.parseAdministrativeOrdersForSelectType)(administrativeOrders);
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return [];
    }
};
exports.parseAdministrativeOrderForSelect = parseAdministrativeOrderForSelect;
const parseAdministrativeOrdersForSelectType = (order) => {
    return order.data.map(el => {
        return {
            id: el.id,
            label: 'ბრძანების #: '
                + el.attributes.number
                + ' - '
                + el.attributes.name
                + ' - გამოცემის თარიღი:'
                + (0, parsers_1.transformDate)(el.attributes.publicationDate)
        };
    });
};
exports.parseAdministrativeOrdersForSelectType = parseAdministrativeOrdersForSelectType;
exports.administrativeOrderTypes = [
    { id: String(types_1.AdministrativeOrderTypes.ARTICLE), label: 'მუხლი', color: 'text-primary' },
    { id: String(types_1.AdministrativeOrderTypes.POINT), label: 'პუნქტი', color: 'text-warning' },
    { id: String(types_1.AdministrativeOrderTypes.SUB_POINT), label: 'ქვეპუნქტი', color: 'text-dark-50' }
];
const parseArticleParentName = (data, orderNumber, publicationDate) => {
    return parseArticleName(data.attributes.type) + ': ' + data.attributes.number
        + ' - ' + data.attributes.name
        + (!data.attributes.price ? '' : ' - ' + data.attributes.price + 'ლ')
        + ' - ბრძანების #' + orderNumber
        + ' - გამოცემის თარიღი:' + publicationDate;
};
const parseArticleName = (type) => {
    switch (type) {
        case types_1.AdministrativeOrderTypes.ARTICLE:
            return 'მუხლი';
        case types_1.AdministrativeOrderTypes.POINT:
            return 'პუნქტი';
        case types_1.AdministrativeOrderTypes.SUB_POINT:
            return 'ქვეპუნქტი';
    }
};
