"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const inventoryItem_1 = require("../store/inventoryItem");
const index_1 = require("api/index");
const queryString_1 = require("core/helpers/queryString");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const react_router_1 = require("react-router");
const useInventoryItem = (props) => {
    const [state, dispatch] = (0, inventoryItem_1.useInventoryItemReducer)();
    const [listingParams, setListingParams] = (0, react_1.useState)('');
    const navigate = (0, react_router_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const getInventoryItem = async (id) => {
        dispatch({ type: 'SEND_INVENTORY_ITEM_REQUEST' });
        const inventoryItem = await index_1.PurchasesInventoryItemsApi.getInventoryItem(id);
        dispatch({ type: 'DONE_INVENTORY_ITEM_REQUEST', payload: inventoryItem });
    };
    const createInventoryItem = async (values) => {
        const params = (0, helpers_1.transformInventoryItemParams)(values);
        dispatch({ type: 'SEND_INVENTORY_ITEM_CREATE' });
        const inventoryItem = await index_1.PurchasesInventoryItemsApi.createInventoryItem(params);
        dispatch({ type: 'DONE_INVENTORY_ITEM_CREATE', payload: inventoryItem });
        if (inventoryItem.data !== null)
            navigate(`/references/purchases/purchase-items/${inventoryItem.data.id}/edit`);
        return inventoryItem;
    };
    const updateInventoryItem = async (id, values) => {
        const params = (0, helpers_1.transformInventoryItemParams)(values);
        dispatch({ type: 'SEND_INVENTORY_ITEM_UPDATE' });
        const inventoryItem = await index_1.PurchasesInventoryItemsApi.updateInventoryItem(id, params);
        dispatch({ type: 'DONE_INVENTORY_ITEM_UPDATE', payload: inventoryItem });
        return inventoryItem;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => {
        const params = (0, helpers_1.transformInventoryItemFilterParamsToUrl)((0, helpers_1.transformUrlToInventoryItemFilterParams)(location.search));
        const searchParams = (0, queryString_1.stringify)({ ...params });
        setListingParams(searchParams || '?');
        if (props.id)
            getInventoryItem(props.id).then();
    }, [props.id]);
    return { state, createInventoryItem, updateInventoryItem, getInventoryItem, closeErrorMessage, listingParams };
};
exports.default = useInventoryItem;
