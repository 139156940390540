"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.verifySubmissionItemsError = exports.verifySubmissionItemsFormValidation = void 0;
const types_1 = require("api/references/purchase-subject/types");
const types_2 = require("api/documents/purchases/submissionVerifies/types");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const validates_1 = require("core/helpers/validates");
// export const submissionVerifyItemV2FormValidation = (values: VerifySubmissionItemForm): VerifySubmissionItemV2FormValidations => {
//   const formErrors: VerifySubmissionItemV2FormValidations = {
//     cpvCode: required(values.cpvCode),
//     inventoryConfigurationId: required(values.inventoryConfigurationId),
//     dimensionUnit: required(values.dimensionUnit),
//     project: required(values.project),
//     currency: required(values.currency),
//     currencyExchangeRate: values.currency?.label !== 'GEL' ? requiredPositiveNumber(values.currencyExchangeRate) : undefined,
//     quantity: requiredPositiveNumber(values.quantity),
//     price: requiredPositiveNumber(values.price),
//     assignment: required(values.assignment),
//     characteristics: !values.purchaseSubject.specificationName ? required(values.characteristics) : undefined
//   }
//   const errors: VerifySubmissionItemV2FormValidations = {} as VerifySubmissionItemV2FormValidations
//   Object.entries(formErrors).forEach(([key, value]) => {
//     if (value) errors[key as keyof VerifySubmissionItemV2FormValidations] = value
//   })
//   return errors
// }
const verifySubmissionItemsFormValidation = (values, actionType) => {
    const formErrors = {
        verifySubmissionItemsErrors: values.verifySubmissionItemsValues.map(item => {
            if (item.inPurchase === apiGlobalTypes_1.YesNoStatus.YES)
                return undefined;
            if (actionType === types_2.VerifyActionType.BUDGETING) {
                return {
                    project: (0, validates_1.required)(item.project),
                    budgetArticle: item.budgetingStatus === types_2.SubmissionVerifyItemStatus.APPROVED ? (0, validates_1.required)(item.budgetArticle) : undefined
                };
            }
            return (0, exports.verifySubmissionItemsError)(item, values.verifySubmissionItemsValues);
        })
    };
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (key !== 'verifySubmissionItemsErrors' && value)
            errors[key] = value;
        formErrors.verifySubmissionItemsErrors = formErrors.verifySubmissionItemsErrors?.map(err => {
            if (err && (err.price
                || err.project
                || err.budgetArticle
                || err.currencyExchangeRate
                || err.assignment
                || err.quantity
                || err.cpvCode
                || err.inventoryConfigurationId
                || err.dimensionUnit
                || err.currency
                || err.templateType
                || err.bookLink
                || err.bookAuthor
                || err.bookTitle
                || err.bookIsbnCode
                || err.characteristics)) {
                return err;
            }
            return;
        });
        if (formErrors.verifySubmissionItemsErrors.some(err => err)) {
            errors[key] = formErrors.verifySubmissionItemsErrors;
        }
    });
    return errors;
};
exports.verifySubmissionItemsFormValidation = verifySubmissionItemsFormValidation;
const verifySubmissionItemsError = (item, values) => {
    const dividedItems = values.filter(el => item.id && el.parentId === item.id);
    if (dividedItems.length !== 0)
        dividedItems.push(item);
    const totalQuantity = dividedItems.reduce((accumulator, currentValue) => (accumulator * 1000 + currentValue.quantity * 1000) / 1000, 0);
    return {
        cpvCode: (0, validates_1.required)(item.cpvCode),
        inventoryConfigurationId: (0, validates_1.required)(item.inventoryConfigurationId),
        dimensionUnit: (0, validates_1.required)(item.dimensionUnit),
        project: (0, validates_1.required)(item.project),
        currency: (0, validates_1.required)(item.currency),
        currencyExchangeRate: item.currency?.label !== 'GEL' ? (0, validates_1.requiredPositiveNumber)(item.currencyExchangeRate) : undefined,
        quantity: !(0, validates_1.requiredPositiveNumber)(item.quantity)
            ? totalQuantity !== 0 ? totalQuantity !== item.originalQuantity ? `დაყოფის ჯამი უნდა იყოს: ${item.originalQuantity}` : undefined : undefined
            : 'სავალდებულოა დადებითი რიცხვი',
        price: (0, validates_1.requiredPositiveNumber)(item.price),
        bookAuthor: item.inventoryConfigurationType === types_1.InventoryConfigurationType.BOOK || item.inventoryConfigurationType === types_1.InventoryConfigurationType.NATIONWIDE ? (0, validates_1.required)(item.bookAuthor) : undefined,
        bookTitle: item.inventoryConfigurationType === types_1.InventoryConfigurationType.BOOK || item.inventoryConfigurationType === types_1.InventoryConfigurationType.NATIONWIDE ? (0, validates_1.required)(item.bookTitle) : undefined,
        bookLink: item.inventoryConfigurationType === types_1.InventoryConfigurationType.BOOK ? (0, validates_1.required)(item.bookLink) : undefined,
        bookIsbnCode: item.inventoryConfigurationType === types_1.InventoryConfigurationType.BOOK || item.inventoryConfigurationType === types_1.InventoryConfigurationType.NATIONWIDE ? (0, validates_1.required)(item.bookIsbnCode) : undefined,
        assignment: (0, validates_1.required)(item.assignment),
        characteristics: !item.purchaseSubject.specificationName ? (0, validates_1.required)(item.characteristics) : undefined,
        templateType: (0, validates_1.required)(item.templateType)
    };
};
exports.verifySubmissionItemsError = verifySubmissionItemsError;
