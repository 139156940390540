"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUsers = void 0;
const react_1 = require("react");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const index_1 = require("api/index");
const users_1 = require("../store/users");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const queryString_1 = require("core/helpers/queryString");
const helpers_1 = require("../helpers");
const react_router_dom_1 = require("react-router-dom");
const useUsers = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, users_1.useUsersReducer)();
    const [structuralUnit, setStructuralUnit] = (0, react_1.useState)(null);
    const [showDeleteAlert, setShowDeleteAlert] = (0, react_1.useState)(false);
    const [showFilter, setShowFilter] = (0, react_1.useState)(false);
    const [toDeleteId, setToDeleteId] = (0, react_1.useState)(null);
    const [linkParams, setLinkParams] = (0, react_1.useState)('');
    const isMounted = (0, react_1.useRef)(false);
    const navigatePagination = (page, values) => {
        getUsers({ ...values, page }).then();
    };
    const selectPerPage = (perPage, values) => {
        dispatch({ type: 'CHANGE_PER_PAGE', payload: { ...values, perPage } });
        getUsers({ ...values, perPage, page: 1 }).then();
    };
    const deleteAlert = (id) => {
        setShowDeleteAlert(true);
        setToDeleteId(id);
    };
    const debouncedSearch = (name, value, filterValues) => {
        (0, debouncedMethods_1.debouncedOnFunc)({ ...filterValues, [name]: value, page: 1 }, getUsers);
    };
    const filter = (name, value, filterValues) => {
        getUsers({ ...filterValues, [name]: value, page: 1 }).then();
    };
    const deleteUser = async (confirm) => {
        setShowDeleteAlert(false);
        if (!toDeleteId || !confirm)
            return setToDeleteId(null);
        dispatch({ type: 'SEND_DELETE_REQUEST' });
        const users = await index_1.UsersApi.deleteUser(toDeleteId);
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'DONE_DELETE_REQUEST', payload: { errors: users.errors } });
        if (users.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            getUsers(params).then();
        setToDeleteId(null);
    };
    const getUsers = async (values, userId) => {
        dispatch({ type: 'SEND_USERS_REQUEST' });
        const filterParams = (0, helpers_1.transformUserParamsToFilterParams)(values);
        const users = await index_1.UsersApi.getUsers(filterParams);
        if (users.data !== null)
            setLinkParams((0, queryString_1.stringify)((0, helpers_1.transformUserFilterParamsToUrl)(values)));
        if (isMounted?.current) {
            navigate('/administer/users' + (0, queryString_1.stringify)((0, helpers_1.transformUserFilterParamsToUrl)(values), { replace: true }));
            dispatch({ type: 'DONE_USERS_REQUEST', payload: users });
            if (userId)
                animateUsers(userId);
        }
    };
    const animateUsers = (userId) => {
        const scrollableElement = document.getElementById('userId-' + userId);
        if (scrollableElement) {
            scrollableElement.className += 'bg-scroll-animation';
            scrollableElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            scrollableElement.focus({ preventScroll: true });
        }
    };
    const initializeFilterForm = async () => {
        const params = (0, helpers_1.transformUrlToUserFilterParams)(location.search);
        const isAnyFilterValue = Object.entries(params).some(([key, value]) => {
            if (key === 'page' || key === 'perPage')
                return false;
            return !!value;
        });
        if (isAnyFilterValue)
            setShowFilter(true);
        dispatch({ type: 'INITIALIZE_FILTER_FORM', payload: params });
        const userId = (0, queryString_1.parse)(location.search).userId;
        getUsers(params, userId).then();
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        initializeFilterForm().then();
        return () => { isMounted.current = false; };
    }, []);
    return {
        state,
        navigatePagination,
        selectPerPage,
        deleteUser,
        debouncedSearch,
        deleteAlert,
        toDeleteId,
        getUsers,
        structuralUnit,
        setStructuralUnit,
        showDeleteAlert,
        linkParams,
        filter,
        showFilter,
        setShowFilter
    };
};
exports.useUsers = useUsers;
