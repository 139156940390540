"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePermissions = void 0;
const index_1 = require("api/index");
const permissions_1 = require("../store/permissions");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const usePermissions = (props) => {
    const [state, dispatch] = (0, permissions_1.usePermissionReducer)();
    const getAclPermissions = async (params) => {
        dispatch({ type: 'SEND_PERMISSIONS_REQUEST' });
        const permissions = await index_1.PermissionApi.getAclPermissions(params);
        dispatch({ type: 'DONE_PERMISSIONS_REQUEST', payload: permissions });
    };
    const getUserPermissions = async (id) => {
        dispatch({ type: 'SEND_USER_PERMISSIONS_REQUEST' });
        const permissions = await index_1.PermissionApi.getUserPermissions(id);
        dispatch({ type: 'DONE_USER_PERMISSIONS_REQUEST', payload: permissions });
    };
    const updateUserPermissions = async (id, values) => {
        const params = (0, helpers_1.parseUserPermissionParams)(values.permissionGroups);
        dispatch({ type: 'SEND_USER_PERMISSIONS_UPDATE' });
        const permissions = await index_1.PermissionApi.updateUserPermissions(id, params);
        dispatch({ type: 'DONE_USER_PERMISSIONS_UPDATE', payload: permissions });
        return permissions;
    };
    (0, react_1.useEffect)(() => {
        if (props.id) {
            getAclPermissions({}).then(() => getUserPermissions(props.id).then());
        }
    }, [props.id]);
    return { state, updateUserPermissions };
};
exports.usePermissions = usePermissions;
exports.default = exports.usePermissions;
