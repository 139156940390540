"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const employeeAutoCompleteForProject_1 = require("../stores/employeeAutoCompleteForProject");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const employees_1 = require("api/references/employees");
const react_1 = require("react");
const useEmployeeAutoCompleteForProject = () => {
    const [state, dispatch] = (0, employeeAutoCompleteForProject_1.useEmployeeAutocompleteReducer)();
    const [userType, setUserType] = (0, react_1.useState)(null);
    const getEmployeeAutoComplete = async (params) => {
        dispatch({ type: 'SEND_EMPLOYEE_AUTOCOMPLETE_REQUEST' });
        const employeeAutoComplete = await (0, employees_1.getEmployeesForSelect)(params);
        dispatch({ type: 'DONE_EMPLOYEE_AUTOCOMPLETE_REQUEST', payload: { data: employeeAutoComplete } });
    };
    const resetAutocompleteData = (event) => {
        if (!event)
            return dispatch({ type: 'RESET_DATA' });
        const isFullName = document.querySelector('#autocomplete-listing')?.contains(event.target);
        if (!isFullName)
            dispatch({ type: 'RESET_DATA' });
    };
    const debouncedSearch = (value) => {
        const params = value ? ({ filters: { keyword: value } }) : {};
        (0, debouncedMethods_1.debouncedOnFunc)(params, getEmployeeAutoComplete);
    };
    return { state, debouncedSearch, resetAutocompleteData, setUserType, userType };
};
exports.default = useEmployeeAutoCompleteForProject;
