"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const education_1 = require("../store/education");
const index_1 = require("api/index");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const helper_1 = require("../helper");
const useEducation = (props) => {
    const [state, dispatch] = (0, education_1.useEducationReducer)();
    const [deleteHigh, setDeleteHigh] = (0, react_1.useState)({ showAlert: false, ids: null });
    const [deleteProfessional, setDeleteProfessional] = (0, react_1.useState)({ showAlert: false, ids: null });
    const [deleteSecondary, setDeleteSecondary] = (0, react_1.useState)({ showAlert: false, ids: null });
    const [cascadedHighs, setCascadedHighs] = (0, react_1.useState)([]);
    const [cascadedProfessionals, setCascadedProfessionals] = (0, react_1.useState)([]);
    const [cascadedSecondaries, setCascadedSecondaries] = (0, react_1.useState)([]);
    const getEducationHighs = async (id) => {
        dispatch({ type: 'SEND_EDUCATION_HIGHS_REQUEST' });
        const educationHighs = await index_1.EmployeesApi.getEducationHighs(id);
        dispatch({ type: 'DONE_EDUCATION_HIGHS_REQUEST', payload: educationHighs });
    };
    const getEducationProfessionals = async (id) => {
        dispatch({ type: 'SEND_EDUCATION_PROFESSIONALS_REQUEST' });
        const educationProfessionals = await index_1.EmployeesApi.getEducationProfessionals(id);
        dispatch({ type: 'DONE_EDUCATION_PROFESSIONALS_REQUEST', payload: educationProfessionals });
    };
    const getEducationSecondaries = async (id) => {
        dispatch({ type: 'SEND_EDUCATION_SECONDARIES_REQUEST' });
        const educationSecondaries = await index_1.EmployeesApi.getEducationSecondaries(id);
        dispatch({ type: 'DONE_EDUCATION_SECONDARIES_REQUEST', payload: educationSecondaries });
    };
    const addOrUpdateEducationHigh = async (id, values) => {
        const params = (0, helper_1.transformEducationHighParams)(values);
        dispatch({ type: 'SEND_EDUCATION_HIGH_CREATE_OR_UPDATE' });
        const educationHigh = await index_1.EmployeesApi.addOrUpdateEducationHigh(id, params);
        dispatch({ type: 'DONE_EDUCATION_HIGH_CREATE_OR_UPDATE', payload: educationHigh });
        return educationHigh;
    };
    const addOrUpdateEducationProfessional = async (id, values) => {
        const params = (0, helper_1.transformEducationProfessional)(values);
        dispatch({ type: 'SEND_EDUCATION_PROFESSIONAL_CREATE_OR_UPDATE' });
        const educationProfessional = await index_1.EmployeesApi.addOrUpdateEducationProfessional(id, params);
        dispatch({ type: 'DONE_EDUCATION_PROFESSIONAL_CREATE_OR_UPDATE', payload: educationProfessional });
        return educationProfessional;
    };
    const addOrUpdateEducationSecondary = async (id, values) => {
        const params = (0, helper_1.transformEducationSecondary)(values);
        dispatch({ type: 'SEND_EDUCATION_SECONDARY_CREATE_OR_UPDATE' });
        const educationSecondary = await index_1.EmployeesApi.addOrUpdateEducationSecondary(id, params);
        dispatch({ type: 'DONE_EDUCATION_SECONDARY_CREATE_OR_UPDATE', payload: educationSecondary });
        return educationSecondary;
    };
    const deleteEducationProfessional = async (confirm) => {
        if (!confirm || !deleteProfessional.ids) {
            setDeleteProfessional({ showAlert: false, ids: [] });
            return false;
        }
        setDeleteProfessional(({ ...deleteProfessional, showAlert: false }));
        dispatch({ type: 'SEND_EDUCATION_PROFESSIONAL_DELETE_REQUEST' });
        const response = await index_1.EmployeesApi.deleteEducationProfessional(props.id, { ids: deleteProfessional.ids });
        dispatch({ type: 'DONE_EDUCATION_PROFESSIONAL_DELETE_REQUEST', payload: response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS ? deleteProfessional.ids : [] });
        setDeleteProfessional({ showAlert: false, ids: [] });
        return response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS;
    };
    const deleteEducationHigh = async (confirm) => {
        if (!confirm || !deleteHigh.ids) {
            setDeleteHigh({ showAlert: false, ids: [] });
            return false;
        }
        setDeleteHigh(({ ...deleteHigh, showAlert: false }));
        dispatch({ type: 'SEND_EDUCATION_HIGH_DELETE_REQUEST' });
        const response = await index_1.EmployeesApi.deleteEducationHigh(props.id, { ids: deleteHigh.ids });
        dispatch({ type: 'DONE_EDUCATION_HIGH_DELETE_REQUEST', payload: response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS ? deleteHigh.ids : [] });
        setDeleteHigh({ showAlert: false, ids: [] });
        return response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS;
    };
    const deleteEducationSecondary = async (confirm) => {
        if (!confirm || !deleteSecondary.ids) {
            setDeleteSecondary({ showAlert: false, ids: [] });
            return false;
        }
        setDeleteSecondary(({ ...deleteSecondary, showAlert: false }));
        dispatch({ type: 'SEND_EDUCATION_SECONDARY_DELETE_REQUEST' });
        const response = await index_1.EmployeesApi.deleteEducationSecondary(props.id, { ids: deleteSecondary.ids });
        dispatch({ type: 'DONE_EDUCATION_SECONDARY_DELETE_REQUEST', payload: response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS ? deleteSecondary.ids : [] });
        setDeleteSecondary({ showAlert: false, ids: [] });
        return response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS;
    };
    const handleCascadeHigh = (id) => {
        cascadedHighs.includes(id)
            ? setCascadedHighs(cascadedHighs.filter(el => el !== id))
            : setCascadedHighs([...cascadedHighs, id]);
    };
    const handleCascadeProfessional = (id) => {
        cascadedProfessionals.includes(id)
            ? setCascadedProfessionals(cascadedProfessionals.filter(el => el !== id))
            : setCascadedProfessionals([...cascadedProfessionals, id]);
    };
    const handleCascadeSecondary = (id) => {
        cascadedSecondaries.includes(id)
            ? setCascadedSecondaries(cascadedSecondaries.filter(el => el !== id))
            : setCascadedSecondaries([...cascadedSecondaries, id]);
    };
    const fetchAllEducation = async (id) => {
        await Promise.all([
            getEducationHighs(id),
            getEducationProfessionals(id),
            getEducationSecondaries(id)
        ]);
    };
    (0, react_1.useEffect)(() => {
        if (props.id) {
            fetchAllEducation(props.id).then(() => {
                dispatch({ type: 'EDUCATION_FETCHED', payload: true });
            });
            return dispatch({ type: 'EDUCATION_FETCHED', payload: false });
        }
    }, [props.id]);
    return {
        state,
        addOrUpdateEducationHigh,
        addOrUpdateEducationProfessional,
        getEducationSecondaries,
        addOrUpdateEducationSecondary,
        deleteHigh,
        setDeleteHigh,
        deleteEducationHigh,
        deleteEducationProfessional,
        deleteProfessional,
        setDeleteProfessional,
        deleteEducationSecondary,
        deleteSecondary,
        setDeleteSecondary,
        cascadedHighs,
        handleCascadeHigh,
        setCascadedHighs,
        cascadedProfessionals,
        handleCascadeProfessional,
        setCascadedProfessionals,
        cascadedSecondaries,
        handleCascadeSecondary,
        setCascadedSecondaries
    };
};
exports.default = useEducation;
