"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateStructuralUnitForOrders = exports.getStructuralUnitForOrders = exports.deleteArticle = exports.createOrUpdateArticles = exports.getArticlesForSelect = exports.getAdministrativeOrdersArticles = exports.updateAdministrativeOrder = exports.createAdministrativeOrder = exports.getAdministrativeOrdersForSelect = exports.getAdministrativeOrder = exports.deleteAdministrativeOrder = exports.getAdministrativeOrders = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/documents/orders/administrativeOrders/parsers");
const parsers_2 = require("api/references/structuralUnitsUpgraded/parsers");
const getAdministrativeOrders = async (params) => {
    const response = await Api.get('documents/administrative-orders', params);
    return (0, parsers_1.parseAdministrativeOrders)(response);
};
exports.getAdministrativeOrders = getAdministrativeOrders;
const deleteAdministrativeOrder = async (id) => {
    return await Api.deleteItem('documents/administrative-orders/' + id);
};
exports.deleteAdministrativeOrder = deleteAdministrativeOrder;
const getAdministrativeOrder = async (id) => {
    const response = await Api.get('documents/administrative-orders/' + id);
    return (0, parsers_1.parseAdministrativeOrder)(response);
};
exports.getAdministrativeOrder = getAdministrativeOrder;
const getAdministrativeOrdersForSelect = async (params) => {
    const response = await Api.get('documents/administrative-orders', params);
    return (0, parsers_1.parseAdministrativeOrderForSelect)(response);
};
exports.getAdministrativeOrdersForSelect = getAdministrativeOrdersForSelect;
const createAdministrativeOrder = async (params) => {
    const response = await Api.post('documents/administrative-orders', params);
    return (0, parsers_1.parseAdministrativeOrder)(response);
};
exports.createAdministrativeOrder = createAdministrativeOrder;
const updateAdministrativeOrder = async (id, params) => {
    const response = await Api.patch('documents/administrative-orders/' + id, params);
    return (0, parsers_1.parseAdministrativeOrder)(response);
};
exports.updateAdministrativeOrder = updateAdministrativeOrder;
const getAdministrativeOrdersArticles = async (params) => {
    const response = await Api.get('documents/administrative-orders/articles', params);
    return (0, parsers_1.parseArticles)(response);
};
exports.getAdministrativeOrdersArticles = getAdministrativeOrdersArticles;
const getArticlesForSelect = async (params) => {
    const response = await Api.get('documents/administrative-orders/articles', params);
    return (0, parsers_1.parseArticlesForSelect)(response);
};
exports.getArticlesForSelect = getArticlesForSelect;
const createOrUpdateArticles = async (id, params) => {
    const response = await Api.patch(`documents/administrative-orders/${id}/articles`, params);
    return (0, parsers_1.parseArticles)(response);
};
exports.createOrUpdateArticles = createOrUpdateArticles;
const deleteArticle = async (ordersId, params) => {
    return await Api.deleteItem(`documents/administrative-orders/${ordersId}/articles`, params);
};
exports.deleteArticle = deleteArticle;
const getStructuralUnitForOrders = async (params) => {
    const response = await Api.get('references/structural-units', params);
    return (0, parsers_2.parseStructuralUnitForCards)(response);
};
exports.getStructuralUnitForOrders = getStructuralUnitForOrders;
const updateStructuralUnitForOrders = async (administrativeOrderId, params) => {
    const response = await Api.patch(`documents/administrative-orders/${administrativeOrderId}/structural-units`, params);
    return (0, parsers_2.parseStructuralUnitForCards)(response);
};
exports.updateStructuralUnitForOrders = updateStructuralUnitForOrders;
