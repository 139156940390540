"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.put = exports.deleteItem = exports.get = exports.patch = exports.post = void 0;
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const types_1 = require("api/auth/types");
const helper_1 = require("api/helper");
const auth_1 = require("api/auth");
const queryString_1 = require("core/helpers/queryString");
const baseURL = process.env.API_BASE_URL;
const sendPrivateRequest = async (url, method, params, reFetching, BodyInit, file) => {
    try {
        const authStatus = localStorage.getItem('authenticate');
        if (authStatus === 'locked' && reFetching)
            return await waitForReFetching(url, method, params, BodyInit, file);
        const storedToken = localStorage.getItem('token');
        const token = JSON.parse(storedToken || '{}');
        const activeStructuralUnitId = localStorage.getItem('activeStructuralUnitId');
        const accountId = JSON.parse(activeStructuralUnitId || '""');
        const urlParams = (0, queryString_1.stringify)(params, { encode: false });
        const headersObject = {
            'Authorization': `Bearer ${token.accessToken}`,
            'Accept-Language': 'ka',
            'Accept': 'application/json'
        };
        if (accountId)
            headersObject['account-id'] = accountId;
        const headers = file ? headersObject : { ...headersObject, 'Content-Type': 'application/json' };
        const body = file ? BodyInit : BodyInit && JSON.stringify(BodyInit);
        const response = await fetch(baseURL + url + urlParams, {
            body,
            headers,
            method: method
        });
        if (response.status === apiGlobalTypes_1.ResponseStatuses.APPLICATION_UNAVAILABLE) {
            return {
                status: response.status,
                content: null,
                errors: [{ name: 'catch', message: '503 აპლიკაცია დროებით გათიშულია' }]
            };
        }
        if (response.status === apiGlobalTypes_1.ResponseStatuses.UNAUTHENTICATED) {
            const refreshStatus = await (0, auth_1.refreshToken)();
            if (refreshStatus === types_1.RefreshTokenStatus.LOCKED && reFetching) {
                return await waitForReFetching(url, method, params, BodyInit, file);
            }
            if (refreshStatus === types_1.RefreshTokenStatus.SUCCESS && reFetching) {
                return sendPrivateRequest(url, method, params, false, BodyInit, file);
            }
        }
        if (!response.ok) {
            return {
                status: response.status,
                content: null,
                errors: await (0, helper_1.getErrorMessages)(response)
            };
        }
        const content = await response.json();
        return {
            status: response.status,
            content: content,
            errors: null
        };
    }
    catch (error) {
        console.error(error);
        return {
            status: 1,
            content: null,
            errors: [{ name: 'catch', message: 'unexpected' }]
        };
    }
};
const waitForReFetching = async (url, method, params, BodyInit, file) => {
    await new Promise(resolve => setTimeout(resolve, 2000));
    return sendPrivateRequest(url, method, params, false, BodyInit, file);
};
const post = (url, body, file = false) => {
    return sendPrivateRequest(url, 'POST', {}, true, body, file);
};
exports.post = post;
const patch = (url, body) => {
    return sendPrivateRequest(url, 'PATCH', {}, true, body);
};
exports.patch = patch;
const get = (url, params = {}) => {
    return sendPrivateRequest(url, 'GET', params, true);
};
exports.get = get;
const deleteItem = (url, params) => {
    return sendPrivateRequest(url, 'DELETE', {}, true, params);
};
exports.deleteItem = deleteItem;
const put = (url, body) => {
    return sendPrivateRequest(url, 'PUT', {}, true, body);
};
exports.put = put;
