"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEducationalInstitutionReducer = exports.initialEducationalInstitutionStore = void 0;
const react_1 = require("react");
exports.initialEducationalInstitutionStore = {
    data: [],
    isUpdating: false,
    creatingEducationalInstitution: false
};
const useEducationalInstitutionReducer = () => {
    return (0, react_1.useReducer)(educationalInstitutionReducer, exports.initialEducationalInstitutionStore);
};
exports.useEducationalInstitutionReducer = useEducationalInstitutionReducer;
const educationalInstitutionReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_CREATE_EDUCATIONAL_INSTITUTION_REQUEST':
            return {
                ...state,
                creatingEducationalInstitution: true
            };
        case 'DONE_CREATE_EDUCATIONAL_INSTITUTION_REQUEST':
            return {
                ...state,
                creatingEducationalInstitution: false
            };
    }
};
