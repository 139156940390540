"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const ToastProgressBar = (props) => {
    const [barWidth, setBarWidth] = (0, react_1.useState)(0);
    (0, react_1.useEffect)(() => {
        setBarWidth(props.timeLeft * 20);
    }, [props.timeLeft, props.isPaused]);
    return (0, jsx_runtime_1.jsx)("div", { className: `progressBar activeProgress-${barWidth}` });
};
exports.default = ToastProgressBar;
