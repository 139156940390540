"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assignTypeOptions = void 0;
const types_1 = require("src/api/documents/human-resources/assign-submissions/types");
exports.assignTypeOptions = [
    { id: String(types_1.AssignType.CONTRACT), label: 'შრომითი ხელშეკრულებით' },
    { id: String(types_1.AssignType.ORDER), label: 'ბრძანებით' },
    { id: String(types_1.AssignType.ORDER_CONTRACT), label: 'ბრძანებით/შრომითი ხელშეკრულებით' },
    { id: String(types_1.AssignType.CONTRACT_EXT), label: 'შრომითი ხელშეკრულების ვადის გაგრძელებით' }
];
