"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const country_1 = require("../store/country");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useCountry = (props) => {
    const [state, dispatch] = (0, country_1.useCountryReducer)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const getCountry = async (id) => {
        dispatch({ type: 'SEND_COUNTRY_REQUEST' });
        const country = await index_1.CountriesApi.getCountry(id);
        dispatch({ type: 'DONE_COUNTRY_REQUEST', payload: country });
    };
    const createCountry = async (values) => {
        const params = (0, helpers_1.transformCountryParams)(values);
        dispatch({ type: 'SEND_COUNTRY_CREATE' });
        const country = await index_1.CountriesApi.createCountry(params);
        dispatch({ type: 'DONE_COUNTRY_CREATE', payload: country });
        if (country.data !== null) {
            navigate(`/references/addresses/countries/${country.data.id}/edit`);
        }
        return country;
    };
    const updateCountry = async (id, values) => {
        const params = (0, helpers_1.transformCountryParams)(values);
        dispatch({ type: 'SEND_COUNTRY_UPDATE' });
        const country = await index_1.CountriesApi.updateCountry(id, params);
        dispatch({ type: 'DONE_COUNTRY_UPDATE', payload: country });
        return country;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getCountry(props.id).then(); }, [props.id]);
    return { state, createCountry, updateCountry, getCountry, closeErrorMessage };
};
exports.default = useCountry;
