"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseRegionsForSelect = exports.parseRegion = exports.parseRegions = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseRegions = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const regions = response.content;
    try {
        return {
            data: regions.data.map(el => {
                return {
                    id: el.id,
                    nameKa: el.attributes.nameKa,
                    nameEn: el.attributes.nameEn,
                    country: el.relationships.country.data.attributes.nameEn
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(regions.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseRegions = parseRegions;
const parseRegion = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const region = response.content;
    try {
        return {
            data: {
                id: region.data.id,
                nameKa: region.data.attributes.nameKa,
                nameEn: region.data.attributes.nameEn,
                country: {
                    id: region.data.relationships.country.data.id,
                    label: region.data.relationships.country.data.attributes.nameEn
                }
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseRegion = parseRegion;
const parseRegionsForSelect = (response) => {
    if (response.content === null)
        return [];
    const regions = response.content;
    try {
        return regions.data.map(el => {
            return {
                id: el.id,
                label: el.attributes.nameKa
            };
        });
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return [];
    }
};
exports.parseRegionsForSelect = parseRegionsForSelect;
