"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseCategories = exports.parseCategoriesForSelect = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseCategoriesForSelect = (response) => {
    if (response.content === null)
        return [];
    const categories = response.content;
    try {
        return categories.data.map(el => {
            return {
                id: el.id,
                label: (el.attributes.code && el.attributes.code + ' - ' + el.attributes.name) || el.attributes.name
            };
        });
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return [];
    }
};
exports.parseCategoriesForSelect = parseCategoriesForSelect;
const parseCategories = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const categories = response.content;
    try {
        return {
            data: categories.data.map(el => {
                return {
                    id: el.id,
                    name: el.attributes.name,
                    hasChildren: el.attributes.hasChildren,
                    closed: true,
                    level: 0,
                    children: [],
                    code: el.attributes.code,
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt),
                    type: el.attributes.type
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(categories.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseCategories = parseCategories;
