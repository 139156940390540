"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useHighlight = (props) => {
    (0, react_1.useEffect)(() => {
        if (props.isFetched && props.id) {
            const scrollableElement = document.getElementById('highlightedId-' + props.id);
            if (scrollableElement) {
                scrollableElement.className += 'bg-scroll-animation';
                scrollableElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                scrollableElement.focus({ preventScroll: true });
            }
        }
    }, [props.isFetched]);
};
exports.default = useHighlight;
