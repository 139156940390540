"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWorkDirectionsForSelect = exports.updateWorkDirection = exports.createWorkDirection = exports.getWorkDirection = exports.deleteItem = exports.getWorkDirections = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/references/humanResources/workDirection/parsers");
const getWorkDirections = async (params) => {
    const response = await Api.get('documents/human-resources/work-directions', params);
    return (0, parsers_1.parseWorkDirections)(response);
};
exports.getWorkDirections = getWorkDirections;
const deleteItem = async (id) => {
    return await Api.deleteItem(`documents/human-resources/work-directions/${id}`);
};
exports.deleteItem = deleteItem;
const getWorkDirection = async (id) => {
    const response = await Api.get('documents/human-resources/work-directions/' + id);
    return (0, parsers_1.parseWorkDirection)(response);
};
exports.getWorkDirection = getWorkDirection;
const createWorkDirection = async (params) => {
    const response = await Api.post('documents/human-resources/work-directions', params);
    return (0, parsers_1.parseWorkDirection)(response);
};
exports.createWorkDirection = createWorkDirection;
const updateWorkDirection = async (id, params) => {
    const response = await Api.patch('documents/human-resources/work-directions/' + id, params);
    return (0, parsers_1.parseWorkDirection)(response);
};
exports.updateWorkDirection = updateWorkDirection;
const getWorkDirectionsForSelect = async (params) => {
    const response = await Api.get('documents/human-resources/work-directions', params);
    return (0, parsers_1.parseWorkDirectionsForSelect)(response);
};
exports.getWorkDirectionsForSelect = getWorkDirectionsForSelect;
