"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.copyConditionalSubmission = exports.declineDeterminationItem = exports.suspendDeterminationItem = exports.sendDeterminationProgressInPurchase = exports.sendDeterminationToChancellery = exports.getDeterminationFiles = exports.deleteDeterminationFile = exports.updateDetermination = exports.getDeterminations = exports.createDetermination = exports.suspendItem = exports.declineItem = exports.createConditionalSubmission = exports.updateConditionalSubmission = exports.createSubmission = exports.sendProgressInPurchase = exports.sendToChancellery = exports.getDeterminationSubmissionFiles = exports.getConditionalSubmissionFiles = exports.updateOrCreateConditionalSubmissionItem = exports.deleteConditionalSubmissionItems = exports.checkItemStatus = exports.getConditionalSubmissionItems = exports.getConditionalSubmission = exports.getConditionalSubmissionsItems = exports.deleteConditionalSubmissionFile = exports.deleteConditionalSubmission = exports.getConditionalSubmissions = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const ApiV2 = tslib_1.__importStar(require("api/privateRequest-v2"));
const parsers_1 = require("api/documents/purchases/conditional-submissions/parsers");
const getConditionalSubmissions = async (params) => {
    const response = await Api.get('documents/purchases/conditional-submissions', params);
    return (0, parsers_1.parseConditionalSubmissions)(response);
};
exports.getConditionalSubmissions = getConditionalSubmissions;
const deleteConditionalSubmission = async (id) => {
    return await Api.deleteItem('documents/purchases/conditional-submissions/' + id);
};
exports.deleteConditionalSubmission = deleteConditionalSubmission;
const deleteConditionalSubmissionFile = async (id, params) => {
    return await Api.patch('documents/purchases/conditional-submissions/remove-files/' + id, params);
};
exports.deleteConditionalSubmissionFile = deleteConditionalSubmissionFile;
const getConditionalSubmissionsItems = async (id, page) => {
    const response = await Api.get(`documents/purchases/conditional-submissions/${id}/items`, { page });
    return (0, parsers_1.parseConditionalSubmissionsItem)(response);
};
exports.getConditionalSubmissionsItems = getConditionalSubmissionsItems;
const getConditionalSubmission = async (id) => {
    const response = await Api.get('documents/purchases/conditional-submissions/' + id);
    return (0, parsers_1.parseConditionalSubmission)(response);
};
exports.getConditionalSubmission = getConditionalSubmission;
const getConditionalSubmissionItems = async (id, params) => {
    const response = await Api.get(`documents/purchases/conditional-submissions/${id}/items`, params);
    return (0, parsers_1.parseConditionalSubmissionItems)(response);
};
exports.getConditionalSubmissionItems = getConditionalSubmissionItems;
const checkItemStatus = async (conditionalSubmissionId, itemId) => {
    const response = await Api.get(`documents/purchases/conditional-submissions/item-info/${conditionalSubmissionId}`, itemId);
    return (0, parsers_1.parseConditionalSubmissionItemStatus)(response);
};
exports.checkItemStatus = checkItemStatus;
const deleteConditionalSubmissionItems = async (id, params) => {
    return await Api.deleteItem(`documents/purchases/conditional-submissions/${id}/items`, params);
};
exports.deleteConditionalSubmissionItems = deleteConditionalSubmissionItems;
const updateOrCreateConditionalSubmissionItem = async (id, params) => {
    const response = await Api.patch(`documents/purchases/conditional-submissions/${id}/items`, params);
    return (0, parsers_1.parseConditionalSubmissionItems)(response);
};
exports.updateOrCreateConditionalSubmissionItem = updateOrCreateConditionalSubmissionItem;
const getConditionalSubmissionFiles = async (params, id) => {
    const response = await Api.get(`documents/purchases/conditional-submissions/${id}/export`, params);
    return (0, parsers_1.parseConditionalSubmissionFiles)(response);
};
exports.getConditionalSubmissionFiles = getConditionalSubmissionFiles;
const getDeterminationSubmissionFiles = async (id) => {
    const response = await Api.get(`documents/purchases/conditional-submissions/determinations/${id}/export`);
    return (0, parsers_1.parseConditionalSubmissionFiles)(response);
};
exports.getDeterminationSubmissionFiles = getDeterminationSubmissionFiles;
const sendToChancellery = async (id) => {
    const response = await Api.patch(`documents/purchases/conditional-submissions/${id}/send`, {});
    return (0, parsers_1.parseConditionalSubmission)(response);
};
exports.sendToChancellery = sendToChancellery;
const sendProgressInPurchase = async (id) => {
    const response = await Api.patch(`documents/purchases/conditional-submissions/${id}/requested`, {});
    return (0, parsers_1.parseConditionalSubmission)(response);
};
exports.sendProgressInPurchase = sendProgressInPurchase;
const createSubmission = async (params) => {
    const response = await ApiV2.post('documents/purchases/submissions', params);
    return (0, parsers_1.parseCreateSubmission)(response);
};
exports.createSubmission = createSubmission;
const updateConditionalSubmission = async (id, params) => {
    const response = await Api.patch(`documents/purchases/conditional-submissions/${id}`, params);
    return (0, parsers_1.parseConditionalSubmission)(response);
};
exports.updateConditionalSubmission = updateConditionalSubmission;
const createConditionalSubmission = async (params) => {
    const response = await Api.post('documents/purchases/conditional-submissions', params);
    return (0, parsers_1.parseConditionalSubmission)(response);
};
exports.createConditionalSubmission = createConditionalSubmission;
const declineItem = async (conditionalSubmissionId, params) => {
    const response = await Api.patch(`documents/purchases/conditional-submissions/${conditionalSubmissionId}/decline`, params);
    return (0, parsers_1.parseConditionalSubmission)(response);
};
exports.declineItem = declineItem;
const suspendItem = async (conditionalSubmissionId, params) => {
    const response = await Api.patch(`documents/purchases/conditional-submissions/${conditionalSubmissionId}/suspend`, params);
    return (0, parsers_1.parseConditionalSubmission)(response);
};
exports.suspendItem = suspendItem;
const createDetermination = async (conditionalSubmissionId, params) => {
    const response = await Api.post(`documents/purchases/conditional-submissions/determinations/${conditionalSubmissionId}`, params);
    return (0, parsers_1.parseDetermination)(response);
};
exports.createDetermination = createDetermination;
const getDeterminations = async (conditionalSubmissionId) => {
    const response = await Api.get(`documents/purchases/conditional-submissions/determinations/${conditionalSubmissionId}/items`);
    return (0, parsers_1.parseDeterminations)(response);
};
exports.getDeterminations = getDeterminations;
const updateDetermination = async (determinationId, params) => {
    const response = await Api.patch(`documents/purchases/conditional-submissions/determinations/${determinationId}`, params);
    return (0, parsers_1.parseDetermination)(response);
};
exports.updateDetermination = updateDetermination;
const deleteDeterminationFile = async (determinationId, params) => {
    return await Api.patch(`documents/purchases/conditional-submissions/determinations/${determinationId}/remove-files`, params);
};
exports.deleteDeterminationFile = deleteDeterminationFile;
const getDeterminationFiles = async (params, determinationId) => {
    const response = await Api.get(`documents/purchases/conditional-submissions/determinations/${determinationId}/export`, params);
    return (0, parsers_1.parseConditionalSubmissionFiles)(response);
};
exports.getDeterminationFiles = getDeterminationFiles;
const sendDeterminationToChancellery = async (determinationId) => {
    const response = await Api.patch(`documents/purchases/conditional-submissions/determinations/${determinationId}/send`, {});
    return (0, parsers_1.parseDetermination)(response);
};
exports.sendDeterminationToChancellery = sendDeterminationToChancellery;
const sendDeterminationProgressInPurchase = async (determinationId) => {
    const response = await Api.patch(`documents/purchases/conditional-submissions/determinations/${determinationId}/requested`, {});
    return (0, parsers_1.parseDetermination)(response);
};
exports.sendDeterminationProgressInPurchase = sendDeterminationProgressInPurchase;
const suspendDeterminationItem = async (determinationId, params) => {
    const response = await Api.patch(`documents/purchases/conditional-submissions/determinations/${determinationId}/suspend`, params);
    return (0, parsers_1.parseDetermination)(response);
};
exports.suspendDeterminationItem = suspendDeterminationItem;
const declineDeterminationItem = async (determinationId, params) => {
    const response = await Api.patch(`documents/purchases/conditional-submissions/determinations/${determinationId}/decline`, params);
    return (0, parsers_1.parseDetermination)(response);
};
exports.declineDeterminationItem = declineDeterminationItem;
const copyConditionalSubmission = async (id) => {
    const response = await Api.get(`documents/purchases/conditional-submissions/copy-item/${id}`);
    return (0, parsers_1.parseConditionalSubmission)(response);
};
exports.copyConditionalSubmission = copyConditionalSubmission;
