"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcurementStaticsReducer = exports.useProcurementStaticsReducer = void 0;
const react_1 = require("react");
const types_1 = require("../types");
const store_1 = require("core/store");
const initialProcurementStatistic = {
    filterData: { type: { id: String(types_1.StatisticProcurementTypes.SUMMERY_OF_PURCHASE), label: 'ხელშეკრულების ჯამური ღირებულების მიხედვით' } },
    procurements: [],
    procurementItems: [],
    categories: [],
    budgetArticles: [],
    projects: [],
    errors: null,
    meta: store_1.initialMeta,
    isFetching: true,
    isFileFetching: false
};
const useProcurementStaticsReducer = () => {
    return (0, react_1.useReducer)(exports.ProcurementStaticsReducer, initialProcurementStatistic);
};
exports.useProcurementStaticsReducer = useProcurementStaticsReducer;
const ProcurementStaticsReducer = (state, action) => {
    switch (action.type) {
        case 'INITIAL_FILTER_DATA':
            return {
                ...state,
                filterData: action.payload
            };
        case 'SEND_STATISTICS_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_PROCUREMENTS_STATISTICS_REQUEST':
            return {
                ...state,
                errors: action.payload.errors,
                procurements: action.payload.data || [],
                procurementItems: [],
                categories: [],
                budgetArticles: [],
                projects: [],
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'DONE_PROCUREMENT_ITEMS_STATISTICS_REQUEST':
            return {
                ...state,
                errors: action.payload.errors,
                procurementItems: action.payload.data || [],
                procurements: [],
                categories: [],
                budgetArticles: [],
                projects: [],
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'DONE_PROCUREMENT_ITEMS_CATEGORIES_REQUEST':
            return {
                ...state,
                categories: action.payload.data || [],
                errors: action.payload.errors,
                procurements: [],
                procurementItems: [],
                budgetArticles: [],
                projects: [],
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'DONE_PROCUREMENT_ITEMS_BUDGET_REQUEST':
            return {
                ...state,
                errors: action.payload.errors,
                budgetArticles: action.payload.data || [],
                procurements: [],
                procurementItems: [],
                categories: [],
                projects: [],
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'DONE_PROCUREMENT_ITEMS_PROJECT_REQUEST':
            return {
                ...state,
                errors: action.payload.errors,
                projects: action.payload.data || [],
                procurements: [],
                procurementItems: [],
                categories: [],
                budgetArticles: [],
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'SEND_FILE_REQUEST':
            return {
                ...state,
                isFileFetching: true
            };
        case 'DONE_FILE_REQUEST':
            return {
                ...state,
                isFileFetching: false
            };
        case 'CHANGE_PER_PAGE':
            return {
                ...state,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        perPage: action.payload.perPage
                    }
                },
                filterData: action.payload
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.ProcurementStaticsReducer = ProcurementStaticsReducer;
