"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.authReducer = exports.useAuthReducer = exports.initialAuthState = void 0;
const react_1 = require("react");
exports.initialAuthState = {
    data: {
        user: null,
        token: null
    },
    userErrors: null,
    tokenErrors: null,
    status: null,
    isFetchingUser: true,
    isFetchingToken: false,
    isRevoking: false,
    isLoading: false
};
const useAuthReducer = () => {
    return (0, react_1.useReducer)(exports.authReducer, exports.initialAuthState);
};
exports.useAuthReducer = useAuthReducer;
const authReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_TOKEN_REQUEST':
            return {
                ...state,
                isFetchingToken: true,
                tokenErrors: null,
                isLoading: true
            };
        case 'DONE_TOKEN_REQUEST':
            return {
                ...state,
                data: {
                    ...state.data,
                    token: action.payload.data
                },
                isFetchingToken: false,
                tokenErrors: action.payload.errors,
                isLoading: state.isFetchingUser
            };
        case 'SEND_AUTH_USER_REQUEST':
            return {
                ...state,
                isFetchingUser: true,
                userErrors: null,
                isLoading: true
            };
        case 'DONE_AUTH_USER_REQUEST':
            return {
                ...state,
                data: {
                    ...state.data,
                    user: action.payload.data ? action.payload.data : state.data.user
                },
                isFetchingUser: false,
                userErrors: action.payload.errors,
                isLoading: state.isFetchingToken
            };
        case 'RESET_TOKEN_ERRORS':
            return {
                ...state,
                userErrors: null
            };
        case 'SEND_DELETE_TOKEN':
            return {
                ...state,
                isRevoking: true,
                tokenErrors: null
            };
        case 'DONE_DELETE_TOKEN':
            return {
                ...state,
                isRevoking: false,
                tokenErrors: action.payload.errors
            };
        case 'RESET_AUTH_USER_ERRORS':
            return {
                ...state,
                userErrors: null
            };
        default:
            return state;
    }
};
exports.authReducer = authReducer;
