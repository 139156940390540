"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePermissionReducer = void 0;
const react_1 = require("react");
const initialPermissionValues = {
    selectedAll: false,
    length: null,
    permissionGroups: []
};
const initialPermissionsStore = {
    data: initialPermissionValues,
    errors: null,
    isFetching: true,
    isFetchingStructuralUnit: false,
    isFetchingUser: false,
    isLoading: false,
    isUpdating: false,
    isChecking: false
};
const usePermissionReducer = () => {
    return (0, react_1.useReducer)(PermissionReducer, initialPermissionsStore);
};
exports.usePermissionReducer = usePermissionReducer;
const PermissionReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_PERMISSIONS_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_PERMISSIONS_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                isFetching: false,
                isLoading: false
            };
        case 'SEND_USER_PERMISSIONS_REQUEST':
            return {
                ...state,
                errors: null,
                isFetchingUser: true,
                isLoading: true
            };
        case 'DONE_USER_PERMISSIONS_REQUEST':
            return {
                ...state,
                errors: action.payload.errors,
                data: action.payload.data
                    ? {
                        ...state.data,
                        selectedAll: state.data.length === action.payload.data.length,
                        permissionGroups: transformAttachedPermission(state.data.permissionGroups, action.payload.data)
                    }
                    : state.data,
                isFetchingUser: false,
                isLoading: false
            };
        case 'SEND_STRUCTURAL_UNIT_PERMISSIONS_REQUEST':
            return {
                ...state,
                errors: null,
                isFetchingStructuralUnit: true,
                isLoading: true
            };
        case 'DONE_STRUCTURAL_UNIT_PERMISSIONS_REQUEST':
            return {
                ...state,
                errors: action.payload.errors,
                data: action.payload.data
                    ? {
                        ...state.data,
                        selectedAll: state.data.length === action.payload.data.length,
                        permissionGroups: transformAttachedPermission(state.data.permissionGroups, action.payload.data)
                    }
                    : state.data,
                isFetchingStructuralUnit: false,
                isLoading: false
            };
        case 'SEND_USER_PERMISSIONS_UPDATE':
            return {
                ...state,
                isUpdating: true,
                isLoading: true,
                errors: null
            };
        case 'DONE_USER_PERMISSIONS_UPDATE':
            return {
                ...state,
                data: action.payload.data
                    ? {
                        ...state.data,
                        selectedAll: state.data.length === action.payload.data.length,
                        permissionGroups: transformAttachedPermission(state.data.permissionGroups, action.payload.data)
                    }
                    : state.data,
                isUpdating: false,
                isLoading: state.isChecking || state.isFetching,
                errors: action.payload.errors
            };
        default:
            return state;
    }
};
const transformAttachedPermission = (permissionGroups, attachedPermissions) => {
    return permissionGroups.map(group => ({
        ...group,
        checked: group.groupPermissions.every(permission => attachedPermissions.includes(permission.id)),
        groupPermissions: group.groupPermissions.map(el => ({ ...el, checked: attachedPermissions.includes(el.id) }))
    }));
};
