"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lockOut = exports.lockIn = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/lock/parsers");
const lockIn = async (params) => {
    const response = await Api.post('lock-in', params);
    return (0, parsers_1.parseDocumentLockIn)(response);
};
exports.lockIn = lockIn;
const lockOut = async (params) => {
    return await Api.deleteItem('lock-out', params);
};
exports.lockOut = lockOut;
