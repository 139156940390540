"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCurrencyForSelect = exports.updateCurrency = exports.createCurrency = exports.getCurrency = exports.getCurrencyRate = exports.deleteCurrency = exports.getCurrencies = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/references/currencies/parsers");
const getCurrencies = async (params) => {
    const response = await Api.get('references/currencies', params);
    return (0, parsers_1.parseCurrencies)(response);
};
exports.getCurrencies = getCurrencies;
const deleteCurrency = async (id) => {
    return await Api.deleteItem('references/currencies/' + id);
};
exports.deleteCurrency = deleteCurrency;
const getCurrencyRate = async (id, params) => {
    const response = await Api.get('references/currencies/rates/' + id, params);
    return (0, parsers_1.parseCurrencyRates)(response);
};
exports.getCurrencyRate = getCurrencyRate;
const getCurrency = async (id) => {
    const response = await Api.get('references/currencies/' + id);
    return (0, parsers_1.parseCurrency)(response);
};
exports.getCurrency = getCurrency;
const createCurrency = async (params) => {
    const response = await Api.post('references/currencies', params);
    return (0, parsers_1.parseCurrency)(response);
};
exports.createCurrency = createCurrency;
const updateCurrency = async (id, params) => {
    const response = await Api.patch('references/currencies/' + id, params);
    return (0, parsers_1.parseCurrency)(response);
};
exports.updateCurrency = updateCurrency;
const getCurrencyForSelect = async (params) => {
    const response = await Api.get('references/currencies', params);
    return (0, parsers_1.parseCurrencyForSelect)(response);
};
exports.getCurrencyForSelect = getCurrencyForSelect;
