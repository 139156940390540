"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const EmployeeContract_1 = require("../store/EmployeeContract");
const index_1 = require("api/index");
const useEmployeeContract = (props) => {
    const [state, dispatch] = (0, EmployeeContract_1.useEmployeeContractReducer)();
    const [showAlert, setShowAlert] = (0, react_1.useState)(false);
    const getContract = async () => {
        dispatch({ type: 'SEND_CONTRACT_FILE_REQUEST' });
        const response = await index_1.EmployeesApi.getEmployeeContract(props.hash);
        dispatch({ type: 'DONE_CONTRACT_FILE_REQUEST', payload: response });
    };
    const handleConfirm = async () => {
        dispatch({ type: 'SEND_CONTRACT_SIGN_REQUEST' });
        const response = await index_1.EmployeesApi.appointedCandidateSign(props.hash);
        dispatch({ type: 'DONE_CONTRACT_SIGN_REQUEST', payload: response });
        return response;
    };
    const handleErrorClose = () => {
        dispatch({ type: 'CLOSE_ERROR' });
    };
    (0, react_1.useEffect)(() => {
        if (props.hash)
            getContract().then();
    }, [props.hash]);
    return {
        state,
        handleConfirm,
        showAlert,
        handleErrorClose,
        setShowAlert,
        getContract
    };
};
exports.default = useEmployeeContract;
