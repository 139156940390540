"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCorrectionCalculations = void 0;
const correctionCalculations_1 = require("../store/correctionCalculations");
const index_1 = require("api/index");
const react_1 = require("react");
const useCorrectionCalculations = (procurementId) => {
    const [state, dispatch] = (0, correctionCalculations_1.useCorrectionCalculationsReducer)();
    const getProcurementCalculations = async () => {
        dispatch({ type: 'SEND_CORRECTION_CALCULATE_REQUEST' });
        const response = await index_1.ProcurementsApi.getCorrectionCalculations(procurementId);
        const data = response.data;
        if (data)
            dispatch({ type: 'DONE_CORRECTION_CALCULATE_REQUEST', payload: data });
    };
    (0, react_1.useEffect)(() => {
        getProcurementCalculations().then();
    }, []);
    return {
        state
    };
};
exports.useCorrectionCalculations = useCorrectionCalculations;
