"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vacation_1 = require("../store/vacation");
const index_1 = require("api/index");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useVacation = (props) => {
    const [state, dispatch] = (0, vacation_1.useVacationReducer)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const getVacation = async (id) => {
        dispatch({ type: 'SEND_VACATION_REQUEST' });
        const vacation = await index_1.VacationsApi.getVacation(id);
        dispatch({ type: 'DONE_VACATION_REQUEST', payload: vacation });
    };
    const createVacation = async (values) => {
        const params = (0, helpers_1.transformVacationParams)(values);
        dispatch({ type: 'SEND_VACATION_CREATE' });
        const vacation = await index_1.VacationsApi.createVacation(params);
        dispatch({ type: 'DONE_VACATION_CREATE', payload: vacation });
        if (vacation.data !== null)
            navigate(`/documents/human-resources/vacations/${vacation.data.id}/edit`);
        return vacation;
    };
    const updateVacation = async (id, values) => {
        const params = (0, helpers_1.transformVacationParams)(values);
        dispatch({ type: 'SEND_VACATION_UPDATE' });
        const vacation = await index_1.VacationsApi.updateVacation(id, params);
        dispatch({ type: 'DONE_VACATION_UPDATE', payload: vacation });
        return vacation;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getVacation(props.id).then(); }, [props.id]);
    return {
        state,
        createVacation,
        updateVacation,
        getVacation,
        closeErrorMessage
    };
};
exports.default = useVacation;
