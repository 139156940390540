"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseContractTypesForSelect = exports.parseContractType = exports.parseContractTypes = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseContractTypes = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const contractTypes = response.content;
    try {
        return {
            data: contractTypes.data.map(el => {
                return {
                    id: el.id,
                    positionType: {
                        id: el.relationships.positionType.data.id,
                        label: el.relationships.positionType.data.attributes.name,
                        index: el.relationships.positionType.data.attributes.index
                    },
                    name: el.attributes.name,
                    index: el.relationships.positionType.data.attributes.index || '',
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt),
                    content: el.attributes.content,
                    createdDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.createdAt),
                    updatedDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.updatedAt)
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(contractTypes.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseContractTypes = parseContractTypes;
const parseContractType = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const contractType = response.content.data;
    try {
        return {
            data: {
                id: contractType.id,
                positionType: {
                    id: contractType.relationships.positionType.data.id,
                    label: contractType.relationships.positionType.data.attributes.name,
                    index: contractType.relationships.positionType.data.attributes.index
                },
                index: contractType.relationships.positionType.data.attributes.index || '',
                name: contractType.attributes.name,
                content: contractType.attributes.content
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseContractType = parseContractType;
const parseContractTypesForSelect = (response) => {
    if (response.content === null)
        return [];
    const contractTypes = response.content;
    try {
        return contractTypes.data.map(el => {
            return {
                id: el.id,
                label: el.attributes.name
            };
        });
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return [];
    }
};
exports.parseContractTypesForSelect = parseContractTypesForSelect;
