"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformMediatorBanksParams = exports.transformBankFilterParamsToUrl = exports.transformBankParamsToFilterParams = exports.transformUrlToBankFilterParams = exports.transformBankParams = void 0;
const queryString_1 = require("core/helpers/queryString");
const transformBankParams = (params) => {
    return {
        title: params.title,
        code: params.code,
        iban: params.iban,
        type: params.type
    };
};
exports.transformBankParams = transformBankParams;
const transformUrlToBankFilterParams = (url) => {
    const values = (0, queryString_1.parse)(url);
    return {
        keyword: values.keyword,
        page: values.page,
        perPage: values.perPage
    };
};
exports.transformUrlToBankFilterParams = transformUrlToBankFilterParams;
const transformBankParamsToFilterParams = (params) => {
    const filters = {};
    const paginate = {};
    const filterParams = {
        keyword: params.keyword,
        page: params.page,
        perPage: params.perPage
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if (value && (key !== 'page' && key !== 'perPage'))
                filters[key] = value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(filters).length === 0)
            return { ...paginate };
        return { ...paginate, filters };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformBankParamsToFilterParams = transformBankParamsToFilterParams;
const transformBankFilterParamsToUrl = (values) => {
    const { page, perPage, keyword } = values;
    return {
        page,
        perPage,
        keyword
    };
};
exports.transformBankFilterParamsToUrl = transformBankFilterParamsToUrl;
const transformMediatorBanksParams = (values) => ({ ids: values.map(el => el.id) });
exports.transformMediatorBanksParams = transformMediatorBanksParams;
