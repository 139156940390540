"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const administrativeOrder_1 = require("../store/administrativeOrder");
const index_1 = require("api/index");
const helpers_1 = require("../helpers");
const react_router_dom_1 = require("react-router-dom");
const react_1 = require("react");
const useAdministrativeOrder = (props) => {
    const [state, dispatch] = (0, administrativeOrder_1.useAdministrativeOrderReducer)();
    const [hasArticles, setHasArticles] = (0, react_1.useState)(false);
    const navigate = (0, react_router_dom_1.useNavigate)();
    const getAdministrativeOrder = async (id) => {
        dispatch({ type: 'SEND_ADMINISTRATIVE_ORDER_REQUEST' });
        const administrativeOrder = await index_1.AdministrativeOrdersApi.getAdministrativeOrder(id);
        dispatch({ type: 'DONE_ADMINISTRATIVE_ORDER_REQUEST', payload: administrativeOrder });
    };
    const createAdministrativeOrder = async (values) => {
        const params = (0, helpers_1.transformAdministrativeOrderParams)(values);
        dispatch({ type: 'SEND_ADMINISTRATIVE_ORDER_CREATE' });
        const administrativeOrder = await index_1.AdministrativeOrdersApi.createAdministrativeOrder(params);
        dispatch({ type: 'DONE_ADMINISTRATIVE_ORDER_CREATE', payload: administrativeOrder });
        if (administrativeOrder.data !== null) {
            navigate(`/documents/administrative-orders/${administrativeOrder.data.id}/edit`);
        }
        return administrativeOrder;
    };
    const updateAdministrativeOrder = async (commandId, values) => {
        const params = (0, helpers_1.transformAdministrativeOrderParams)(values);
        dispatch({ type: 'SEND_ADMINISTRATIVE_ORDER_UPDATE' });
        const administrativeOrder = await index_1.AdministrativeOrdersApi.updateAdministrativeOrder(commandId, params);
        dispatch({ type: 'DONE_ADMINISTRATIVE_ORDER_UPDATE', payload: administrativeOrder });
        return administrativeOrder;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.commandId)
        getAdministrativeOrder(props.commandId).then(); }, [props.commandId]);
    return {
        state,
        createAdministrativeOrder,
        getAdministrativeOrder,
        updateAdministrativeOrder,
        closeErrorMessage,
        setHasArticles,
        hasArticles
    };
};
exports.default = useAdministrativeOrder;
