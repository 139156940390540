"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const helper_1 = require("../helper");
const react_1 = require("react");
const projectStructuralUnits_1 = require("../stores/projectStructuralUnits");
const useProjectStructuralUnits = (props) => {
    const [state, dispatch] = (0, projectStructuralUnits_1.useProjectStructuralUnitReducer)();
    const getStructuralUnits = async (projectId) => {
        dispatch({ type: 'SEND_GET_STRUCTURAL_UNIT' });
        const structuralUnits = await index_1.ProjectsApi.getStructuralUnitForProject({ filters: { projectIds: [projectId] } });
        dispatch({ type: 'DONE_GET_STRUCTURAL_UNIT', payload: structuralUnits });
    };
    const updateStructuralUnits = async (values) => {
        const params = (0, helper_1.transformProjectStructuralUnitParams)(values);
        dispatch({ type: 'SEND_UPDATE_STRUCTURAL_UNIT' });
        const structuralUnits = await index_1.ProjectsApi.updateStructuralUnitForProject(props.projectId, params);
        dispatch({ type: 'DONE_UPDATE_STRUCTURAL_UNIT', payload: structuralUnits });
        return structuralUnits;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => {
        if (props.projectId)
            getStructuralUnits(props.projectId).then();
    }, [props.projectId]);
    return {
        state,
        updateStructuralUnits,
        closeErrorMessage
    };
};
exports.default = useProjectStructuralUnits;
