"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sentEmailReducer = exports.useSentEmailReducer = exports.initialSentEmailState = void 0;
const react_1 = require("react");
const types_1 = require("api/administer/sentEmails/types");
const initialSentEmailValue = {
    status: types_1.SentEmailsStatuses.FAILED,
    subject: '',
    address: '',
    id: '',
    visualStatus: { name: '', color: '' },
    createdAt: '',
    updatedAt: '',
    ccAddresses: [],
    body: '',
    attachments: [],
    attachmentsQuantity: null
};
exports.initialSentEmailState = {
    data: initialSentEmailValue,
    errors: null,
    status: null,
    isFetching: false,
    sendingIds: []
};
const useSentEmailReducer = () => {
    return (0, react_1.useReducer)(exports.sentEmailReducer, exports.initialSentEmailState);
};
exports.useSentEmailReducer = useSentEmailReducer;
const sentEmailReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_SENT_EMAIL_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true
            };
        case 'DONE_SENT_EMAIL_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isFetching: false
            };
        case 'SEND_EMAIL_REQUEST':
            return {
                ...state,
                sendingIds: [...state.sendingIds, action.payload]
            };
        case 'DONE_EMAIL_REQUEST':
            return {
                ...state,
                data: action.payload.sentEmailData.data || state.data,
                errors: action.payload.sentEmailData.errors,
                status: action.payload.sentEmailData.status,
                sendingIds: state.sendingIds.filter(sendingId => sendingId !== action.payload.id)
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.sentEmailReducer = sentEmailReducer;
