"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assignSubmissionFormValidation = void 0;
const types_1 = require("api/documents/human-resources/assign-submissions/types");
const validates_1 = require("core/helpers/validates");
const assignSubmissionFormValidation = (values) => {
    const formErrors = {
        addresseesErrors: values.addressees.map(el => {
            return {
                addresseeStructuralUnit: (0, validates_1.required)(el.addresseeStructuralUnit),
                addressee: (0, validates_1.required)(el.id)
            };
        }),
        edocNumber: values.status === types_1.HRAssignSubmissionStatuses.SENT ? (0, validates_1.required)(values.edocNumber) : undefined,
        requestRegistrationDate: values.status === types_1.HRAssignSubmissionStatuses.SENT ? (0, validates_1.required)(values.requestRegistrationDate) : undefined,
        contactPerson: (0, validates_1.required)(values.contactPerson),
        contactPersonEmail: (0, validates_1.required)(values.contactPersonEmail),
        contactPersonPhoneNumber: (0, validates_1.required)(values.phoneNumber),
        submissionAuthor: (0, validates_1.required)(values.submissionAuthor),
        authorStructuralUnit: (0, validates_1.required)(values.authorStructuralUnit),
        purpose: (0, validates_1.sentenceContainsTwoWordsAndLetter)(values.purpose),
        filesError: values.status === types_1.HRAssignSubmissionStatuses.SENT ? (0, validates_1.requiredNotEmptyArray)(values.files) : undefined
    };
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (value && key !== 'addresseesErrors')
            errors[key] = value;
        if (formErrors.addresseesErrors.some(err => err.addressee || err.addresseeStructuralUnit)) {
            errors[key] = value;
        }
    });
    return errors;
};
exports.assignSubmissionFormValidation = assignSubmissionFormValidation;
