"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateHoliday = exports.createHoliday = exports.getHoliday = exports.deleteItem = exports.getHolidays = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/references/holidays/parsers");
const getHolidays = async (params) => {
    const response = await Api.get('references/holidays', params);
    return (0, parsers_1.parseHolidays)(response);
};
exports.getHolidays = getHolidays;
const deleteItem = async (id) => {
    return await Api.deleteItem(`references/holidays/${id}`);
};
exports.deleteItem = deleteItem;
const getHoliday = async (id) => {
    const response = await Api.get('references/holidays/' + id);
    return (0, parsers_1.parseHoliday)(response);
};
exports.getHoliday = getHoliday;
const createHoliday = async (params) => {
    const response = await Api.post('references/holidays', params);
    return (0, parsers_1.parseHoliday)(response);
};
exports.createHoliday = createHoliday;
const updateHoliday = async (id, params) => {
    const response = await Api.patch('references/holidays/' + id, params);
    return (0, parsers_1.parseHoliday)(response);
};
exports.updateHoliday = updateHoliday;
