"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const countries_1 = require("../store/countries");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helpers_1 = require("../helpers");
const queryString_1 = require("core/helpers/queryString");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useCountries = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, countries_1.useCountriesReducer)();
    const [keyword, setKeyword] = (0, react_1.useState)('');
    const [showDeleteAlert, setShowDeleteAlert] = (0, react_1.useState)(false);
    const [deletedCountryId, setDeletedCountryId] = (0, react_1.useState)(null);
    const isMounted = (0, react_1.useRef)(false);
    const navigatePagination = (page) => {
        const params = (0, queryString_1.parse)(location.search);
        getCountries({ ...params, page }).then();
    };
    const selectPerPage = (perPage) => {
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'CHANGE_PER_PAGE', payload: perPage });
        getCountries({ ...params, perPage, page: undefined }).then();
    };
    const deleteAlert = (id) => {
        setShowDeleteAlert(true);
        setDeletedCountryId(id);
    };
    const debouncedSearch = (value) => {
        setKeyword(value);
        const searchParams = (0, queryString_1.parse)(location.search);
        const params = value
            ? { perPage: searchParams.perPage, filters: { keyword: value } }
            : { page: searchParams.page };
        (0, debouncedMethods_1.debouncedOnFunc)(params, getCountries);
    };
    const deleteCountry = async (confirm) => {
        setShowDeleteAlert(false);
        if (!deletedCountryId || !confirm)
            return setDeletedCountryId(null);
        dispatch({ type: 'SEND_DELETE_REQUEST' });
        const countries = await index_1.CountriesApi.deleteCountry(deletedCountryId);
        const params = (0, queryString_1.parse)(location.search);
        if (countries.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            getCountries(params).then();
        dispatch({ type: 'DONE_DELETE_REQUEST', payload: { errors: countries.errors } });
        setDeletedCountryId(null);
    };
    const getCountries = async (params) => {
        dispatch({ type: 'SEND_COUNTRIES_REQUEST' });
        const countries = await index_1.CountriesApi.getCountries(params);
        if (isMounted?.current) {
            navigate('/references/addresses/countries' + (0, queryString_1.stringify)(params));
            dispatch({ type: 'DONE_COUNTRIES_REQUEST', payload: countries });
        }
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        const params = (0, helpers_1.getParamsFromUrl)(location.search);
        if (params.perPage)
            dispatch({ type: 'CHANGE_PER_PAGE', payload: params.perPage });
        setKeyword(params.filters?.keyword || '');
        getCountries(params).then();
        return () => { isMounted.current = false; };
    }, []);
    return {
        state,
        navigatePagination,
        selectPerPage,
        debouncedSearch,
        deleteAlert,
        deleteCountry,
        deletedCountryId,
        showDeleteAlert,
        keyword,
        getCountries
    };
};
exports.default = useCountries;
