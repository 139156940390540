"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialFile = exports.parseFileData = exports.parseMediaFileForPreview = exports.parseMediaFileForDownload = exports.parseMediaFiles = void 0;
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const helper_1 = require("api/helper");
const constants_1 = require("src/components/files/helpers/constants");
const parseMediaFiles = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const mediaFiles = response.content.data;
    try {
        return {
            data: {
                id: mediaFiles.id,
                name: mediaFiles.attributes.name,
                status: mediaFiles.attributes.status,
                link: mediaFiles.attributes.downloadUrl,
                originalName: mediaFiles.attributes.originalName,
                extension: mediaFiles.attributes.extension
            },
            status: response.status,
            errors: response.errors
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseMediaFiles = parseMediaFiles;
const parseMediaFileForDownload = (response, preview) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const downloadFile = response.content.data;
    try {
        return {
            data: {
                id: downloadFile.id,
                link: preview ? downloadFile.attributes.previewUrl : downloadFile.attributes.downloadUrl,
                originalName: downloadFile.attributes.originalName,
                name: downloadFile.attributes.name,
                status: downloadFile.attributes.status,
                extension: downloadFile.attributes.extension,
                downloadableFile: !!constants_1.fileExtensions.find(file => file.extension === downloadFile.attributes.extension && file.allowDownload)
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseMediaFileForDownload = parseMediaFileForDownload;
//todo @Davit გასაკეთებელია ერთი პარსერი რომელიც გაისპრედება
// parseMediaFileForPreview-ში და parseMediaFileForDownload-ში და დაემატება შესაბამისად ან downloadUrl ან previewUrl
// ასევე find-ი downloadableFile-ში უნდა გადაკეთდეს some-ად
// და ბოლოს PreviewFileData და DownloadFileData უნდა გაერთიანდეს
const parseMediaFileForPreview = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const previewFile = response.content.data;
    try {
        return {
            data: {
                id: previewFile.id,
                link: previewFile.attributes.previewUrl,
                originalName: previewFile.attributes.originalName,
                name: previewFile.attributes.name,
                status: previewFile.attributes.status,
                extension: previewFile.attributes.extension,
                downloadableFile: !!constants_1.fileExtensions.find(file => file.extension === previewFile.attributes.extension && file.allowDownload)
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseMediaFileForPreview = parseMediaFileForPreview;
const parseFileData = (file) => {
    return {
        link: file.attributes.downloadUrl,
        extension: file.attributes.extension,
        filename: file.attributes.filename,
        size: file.attributes.size
    };
};
exports.parseFileData = parseFileData;
exports.initialFile = { id: '', name: '', link: '', originalName: '', extension: '', status: apiGlobalTypes_1.FileDownloadStatus.TEMPORARY };
