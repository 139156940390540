"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const context_1 = require("src/context/context");
const GenerateNodes = ({ nodes, handleOpenClose, loadingItems }) => {
    const { toast } = (0, context_1.useToastContext)();
    return ((0, jsx_runtime_1.jsx)("ul", { className: 'jstree-children', children: nodes.map((item, index) => {
            const leaf = item.hasChildren ? '' : ' jstree-leaf';
            const loading = loadingItems.find(el => el.id === item.id && el.level === item.level) ? ' jstree-loading' : '';
            const isOpen = item.children.length > 0 && !item.closed ? ' jstree-open' : ' jstree-closed';
            const lastNode = !nodes[index + 1] ? ' jstree-last' : '';
            return ((0, jsx_runtime_1.jsxs)("li", { className: `jstree-node${leaf + isOpen + lastNode + loading}`, children: [(0, jsx_runtime_1.jsx)("i", { className: 'jstree-icon jstree-ocl', onClick: async (e) => {
                            e.preventDefault();
                            const res = await handleOpenClose(item);
                            if (res.errors !== null)
                                res.errors.forEach(el => toast.danger(el.message));
                        } }), (0, jsx_runtime_1.jsxs)("a", { className: 'jstree-anchor', children: [(0, jsx_runtime_1.jsx)("i", { className: `jstree-icon jstree-themeicon jstree-themeicon-custom fa
                  ${item.hasChildren ? 'fa-folder text-primary' : 'fa-file text-warning'}` }), item.name] }), item.children.length > 0 ?
                        (0, jsx_runtime_1.jsx)(GenerateNodes, { nodes: item.children, handleOpenClose: handleOpenClose, loadingItems: loadingItems }) : null] }, 'jstree-key-' + item.id));
        }) }));
};
const TreeNodes = (props) => {
    const { nodes, handleOpenClose, loadingItems } = props;
    return (((0, jsx_runtime_1.jsx)("div", { className: 'tree-demo jstree jstree-4 jstree-default', id: 'kt_tree_4', children: (0, jsx_runtime_1.jsx)("ul", { className: 'jstree-container-ul jstree-children jstree-contextmenu', children: nodes.length > 0 ?
                (0, jsx_runtime_1.jsx)(GenerateNodes, { nodes: nodes, handleOpenClose: handleOpenClose, loadingItems: loadingItems })
                : null }) })));
};
exports.default = TreeNodes;
