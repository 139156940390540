"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWorkTypesForSelect = exports.updateWorkType = exports.createWorkType = exports.getWorkType = exports.deleteItem = exports.getWorkTypes = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("../../../privateRequest"));
const parsers_1 = require("./parsers");
const parsers_2 = require("api/references/humanResources/workTypes/parsers");
const getWorkTypes = async (params) => {
    const response = await Api.get('references/human-resources/work-types', params);
    return (0, parsers_1.parseWorkTypes)(response);
};
exports.getWorkTypes = getWorkTypes;
const deleteItem = async (id) => {
    return await Api.deleteItem(`references/human-resources/work-types/${id}`);
};
exports.deleteItem = deleteItem;
const getWorkType = async (id) => {
    const response = await Api.get('references/human-resources/work-types/' + id);
    return (0, parsers_1.parseWorkType)(response);
};
exports.getWorkType = getWorkType;
const createWorkType = async (params) => {
    const response = await Api.post('references/human-resources/work-types', params);
    return (0, parsers_1.parseWorkType)(response);
};
exports.createWorkType = createWorkType;
const updateWorkType = async (id, params) => {
    const response = await Api.patch('references/human-resources/work-types/' + id, params);
    return (0, parsers_1.parseWorkType)(response);
};
exports.updateWorkType = updateWorkType;
const getWorkTypesForSelect = async (params) => {
    const response = await Api.get('references/human-resources/work-types', params);
    return (0, parsers_2.parseWorkTypesForSelect)(response);
};
exports.getWorkTypesForSelect = getWorkTypesForSelect;
