"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.structuralUnitRolesReducer = exports.useStructuralUnitRolesReducer = exports.initialStructuralUnitRolesState = void 0;
const react_1 = require("react");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
exports.initialStructuralUnitRolesState = {
    data: [],
    attachedRoles: [],
    loadingRoles: [],
    errors: null,
    isFetching: true,
    isFetchingAttachedRole: true
};
const useStructuralUnitRolesReducer = () => {
    return (0, react_1.useReducer)(exports.structuralUnitRolesReducer, exports.initialStructuralUnitRolesState);
};
exports.useStructuralUnitRolesReducer = useStructuralUnitRolesReducer;
const structuralUnitRolesReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_ROLES_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_ROLES_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                errors: action.payload.errors,
                isFetching: false
            };
        case 'SEND_STRUCTURAL_UNITS_ROLE_REQUEST':
            return {
                ...state,
                errors: null,
                isFetchingAttachedRole: true
            };
        case 'DONE_STRUCTURAL_UNITS_ROLE_REQUEST':
            return {
                ...state,
                errors: action.payload.errors,
                attachedRoles: action.payload.data || [],
                isFetchingAttachedRole: false
            };
        case 'SEND_ATTACH_ROLE_TO_STRUCTURAL_UNITS':
            return {
                ...state,
                loadingRoles: [...state.loadingRoles, ...action.payload],
                errors: null
            };
        case 'DONE_ATTACH_ROLE_TO_STRUCTURAL_UNITS':
            return {
                ...state,
                loadingRoles: state.loadingRoles.filter(el => !action.payload.attachedRoles.includes(el)),
                attachedRoles: action.payload.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS
                    ? [...state.attachedRoles, ...action.payload.attachedRoles]
                    : state.attachedRoles,
                errors: null
            };
        case 'SEND_DETACH_ROLE_TO_STRUCTURAL_UNITS':
            return {
                ...state,
                loadingRoles: [...state.loadingRoles, ...action.payload],
                errors: null
            };
        case 'DONE_DETACH_ROLE_TO_STRUCTURAL_UNITS':
            return {
                ...state,
                loadingRoles: state.loadingRoles.filter(el => !action.payload.attachedRoles.includes(el)),
                attachedRoles: action.payload.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS
                    ? state.attachedRoles.filter(el => !action.payload.attachedRoles.includes(el))
                    : state.attachedRoles,
                errors: null
            };
        default:
            return state;
    }
};
exports.structuralUnitRolesReducer = structuralUnitRolesReducer;
