"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const index_1 = require("api/index");
const sentEmails_1 = require("../store/sentEmails");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const queryString_1 = require("core/helpers/queryString");
const helpers_1 = require("../helpers");
const react_router_dom_1 = require("react-router-dom");
const useSentEmails = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, sentEmails_1.useSentEmailsReducer)();
    const [showSweetAlert, setShowSweetAlert] = (0, react_1.useState)(null);
    const isMounted = (0, react_1.useRef)(false);
    const [showFilter, setShowFilter] = (0, react_1.useState)(false);
    const navigatePagination = (page, values) => {
        getSentEmails({ ...values, page }).then();
    };
    const selectPerPage = (perPage, values) => {
        dispatch({ type: 'CHANGE_PER_PAGE', payload: { ...values, perPage } });
        getSentEmails({ ...values, perPage, page: 1 }).then();
    };
    const debouncedSearch = (name, value, filterValues) => {
        (0, debouncedMethods_1.debouncedOnFunc)({ ...filterValues, [name]: value, page: 1 }, getSentEmails);
    };
    const filter = (name, value, filterValues) => {
        getSentEmails({ ...filterValues, [name]: value, page: 1 }).then();
    };
    const sendEmail = async (id) => {
        dispatch({ type: 'SEND_EMAIL_REQUEST', payload: id });
        const sentEmail = await index_1.SentEmailsApi.sendEmail(id);
        dispatch({ type: 'DONE_EMAIL_REQUEST', payload: { sentEmailData: sentEmail, id } });
        return sentEmail;
    };
    const getSentEmails = async (values) => {
        dispatch({ type: 'SEND_SENT_EMAILS_REQUEST' });
        const filterParams = (0, helpers_1.transformSentEmailParamsToFilterParams)(values);
        const sentEmails = await index_1.SentEmailsApi.getSentEmails(filterParams);
        if (isMounted?.current) {
            navigate('/administer/sent-emails' + (0, queryString_1.stringify)((0, helpers_1.transformFilterParamsToUrl)(values)), { replace: true });
            dispatch({ type: 'DONE_SENT_EMAILS_REQUEST', payload: sentEmails });
        }
    };
    const initializeFilterForm = async () => {
        const params = (0, helpers_1.transformUrlToFilterParams)(location.search);
        if (params.status || params.createdAtFrom || params.createdAtTo)
            setShowFilter(true);
        dispatch({ type: 'INITIALIZE_FILTER_FORM', payload: params });
        getSentEmails(params).then();
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        initializeFilterForm().then();
        return () => { isMounted.current = false; };
    }, []);
    return {
        state,
        navigatePagination,
        selectPerPage,
        debouncedSearch,
        getSentEmails,
        setShowSweetAlert,
        showSweetAlert,
        sendEmail,
        setShowFilter,
        showFilter,
        filter
    };
};
exports.default = useSentEmails;
