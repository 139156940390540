"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBanksForSelect = exports.updateBank = exports.createBank = exports.getBank = exports.deleteItem = exports.createOrUpdateMediatorBanks = exports.getMediatorBanks = exports.getBanks = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("../../privateRequest"));
const parsers_1 = require("api/references/banks/parsers");
const getBanks = async (params) => {
    const response = await Api.get('references/banks', params);
    return (0, parsers_1.parseBanks)(response);
};
exports.getBanks = getBanks;
const getMediatorBanks = async (bankId) => {
    const response = await Api.get(`references/banks/${bankId}/mediator-banks`, {});
    return (0, parsers_1.parseMediatorBanks)(response);
};
exports.getMediatorBanks = getMediatorBanks;
const createOrUpdateMediatorBanks = async (bankId, params) => {
    const response = await Api.post(`references/banks/${bankId}/mediator-banks`, params);
    return (0, parsers_1.parseMediatorBanksData)(response);
};
exports.createOrUpdateMediatorBanks = createOrUpdateMediatorBanks;
const deleteItem = async (id) => {
    return await Api.deleteItem(`references/banks/${id}`);
};
exports.deleteItem = deleteItem;
const getBank = async (id) => {
    const response = await Api.get('references/banks/' + id);
    return (0, parsers_1.parseBank)(response);
};
exports.getBank = getBank;
const createBank = async (params) => {
    const response = await Api.post('references/banks', params);
    return (0, parsers_1.parseBank)(response);
};
exports.createBank = createBank;
const updateBank = async (id, params) => {
    const response = await Api.patch('references/banks/' + id, params);
    return (0, parsers_1.parseBank)(response);
};
exports.updateBank = updateBank;
const getBanksForSelect = async (params) => {
    const response = await Api.get('references/banks', params);
    return (0, parsers_1.parseBanksForSelect)(response);
};
exports.getBanksForSelect = getBanksForSelect;
