"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const assignment_1 = require("../store/assignment");
const index_1 = require("api/index");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const helper_1 = require("../helper");
const react_1 = require("react");
const useAssignHook = (props) => {
    const [state, dispatch] = (0, assignment_1.useAssignmentReducer)();
    const [currencies, setCurrencies] = (0, react_1.useState)([]);
    const [deleteAlert, setDeleteAlert] = (0, react_1.useState)({ showAlert: false, id: null });
    const [districts, setDistricts] = (0, react_1.useState)([]);
    const getEmployeeAssignments = async () => {
        dispatch({ type: 'SEND_EMPLOYEE_ASSIGNMENTS_REQUEST' });
        const employeeAssignments = await index_1.EmployeesApi.getAssignments(props.employeeId);
        dispatch({ type: 'DONE_EMPLOYEE_ASSIGNMENTS_REQUEST', payload: employeeAssignments });
    };
    const getEmployeeAssignment = async (originalId) => {
        dispatch({ type: 'SEND_EMPLOYEE_ASSIGNMENT_REQUEST' });
        const employeeAssignment = await index_1.EmployeesApi.getAssignment(originalId);
        if (employeeAssignment.data) {
            dispatch({ type: 'DONE_EMPLOYEE_ASSIGNMENT_REQUEST', payload: { ...employeeAssignment, data: { ...employeeAssignment.data, originalId } } });
        }
    };
    const deleteAssignment = async (confirm) => {
        if (!confirm || !deleteAlert.id) {
            setDeleteAlert({ showAlert: false, id: null });
            return;
        }
        setDeleteAlert({ ...deleteAlert, showAlert: false });
        dispatch({ type: 'SEND_ASSIGNMENT_DELETE_REQUEST' });
        const assignment = await index_1.EmployeesApi.deleteAssignment(deleteAlert.id);
        dispatch({ type: 'DONE_ASSIGNMENT_DELETE_REQUEST', payload: assignment.errors });
        setDeleteAlert({ showAlert: false, id: null });
        if (assignment.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            getEmployeeAssignments().then();
    };
    const createOrUpdateAssignment = async (values, correcting, originalId) => {
        const params = (0, helper_1.transformEmployeeAssignmentParams)(values, props.employeeId, correcting);
        if (correcting) {
            dispatch({ type: 'SEND_EMPLOYEE_ASSIGNMENT_CORRECTION_CREATE' });
            const assignment = await index_1.EmployeesApi.createEmployeeAssignmentCorrection(params, correcting);
            const assignmentsWithId = {
                ...assignment,
                data: assignment.data ? assignment.data.map(el => {
                    return {
                        ...el,
                        originalId: originalId
                    };
                }) : null
            };
            dispatch({ type: 'DONE_EMPLOYEE_ASSIGNMENT_CORRECTION_CREATE', payload: { data: assignmentsWithId.data } });
            return assignment;
        }
        else {
            dispatch({ type: 'SEND_EMPLOYEE_ASSIGNMENT_REQUEST_CREATE_UPDATE' });
            const employeeAssignments = await index_1.EmployeesApi.createEmployeeAssignment(params);
            const employeeAssignmentsWithOriginalIds = {
                ...employeeAssignments,
                data: employeeAssignments.data ? employeeAssignments.data.map(el => {
                    return {
                        ...el,
                        originalId: originalId
                    };
                }) : null
            };
            if (values.assignId === null) {
                dispatch({ type: 'DONE_EMPLOYEE_ASSIGNMENT_REQUEST_CREATE', payload: employeeAssignmentsWithOriginalIds });
            }
            else {
                dispatch({ type: 'DONE_EMPLOYEE_ASSIGNMENT_REQUEST_UPDATE', payload: employeeAssignmentsWithOriginalIds });
            }
            return employeeAssignments;
        }
    };
    const getCurrenciesForSelect = async (params) => {
        const data = await index_1.CurrenciesApi.getCurrencyForSelect(params);
        setCurrencies(data);
    };
    const handleCreateWorkType = async (value) => {
        dispatch({ type: 'SEND_CREATE_WORK_TYPE_REQUEST' });
        const workType = await index_1.WorkTypesApi.createWorkType({ name: value });
        dispatch({ type: 'DONE_CREATE_WORK_TYPE_REQUEST' });
        return workType;
    };
    (0, react_1.useEffect)(() => {
        getCurrenciesForSelect({ perPage: 300, sort: '+sort,-id' }).then();
    }, []);
    (0, react_1.useEffect)(() => {
        getEmployeeAssignments().then();
    }, []);
    return {
        state,
        createOrUpdateAssignment,
        currencies,
        handleCreateWorkType,
        deleteAssignment,
        deleteAlert,
        setDeleteAlert,
        setDistricts,
        districts,
        getEmployeeAssignment
    };
};
exports.default = useAssignHook;
