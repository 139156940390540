"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const dimensionUnit_1 = require("../store/dimensionUnit");
const api_1 = require("src/api");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useDimensionUnit = (props) => {
    const [state, dispatch] = (0, dimensionUnit_1.useDimensionUnitReducer)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const getDimensionUnit = async (id) => {
        dispatch({ type: 'SEND_DIMENSION_UNIT_REQUEST' });
        const dimensionUnit = await api_1.DimensionUnitsApi.getDimensionUnit(id);
        dispatch({ type: 'DONE_DIMENSION_UNIT_REQUEST', payload: dimensionUnit });
    };
    const createDimensionUnit = async (values) => {
        const params = (0, helpers_1.transformDimensionUnitParams)(values);
        dispatch({ type: 'SEND_DIMENSION_UNIT_CREATE' });
        const dimensionUnit = await api_1.DimensionUnitsApi.createDimensionUnit(params);
        dispatch({ type: 'DONE_DIMENSION_UNIT_CREATE', payload: dimensionUnit });
        if (dimensionUnit.data !== null) {
            navigate(`/references/dimension-units/${dimensionUnit.data.id}/edit`);
        }
        return dimensionUnit;
    };
    const updateDimensionUnit = async (id, values) => {
        const params = (0, helpers_1.transformDimensionUnitParams)(values);
        dispatch({ type: 'SEND_DIMENSION_UNIT_UPDATE' });
        const dimensionUnit = await api_1.DimensionUnitsApi.updateDimensionUnit(id, params);
        dispatch({ type: 'DONE_DIMENSION_UNIT_UPDATE', payload: dimensionUnit });
        return dimensionUnit;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getDimensionUnit(props.id).then(); }, [props.id]);
    return { state, createDimensionUnit, updateDimensionUnit, getDimensionUnit, closeErrorMessage };
};
exports.default = useDimensionUnit;
