"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseBankAccounts = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseBankAccounts = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const bankAccounts = response.content;
    try {
        return {
            data: bankAccounts.data.map(el => {
                const file = el.relationships.requisitesMediaFiles;
                const bank = el.relationships.bank;
                return {
                    id: el.id,
                    bankStatus: el.attributes.status,
                    accountNumber: el.attributes.accountNumber,
                    bank: {
                        id: bank.data.id,
                        label: bank.data.attributes.title,
                        title: bank.data.attributes.title + ' - ' + ' ( ' + bank.data.attributes.code + ' ) ',
                        code: bank.data.attributes.code,
                        type: bank.data.attributes.type,
                        iban: bank.data.attributes.iban
                    },
                    code: el.relationships.bank.data.attributes.code,
                    iban: el.relationships.bank.data.attributes.iban,
                    requisitesMediaFile: file && (0, parsers_1.parseFile)(file.data)
                };
            }),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseBankAccounts = parseBankAccounts;
