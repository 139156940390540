"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInventorySpecifications = void 0;
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const InventorySpecificationsType_1 = require("../store/InventorySpecificationsType");
const index_1 = require("api/index");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const queryString_1 = require("core/helpers/queryString");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const react_router_1 = require("react-router");
const useInventorySpecifications = () => {
    const navigate = (0, react_router_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, InventorySpecificationsType_1.useInventorySpecificationReducer)();
    const [linkParams, setLinkParams] = (0, react_1.useState)('');
    const [showDeleteAlert, setShowDeleteAlert] = (0, react_1.useState)(false);
    const [toDeleteId, setToDeleteId] = (0, react_1.useState)(null);
    const [specificationId, setSpecificationId] = (0, react_1.useState)(null);
    const isMounted = (0, react_1.useRef)(false);
    const navigatePagination = (page, values) => {
        getInventorySpecifications({ ...values, page }).then();
    };
    const filter = (name, value, filterValues) => {
        getInventorySpecifications({ ...filterValues, [name]: value, page: 1 }).then();
    };
    const selectPerPage = (perPage, values) => {
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'CHANGE_PER_PAGE', payload: { ...values, perPage } });
        getInventorySpecifications({ ...params, perPage, page: undefined }).then();
    };
    const debouncedSearch = (name, value, filterValues) => {
        (0, debouncedMethods_1.debouncedOnFunc)({ ...filterValues, [name]: value, page: 1 }, getInventorySpecifications);
    };
    const getInventorySpecifications = async (values) => {
        dispatch({ type: 'SEND_INVENTORY_SPECIFICATION_REQUEST' });
        const filterParams = (0, helpers_1.transformSpecificationParamsToFilterParams)(values);
        const specifications = await index_1.PurchasesSpecificationsApi.getInventorySpecifications(filterParams);
        if (specifications.data !== null)
            setLinkParams((0, queryString_1.stringify)((0, helpers_1.transformSpecificationFilterParamsToUrl)(values)));
        if (isMounted?.current) {
            dispatch({ type: 'DONE_INVENTORY_SPECIFICATION_REQUEST', payload: specifications });
            navigate('/references/purchases/purchase-specification' + (0, queryString_1.stringify)((0, helpers_1.transformSpecificationFilterParamsToUrl)(values)), { replace: true });
        }
    };
    const deleteAlert = (id) => {
        setShowDeleteAlert(true);
        setToDeleteId(id);
    };
    const deleteInventorySpecification = async (confirm) => {
        setShowDeleteAlert(false);
        if (!toDeleteId || !confirm)
            return setToDeleteId(null);
        dispatch({ type: 'SEND_DELETE_REQUEST' });
        const specification = await index_1.PurchasesSpecificationsApi.deleteInventorySpecification(toDeleteId);
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'DONE_DELETE_REQUEST', payload: { errors: specification.errors } });
        if (specification.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            getInventorySpecifications(params).then();
        setToDeleteId(null);
    };
    const initializeFilterForm = async () => {
        const params = (0, helpers_1.transformUrlToSpecificationFilterParams)(location.search);
        const specificationListId = (0, queryString_1.parse)(location.search).specificationId;
        getInventorySpecifications(params).then(() => dispatch({ type: 'INITIALIZE_FILTER_FORM', payload: params }));
        setSpecificationId(specificationListId);
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        initializeFilterForm().then();
        return () => { isMounted.current = false; };
    }, []);
    return {
        debouncedSearch,
        navigatePagination,
        deleteAlert,
        deleteInventorySpecification,
        toDeleteId,
        showDeleteAlert,
        state,
        selectPerPage,
        filter,
        linkParams,
        getInventorySpecifications,
        specificationId
    };
};
exports.useInventorySpecifications = useInventorySpecifications;
exports.default = exports.useInventorySpecifications;
