"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assignContractsReducer = exports.useContractSigningListReducer = exports.initialContractSigningState = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
const initialFilterForm = {
    keyword: ''
};
exports.initialContractSigningState = {
    data: [],
    status: null,
    meta: store_1.initialMeta,
    filterFormValue: initialFilterForm,
    errors: null,
    isFetching: false,
    isDeleting: false,
    isNotifying: false,
    isNotifyingAll: false,
    isFetched: false
};
const useContractSigningListReducer = () => {
    return (0, react_1.useReducer)(exports.assignContractsReducer, exports.initialContractSigningState);
};
exports.useContractSigningListReducer = useContractSigningListReducer;
const assignContractsReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_CONTRACTS_LIST_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_CONTRACTS_LIST_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'SEND_NOTIFY_PENDING_CONTRACT_FILES':
            return {
                ...state,
                isNotifying: true
            };
        case 'DONE_NOTIFY_PENDING_CONTRACT_FILES':
            return {
                ...state,
                isNotifying: false
            };
        case 'SEND_NOTIFY_ALL_PENDING_CONTRACT_FILES':
            return {
                ...state,
                isNotifyingAll: true
            };
        case 'DONE_NOTIFY_ALL_PENDING_CONTRACT_FILES':
            return {
                ...state,
                isNotifyingAll: false
            };
        case 'INITIALIZE_FILTER_FORM':
            return {
                ...state,
                filterFormValue: action.payload,
                isFetched: true
            };
        default:
            return state;
    }
};
exports.assignContractsReducer = assignContractsReducer;
