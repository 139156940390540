"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const fundingSources_1 = require("../store/fundingSources");
const index_1 = require("api/index");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const useFundingSources = (props) => {
    const [state, dispatch] = (0, fundingSources_1.useFundingSourcesFormReducer)();
    const getFundingSources = async (structuralUnitId) => {
        dispatch({ type: 'SEND_FUNDING_SOURCES_REQUEST' });
        const FundingSources = await index_1.StructuralUnitsUpgradedApi.getFundingSources(structuralUnitId);
        dispatch({ type: 'DONE_FUNDING_SOURCES_REQUEST', payload: FundingSources });
    };
    const updateFundingSources = async (params, structuralUnitId) => {
        const fundingSourcesParams = (0, helpers_1.transformArraySelectTypeToIds)(params);
        dispatch({ type: 'SEND_UPDATE_FUNDING_SOURCES' });
        const fundingSources = await index_1.StructuralUnitsUpgradedApi.updateFundingSources(structuralUnitId, fundingSourcesParams);
        dispatch({ type: 'DONE_UPDATE_FUNDING_SOURCES', payload: fundingSources });
        return fundingSources;
    };
    (0, react_1.useEffect)(() => {
        if (props.structuralUnitId)
            getFundingSources(props.structuralUnitId).then();
    }, [props.structuralUnitId]);
    return { updateFundingSources, state };
};
exports.default = useFundingSources;
