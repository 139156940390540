"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const ErrorMessages = (props) => {
    (0, react_1.useEffect)(() => {
        if (!props.errors)
            return;
        const error = document.getElementsByClassName('error-message-text')[0];
        if (error) {
            error.scrollIntoView({ behavior: 'smooth', block: 'center' });
            error.focus({ preventScroll: true });
        }
    }, [props.errors]);
    if (!props.errors)
        return null;
    return ((0, jsx_runtime_1.jsx)("div", { children: props.errors.map((el, index) => {
            return ((0, jsx_runtime_1.jsxs)("div", { className: 'alert alert-custom alert-notice alert-light-danger fade show p-3', children: [(0, jsx_runtime_1.jsx)("div", { className: 'alert-icon', children: (0, jsx_runtime_1.jsx)("i", { className: 'flaticon-warning' }) }), (0, jsx_runtime_1.jsx)("div", { className: 'alert-text error-message-text', children: el.message }), (0, jsx_runtime_1.jsx)("div", { className: 'alert-close', children: (0, jsx_runtime_1.jsx)("button", { type: 'button', className: 'close p-5', onClick: () => props.closeErrorMessage(index), children: (0, jsx_runtime_1.jsx)("span", { children: (0, jsx_runtime_1.jsx)("i", { className: 'ki ki-close' }) }) }) })] }, index));
        }) }));
};
exports.default = ErrorMessages;
