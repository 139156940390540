"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.roomsReducer = exports.useRoomsReducer = exports.initialRoomsState = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
exports.initialRoomsState = {
    data: [],
    status: null,
    meta: store_1.initialMeta,
    errors: null,
    isFetching: true,
    isDeleting: false
};
const useRoomsReducer = () => {
    return (0, react_1.useReducer)(exports.roomsReducer, exports.initialRoomsState);
};
exports.useRoomsReducer = useRoomsReducer;
const roomsReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_ROOMS_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_ROOMS_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                status: action.payload.status,
                errors: action.payload.errors,
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'SEND_ROOM_DELETE_REQUEST':
            return {
                ...state,
                isDeleting: true
            };
        case 'DONE_ROOM_DELETE_REQUEST':
            return {
                ...state,
                errors: action.payload.errors,
                isDeleting: false
            };
        case 'CHANGE_PER_PAGE':
            return {
                ...state,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        perPage: action.payload
                    }
                }
            };
        default:
            return state;
    }
};
exports.roomsReducer = roomsReducer;
