"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useLegalBasisSidebar = () => {
    const [openedLegalBasisSidebar, setOpenedLegalBasisSidebar] = (0, react_1.useState)(false);
    return {
        openedLegalBasisSidebar,
        setOpenedLegalBasisSidebar
    };
};
exports.default = useLegalBasisSidebar;
