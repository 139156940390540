"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Citizen = exports.EmploymentContractType = exports.ContractContinuationType = exports.AssignCategory = exports.AssignType = exports.AssignSubmissionItemFileTypes = exports.AssignSubmissionVerifyItemStatuses = exports.WorkLoad = exports.SalaryGivenType = exports.SalaryType = exports.HRAssignSubmissionSentBy = exports.HRAssignSubmissionStatuses = exports.AssignSubmissionFileTypes = void 0;
var AssignSubmissionFileTypes;
(function (AssignSubmissionFileTypes) {
    AssignSubmissionFileTypes[AssignSubmissionFileTypes["EDOC_DOCUMENT"] = 1] = "EDOC_DOCUMENT";
})(AssignSubmissionFileTypes || (exports.AssignSubmissionFileTypes = AssignSubmissionFileTypes = {}));
var HRAssignSubmissionStatuses;
(function (HRAssignSubmissionStatuses) {
    HRAssignSubmissionStatuses[HRAssignSubmissionStatuses["DRAFT"] = 1] = "DRAFT";
    HRAssignSubmissionStatuses[HRAssignSubmissionStatuses["CONFIRMED"] = 2] = "CONFIRMED";
    HRAssignSubmissionStatuses[HRAssignSubmissionStatuses["DECLINED"] = 3] = "DECLINED";
    HRAssignSubmissionStatuses[HRAssignSubmissionStatuses["SENT_TO_VERIFY"] = 4] = "SENT_TO_VERIFY";
    HRAssignSubmissionStatuses[HRAssignSubmissionStatuses["SENT"] = 5] = "SENT";
})(HRAssignSubmissionStatuses || (exports.HRAssignSubmissionStatuses = HRAssignSubmissionStatuses = {}));
var HRAssignSubmissionSentBy;
(function (HRAssignSubmissionSentBy) {
    HRAssignSubmissionSentBy[HRAssignSubmissionSentBy["SENT_BY_EDOC"] = 1] = "SENT_BY_EDOC";
    HRAssignSubmissionSentBy[HRAssignSubmissionSentBy["SENT_BY_CHANCELLERY"] = 2] = "SENT_BY_CHANCELLERY";
})(HRAssignSubmissionSentBy || (exports.HRAssignSubmissionSentBy = HRAssignSubmissionSentBy = {}));
var SalaryType;
(function (SalaryType) {
    SalaryType[SalaryType["FIXED"] = 1] = "FIXED";
    SalaryType[SalaryType["FIXED_RESERVATION"] = 2] = "FIXED_RESERVATION";
    SalaryType[SalaryType["SUPPLEMENT"] = 3] = "SUPPLEMENT";
    SalaryType[SalaryType["BY_JOB"] = 4] = "BY_JOB";
    SalaryType[SalaryType["UNPAID"] = 5] = "UNPAID";
})(SalaryType || (exports.SalaryType = SalaryType = {}));
var SalaryGivenType;
(function (SalaryGivenType) {
    SalaryGivenType[SalaryGivenType["INVOICE"] = 1] = "INVOICE";
    SalaryGivenType[SalaryGivenType["SUBMISSION"] = 2] = "SUBMISSION";
    SalaryGivenType[SalaryGivenType["INVOICE_AND_SUBMISSION"] = 3] = "INVOICE_AND_SUBMISSION";
    SalaryGivenType[SalaryGivenType["ADMINISTRATIVE_ORDER"] = 4] = "ADMINISTRATIVE_ORDER";
    SalaryGivenType[SalaryGivenType["CONTRACT"] = 5] = "CONTRACT";
})(SalaryGivenType || (exports.SalaryGivenType = SalaryGivenType = {}));
var WorkLoad;
(function (WorkLoad) {
    WorkLoad[WorkLoad["FULL"] = 1] = "FULL";
    WorkLoad[WorkLoad["PARTIAL"] = 2] = "PARTIAL";
})(WorkLoad || (exports.WorkLoad = WorkLoad = {}));
var AssignSubmissionVerifyItemStatuses;
(function (AssignSubmissionVerifyItemStatuses) {
    AssignSubmissionVerifyItemStatuses[AssignSubmissionVerifyItemStatuses["DRAFT"] = 1] = "DRAFT";
    AssignSubmissionVerifyItemStatuses[AssignSubmissionVerifyItemStatuses["DECLINED"] = 2] = "DECLINED";
    AssignSubmissionVerifyItemStatuses[AssignSubmissionVerifyItemStatuses["SUSPENDED"] = 3] = "SUSPENDED";
    AssignSubmissionVerifyItemStatuses[AssignSubmissionVerifyItemStatuses["CONFIRMED"] = 4] = "CONFIRMED";
    AssignSubmissionVerifyItemStatuses[AssignSubmissionVerifyItemStatuses["NEEDS_NO_VERIFY"] = 5] = "NEEDS_NO_VERIFY";
    AssignSubmissionVerifyItemStatuses[AssignSubmissionVerifyItemStatuses["VERIFY_AT_PAYMENT_STAGE"] = 6] = "VERIFY_AT_PAYMENT_STAGE";
    AssignSubmissionVerifyItemStatuses[AssignSubmissionVerifyItemStatuses["VERIFY_BY_BUDGETING_DEPT"] = 7] = "VERIFY_BY_BUDGETING_DEPT";
})(AssignSubmissionVerifyItemStatuses || (exports.AssignSubmissionVerifyItemStatuses = AssignSubmissionVerifyItemStatuses = {}));
var AssignSubmissionItemFileTypes;
(function (AssignSubmissionItemFileTypes) {
    AssignSubmissionItemFileTypes[AssignSubmissionItemFileTypes["PASSPORT"] = 1] = "PASSPORT";
    AssignSubmissionItemFileTypes[AssignSubmissionItemFileTypes["DIPLOMA"] = 2] = "DIPLOMA";
    AssignSubmissionItemFileTypes[AssignSubmissionItemFileTypes["CV"] = 3] = "CV";
    AssignSubmissionItemFileTypes[AssignSubmissionItemFileTypes["BANKING_REQUISITES"] = 4] = "BANKING_REQUISITES";
    AssignSubmissionItemFileTypes[AssignSubmissionItemFileTypes["TAX_CERTIFICATE"] = 5] = "TAX_CERTIFICATE";
    AssignSubmissionItemFileTypes[AssignSubmissionItemFileTypes["CONVICTION"] = 6] = "CONVICTION";
    AssignSubmissionItemFileTypes[AssignSubmissionItemFileTypes["OTHER"] = 7] = "OTHER";
})(AssignSubmissionItemFileTypes || (exports.AssignSubmissionItemFileTypes = AssignSubmissionItemFileTypes = {}));
var AssignType;
(function (AssignType) {
    AssignType[AssignType["CONTRACT"] = 1] = "CONTRACT";
    AssignType[AssignType["ORDER"] = 2] = "ORDER";
    AssignType[AssignType["ORDER_CONTRACT"] = 3] = "ORDER_CONTRACT";
    AssignType[AssignType["CONTRACT_EXT"] = 4] = "CONTRACT_EXT";
})(AssignType || (exports.AssignType = AssignType = {}));
// export enum PositionType {
//   ACADEMIC = 1,
//   ADMINISTRATIVE = 2,
//   RESEARCH = 3,
//   ASSISTANT_PERSONNEL = 4,
//   IN_LEARNING_PROCESS = 5,
//   TEACHER = 6,
//   UNDER_GRANT = 7
// }
var AssignCategory;
(function (AssignCategory) {
    AssignCategory[AssignCategory["ELECTED"] = 1] = "ELECTED";
    AssignCategory[AssignCategory["INVITED"] = 2] = "INVITED";
})(AssignCategory || (exports.AssignCategory = AssignCategory = {}));
var ContractContinuationType;
(function (ContractContinuationType) {
    ContractContinuationType[ContractContinuationType["SAME_CONDITIONS"] = 1] = "SAME_CONDITIONS";
    ContractContinuationType[ContractContinuationType["CONDITION_CORRECTION"] = 2] = "CONDITION_CORRECTION";
})(ContractContinuationType || (exports.ContractContinuationType = ContractContinuationType = {}));
var EmploymentContractType;
(function (EmploymentContractType) {
    EmploymentContractType[EmploymentContractType["CONTRACT"] = 1] = "CONTRACT";
    EmploymentContractType[EmploymentContractType["CORRECTION"] = 2] = "CORRECTION";
})(EmploymentContractType || (exports.EmploymentContractType = EmploymentContractType = {}));
var Citizen;
(function (Citizen) {
    Citizen[Citizen["GEORGIAN"] = 1] = "GEORGIAN";
    Citizen[Citizen["FOREIGN"] = 2] = "FOREIGN";
})(Citizen || (exports.Citizen = Citizen = {}));
