"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.determinationReducer = exports.useDeterminationReducer = exports.initialDeterminationState = void 0;
const types_1 = require("api/documents/purchases/conditional-submissions/types");
const react_1 = require("react");
const initialDeterminationValue = {
    edocNumber: '',
    requestRegistrationDate: null,
    content: '',
    number: '',
    files: [],
    additionalFiles: [],
    disabledStatus: false,
    status: types_1.DeterminationsStatuses.DRAFT,
    visualStatus: { name: 'პროექტი', color: 'secondary' },
    visualSentBy: null,
    sentBy: null,
    creator: null,
    isOriginal: false,
    hasDeterminations: false,
    updatedAt: null //TODO @Merab todo should be removed after Tornike migrate database
};
exports.initialDeterminationState = {
    determinations: [],
    currentVersion: initialDeterminationValue,
    errors: null,
    determinationErrors: null,
    status: null,
    isCreating: false,
    isLoading: false,
    isFetching: false,
    isUpdating: false,
    isSending: false,
    isSuspending: false,
    isDeclining: false,
    isCreatingSubmission: false,
    isFetchingDeterminations: false
};
const useDeterminationReducer = () => {
    return (0, react_1.useReducer)(exports.determinationReducer, exports.initialDeterminationState);
};
exports.useDeterminationReducer = useDeterminationReducer;
const determinationReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_DETERMINATIONS_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_DETERMINATIONS_REQUEST':
            return {
                ...state,
                determinations: action.payload.data || state.determinations,
                status: action.payload.status,
                determinationErrors: action.payload.errors,
                isFetching: false,
                isLoading: state.isCreating
                    || state.isUpdating
                    || state.isSending
                    || state.isCreatingSubmission
                    || state.isDeclining
                    || state.isSuspending
            };
        case 'SEND_DETERMINATION_CREATE':
            return {
                ...state,
                errors: null,
                isCreating: true,
                isLoading: true
            };
        case 'DONE_DETERMINATION_CREATE':
            return {
                ...state,
                currentVersion: action.payload.data || state.currentVersion,
                errors: action.payload.errors,
                isCreating: false,
                status: action.payload.status,
                isLoading: state.isFetching
                    || state.isUpdating
                    || state.isSending
                    || state.isCreatingSubmission
                    || state.isDeclining
                    || state.isSuspending
            };
        case 'SEND_DETERMINATION_UPDATE':
            return {
                ...state,
                errors: null,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_DETERMINATION_UPDATE':
            return {
                ...state,
                currentVersion: action.payload.data || state.currentVersion,
                errors: action.payload.errors,
                status: action.payload.status,
                isUpdating: false,
                isLoading: state.isCreating
                    || state.isFetching
                    || state.isSending
                    || state.isCreatingSubmission
                    || state.isDeclining
                    || state.isSuspending
            };
        case 'SEND_DETERMINATION_SEND_REQUEST':
            return {
                ...state,
                isSending: true,
                isLoading: true
            };
        case 'DONE_DETERMINATION_SEND_REQUEST':
            return {
                ...state,
                currentVersion: action.payload.data || state.currentVersion,
                determinations: (state.determinations.map(item => item.id === action.payload.data?.id
                    ? action.payload.data
                    : item)) || state.determinations,
                errors: action.payload.errors,
                status: action.payload.status,
                isSending: false,
                isLoading: state.isCreating
                    || state.isFetching
                    || state.isUpdating
                    || state.isCreatingSubmission
                    || state.isDeclining
                    || state.isSuspending
            };
        case 'SEND_DETERMINATION_SUSPEND_REQUEST':
            return {
                ...state,
                errors: null,
                isSuspending: true,
                isLoading: true
            };
        case 'DONE_DETERMINATION_SUSPEND_REQUEST':
            return {
                ...state,
                currentVersion: action.payload.data || state.currentVersion,
                determinations: (state.determinations.map(item => item.id === action.payload.data?.id
                    ? action.payload.data
                    : item)) || state.determinations,
                status: action.payload.status,
                errors: action.payload.errors,
                isSuspending: false,
                isLoading: state.isCreating
                    || state.isUpdating
                    || state.isSending
                    || state.isCreatingSubmission
                    || state.isFetching
                    || state.isDeclining
            };
        case 'SEND_DETERMINATION_DECLINE_REQUEST':
            return {
                ...state,
                errors: null,
                isDeclining: true,
                isLoading: true
            };
        case 'DONE_DETERMINATION_DECLINE_REQUEST':
            return {
                ...state,
                currentVersion: action.payload.data || state.currentVersion,
                determinations: (state.determinations.map(item => item.id === action.payload.data?.id
                    ? action.payload.data
                    : item)) || state.determinations,
                status: action.payload.status,
                errors: action.payload.errors,
                isDeclining: false,
                isLoading: state.isCreating
                    || state.isUpdating
                    || state.isSending
                    || state.isCreatingSubmission
                    || state.isFetching
                    || state.isSuspending
            };
        case 'SEND_PROGRESS_IN_PURCHASE_REQUEST':
            return {
                ...state,
                isCreatingSubmission: true,
                isLoading: true
            };
        case 'DONE_PROGRESS_IN_PURCHASE_REQUEST':
            return {
                ...state,
                currentVersion: action.payload.data || state.currentVersion,
                determinations: (state.determinations.map(item => item.id === action.payload.data?.id
                    ? action.payload.data
                    : item)) || state.determinations,
                status: action.payload.status,
                errors: action.payload.errors,
                isCreatingSubmission: false,
                isLoading: state.isCreating
                    || state.isFetching
                    || state.isUpdating
                    || state.isSending
                    || state.isSuspending
                    || state.isDeclining
            };
        case 'CHANGE_CURRENT_VERSION':
            return {
                ...state,
                currentVersion: state.determinations.find(el => el.id === action.payload) || state.currentVersion
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.determinationReducer = determinationReducer;
