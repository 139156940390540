"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.subjectReducer = exports.initialSubjectState = void 0;
const store_1 = require("core/store");
exports.initialSubjectState = {
    data: [],
    meta: store_1.initialMeta,
    errors: null,
    isFetching: false
};
const subjectReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_SUBJECTS_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_SUBJECTS_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                errors: action.payload.errors,
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'CHANGE_PER_PAGE':
            return {
                ...state,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        perPage: action.payload
                    }
                }
            };
        default:
            return state;
    }
};
exports.subjectReducer = subjectReducer;
