"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getParamsFromUrl = exports.transformBudgetSourceParams = void 0;
const queryString_1 = require("core/helpers/queryString");
const transformBudgetSourceParams = (params) => {
    return {
        name: params.name,
        fundingSourceId: params.fundingSource.id,
        structuralUnitId: params.structuralUnit?.id || null
    };
};
exports.transformBudgetSourceParams = transformBudgetSourceParams;
const getParamsFromUrl = (url) => {
    try {
        const params = (0, queryString_1.parse)(url);
        return {
            page: params.page,
            perPage: params.perPage,
            filters: params.filters?.keyword ? { keyword: params.filters.keyword } : undefined
        };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.getParamsFromUrl = getParamsFromUrl;
