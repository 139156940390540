"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const types_1 = require("api/references/employees/types");
const dismissal_1 = require("../store/dismissal");
const react_1 = require("react");
const index_1 = require("api/index");
const employees_1 = require("api/references/employees");
const queryString_1 = require("core/helpers/queryString");
const helper_1 = require("../helper");
const helpers_1 = require("../../../administer/users/helpers");
const react_router_dom_1 = require("react-router-dom");
const useDismissal = (props) => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, dismissal_1.useDismissalReducer)();
    const [deleteDismissalAlert, setDeleteDismissalAlert] = (0, react_1.useState)({ showAlert: false, id: null });
    const [assignOptions, setAssignOptions] = (0, react_1.useState)([]);
    const [cascadedItems, setCascadedItems] = (0, react_1.useState)([]);
    const getDismissals = async (params) => {
        dispatch({ type: 'SEND_DISMISSAL_REQUEST' });
        const dismissals = await index_1.EmployeesApi.getDismissals(params, props.employeeId);
        navigate(`/references/employees/${props.employeeId}/edit` + (0, queryString_1.stringify)(params), { replace: true });
        dispatch({ type: 'DONE_DISMISSAL_REQUEST', payload: dismissals });
    };
    const deleteDismissal = async (confirm) => {
        if (!confirm || !deleteDismissalAlert.id)
            return setDeleteDismissalAlert({ showAlert: false, id: null });
        setDeleteDismissalAlert({ ...deleteDismissalAlert, showAlert: false });
        dispatch({ type: 'SEND_DELETE_DISMISSAL' });
        const employee = await index_1.EmployeesApi.deleteDismissal(deleteDismissalAlert.id);
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'DONE_DELETE_DISMISSAL', payload: { errors: employee.errors } });
        setDeleteDismissalAlert({ showAlert: false, id: null });
        if (props.employeeId)
            getDismissals(params).then();
    };
    const addDismissal = async (values) => {
        const params = (0, helper_1.transformEmployeeDismissalsParams)(values);
        dispatch({ type: 'SEND_DISMISSAL_CREATE' });
        const employeeDismissalData = await index_1.EmployeesApi.addDismissal(params);
        dispatch({ type: 'DONE_DISMISSAL_CREATE', payload: employeeDismissalData });
        return employeeDismissalData;
    };
    const updateDismissalItem = async (id, values) => {
        const params = (0, helper_1.transformEmployeeDismissalsParams)(values);
        dispatch({ type: 'SEND_DISMISSAL_ITEM_UPDATE' });
        const dismissalItem = await index_1.EmployeesApi.updateDismissal(id, params);
        dispatch({ type: 'DONE_DISMISSAL_ITEM_UPDATE', payload: dismissalItem });
        return dismissalItem;
    };
    const selectPerPage = (perPage) => {
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'CHANGE_PER_PAGE', payload: perPage });
        getDismissals({ ...params, perPage, page: undefined }).then();
    };
    const navigatePagination = (page) => {
        const params = (0, queryString_1.parse)(location.search);
        getDismissals({ ...params, page }).then();
    };
    const handleCascadeItem = (id) => {
        cascadedItems.includes(id)
            ? setCascadedItems(cascadedItems.filter(el => el !== id))
            : setCascadedItems([...cascadedItems, id]);
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => {
        if (props.employeeId) {
            const params = (0, helpers_1.transformUrlToUserFilterParams)(location.search);
            if (params.perPage)
                dispatch({ type: 'CHANGE_PER_PAGE', payload: params.perPage });
            getDismissals(params).then();
            (0, employees_1.getMajorContractsForSelect)({
                filters: { userId: props.employeeId, contractStatus: [types_1.ContractStatuses.ACTIVE, types_1.ContractStatuses.EXPIRED] },
                perPage: 100
            }).then(results => setAssignOptions(results));
        }
    }, [props.employeeId]);
    return {
        state,
        closeErrorMessage,
        deleteDismissal,
        deleteDismissalAlert,
        addDismissal,
        setDeleteDismissalAlert,
        assignOptions,
        setAssignOptions,
        cascadedItems,
        setCascadedItems,
        handleCascadeItem,
        updateDismissalItem,
        selectPerPage,
        navigatePagination,
        getDismissals
    };
};
exports.default = useDismissal;
