"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createMajorPositionType = exports.getMajorPositionTypesForSelect = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/references/humanResources/majorPositionType/parsers");
const react_toastify_1 = require("react-toastify");
const getMajorPositionTypesForSelect = async (params) => {
    const response = await Api.get('references/human-resources/major-position-types', params);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, parsers_1.parseMajorPositionTypesForSelect)(response);
};
exports.getMajorPositionTypesForSelect = getMajorPositionTypesForSelect;
const createMajorPositionType = async (params) => {
    const response = await Api.post('references/human-resources/major-position-types', params);
    return (0, parsers_1.parseMajorPositionType)(response);
};
exports.createMajorPositionType = createMajorPositionType;
