"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const orders_1 = require("../store/orders");
const index_1 = require("api/index");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const useOrders = (props) => {
    const [state, dispatch] = (0, orders_1.useOrdersFormReducer)();
    const getOrders = async (structuralUnitId) => {
        dispatch({ type: 'SEND_ORDERS_REQUEST' });
        const orders = await index_1.StructuralUnitsUpgradedApi.getOrders(structuralUnitId);
        dispatch({ type: 'DONE_ORDERS_REQUEST', payload: orders });
    };
    const updateOrders = async (params) => {
        const ordersParams = (0, helpers_1.transformArraySelectTypeToIds)(params);
        dispatch({ type: 'SEND_UPDATE_ORDERS' });
        const orders = await index_1.StructuralUnitsUpgradedApi.updateOrders(props.structuralUnitId, ordersParams);
        dispatch({ type: 'DONE_UPDATE_ORDERS', payload: orders });
        return orders;
    };
    (0, react_1.useEffect)(() => {
        if (props.structuralUnitId)
            getOrders(props.structuralUnitId).then();
    }, [props.structuralUnitId]);
    return { updateOrders, state };
};
exports.default = useOrders;
