"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCpvCodes = exports.getCpvCodesForSelect = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("./parsers");
const getCpvCodesForSelect = async (params) => {
    const response = await Api.get('references/cpv-codes', params);
    return (0, parsers_1.parseCpvCodesForSelect)(response);
};
exports.getCpvCodesForSelect = getCpvCodesForSelect;
const getCpvCodes = async (params) => {
    const response = await Api.get('references/cpv-codes', params);
    return (0, parsers_1.parseCpvCodes)(response);
};
exports.getCpvCodes = getCpvCodes;
