"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const additionalData_1 = require("../helpers/additionalData");
const react_1 = require("react");
const useAdditionalData = () => {
    const [loading, setLoading] = (0, react_1.useState)(false);
    const updateAdditionalData = async (values, documentId) => {
        const params = (0, additionalData_1.transformAdditionalParams)(values);
        setLoading(true);
        const response = await index_1.ProcurementsApi.updateProcurementAdditionalData(documentId, params);
        setLoading(false);
        return response;
    };
    return { loading, updateAdditionalData };
};
exports.default = useAdditionalData;
