"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateStructuralUnitForProject = exports.getStructuralUnitForProject = exports.updateProjectPerformers = exports.deleteProject = exports.getProjects = exports.getUserProjects = exports.detachProjectsFromUser = exports.attacheProjectsToUser = exports.attacheUsersToProject = exports.getAttachedUsers = exports.updateProject = exports.getProject = exports.createProject = exports.getFilteredProjectsByUserForSelect = exports.getProjectsForSelect = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("./parsers");
const parsers_2 = require("api/references/structuralUnitsUpgraded/parsers");
const getProjectsForSelect = async (params, withBudgetSource = true) => {
    const response = await Api.get('references/funding/projects', { ...params, sort: '-end_date' });
    return (0, parsers_1.parseProjectsForSelect)(response, withBudgetSource);
};
exports.getProjectsForSelect = getProjectsForSelect;
const getFilteredProjectsByUserForSelect = async (params, withBudgetSource = true) => {
    const response = await Api.get('references/funding/projects/sorted-projects-by-user', params);
    return (0, parsers_1.parseProjectsForSelect)(response, withBudgetSource);
};
exports.getFilteredProjectsByUserForSelect = getFilteredProjectsByUserForSelect;
const createProject = async (params) => {
    const response = await Api.post('references/funding/projects', params);
    return (0, parsers_1.parseProject)(response);
};
exports.createProject = createProject;
const getProject = async (id) => {
    const response = await Api.get('references/funding/projects/' + id);
    return (0, parsers_1.parseProject)(response);
};
exports.getProject = getProject;
const updateProject = async (id, params) => {
    const response = await Api.patch('references/funding/projects/' + id, params);
    return (0, parsers_1.parseProject)(response);
};
exports.updateProject = updateProject;
const getAttachedUsers = async (id) => {
    const response = await Api.get('references/funding/projects/' + id);
    return (0, parsers_1.parseAttachedUsers)(response);
};
exports.getAttachedUsers = getAttachedUsers;
const attacheUsersToProject = async (id, params) => {
    return await Api.post(`references/funding/projects/${id}/attach-users`, params);
};
exports.attacheUsersToProject = attacheUsersToProject;
const attacheProjectsToUser = async (id, params) => {
    return await Api.post(`references/funding/projects/${id}/attach-projects`, params);
};
exports.attacheProjectsToUser = attacheProjectsToUser;
const detachProjectsFromUser = async (id, params) => {
    return await Api.post(`references/funding/projects/${id}/detach-projects`, params);
};
exports.detachProjectsFromUser = detachProjectsFromUser;
const getUserProjects = async (params) => {
    const response = await Api.get('references/funding/projects/user-projects', params);
    return (0, parsers_1.parseUserProjects)(response);
};
exports.getUserProjects = getUserProjects;
const getProjects = async (params) => {
    const response = await Api.get('references/funding/projects', params);
    return (0, parsers_1.parseProjects)(response);
};
exports.getProjects = getProjects;
const deleteProject = async (id) => {
    return await Api.deleteItem('references/funding/projects/' + id);
};
exports.deleteProject = deleteProject;
const updateProjectPerformers = async (params) => {
    const response = await Api.patch('references/funding/projects/update-users', params);
    return (0, parsers_1.parseProjectsPerformers)(response);
};
exports.updateProjectPerformers = updateProjectPerformers;
const getStructuralUnitForProject = async (params) => {
    const response = await Api.get('references/structural-units', params);
    return (0, parsers_2.parseStructuralUnitForCards)(response);
};
exports.getStructuralUnitForProject = getStructuralUnitForProject;
const updateStructuralUnitForProject = async (projectId, params) => {
    const response = await Api.patch(`references/funding/projects/${projectId}/structural-units`, params);
    return (0, parsers_2.parseStructuralUnitForCards)(response);
};
exports.updateStructuralUnitForProject = updateStructuralUnitForProject;
