"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSubmissionVerifiesReducer = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
// const initialFilterForm: FilterFormValues = {
//   keyword: '',
//   fromSumPrice: null,
//   toSumPrice: null,
//   itemBudgetingStatus: null,
//   itemPurchaseStatus: null,
//   status: null,
//   itemStatus: null,
//   budgetingStatusUpdatedBy: null,
//   budgetArticleId: null,
//   userIdForProjects: null,
//   submissionCreatedBy: null,
// }
const initialSubmissionVerifiesStore = {
    data: [],
    status: null,
    filterFormValue: {},
    meta: store_1.initialMeta,
    errors: null,
    isFetching: true,
    isFetched: false
};
const useSubmissionVerifiesReducer = () => {
    return (0, react_1.useReducer)(SubmissionVerifiesReducer, initialSubmissionVerifiesStore);
};
exports.useSubmissionVerifiesReducer = useSubmissionVerifiesReducer;
const SubmissionVerifiesReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_SUBMISSION_VERIFIES_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_SUBMISSION_VERIFIES_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                status: action.payload.status,
                errors: action.payload.errors,
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'CHANGE_PER_PAGE':
            return {
                ...state,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        perPage: action.payload
                    }
                }
            };
        case 'INITIALIZE_FILTER_FORM':
            return {
                ...state,
                filterFormValue: action.payload,
                isFetched: true
            };
        default:
            return state;
    }
};
