"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSubmissionVerifyItemsV2Reducer = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
const initialSubmissionVerifyItemsV2Store = {
    data: [],
    deleteItemIds: [],
    status: null,
    isFetching: false,
    isApprovingItems: false,
    isLoading: false,
    isDecliningItems: false,
    isSuspendingItems: false,
    isUpdatingItemsList: false,
    meta: store_1.initialMeta,
    errors: null
};
const useSubmissionVerifyItemsV2Reducer = () => {
    return (0, react_1.useReducer)(submissionVerifyItemsV2Reducer, initialSubmissionVerifyItemsV2Store);
};
exports.useSubmissionVerifyItemsV2Reducer = useSubmissionVerifyItemsV2Reducer;
const submissionVerifyItemsV2Reducer = (state, action) => {
    switch (action.type) {
        case 'SEND_SUBMISSION_VERIFY_ITEMS_V2_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_SUBMISSION_VERIFY_ITEMS_V2_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                status: action.payload.status,
                errors: action.payload.errors,
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'DONE_SUBMISSION_VERIFY_ITEM_DELETE_REQUEST': {
            return {
                ...state,
                deleteItemIds: [...state.deleteItemIds, action.payload.deleteItemId],
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        total: state.meta.pagination.total - action.payload.deleteItemLength,
                        count: state.meta.pagination.count - action.payload.deleteItemLength
                    }
                },
                errors: action.payload.errors
            };
        }
        case 'SEND_SUBMISSION_VERIFY_APPROVE_ITEMS':
            return {
                ...state,
                errors: null,
                isApprovingItems: true,
                isLoading: true
            };
        case 'DONE_SUBMISSION_VERIFY_APPROVE_ITEMS':
            return {
                ...state,
                data: action.payload.data ? state.data.map(el => {
                    const findItem = action.payload.data.find(item => item.id === el.id);
                    if (findItem)
                        return findItem;
                    return el;
                }) : state.data,
                status: action.payload.status,
                isApprovingItems: false,
                isLoading: state.isFetching
                    || state.isDecliningItems
                    || state.isSuspendingItems
                    || state.isUpdatingItemsList
            };
        case 'SEND_SUBMISSION_VERIFY_DECLINE_ITEMS':
            return {
                ...state,
                errors: null,
                isDecliningItems: true,
                isLoading: true
            };
        case 'DONE_SUBMISSION_VERIFY_DECLINE_ITEMS':
            return {
                ...state,
                data: action.payload.data ? state.data.map(el => {
                    const findItem = action.payload.data.find(item => item.id === el.id);
                    if (findItem)
                        return findItem;
                    return el;
                }) : state.data,
                status: action.payload.status,
                isDecliningItems: false,
                isLoading: state.isApprovingItems
                    || state.isFetching
                    || state.isSuspendingItems
                    || state.isUpdatingItemsList
            };
        case 'SEND_SUBMISSION_VERIFY_SUSPEND_ITEMS':
            return {
                ...state,
                errors: null,
                isSuspendingItems: true,
                isLoading: true
            };
        case 'DONE_SUBMISSION_VERIFY_SUSPEND_ITEMS':
            return {
                ...state,
                data: action.payload.data ? state.data.map(el => {
                    const findItem = action.payload.data.find(item => item.id === el.id);
                    if (findItem)
                        return findItem;
                    return el;
                }) : state.data,
                status: action.payload.status,
                isSuspendingItems: false,
                isLoading: state.isApprovingItems
                    || state.isDecliningItems
                    || state.isFetching
                    || state.isUpdatingItemsList
            };
        case 'SEND_ITEMS_LIST_UPDATE_REQUEST':
            return {
                ...state,
                errors: null,
                isUpdatingItemsList: true,
                isLoading: true
            };
        case 'DONE_ITEMS_LIST_UPDATE_REQUEST':
            return {
                ...state,
                data: action.payload.submissionVerifyItemsV2.data !== null
                    ? margeStateAndUpdatedData(state.data, action.payload.submissionVerifyItemsV2.data, state.deleteItemIds)
                    : state.data,
                deleteItemIds: [],
                errors: action.payload.submissionVerifyItemsV2.errors,
                isUpdatingItemsList: false,
                isLoading: state.isDecliningItems
                    || state.isApprovingItems
                    || state.isFetching
                    || state.isSuspendingItems,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        total: state.meta.pagination.total + action.payload.newItemsLength,
                        count: state.meta.pagination.count + action.payload.newItemsLength
                    }
                }
            };
        case 'CHANGE_PER_PAGE':
            return {
                ...state,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        perPage: action.payload
                    }
                }
            };
        case 'UPDATE_STATE_ITEMS':
            return {
                ...state,
                data: action.payload
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
const margeStateAndUpdatedData = (stateData, actionData, deleteItemIds) => {
    return [
        ...actionData.filter(el => !stateData.some(item => item.id === el.id)),
        ...stateData.filter(el => !deleteItemIds.some(itemId => itemId === el.id))
            .map(stateItem => {
            const foundItem = actionData.find(payloadData => payloadData.id === stateItem.id);
            return foundItem || stateItem;
        })
    ];
};
