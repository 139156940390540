"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HrAssignStatusBy = exports.HRAssignSubmissionVerifyStatuses = void 0;
var HRAssignSubmissionVerifyStatuses;
(function (HRAssignSubmissionVerifyStatuses) {
    HRAssignSubmissionVerifyStatuses[HRAssignSubmissionVerifyStatuses["DRAFT"] = 1] = "DRAFT";
    HRAssignSubmissionVerifyStatuses[HRAssignSubmissionVerifyStatuses["PARTIALLY_CONFIRMED"] = 2] = "PARTIALLY_CONFIRMED";
    HRAssignSubmissionVerifyStatuses[HRAssignSubmissionVerifyStatuses["CONFIRMED"] = 3] = "CONFIRMED";
    HRAssignSubmissionVerifyStatuses[HRAssignSubmissionVerifyStatuses["DECLINED"] = 4] = "DECLINED";
    HRAssignSubmissionVerifyStatuses[HRAssignSubmissionVerifyStatuses["SUSPENDED"] = 5] = "SUSPENDED";
})(HRAssignSubmissionVerifyStatuses || (exports.HRAssignSubmissionVerifyStatuses = HRAssignSubmissionVerifyStatuses = {}));
var HrAssignStatusBy;
(function (HrAssignStatusBy) {
    HrAssignStatusBy[HrAssignStatusBy["CREATOR"] = 1] = "CREATOR";
    HrAssignStatusBy[HrAssignStatusBy["BUDGETING"] = 2] = "BUDGETING";
    HrAssignStatusBy[HrAssignStatusBy["HEAD_OF_HR"] = 3] = "HEAD_OF_HR";
})(HrAssignStatusBy || (exports.HrAssignStatusBy = HrAssignStatusBy = {}));
