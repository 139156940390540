"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.approvalStatusStyle = exports.procurementType = void 0;
const types_1 = require("api/documents/purchases/procurements/types");
exports.procurementType = {
    1: 'ელექტრონული',
    2: 'გამარტივებული',
    3: 'კონსოლიდირებული'
};
const approvalStatusStyle = (status, deleted = false) => {
    switch (status) {
        case types_1.ApproverStatus.APPROVED:
            return ' fa-user-check text-success';
        case types_1.ApproverStatus.DECLINED:
            return ' fa-user-times text-danger';
        case types_1.ApproverStatus.PENDING:
            return ' fa-user-clock ' + (deleted ? 'text-muted' : 'text-dark-65');
        case types_1.ApproverStatus.SUSPENDED:
            return ' fa-user-lock text-warning';
        default:
            return ' fa-user-clock ' + (deleted ? 'text-muted' : 'text-dark-65');
    }
};
exports.approvalStatusStyle = approvalStatusStyle;
