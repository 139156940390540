"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const LegalBasisList = (props) => {
    (0, react_1.useEffect)(() => {
        document.body.addEventListener('click', props.resetAutocompleteData);
        return () => document.body.removeEventListener('click', props.resetAutocompleteData);
    }, []);
    return ((0, jsx_runtime_1.jsx)("div", { className: 'name-For-position', id: 'autocomplete-legal_listing', children: (0, jsx_runtime_1.jsx)("div", { className: 'p-3 mt-2 card-scroll list', style: { height: 210 }, children: (0, jsx_runtime_1.jsx)("ul", { className: 'list-hover pointer', children: props.data.map((el, index) => {
                    return ((0, jsx_runtime_1.jsx)("li", { className: 'p-1 item-hover', onClick: () => {
                            props.setValue({ id: el.id, label: el.label });
                            props.resetAutocompleteData();
                        }, dangerouslySetInnerHTML: { __html: el.label } }, index));
                }) }) }) }));
};
exports.default = LegalBasisList;
