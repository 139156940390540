"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useListActions = () => {
    const [showActionMenu, setShowActionMenu] = (0, react_1.useState)(false);
    const handleShowActionMenu = (_e, show = false) => {
        setShowActionMenu(show);
        if (show) {
            window.addEventListener('click', handleShowActionMenu, true);
        }
        else {
            window.removeEventListener('click', handleShowActionMenu, true);
        }
    };
    return { showActionMenu, handleShowActionMenu };
};
exports.default = useListActions;
