"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_pdf_1 = require("react-pdf");
const react_1 = require("react");
const PdfInfinite = (props) => {
    const [numPages, setNumPages] = (0, react_1.useState)(null);
    const onDocumentLoadSuccess = ({ numPages }) => { setNumPages(numPages); };
    return ((0, jsx_runtime_1.jsx)(react_pdf_1.Document, { file: props.pdfFileUrl, onLoadSuccess: onDocumentLoadSuccess, children: Array.from(new Array(numPages), (_, index) => ((0, jsx_runtime_1.jsx)(react_pdf_1.Page, { scale: props.scale || 1.5, pageNumber: index + 1 }, `page_${index + 1}`))) }));
};
exports.default = PdfInfinite;
