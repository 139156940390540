"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useCalculateCpvCodes = (props) => {
    const [groupedItems, setGroupedItems] = (0, react_1.useState)([]);
    const [summery, setSummery] = (0, react_1.useState)(0);
    const [error, setError] = (0, react_1.useState)('');
    const Calculate = () => {
        const newArray = props.items.map(el => {
            const rate = !el.currencyExchangeRate ? 1 : el.currencyExchangeRate;
            if (!el.quantity || !el.price) {
                setError('გთხოვთ შეავსოთ გამოტოვებული ველები. აუცილებელია ველებია: ოდენობა, ერთეულის ფასი, ვალუტა და გაცვლითი კურსი');
            }
            return {
                id: el.cpvCode?.id || 'missing',
                label: el.cpvCode?.label || 'გამოტოვებული',
                sumPrice: !el.quantity || !el.price ? 0 : el.quantity * rate * el.price / (el.currency?.quantity ? el.currency.quantity : 1),
                count: 1
            };
        });
        let sum = 0;
        const groupedCpvCodes = newArray.reduce((previousValue, currentValue) => {
            const item = previousValue.find(el => el.id === currentValue.id);
            if (item) {
                const newItem = {
                    ...item,
                    count: item.count + 1,
                    sumPrice: item.sumPrice + currentValue.sumPrice
                };
                const index = previousValue.findIndex(el => el.id === currentValue.id);
                previousValue[index] = newItem;
            }
            else {
                previousValue.push(currentValue);
            }
            sum = sum + (currentValue.id !== 'missing' ? currentValue.sumPrice : 0);
            return previousValue;
        }, []);
        setSummery(sum);
        setGroupedItems(groupedCpvCodes);
    };
    (0, react_1.useEffect)(() => { Calculate(); }, []);
    return { groupedItems, summery, error };
};
exports.default = useCalculateCpvCodes;
