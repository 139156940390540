"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFakeSubmission = void 0;
const types_1 = require("api/documents/purchases/submissions/types");
const index_1 = require("api/index");
const types_2 = require("api/documents/purchases/procurements/types");
const helper_1 = require("../../../common/helper");
const helpers_1 = require("core/helpers");
const useFakeConditionalSubmission_1 = require("./useFakeConditionalSubmission");
const react_1 = require("react");
const context_1 = require("src/context/context");
const useFakeSubmission = (props) => {
    const [isCreating, setIsCreating] = (0, react_1.useState)(false);
    const [isChanging, setIsChanging] = (0, react_1.useState)(false);
    const [isCreatingForCorrection, setIsCreatingForCorrection] = (0, react_1.useState)(false);
    const hook = (0, useFakeConditionalSubmission_1.useFakeConditionalSubmission)({ uid: props.uid });
    const { toast } = (0, context_1.useToastContext)();
    const createFakeSubmission = async (loading = true) => {
        if (loading)
            setIsCreating(true);
        const submission = await hook.createSubmission();
        if (submission !== null) {
            toast.success('წარდგინება შეიქმნა წარმატებით');
            if (loading)
                setIsCreating(false);
            return submission;
        }
        else {
            toast.danger('წარდგინება ვერ შეიქმნა');
            if (loading)
                setIsCreating(false);
            return null;
        }
    };
    const createSubmissionForCorrection = async (loading = true) => {
        if (loading)
            setIsCreatingForCorrection(true);
        const submission = await createFakeSubmission(false);
        if (submission) {
            const getSubmission = await index_1.SubmissionsApi.getSubmission(submission);
            if (getSubmission.data) {
                await index_1.SubmissionsApi.updateSubmission(submission, {
                    accountId: getSubmission.data.structuralUnit.accountId,
                    fileUids: getSubmission.data.files.map(el => el.id),
                    executionDate: (0, helper_1.transformParamsDate)((0, helper_1.dateToSubtract)(null, null, 20)),
                    number: getSubmission.data.number,
                    date: (0, helper_1.transformParamsDate)((getSubmission.data.date)),
                    expirationDate: (0, helper_1.transformParamsDate)(getSubmission.data.expirationDate)
                });
                const items = await index_1.SubmissionsApi.getSubmissionItemsForm(submission, {});
                const updatedItems = items.data.map(el => {
                    return {
                        id: el.id,
                        dimensionUnitId: el.dimensionUnit?.id,
                        cpvCodeId: String((0, helpers_1.getRandomIntBetween)(10, 1)),
                        projectId: el.project?.id,
                        currencyId: el.currency?.id || '1',
                        quantity: el.quantity || (0, helpers_1.getRandomIntBetween)(20, 1),
                        price: (0, helpers_1.getRandomIntBetween)(20, 1),
                        templateType: types_2.TemplateTypes.ELECTRONIC,
                        currencyExchangeRate: el.currencyExchangeRate,
                        characteristics: el.characteristics,
                        assignment: el.assignment,
                        budgetArticleId: String((0, helpers_1.getRandomIntBetween)(20, 10)),
                        conditionalSubmissionItemId: el.conditionalSubmissionItemId,
                        inventoryConfigurationId: el.inventoryConfigurationId,
                        bookLink: 'test',
                        bookAuthor: 'test',
                        bookIsbnCode: 'test',
                        bookTitle: 'test'
                    };
                });
                const itemsUpdatedVersion = await index_1.SubmissionsApi.updateSubmissionItem(submission, { items: updatedItems });
                if (itemsUpdatedVersion.data) {
                    await index_1.SubmissionsApi.sendStatusToCorrection(getSubmission.data.id);
                    if (loading)
                        setIsCreatingForCorrection(false);
                    toast.success('წარმატებით გაიგზავნა კორექციისთვის');
                }
                else {
                    if (loading)
                        setIsCreatingForCorrection(false);
                    toast.danger('something went wrong');
                }
            }
            else {
                if (loading)
                    setIsCreatingForCorrection(false);
                toast.danger('something went wrong');
            }
        }
        else {
            if (loading)
                setIsCreatingForCorrection(false);
            toast.danger('something went wrong');
        }
    };
    const changeFakeStatus = async (loading = true) => {
        if (loading)
            setIsChanging(true);
        const submission = await createFakeSubmission(false);
        if (submission === null) {
            if (loading)
                setIsChanging(false);
            return null;
        }
        if (submission) {
            const getSubmission = await index_1.SubmissionsApi.getSubmission(submission);
            if (!getSubmission.data)
                return null;
            await index_1.SubmissionsApi.updateSubmission(submission, {
                accountId: getSubmission.data.structuralUnit.accountId,
                fileUids: getSubmission.data.files.map(el => el.id),
                executionDate: (0, helper_1.transformParamsDate)((0, helper_1.dateToSubtract)(null, null, 20)),
                number: getSubmission.data.number,
                date: (0, helper_1.transformParamsDate)((getSubmission.data.date)),
                expirationDate: (0, helper_1.transformParamsDate)(getSubmission.data.expirationDate)
            });
            const items = await index_1.SubmissionsApi.getSubmissionItemsForm(submission, {});
            const updatedItems = items.data.map(el => {
                return {
                    id: el.id,
                    dimensionUnitId: el.dimensionUnit?.id,
                    cpvCodeId: String((0, helpers_1.getRandomIntBetween)(10, 1)),
                    projectId: el.project?.id,
                    currencyId: el.currency?.id || '1',
                    quantity: el.quantity || (0, helpers_1.getRandomIntBetween)(20, 1),
                    price: (0, helpers_1.getRandomIntBetween)(20, 1),
                    templateType: types_2.TemplateTypes.ELECTRONIC,
                    currencyExchangeRate: el.currencyExchangeRate,
                    characteristics: el.characteristics,
                    assignment: el.assignment,
                    budgetArticleId: String((0, helpers_1.getRandomIntBetween)(20, 10)),
                    conditionalSubmissionItemId: el.conditionalSubmissionItemId,
                    inventoryConfigurationId: el.inventoryConfigurationId,
                    bookLink: 'test',
                    bookAuthor: 'test',
                    bookIsbnCode: 'test',
                    bookTitle: 'test'
                };
            });
            await index_1.SubmissionsApi.updateSubmissionItem(submission, { items: updatedItems });
            const changeSubmissionStatus = await index_1.SubmissionsApi.changeStatus(submission, { status: types_1.SubmissionsStatuses.CHECKING_PROCESS });
            if (loading)
                setIsChanging(false);
            if (changeSubmissionStatus.data !== null) {
                toast.success('წარდგინება წარმატებით გაიგზავნა');
                return changeSubmissionStatus.data.number;
            }
            else {
                toast.danger('წარდგინება ვერ გაიგზავნა');
            }
        }
        else {
            toast.danger('წარდგინების ID არ მოიძებნა');
        }
        return null;
    };
    return {
        createFakeSubmission,
        changeFakeStatus,
        isCreating,
        isChanging,
        isCreatingForCorrection,
        createSubmissionForCorrection
    };
};
exports.useFakeSubmission = useFakeSubmission;
