"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useConditionalSubmissions = void 0;
const index_1 = require("api/index");
const conditionalSubmissions_1 = require("../store/conditionalSubmissions");
const react_1 = require("react");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const purchase_1 = require("../../../../common/helper/purchase");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const queryString_1 = require("core/helpers/queryString");
const helpers_1 = require("../helpers");
const helper_1 = require("../../../../common/helper");
const react_router_dom_1 = require("react-router-dom");
const useConditionalSubmissions = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, conditionalSubmissions_1.useConditionalSubmissionsReducer)();
    const [showItemStatus, setShowItemStatus] = (0, react_1.useState)(false);
    const [toDeleteId, setToDeleteId] = (0, react_1.useState)(null);
    const [toCopyId, setToCopyId] = (0, react_1.useState)(null);
    const [showDeleteAlert, setShowDeleteAlert] = (0, react_1.useState)(false);
    const [showCopyAlert, setShowCopyAlert] = (0, react_1.useState)(false);
    const [showFilter, setShowFilter] = (0, react_1.useState)(false);
    const [linkParams, setLinkParams] = (0, react_1.useState)('');
    const [conditionalSubmissionId, setConditionalSubmissionId] = (0, react_1.useState)(null);
    const isMounted = (0, react_1.useRef)(false);
    const navigatePagination = (page, filterValues) => {
        getConditionalSubmissions({ ...filterValues, page }).then();
    };
    const selectPerPage = (perPage, filterValues) => {
        getConditionalSubmissions({ ...filterValues, perPage, page: 1 }).then();
    };
    const copyConditionalSubmission = async (id) => {
        dispatch({ type: 'SEND_COPY_REQUEST' });
        const conditionalSubmission = await index_1.ConditionalSubmissionsApi.copyConditionalSubmission(id);
        dispatch({ type: 'DONE_COPY_REQUEST', payload: { errors: conditionalSubmission.errors } });
        setToCopyId(null);
        if (conditionalSubmission.status === apiGlobalTypes_1.ResponseStatuses.CREATED) {
            navigate(`/documents/purchases/conditional-submissions/${conditionalSubmission.data?.id}/edit`, { replace: true });
        }
        return conditionalSubmission;
    };
    const filter = (name, value, filterValues) => {
        getConditionalSubmissions({ ...filterValues, [name]: value, page: 1 }).then();
    };
    const debouncedSearch = (name, value, filterValues) => {
        (0, debouncedMethods_1.debouncedOnFunc)({ ...filterValues, [name]: value, page: 1 }, getConditionalSubmissions);
    };
    const checkStatus = async (submissionId, itemId) => {
        dispatch({ type: 'SEND_CONDITIONAL_SUBMISSIONS_ITEMS_STATUS_REQUEST', payload: itemId[0] });
        const itemStatuses = await index_1.ConditionalSubmissionsApi.checkItemStatus(submissionId, { filters: { ids: itemId } });
        dispatch({ type: 'DONE_CONDITIONAL_SUBMISSIONS_ITEMS_STATUS_REQUEST', payload: itemStatuses });
        setShowItemStatus(true);
    };
    const deleteAlert = (id) => {
        setShowDeleteAlert(true);
        setToDeleteId(id);
    };
    const copyAlert = (id) => {
        setShowCopyAlert(true);
        setToCopyId(id);
    };
    const deleteConditionalSubmission = async (confirm, formValue) => {
        setShowDeleteAlert(false);
        if (!toDeleteId || !confirm)
            return setToDeleteId(null);
        dispatch({ type: 'SEND_DELETE_REQUEST' });
        const conditionalSubmissions = await index_1.ConditionalSubmissionsApi.deleteConditionalSubmission(toDeleteId);
        dispatch({ type: 'DONE_DELETE_REQUEST', payload: { errors: conditionalSubmissions.errors } });
        if (conditionalSubmissions.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            getConditionalSubmissions(formValue).then();
        setToDeleteId(null);
    };
    const getConditionalSubmissions = async (values) => {
        dispatch({ type: 'SEND_CONDITIONAL_SUBMISSION_REQUEST' });
        const filterParams = (0, helpers_1.transformParamsToFilterParams)(values);
        const conditionalSubmissions = await index_1.ConditionalSubmissionsApi.getConditionalSubmissions(filterParams);
        if (conditionalSubmissions.data !== null)
            setLinkParams((0, queryString_1.stringify)((0, helpers_1.transformFilterParamsToUrl)(values)));
        if (isMounted?.current) {
            dispatch({ type: 'DONE_CONDITIONAL_SUBMISSION_REQUEST', payload: conditionalSubmissions });
            navigate('/documents/purchases/conditional-submissions' + (0, queryString_1.stringify)((0, helpers_1.transformFilterParamsToUrl)(values)), { replace: true });
        }
    };
    const initializeFilterForm = async () => {
        const params = (0, helpers_1.transformUrlToFilterParams)(location.search);
        const paginatedStorage = JSON.parse(localStorage.getItem('paginated') || '""');
        const storagePerPage = paginatedStorage?.conditionalSubmissionListing?.perPage;
        params.perPage = params.perPage || Number(storagePerPage) || 25;
        const conditionalSubmissionOnlyMine = localStorage.getItem('conditionalSubmissionOnlyMine') || undefined;
        if (params.structuralUnit || params.employee || params.status || params.keyword || params.createdBy || params.purchaseSubject)
            setShowFilter(true);
        params.onlyMine = conditionalSubmissionOnlyMine;
        const conditionalId = (0, queryString_1.parse)(location.search).conditionalSubmissionId;
        getConditionalSubmissions(params).then(() => dispatch({ type: 'INITIALIZE_FILTER_FORM', payload: params }));
        setConditionalSubmissionId(conditionalId);
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        initializeFilterForm().then();
        return () => { isMounted.current = false; };
    }, []);
    const conditionalSubmissionStatusOptions = (0, react_1.useMemo)(() => (0, helper_1.transformObjectForSelect)(purchase_1.conditionalSubmissionStatus), []);
    return {
        state,
        navigatePagination,
        selectPerPage,
        deleteConditionalSubmission,
        deleteAlert,
        filter,
        debouncedSearch,
        setShowFilter,
        showFilter,
        conditionalSubmissionStatusOptions,
        toDeleteId,
        showDeleteAlert,
        getConditionalSubmissions,
        setShowItemStatus,
        showItemStatus,
        checkStatus,
        copyConditionalSubmission,
        toCopyId,
        copyAlert,
        showCopyAlert,
        linkParams,
        conditionalSubmissionId,
        setShowCopyAlert,
        setToCopyId
    };
};
exports.useConditionalSubmissions = useConditionalSubmissions;
