"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateProcurementType = exports.createProcurementType = exports.deleteProcurementType = exports.getProcurementTypes = exports.getProcurementType = exports.getProcurementTypesForSelect = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("./parsers");
const getProcurementTypesForSelect = async (params = {}) => {
    const response = await Api.get('references/procurement-types', params);
    return (0, parsers_1.parseProcurementTypesForSelect)(response);
};
exports.getProcurementTypesForSelect = getProcurementTypesForSelect;
const getProcurementType = async (id) => {
    const response = await Api.get('references/procurement-types/' + id);
    return (0, parsers_1.parseProcurementTypeData)(response);
};
exports.getProcurementType = getProcurementType;
const getProcurementTypes = async (params) => {
    const response = await Api.get('references/procurement-types', params);
    return (0, parsers_1.parseProcurementTypes)(response);
};
exports.getProcurementTypes = getProcurementTypes;
const deleteProcurementType = async (id) => {
    return await Api.deleteItem('references/procurement-types/' + id);
};
exports.deleteProcurementType = deleteProcurementType;
const createProcurementType = async (params) => {
    const response = await Api.post('references/procurement-types/', params);
    return (0, parsers_1.parseProcurementTypeData)(response);
};
exports.createProcurementType = createProcurementType;
const updateProcurementType = async (id, params) => {
    const response = await Api.patch('references/procurement-types/' + id, params);
    return (0, parsers_1.parseProcurementTypeData)(response);
};
exports.updateProcurementType = updateProcurementType;
