"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformInventoryItemFilterParamsToUrl = exports.transformInventoryItemParamsToFilterParams = exports.transformUrlToInventoryItemFilterParams = exports.transformInventoryItemParams = void 0;
const queryString_1 = require("core/helpers/queryString");
const transformInventoryItemParams = (params) => {
    return { name: params.name };
};
exports.transformInventoryItemParams = transformInventoryItemParams;
// export const getParamsFromUrl = (url: string): InventoryItemsParams => {
//   try {
//     const params = parse(url) as InventoryItemsParams
//     return {
//       page: params.page,
//       perPage: params.perPage,
//       filters: params.filters?.keyword ? { keyword: params.filters.keyword } : undefined
//     }
//   } catch (err) {
//     console.error({ err })
//     return {}
//   }
// }
const transformUrlToInventoryItemFilterParams = (url) => {
    const values = (0, queryString_1.parse)(url);
    return {
        keyword: values.keyword,
        page: values.page,
        perPage: values.perPage
    };
};
exports.transformUrlToInventoryItemFilterParams = transformUrlToInventoryItemFilterParams;
const transformInventoryItemParamsToFilterParams = (params) => {
    const filters = {};
    const paginate = {};
    const filterParams = {
        keyword: params.keyword,
        page: params.page,
        perPage: params.perPage
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if (value && (key !== 'page' && key !== 'perPage'))
                filters[key] = value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(filters).length === 0)
            return { ...paginate };
        return { ...paginate, filters };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformInventoryItemParamsToFilterParams = transformInventoryItemParamsToFilterParams;
const transformInventoryItemFilterParamsToUrl = (values) => {
    const { page, perPage, keyword } = values;
    return {
        page,
        perPage,
        keyword
    };
};
exports.transformInventoryItemFilterParamsToUrl = transformInventoryItemFilterParamsToUrl;
