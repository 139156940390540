"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAssignSubmissionVerifyItemsForm = void 0;
const index_1 = require("api/index");
const react_1 = require("react");
const useHRSubmissionVerifyItems_1 = require("../store/useHRSubmissionVerifyItems");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const types_1 = require("api/references/structuralUnitsUpgraded/types");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helpers_1 = require("../helpers");
const queryString_1 = require("core/helpers/queryString");
const react_router_dom_1 = require("react-router-dom");
const useAssignSubmissionVerifyItemsForm = (props) => {
    const [state, dispatch] = (0, useHRSubmissionVerifyItems_1.useAssignSubmissionVerifyItemsReducer)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [keyword, setKeyword] = (0, react_1.useState)('');
    const [cascadedItems, setCascadedItems] = (0, react_1.useState)([]);
    const [currencies, setCurrencies] = (0, react_1.useState)([]);
    const [elRefs, setElRefs] = (0, react_1.useState)([]);
    const [taxOptions, setTaxOptions] = (0, react_1.useState)([]);
    const [cardIsOpen, setCardIsOpen] = (0, react_1.useState)(false);
    const [showStatusMessage, setShowStatusMessage] = (0, react_1.useState)(null);
    const [selectedItemsNames, setSelectedItems] = (0, react_1.useState)([]);
    const [selectedProjectIds, setSelectedProjectIds] = (0, react_1.useState)([]);
    const [isSticky, setSticky] = (0, react_1.useState)(false);
    const [width, setWidth] = (0, react_1.useState)(undefined);
    const [selectedAllItems, setSelectedAllItems] = (0, react_1.useState)(false);
    const [commentModalOpen, setCommentModalOpen] = (0, react_1.useState)(null);
    const [selectedAllProjects, setSelectedAllProjects] = (0, react_1.useState)(false);
    const [showItemsList, setShowItemsList] = (0, react_1.useState)(false);
    const ref = (0, react_1.useRef)(null);
    const getItems = async (params) => {
        const itemsParams = (0, helpers_1.transformAssignSubmissionVerifyItemsParams)(params);
        const verifyParams = (0, helpers_1.transformAssignSubmissionVerifyFilterParamsToUrl)((0, helpers_1.transformUrlToAssignSubmissionVerifyFilterParams)(location.search));
        dispatch({ type: 'SEND_HR_SUBMISSION_VERIFY_ITEMS_REQUEST' });
        const submissionVerifyItemData = await index_1.HumanResourcesSubmissionVerifiesApi.getHRAssignSubmissionVerifyFormItems(props.id, itemsParams);
        const searchParams = (0, queryString_1.stringify)({ ...params, ...verifyParams });
        navigate(searchParams || location.pathname, { replace: true });
        dispatch({ type: 'DONE_HR_SUBMISSION_VERIFY_ITEMS_REQUEST', payload: submissionVerifyItemData });
    };
    const getTaxOptions = async () => {
        if (taxOptions.length > 1)
            return taxOptions;
        const response = await index_1.EmployeesApi.getTaxOptions();
        if (response.data !== null) {
            setTaxOptions(response.data);
            return response.data;
        }
        return [];
    };
    const debouncedSearch = (value) => {
        setKeyword(value);
        const searchParams = (0, queryString_1.parse)(location.search);
        const params = value
            ? { perPage: searchParams.perPage, filters: { keyword: value } }
            : { page: searchParams.page };
        (0, debouncedMethods_1.debouncedOnFunc)(params, getItems);
    };
    const updateAssignSubmissionVerifyItemsList = async (id, values) => {
        const params = (0, helpers_1.transformAssignSubmissionVerifyItemsListParams)(values);
        const newItemsLength = values.filter(item => item.id === null).length;
        dispatch({ type: 'SEND_ITEMS_LIST_UPDATE_REQUEST' });
        const assignSubmissionVerifyItems = await index_1.HumanResourcesSubmissionVerifiesApi.updateHRAssignSubmissionVerifyItems(id, params);
        dispatch({ type: 'DONE_ITEMS_LIST_UPDATE_REQUEST', payload: { assignSubmissionVerifyItems, newItemsLength } });
        return assignSubmissionVerifyItems;
    };
    const updateAssignSubmissionVerifyItem = async (id, value, index) => {
        const params = (0, helpers_1.transformAssignSubmissionVerifyItemsListParams)([value]);
        dispatch({ type: 'SEND_ITEM_UPDATE_REQUEST' });
        const assignSubmissionVerifyItems = await index_1.HumanResourcesSubmissionVerifiesApi.updateHRAssignSubmissionVerifyItems(id, params);
        const newAssignSubmissionVerifyItem = {
            data: assignSubmissionVerifyItems.data ? assignSubmissionVerifyItems.data[0] : null,
            status: assignSubmissionVerifyItems.status,
            errors: assignSubmissionVerifyItems.errors
        };
        dispatch({ type: `DONE_ITEM_${value.id ? 'UPDATE' : 'CREATE'}_REQUEST`, payload: { item: newAssignSubmissionVerifyItem.data, index } });
        return newAssignSubmissionVerifyItem;
    };
    const handleCopyAssignSubmissionVerifyItem = (items, item, itemIndex) => {
        const newArray = [...items];
        newArray.splice(itemIndex + 1, 0, {
            ...item,
            id: null,
            assignSubmissionItemId: null,
            positionInfos: item.positionInfos.map(position => ({
                ...position,
                id: null,
                projectInfos: position.projectInfos.map(el => ({
                    ...el,
                    id: null,
                    workInfos: el.workInfos.map(workInfo => ({
                        ...workInfo,
                        id: null,
                        remunerationWorks: workInfo.remunerationWorks.map(work => ({ ...work, id: null })),
                        fixedReservations: workInfo.fixedReservations.map(reservation => ({ ...reservation, id: null })),
                        supplements: workInfo.supplements.map(supplement => ({ ...supplement, id: null })),
                        unitFees: workInfo.unitFees.map(unitFee => ({ ...unitFee, id: null }))
                    }))
                }))
            }))
        });
        return newArray;
    };
    const navigatePagination = (itemPage) => {
        const params = (0, queryString_1.parse)(location.search);
        getItems({ ...params, itemPage }).then();
    };
    const handleCascadeItem = (id) => {
        cascadedItems.includes(id)
            ? setCascadedItems(cascadedItems.filter(el => el !== id))
            : setCascadedItems([...cascadedItems, id]);
    };
    const getCurrenciesForSelect = async (params) => {
        const data = await index_1.CurrenciesApi.getCurrencyForSelect(params);
        setCurrencies(data);
    };
    const selectPerPage = (itemPerPage) => {
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'CHANGE_PER_PAGE', payload: itemPerPage });
        getItems({ ...params, itemPerPage, itemPage: undefined }).then();
    };
    const updateStateItems = (items) => {
        dispatch({ type: 'UPDATE_STATE_ITEMS', payload: items });
    };
    const declineItems = async (declineComment) => {
        setShowStatusMessage(null);
        dispatch({ type: 'SEND_HR_SUBMISSION_VERIFY_DECLINE_ITEMS' });
        const assignSubmissionVerifyItems = await index_1.HumanResourcesSubmissionVerifiesApi.declineItems({ ids: selectedProjectIds, declineComment });
        dispatch({ type: 'DONE_HR_SUBMISSION_VERIFY_DECLINE_ITEMS', payload: { projectData: assignSubmissionVerifyItems } });
        if (assignSubmissionVerifyItems.data !== null) {
            props.getSubmissionVerify(props.id);
            setSelectedProjectIds([]);
            if (selectedAllProjects)
                setSelectedAllProjects(false);
        }
        return assignSubmissionVerifyItems;
    };
    const suspendItems = async (suspendComment) => {
        setShowStatusMessage(null);
        dispatch({ type: 'SEND_HR_SUBMISSION_VERIFY_SUSPEND_ITEMS' });
        const assignSubmissionVerifyItems = await index_1.HumanResourcesSubmissionVerifiesApi.suspendItems({ ids: selectedProjectIds, suspendComment });
        dispatch({ type: 'DONE_HR_SUBMISSION_VERIFY_SUSPEND_ITEMS', payload: { projectData: assignSubmissionVerifyItems } });
        if (assignSubmissionVerifyItems.data !== null) {
            props.getSubmissionVerify(props.id);
            setSelectedProjectIds([]);
            if (selectedAllProjects)
                setSelectedAllProjects(false);
        }
        return assignSubmissionVerifyItems;
    };
    const confirmItems = async () => {
        dispatch({ type: 'SEND_HR_SUBMISSION_VERIFY_CONFIRM_ITEMS' });
        const assignSubmissionVerifyItems = await index_1.HumanResourcesSubmissionVerifiesApi.confirmItems({ ids: selectedProjectIds });
        dispatch({ type: 'DONE_HR_SUBMISSION_VERIFY_CONFIRM_ITEMS', payload: { projectData: assignSubmissionVerifyItems } });
        if (assignSubmissionVerifyItems.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS) {
            props.getSubmissionVerify(props.id);
            setSelectedProjectIds([]);
            if (selectedAllProjects)
                setSelectedAllProjects(false);
        }
        return assignSubmissionVerifyItems;
    };
    const verifyAtPaymentStageItems = async () => {
        dispatch({ type: 'SEND_HR_SUBMISSION_VERIFY_AT_PAYMENT_STAGE_ITEMS' });
        const assignSubmissionVerifyItems = await index_1.HumanResourcesSubmissionVerifiesApi.verifyAtPaymentStage({ ids: selectedProjectIds });
        dispatch({ type: 'DONE_HR_SUBMISSION_VERIFY_AT_PAYMENT_STAGE_ITEMS', payload: { projectData: assignSubmissionVerifyItems } });
        if (assignSubmissionVerifyItems.data !== null) {
            props.getSubmissionVerify(props.id);
            setSelectedProjectIds([]);
            if (selectedAllProjects)
                setSelectedAllProjects(false);
        }
        return assignSubmissionVerifyItems;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    const selectItem = (checked, itemName) => {
        if (checked) {
            setSelectedItems([...selectedItemsNames, itemName]);
        }
        else {
            setSelectedItems(selectedItemsNames.filter(el => el !== itemName));
            setSelectedAllItems(false);
        }
    };
    const selectAllItems = (checked, employees) => {
        setSelectedAllItems(checked);
        if (checked) {
            const allNames = [];
            employees.forEach((el, employeeIndex) => {
                el.positionInfos.forEach((info, positionInfoIndex) => {
                    if (info.structuralUnit && info.structuralUnit.type === types_1.StructuralUnitType.STRUCTURAL_UNIT) {
                        allNames.push(`employees[${employeeIndex}].positionInfos[${positionInfoIndex}].positionType`);
                    }
                });
            });
            setSelectedItems(allNames);
        }
        else {
            setSelectedItems([]);
        }
    };
    const selectProjectIds = (checked, projectId) => {
        if (checked) {
            setSelectedProjectIds([...selectedProjectIds, projectId]);
        }
        else {
            setSelectedProjectIds(selectedProjectIds.filter(el => el !== projectId));
            setSelectedAllProjects(false);
        }
    };
    const selectAllProjects = (checked, employees) => {
        setSelectedAllProjects(checked);
        if (checked) {
            const allIds = [];
            employees.forEach(employee => {
                employee.positionInfos.forEach(info => {
                    info.projectInfos.map(project => {
                        project.id && allIds.push(project.id);
                    });
                });
            });
            setSelectedProjectIds(allIds);
        }
        else {
            setSelectedProjectIds([]);
        }
    };
    const handleScroll = () => {
        if (ref.current) {
            setWidth(ref.current.clientWidth);
            setSticky(ref.current.getBoundingClientRect().top <= 122);
        }
    };
    (0, react_1.useEffect)(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', () => handleScroll);
        };
    }, []);
    (0, react_1.useEffect)(() => {
        const params = (0, helpers_1.getAssignSubmissionVerifyItemsParamsFromUrl)(location.search);
        const paginatedStorage = JSON.parse(localStorage.getItem('paginated') || '""');
        const storagePerPage = paginatedStorage?.assignSubmissionVerifyItems?.perPage;
        params.itemPerPage = params.itemPerPage || Number(storagePerPage) || 50;
        setKeyword(params.itemKeyword || '');
        getItems(params).then();
    }, [props.id]);
    (0, react_1.useEffect)(() => {
        getCurrenciesForSelect({ perPage: 300, sort: '+sort,-id' }).then();
    }, []);
    return {
        state,
        currencies,
        cascadedItems,
        handleCascadeItem,
        keyword,
        debouncedSearch,
        navigatePagination,
        setCascadedItems,
        selectPerPage,
        updateStateItems,
        elRefs,
        setElRefs,
        taxOptions,
        cardIsOpen,
        setCardIsOpen,
        updateAssignSubmissionVerifyItemsList,
        getTaxOptions,
        showStatusMessage,
        setShowStatusMessage,
        declineItems,
        suspendItems,
        confirmItems,
        verifyAtPaymentStageItems,
        selectedItemsNames,
        selectItem,
        selectAllItems,
        isSticky,
        ref,
        width,
        selectedAllItems,
        selectedProjectIds,
        selectProjectIds,
        selectAllProjects,
        selectedAllProjects,
        commentModalOpen,
        setCommentModalOpen,
        updateAssignSubmissionVerifyItem,
        setShowItemsList,
        showItemsList,
        handleCopyAssignSubmissionVerifyItem,
        closeErrorMessage
    };
};
exports.useAssignSubmissionVerifyItemsForm = useAssignSubmissionVerifyItemsForm;
