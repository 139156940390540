"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const hrEmployeesAndStructuralUnit_1 = require("../store/hrEmployeesAndStructuralUnit");
const index_1 = require("api/index");
const react_1 = require("react");
const useHrEmployeeAndStructuralUnit = (props) => {
    const [state, dispatch] = (0, hrEmployeesAndStructuralUnit_1.useHrEmployeeAndStructuralUnitReducer)();
    const getHrEmployees = async () => {
        dispatch({ type: 'SEND_GET_HR_EMPLOYEE_REQUEST' });
        const hrEmployees = await index_1.StructuralUnitsUpgradedApi.getHrEmployee({ abbr: 'hr' });
        const attachedAccounts = await index_1.StructuralUnitsUpgradedApi.getAttachedEmployees(props.structuralUnitId);
        dispatch({ type: 'DONE_GET_HR_EMPLOYEE_REQUEST', payload: { hrEmployees, attachedAccounts } });
    };
    const getCheckedEmployees = async () => {
        dispatch({ type: 'SEND_GET_CHECKED_HR_EMPLOYEE_REQUEST' });
        const attachedAccounts = await index_1.StructuralUnitsUpgradedApi.getAttachedEmployees(props.structuralUnitId);
        dispatch({ type: 'DONE_GET_CHECKED_HR_EMPLOYEE_REQUEST', payload: { attachedAccounts } });
    };
    const attachEmployee = async (accountId) => {
        dispatch({ type: 'SEND_EMPLOYEE_ATTACH_REQUEST', payload: accountId });
        const attachedAccounts = await index_1.StructuralUnitsUpgradedApi.attachEmployee(props.structuralUnitId, { accountIds: [accountId] });
        dispatch({ type: 'DONE_EMPLOYEE_ATTACH_REQUEST', payload: { attachedAccounts, accountId } });
        return attachedAccounts;
    };
    const detachEmployee = async (accountId) => {
        dispatch({ type: 'SEND_EMPLOYEE_DETACH_REQUEST', payload: accountId });
        const responseStatus = await index_1.StructuralUnitsUpgradedApi.detachEmployee(props.structuralUnitId, { accountIds: [accountId] });
        dispatch({ type: 'DONE_EMPLOYEE_DETACH_REQUEST', payload: accountId });
        return responseStatus;
    };
    (0, react_1.useEffect)(() => {
        if (props.structuralUnitId)
            getHrEmployees().then();
    }, [props.structuralUnitId]);
    return {
        state,
        getCheckedEmployees,
        attachEmployee,
        detachEmployee
    };
};
exports.default = useHrEmployeeAndStructuralUnit;
