"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const SubmissionList = (props) => {
    const ref = (0, react_1.useRef)(null);
    const handleClickAway = () => (props.setSubmissionList(false));
    (0, react_1.useEffect)(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target))
                handleClickAway();
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [handleClickAway]);
    return ((0, jsx_runtime_1.jsx)("div", { className: 'name-For-position', id: 'autocomplete-listing', ref: ref, children: (0, jsx_runtime_1.jsx)("div", { className: 'p-3 mt-2 card-scroll list', style: { height: 200 }, children: (0, jsx_runtime_1.jsx)("ul", { className: 'list-hover pointer', children: props.data.map((el, index) => {
                    return ((0, jsx_runtime_1.jsxs)("li", { className: 'p-1 item-hover', onClick: () => {
                            props.setValue(el);
                            props.setSubmissionList(false);
                        }, children: [" ", el.label] }, index));
                }) }) }) }));
};
exports.default = SubmissionList;
