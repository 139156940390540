"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bankAccountReducer = exports.useBankAccountReducer = exports.initialBankAccountState = exports.initialBankAccountFormValue = void 0;
const react_1 = require("react");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
exports.initialBankAccountFormValue = {
    id: '',
    bankStatus: apiGlobalTypes_1.YesNoStatus.NO,
    accountNumber: '',
    bank: null,
    requisitesMediaFile: null
};
exports.initialBankAccountState = {
    data: [],
    formValue: exports.initialBankAccountFormValue,
    status: null,
    errors: null,
    isFetching: false,
    isCreatingOrUpdatingBank: false,
    isLoading: false
};
const useBankAccountReducer = () => {
    return (0, react_1.useReducer)(exports.bankAccountReducer, exports.initialBankAccountState);
};
exports.useBankAccountReducer = useBankAccountReducer;
const bankAccountReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_BANK_ACCOUNT_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_BANK_ACCOUNT_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isFetching: false,
                isLoading: state.isCreatingOrUpdatingBank
            };
        case 'SEND_BANK_ACCOUNT_CREATE_OR_UPDATE':
            return {
                ...state,
                errors: null,
                isCreatingOrUpdatingBank: !action.payload.id,
                isLoading: true
            };
        case 'DONE_BANK_ACCOUNT_CREATE_OR_UPDATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isCreatingOrUpdatingBank: false,
                isLoading: state.isFetching
            };
        case 'SEND_DELETE_BANK_ACCOUNT':
            return {
                ...state,
                errors: null
            };
        case 'DONE_DELETE_BANK_ACCOUNT':
            return {
                ...state,
                data: action.payload.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS
                    ? state.data.filter(item => item.id !== action.payload.id)
                    : state.data,
                errors: action.payload.errors
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.bankAccountReducer = bankAccountReducer;
