"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformUserPermissions = exports.parseAuthUser = exports.parseAuth = void 0;
const helper_1 = require("../helper");
const permissions_1 = require("src/modules/auth/login/helpers/permissions");
const parseAuth = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const token = response.content;
    try {
        return {
            data: {
                accessToken: token.access_token,
                expiresIn: token.expires_in,
                refreshToken: token.refresh_token
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        return helper_1.errorObject;
    }
};
exports.parseAuth = parseAuth;
const parseAuthUser = (response) => {
    try {
        if (response.content === null)
            return { data: null, errors: response.errors, status: response.status };
        const { data } = response.content;
        const accounts = data?.relationships?.accounts?.data || [];
        const responseEmulatedBy = data.relationships.emulatedBy;
        const activeAccount = data.relationships.activeAccount;
        const activeAccountStructuralUnit = activeAccount?.data.relationships.contract.data.relationships.structuralUnit?.data;
        return {
            data: {
                id: data.id,
                firstName: data.attributes.firstname,
                fullName: data.attributes.fullname,
                lastName: data.attributes.lastname,
                edocUser: data.relationships.activeAccount?.data.relationships.contract.data.relationships.structuralUnit?.data.attributes.edocUser || false,
                abbr: accounts
                    .filter(el => el.relationships.contract.data.relationships.structuralUnit)
                    .map(account => account.relationships.contract.data.relationships.structuralUnit.data.attributes.abbr)
                    .filter((el) => el !== null),
                email: data.attributes.email,
                activeStructuralUnit: activeAccountStructuralUnit
                    ? {
                        id: activeAccountStructuralUnit.id,
                        abbr: activeAccountStructuralUnit.attributes.abbr,
                        basicFunctionDuties: activeAccountStructuralUnit.attributes.basicFunctionDuties,
                        fullName: activeAccountStructuralUnit.attributes.fullName,
                        parentIds: activeAccountStructuralUnit.attributes.parentIds,
                        hasChildren: activeAccountStructuralUnit.attributes.hasChildren,
                        name: activeAccountStructuralUnit.attributes.name,
                        positionStatus: activeAccountStructuralUnit.attributes.positionStatus,
                        positionType: null,
                        prefixForPosition: activeAccountStructuralUnit.attributes.prefixForPosition,
                        status: activeAccountStructuralUnit.attributes.status,
                        type: activeAccountStructuralUnit.attributes.type,
                        useUnitPrefix: activeAccountStructuralUnit.attributes.useUnitPrefix,
                        employee: null
                    }
                    : null,
                accounts: data.relationships.activeAccount ? accounts.map(el => {
                    const structuralUnit = el.relationships.contract.data.relationships.structuralUnit;
                    const project = el.relationships.contract.data.relationships.project;
                    const nameWords = structuralUnit
                        ? structuralUnit.data.attributes.name.split(' ')
                        : project ? project.data.attributes.name.split(' ') : []; // პროექტი აუცილებლად უნდა მოყვებოდეს თუ სტრუქტურა არ მოყვება
                    return {
                        name: structuralUnit ? structuralUnit.data.attributes.name : project ? 'პროექტის "' + project.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი' : '',
                        id: el.id,
                        active: el.id === data.relationships.activeAccount.data.id,
                        shortName: nameWords.length > 2 ? nameWords.slice(0, 2).join(' ') : ''
                    };
                }) : [],
                emulatedBy: responseEmulatedBy ? {
                    id: responseEmulatedBy.data.id,
                    fullName: responseEmulatedBy.data.attributes.fullname,
                    email: responseEmulatedBy.data.attributes.email,
                    status: responseEmulatedBy.data.attributes.status,
                    firstname: responseEmulatedBy.data.attributes.firstname,
                    lastname: responseEmulatedBy.data.attributes.lastname,
                    fathersName: responseEmulatedBy.data.attributes.fathersName,
                    identityNumber: responseEmulatedBy.data.attributes.identityNumber,
                    idCardValidTo: responseEmulatedBy.data.attributes.idCardValidTo,
                    address: responseEmulatedBy.data.attributes.address,
                    mobileNumber: responseEmulatedBy.data.attributes.mobileNumber,
                    workPhoneNumber: responseEmulatedBy.data.attributes.workPhoneNumber,
                    personalEmail: responseEmulatedBy.data.attributes.personalEmail,
                    birthDate: responseEmulatedBy.data.attributes.birthDate,
                    gender: responseEmulatedBy.data.attributes.gender,
                    emailVerifiedAt: responseEmulatedBy.data.attributes.emailVerifiedAt,
                    passportNumber: responseEmulatedBy.data.attributes.passportNumber,
                    moduleType: responseEmulatedBy.data.type
                } : null,
                accountId: data.relationships.activeAccount?.data.id,
                permissions: data.relationships.activeAccount ? (0, exports.transformUserPermissions)(data.relationships.activeAccount.data.relationships.permissions.data) : {}
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseAuthUser = parseAuthUser;
const transformUserPermissions = (items) => {
    const data = {};
    for (const item of items) {
        if (!permissions_1.permissions[item.attributes.name]) {
            continue;
        }
        data[permissions_1.permissions[item.attributes.name]] = true;
    }
    return data;
};
exports.transformUserPermissions = transformUserPermissions;
