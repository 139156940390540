"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseVerifySubmissionItemActionLog = void 0;
const types_1 = require("api/documents/purchases/submissionVerifies/types");
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parsers_2 = require("api/documents/purchases/submissionVerifies/parsers");
const parsers_3 = require("api/documents/purchases/procurements/parsers");
const parseVerifySubmissionItemActionLog = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const logs = response.content;
    try {
        return {
            data: logs.data.map(log => {
                const logItem = JSON.parse(log.attributes.data);
                const logItemAttributes = logItem.data.attributes;
                const logItemRelationships = logItem.relationships;
                return {
                    id: logItem.data.id,
                    inPurchase: logItemAttributes.inPurchase,
                    actuallyDoneComment: null, // it is for common items
                    parentId: logItemRelationships.parent && logItemRelationships.parent.data.id,
                    originalQuantity: logItemAttributes.quantity,
                    inventoryConfigurationId: logItemRelationships.inventoryConfiguration.data.id,
                    purchaseSubject: {
                        subjectName: logItemRelationships.inventoryConfiguration.data.attributes.itemTitle,
                        specificationName: logItemRelationships.inventoryConfiguration.data.attributes.specificationTitle || '',
                        categoryName: logItemRelationships.inventoryConfiguration.data.attributes.categoryTitle || '',
                        verified: logItemRelationships.inventoryConfiguration.data.attributes.verified
                    },
                    quantity: logItemAttributes.quantity,
                    dimensionUnit: {
                        id: String(logItemRelationships.dimensionUnit.data.id),
                        label: logItemRelationships.dimensionUnit.data.attributes.name
                    },
                    price: logItemAttributes.price,
                    currency: {
                        id: String(logItemRelationships.currency.data.id),
                        label: logItemRelationships.currency.data.attributes.code,
                        currentExchangeRate: logItemRelationships.currency.data.attributes.rate,
                        quantity: logItemRelationships.currency.data.attributes.quantity,
                        transformedRate: logItemRelationships.currency.data.attributes.rate
                            ? logItemRelationships.currency.data.attributes.rate / (logItemRelationships.currency.data.attributes.quantity || 1)
                            : 1
                    },
                    currencyExchangeRate: logItemAttributes.currencyExchangeRate,
                    cpvCode: {
                        id: String(logItemRelationships.cpvCode.data.id),
                        label: logItemRelationships.cpvCode.data.attributes.code + ' - ' + logItemRelationships.cpvCode.data.attributes.name,
                        name: logItemRelationships.cpvCode.data.attributes.name,
                        code: logItemRelationships.cpvCode.data.attributes.code
                    },
                    project: {
                        id: String(logItemRelationships.project.data.id),
                        label: parseProjectFullName(logItemRelationships.project)
                    },
                    budgetArticle: logItemRelationships.budgetArticle && {
                        id: String(logItemRelationships.budgetArticle.data.id),
                        label: logItemRelationships.budgetArticle.data.attributes.code + ' - ' + logItemRelationships.budgetArticle.data.attributes.name,
                        name: logItemRelationships.budgetArticle.data.attributes.name,
                        code: logItemRelationships.budgetArticle.data.attributes.code
                    } || null,
                    characteristics: logItemAttributes.characteristics,
                    assignment: logItemAttributes.assignment,
                    procurementTerminationReason: logItemAttributes.procurementTerminationReason,
                    budgetingStatusMessage: parseBudgetingMessageLog(logItemAttributes, log.relationships.creator.data.relationships.user.data.attributes.fullname),
                    purchasingStatusMessage: parsePurchasingMessageLog(logItemAttributes, log.relationships.creator.data.relationships.user.data.attributes.fullname),
                    budgetingVisualStatus: (0, parsers_2.parseSubmissionVerifyItemStatusName)(logItemAttributes.budgetingStatus),
                    purchaseVisualStatus: (0, parsers_2.parseSubmissionVerifyItemStatusName)(logItemAttributes.purchaseStatus),
                    deletedAt: (0, parsers_1.nullableTransformDate)(logItemAttributes.deletedAt),
                    budgetingStatus: logItemAttributes.budgetingStatus,
                    purchaseStatus: logItemAttributes.purchaseStatus,
                    budgetingStatusUpdatedAt: (0, parsers_1.nullableTransformDate)(logItemAttributes.budgetingStatusUpdatedAt),
                    purchaseStatusUpdatedAt: (0, parsers_1.nullableTransformDate)(logItemAttributes.purchaseStatusUpdatedAt),
                    hasLogs: false,
                    gelSummery: logItemRelationships.currency.data.attributes.code !== 'GEL'
                        ? logItemRelationships.currency.data.attributes.quantity
                            ? logItemAttributes.quantity * logItemAttributes.price * (logItemAttributes.currencyExchangeRate / logItemRelationships.currency.data.attributes.quantity)
                            : null
                        : logItemAttributes.quantity * logItemAttributes.price,
                    procurement: logItemRelationships.procurementItem && {
                        id: String(logItemRelationships.procurementItem.relationships.document.data.id),
                        status: logItemRelationships.procurementItem.relationships.document.data.attributes.status,
                        number: logItemRelationships.procurementItem.relationships.document.data.attributes.signedDocumentNumber
                            || logItemRelationships.procurementItem.relationships.document.data.attributes.number,
                        itemPrice: logItemRelationships.procurementItem.data.attributes.price || 0
                    } || null,
                    purchaseDeclineComment: logItemAttributes.purchaseDeclineComment,
                    budgetingDeclineComment: logItemAttributes.budgetingDeclineComment,
                    purchaseSuspendComment: logItemAttributes.purchaseSuspendComment,
                    budgetingSuspendComment: logItemAttributes.budgetingSuspendComment,
                    templateType: parsers_3.templateTypes.find(type => Number(type.id) === logItemAttributes.templateType) || null,
                    bookAuthor: logItemAttributes.bookAuthor,
                    bookLink: logItemAttributes.bookLink,
                    bookIsbnCode: logItemAttributes.bookIsbnCode,
                    bookTitle: logItemAttributes.bookTitle
                };
            }),
            status: response.status,
            meta: (0, parsers_1.transformPagination)(logs.meta),
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseVerifySubmissionItemActionLog = parseVerifySubmissionItemActionLog;
const parseProjectFullName = (project, withBudgetSource = true) => {
    try {
        const number = project.data.attributes.number ? project.data.attributes.number + ' - ' : '';
        const name = project.data.attributes.name + ' - ';
        const additionalInfo = project.data.attributes.additionalInfo ? project.data.attributes.additionalInfo + ' - ' : '';
        const headOfDirection = project.data.attributes.headOfDirection + ' - ';
        const startDate = project.data.attributes.startDate ? (0, parsers_1.transformDate)(project.data.attributes.startDate) + ' - ' : '';
        const endDate = project.data.attributes.endDate ? (0, parsers_1.transformDate)(project.data.attributes.endDate) : '';
        const donor = project.relationships.donor ? project.relationships.donor.data.attributes.name + ' - ' : '';
        const fundingSource = project.relationships.fundingSource.data.attributes.name + ' - ';
        const budgetSource = project.relationships.budgetSource.data.attributes.name + ' - ';
        return number + name + fundingSource + (withBudgetSource ? budgetSource : '') + additionalInfo + headOfDirection + donor + startDate + endDate;
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return project.data.attributes.name;
    }
};
const parseBudgetingMessageLog = (logItemAttributes, userFullName) => {
    const updatedBy = userFullName && logItemAttributes.budgetingStatusUpdatedAt
        ? userFullName + ' - ' + (0, parsers_1.transformDate)(logItemAttributes.budgetingStatusUpdatedAt)
        : 'System';
    switch (logItemAttributes.budgetingStatus) {
        case types_1.SubmissionVerifyItemStatus.DRAFT:
            return updatedBy;
        case types_1.SubmissionVerifyItemStatus.APPROVED:
            return updatedBy;
        case types_1.SubmissionVerifyItemStatus.DECLINED:
            return logItemAttributes.budgetingDeclineComment && (logItemAttributes.budgetingDeclineComment + ' - ' + updatedBy) || updatedBy;
        case types_1.SubmissionVerifyItemStatus.SUSPENDED:
            return logItemAttributes.budgetingSuspendComment && (logItemAttributes.budgetingSuspendComment + ' - ' + updatedBy) || updatedBy;
        default:
            return updatedBy;
    }
};
const parsePurchasingMessageLog = (logItemAttributes, userFullName) => {
    const updatedBy = userFullName && logItemAttributes.purchaseStatusUpdatedAt
        ? userFullName + ' - ' + (0, parsers_1.transformDate)(logItemAttributes.purchaseStatusUpdatedAt)
        : 'System';
    switch (logItemAttributes.purchaseStatus) {
        case types_1.SubmissionVerifyItemStatus.DRAFT:
            return updatedBy;
        case types_1.SubmissionVerifyItemStatus.APPROVED:
            return updatedBy;
        case types_1.SubmissionVerifyItemStatus.DECLINED:
            return logItemAttributes.purchaseDeclineComment && (logItemAttributes.purchaseDeclineComment + ' - ' + updatedBy) || updatedBy;
        case types_1.SubmissionVerifyItemStatus.SUSPENDED:
            return logItemAttributes.purchaseSuspendComment && (logItemAttributes.purchaseSuspendComment + ' - ' + updatedBy) || updatedBy;
        default:
            return updatedBy;
    }
};
