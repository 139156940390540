"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const subjects_1 = require("../store/subjects");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const react_1 = require("react");
const useSubjects = (props) => {
    const [state, dispatch] = (0, react_1.useReducer)(subjects_1.subjectReducer, subjects_1.initialSubjectState);
    const [keyword, setKeyword] = (0, react_1.useState)('');
    const debouncedSearch = (debouncedKeyword) => {
        setKeyword(debouncedKeyword);
        const pagination = state.meta.pagination;
        const params = {
            filters: { category: props.categoryId, keyword: debouncedKeyword },
            page: 1,
            perPage: pagination.perPage
        };
        (0, debouncedMethods_1.debouncedOnFunc)(params, getSubjects);
    };
    const getSubjects = (0, react_1.useCallback)(async (params) => {
        dispatch({ type: 'SEND_SUBJECTS_REQUEST' });
        const subjects = await index_1.PurchaseSubjectsApi.getPurchaseSubjects(params);
        dispatch({ type: 'DONE_SUBJECTS_REQUEST', payload: subjects });
    }, [props.categoryId]);
    const selectPerPage = (perPage) => {
        dispatch({ type: 'CHANGE_PER_PAGE', payload: perPage });
        const params = {
            filters: { category: props.categoryId, keyword },
            page: state.meta.pagination.currentPage,
            perPage
        };
        getSubjects(params).then();
    };
    const navigatePagination = (page) => {
        const params = {
            filters: { category: props.categoryId, keyword },
            page,
            perPage: state.meta.pagination.perPage
        };
        getSubjects(params).then();
    };
    (0, react_1.useEffect)(() => {
        getSubjects({ filters: { category: props.categoryId } }).then();
    }, [props.categoryId, getSubjects]);
    return {
        state,
        getSubjects,
        // closeModal,
        keyword,
        debouncedSearch,
        selectPerPage,
        navigatePagination
    };
};
exports.default = useSubjects;
