"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.budgetingRecordsReducer = exports.useBudgetingRecordListReducer = exports.initialBudgetingRecordState = void 0;
const react_1 = require("react");
exports.initialBudgetingRecordState = {
    data: null,
    status: null,
    errors: null,
    isFetching: false,
    isRecording: false,
    isSendingComment: false
};
const useBudgetingRecordListReducer = () => {
    return (0, react_1.useReducer)(exports.budgetingRecordsReducer, exports.initialBudgetingRecordState);
};
exports.useBudgetingRecordListReducer = useBudgetingRecordListReducer;
const budgetingRecordsReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_GET_RECORD_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_GET_RECORD_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isFetching: false
            };
        case 'SEND_COMMENTING_REQUEST':
            return {
                ...state,
                isSendingComment: true
            };
        case 'DONE_COMMENTING_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isSendingComment: false
            };
        case 'SEND_STATUS_CHANGE_REQUEST':
            return {
                ...state,
                isRecording: true
            };
        case 'DONE_STATUS_CHANGE_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isRecording: false
            };
        default:
            return state;
    }
};
exports.budgetingRecordsReducer = budgetingRecordsReducer;
