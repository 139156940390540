"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const determination_1 = require("../store/determination");
const react_1 = require("react");
const helpers_1 = require("../helpers");
const react_router_dom_1 = require("react-router-dom");
const useDetermination = (props) => {
    const [state, dispatch] = (0, determination_1.useDeterminationReducer)();
    const [determinationFileType, setDeterminationFileType] = (0, react_1.useState)('');
    const [showDeleteFileAlert, setShowDeleteFileAlert] = (0, react_1.useState)(null);
    const [deletingFiles, setDeletingFiles] = (0, react_1.useState)([]);
    const [showSweetAlert, setShowSweetAlert] = (0, react_1.useState)(false);
    const [showSuspendMessage, setShowSuspendMessage] = (0, react_1.useState)(false);
    const [showDeclineMessage, setShowDeclineMessage] = (0, react_1.useState)(false);
    const [showProgressInPurchaseAlert, setShowProgressInPurchaseAlert] = (0, react_1.useState)(false);
    const [isExporting, setIsExporting] = (0, react_1.useState)(false);
    const navigate = (0, react_router_dom_1.useNavigate)();
    const getDeterminations = async () => {
        dispatch({ type: 'SEND_DETERMINATIONS_REQUEST' });
        const determinations = await index_1.ConditionalSubmissionsApi.getDeterminations(props.conditionalSubmissionId);
        dispatch({ type: 'DONE_DETERMINATIONS_REQUEST', payload: determinations });
    };
    const createDetermination = async (values) => {
        const params = (0, helpers_1.transformDeterminationParams)(values);
        dispatch({ type: 'SEND_DETERMINATION_CREATE' });
        const determination = await index_1.ConditionalSubmissionsApi.createDetermination(props.conditionalSubmissionId, params);
        dispatch({ type: 'DONE_DETERMINATION_CREATE', payload: determination });
        if (determination.data !== null) {
            navigate(`/documents/purchases/conditional-submissions/${props.conditionalSubmissionId}/determination/${determination.data.id}/edit`);
        }
        return determination;
    };
    const updateDetermination = async (values, determinationId) => {
        const params = (0, helpers_1.transformDeterminationParams)(values);
        dispatch({ type: 'SEND_DETERMINATION_UPDATE' });
        const determination = await index_1.ConditionalSubmissionsApi.updateDetermination(determinationId, params);
        dispatch({ type: 'DONE_DETERMINATION_UPDATE', payload: determination });
        return determination;
    };
    const deleteDeterminationFile = async (id) => {
        setDeletingFiles(prev => [...prev, id]);
        const determinationFile = await index_1.ConditionalSubmissionsApi.deleteDeterminationFile(props.determinationId, { fileUids: [id] });
        setDeletingFiles(prev => prev.filter(el => el !== id));
        return { errors: determinationFile.errors, status: determinationFile.status };
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    const fileDownload = async (type, id) => {
        setIsExporting(true);
        const file = await index_1.ConditionalSubmissionsApi.getDeterminationFiles({ type }, id);
        if (file)
            open(file.data?.link);
        setIsExporting(false);
        return file;
    };
    const sendDetermination = async (id) => {
        dispatch({ type: 'SEND_DETERMINATION_SEND_REQUEST' });
        const determination = await index_1.ConditionalSubmissionsApi.sendDeterminationToChancellery(id);
        dispatch({ type: 'DONE_DETERMINATION_SEND_REQUEST', payload: determination });
        return determination;
    };
    const sendProgressInPurchase = async (id) => {
        dispatch({ type: 'SEND_PROGRESS_IN_PURCHASE_REQUEST' });
        const determination = await index_1.ConditionalSubmissionsApi.sendDeterminationProgressInPurchase(id);
        dispatch({ type: 'DONE_PROGRESS_IN_PURCHASE_REQUEST', payload: determination });
        return determination;
    };
    const suspendDeterminationItem = async (suspendComment) => {
        setShowSuspendMessage(false);
        dispatch({ type: 'SEND_DETERMINATION_SUSPEND_REQUEST' });
        const determination = await index_1.ConditionalSubmissionsApi.suspendDeterminationItem(props.determinationId, { comment: suspendComment });
        dispatch({ type: 'DONE_DETERMINATION_SUSPEND_REQUEST', payload: determination });
        return determination;
    };
    const declineDeterminationItem = async (declineComment) => {
        setShowDeclineMessage(false);
        dispatch({ type: 'SEND_DETERMINATION_DECLINE_REQUEST' });
        const determination = await index_1.ConditionalSubmissionsApi.declineDeterminationItem(props.determinationId, { comment: declineComment });
        dispatch({ type: 'DONE_DETERMINATION_DECLINE_REQUEST', payload: determination });
        return determination;
    };
    const loadOriginal = () => { navigate(`/documents/purchases/conditional-submissions/${props.conditionalSubmissionId}/edit`); };
    (0, react_1.useEffect)(() => {
        if (props.determinationId && !state.currentVersion.id) {
            getDeterminations().then(() => {
                dispatch({ type: 'CHANGE_CURRENT_VERSION', payload: props.determinationId });
            });
        }
        else if (props.determinationId) {
            dispatch({ type: 'CHANGE_CURRENT_VERSION', payload: props.determinationId });
        }
    }, [props.determinationId]);
    return {
        state,
        createDetermination,
        closeErrorMessage,
        determinationFileType,
        setDeterminationFileType,
        setShowDeleteFileAlert,
        showDeleteFileAlert,
        deletingFiles,
        deleteDeterminationFile,
        updateDetermination,
        setShowSweetAlert,
        showSweetAlert,
        setShowSuspendMessage,
        showSuspendMessage,
        setShowDeclineMessage,
        showDeclineMessage,
        setShowProgressInPurchaseAlert,
        showProgressInPurchaseAlert,
        fileDownload,
        sendDetermination,
        sendProgressInPurchase,
        suspendDeterminationItem,
        declineDeterminationItem,
        // changeCurrentVersion,
        loadOriginal,
        isExporting
    };
};
exports.default = useDetermination;
