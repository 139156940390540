"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const warehouse_1 = require("../store/warehouse");
const api_1 = require("src/api");
const helper_1 = require("../helper");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useWarehouse = (props) => {
    const [state, dispatch] = (0, warehouse_1.useWarehouseReducer)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const getWarehouse = async (id) => {
        dispatch({ type: 'SEND_WAREHOUSE_REQUEST' });
        const warehouse = await api_1.WarehousesApi.getWarehouse(id);
        dispatch({ type: 'DONE_WAREHOUSE_REQUEST', payload: warehouse });
    };
    const createWarehouse = async (values) => {
        const params = (0, helper_1.transformWarehouseParams)(values);
        dispatch({ type: 'SEND_WAREHOUSE_CREATE' });
        const warehouse = await api_1.WarehousesApi.createWarehouse(params);
        dispatch({ type: 'DONE_WAREHOUSE_CREATE', payload: warehouse });
        if (warehouse.data !== null)
            navigate(`/references/warehouses/${warehouse.data.id}/edit`);
        return warehouse;
    };
    const updateWarehouse = async (id, values) => {
        const params = (0, helper_1.transformWarehouseParams)((values));
        dispatch({ type: 'SEND_WAREHOUSE_UPDATE' });
        const warehouse = await api_1.WarehousesApi.updateWarehouse(id, params);
        dispatch({ type: 'DONE_WAREHOUSE_UPDATE', payload: warehouse });
        return warehouse;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getWarehouse(props.id).then(); }, [props.id]);
    return { state, createWarehouse, updateWarehouse, getWarehouse, closeErrorMessage };
};
exports.default = useWarehouse;
