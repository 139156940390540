"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToastType = void 0;
var ToastType;
(function (ToastType) {
    ToastType[ToastType["DANGER"] = 0] = "DANGER";
    ToastType[ToastType["SUCCESS"] = 1] = "SUCCESS";
    ToastType[ToastType["INFO"] = 2] = "INFO";
    ToastType[ToastType["WARNING"] = 3] = "WARNING";
})(ToastType || (exports.ToastType = ToastType = {}));
