"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInventorySpecification = void 0;
const InventorySpecificatioType_1 = require("../store/InventorySpecificatioType");
const index_1 = require("api/index");
const queryString_1 = require("core/helpers/queryString");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const react_router_1 = require("react-router");
const useInventorySpecification = (props) => {
    const [state, dispatch] = (0, InventorySpecificatioType_1.useInventorySpecificationReducer)();
    const [listingParams, setListingParams] = (0, react_1.useState)('');
    const navigate = (0, react_router_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const getInventorySpecification = async (id) => {
        dispatch({ type: 'SEND_INVENTORY_SPECIFICATION_REQUEST' });
        const specification = await index_1.PurchasesSpecificationsApi.getInventorySpecification(id);
        dispatch({ type: 'DONE_INVENTORY_SPECIFICATION_REQUEST', payload: specification });
    };
    const crateInventorySpecification = async (values) => {
        const params = (0, helpers_1.transformInventorySpecificationParams)(values);
        dispatch({ type: 'SEND_INVENTORY_SPECIFICATION_REQUEST' });
        const specification = await index_1.PurchasesSpecificationsApi.crateInventorySpecification(params);
        dispatch({ type: 'DONE_INVENTORY_SPECIFICATION_REQUEST', payload: specification });
        if (specification.data !== null) {
            navigate(`/references/purchases/purchase-specification/${specification.data.id}/edit`);
        }
        return specification;
    };
    const updateInventorySpecification = async (id, values) => {
        const params = (0, helpers_1.transformInventorySpecificationParams)(values);
        dispatch({ type: 'SEND_INVENTORY_SPECIFICATION_REQUEST' });
        const specification = await index_1.PurchasesSpecificationsApi.updateInventorySpecification(id, params);
        dispatch({ type: 'DONE_INVENTORY_SPECIFICATION_REQUEST', payload: specification });
        return specification;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => {
        const params = (0, helpers_1.transformSpecificationFilterParamsToUrl)((0, helpers_1.transformUrlToSpecificationFilterParams)(location.search));
        const searchParams = (0, queryString_1.stringify)({ ...params });
        setListingParams(searchParams || '?');
        if (props.id)
            getInventorySpecification(props.id).then();
    }, [props.id]);
    return { state, crateInventorySpecification, updateInventorySpecification, getInventorySpecification, closeErrorMessage, listingParams };
};
exports.useInventorySpecification = useInventorySpecification;
exports.default = exports.useInventorySpecification;
