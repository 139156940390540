"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const workDirection_1 = require("../store/workDirection");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useWorkDirection = (props) => {
    const [state, dispatch] = (0, workDirection_1.useWorkDirectionReducer)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const getWorkDirection = async (id) => {
        dispatch({ type: 'SEND_WORK_DIRECTION_REQUEST' });
        const workDirection = await index_1.WorkDirectionApi.getWorkDirection(id);
        dispatch({ type: 'DONE_WORK_DIRECTION_REQUEST', payload: workDirection });
    };
    const createWorkDirection = async (values) => {
        const params = (0, helpers_1.transformWorkDirectionParams)(values);
        dispatch({ type: 'SEND_WORK_DIRECTION_CREATE' });
        const workDirection = await index_1.WorkDirectionApi.createWorkDirection(params);
        dispatch({ type: 'DONE_WORK_DIRECTION_CREATE', payload: workDirection });
        if (workDirection.data !== null)
            navigate(`/references/human-resources/work-directions/${workDirection.data.id}/edit`);
        return workDirection;
    };
    const updateWorkDirection = async (id, values) => {
        const params = (0, helpers_1.transformWorkDirectionParams)(values);
        dispatch({ type: 'SEND_WORK_DIRECTION_UPDATE' });
        const workDirection = await index_1.WorkDirectionApi.updateWorkDirection(id, params);
        dispatch({ type: 'DONE_WORK_DIRECTION_UPDATE', payload: workDirection });
        return workDirection;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getWorkDirection(props.id).then(); }, [props.id]);
    return { state, createWorkDirection, updateWorkDirection, getWorkDirection, closeErrorMessage };
};
exports.default = useWorkDirection;
