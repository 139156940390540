"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const currency_1 = require("../store/currency");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_1 = require("react-router");
const useCurrency = (props) => {
    const [state, dispatch] = (0, currency_1.useCurrencyReducer)();
    const navigate = (0, react_router_1.useNavigate)();
    const getCurrency = async (id) => {
        dispatch({ type: 'SEND_CURRENCY_REQUEST' });
        const currency = await index_1.CurrenciesApi.getCurrency(id);
        dispatch({ type: 'DONE_CURRENCY_REQUEST', payload: currency });
    };
    const createCurrency = async (values) => {
        const params = (0, helpers_1.transformCurrencyParams)(values);
        dispatch({ type: 'SEND_CURRENCY_CREATE' });
        const currency = await index_1.CurrenciesApi.createCurrency(params);
        dispatch({ type: 'DONE_CURRENCY_CREATE', payload: currency });
        if (currency.data !== null) {
            navigate(`/references/currencies/${currency.data.id}/edit`);
        }
        return currency;
    };
    const updateCurrency = async (id, values) => {
        const params = (0, helpers_1.transformCurrencyParams)(values);
        dispatch({ type: 'SEND_CURRENCY_UPDATE' });
        const currency = await index_1.CurrenciesApi.updateCurrency(id, params);
        dispatch({ type: 'DONE_CURRENCY_UPDATE', payload: currency });
        return currency;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getCurrency(props.id).then(); }, [props.id]);
    return { state, createCurrency, updateCurrency, getCurrency, closeErrorMessage };
};
exports.default = useCurrency;
