"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const legalBasisAutocomplete_1 = require("../store/legalBasisAutocomplete");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const procurements_1 = require("api/documents/purchases/procurements");
const react_1 = require("react");
const useLegalBasisAutocomplete = () => {
    const [state, dispatch] = (0, legalBasisAutocomplete_1.useLegalBasisAutocompleteReducer)();
    const [legalBasisId, setLegalBasisId] = (0, react_1.useState)(null);
    const [legalBasis, setLegalBasis] = (0, react_1.useState)(null);
    const getLegalBasisAutocomplete = async (params) => {
        dispatch({ type: 'SEND_LEGAL_BASIS_REQUEST' });
        const legalBasisAutocomplete = await (0, procurements_1.getLegalBasicsForSelect)(params);
        dispatch({ type: 'DONE_LEGAL_BASIS_REQUEST', payload: { data: legalBasisAutocomplete } });
    };
    const resetAutocompleteData = (event) => {
        if (!event)
            return dispatch({ type: 'RESET_DATA' });
        const isChosen = document.querySelector('#autocomplete-legal_listing')?.contains(event.target);
        if (!isChosen)
            dispatch({ type: 'RESET_DATA' });
    };
    const debouncedSearch = (value) => {
        const params = value ? ({ filters: { keyword: value } }) : {};
        (0, debouncedMethods_1.debouncedOnFunc)(params, getLegalBasisAutocomplete);
    };
    return { state, resetAutocompleteData, debouncedSearch, setLegalBasis, legalBasis, legalBasisId, setLegalBasisId };
};
exports.default = useLegalBasisAutocomplete;
