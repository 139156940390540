"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const verifiedItems_1 = require("../store/verifiedItems");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const useVerifiedItems = (props) => {
    const [state, dispatch] = (0, verifiedItems_1.useVerifiedItemsReducer)();
    const filter = (name, value, filterValues) => {
        getVerifiedItems({ ...filterValues, [name]: value, page: 1 }).then();
    };
    const getVerifiedItems = async (params) => {
        dispatch({ type: 'SEND_VERIFIED_ITEMS_REQUEST' });
        const verifiedItems = await index_1.ProcurementsApi.getVerifiedItems(params);
        dispatch({ type: 'DONE_VERIFIED_ITEMS_REQUEST', payload: verifiedItems });
    };
    const getCpvCodes = async () => {
        dispatch({ type: 'SEND_CPV_CODES_REQUEST' });
        const cpvCodes = await index_1.ProcurementsApi.getApprovedItemCpvCodes();
        dispatch({ type: 'DONE_CPV_CODES_REQUEST', payload: cpvCodes });
    };
    const getApprovedSubmissions = async () => {
        dispatch({ type: 'SEND_SUBMISSIONS_REQUEST' });
        const submissions = await index_1.ProcurementsApi.getApprovedItemSubmissions();
        dispatch({ type: 'DONE_SUBMISSIONS_REQUEST', payload: submissions });
    };
    const navigatePagination = (page, values) => {
        const params = (0, helpers_1.transformVerifiedItemsFilterParams)(values);
        getVerifiedItems({ ...params, page }).then();
    };
    const selectPerPage = (perPage, values) => {
        const params = (0, helpers_1.transformVerifiedItemsFilterParams)(values);
        getVerifiedItems({ ...params, perPage }).then();
    };
    const debouncedSearch = (values) => {
        const params = (0, helpers_1.transformVerifiedItemsFilterParams)(values);
        (0, debouncedMethods_1.debouncedOnFunc)(params, getVerifiedItems);
    };
    (0, react_1.useEffect)(() => {
        getVerifiedItems(props?.procurementCorrectionId ? { filters: { procurementCorrectionId: props.procurementCorrectionId } } : {}).then(() => {
            getCpvCodes().then();
            getApprovedSubmissions().then();
        });
    }, []);
    return { debouncedSearch, navigatePagination, selectPerPage, state, filter };
};
exports.default = useVerifiedItems;
