"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const types_1 = require("../types");
const categories_1 = require("../store/categories");
const index_1 = require("api/index");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helpers_1 = require("../helpers");
const queryString_1 = require("core/helpers/queryString");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useCategories = (props) => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, categories_1.useCategoriesReducer)();
    const [keyword, setKeyword] = (0, react_1.useState)('');
    const isMounted = (0, react_1.useRef)(false);
    const getCategories = async (params) => {
        dispatch({ type: 'SEND_CATEGORIES_REQUEST' });
        const categories = await index_1.PurchaseCategoriesApi.getCategories(params);
        if (isMounted) {
            navigate(`/references/purchases/${params.type === types_1.CategoryTypes.SUBJECT_CATEGORIES
                ? 'categories'
                : 'subject-accounting'}` + (0, queryString_1.stringify)(params), { replace: true });
            dispatch({ type: 'DONE_CATEGORIES_REQUEST', payload: categories });
        }
    };
    const handleOpenClose = async (category) => {
        if (!category.hasChildren)
            return;
        const newStateData = [...state.data];
        const foundItem = (0, helpers_1.findNestedItem)(newStateData, category);
        if (!foundItem)
            return;
        if (foundItem.children.length > 0) {
            foundItem.closed = !foundItem.closed;
            dispatch({ type: 'UPDATE_STATE', payload: newStateData });
            return;
        }
        const params = { filters: { parentId: category.id }, perPage: 100, type: props.type };
        dispatch({ type: 'SET_LOADING_ITEM', payload: foundItem });
        const categories = await index_1.PurchaseCategoriesApi.getCategories(params);
        dispatch({ type: 'UN_SET_LOADING_ITEM', payload: foundItem });
        foundItem.closed = !foundItem.closed;
        foundItem.children = categories.data?.map(child => {
            child.level = foundItem.level + 1;
            return child;
        }) || [];
        dispatch({ type: 'UPDATE_STATE', payload: newStateData });
    };
    const debouncedSearch = (value) => {
        setKeyword(value);
        const params = value
            ? { filters: { keyword: value }, perPage: 100, type: props.type }
            : { filters: { parentId: 0 }, perPage: 100, type: props.type };
        (0, debouncedMethods_1.debouncedOnFunc)(params, getCategories);
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        const params = (0, helpers_1.getParamsFromUrl)(location.search);
        setKeyword(params.filters?.keyword || '');
        getCategories({ ...params, type: props.type }).then();
        return () => { isMounted.current = false; };
    }, []);
    return { state, keyword, debouncedSearch, handleOpenClose, getCategories };
};
exports.default = useCategories;
