"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const donor_1 = require("../store/donor");
const index_1 = require("api/index");
const queryString_1 = require("core/helpers/queryString");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useDonor = (props) => {
    const [state, dispatch] = (0, donor_1.useDonorReducer)();
    const [listingParams, setListingParams] = (0, react_1.useState)('');
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const getDonor = async (id) => {
        dispatch({ type: 'SEND_DONOR_REQUEST' });
        const donor = await index_1.DonorsApi.getDonor(id);
        dispatch({ type: 'DONE_DONOR_REQUEST', payload: donor });
    };
    const createDonor = async (values) => {
        const params = (0, helpers_1.transformDonorParams)(values);
        dispatch({ type: 'SEND_DONOR_CREATE' });
        const donor = await index_1.DonorsApi.createDonor(params);
        dispatch({ type: 'DONE_DONOR_CREATE', payload: donor });
        if (donor.data !== null) {
            navigate(`/references/funding/donors/${donor.data.id}/edit`);
        }
        return donor;
    };
    const updateDonor = async (id, values) => {
        const params = (0, helpers_1.transformDonorParams)(values);
        dispatch({ type: 'SEND_DONOR_UPDATE' });
        const donor = await index_1.DonorsApi.updateDonor(id, params);
        dispatch({ type: 'DONE_DONOR_UPDATE', payload: donor });
        return donor;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => {
        const params = (0, helpers_1.transformDonorFilterParamsToUrl)((0, helpers_1.transformUrlToDonorFilterParams)(location.search));
        const searchParams = (0, queryString_1.stringify)({ ...params });
        setListingParams(searchParams || '?');
        if (props.id)
            getDonor(props.id).then();
    }, [props.id]);
    return { state, createDonor, updateDonor, getDonor, closeErrorMessage, listingParams };
};
exports.default = useDonor;
