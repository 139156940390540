"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformPurchaseSubjectFilterParamsToUrl = exports.transformPurchaseSubjectParamsToFilterParams = exports.transformUrlToPurchaseSubjectFilterParams = exports.transformPurchaseSubjectParams = exports.getParamsFromUrl = void 0;
const types_1 = require("api/references/purchase-subject/types");
const queryString_1 = require("core/helpers/queryString");
const getParamsFromUrl = (url) => {
    try {
        const params = (0, queryString_1.parse)(url);
        return {
            page: params.page,
            perPage: params.perPage,
            filters: params.filters?.keyword ? { keyword: params.filters.keyword } : undefined
        };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.getParamsFromUrl = getParamsFromUrl;
const transformPurchaseSubjectParams = (value) => {
    return {
        itemId: value.subject?.id,
        specificationId: value.specification?.id || null,
        categoryId: value.category?.id,
        verified: value.verified,
        type: value.type
    };
};
exports.transformPurchaseSubjectParams = transformPurchaseSubjectParams;
const transformUrlToPurchaseSubjectFilterParams = (url) => {
    const values = (0, queryString_1.parse)(url);
    return {
        category: values.categoryId && values.categoryLabel ? { id: values.categoryId, label: values.categoryLabel } : undefined,
        keyword: values.keyword,
        verified: values.verified && Number(values.verified),
        page: values.page,
        perPage: values.perPage
    };
};
exports.transformUrlToPurchaseSubjectFilterParams = transformUrlToPurchaseSubjectFilterParams;
const transformPurchaseSubjectParamsToFilterParams = (params) => {
    const filters = {};
    const paginate = {};
    const filterParams = {
        keyword: params.keyword,
        verified: params.verified,
        category: params.category?.id,
        page: params.page,
        perPage: params.perPage
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if ((value || value === types_1.VerifiedStatus.UNVERIFIED) && key !== 'page' && key !== 'perPage')
                filters[key] = value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(filters).length === 0)
            return { ...paginate };
        return { ...paginate, filters };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformPurchaseSubjectParamsToFilterParams = transformPurchaseSubjectParamsToFilterParams;
const transformPurchaseSubjectFilterParamsToUrl = (values) => {
    const { page, perPage, keyword, verified, category } = values;
    return {
        page,
        perPage,
        keyword,
        verified,
        categoryId: category?.id,
        categoryLabel: category?.label
    };
};
exports.transformPurchaseSubjectFilterParamsToUrl = transformPurchaseSubjectFilterParamsToUrl;
