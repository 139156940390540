"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseEmployeeForStructuralUnitSelect = exports.parseContract = exports.parseContracts = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseContracts = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors };
    const contracts = response.content;
    try {
        return {
            data: contracts.data.map(el => {
                return {
                    id: el.id,
                    endDate: (0, parsers_1.nullableTransformDate)(el.attributes.endDate),
                    startDate: (0, parsers_1.transformDate)(el.attributes.startDate),
                    status: el.attributes.status,
                    structuralUnitId: el.attributes.structuralUnitId,
                    userId: el.attributes.userId
                };
            }),
            errors: null,
            meta: (0, parsers_1.transformPagination)(contracts.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseContracts = parseContracts;
const parseContract = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const contract = response.content;
    try {
        return {
            data: {
                id: contract.data.id,
                endDate: (0, parsers_1.nullableTransformDate)(contract.data.attributes.endDate),
                startDate: (0, parsers_1.transformDate)(contract.data.attributes.startDate),
                createdAt: (0, parsers_1.transformDate)(contract.data.attributes.createdAt),
                updatedAt: (0, parsers_1.transformDate)(contract.data.attributes.updatedAt),
                status: contract.data.attributes.status,
                user: (0, exports.parseEmployeeForStructuralUnitSelect)(contract.data.relationships.user.data),
                structuralUnit: {
                    id: contract.data.relationships.structuralUnit.data.id,
                    label: contract.data.relationships.structuralUnit.data.attributes.name
                }
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseContract = parseContract;
const parseEmployeeForStructuralUnitSelect = (employee) => {
    const identityNumber = employee.attributes.identityNumber ? ' (' + employee.attributes.identityNumber + ')' : '';
    return {
        id: employee.id,
        label: employee.attributes.fullname + identityNumber
    };
};
exports.parseEmployeeForStructuralUnitSelect = parseEmployeeForStructuralUnitSelect;
