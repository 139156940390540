"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assignSubmissionVerifyFormValidation = void 0;
const types_1 = require("api/documents/human-resources/assign-submissions/types");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const validates_1 = require("core/helpers/validates");
const helper_1 = require("src/modules/common/helper");
const assignSubmissionItemForm_1 = require("../../assign-submissions/validates/assignSubmissionItemForm");
const validateSubmissionVerifyEmployee = (item) => {
    return {
        personalNumber: checkPersonalNumberValidity(item),
        idCardValidityDate: checkIdCardDateValidity(item),
        passportNumber: checkPassportNumberValidity(item),
        passportValidityDate: checkPassportDateValidity(item),
        // residenceCertificateDate: item.residenceCertificateDate ? requiredDateRange(item.residenceCertificateDate): undefined,
        residencyValidityDate: checkResidencyDateValidity(item),
        residenceCertificateNumber: checkResidenceCertificateNumberValidity(item),
        firstname: (0, validates_1.required)(item.firstname),
        lastname: (0, validates_1.required)(item.lastname),
        phoneNumber: (0, validates_1.required)(item.phoneNumber),
        country: item.citizen === types_1.Citizen.FOREIGN ? (0, validates_1.required)(item.country) : undefined,
        email: !item.hasNoEmail ? (0, validates_1.required)(item.email) : undefined,
        taxReliefAmount: item.useOfTaxRelief ? (0, validates_1.required)(item.taxReliefAmount) : undefined,
        errorTaxOptions: item.taxReliefBenefit === apiGlobalTypes_1.YesNoStatus.YES
            ? !item.taxReliefId ? 'სავალდებულოა, აირჩიოთ შეღავათის ტიპი' : undefined
            : undefined,
        positionInfoErrors: item.positionInfos.map(positionInfo => {
            const startDate = positionInfo.startDate && new Date((0, helper_1.transformParamsDate)(positionInfo.startDate));
            const today = new Date();
            return {
                position: (0, validates_1.required)(positionInfo.position),
                positionType: (0, validates_1.required)(positionInfo.positionType),
                assignType: (0, validates_1.required)(positionInfo.assignType),
                employmentContractType: positionInfo.assignType && Number(positionInfo.assignType.id) === types_1.AssignType.CONTRACT
                    ? positionInfo.employmentContractType === null
                        ? 'სავალდებულოა არჩევა'
                        : undefined
                    : undefined,
                contractContinuationType: positionInfo.assignType && Number(positionInfo.assignType.id) === types_1.AssignType.CONTRACT_EXT
                    ? positionInfo.contractContinuationType === null
                        ? 'სავალდებულოა არჩევა'
                        : undefined
                    : undefined,
                workPlaces: (0, validates_1.requiredNotEmptyArray)(positionInfo.workPlaces),
                startDate: !startDate
                    ? (0, validates_1.required)(positionInfo.startDate)
                    : today > startDate
                        ? 'არჩეული თარიღი ნაკლები ან ტოლია დღევანდელ თარიღზე!'
                        : undefined,
                basicFunctionDuties: (0, validates_1.required)(positionInfo.basicFunctionDuties),
                projectInfoErrors: positionInfo.projectInfos.map(projectInfo => {
                    return {
                        project: (0, validates_1.required)(projectInfo.project),
                        workTypesInfoErrors: projectInfo.workInfos.map(workInfo => {
                            return {
                                workload: (0, validates_1.required)(workInfo.workload),
                                salaryType: (0, validates_1.required)(workInfo.salaryType),
                                salaryAmount: workInfo.salaryType && Number(workInfo.salaryType.id) === types_1.SalaryType.FIXED ? (0, validates_1.requiredPositiveNumber)(workInfo.salaryAmount) : undefined,
                                equivalentCurrency: workInfo.salaryType && Number(workInfo.salaryType.id) === types_1.SalaryType.FIXED ? (0, validates_1.required)(workInfo.equivalentCurrency) : undefined,
                                currency: workInfo.salaryType && Number(workInfo.salaryType.id) === types_1.SalaryType.FIXED ? (0, validates_1.required)(workInfo.currency) : undefined,
                                salaryGivenType: workInfo.salaryType && Number(workInfo.salaryType.id) === types_1.SalaryType.FIXED ? (0, validates_1.required)(workInfo.salaryGivenType) : undefined,
                                workType: workInfo.salaryType && Number(workInfo.salaryType.id) === types_1.SalaryType.BY_JOB ? (0, validates_1.required)(workInfo.workType) : undefined,
                                remunerationWork: workInfo.salaryType && Number(workInfo.salaryType.id) === types_1.SalaryType.BY_JOB ? (0, validates_1.required)(workInfo.remunerationWork) : undefined,
                                workTypeInfoItems: workInfo.salaryType && Number(workInfo.salaryType.id) !== types_1.SalaryType.FIXED
                                    ? Number(workInfo.salaryType.id) !== types_1.SalaryType.BY_JOB ? (0, assignSubmissionItemForm_1.transformWorkInfoItemErrors)(workInfo, positionInfo.startDate, positionInfo.endDate)
                                        : workInfo.remunerationWork ? (0, assignSubmissionItemForm_1.transformWorkInfoItemErrors)(workInfo, positionInfo.startDate, positionInfo.endDate) : []
                                    : []
                            };
                        })
                    };
                })
            };
        })
    };
};
const assignSubmissionVerifyFormValidation = (values, selectedProjects) => {
    const formErrors = {
        assignSubmissionItemsErrors: values.employees.map(item => validateSubmissionVerifyEmployee(item))
    };
    const errors = {};
    Object.entries(formErrors).forEach(() => {
        formErrors.assignSubmissionItemsErrors = formErrors.assignSubmissionItemsErrors?.map(el => {
            if (el && (el.firstname
                || el.lastname
                || el.passportNumber
                || el.country
                || el.residenceCertificateNumber
                || el.personalNumber
                || el.idCardValidityDate
                || el.passportValidityDate
                || el.residenceCertificateDate
                || el.residencyValidityDate
                || el.phoneNumber
                || el.email
                || el.taxReliefAmount
                || el.errorTaxOptions
                || (el?.positionInfoErrors).some(positionInfo => positionInfo.position
                    || positionInfo.startDate
                    || positionInfo.workPlaces
                    || positionInfo.assignType
                    || positionInfo.positionType
                    || positionInfo.employmentContractType
                    || positionInfo.contractContinuationType
                    || positionInfo.basicFunctionDuties
                    || positionInfo.projectInfoErrors.some(projectInfo => projectInfo.project
                        || projectInfo.workTypesInfoErrors.some(workType => workType.workload
                            || workType.salaryType
                            || workType.equivalentCurrency
                            || workType.salaryAmount
                            || workType.salaryGivenType
                            || workType.workType
                            || workType.remunerationWork
                            || workType.workTypeInfoItems.some(item => item.months
                                || item.dateRange
                                || item.salaryAmount
                                || item.salaryGivenType
                                || item.supplementAmount
                                || item.unitName
                                || item.amount
                                || item.equivalentCurrency
                                || item.administrativeOrdersArticle
                                || item.tariff
                                || item.volumeWorkPerformed
                                || item.year)))))) {
                return el;
            }
            return;
        });
        if (formErrors.assignSubmissionItemsErrors.some(err => err)) {
            errors.assignSubmissionItemsErrors = formErrors.assignSubmissionItemsErrors;
        }
    });
    if (selectedProjects) {
        const selectedProjectEmployeeErrors = errors.assignSubmissionItemsErrors?.map((el, index) => {
            const selectedAtLeastOneProjectEmployeeIndexes = [];
            values.employees.forEach((el, index) => {
                el.positionInfos.some(position => {
                    return position.projectInfos.some(project => {
                        if (selectedProjects?.includes(project.id)) {
                            selectedAtLeastOneProjectEmployeeIndexes.push(index);
                            return true;
                        }
                        return false;
                    });
                });
            });
            return selectedAtLeastOneProjectEmployeeIndexes.includes(index) ? el : undefined;
        });
        return selectedProjectEmployeeErrors?.some(el => el)
            ? { assignSubmissionItemsErrors: selectedProjectEmployeeErrors }
            : {};
    }
    return errors;
};
exports.assignSubmissionVerifyFormValidation = assignSubmissionVerifyFormValidation;
const checkPersonalNumberValidity = (item) => {
    if (item.citizen === types_1.Citizen.FOREIGN) {
        return item.residenceCertificateNumber || item.residencyValidityDate ? (0, validates_1.requiredPersonalNumber)(item.personalNumber) : undefined;
    }
    else {
        return (0, validates_1.requiredPersonalNumber)(item.personalNumber);
    }
};
const checkIdCardDateValidity = (item) => {
    if (item.citizen === types_1.Citizen.FOREIGN) {
        return undefined;
    }
    else {
        return item.idCardValidityDate || (!item.passportNumber && !item.passportValidityDate) ? (0, validates_1.requiredDateRange)(item.idCardValidityDate) : undefined;
    }
};
const checkPassportNumberValidity = (item) => {
    if (item.citizen === types_1.Citizen.FOREIGN) {
        return (0, validates_1.required)(item.passportNumber);
    }
    else {
        return item.passportValidityDate || !item.idCardValidityDate ? (0, validates_1.required)(item.passportNumber) : undefined;
    }
};
const checkPassportDateValidity = (item) => {
    if (item.citizen === types_1.Citizen.FOREIGN) {
        return (0, validates_1.requiredDateRange)(item.passportValidityDate);
    }
    else {
        return item.passportValidityDate || item.passportNumber ? (0, validates_1.requiredDateRange)(item.passportValidityDate) : undefined;
    }
};
const checkResidencyDateValidity = (item) => {
    if (item.citizen === types_1.Citizen.FOREIGN) {
        return item.residencyValidityDate || item.personalNumber || item.residencyValidityDate || item.residenceCertificateNumber ? (0, validates_1.requiredDateRange)(item.residencyValidityDate) : undefined;
    }
    else {
        return undefined;
    }
};
const checkResidenceCertificateNumberValidity = (item) => {
    if (item.citizen === types_1.Citizen.FOREIGN) {
        return item.personalNumber || item.residencyValidityDate ? (0, validates_1.required)(item.residenceCertificateNumber) : undefined;
    }
    else {
        return undefined;
    }
};
