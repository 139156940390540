"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const StickyFooter = (props) => {
    const [isSticky, setIsSticky] = (0, react_1.useState)(false);
    const [width, setWidth] = (0, react_1.useState)(undefined);
    const ref = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry) {
                setIsSticky(!entry.isIntersecting);
                setWidth(entry.boundingClientRect.width);
            }
        });
        if (ref.current)
            observer.observe(ref.current);
        return () => observer.disconnect();
    }, [ref]);
    return ((0, jsx_runtime_1.jsx)("div", { ref: ref, children: (0, jsx_runtime_1.jsx)("div", { className: `text-right bottom-0 position-${isSticky
                ? 'fixed'
                : 'static'}`, style: { zIndex: 999 }, children: (0, jsx_runtime_1.jsx)("div", { className: `${isSticky ? 'shadow-sm p-3 mb-3 rounded sticky-bg' : 'bg-transparent'}`, style: { width: width, backgroundColor: isSticky ? 'rgba(255, 255, 255, 0.9)' : 'transparent' }, children: props.children }) }) }));
};
exports.default = StickyFooter;
