"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePermissions = void 0;
const index_1 = require("api/index");
const permissions_1 = require("../store/permissions");
const helpers_1 = require("../helpers");
const queryString_1 = require("core/helpers/queryString");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const usePermissions = (props) => {
    const [state, dispatch] = (0, permissions_1.usePermissionReducer)();
    const [listingParams, setListingParams] = (0, react_1.useState)('');
    const location = (0, react_router_dom_1.useLocation)();
    const getAclPermissions = async (params) => {
        dispatch({ type: 'SEND_PERMISSIONS_REQUEST' });
        const permissions = await index_1.PermissionApi.getAclPermissions(params);
        dispatch({ type: 'DONE_PERMISSIONS_REQUEST', payload: permissions });
    };
    const getRolePermissions = async () => {
        dispatch({ type: 'SEND_ROLE_PERMISSIONS_REQUEST' });
        const permissions = await index_1.RolesApi.getRolePermissions(props.id);
        dispatch({ type: 'DONE_ROLE_PERMISSIONS_REQUEST', payload: permissions });
    };
    const updateRolePermissions = async (id, values) => {
        const params = (0, helpers_1.parseRolePermissionParams)(values.permissionGroups);
        dispatch({ type: 'SEND_ROLE_PERMISSIONS_UPDATE' });
        const permissions = await index_1.RolesApi.updateRolePermissions(id, params);
        dispatch({ type: 'DONE_ROLE_PERMISSIONS_UPDATE', payload: permissions });
        return permissions;
    };
    (0, react_1.useEffect)(() => {
        const params = (0, helpers_1.getParamsFromUrl)(location.search);
        const searchParams = (0, queryString_1.stringify)({ ...params });
        setListingParams(searchParams || '?');
        getAclPermissions({}).then(() => getRolePermissions().then());
    }, []);
    return { state, updateRolePermissions: updateRolePermissions, listingParams };
};
exports.usePermissions = usePermissions;
exports.default = exports.usePermissions;
