"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const district_1 = require("../store/district");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useDistrict = (props) => {
    const [state, dispatch] = (0, district_1.useDistrictReducer)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const getDistrict = async (id) => {
        dispatch({ type: 'SEND_DISTRICT_REQUEST' });
        const district = await index_1.DistrictsApi.getDistrict(id);
        dispatch({ type: 'DONE_DISTRICT_REQUEST', payload: district });
    };
    const createDistrict = async (values) => {
        const params = (0, helpers_1.transformDistrictParams)(values);
        dispatch({ type: 'SEND_DISTRICT_CREATE' });
        const district = await index_1.DistrictsApi.createDistrict(params);
        dispatch({ type: 'DONE_DISTRICT_CREATE', payload: district });
        if (district.data !== null && !props.sideBar) {
            navigate(`/references/addresses/districts/${district.data.id}/edit`);
        }
        return district;
    };
    const updateDistrict = async (id, values) => {
        const params = (0, helpers_1.transformDistrictParams)(values);
        dispatch({ type: 'SEND_DISTRICT_UPDATE' });
        const district = await index_1.DistrictsApi.updateDistrict(id, params);
        dispatch({ type: 'DONE_DISTRICT_UPDATE', payload: district });
        return district;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getDistrict(props.id).then(); }, [props.id]);
    return {
        state,
        getDistrict,
        updateDistrict,
        createDistrict,
        closeErrorMessage
    };
};
exports.default = useDistrict;
