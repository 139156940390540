"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const ProcurementStatistics = (props) => {
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: props.procurements.length === 0
            ? ((0, jsx_runtime_1.jsx)("div", { className: 'alert alert-warning text-center', children: "\u10E9\u10D0\u10DC\u10D0\u10EC\u10D4\u10E0\u10D8 \u10D5\u10D4\u10E0 \u10DB\u10DD\u10D8\u10EB\u10D4\u10D1\u10DC\u10D0" }))
            : ((0, jsx_runtime_1.jsxs)("table", { className: 'table', children: [(0, jsx_runtime_1.jsx)("thead", { children: (0, jsx_runtime_1.jsxs)("tr", { children: [(0, jsx_runtime_1.jsx)("th", { scope: 'col', children: "#" }), (0, jsx_runtime_1.jsx)("th", { scope: 'col', children: "\u10D9\u10DD\u10DB\u10DE\u10D0\u10DC\u10D8\u10D8\u10E1 \u10E1\u10D0\u10EE\u10D4\u10DA\u10D8" }), (0, jsx_runtime_1.jsx)("th", { scope: 'col', children: "\u10D9\u10DD\u10DC\u10E2\u10E0\u10D0\u10E5\u10E2\u10D8\u10E1 #" }), (0, jsx_runtime_1.jsx)("th", { scope: 'col', children: "\u10E0\u10D4\u10D2\u10D8\u10E1\u10E2\u10E0\u10D0\u10EA\u10D8\u10D8\u10E1 \u10D7\u10D0\u10E0\u10D8\u10E6\u10D8" }), (0, jsx_runtime_1.jsx)("th", { scope: 'col', children: "\u10D9\u10DD\u10DC\u10E2\u10E0\u10D0\u10E5\u10E2\u10D8\u10E1 \u10E2\u10D8\u10DE\u10D8" }), (0, jsx_runtime_1.jsx)("th", { scope: 'col', children: "\u10DE\u10D8\u10E0\u10DD\u10D1\u10D8\u10D7\u10D8 \u10DC\u10DD\u10DB\u10D4\u10E0\u10D8" }), (0, jsx_runtime_1.jsx)("th", { scope: 'col', children: "\u10EF\u10D0\u10DB\u10D8" })] }) }), (0, jsx_runtime_1.jsx)("tbody", { children: props.procurements.map((el, index) => {
                            return ((0, jsx_runtime_1.jsxs)("tr", { children: [(0, jsx_runtime_1.jsx)("th", { scope: 'row', children: index + 1 }), (0, jsx_runtime_1.jsx)("td", { children: el.companyName }), (0, jsx_runtime_1.jsx)("td", { children: el.contractNumber }), (0, jsx_runtime_1.jsx)("td", { children: el.registrationDate }), (0, jsx_runtime_1.jsx)("td", { children: el.contractType }), (0, jsx_runtime_1.jsx)("td", { children: el.conditionalNumber }), (0, jsx_runtime_1.jsx)("td", { children: el.sumPrice })] }, el.id.toString()));
                        }) })] })) }));
};
exports.default = ProcurementStatistics;
