"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getProcurementStatisticsByItemProjects = exports.getProcurementStatisticsByItemBudgetArticles = exports.getProcurementStatisticsByItemCategories = exports.getProcurementStatisticsByItems = exports.exportProcurementStatistics = exports.getProcurementStatistics = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/reports/purchases/parsers");
const getProcurementStatistics = async (params) => {
    const response = await Api.get('statistics/documents/purchases/procurements/get-procurements', params);
    return (0, parsers_1.parseProcurementStatistics)(response);
};
exports.getProcurementStatistics = getProcurementStatistics;
const exportProcurementStatistics = async (params) => {
    const response = await Api.get('statistics/documents/purchases/procurements/export', params);
    return (0, parsers_1.parseProcurementStatisticFile)(response);
};
exports.exportProcurementStatistics = exportProcurementStatistics;
const getProcurementStatisticsByItems = async (params) => {
    const response = await Api.get('statistics/documents/purchases/procurements/get-procurement-items', params);
    return (0, parsers_1.parseProcurementStatisticsItems)(response);
};
exports.getProcurementStatisticsByItems = getProcurementStatisticsByItems;
const getProcurementStatisticsByItemCategories = async (params) => {
    const response = await Api.get('statistics/documents/purchases/procurements/get-procurement-item-categories', params);
    return (0, parsers_1.parseProcurementStatisticCategories)(response);
};
exports.getProcurementStatisticsByItemCategories = getProcurementStatisticsByItemCategories;
const getProcurementStatisticsByItemBudgetArticles = async (params) => {
    const response = await Api.get('statistics/documents/purchases/procurements/get-procurement-item-budget-articles', params);
    return (0, parsers_1.parseProcurementStatisticBudgetArticles)(response);
};
exports.getProcurementStatisticsByItemBudgetArticles = getProcurementStatisticsByItemBudgetArticles;
const getProcurementStatisticsByItemProjects = async (params) => {
    const response = await Api.get('statistics/documents/purchases/procurements/get-procurement-item-projects', params);
    return (0, parsers_1.parseProcurementStatisticProjects)(response);
};
exports.getProcurementStatisticsByItemProjects = getProcurementStatisticsByItemProjects;
