"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAssignSubmissionVerifyForm = void 0;
const react_1 = require("react");
const useHRSubmissionVerify_1 = require("../store/useHRSubmissionVerify");
const index_1 = require("api/index");
const helpers_1 = require("../helpers");
const store_1 = require("core/store");
const queryString_1 = require("core/helpers/queryString");
const react_router_dom_1 = require("react-router-dom");
const useAssignSubmissionVerifyForm = (props) => {
    const [state, dispatch] = (0, useHRSubmissionVerify_1.useHRAssignSubmissionVerifyReducer)();
    const [declineComment, setDeclineComment] = (0, react_1.useState)('');
    const [showDeclineAlert, setShowDeclineAlert] = (0, react_1.useState)(false);
    const [showSweetAlert, setShowSweetAlert] = (0, react_1.useState)(false);
    const [listingParams, setListingParams] = (0, react_1.useState)('');
    const [buttonNavigate, setButtonNavigate] = (0, react_1.useState)(useHRSubmissionVerify_1.initialAssignSubmissionVerifyNavigate);
    const [assignSubmissionVerifyParamsIds, setAssignSubmissionVerifyParamsIds] = (0, react_1.useState)({ assignSubmissionVerifyId: [], meta: store_1.initialMeta });
    const ref = (0, react_1.useRef)(null);
    const [isSticky, setSticky] = (0, react_1.useState)(false);
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const handleScroll = () => {
        if (ref.current) {
            setSticky(ref.current.getBoundingClientRect().bottom <= 150);
        }
    };
    (0, react_1.useEffect)(() => {
        if (state.data.isVerifySend) {
            window.addEventListener('scroll', handleScroll);
        }
        else {
            window.removeEventListener('scroll', () => handleScroll);
        }
    }, [state.data.isVerifySend]);
    const getSubmissionVerify = async (id) => {
        dispatch({ type: 'SEND_HR_SUBMISSION_VERIFY_REQUEST' });
        const submissionVerifyData = await index_1.HumanResourcesSubmissionVerifiesApi.getHrAssignSubmissionVerify(id);
        dispatch({ type: 'DONE_HR_SUBMISSION_VERIFY_REQUEST', payload: submissionVerifyData });
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    const showDeclineConfirm = () => {
        setShowDeclineAlert(prevShow => !prevShow);
    };
    const declineHRSubmissionVerify = async (confirm, comment) => {
        setShowDeclineAlert(false);
        if (confirm) {
            dispatch({ type: 'SEND_HR_SUBMISSION_VERIFY_DECLINE' });
            const HRSubmissionVerify = await index_1.HumanResourcesSubmissionVerifiesApi.declineHRSubmissionVerify(props.id, { declineComment: comment });
            dispatch({ type: 'DONE_HR_SUBMISSION_VERIFY_DECLINE', payload: HRSubmissionVerify });
        }
    };
    const sendForVerify = async (id) => {
        dispatch({ type: 'SEND_HR_SUBMISSION_VERIFY_SEND' });
        const HRSubmissionVerify = await index_1.HumanResourcesSubmissionVerifiesApi.sendForVerify(id);
        dispatch({ type: 'DONE_HR_SUBMISSION_VERIFY_SEND', payload: HRSubmissionVerify });
        return HRSubmissionVerify;
    };
    const getAssignSubmissionVerifyIds = async (values) => {
        const assignSubmissionVerifyParams = (0, helpers_1.transformAssignSubmissionVerifyParamsToFilterParams)({ ...values });
        const itemsParams = (0, helpers_1.getAssignSubmissionVerifyItemsParamsFromUrl)(location.search);
        const humanResourceSubmissionVerifies = await index_1.HumanResourcesSubmissionVerifiesApi.getHumanResourceSubmissionVerifies(assignSubmissionVerifyParams);
        if (humanResourceSubmissionVerifies.data !== null && humanResourceSubmissionVerifies.meta) {
            const responseIds = {
                assignSubmissionVerifyId: humanResourceSubmissionVerifies.data.map(el => el.id),
                meta: humanResourceSubmissionVerifies.meta
            };
            const searchParams = (0, queryString_1.stringify)({ ...(0, helpers_1.transformAssignSubmissionVerifyFilterParamsToUrl)(values), ...itemsParams });
            setListingParams(searchParams || '?');
            setAssignSubmissionVerifyParamsIds(responseIds);
            return responseIds;
        }
        return null;
    };
    const nextAssignSubmissionVerify = async () => {
        setButtonNavigate(prevState => ({ ...prevState, next: true, isLoading: true }));
        const currentIndex = assignSubmissionVerifyParamsIds.assignSubmissionVerifyId.indexOf(props.id);
        const nextAssignSubmissionVerify = assignSubmissionVerifyParamsIds.assignSubmissionVerifyId[currentIndex + 1];
        const { pagination } = assignSubmissionVerifyParamsIds.meta;
        const params = (0, helpers_1.transformAssignSubmissionVerifyFilterParamsToUrl)((0, helpers_1.transformUrlToAssignSubmissionVerifyFilterParams)(location.search));
        if (nextAssignSubmissionVerify) {
            const itemsParams = (0, helpers_1.getAssignSubmissionVerifyItemsParamsFromUrl)(location.search);
            navigate('/documents/human-resources/assign-submission-verifies/' + nextAssignSubmissionVerify + '/edit' + (0, queryString_1.stringify)({ ...params, ...itemsParams }));
            const lastElement = pagination.currentPage === pagination.totalPages && assignSubmissionVerifyParamsIds.assignSubmissionVerifyId.length === currentIndex + 2;
            setButtonNavigate(prevState => ({ ...prevState, next: false, firstElement: false, lastElement, isLoading: false }));
        }
        else {
            if (pagination.currentPage === pagination.totalPages) {
                setButtonNavigate(prevState => ({ ...prevState, next: false, lastElement: true, isLoading: false }));
                return;
            }
            const formValues = (0, helpers_1.transformUrlToAssignSubmissionVerifyFilterParams)(location.search);
            const response = await getAssignSubmissionVerifyIds({ ...formValues, page: pagination.currentPage + 1 });
            if (response && response.assignSubmissionVerifyId[0]) {
                const itemsParams = (0, helpers_1.getAssignSubmissionVerifyItemsParamsFromUrl)(location.search);
                formValues.page = response.meta.pagination.currentPage;
                const urlParams = { ...(0, helpers_1.transformAssignSubmissionVerifyFilterParamsToUrl)(formValues), page: response.meta.pagination.currentPage, ...itemsParams };
                navigate('/documents/human-resources/assign-submission-verifies/' + response.assignSubmissionVerifyId[0] + '/edit' + (0, queryString_1.stringify)(urlParams));
                const lastElement = response.meta.pagination.currentPage === response.meta.pagination.totalPages && response.assignSubmissionVerifyId.length === 1;
                setButtonNavigate(prevState => ({ ...prevState, next: false, firstElement: false, lastElement, isLoading: false }));
            }
        }
    };
    const previousAssignSubmissionVerify = async () => {
        setButtonNavigate(prevState => ({ ...prevState, previous: true, isLoading: true }));
        const currentIndex = assignSubmissionVerifyParamsIds.assignSubmissionVerifyId.indexOf(props.id);
        const prevAssignSubmissionVerify = assignSubmissionVerifyParamsIds.assignSubmissionVerifyId[currentIndex - 1];
        const { pagination } = assignSubmissionVerifyParamsIds.meta;
        const params = (0, helpers_1.transformAssignSubmissionVerifyFilterParamsToUrl)((0, helpers_1.transformUrlToAssignSubmissionVerifyFilterParams)(location.search));
        if (prevAssignSubmissionVerify) {
            const itemsParams = (0, helpers_1.getAssignSubmissionVerifyItemsParamsFromUrl)(location.search);
            navigate('/documents/human-resources/assign-submission-verifies/' + prevAssignSubmissionVerify + '/edit' + (0, queryString_1.stringify)({ ...params, ...itemsParams }));
            const firstElement = pagination.currentPage === 1 && currentIndex === 1;
            setButtonNavigate(prevState => ({ ...prevState, previous: false, lastElement: false, firstElement, isLoading: false }));
        }
        else {
            if (pagination.currentPage === 1) {
                setButtonNavigate(prevState => ({ ...prevState, previous: false, firstElement: true, isLoading: false }));
                return;
            }
            const formValues = (0, helpers_1.transformUrlToAssignSubmissionVerifyFilterParams)(location.search);
            const response = await getAssignSubmissionVerifyIds({ ...formValues, page: pagination.currentPage - 1 });
            if (response) {
                const previousAssignSubmissionVerify = response.assignSubmissionVerifyId[response.assignSubmissionVerifyId.length - 1];
                if (previousAssignSubmissionVerify) {
                    formValues.page = response.meta.pagination.currentPage;
                    const itemsParams = (0, helpers_1.getAssignSubmissionVerifyItemsParamsFromUrl)(location.search);
                    const urlParams = { ...(0, helpers_1.transformAssignSubmissionVerifyFilterParamsToUrl)(formValues), ...itemsParams };
                    navigate('/documents/human-resources/assign-submission-verifies/' + previousAssignSubmissionVerify + '/edit' + (0, queryString_1.stringify)(urlParams));
                    const firstElement = response.meta.pagination.currentPage === 1 && currentIndex === 1;
                    setButtonNavigate(prevState => ({ ...prevState, previous: false, lastElement: false, firstElement, isLoading: false }));
                }
            }
        }
    };
    (0, react_1.useEffect)(() => {
        const params = (0, helpers_1.transformUrlToAssignSubmissionVerifyFilterParams)(location.search);
        getAssignSubmissionVerifyIds(params).then(response => {
            if (response) {
                const { currentPage, totalPages } = response.meta.pagination;
                const assignVerifyIndex = response.assignSubmissionVerifyId.indexOf(props.id);
                if (assignVerifyIndex === 0 && currentPage === 1)
                    setButtonNavigate(prevState => ({ ...prevState, firstElement: true }));
                if (currentPage === totalPages && assignVerifyIndex === response.assignSubmissionVerifyId.length - 1) {
                    setButtonNavigate(prevState => ({ ...prevState, lastElement: true }));
                }
            }
        });
    }, []);
    (0, react_1.useEffect)(() => { if (props.id)
        getSubmissionVerify(props.id).then(); }, [props.id]);
    return {
        state,
        closeErrorMessage,
        declineComment,
        setDeclineComment,
        showDeclineConfirm,
        showDeclineAlert,
        declineHRSubmissionVerify,
        setShowSweetAlert,
        showSweetAlert,
        sendForVerify,
        getSubmissionVerify,
        listingParams,
        buttonNavigate,
        ref,
        isSticky,
        nextAssignSubmissionVerify,
        previousAssignSubmissionVerify
    };
};
exports.useAssignSubmissionVerifyForm = useAssignSubmissionVerifyForm;
