"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectsReducer = exports.useProjectsReducer = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
const initialProjects = {
    data: [],
    status: null,
    meta: store_1.initialMeta,
    filterForm: {},
    errors: null,
    isFetching: true,
    isDeleting: false,
    isUpdatingPerformers: false,
    isFetched: false
};
const useProjectsReducer = () => {
    return (0, react_1.useReducer)(exports.ProjectsReducer, initialProjects);
};
exports.useProjectsReducer = useProjectsReducer;
const ProjectsReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_PROJECTS_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_PROJECTS_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                status: action.payload.status,
                errors: action.payload.errors,
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'SEND_DELETE_REQUEST':
            return {
                ...state,
                isDeleting: true
            };
        case 'DONE_DELETE_REQUEST':
            return {
                ...state,
                errors: action.payload.errors,
                isDeleting: false
            };
        case 'SEND_PROJECT_PERFORMERS_UPDATE':
            return {
                ...state,
                isUpdatingPerformers: true
            };
        case 'DONE_PROJECT_PERFORMERS_UPDATE':
            return {
                ...state,
                data: (action.payload.data && state.data.map(el => {
                    const project = action.payload.data.find(item => el.id === item.projectId);
                    if (project) {
                        return {
                            ...el,
                            userFullNames: project.userFullNames
                        };
                    }
                    else {
                        return el;
                    }
                })) || state.data,
                isUpdatingPerformers: false
            };
        case 'CHANGE_PER_PAGE':
            return {
                ...state,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        perPage: action.payload.perPage
                    }
                },
                filterForm: action.payload
            };
        case 'INITIALIZE_FILTER_FORM':
            return {
                ...state,
                filterForm: action.payload,
                isFetched: true
            };
        default:
            return state;
    }
};
exports.ProjectsReducer = ProjectsReducer;
