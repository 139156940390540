"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useBankSidebar = () => {
    const [openedBankSidebar, setOpenedBankSidebar] = (0, react_1.useState)(false);
    const setBankSideBar = (isOpen) => {
        setOpenedBankSidebar(() => {
            if (isOpen)
                window.addEventListener('click', closeEmployeeBankSideBar, true);
            return isOpen;
        });
    };
    const closeSideBar = () => {
        window.removeEventListener('click', closeEmployeeBankSideBar, true);
        setOpenedBankSidebar(false);
    };
    const closeEmployeeBankSideBar = (event) => {
        const isBank = document.querySelector('#bank-menu')?.contains(event.target);
        const isBankSidebarAlert = document.querySelector('#bankSideBarAlert')?.contains(event.target);
        if (isBank || isBankSidebarAlert)
            return;
        window.removeEventListener('click', closeEmployeeBankSideBar, true);
        setOpenedBankSidebar(false);
    };
    return {
        setBankSideBar,
        closeSideBar,
        openedBankSidebar
    };
};
exports.default = useBankSidebar;
