"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const index_1 = require("api/index");
const constants_1 = require("src/components/files/helpers/constants");
const react_1 = require("react");
const useMediaFileUploads = (props) => {
    const [loading, setLoading] = (0, react_1.useState)(false);
    const [openedFilePreview, setOpenedFilePreview] = (0, react_1.useState)(null);
    const [loadingFileIds, setLoadingFileIds] = (0, react_1.useState)([]);
    const [uploading, setUploading] = (0, react_1.useState)(false);
    const [isGettingFileProperties, setIsGettingFileProperties] = (0, react_1.useState)([]);
    const uploadFiles = async (e) => {
        const files = e.target.files;
        if (!files || files.length === 0) {
            return {
                status: apiGlobalTypes_1.ResponseStatuses.UNEXPECTED,
                data: null,
                errors: [{ message: 'გთხოვთ ატვირთოთ დაშვებული გაფართოების ფაილები', name: 'unexpected' }]
            };
        }
        setLoading(true);
        const formData = new FormData();
        // for (let i = 0; i < files.length; i++) {
        formData.append('file', files[0]);
        formData.append('uid', '');
        // }
        const filesData = await index_1.FilesApi.uploadFiles(formData, props.url);
        setLoading(false);
        return filesData;
    };
    const downloadFile = async (uid) => {
        setLoadingFileIds(prevState => [...prevState, uid]);
        const file = await index_1.FilesApi.downloadFile(uid);
        setLoadingFileIds(prevState => prevState.filter(el => el !== uid));
        if (file.data) {
            const link = document.createElement('a');
            link.href = file.data.link;
            if (!file.data.downloadableFile)
                link.target = '_blank';
            link.download = file.data.originalName;
            link.click();
        }
    };
    const handleOpenFile = async (file, isFacsimile) => {
        setIsGettingFileProperties([...isGettingFileProperties, file.id]);
        if (isFacsimile
            ? constants_1.allowedZoomExtensions.find(item => item === file.extension)
            : constants_1.fileExtensions.find(item => item.extension === file.extension && !item.allowDownload)) {
            const previewFile = await getFileProperties(file.id, file.extension);
            if (previewFile?.data)
                setOpenedFilePreview({ ...previewFile, data: { ...previewFile?.data, link: previewFile.data.link + '#toolbar=0&navpanes=0&scrollbar=0' } });
        }
        setIsGettingFileProperties(isGettingFileProperties.filter(el => el === file.id));
    };
    const uploadFileWithDownloadLink = async (e) => {
        const files = e.target.files;
        if (!files || files.length === 0) {
            return {
                status: apiGlobalTypes_1.ResponseStatuses.UNEXPECTED,
                data: null,
                errors: [{ message: 'ფაილი ვერ აიტვირთა', name: 'unexpected' }]
            };
        }
        setUploading(true);
        const formData = new FormData();
        formData.append('file', files[0]);
        formData.append('uid', '');
        const filesData = await index_1.FilesApi.uploadFiles(formData, props.url);
        if (filesData.data && filesData.data.status !== apiGlobalTypes_1.FileDownloadStatus.CONFIRMED) {
            const response = await getFileData(filesData.data.id);
            setUploading(false);
            return response;
        }
        setUploading(false);
        return filesData;
    };
    const getFileData = async (id, step = 1) => {
        const downloadFile = await index_1.FilesApi.previewFile(id);
        if (step > 3)
            return downloadFile;
        if (downloadFile.data && downloadFile.data.status !== apiGlobalTypes_1.FileDownloadStatus.CONFIRMED) {
            await new Promise(resolve => setTimeout(resolve, 2000));
            return await getFileData(id, step + 1);
        }
        return downloadFile;
    };
    const getFileProperties = async (uid, extension) => {
        setIsGettingFileProperties([...isGettingFileProperties, uid]);
        if (constants_1.fileExtensions.find(file => file.extension === extension && !file.allowDownload)) {
            const file = await index_1.FilesApi.previewFile(uid);
            setUploading(false);
            setIsGettingFileProperties(isGettingFileProperties.filter(el => el === uid));
            return file;
        }
        setIsGettingFileProperties(isGettingFileProperties.filter(el => el === uid));
        return null;
    };
    return {
        uploadFiles,
        loadingFileIds,
        downloadFile,
        loading,
        openedFilePreview,
        setOpenedFilePreview,
        getFileProperties,
        uploadFileWithDownloadLink,
        uploading,
        isGettingFileProperties,
        handleOpenFile
    };
};
exports.default = useMediaFileUploads;
