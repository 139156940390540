"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.detachRoleToStructuralUnit = exports.attachRoleToStructuralUnit = exports.updateRolePermissions = exports.getStructuralUnitRoles = exports.getRolePermissions = exports.updateRole = exports.createRole = exports.getRole = exports.getRoles = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("../../privateRequest"));
const parsers_1 = require("api/administer/roles/parsers");
const getRoles = async (params) => {
    const response = await Api.get('acl/roles', params);
    return (0, parsers_1.parseRoles)(response);
};
exports.getRoles = getRoles;
const getRole = async (id) => {
    const response = await Api.get(`acl/roles/${id}`);
    return (0, parsers_1.parseRole)(response);
};
exports.getRole = getRole;
const createRole = async (params) => {
    const response = await Api.post('acl/roles', params);
    return (0, parsers_1.parseRole)(response);
};
exports.createRole = createRole;
const updateRole = async (id, params) => {
    const response = await Api.patch('acl/roles/' + id, params);
    return (0, parsers_1.parseRole)(response);
};
exports.updateRole = updateRole;
const getRolePermissions = async (roleId) => {
    const response = await Api.get('acl/permissions', { filters: { roleId: roleId } });
    return (0, parsers_1.parseRolePermissions)(response);
};
exports.getRolePermissions = getRolePermissions;
const getStructuralUnitRoles = async (params) => {
    const response = await Api.get('acl/roles', params);
    return (0, parsers_1.parseRolePermissions)(response);
};
exports.getStructuralUnitRoles = getStructuralUnitRoles;
const updateRolePermissions = async (id, params) => {
    const response = await Api.patch(`acl/permissions/attach/role/${id}`, params);
    return (0, parsers_1.parseRolePermissions)(response);
};
exports.updateRolePermissions = updateRolePermissions;
const attachRoleToStructuralUnit = async (structuralUnitId, params) => {
    const response = await Api.patch(`acl/roles/attach/structural-unit/${structuralUnitId}`, params);
    return (0, parsers_1.parseAttachedRoles)(response);
};
exports.attachRoleToStructuralUnit = attachRoleToStructuralUnit;
const detachRoleToStructuralUnit = async (structuralUnitId, params) => {
    const response = await Api.deleteItem(`acl/roles/detach/structural-unit/${structuralUnitId}`, params);
    return (0, parsers_1.parseAttachedRoles)(response);
};
exports.detachRoleToStructuralUnit = detachRoleToStructuralUnit;
