"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyLegalStatuses = exports.LegalForm = exports.CompanyAddressType = void 0;
var CompanyAddressType;
(function (CompanyAddressType) {
    CompanyAddressType[CompanyAddressType["physical"] = 1] = "physical";
    CompanyAddressType[CompanyAddressType["legal"] = 2] = "legal";
})(CompanyAddressType || (exports.CompanyAddressType = CompanyAddressType = {}));
var LegalForm;
(function (LegalForm) {
    LegalForm[LegalForm["juridical"] = 1] = "juridical";
    LegalForm[LegalForm["physical"] = 2] = "physical";
})(LegalForm || (exports.LegalForm = LegalForm = {}));
var CompanyLegalStatuses;
(function (CompanyLegalStatuses) {
    CompanyLegalStatuses[CompanyLegalStatuses["individualEntrepreneur"] = 1] = "individualEntrepreneur";
    CompanyLegalStatuses[CompanyLegalStatuses["JointAndSeveralLiabilityCompany"] = 2] = "JointAndSeveralLiabilityCompany";
    CompanyLegalStatuses[CompanyLegalStatuses["limitedPartnership"] = 3] = "limitedPartnership";
    CompanyLegalStatuses[CompanyLegalStatuses["LimitedLiabilityCompany"] = 4] = "LimitedLiabilityCompany";
    CompanyLegalStatuses[CompanyLegalStatuses["JointStockCompany"] = 5] = "JointStockCompany";
    CompanyLegalStatuses[CompanyLegalStatuses["Cooperative"] = 6] = "Cooperative";
    CompanyLegalStatuses[CompanyLegalStatuses["NonProfitLegalEntity"] = 7] = "NonProfitLegalEntity";
    CompanyLegalStatuses[CompanyLegalStatuses["LegalEntityOfPublicLaw"] = 8] = "LegalEntityOfPublicLaw";
})(CompanyLegalStatuses || (exports.CompanyLegalStatuses = CompanyLegalStatuses = {}));
