"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const index_1 = require("api/index");
const educationalInstitutionsCreate_1 = require("../store/educationalInstitutionsCreate");
const useEducationalInstitution = () => {
    const [state, dispatch] = (0, educationalInstitutionsCreate_1.useEducationalInstitutionReducer)();
    const [startTransition, setStartTransition] = (0, react_1.useState)(false);
    const handleCreateEducationalInstitution = async (value) => {
        dispatch({ type: 'SEND_CREATE_EDUCATIONAL_INSTITUTION_REQUEST' });
        const educationalInstitution = await index_1.EducationalInstitutionApi.createEducationalInstitution({ name: value });
        dispatch({ type: 'DONE_CREATE_EDUCATIONAL_INSTITUTION_REQUEST' });
        return educationalInstitution;
    };
    return {
        startTransition,
        setStartTransition,
        handleCreateEducationalInstitution,
        state
    };
};
exports.default = useEducationalInstitution;
