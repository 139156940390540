"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateWarrantyTerm = exports.createWarrantyTerm = exports.getWarrantyTerm = exports.deleteWarrantyTerm = exports.getWarrantyTerms = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/references/warranty-terms/parsers");
const getWarrantyTerms = async (params) => {
    const response = await Api.get('references/warranty-terms', params);
    return (0, parsers_1.parseWarrantyTerms)(response);
};
exports.getWarrantyTerms = getWarrantyTerms;
const deleteWarrantyTerm = async (id) => {
    return await Api.deleteItem('references/warranty-terms/' + id);
};
exports.deleteWarrantyTerm = deleteWarrantyTerm;
const getWarrantyTerm = async (id) => {
    const response = await Api.get('references/warranty-terms/' + id);
    return (0, parsers_1.parseWarrantyTerm)(response);
};
exports.getWarrantyTerm = getWarrantyTerm;
const createWarrantyTerm = async (params) => {
    const response = await Api.post('references/warranty-terms', params);
    return (0, parsers_1.parseWarrantyTerm)(response);
};
exports.createWarrantyTerm = createWarrantyTerm;
const updateWarrantyTerm = async (id, params) => {
    const response = await Api.patch('references/warranty-terms/' + id, params);
    return (0, parsers_1.parseWarrantyTerm)(response);
};
exports.updateWarrantyTerm = updateWarrantyTerm;
