"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.submissionAutoCompleteReducer = exports.useSubmissionAutoCompleteReducer = exports.initialSubmissionAutoCompleteState = void 0;
const react_1 = require("react");
exports.initialSubmissionAutoCompleteState = {
    data: [],
    isFetching: true
};
const useSubmissionAutoCompleteReducer = () => {
    return (0, react_1.useReducer)(exports.submissionAutoCompleteReducer, exports.initialSubmissionAutoCompleteState);
};
exports.useSubmissionAutoCompleteReducer = useSubmissionAutoCompleteReducer;
const submissionAutoCompleteReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_SUBMISSION_AUTOCOMPLETE_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_SUBMISSION_AUTOCOMPLETE_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                isFetching: false
            };
        default:
            return state;
    }
};
exports.submissionAutoCompleteReducer = submissionAutoCompleteReducer;
