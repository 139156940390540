"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.refreshToken = exports.getAuthUser = exports.loginOut = exports.login = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("../privateRequest"));
const PublicApi = tslib_1.__importStar(require("../requests"));
const types_1 = require("./types");
const parsers_1 = require("./parsers");
const login = async (params) => {
    const response = await PublicApi.post('auth/token', params);
    return (0, parsers_1.parseAuth)(response);
};
exports.login = login;
const loginOut = async () => {
    return await Api.deleteItem('auth/token');
};
exports.loginOut = loginOut;
const getAuthUser = async () => {
    const response = await Api.get('auth/user');
    return (0, parsers_1.parseAuthUser)(response);
};
exports.getAuthUser = getAuthUser;
const refreshToken = async () => {
    const authenticateStatus = localStorage.getItem('authenticate');
    if (authenticateStatus === 'locked')
        return types_1.RefreshTokenStatus.LOCKED;
    localStorage.setItem('authenticate', 'locked');
    const storedToken = localStorage.getItem('token');
    if (!storedToken)
        return types_1.RefreshTokenStatus.EMPTY_TOKEN;
    try {
        const params = {
            grant_type: process.env.API_AUTH_REFRESH_GRANT_TYPE,
            client_id: process.env.API_AUTH_CLIENT_ID,
            refresh_token: JSON.parse(storedToken).refreshToken
        };
        const response = await PublicApi.post('auth/token/refresh', params);
        const responseToken = response.content;
        const token = {
            accessToken: responseToken.access_token,
            refreshToken: responseToken.refresh_token,
            expiresIn: responseToken.expires_in * 1000 + Date.now()
        };
        localStorage.setItem('authenticate', 'unlocked');
        localStorage.setItem('token', JSON.stringify(token));
        return types_1.RefreshTokenStatus.SUCCESS;
    }
    catch (e) {
        return types_1.RefreshTokenStatus.FAILED;
    }
};
exports.refreshToken = refreshToken;
