"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parametersReducer = exports.useUserSettingsReducer = exports.initialParametersState = void 0;
const react_1 = require("react");
exports.initialParametersState = {
    data: {
        locale: 'ka',
        settings: [],
        selectMails: false,
        selectSystems: false
    },
    status: null,
    errors: null,
    isFetching: true,
    isLoading: false,
    isUpdating: false
};
const useUserSettingsReducer = () => {
    return (0, react_1.useReducer)(exports.parametersReducer, exports.initialParametersState);
};
exports.useUserSettingsReducer = useUserSettingsReducer;
const parametersReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_USER_SETTINGS_REQUEST':
            return {
                ...state,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_USER_SETTINGS_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isFetching: false,
                isLoading: state.isUpdating
            };
        case 'SEND_USER_SETTINGS_UPDATE':
            return {
                ...state,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_USER_SETTINGS_UPDATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                isUpdating: false,
                isLoading: state.isFetching
            };
        default:
            return state;
    }
};
exports.parametersReducer = parametersReducer;
