"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAuthHistories = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("../../privateRequest"));
const parsers_1 = require("api/administer/authHistory/parsers");
const getAuthHistories = async (params) => {
    const response = await Api.get('user/auth-logs', params);
    return (0, parsers_1.parseAuthHistories)(response);
};
exports.getAuthHistories = getAuthHistories;
