"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractTypesReducer = exports.useContractTypesReducer = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
const initialContractTypes = {
    data: [],
    status: null,
    errors: null,
    meta: store_1.initialMeta,
    isFetching: true,
    isDeleting: false
};
const useContractTypesReducer = () => {
    return (0, react_1.useReducer)(exports.ContractTypesReducer, initialContractTypes);
};
exports.useContractTypesReducer = useContractTypesReducer;
const ContractTypesReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_CONTRACT_TYPE_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_CONTRACT_TYPE_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                status: action.payload.status,
                errors: action.payload.errors,
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'SEND_DELETE_REQUEST':
            return {
                ...state,
                isDeleting: true
            };
        case 'DONE_DELETE_REQUEST':
            return {
                ...state,
                isDeleting: false,
                errors: action.payload.errors
            };
        case 'CHANGE_PER_PAGE':
            return {
                ...state,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        perPage: action.payload
                    }
                }
            };
        default:
            return state;
    }
};
exports.ContractTypesReducer = ContractTypesReducer;
