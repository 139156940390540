"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const ReadOnlyCompanyBankAccount = (props) => {
    return ((0, jsx_runtime_1.jsx)("div", { className: 'card-body', children: (0, jsx_runtime_1.jsx)("div", { className: 'row', children: (0, jsx_runtime_1.jsx)("div", { className: 'col-md-10 mx-auto', children: (0, jsx_runtime_1.jsxs)("table", { className: 'table table-hover', children: [(0, jsx_runtime_1.jsx)("thead", { children: (0, jsx_runtime_1.jsxs)("tr", { children: [(0, jsx_runtime_1.jsx)("th", { style: { maxWidth: 40 }, children: "#" }), (0, jsx_runtime_1.jsx)("th", { children: "\u10D1\u10D0\u10DC\u10D9\u10D8\u10E1 \u10D3\u10D0\u10E1\u10D0\u10EE\u10D4\u10DA\u10D4\u10D1\u10D0" }), (0, jsx_runtime_1.jsx)("th", { children: "\u10D0\u10DC\u10D2\u10D0\u10E0\u10D8\u10E8\u10D8\u10E1 \u10DC\u10DD\u10DB\u10D4\u10E0\u10D8" }), (0, jsx_runtime_1.jsx)("th", { children: "\u10D1\u10D0\u10DC\u10D9\u10D8\u10E1 \u10D9\u10DD\u10D3\u10D8" })] }) }), (0, jsx_runtime_1.jsx)("tbody", { children: props.companyHook.state.data.bankAccounts?.map((el, index) => {
                                return ((0, jsx_runtime_1.jsxs)("tr", { className: `kt-datatable__row${props.companyHook.state.loadingItems.find(id => el.id === id)
                                        ? ' progress-bar-striped progress-bar-animated bg-light'
                                        : ''}`, children: [(0, jsx_runtime_1.jsx)("td", { className: `${el.status ? '' : 'text-muted'}`, children: index + 1 }), (0, jsx_runtime_1.jsx)("td", { className: `${el.status ? '' : 'text-muted'}`, children: el.name }), (0, jsx_runtime_1.jsx)("td", { className: `${el.status ? '' : 'text-muted'}`, children: el.accountNumber }), (0, jsx_runtime_1.jsx)("td", { className: `${el.status ? '' : 'text-muted'}`, children: el.code })] }, index));
                            }) })] }) }) }) }));
};
exports.default = ReadOnlyCompanyBankAccount;
