"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformProjectStructuralUnitParams = exports.transformProjectPerformersParams = exports.transformProjectFilterParamsToUrl = exports.transformUrlToProjectFilterParams = exports.transformProjectParamsToFilterParams = exports.transformBudgetUsersValues = exports.transformAttachUsersToProjectValues = exports.transformAttachUsersToProjectParams = exports.transformProjectParams = void 0;
const types_1 = require("api/references/funding/projects/types");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const parsers_1 = require("api/parsers/parsers");
const queryString_1 = require("core/helpers/queryString");
const transformProjectParams = (values, headOfDirectionId, coordinatorId) => {
    return {
        donorId: values.donor?.id || null,
        budgetSourceId: values.budgetSource?.id,
        fundingSourceId: values.fundingSource?.id,
        additionalInfo: values.additionalInfo,
        endDate: values.endDate ? (0, parsers_1.transformDateParams)(values.endDate) : null,
        coordinatorId: coordinatorId,
        coordinatorName: !coordinatorId ? values.coordinatorName : null,
        headOfDirectionId: headOfDirectionId,
        headOfDirection: !headOfDirectionId ? values.headOfDirection : null,
        needsAssignVerify: values.needsAssignVerify === types_1.NeedVerificationStatus.NEED_VERIFICATION,
        responsibles: values.responsibles?.map(el => {
            return {
                accountId: el.accountId,
                userId: !el.accountId ? el.id : undefined
            };
        }) || [],
        name: values.name,
        number: values.number,
        startDate: values.startDate ? (0, parsers_1.transformDateParams)(values.startDate) : null
    };
};
exports.transformProjectParams = transformProjectParams;
const transformAttachUsersToProjectParams = (values) => {
    return { userIds: values.filter(user => user.attached).map(el => el.id) };
};
exports.transformAttachUsersToProjectParams = transformAttachUsersToProjectParams;
const transformAttachUsersToProjectValues = (values, attachedIds) => {
    if (attachedIds.data === null)
        return { data: null, errors: attachedIds.errors, status: attachedIds.status };
    return {
        data: values.map(el => {
            return {
                id: el.id,
                fullname: el.fullname,
                attached: attachedIds.data.includes(el.id)
            };
        }),
        status: attachedIds.status,
        errors: null
    };
};
exports.transformAttachUsersToProjectValues = transformAttachUsersToProjectValues;
const transformBudgetUsersValues = (values) => {
    if (values.data === null)
        return { data: null, status: values.status, errors: values.errors };
    return {
        data: values.data.map(el => {
            return {
                id: el.id,
                fullname: el.fullName,
                attached: false
            };
        }),
        status: values.status,
        errors: null
    };
};
exports.transformBudgetUsersValues = transformBudgetUsersValues;
const transformProjectParamsToFilterParams = (params) => {
    const filters = {};
    const paginate = {};
    const filterParams = {
        hasNoUsers: params.hasNoUsers,
        filterByUserId: params.user?.id,
        budgetSourceId: params.budgetSource?.id,
        fundingSourceId: params.fundingSource?.id,
        donorId: params.donor?.id,
        keyword: params.keyword,
        coordinator: params.coordinator,
        responsible: params.responsible,
        startDateFrom: (0, parsers_1.optionalTransformDate)(params.startDateFrom),
        startDateTo: (0, parsers_1.optionalTransformDate)(params.startDateTo),
        endDateFrom: (0, parsers_1.optionalTransformDate)(params.endDateFrom),
        endDateTo: (0, parsers_1.optionalTransformDate)(params.endDateTo),
        page: params.page,
        perPage: params.perPage
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if (value && key !== 'page' && key !== 'perPage')
                filters[key] = value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(filters).length === 0)
            return { ...paginate };
        return { ...paginate, filters };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformProjectParamsToFilterParams = transformProjectParamsToFilterParams;
const transformUrlToProjectFilterParams = (url) => {
    const values = (0, queryString_1.parse)(url);
    return {
        user: values.filterByUserId && values.filterByUserLabel ? { id: values.filterByUserId, label: values.filterByUserLabel } : undefined,
        donor: values.donorId && values.donorLabel ? { id: values.donorId, label: values.donorLabel } : undefined,
        budgetSource: values.budgetSourceId && values.budgetSourceLabel ? { id: values.budgetSourceId, label: values.budgetSourceLabel } : undefined,
        fundingSource: values.fundingSourceId && values.fundingSourceLabel ? { id: values.fundingSourceId, label: values.fundingSourceLabel } : undefined,
        hasNoUsers: values.hasNoUsers ? Number(values.hasNoUsers) === apiGlobalTypes_1.YesNoStatus.YES : undefined,
        startDateFrom: values.startDateFrom,
        startDateTo: values.startDateTo,
        endDateFrom: values.endDateFrom,
        endDateTo: values.endDateTo,
        keyword: values.keyword,
        page: values.page,
        perPage: values.perPage
    };
};
exports.transformUrlToProjectFilterParams = transformUrlToProjectFilterParams;
const transformProjectFilterParamsToUrl = (values) => {
    const { page, perPage, user, startDateFrom, startDateTo, endDateFrom, endDateTo, keyword, hasNoUsers, donor, budgetSource, fundingSource, coordinator, responsible } = values;
    return {
        page,
        perPage,
        filterByUserId: user?.id,
        filterByUserLabel: user?.label,
        donorId: donor?.id,
        donorLabel: donor?.label,
        budgetSourceId: budgetSource?.id,
        budgetSourceLabel: budgetSource?.label,
        fundingSourceId: fundingSource?.id,
        fundingSourceLabel: fundingSource?.label,
        hasNoUsers: hasNoUsers ? 1 : undefined,
        coordinator,
        responsible,
        keyword,
        startDateFrom,
        startDateTo,
        endDateFrom,
        endDateTo
    };
};
exports.transformProjectFilterParamsToUrl = transformProjectFilterParamsToUrl;
const transformProjectPerformersParams = (values, initialData) => {
    const items = values.map((project, projectIndex) => {
        return {
            projectId: project.id,
            users: project.checked
                ? project.userFullNames
                    .map((user, userIndex) => {
                    return {
                        userId: initialData[projectIndex]?.userFullNames[userIndex]?.id,
                        substituteUserId: user.id
                    };
                })
                    .filter(el => el.userId !== el.substituteUserId)
                : []
        };
    });
    const filteredItems = items.filter(item => item.users.length !== 0);
    return filteredItems.length === 0 ? null : { items: filteredItems };
};
exports.transformProjectPerformersParams = transformProjectPerformersParams;
const transformProjectStructuralUnitParams = (values) => {
    return {
        ids: values.attachedStructuralUnits.map(el => el.id)
    };
};
exports.transformProjectStructuralUnitParams = transformProjectStructuralUnitParams;
