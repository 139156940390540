"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const useHRSubmissions_1 = require("../store/useHRSubmissions");
const index_1 = require("api/index");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const queryString_1 = require("core/helpers/queryString");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useHumanResourceSubmissions = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, useHRSubmissions_1.useHumanResourceSubmissionsReducer)();
    const [linkParams, setLinkParams] = (0, react_1.useState)('');
    const [showFilter, setShowFilter] = (0, react_1.useState)(false);
    const [showDeleteAlert, setShowDeleteAlert] = (0, react_1.useState)(false);
    const [deletedHumanResourceSubmissionId, setDeletedHumanResourceSubmissionId] = (0, react_1.useState)(null);
    const [assignSubmissionId, setAssignSubmissionId] = (0, react_1.useState)(null);
    const isMounted = (0, react_1.useRef)(false);
    const navigatePagination = (page, values) => {
        getHumanResourceSubmissions({ ...values, page }).then();
    };
    const filter = (name, value, filterValues) => {
        getHumanResourceSubmissions({ ...filterValues, [name]: value, page: 1 }).then();
    };
    const selectPerPage = (perPage, values) => {
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'CHANGE_PER_PAGE', payload: { ...values, perPage } });
        getHumanResourceSubmissions({ ...params, perPage, page: undefined }).then();
    };
    const deleteAlert = (id) => {
        setShowDeleteAlert(true);
        setDeletedHumanResourceSubmissionId(id);
    };
    const debouncedSearch = (name, value, filterValues) => {
        (0, debouncedMethods_1.debouncedOnFunc)({ ...filterValues, [name]: value, page: 1 }, getHumanResourceSubmissions);
    };
    const deleteHumanResourceSubmission = async (confirm) => {
        setShowDeleteAlert(false);
        if (!deletedHumanResourceSubmissionId || !confirm)
            return setDeletedHumanResourceSubmissionId(null);
        dispatch({ type: 'SEND_DELETE_REQUEST' });
        const HumanResourceSubmissions = await index_1.HumanResourcesSubmissionsApi.deleteHRAssignSubmission(deletedHumanResourceSubmissionId);
        //const params = parse(history.location.search) as HumanuseHumanResourceSubmissionsParams
        dispatch({ type: 'DONE_DELETE_REQUEST', payload: { errors: HumanResourceSubmissions.errors } });
        if (HumanResourceSubmissions.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            getHumanResourceSubmissions({}).then();
        setDeletedHumanResourceSubmissionId(null);
    };
    const getHumanResourceSubmissions = async (values) => {
        dispatch({ type: 'SEND_HUMAN_RESOURCE_SUBMISSIONS_REQUEST' });
        const filterParams = (0, helpers_1.transformAssignSubmissionParamsToFilterParams)(values);
        const HumanResourceSubmissions = await index_1.HumanResourcesSubmissionsApi.getHumanResourceSubmissions(filterParams);
        if (HumanResourceSubmissions.data !== null)
            setLinkParams((0, queryString_1.stringify)((0, helpers_1.transformAssignSubmissionFilterParamsToUrl)(values)));
        if (isMounted?.current) {
            dispatch({ type: 'DONE_HUMAN_RESOURCE_SUBMISSIONS_REQUEST', payload: HumanResourceSubmissions });
            navigate('/documents/human-resources/assign-submissions' + (0, queryString_1.stringify)((0, helpers_1.transformAssignSubmissionFilterParamsToUrl)(values)), { replace: true });
        }
    };
    const initializeFilterForm = async () => {
        const params = (0, helpers_1.transformUrlToAssignSubmissionFilterParams)(location.search);
        const isAnyFilterValue = Object.entries(params).some(([key, value]) => {
            if (key === 'page' || key === 'perPage')
                return false;
            return !!value;
        });
        if (isAnyFilterValue)
            setShowFilter(true);
        dispatch({ type: 'INITIALIZE_FILTER_FORM', payload: params });
        const assignSubmissionListId = (0, queryString_1.parse)(location.search).assignSubmissionId;
        getHumanResourceSubmissions(params).then(() => dispatch({ type: 'INITIALIZE_FILTER_FORM', payload: params }));
        setAssignSubmissionId(assignSubmissionListId);
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        initializeFilterForm().then();
        return () => { isMounted.current = false; };
    }, []);
    return {
        state,
        navigatePagination,
        selectPerPage,
        debouncedSearch,
        filter,
        deleteAlert,
        deleteHumanResourceSubmission,
        deletedHumanResourceSubmissionId,
        showDeleteAlert,
        linkParams,
        setShowFilter,
        showFilter,
        getHumanResourceSubmissions,
        assignSubmissionId
    };
};
exports.default = useHumanResourceSubmissions;
