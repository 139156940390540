"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const index_1 = require("api/index");
const submissionVerify_1 = require("../store/submissionVerify");
const helpers_1 = require("../helpers");
const store_1 = require("core/store");
const queryString_1 = require("core/helpers/queryString");
const react_router_dom_1 = require("react-router-dom");
const useSubmissionVerify = (props) => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, submissionVerify_1.useSubmissionVerifyReducer)();
    const [showDeclineAlert, setShowDeclineAlert] = (0, react_1.useState)(false);
    const [buttonNavigate, setButtonNavigate] = (0, react_1.useState)(submissionVerify_1.initialVerifySubmissionNavigate);
    const [verifyParamsIds, setVerifyParamsIds] = (0, react_1.useState)({ verifySubmissionIds: [], meta: store_1.initialMeta });
    const [listingParams, setListingParams] = (0, react_1.useState)('');
    const getSubmissionVerify = async (id) => {
        dispatch({ type: 'SEND_SUBMISSION_VERIFY_REQUEST' });
        const submissionVerify = await index_1.SubmissionVerifiesApi.getSubmissionVerify(id);
        dispatch({ type: 'DONE_SUBMISSION_VERIFY_REQUEST', payload: submissionVerify });
    };
    const requestChange = async (id) => {
        dispatch({ type: 'SEND_SUBMISSION_VERIFY_REQUEST_CHANGE' });
        const submissionVerify = await index_1.SubmissionVerifiesApi.requestChange(id);
        dispatch({ type: 'DONE_SUBMISSION_VERIFY_REQUEST_CHANGE', payload: submissionVerify });
        return submissionVerify;
    };
    const declineSubmission = async (comment, verifySubmissionId) => {
        dispatch({ type: 'SEND_SUBMISSION_VERIFY_DECLINE' });
        const submissionVerify = await index_1.SubmissionVerifiesApi.decline(verifySubmissionId, { comment });
        dispatch({ type: 'DONE_SUBMISSION_VERIFY_DECLINE', payload: submissionVerify });
        return submissionVerify;
    };
    const showDeclineConfirm = () => {
        setShowDeclineAlert(prevShow => !prevShow);
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    const getVerifySubmissionIds = async (values) => {
        const verifySubmissionParams = (0, helpers_1.transformVerifySubmissionParamsToFilterParams)({ ...values });
        const itemsParams = (0, helpers_1.getItemsV2ParamsFromUrl)(location.search);
        const verifySubmissions = await index_1.SubmissionVerifiesApi.getSubmissionVerifies(verifySubmissionParams);
        if (verifySubmissions.data !== null && verifySubmissions.meta) {
            const responseIds = {
                verifySubmissionIds: verifySubmissions.data.map(el => el.id),
                meta: verifySubmissions.meta
            };
            const searchParams = (0, queryString_1.stringify)({ ...(0, helpers_1.transformVerifyFilterParamsToUrl)(values), ...itemsParams });
            setListingParams(searchParams || '?');
            setVerifyParamsIds(responseIds);
            return responseIds;
        }
        return null;
    };
    const nextSubmissionVerify = async () => {
        setButtonNavigate(prevState => ({ ...prevState, next: true, isLoading: true }));
        const currentIndex = verifyParamsIds.verifySubmissionIds.indexOf(props.id);
        const nextVerifySubmission = verifyParamsIds.verifySubmissionIds[currentIndex + 1];
        const { pagination } = verifyParamsIds.meta;
        const params = (0, helpers_1.transformVerifyFilterParamsToUrl)((0, helpers_1.transformVerifyUrlToFilterParams)(location.search));
        if (nextVerifySubmission) {
            const itemsParams = (0, helpers_1.getItemsV2ParamsFromUrl)(location.search);
            navigate('/documents/purchases/submission-verifies/' + nextVerifySubmission + '/edit' + (0, queryString_1.stringify)({ ...params, ...itemsParams }));
            const lastElement = pagination.currentPage === pagination.totalPages && verifyParamsIds.verifySubmissionIds.length === currentIndex + 2;
            setButtonNavigate(prevState => ({ ...prevState, next: false, firstElement: false, lastElement, isLoading: false }));
        }
        else {
            if (pagination.currentPage === pagination.totalPages) {
                setButtonNavigate(prevState => ({ ...prevState, next: false, lastElement: true, isLoading: false }));
                return;
            }
            const formValues = (0, helpers_1.transformVerifyUrlToFilterParams)(location.search);
            const response = await getVerifySubmissionIds({ ...formValues, page: pagination.currentPage + 1 });
            if (response && response.verifySubmissionIds[0]) {
                const itemsParams = (0, helpers_1.getItemsV2ParamsFromUrl)(location.search);
                formValues.page = response.meta.pagination.currentPage;
                const urlParams = { ...(0, helpers_1.transformVerifyFilterParamsToUrl)(formValues), page: response.meta.pagination.currentPage, ...itemsParams };
                navigate('/documents/purchases/submission-verifies/' + response.verifySubmissionIds[0] + '/edit' + (0, queryString_1.stringify)(urlParams));
                const lastElement = response.meta.pagination.currentPage === response.meta.pagination.totalPages && response.verifySubmissionIds.length === 1;
                setButtonNavigate(prevState => ({ ...prevState, next: false, firstElement: false, lastElement, isLoading: false }));
            }
        }
    };
    const previousSubmissionVerify = async () => {
        setButtonNavigate(prevState => ({ ...prevState, previous: true, isLoading: true }));
        const currentIndex = verifyParamsIds.verifySubmissionIds.indexOf(props.id);
        const prevVerifySubmission = verifyParamsIds.verifySubmissionIds[currentIndex - 1];
        const { pagination } = verifyParamsIds.meta;
        const params = (0, helpers_1.transformVerifyFilterParamsToUrl)((0, helpers_1.transformVerifyUrlToFilterParams)(location.search));
        if (prevVerifySubmission) {
            const itemsParams = (0, helpers_1.getItemsV2ParamsFromUrl)(location.search);
            navigate('/documents/purchases/submission-verifies/' + prevVerifySubmission + '/edit' + (0, queryString_1.stringify)({ ...params, ...itemsParams }));
            const firstElement = pagination.currentPage === 1 && currentIndex === 1;
            setButtonNavigate(prevState => ({ ...prevState, previous: false, lastElement: false, firstElement, isLoading: false }));
        }
        else {
            if (pagination.currentPage === 1) {
                setButtonNavigate(prevState => ({ ...prevState, previous: false, firstElement: true, isLoading: false }));
                return;
            }
            const formValues = (0, helpers_1.transformVerifyUrlToFilterParams)(location.search);
            const response = await getVerifySubmissionIds({ ...formValues, page: pagination.currentPage - 1 });
            if (response) {
                const previousVerifySubmission = response.verifySubmissionIds[response.verifySubmissionIds.length - 1];
                if (previousVerifySubmission) {
                    formValues.page = response.meta.pagination.currentPage;
                    const itemsParams = (0, helpers_1.getItemsV2ParamsFromUrl)(location.search);
                    const urlParams = { ...(0, helpers_1.transformVerifyFilterParamsToUrl)(formValues), ...itemsParams };
                    navigate('/documents/purchases/submission-verifies/' + previousVerifySubmission + '/edit' + (0, queryString_1.stringify)(urlParams));
                    const firstElement = response.meta.pagination.currentPage === 1 && currentIndex === 1;
                    setButtonNavigate(prevState => ({ ...prevState, previous: false, lastElement: false, firstElement, isLoading: false }));
                }
            }
        }
    };
    (0, react_1.useEffect)(() => {
        const params = (0, helpers_1.transformVerifyUrlToFilterParams)(location.search);
        getVerifySubmissionIds(params).then(response => {
            if (response) {
                const { currentPage, totalPages } = response.meta.pagination;
                const verifyIndex = response.verifySubmissionIds.indexOf(props.id);
                if (verifyIndex === 0 && currentPage === 1)
                    setButtonNavigate(prevState => ({ ...prevState, firstElement: true }));
                if (currentPage === totalPages && verifyIndex === response.verifySubmissionIds.length - 1) {
                    setButtonNavigate(prevState => ({ ...prevState, lastElement: true }));
                }
            }
        });
    }, []);
    (0, react_1.useEffect)(() => { if (props.id)
        getSubmissionVerify(props.id).then(); }, [props.id]);
    return {
        state,
        getSubmissionVerify,
        declineSubmission,
        showDeclineConfirm,
        showDeclineAlert,
        requestChange,
        closeErrorMessage,
        nextSubmissionVerify,
        buttonNavigate,
        listingParams,
        previousSubmissionVerify,
        setShowDeclineAlert
    };
};
exports.default = useSubmissionVerify;
