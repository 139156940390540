"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseTrainings = void 0;
const types_1 = require("api/references/employees/types");
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseTrainings = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const trainings = response.content;
    try {
        return {
            data: trainings.data.map(training => {
                return {
                    id: training.id,
                    withFunding: training.attributes.withFunding ? types_1.Funding.YES : types_1.Funding.NO,
                    name: training.attributes.name,
                    dateTo: (0, parsers_1.nullableTransformDate)(training.attributes.dateTo),
                    dateFrom: (0, parsers_1.transformDate)(training.attributes.dateFrom),
                    hour: training.attributes.hour,
                    credit: training.attributes.credit,
                    trainingHoursSelected: !!training.attributes.hour,
                    trainingCreditsSelected: !!training.attributes.credit,
                    files: training.relationships.mediaFiles.data.map(file => {
                        return {
                            id: file.id,
                            link: file.attributes.downloadUrl,
                            originalName: file.attributes.originalName,
                            status: file.attributes.status,
                            name: file.attributes.name,
                            extension: file.attributes.extension
                        };
                    })
                };
            }),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseTrainings = parseTrainings;
