"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReasonTypes = exports.ProcurementCorrectionType = void 0;
var ProcurementCorrectionType;
(function (ProcurementCorrectionType) {
    ProcurementCorrectionType[ProcurementCorrectionType["LETTER"] = 1] = "LETTER";
    ProcurementCorrectionType[ProcurementCorrectionType["SUBMISSION"] = 2] = "SUBMISSION";
})(ProcurementCorrectionType || (exports.ProcurementCorrectionType = ProcurementCorrectionType = {}));
var ReasonTypes;
(function (ReasonTypes) {
    ReasonTypes[ReasonTypes["DELIVERY_DATE"] = 1] = "DELIVERY_DATE";
    ReasonTypes[ReasonTypes["CONTRACT_VALIDITY_DATE"] = 2] = "CONTRACT_VALIDITY_DATE";
    ReasonTypes[ReasonTypes["PRICE"] = 3] = "PRICE";
    ReasonTypes[ReasonTypes["COMPANY_REQUISITES"] = 4] = "COMPANY_REQUISITES";
    ReasonTypes[ReasonTypes["BILATERAL_TERMINATION_OF_CONTRACT"] = 5] = "BILATERAL_TERMINATION_OF_CONTRACT";
    ReasonTypes[ReasonTypes["FUNDING_SOURCE"] = 6] = "FUNDING_SOURCE";
    ReasonTypes[ReasonTypes["CHANGE_CPV_CODE"] = 7] = "CHANGE_CPV_CODE";
    ReasonTypes[ReasonTypes["SUBJECT_NAME"] = 8] = "SUBJECT_NAME";
    ReasonTypes[ReasonTypes["UNILATERAL_TERMINATION_OF_CONTRACT"] = 9] = "UNILATERAL_TERMINATION_OF_CONTRACT";
    ReasonTypes[ReasonTypes["CHANGE_QUANTITY_OF_PURCHASE_SUBJECTS"] = 10] = "CHANGE_QUANTITY_OF_PURCHASE_SUBJECTS";
    ReasonTypes[ReasonTypes["ADD_ADVANCE_GUARANTEE_ITEM"] = 11] = "ADD_ADVANCE_GUARANTEE_ITEM";
})(ReasonTypes || (exports.ReasonTypes = ReasonTypes = {}));
