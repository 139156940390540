"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.categoriesReducer = exports.useCategoriesReducer = exports.initialCategoriesState = void 0;
const react_1 = require("react");
exports.initialCategoriesState = {
    data: [],
    status: null,
    loadingItems: [],
    errors: null,
    isFetching: true
};
const useCategoriesReducer = () => {
    return (0, react_1.useReducer)(exports.categoriesReducer, exports.initialCategoriesState);
};
exports.useCategoriesReducer = useCategoriesReducer;
const categoriesReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_CATEGORIES_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_CATEGORIES_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                status: action.payload.status,
                errors: action.payload.errors,
                isFetching: false
            };
        case 'UPDATE_STATE':
            return {
                ...state,
                data: action.payload || []
            };
        case 'SET_LOADING_ITEM':
            return {
                ...state,
                loadingItems: [...state.loadingItems, action.payload]
            };
        case 'UN_SET_LOADING_ITEM':
            return {
                ...state,
                loadingItems: state.loadingItems.filter(el => !(el.id === action.payload.id && el.level === action.payload.level))
            };
        default:
            return state;
    }
};
exports.categoriesReducer = categoriesReducer;
