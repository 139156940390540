"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatisticProcurementTypes = void 0;
var StatisticProcurementTypes;
(function (StatisticProcurementTypes) {
    StatisticProcurementTypes[StatisticProcurementTypes["SUMMERY_OF_PURCHASE"] = 1] = "SUMMERY_OF_PURCHASE";
    StatisticProcurementTypes[StatisticProcurementTypes["CATEGORIES"] = 2] = "CATEGORIES";
    StatisticProcurementTypes[StatisticProcurementTypes["BUDGET_ARTICLES"] = 3] = "BUDGET_ARTICLES";
    StatisticProcurementTypes[StatisticProcurementTypes["PURCHASE_SUBJECTS"] = 4] = "PURCHASE_SUBJECTS";
    StatisticProcurementTypes[StatisticProcurementTypes["PROJECTS"] = 5] = "PROJECTS";
})(StatisticProcurementTypes || (exports.StatisticProcurementTypes = StatisticProcurementTypes = {}));
