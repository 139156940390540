"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformGoogleAuthUserParams = exports.transformAuthUserParams = void 0;
const transformAuthUserParams = (values) => {
    return {
        login: values.username,
        password: values.password,
        client_id: process.env.API_AUTH_CLIENT_ID,
        grant_type: process.env.API_AUTH_GRANT_TYPE
    };
};
exports.transformAuthUserParams = transformAuthUserParams;
const transformGoogleAuthUserParams = (googleToken) => {
    return {
        token: googleToken,
        client_id: process.env.API_AUTH_CLIENT_ID,
        grant_type: process.env.GOOGLE_AUTH_GRANT_TYPE
    };
};
exports.transformGoogleAuthUserParams = transformGoogleAuthUserParams;
