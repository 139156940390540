"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseUnreadQuantities = exports.parseNews = exports.parseNewses = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseNewses = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const newses = response.content;
    try {
        return {
            data: newses.data.map(el => {
                return {
                    id: el.id,
                    moduleType: el.type,
                    title: el.attributes.title,
                    publishDate: (0, parsers_1.transformDate)(el.attributes.publishDate),
                    publishDateTimeAtTimeAt: (0, parsers_1.transformDateTime)(el.attributes.publishDate),
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    createdDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt),
                    updatedDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.updatedAt),
                    newsBody: el.attributes.body,
                    seen: el.relationships.seenBy.data.length > 0
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(newses.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseNewses = parseNewses;
const parseNews = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const news = response.content;
    try {
        return {
            data: {
                id: news.data.id,
                title: news.data.attributes.title,
                text: news.data.attributes.body,
                publishDate: (0, parsers_1.transformDate)(news.data.attributes.publishDate)
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseNews = parseNews;
const parseUnreadQuantities = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const headerQuantities = response.content.data;
    try {
        return {
            data: {
                notifications: headerQuantities.attributes.unreadNotificationsCount,
                news: headerQuantities.attributes.unreadNewsCount
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseUnreadQuantities = parseUnreadQuantities;
