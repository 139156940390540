"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeterminations = void 0;
const index_1 = require("api/index");
const determinations_1 = require("../store/determinations");
const react_1 = require("react");
const useDeterminations = (props) => {
    const [state, dispatch] = (0, determinations_1.useDeterminationsReducer)();
    const getDeterminations = async () => {
        dispatch({ type: 'SEND_DETERMINATIONS_REQUEST' });
        const determinations = await index_1.ConditionalSubmissionsApi.getDeterminations(props.conditionalSubmissionId);
        dispatch({ type: 'DONE_DETERMINATIONS_REQUEST', payload: determinations });
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.hasDeterminations)
        getDeterminations().then(); }, [props.hasDeterminations]);
    return { state, closeErrorMessage };
};
exports.useDeterminations = useDeterminations;
exports.default = exports.useDeterminations;
