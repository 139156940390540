"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const submissionVerifyActionLog_1 = require("../store/submissionVerifyActionLog");
const react_1 = require("react");
const useVerifySubmissionActionLog = (props) => {
    const [state, dispatch] = (0, submissionVerifyActionLog_1.useSubmissionVerifyActionLogReducer)();
    const getVerifyItemsActionLog = async (params) => {
        dispatch({ type: 'SEND_SUBMISSION_VERIFY_ACTION_LOG_REQUEST' });
        const actionLog = await index_1.SubmissionVerifiesApi.getVerifySubmissionItemsActionLog(props.verifySubmissionItem.id, params);
        dispatch({ type: 'DONE_SUBMISSION_VERIFY_ACTION_LOG_REQUEST', payload: { logsData: actionLog, verifySubmissionItem: props.verifySubmissionItem } });
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    const navigatePagination = (page) => {
        getVerifyItemsActionLog({ perPage: 25, page }).then();
    };
    (0, react_1.useEffect)(() => {
        getVerifyItemsActionLog({ page: 1, perPage: 25 }).then();
    }, []);
    return { state, navigatePagination, closeErrorMessage };
};
exports.default = useVerifySubmissionActionLog;
