"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const react_1 = require("react");
const useAttributes = () => {
    const [companyIsLoading, setCompanyIsLoading] = (0, react_1.useState)(false);
    const setAddressAndAccounts = async (id) => {
        setCompanyIsLoading(true);
        const company = await index_1.CompaniesApi.getCompany(id);
        setCompanyIsLoading(false);
        if (company.data !== null) {
            return { data: {
                    id: company.data.id,
                    label: company.data.attributes.name + ' - ' + company.data.attributes.legalStatusShortName,
                    addresses: company.data.addresses.map(el => ({ id: el.id, label: el.address, disabled: !el.status })),
                    bankAccounts: company.data.bankAccounts.map(el => ({
                        id: el.id,
                        label: el.accountNumber,
                        disabled: !el.status
                    })),
                    signatories: company.data.signatories.map(el => ({
                        id: el.id,
                        label: el.firstname + ' ' + el.lastname,
                        phones: el.phones.map(phone => ({ id: phone.id, label: phone.phone }))
                    }))
                }, status: company.status, errors: company.errors };
        }
        else {
            return { data: null, errors: company.errors, status: company.status };
        }
    };
    return { setAddressAndAccounts, companyIsLoading };
};
exports.default = useAttributes;
