"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const index_1 = require("api/index");
const purchasedItems_1 = require("../store/purchasedItems");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helper_1 = require("../helper");
const usePurchasedItems = ({ form, procurement }) => {
    const procurementId = procurement?.id;
    const [state, dispatch] = (0, purchasedItems_1.usePurchasedItemsReducer)();
    const [purchasedItemsModal, setPurchasedItemsModal] = (0, react_1.useState)(false);
    const getPurchasedItems = async (params) => {
        dispatch({ type: 'SEND_PURCHASED_ITEMS_REQUEST' });
        const filterParams = (0, helper_1.transformPurchasedItemsToFilterParams)({ keyword: params.keyword, page: params.page, perPage: params.perPage, procurementId: procurementId, submissionCreator: params.submissionCreator });
        const purchasedItems = await index_1.InvoicesApi.getPurchasedItems(filterParams);
        dispatch({ type: 'DONE_PURCHASED_ITEMS_REQUEST', payload: purchasedItems });
    };
    const filter = (name, value, filterValues) => {
        getPurchasedItems({ ...filterValues, [name]: value, page: 1 }).then();
    };
    const selectPerPage = (perPage, values) => {
        dispatch({ type: 'CHANGE_PER_PAGE', payload: { ...values, perPage } });
        getPurchasedItems({ ...values, perPage, page: 1 }).then();
    };
    const navigatePagination = (page, values) => {
        getPurchasedItems({ ...values, page }).then();
    };
    const debouncedSearch = (name, value, filterValues) => {
        (0, debouncedMethods_1.debouncedOnFunc)({ ...filterValues, [name]: value, page: 1 }, getPurchasedItems);
    };
    const removeInvoiceItem = (id) => {
        form.setFieldValue('invoiceItems', (form.values.invoiceItems.filter(item => item.id !== id)));
    };
    (0, react_1.useEffect)(() => {
        if (purchasedItemsModal) {
            const params = { procurementId: procurementId };
            getPurchasedItems(params).then();
        }
    }, [purchasedItemsModal]);
    const calculateSumPrice = (0, react_1.useCallback)(() => {
        return form.values.invoiceItems.reduce((sum, el) => {
            const currencyExchangeRate = el.currencyExchangeRate || 1;
            return sum + (el.price * el.quantity * (currencyExchangeRate / el.currencyQuantity));
        }, 0);
    }, [form.values.invoiceItems]);
    const addRemovePurchasedItems = (checked, purchasedItems) => {
        if (checked) {
            const newPurchasedItems = purchasedItems.reduce((accumulator, currentValue) => {
                if (!form.values.invoiceItems.find(el => currentValue.procurementItemId === el.procurementItemId)) {
                    const newItem = { ...currentValue, quantity: currentValue.restQuantity };
                    accumulator.push(newItem);
                }
                return accumulator;
            }, []);
            form.setFieldValue('invoiceItems', [...form.values.invoiceItems, ...newPurchasedItems]);
        }
        else {
            const items = form.values.invoiceItems.reduce((accumulator, currentValue) => {
                if (!purchasedItems.find(el => currentValue.procurementItemId === el.procurementItemId))
                    accumulator.push(currentValue);
                return accumulator;
            }, []);
            form.setFieldValue('invoiceItems', items);
        }
    };
    return {
        state,
        purchasedItemsModal,
        navigatePagination,
        setPurchasedItemsModal,
        debouncedSearch,
        addRemovePurchasedItems,
        removeInvoiceItem,
        calculateSumPrice,
        filter,
        selectPerPage
    };
};
exports.default = usePurchasedItems;
