"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSubmissionVerifyActionLogReducer = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
const initialSubmissionVerifyActionLogStore = {
    data: [],
    errors: null,
    meta: store_1.initialMeta,
    isFetching: true
};
const useSubmissionVerifyActionLogReducer = () => {
    return (0, react_1.useReducer)(submissionVerifyActionLogReducer, initialSubmissionVerifyActionLogStore);
};
exports.useSubmissionVerifyActionLogReducer = useSubmissionVerifyActionLogReducer;
const submissionVerifyActionLogReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_SUBMISSION_VERIFY_ACTION_LOG_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_SUBMISSION_VERIFY_ACTION_LOG_REQUEST':
            return {
                ...state,
                data: action.payload.logsData.data?.reverse().map((log, index) => {
                    return transformLogData(log, index, action.payload.verifySubmissionItem, action.payload.logsData.data);
                }) || [],
                errors: action.payload.logsData.errors,
                meta: action.payload.logsData.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
const transformLogData = (log, logIndex, verifySubmissionItem, logData) => {
    const prevLogData = logData[logIndex - 1];
    if (logIndex > 0) {
        return {
            ...log,
            alteredPurchaseSubject: {
                subjectName: logData[logIndex - 1].purchaseSubject.subjectName,
                specificationName: logData[logIndex - 1].purchaseSubject.specificationName,
                categoryName: logData[logIndex - 1].purchaseSubject.categoryName
            },
            alteredProcurement: prevLogData.procurement,
            alteredPurchaseStatus: prevLogData.purchaseStatus,
            alteredBudgetingStatus: prevLogData.budgetingStatus,
            alteredQuantity: prevLogData.quantity,
            alteredDimensionUnit: prevLogData.dimensionUnit,
            alteredPrice: prevLogData.price,
            alteredCurrency: prevLogData.currency,
            alteredCurrencyExchangeRate: prevLogData.currencyExchangeRate,
            alteredCpvCode: prevLogData.cpvCode,
            alteredProject: prevLogData.project,
            alteredBudgetArticle: prevLogData.budgetArticle,
            alteredCharacteristics: prevLogData.characteristics,
            alteredAssignment: prevLogData.assignment,
            alteredProcurementTerminationReason: prevLogData.procurementTerminationReason,
            alteredBudgetingStatusMessage: prevLogData.budgetingStatusMessage,
            alteredBudgetingVisualStatus: prevLogData.budgetingVisualStatus,
            alteredPurchasingStatusMessage: prevLogData.purchasingStatusMessage,
            alteredPurchaseVisualStatus: prevLogData.purchaseVisualStatus,
            alteredTemplateType: prevLogData.templateType
        };
    }
    return {
        ...log,
        alteredProcurement: verifySubmissionItem.procurement,
        alteredPurchaseStatus: verifySubmissionItem.purchaseStatus,
        alteredBudgetingStatus: verifySubmissionItem.budgetingStatus,
        alteredPurchaseSubject: verifySubmissionItem.purchaseSubject,
        alteredQuantity: verifySubmissionItem.quantity,
        alteredDimensionUnit: verifySubmissionItem.dimensionUnit,
        alteredPrice: verifySubmissionItem.price,
        alteredCurrency: verifySubmissionItem.currency,
        alteredCurrencyExchangeRate: verifySubmissionItem.currencyExchangeRate,
        alteredCpvCode: verifySubmissionItem.cpvCode,
        alteredProject: verifySubmissionItem.project,
        alteredBudgetArticle: verifySubmissionItem.budgetArticle,
        alteredCharacteristics: verifySubmissionItem.characteristics,
        alteredAssignment: verifySubmissionItem.assignment,
        alteredProcurementTerminationReason: verifySubmissionItem.procurementTerminationReason,
        alteredBudgetingStatusMessage: verifySubmissionItem.budgetingStatusMessage,
        alteredBudgetingVisualStatus: verifySubmissionItem.budgetingVisualStatus,
        alteredPurchasingStatusMessage: verifySubmissionItem.purchasingStatusMessage,
        alteredPurchaseVisualStatus: verifySubmissionItem.purchaseVisualStatus,
        alteredTemplateType: verifySubmissionItem.templateType
    };
};
