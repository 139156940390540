"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformVerifySubmissionItemsListParams = exports.transformVerifySubmissionItemV2Params = exports.transformItemsV2Params = exports.getItemsV2ParamsFromUrl = exports.transformVerifyFilterParamsToUrl = exports.transformVerifyUrlToFilterParams = exports.transformVerifySubmissionParamsToFilterParams = void 0;
const types_1 = require("api/references/purchase-subject/types");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const types_2 = require("api/documents/purchases/submissionVerifies/types");
const queryString_1 = require("core/helpers/queryString");
const transformVerifySubmissionParamsToFilterParams = (params) => {
    const filters = {};
    const paginate = {};
    const filterParams = {
        toSumPrice: params.toSumPrice,
        fromSumPrice: params.fromSumPrice,
        status: params.status?.id,
        itemBudgetingStatus: params.itemBudgetingStatus?.id,
        itemPurchaseStatus: params.itemPurchaseStatus?.id,
        itemStatus: params.itemStatus?.id,
        budgetingStatusUpdatedBy: params.budgetingStatusUpdatedBy?.id,
        budgetArticleId: params.budgetArticleId?.id || undefined,
        userIdForProjects: params.userIdForProjects?.id,
        submissionCreatedBy: params.submissionCreatedBy?.id,
        subjectName: params.subjectName,
        keyword: params.keyword,
        isForceMajeure: params.isForceMajeure,
        page: params.page,
        perPage: params.perPage
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if (value && key !== 'page' && key !== 'perPage')
                filters[key] = value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(filters).length === 0)
            return { ...paginate };
        return { ...paginate, filters };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformVerifySubmissionParamsToFilterParams = transformVerifySubmissionParamsToFilterParams;
const transformVerifyUrlToFilterParams = (url) => {
    const values = (0, queryString_1.parse)(url);
    return {
        keyword: values.keyword,
        subjectName: values.subjectName,
        status: values.statusLabel && values.statusId ? { id: values.statusId, label: values.statusLabel } : undefined,
        fromSumPrice: values.fromSumPrice,
        toSumPrice: values.toSumPrice,
        itemBudgetingStatus: values.itemBudgetingStatusID && values.itemBudgetingStatusLabel ? { id: values.itemBudgetingStatusID, label: values.itemBudgetingStatusLabel } : undefined,
        itemPurchaseStatus: values.itemPurchaseStatusID && values.itemPurchaseStatusLabel ? { id: values.itemPurchaseStatusID, label: values.itemPurchaseStatusLabel } : undefined,
        itemStatus: values.itemStatusId && values.itemStatusLabel ? { id: values.itemStatusId, label: values.itemStatusLabel } : undefined,
        budgetingStatusUpdatedBy: values.budgetingStatusUpdatedById && values.budgetingStatusUpdatedByLabel ? { id: values.budgetingStatusUpdatedById, label: values.budgetingStatusUpdatedByLabel } : undefined,
        budgetArticleId: values.budgetArticleId && values.budgetArticleIdLabel ? { id: values.budgetArticleId, label: values.budgetArticleIdLabel } : undefined,
        userIdForProjects: values.userIdForProjectsId && values.userIdForProjectsLabel ? { id: values.userIdForProjectsId, label: values.userIdForProjectsLabel } : undefined,
        submissionCreatedBy: values.submissionCreatedById && values.submissionCreatedByLabel ? { id: values.submissionCreatedById, label: values.submissionCreatedByLabel } : undefined,
        isForceMajeure: values.isForceMajeure ? Number(values.isForceMajeure) === apiGlobalTypes_1.YesNoStatus.YES : undefined,
        page: values.page,
        perPage: values.perPage
    };
};
exports.transformVerifyUrlToFilterParams = transformVerifyUrlToFilterParams;
const transformVerifyFilterParamsToUrl = (values) => {
    const { keyword, status, fromSumPrice, toSumPrice, itemBudgetingStatus, itemPurchaseStatus, itemStatus, budgetingStatusUpdatedBy, budgetArticleId, userIdForProjects, submissionCreatedBy, subjectName, page, perPage, isForceMajeure } = values;
    return {
        keyword: keyword,
        subjectName: subjectName,
        statusId: status && status.id,
        statusLabel: status && status.label,
        fromSumPrice: fromSumPrice,
        toSumPrice: toSumPrice,
        itemBudgetingStatusID: itemBudgetingStatus && itemBudgetingStatus.id,
        itemBudgetingStatusLabel: itemBudgetingStatus && itemBudgetingStatus.label,
        itemPurchaseStatusID: itemPurchaseStatus && itemPurchaseStatus.id,
        itemPurchaseStatusLabel: itemPurchaseStatus && itemPurchaseStatus.label,
        itemStatusId: itemStatus && itemStatus.id,
        itemStatusLabel: itemStatus && itemStatus.label,
        budgetingStatusUpdatedById: budgetingStatusUpdatedBy && budgetingStatusUpdatedBy.id,
        budgetingStatusUpdatedByLabel: budgetingStatusUpdatedBy && budgetingStatusUpdatedBy.label,
        budgetArticleId: budgetArticleId && budgetArticleId.id,
        budgetArticleIdLabel: budgetArticleId && budgetArticleId.label,
        userIdForProjectsId: userIdForProjects && userIdForProjects.id,
        userIdForProjectsLabel: userIdForProjects && userIdForProjects.label,
        submissionCreatedById: submissionCreatedBy && submissionCreatedBy.id,
        submissionCreatedByLabel: submissionCreatedBy && submissionCreatedBy.label,
        isForceMajeure: isForceMajeure ? 1 : undefined,
        page,
        perPage
    };
};
exports.transformVerifyFilterParamsToUrl = transformVerifyFilterParamsToUrl;
const getItemsV2ParamsFromUrl = (url) => {
    try {
        const params = (0, queryString_1.parse)(url);
        return {
            itemPage: params.itemPage,
            itemPerPage: params.itemPerPage,
            itemKeyword: params.itemKeyword ?? undefined,
            itemOnlyDeleted: params.itemOnlyDeleted ?? undefined,
            itemWithDeleted: params.itemWithDeleted ?? undefined
        };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.getItemsV2ParamsFromUrl = getItemsV2ParamsFromUrl;
const transformItemsV2Params = (params) => {
    return {
        page: params.itemPage,
        perPage: params.itemPerPage,
        filters: { keyword: params.itemKeyword ?? undefined, onlyDeleted: params.itemOnlyDeleted ?? undefined, withDeleted: params.itemWithDeleted ?? undefined }
    };
};
exports.transformItemsV2Params = transformItemsV2Params;
const transformVerifySubmissionItemV2Params = (values, actionType) => {
    return {
        items: actionType === types_2.VerifyActionType.BUDGETING
            ? [{
                    id: values.id,
                    budgetArticleId: values.budgetArticle?.id || null,
                    projectId: values.project.id
                }]
            : [{
                    id: values.id,
                    inventoryConfigurationId: values.inventoryConfigurationId,
                    cpvCodeId: values.cpvCode.id,
                    dimensionUnitId: values.dimensionUnit.id,
                    currencyId: values.currency.id,
                    projectId: values.budgetingStatus === types_2.SubmissionVerifyItemStatus.DRAFT ? values.project.id : undefined,
                    currencyExchangeRate: values.currencyExchangeRate,
                    quantity: values.quantity,
                    price: values.price,
                    characteristics: values.characteristics,
                    assignment: values.assignment,
                    templateType: Number(values.templateType.id),
                    parentId: values.parentId,
                    bookTitle: values.inventoryConfigurationType === types_1.InventoryConfigurationType.BOOK || values.inventoryConfigurationType === types_1.InventoryConfigurationType.NATIONWIDE ? values.bookTitle : null,
                    bookAuthor: values.bookTitle && values.inventoryConfigurationType === types_1.InventoryConfigurationType.BOOK || values.inventoryConfigurationType === types_1.InventoryConfigurationType.NATIONWIDE ? values.bookAuthor : null,
                    bookIsbnCode: values.bookTitle && values.inventoryConfigurationType === types_1.InventoryConfigurationType.BOOK || values.inventoryConfigurationType === types_1.InventoryConfigurationType.NATIONWIDE ? values.bookIsbnCode : null,
                    bookLink: values.bookTitle && values.inventoryConfigurationType === types_1.InventoryConfigurationType.BOOK || values.inventoryConfigurationType === types_1.InventoryConfigurationType.NATIONWIDE ? values.bookLink : null
                }]
    };
};
exports.transformVerifySubmissionItemV2Params = transformVerifySubmissionItemV2Params;
const transformVerifyItemForBudgeting = (item) => {
    return {
        id: item.id,
        budgetArticleId: item.budgetArticle?.id || null,
        projectId: item.project.id
    };
};
const transformVerifyItemForPurchases = (item) => {
    return {
        id: item.id,
        inventoryConfigurationId: item.inventoryConfigurationId,
        cpvCodeId: item.cpvCode.id,
        dimensionUnitId: item.dimensionUnit.id,
        currencyId: item.currency.id,
        projectId: item.project.id,
        currencyExchangeRate: item.currencyExchangeRate,
        quantity: item.quantity,
        price: item.price,
        characteristics: item.characteristics,
        assignment: item.assignment,
        templateType: Number(item.templateType.id),
        parentId: item.parentId,
        bookTitle: item.inventoryConfigurationType === types_1.InventoryConfigurationType.BOOK || item.inventoryConfigurationType === types_1.InventoryConfigurationType.NATIONWIDE ? item.bookTitle : null,
        bookAuthor: item.inventoryConfigurationType === types_1.InventoryConfigurationType.BOOK || item.inventoryConfigurationType === types_1.InventoryConfigurationType.NATIONWIDE ? item.bookAuthor : null,
        bookIsbnCode: item.inventoryConfigurationType === types_1.InventoryConfigurationType.BOOK || item.inventoryConfigurationType === types_1.InventoryConfigurationType.NATIONWIDE ? item.bookIsbnCode : null,
        bookLink: item.inventoryConfigurationType === types_1.InventoryConfigurationType.BOOK || item.inventoryConfigurationType === types_1.InventoryConfigurationType.NATIONWIDE ? item.bookLink : null
    };
};
// export const transformSuspendItemParams = (ids: Array<ID>): Array<ID> | null => {
//   return ids
// }
//
// export const transformDeclineItemsParams = (ids: Array<ID>): Array<ID> | null => {
//   return ids
// }
//
// export const transformApproveItemsParams = (ids: Array<ID>): Array<ID> | null => {
//   return ids
// }
const transformVerifySubmissionItemsListParams = (values, actionType) => {
    return {
        items: values.filter(el => el.inPurchase === apiGlobalTypes_1.YesNoStatus.NO).map(el => actionType === types_2.VerifyActionType.BUDGETING
            ? transformVerifyItemForBudgeting(el)
            : transformVerifyItemForPurchases(el))
    };
};
exports.transformVerifySubmissionItemsListParams = transformVerifySubmissionItemsListParams;
