"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addPublicContractComments = exports.getPublicContractComments = exports.addComment_v2 = exports.getComments_v2 = exports.addComment = exports.getComments = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const PublicApi = tslib_1.__importStar(require("src/api/requests"));
const parsers_1 = require("api/comments/parsers");
const getComments = async (id, name) => {
    const response = await Api.get(`documents/purchases/${name === 'submission-verifies' ? 'submission-verifies' : 'conditional-submissions'}/comments/` + id);
    return (0, parsers_1.parseComments)(response);
};
exports.getComments = getComments;
const addComment = async (verifySubmissionId, params, name) => {
    const response = await Api.post(`documents/purchases/${name === 'submission-verifies' ? 'submission-verifies' : 'conditional-submissions'}/add-comment/` + verifySubmissionId, params);
    return (0, parsers_1.parseComment)(response);
};
exports.addComment = addComment;
const getComments_v2 = async (getEndpoint) => {
    const response = await Api.get(getEndpoint);
    return (0, parsers_1.parseComments)(response);
};
exports.getComments_v2 = getComments_v2;
const addComment_v2 = async (addEndPoint, params) => {
    const response = await Api.post(addEndPoint, params);
    return (0, parsers_1.parseComment)(response);
};
exports.addComment_v2 = addComment_v2;
const getPublicContractComments = async (hash) => {
    const response = await PublicApi.get(`references/employees/contract-files/comments/${hash}`);
    return (0, parsers_1.parsePublicComments)(response);
};
exports.getPublicContractComments = getPublicContractComments;
const addPublicContractComments = async (hash, params) => {
    const response = await PublicApi.post(`references/employees/contract-files/add-comment/${hash}`, params);
    return (0, parsers_1.parsePublicComment)(response);
};
exports.addPublicContractComments = addPublicContractComments;
