"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFakeConditionalSubmission = void 0;
const index_1 = require("api/index");
const types_1 = require("api/references/purchase-subject/types");
const fakeData_1 = require("../../helper/fakeData");
const employees_1 = require("api/references/employees");
const helpers_1 = require("core/helpers");
const projects_1 = require("api/references/funding/projects");
const helper_1 = require("../../../common/helper");
const react_1 = require("react");
const context_1 = require("src/context/context");
const useFakeConditionalSubmission = (props) => {
    const [isCreating, setIsCreating] = (0, react_1.useState)(false);
    const [isSending, setIsSending] = (0, react_1.useState)(false);
    const [isUpdating, setIsUpdating] = (0, react_1.useState)(false);
    const [isCreatingSubmission, setIsCreatingSubmission] = (0, react_1.useState)(false);
    const [isCreatingDetermination, setIsCreatingDetermination] = (0, react_1.useState)(false);
    const { toast } = (0, context_1.useToastContext)();
    const createConditionalSubmission = async (loading = true) => {
        if (loading)
            setIsCreating(true);
        const accounts = await (0, employees_1.getEmployeesForSelect)({});
        if (!accounts) {
            if (loading)
                setIsCreating(false);
            return;
        }
        const filteredAccounts = accounts.filter(el => el.structuralUnits.length > 0);
        const account = filteredAccounts[(0, helpers_1.getRandomIntBetween)(filteredAccounts.length, 0)].structuralUnits.map(el => el.accountId);
        const userAccount = {
            accountId: account[0]
        };
        const fullData = { ...fakeData_1.conditionalSubmissionData, ...userAccount };
        const projectId = await createProject();
        if (!projectId) {
            if (loading)
                setIsCreating(false);
            return;
        }
        const conditionalSubmission = await index_1.ConditionalSubmissionsApi.createConditionalSubmission(fullData);
        const inventoryConfigurations = await index_1.PurchaseSubjectsApi.getPurchaseSubjects({ perPage: 10, filters: { verified: types_1.VerifiedStatus.VERIFIED } });
        if (inventoryConfigurations.data) {
            const items = inventoryConfigurations.data.map(el => {
                return {
                    id: null,
                    assignment: (0, helpers_1.getHash)(),
                    dimensionUnitId: String((0, helpers_1.getRandomIntBetween)(10, 1)),
                    characteristics: el.specificationName ? null : (0, helpers_1.getHash)(),
                    quantity: (0, helpers_1.getRandomIntBetween)(20, 1),
                    inventoryConfigurationId: el.id,
                    projectId: '',
                    bookTitle: null,
                    bookAuthor: null,
                    bookLink: null,
                    bookIsbnCode: null,
                    sort: 1
                };
            });
            const conditionalSubmissionItems = {
                items,
                isCreate: true
            };
            if (projectId)
                conditionalSubmissionItems.items.forEach(el => el.projectId = projectId);
            await index_1.ConditionalSubmissionsApi.updateOrCreateConditionalSubmissionItem(conditionalSubmission.data?.id, conditionalSubmissionItems);
        }
        else {
            if (loading)
                setIsCreating(false);
            toast.danger('პირობითი წარდგინება ვერ შეიქმნა');
            return null;
        }
        if (loading)
            setIsCreating(false);
        if (conditionalSubmission.data !== null) {
            toast.success('პირობითი წარდგინება შეიქმნა წარმატებით');
            return conditionalSubmission.data;
        }
        else {
            toast.danger('პირობითი წარდგინება ვერ შეიქმნა');
            return null;
        }
    };
    const sendConditionalSubmission = async (loading = true) => {
        if (loading)
            setIsSending(true);
        const submission = await createConditionalSubmission(false);
        if (submission?.id) {
            const conditionalSubmission = await index_1.ConditionalSubmissionsApi.sendToChancellery(submission.id);
            if (loading)
                setIsSending(false);
            if (conditionalSubmission.data !== null) {
                toast.success('პირობითი წარდგინება წარმატებით გაიგზავნა');
                if (loading)
                    setIsSending(false);
                return conditionalSubmission.data;
            }
            else {
                toast.danger('პირობითი წარდგინება ვერ გაიგზავნა');
                return null;
            }
        }
        else {
            toast.danger('პირობითი წარდგინების ID არ მოიძებნა');
            return null;
        }
    };
    const updateConditionalSubmission = async (loading = true) => {
        if (loading)
            setIsUpdating(true);
        const updateData = {
            edocNumber: String((0, helpers_1.getRandomIntBetween)(1000, 1)),
            requestRegistrationDate: (0, helper_1.transformParamsDate)((0, helper_1.getToday)()),
            fileUids: [
                {
                    type: 1,
                    uid: props.uid
                }
            ]
        };
        const conditionalSubmission = await sendConditionalSubmission(false);
        if (!conditionalSubmission) {
            if (loading)
                setIsUpdating(false);
            return;
        }
        if (conditionalSubmission.id) {
            const accounts = await (0, employees_1.getEmployeesForSelect)({});
            if (!accounts) {
                if (loading)
                    setIsUpdating(false);
                return;
            }
            const filteredAccounts = accounts.filter(el => el.structuralUnits.length > 0);
            const account = filteredAccounts[(0, helpers_1.getRandomIntBetween)(filteredAccounts.length, 0)].structuralUnits.map(el => el.accountId);
            const userAccount = {
                accountId: account[0]
            };
            const fullData = { ...fakeData_1.conditionalSubmissionData, ...updateData, ...userAccount };
            const updateConditionalSubmission = await index_1.ConditionalSubmissionsApi.updateConditionalSubmission(conditionalSubmission.id, fullData);
            if (loading)
                setIsUpdating(false);
            if (updateConditionalSubmission.data !== null) {
                toast.success('პირობითი წარდგინების შესყიდვის დოკუმენტი წარმატებით განახლდა');
                return updateConditionalSubmission.data;
            }
            else {
                toast.danger('პირობითი წარდგინება ვერ განახლდა');
                return null;
            }
        }
        else {
            toast.danger('პირობითი წარდგინების ID არ მოიძებნა');
            return null;
        }
    };
    const createDetermination = async (loading = true) => {
        setIsCreatingDetermination(true);
        const conditionalSubmission = await updateConditionalSubmission(false);
        if (conditionalSubmission) {
            if (conditionalSubmission.id) {
                const suspendedSubmission = await index_1.ConditionalSubmissionsApi.suspendItem(conditionalSubmission.id, { comment: 'Test' });
                if (suspendedSubmission.data) {
                    toast.success('წარმატებით შეჩერდა პირობითი წარდგინება.');
                    const determination = await index_1.ConditionalSubmissionsApi.createDetermination(conditionalSubmission.id, { content: 'Test Test', fileUids: [] });
                    if (loading)
                        setIsCreatingDetermination(false);
                    if (determination.data) {
                        toast.success('წარმატებით შეიქმნა დაზუსტება.');
                    }
                    else {
                        toast.danger('წარმატებით ვერ შეიქმნა დაზუსტება.');
                    }
                }
                else {
                    if (loading)
                        setIsCreatingDetermination(false);
                    toast.danger('წარმატებით ვერ შეჩერდა პირობითი წარდგინება.');
                }
            }
        }
        else {
            if (loading)
                setIsCreatingDetermination(false);
            toast.danger('something went wrong');
        }
    };
    const createSubmission = async (loading = true) => {
        if (loading)
            setIsCreatingSubmission(true);
        const conditionalSubmission = await updateConditionalSubmission(false);
        if (!conditionalSubmission) {
            if (loading)
                setIsCreatingSubmission(false);
            return null;
        }
        if (conditionalSubmission.id) {
            await index_1.ConditionalSubmissionsApi.sendProgressInPurchase(conditionalSubmission.id);
            const conditionalSubmissionItems = await index_1.ConditionalSubmissionsApi.getConditionalSubmissionsItems(conditionalSubmission.id);
            const items = conditionalSubmissionItems.data?.map(el => el);
            if (!items) {
                if (loading)
                    setIsCreatingSubmission(false);
                return null;
            }
            const submission = await index_1.ConditionalSubmissionsApi.createSubmission({ items: conditionalSubmissionItems.data?.filter(item => item).map(value => ({ conditionalSubmissionItemId: value.id })) || [] });
            if (loading)
                setIsCreatingSubmission(false);
            if (submission.data !== null) {
                toast.success('წარდგინება წარმატებით შეიქმნა');
                return submission.data.submissionId;
            }
            else {
                toast.danger('პირობითი წარდგინება ვერ განახლდა');
                return null;
            }
        }
        else {
            toast.danger('პირობითი წარდგინების ID არ მოიძებნა');
            return null;
        }
    };
    const createProject = async () => {
        const selectedProject = await (0, projects_1.getProjectsForSelect)({ filters: { keyword: 'ERP - სატესტო პროექტი 0.0.1' } });
        if (selectedProject.length === 0) {
            const projectData = {
                additionalInfo: '',
                budgetSourceId: '1',
                donorId: null,
                endDate: null,
                fundingSourceId: '2',
                headOfDirection: null,
                coordinatorName: null,
                name: 'ERP - სატესტო პროექტი 0.0.1',
                headOfDirectionId: String((0, helpers_1.getRandomIntBetween)(20, 10)),
                coordinatorId: String((0, helpers_1.getRandomIntBetween)(20, 10)),
                responsibles: [{ accountId: String((0, helpers_1.getRandomIntBetween)(20, 10)) }],
                needsAssignVerify: true,
                number: null,
                startDate: null
            };
            const project = await index_1.ProjectsApi.createProject(projectData);
            return project.data?.id;
        }
        return selectedProject[0].id;
    };
    return {
        createConditionalSubmission,
        sendConditionalSubmission,
        updateConditionalSubmission,
        createSubmission,
        createProject,
        isCreating,
        isSending,
        isUpdating,
        isCreatingSubmission,
        createDetermination,
        isCreatingDetermination
    };
};
exports.useFakeConditionalSubmission = useFakeConditionalSubmission;
