"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformCorrectionParams = void 0;
const transformCorrectionParams = (values) => {
    return {
        number: values.number,
        publicationDate: values.publicationDate,
        name: values.name,
        fileUid: values.files[0].id,
        articles: values.correction.map(el => {
            return {
                correctedArticleId: el.correctionArticle?.id,
                name: el.name,
                number: el.number,
                price: el.price
            };
        })
    };
};
exports.transformCorrectionParams = transformCorrectionParams;
