"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformDeterminationParams = exports.transformItemsParams = exports.getConditionalSubmissionItemsParamsFromUrl = exports.transformCreateSubmissionParams = exports.transformConditionalSubmissionItemsListParams = exports.transformFilterParamsToUrl = exports.transformUrlToFilterParams = exports.transformConditionalSubmissionParams = exports.transformParamsToFilterParams = exports.getParamsFromUrl = void 0;
const types_1 = require("api/documents/purchases/conditional-submissions/types");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const types_2 = require("api/references/purchase-subject/types");
const helper_1 = require("src/modules/common/helper");
const queryString_1 = require("core/helpers/queryString");
const getParamsFromUrl = (url) => {
    const allowedProperties = ['page', 'perPage', 'keyword', 'structuralUnit', 'createdBy', 'employee', 'status'];
    const params = (0, queryString_1.parse)(url);
    const result = {};
    try {
        Object.entries(params).map(([key, value]) => {
            if (value && allowedProperties.includes(key))
                result[key] = value;
        });
        return result;
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.getParamsFromUrl = getParamsFromUrl;
const transformParamsToFilterParams = (params) => {
    const filters = {};
    const paginate = {};
    const filterParams = {
        status: params.status && params.status?.id,
        createdBy: params.createdBy?.id || params.onlyMine,
        keyword: params.keyword,
        employee: params.employee?.id,
        structuralUnit: params.structuralUnit?.id,
        position: params.position?.id,
        page: params.page,
        perPage: params.perPage,
        inventoryConfigurationId: params.purchaseSubject?.id,
        isForceMajeure: params.isForceMajeure
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if (value && key !== 'page' && key !== 'perPage')
                filters[key] = value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(filters).length === 0)
            return { ...paginate };
        return { ...paginate, filters };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformParamsToFilterParams = transformParamsToFilterParams;
const transformConditionalSubmissionParams = (params) => {
    const updateEdoc = params.status === types_1.ConditionalSubmissionsStatuses.SENT;
    const files = [...params.files, ...params.additionalFiles];
    return {
        accountId: params.structuralUnit.accountId,
        edocNumber: updateEdoc ? params.edocNumber : undefined,
        requestRegistrationDate: params.id && updateEdoc ? (0, helper_1.nullableTransformParamsDate)(params.requestRegistrationDate) : undefined,
        purpose: params.purpose,
        termDate: (0, helper_1.nullableTransformParamsDate)(params.termDate),
        contactPersonId: params.contactPerson.id,
        contactPersonPhoneNumber: params.phoneNumber,
        contactPersonEmail: params.email,
        fileUids: files.map(el => ({ uid: el.id, type: el.type })),
        forceMajeureComment: params.forceMajeureComment
    };
};
exports.transformConditionalSubmissionParams = transformConditionalSubmissionParams;
const transformUrlToFilterParams = (url) => {
    const values = (0, queryString_1.parse)(url);
    return {
        keyword: values.keyword,
        status: values.statusLabel && values.statusId ? { id: values.statusId, label: values.statusLabel } : undefined,
        createdBy: values.createdById && values.createdByLabel ? { id: values.createdById, label: values.createdByLabel } : undefined,
        structuralUnit: values.structuralUnitId && values.structuralUnitLabel ? { id: values.structuralUnitId, label: values.structuralUnitLabel } : undefined,
        employee: values.employeeId && values.employeeLabel ? { id: values.employeeId, label: values.employeeLabel } : undefined,
        page: values.page,
        perPage: values.perPage,
        isForceMajeure: values.isForceMajeure ? Number(values.isForceMajeure) === apiGlobalTypes_1.YesNoStatus.YES : undefined,
        purchaseSubject: values.inventoryConfigurationId && values.inventoryConfigurationLabel
            ? { id: values.inventoryConfigurationId, label: values.inventoryConfigurationLabel }
            : undefined
    };
};
exports.transformUrlToFilterParams = transformUrlToFilterParams;
const transformFilterParamsToUrl = (values) => {
    const { keyword, structuralUnit, createdBy, employee, status, page, perPage, position, purchaseSubject, isForceMajeure } = values;
    return {
        keyword,
        employeeId: employee && employee.id,
        employeeLabel: employee && employee.label,
        createdById: createdBy && createdBy.id,
        createdByLabel: createdBy && createdBy.label,
        structuralUnitId: structuralUnit && structuralUnit.id,
        structuralUnitLabel: structuralUnit && structuralUnit.label,
        positionId: position && position.id,
        positionLabel: position && position.label,
        statusId: status && status.id,
        statusLabel: status && status.label,
        inventoryConfigurationId: purchaseSubject && purchaseSubject.id,
        inventoryConfigurationLabel: purchaseSubject && purchaseSubject.label,
        isForceMajeure: isForceMajeure ? 1 : undefined,
        page,
        perPage
    };
};
exports.transformFilterParamsToUrl = transformFilterParamsToUrl;
// export const transformConditionalSubmissionItem = (value: ConditionalSubmissionItem, sort: number): ConditionalSubmissionItemsParams => {
//   return {
//     items: [{
//       id: value.id,
//       projectId: (value.project as SelectType).id,
//       characteristics: value.characteristics,
//       assignment: value.assignment,
//       dimensionUnitId: (value.dimensionUnit as SelectType).id,
//       inventoryConfigurationId: value.inventoryConfigurationId as ID,
//       quantity: value.quantity as number,
//       bookTitle: value.inventoryConfigurationType === InventoryConfigurationType.BOOK ? value.bookTitle : null,
//       bookAuthor: value.inventoryConfigurationType === InventoryConfigurationType.BOOK ? value.bookAuthor : null,
//       bookIsbnCode: value.inventoryConfigurationType === InventoryConfigurationType.BOOK ? value.bookIsbnCode : null,
//       bookLink: value.inventoryConfigurationType === InventoryConfigurationType.BOOK ? value.bookLink : null,
//       sort
//     }]
//   }
// }
const transformConditionalSubmissionItemsListParams = (values, isCreate) => {
    const minimumSortedNumber = values.reduce((acc, cur) => {
        if (!acc) {
            return cur.sort;
        }
        else {
            if (cur.sort < acc)
                return cur.sort;
            return acc;
        }
    }, 0);
    return {
        items: values.map((el, index) => transformConditionalSubmissionItemListParams(el, (minimumSortedNumber + index))),
        isCreate
    };
};
exports.transformConditionalSubmissionItemsListParams = transformConditionalSubmissionItemsListParams;
const transformCreateSubmissionParams = (values) => {
    return { items: values.map(el => ({ conditionalSubmissionItemId: el })) };
};
exports.transformCreateSubmissionParams = transformCreateSubmissionParams;
const transformConditionalSubmissionItemListParams = (item, sort) => {
    return {
        id: item.id,
        quantity: item.quantity,
        inventoryConfigurationId: item.inventoryConfigurationId,
        dimensionUnitId: item.dimensionUnit?.id,
        assignment: item.assignment,
        characteristics: item.characteristics,
        projectId: item.project?.id,
        bookTitle: item.inventoryConfigurationType === types_2.InventoryConfigurationType.BOOK || item.inventoryConfigurationType === types_2.InventoryConfigurationType.NATIONWIDE ? item.bookTitle : null,
        bookAuthor: item.inventoryConfigurationType === types_2.InventoryConfigurationType.BOOK || item.inventoryConfigurationType === types_2.InventoryConfigurationType.NATIONWIDE ? item.bookAuthor : null,
        bookIsbnCode: item.inventoryConfigurationType === types_2.InventoryConfigurationType.BOOK || item.inventoryConfigurationType === types_2.InventoryConfigurationType.NATIONWIDE ? item.bookIsbnCode : null,
        bookLink: item.inventoryConfigurationType === types_2.InventoryConfigurationType.BOOK || item.inventoryConfigurationType === types_2.InventoryConfigurationType.NATIONWIDE ? item.bookLink : null,
        sort
    };
};
const getConditionalSubmissionItemsParamsFromUrl = (url) => {
    try {
        const params = (0, queryString_1.parse)(url);
        return {
            itemPage: params.itemPage,
            itemPerPage: params.itemPerPage,
            itemsKeyword: params.itemsKeyword
        };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.getConditionalSubmissionItemsParamsFromUrl = getConditionalSubmissionItemsParamsFromUrl;
const transformItemsParams = (params) => {
    return {
        page: params.itemPage,
        perPage: params.itemPerPage,
        sort: params.sort,
        filters: params.itemsKeyword ? { keyword: params.itemsKeyword } : undefined
    };
};
exports.transformItemsParams = transformItemsParams;
const transformDeterminationParams = (values) => {
    const updateEdoc = values.status === types_1.DeterminationsStatuses.SENT;
    const files = [...values.files, ...values.additionalFiles];
    return {
        content: values.content,
        edocNumber: updateEdoc ? values.edocNumber : undefined,
        requestRegistrationDate: values.id && updateEdoc ? (0, helper_1.nullableTransformParamsDate)(values.requestRegistrationDate) : undefined,
        fileUids: files.map(file => ({ uid: file.id, type: file.type }))
    };
};
exports.transformDeterminationParams = transformDeterminationParams;
