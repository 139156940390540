"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRoomsForSelect = exports.updateRoom = exports.createRoom = exports.getRoom = exports.deleteItem = exports.getRooms = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/references/addresses/rooms/parsers");
const getRooms = async (params) => {
    const response = await Api.get('references/administrative-building-rooms', params);
    return (0, parsers_1.parseRooms)(response);
};
exports.getRooms = getRooms;
const deleteItem = async (id) => {
    return await Api.deleteItem(`references/administrative-building-rooms/${id}`);
};
exports.deleteItem = deleteItem;
const getRoom = async (id) => {
    const response = await Api.get('references/administrative-building-rooms/' + id);
    return (0, parsers_1.parseRoom)(response);
};
exports.getRoom = getRoom;
const createRoom = async (params) => {
    const response = await Api.post('references/administrative-building-rooms', params);
    return (0, parsers_1.parseRoom)(response);
};
exports.createRoom = createRoom;
const updateRoom = async (id, params) => {
    const response = await Api.patch('references/administrative-building-rooms/' + id, params);
    return (0, parsers_1.parseRoom)(response);
};
exports.updateRoom = updateRoom;
const getRoomsForSelect = async (params) => {
    const response = await Api.get('references/administrative-building-rooms', params);
    return (0, parsers_1.parseRoomsForSelect)(response);
};
exports.getRoomsForSelect = getRoomsForSelect;
