"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseAttachedPermissions = exports.parsePermissions = void 0;
const helper_1 = require("api/helper");
const parsePermissions = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors };
    const permissions = response.content;
    try {
        const parsedPermissions = permissions.data.map(el => parsePermission(el));
        return {
            data: {
                permissionGroups: parsedPermissions.reduce((groups, permission) => {
                    const permissionGroup = {
                        groupName: permission.groupName,
                        checked: false,
                        groupNameKa: permission.groupNameKa,
                        groupPermissions: []
                    };
                    const oldPermissionGroup = groups.find(el => el.groupName === permission.groupName);
                    if (oldPermissionGroup) {
                        oldPermissionGroup.groupPermissions.push(permission);
                    }
                    else {
                        permissionGroup.groupPermissions.push(permission);
                        groups.push(permissionGroup);
                    }
                    return groups;
                }, []),
                selectedAll: false,
                length: permissions.data.length
            },
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parsePermissions = parsePermissions;
const parsePermission = (data) => {
    const group = data.attributes.name.split('.');
    return {
        id: data.id,
        name: data.attributes.name,
        title: data.attributes.title,
        groupName: group[0],
        groupNameKa: translateGroupName(group[0]),
        checked: false,
        label: group[1]
    };
};
const translateGroupName = (name) => {
    return groupName[name] ?? name;
};
const groupName = {
    user_unit_permissions: 'დეპარტამენტის მართვის უფლებები',
    work_types: 'სამუშაოს სახეები',
    assign_submission_verifies: 'დანიშვნის წარდგინების ვერიფიკაცია',
    assign_submissions: 'დანიშვნის წარდგინებები',
    invoices: 'მიღება ჩაბარებები',
    warehouse: 'საწყობი',
    contracts: 'კონტრაქტები',
    donors: 'დონორები',
    inventory_categories: 'შესყიდვის საგნის კატეგორიები',
    procurements: 'ხელშეკრულებები',
    purchase_procurement_type: 'შესყიდვის ტიპები',
    purchase_verify_item: 'შესყიდვის გადამოწმების აითემი',
    purchase_submission_item: 'შესყიდვის წარდგინების აითემი',
    purchase_submission_verifies: 'შესყიდვის წარდგინების ვერიფიკაცია',
    media_files: 'ფაილები',
    dimension_units: 'განზომილების ერთეულები',
    cpv_codes: 'Cpv კოდები',
    purchase_submissions: 'შესყიდვების წარდგინებები',
    purchase_conditional_submissions: 'შესყიდვების პირობითი წარდგინებები',
    districts: 'რაიონები',
    locations: 'ადგილმდებარეობები',
    regions: 'რეგიონები',
    countries: 'ქვეყნები',
    warranty_terms: 'საგარანტიო ვადები',
    projects: 'პროექტები',
    budget_sources: 'ბიუჯეტის წყაროები',
    funding_sources: 'დაფინანსების წყაროები',
    budget_articles: 'საბიუჯეტო მუხლები',
    inventory_specifications: 'შესყიდვის საგნის მახასიათებლები',
    inventory_configurations: 'შესყიდვის საგნები',
    inventory_items: 'შესყიდვის აითემები',
    structural_units: 'სტრუქტურული ერთეულები',
    contract_types: 'კონტრაქტის ტიპები',
    currencies: 'ვალუტები',
    companies: 'კომპანიები',
    administrative_orders: 'ადმინისტრაციული ბრძანებები',
    position_sub_type: 'თანამდებობის ქვეტიპები',
    position_types: 'თანამდებობის ტიპები',
    position_types_config: 'თანამდებობის ტიპების კონფიგურაცია',
    users: 'მომხმარებლები',
    roles: 'როლები',
    permissions: 'უფლებები',
    vacations: 'შვებულებები',
    legal_basics: 'იურიდიული საფუძვლები',
    auth_logs: 'ავტორიზაციის ჟურნალები',
    accounts: 'ანგარიშები',
    tax_relief: 'საგადასახადო შეღავათი',
    user_contract_files: 'მომხმარებლის კონტრაქტის ფაილები',
    educational_institutions: 'საგანმანათლებო ინსტიტუტები',
    work_directions: 'სამუშაო მიმართულებები',
    major_position_types: 'ძირითადი პოზიციის ტიპები',
    assign_contracts: 'შრომითი ხელშეკრულებები',
    holidays: 'არდადეგები',
    banks: 'ბანკები',
    assign_contract_types: 'შრომითი ხელშეკრულების ტიპები',
    legal_basis: 'იურიდიული საფუძვლები',
    dismissals: 'გათავისუფლებები',
    administrative_building_rooms: 'ოთახები',
    administrative_buildings: 'საუნივერსიტეტო კორპუსები',
    emails: 'გაგზავნილი მეილები',
    user_auth_logs: 'ავტორიზაციების ისტორია'
};
const parseAttachedPermissions = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const userPermissions = response.content;
    try {
        return {
            data: userPermissions.data.map(el => el.id),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseAttachedPermissions = parseAttachedPermissions;
