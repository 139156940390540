"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.holidaysReducer = exports.useHolidaysReducer = exports.initialHolidaysState = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
exports.initialHolidaysState = {
    data: [],
    errors: null,
    status: null,
    meta: store_1.initialMeta,
    isFetching: true,
    isDeleting: false
};
const useHolidaysReducer = () => {
    return (0, react_1.useReducer)(exports.holidaysReducer, exports.initialHolidaysState);
};
exports.useHolidaysReducer = useHolidaysReducer;
const holidaysReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_HOLIDAYS_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_HOLIDAYS_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                status: action.payload.status,
                errors: action.payload.errors,
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'SEND_HOLIDAY_DELETE_REQUEST':
            return {
                ...state,
                isDeleting: true
            };
        case 'DONE_HOLIDAY_DELETE_REQUEST':
            return {
                ...state,
                errors: action.payload.errors,
                isDeleting: false
            };
        case 'CHANGE_PER_PAGE':
            return {
                ...state,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        perPage: action.payload
                    }
                }
            };
        default:
            return state;
    }
};
exports.holidaysReducer = holidaysReducer;
