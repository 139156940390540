"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const BudjetUsers_1 = require("../stores/BudjetUsers");
const react_1 = require("react");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const index_1 = require("api/index");
const helper_1 = require("../helper");
const useAttachUserToProject = (id) => {
    const [state, dispatch] = (0, BudjetUsers_1.useBudgetUsersReducer)();
    const [modalShow, setModalShow] = (0, react_1.useState)(null);
    const attachUser = async (values) => {
        const params = (0, helper_1.transformAttachUsersToProjectParams)(values);
        dispatch({ type: 'SEND_PROJECT_USERS_ATTACH' });
        const attached = await index_1.ProjectsApi.attacheUsersToProject(id, params);
        const payload = attached.errors === null
            ? (0, helper_1.transformAttachUsersToProjectValues)(values, { data: params.userIds, errors: null, status: apiGlobalTypes_1.ResponseStatuses.SUCCESS })
            : { data: null, errors: attached.errors, status: attached.status };
        dispatch({ type: 'DONE_PROJECT_USERS_ATTACH', payload });
        return { errors: attached.errors, status: attached.status };
    };
    const getAttachedUser = async (projectId, users) => {
        dispatch({ type: 'SEND_PROJECT_USERS_REQUEST' });
        const attachedUsersData = await index_1.ProjectsApi.getAttachedUsers(projectId);
        const payload = (0, helper_1.transformAttachUsersToProjectValues)(users, attachedUsersData);
        dispatch({ type: 'DONE_PROJECT_USERS_REQUEST', payload });
    };
    const getBudgetUsers = async () => {
        dispatch({ type: 'SEND_BUDGET_USERS_REQUEST' });
        const employeesData = await index_1.UsersApi.getUsers({ filters: { permission: 'user_unit_permissions.budgeting_dep' } });
        if (employeesData.data !== null) {
            const payload = (0, helper_1.transformBudgetUsersValues)(employeesData);
            dispatch({ type: 'DONE_BUDGET_USERS_REQUEST', payload });
            return getAttachedUser(id, payload.data).then();
        }
        dispatch({ type: 'DONE_BUDGET_USERS_REQUEST', payload: { data: null, errors: employeesData.errors, status: employeesData.status } });
    };
    (0, react_1.useEffect)(() => { if (id)
        getBudgetUsers().then(); }, [id]);
    return { state, attachUser, modalShow, setModalShow, getBudgetUsers };
};
exports.default = useAttachUserToProject;
