"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.data = exports.itemsData = exports.workInfosData = exports.conditionalSubmissionData = exports.lastNames = exports.firstNames = exports.getRandomElement = void 0;
const helper_1 = require("../../common/helper");
const helpers_1 = require("core/helpers");
const getRandomElement = (array) => {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
};
exports.getRandomElement = getRandomElement;
exports.firstNames = ['Nick', 'David', 'Kate', 'Helen', 'James', 'Robert', 'John', 'Michael', 'William', 'Thomas'];
exports.lastNames = ['Smith', 'Brown', 'Johnson', 'Garcia', 'Lopez', 'Gonzales', 'Martinez', 'Rodriguez', 'Moore', 'Lee'];
// DOCUMENTS - Conditional Submission
exports.conditionalSubmissionData = {
    purpose: (0, helpers_1.getHash)() + ' ' + (0, helpers_1.getHash)(),
    termDate: (0, helper_1.transformParamsDate)((0, helper_1.dateToAdd)(null, null, 10)),
    contactPersonId: String((0, helpers_1.getRandomIntBetween)(10, 1)),
    contactPersonPhoneNumber: '5' + Math.random().toString().slice(2, 10),
    contactPersonEmail: (0, helpers_1.getHash)() + '@example.com',
    fileUids: [],
    accountId: '1'
};
// HR - Assign submission fake data
exports.workInfosData = {
    currencyId: null,
    equivalentCurrencyId: null,
    items: null,
    remunerationWork: null,
    salaryAmount: null,
    salaryIssuanceType: null,
    salaryType: 5,
    workTypeId: null,
    workload: 1
};
exports.itemsData = {
    items: (Array(...Array(10))).map(() => {
        return {
            id: null,
            firstname: (0, exports.getRandomElement)(exports.firstNames),
            openCorporateEmail: false,
            openBankAccount: false,
            legalAddress: null,
            email: null,
            passportNumber: null,
            personalNumber: Math.random().toString().slice(2, 13),
            actualAddress: null,
            isEdocUser: false,
            lastname: (0, exports.getRandomElement)(exports.lastNames),
            phoneNumber: '5' + Math.random().toString().slice(2, 10),
            foreignCitizen: false,
            positionInfo: []
        };
    })
};
exports.data = {
    addressees: [],
    contactPersonEmail: 'example@example.com',
    contactPersonId: String((0, helpers_1.getRandomIntBetween)(20, 1)),
    contactPersonPhoneNumber: '5' + Math.random().toString().slice(2, 10),
    purpose: (0, helpers_1.getHash)() + ' ' + (0, helpers_1.getHash)(),
    requesterAccountId: '',
    fileUids: []
};
