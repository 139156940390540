"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const taxes_1 = require("../store/taxes");
const helper_1 = require("../helper");
const react_1 = require("react");
const useTaxReliefs = (props) => {
    const [state, dispatch] = (0, taxes_1.useTaxReducer)();
    const getTaxValues = async (id) => {
        dispatch({ type: 'SEND_TAX_VALUE_REQUEST' });
        const response = await index_1.EmployeesApi.getTaxValues(id);
        dispatch({ type: 'DONE_TAX_VALUE_REQUEST', payload: response });
    };
    const getTaxOptions = async () => {
        dispatch({ type: 'SEND_TAX_OPTIONS_REQUEST' });
        const response = await index_1.EmployeesApi.getTaxOptions();
        dispatch({ type: 'DONE_TAX_OPTIONS_REQUEST', payload: response });
        return response.status;
    };
    const updateTaxValues = async (values, id) => {
        const params = (0, helper_1.transformEmployeeTaxReliefsParams)(values);
        dispatch({ type: 'SEND_TAX_UPDATE' });
        const employeeTaxData = await index_1.EmployeesApi.updateTaxes(params, id);
        dispatch({ type: 'DONE_TAX_UPDATE', payload: employeeTaxData });
        return employeeTaxData;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => {
        if (props.id) {
            getTaxOptions().then(response => {
                if (response === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
                    getTaxValues(props.id).then();
            });
        }
    }, [props.id]);
    return {
        updateTaxValues,
        closeErrorMessage,
        state,
        getTaxValues
    };
};
exports.default = useTaxReliefs;
