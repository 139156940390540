"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const api_1 = require("../../../../api");
const dimensionUnits_1 = require("../store/dimensionUnits");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helpers_1 = require("../helpers");
const queryString_1 = require("core/helpers/queryString");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useDimensionUnits = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, dimensionUnits_1.useDimensionUnitsReducer)();
    const [keyword, setKeyword] = (0, react_1.useState)('');
    const [showDeleteAlert, setShowDeleteAlert] = (0, react_1.useState)(false);
    const [toDeleteId, setToDeleteId] = (0, react_1.useState)(null);
    const isMounted = (0, react_1.useRef)(false);
    const navigatePagination = (page) => {
        const params = (0, queryString_1.parse)(location.search);
        getDimensionUnits({ ...params, page }).then();
    };
    const selectPerPage = (perPage) => {
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'CHANGE_PER_PAGE', payload: perPage });
        getDimensionUnits({ ...params, perPage, page: undefined }).then();
    };
    const deleteAlert = (id) => {
        setShowDeleteAlert(true);
        setToDeleteId(id);
    };
    const debouncedSearch = (value) => {
        setKeyword(value);
        const searchParams = (0, queryString_1.parse)(location.search);
        const params = value
            ? { perPage: searchParams.perPage, filters: { keyword: value } }
            : { page: searchParams.page };
        (0, debouncedMethods_1.debouncedOnFunc)(params, getDimensionUnits);
    };
    const getDimensionUnits = async (params) => {
        dispatch({ type: 'SEND_DIMENSION_UNITS_REQUEST' });
        const dimensionUnits = await api_1.DimensionUnitsApi.getDimensionUnits(params);
        if (isMounted?.current) {
            navigate('/references/dimension-units' + (0, queryString_1.stringify)(params), { replace: true });
            dispatch({ type: 'DONE_DIMENSION_UNITS_REQUEST', payload: dimensionUnits });
        }
    };
    const deleteDimensionUnit = async (confirm) => {
        setShowDeleteAlert(false);
        if (!toDeleteId || !confirm)
            return setToDeleteId(null);
        dispatch({ type: 'SEND_DIMENSION_UNIT_DELETE_REQUEST' });
        const dimensionUnits = await api_1.DimensionUnitsApi.deleteItem(toDeleteId);
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'DONE_DIMENSION_UNIT_DELETE_REQUEST', payload: { errors: dimensionUnits.errors } });
        if (dimensionUnits.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            getDimensionUnits(params).then();
        setToDeleteId(null);
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        const params = (0, helpers_1.getParamsFromUrl)(location.search);
        if (params.perPage)
            dispatch({ type: 'CHANGE_PER_PAGE', payload: params.perPage });
        setKeyword(params.filters?.keyword || '');
        getDimensionUnits(params).then();
        return () => { isMounted.current = false; };
    }, []);
    return {
        state,
        navigatePagination,
        selectPerPage,
        debouncedSearch,
        deleteAlert,
        deleteDimensionUnit,
        toDeleteId,
        showDeleteAlert,
        keyword,
        getDimensionUnits
    };
};
exports.default = useDimensionUnits;
