"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const region_1 = require("../store/region");
const helper_1 = require("../helper");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useRegion = (props) => {
    const [state, dispatch] = (0, region_1.useRegionReducer)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const getRegion = async (id) => {
        dispatch({ type: 'SEND_REGION_REQUEST' });
        const region = await index_1.RegionsApi.getRegion(id);
        dispatch({ type: 'DONE_REGION_REQUEST', payload: region });
    };
    const createRegion = async (values) => {
        const params = (0, helper_1.transformRegionParams)(values);
        dispatch({ type: 'SEND_REGION_CREATE' });
        const region = await index_1.RegionsApi.createRegion(params);
        dispatch({ type: 'DONE_REGION_CREATE', payload: region });
        if (region.data !== null) {
            navigate(`/references/addresses/regions/${region.data.id}/edit`);
        }
        return region;
    };
    const updateRegion = async (id, values) => {
        const params = (0, helper_1.transformRegionParams)(values);
        dispatch({ type: 'SEND_REGION_UPDATE' });
        const region = await index_1.RegionsApi.updateRegion(id, params);
        dispatch({ type: 'DONE_REGION_UPDATE', payload: region });
        return region;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getRegion(props.id).then(); }, [props.id]);
    return {
        state,
        createRegion,
        updateRegion,
        getRegion,
        closeErrorMessage
    };
};
exports.default = useRegion;
