"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const contractSigningList_1 = require("../store/contractSigningList");
const react_1 = require("react");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const queryString_1 = require("core/helpers/queryString");
const helpers_1 = require("../helpers");
const helper_1 = require("../../../../references/employees/helper");
const react_router_dom_1 = require("react-router-dom");
const react_router_1 = require("react-router");
const useContractSigningList = (props) => {
    const [state, dispatch] = (0, contractSigningList_1.useContractSigningListReducer)();
    const [showFilter, setShowFilter] = (0, react_1.useState)(false);
    const [linkParams, setLinkParams] = (0, react_1.useState)('');
    const [selectedItemIds, setSelectedItemIds] = (0, react_1.useState)([]);
    const [showNotifyModal, setShowNotifyModal] = (0, react_1.useState)(null);
    const [openModal, setOpenModal] = (0, react_1.useState)(false);
    const [assignContractId, setAssignContractId] = (0, react_1.useState)(null);
    const navigate = (0, react_router_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const isMounted = (0, react_1.useRef)(false);
    const getContractSigning = async (values) => {
        if (!props.signatoryAccountId)
            return;
        dispatch({ type: 'SEND_CONTRACTS_LIST_REQUEST' });
        const filterParams = (0, helpers_1.transformContractSigningParamsToFilterParams)(values, props.signatoryAccountId);
        const response = await index_1.ContractSigningApi.getContractsSigningList(filterParams);
        if (response.data !== null)
            setLinkParams((0, queryString_1.stringify)((0, helpers_1.transformContractSigningFilterParamsToUrl)(values, props.signatoryAccountId)));
        dispatch({ type: 'DONE_CONTRACTS_LIST_REQUEST', payload: response });
        if (isMounted?.current) {
            dispatch({ type: 'DONE_CONTRACTS_LIST_REQUEST', payload: response });
            navigate('/documents/human-resources/assign-contracts' + (0, queryString_1.stringify)((0, helpers_1.transformContractSigningFilterParamsToUrl)(values, props.signatoryAccountId)), { replace: true });
        }
    };
    const initializeFilterForm = async () => {
        const params = (0, helpers_1.transformUrlToContractSigningFilterParams)(location.search);
        const paginatedStorage = JSON.parse(localStorage.getItem('paginated') || '""');
        params.onlyMine = localStorage.getItem('contractOnlyMine') || undefined;
        const storagePerPage = paginatedStorage?.assignContractListing?.perPage;
        params.perPage = params.perPage || Number(storagePerPage) || 25;
        const isAnyFilterValue = Object.entries(params).some(([key, value]) => {
            if (key === 'page' || key === 'perPage' || key === 'onlyMine')
                return false;
            return !!value;
        });
        if (isAnyFilterValue)
            setShowFilter(true);
        const contractListId = (0, queryString_1.parse)(location.search).contractId;
        getContractSigning(params).then(() => dispatch({ type: 'INITIALIZE_FILTER_FORM', payload: params }));
        setAssignContractId(contractListId);
    };
    const filter = (name, value, filterValues) => {
        getContractSigning({ ...filterValues, [name]: value, page: 1 }).then();
    };
    const navigatePagination = (page, values) => {
        getContractSigning({ ...values, page }).then();
    };
    const selectPerPage = (perPage, values) => {
        getContractSigning({ ...values, perPage, page: 1 }).then();
    };
    const debouncedSearch = (name, value, filterValues) => {
        (0, debouncedMethods_1.debouncedOnFunc)({ ...filterValues, [name]: value, page: 1 }, getContractSigning);
    };
    const notifyPendingContractFiles = async (message, notifyAgain) => {
        setShowNotifyModal(null);
        const params = (0, helper_1.transformNotifyPendingContractFilesParams)(showNotifyModal, selectedItemIds, message);
        dispatch({ type: 'SEND_NOTIFY_PENDING_CONTRACT_FILES' });
        const response = await index_1.EmployeesApi.notifyPendingContractFiles(params, notifyAgain);
        dispatch({ type: 'DONE_NOTIFY_PENDING_CONTRACT_FILES' });
        if (response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS) {
            setSelectedItemIds([]);
            const params = (0, helpers_1.transformUrlToContractSigningFilterParams)(location.search);
            getContractSigning(params).then();
        }
        return response;
    };
    const notifyAllPendingContractFiles = async (message, notifyAgain) => {
        setShowNotifyModal(null);
        const params = (0, helper_1.transformNotifyPendingContractFilesParams)(showNotifyModal, selectedItemIds, message);
        dispatch({ type: 'SEND_NOTIFY_ALL_PENDING_CONTRACT_FILES' });
        const response = await index_1.EmployeesApi.notifyPendingContractFiles(params, notifyAgain);
        dispatch({ type: 'DONE_NOTIFY_ALL_PENDING_CONTRACT_FILES' });
        if (response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS) {
            setSelectedItemIds([]);
            const params = (0, helpers_1.transformUrlToContractSigningFilterParams)(location.search);
            getContractSigning({ ...params, page: 1 }).then();
        }
        return response;
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        initializeFilterForm().then();
        return () => { isMounted.current = false; };
    }, [props.signatoryAccountId]);
    return {
        state,
        getContractSigning,
        debouncedSearch,
        filter,
        navigatePagination,
        selectPerPage,
        showFilter,
        setShowFilter,
        linkParams,
        selectedItemIds,
        setSelectedItemIds,
        showNotifyModal,
        setShowNotifyModal,
        notifyAllPendingContractFiles,
        notifyPendingContractFiles,
        openModal,
        setOpenModal,
        assignContractId
    };
};
exports.default = useContractSigningList;
