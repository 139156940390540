"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useSubmissionItemForm = () => {
    // const [state, dispatch] = useSubmissionItemFormReducer()
    const [startTransition, setStartTransition] = (0, react_1.useState)(false);
    const calculateSummery = (item, changedValue) => {
        const newValue = { ...item, ...changedValue };
        if (newValue.quantity === null || newValue.price === null)
            return 0;
        if (newValue.currency?.label === 'GEL')
            return newValue.quantity * newValue.price;
        const newRate = newValue.currency?.quantity && newValue.currencyExchangeRate
            ? newValue.currencyExchangeRate / newValue.currency.quantity
            : newValue.currencyExchangeRate ? newValue.currencyExchangeRate : 0;
        return newValue.currencyExchangeRate ? newValue.quantity * newValue.price * newRate : null;
    };
    // const updateAndCreateSubmissionItem = async(id: ID, values: SubmissionItemType): Promise<SubmissionItemType | null> => {
    //   const params = transformSubmissionItemFormParams(values)
    //   if (params === null) {
    //     toast.error('something went wrong')
    //     return null
    //   }
    //   dispatch({ type: 'SEND_SUBMISSION_ITEM_UPDATE_OR_CREATE' })
    //   const submissionItem = await SubmissionsApi.updateSubmissionItem(id, params)
    //   dispatch({ type: 'DONE_SUBMISSION_ITEM_UPDATE_OR_CREATE' })
    //   if (submissionItem.data !== null) {
    //     toast.success('მონაცემები განახლდა წარმატებით')
    //     return submissionItem.data[0] as SubmissionItemType
    //   } else {
    //     return null
    //   }
    // }
    return {
        // updateAndCreateSubmissionItem,
        // state,
        startTransition,
        setStartTransition,
        calculateSummery
    };
};
exports.default = useSubmissionItemForm;
