"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useCopyTextElement = () => {
    const [copied, setCopied] = (0, react_1.useState)(false);
    const copyTextElement = (text) => {
        const tempInputElement = document.createElement('input');
        setCopied(true);
        tempInputElement.type = 'text';
        tempInputElement.value = text || '';
        document.body.appendChild(tempInputElement);
        tempInputElement.select();
        document.execCommand('copy');
        document.body.removeChild(tempInputElement);
        setTimeout(() => setCopied(false), 1000);
    };
    return {
        copied,
        copyTextElement
    };
};
exports.default = useCopyTextElement;
