"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const api_1 = require("../../../../api");
const types_1 = require("../types");
const newses_1 = require("../store/newses");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helpers_1 = require("../helpers");
const queryString_1 = require("core/helpers/queryString");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useNewses = (publishedOnly) => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, newses_1.useNewsesReducer)();
    const [keyword, setKeyword] = (0, react_1.useState)('');
    const [showDeleteAlert, setShowDeleteAlert] = (0, react_1.useState)(false);
    const [toDeleteId, setToDeleteId] = (0, react_1.useState)(null);
    const isMounted = (0, react_1.useRef)(false);
    const [showMenu, setShowMenu] = (0, react_1.useState)(false);
    const [showUnreadOnly, setShowUnreadOnly] = (0, react_1.useState)(false);
    const navigatePagination = (page) => {
        const params = (0, queryString_1.parse)(location.search);
        getNewses({ ...params, page }).then();
    };
    const selectPerPage = (perPage) => {
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'CHANGE_PER_PAGE', payload: perPage });
        getNewses({ ...params, perPage, page: undefined }).then();
    };
    const deleteAlert = (id) => {
        setShowDeleteAlert(true);
        setToDeleteId(id);
    };
    const debouncedSearch = (value) => {
        setKeyword(value);
        const searchParams = (0, queryString_1.parse)(location.search);
        const params = value
            ? { perPage: searchParams.perPage, filters: { keyword: value } }
            : { page: searchParams.page };
        (0, debouncedMethods_1.debouncedOnFunc)(params, getNewses);
    };
    const getNewses = async (params) => {
        dispatch({ type: 'SEND_NEWSES_REQUEST' });
        const newses = await api_1.NewsesApi.getNewses(params);
        if (isMounted?.current) {
            if (!publishedOnly)
                navigate('/administer/news' + (0, queryString_1.stringify)(params));
            dispatch({ type: 'DONE_NEWSES_REQUEST', payload: newses });
        }
    };
    const deleteNews = async (confirm) => {
        setShowDeleteAlert(false);
        if (!toDeleteId || !confirm)
            return setToDeleteId(null);
        dispatch({ type: 'SEND_NEWS_DELETE_REQUEST' });
        const newses = await api_1.NewsesApi.deleteItem(toDeleteId);
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'DONE_NEWS_DELETE_REQUEST', payload: { errors: newses.errors } });
        if (newses.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            getNewses(params).then();
        setToDeleteId(null);
    };
    //NOTE These methods are only for Navigation News Bar
    const toggleMenu = () => {
        setShowMenu(prevState => !prevState);
        if (!showMenu) {
            window.addEventListener('click', () => setShowMenu(false), true);
        }
        else {
            window.removeEventListener('click', () => setShowMenu(false), true);
        }
    };
    const markSingleNewsAsRead = async (id) => {
        dispatch({ type: 'SEND_MARK_AS_READ_REQUEST', payload: id });
        const response = await api_1.NewsesApi.markAsRead({ news: [id] });
        dispatch({ type: 'DONE_MARK_AS_READ_REQUEST', payload: { response, id } });
        return response;
    };
    const markAllNewsAsRead = async () => {
        dispatch({ type: 'SEND_MARK_ALL_AS_READ_REQUEST' });
        const response = await api_1.NewsesApi.markAllAsRead();
        dispatch({ type: 'DONE_MARK_ALL_AS_READ_REQUEST', payload: response });
        return response;
    };
    const loadMoreNews = async (page) => {
        dispatch({ type: 'SEND_LOAD_MORE' });
        const newses = await api_1.NewsesApi.getNewses({ page, perPage: 10,
            filters: { published: types_1.Publicity.PUBLISHED, unread: showUnreadOnly ? types_1.ReadStatus.UNREAD : undefined } });
        dispatch({ type: 'DONE_LOAD_MORE', payload: newses });
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        const params = (0, helpers_1.getParamsFromUrl)(location.search);
        if (params.perPage)
            dispatch({ type: 'CHANGE_PER_PAGE', payload: params.perPage });
        setKeyword(params.filters?.keyword || '');
        getNewses({
            ...params, perPage: publishedOnly ? 10 : params.perPage,
            filters: {
                ...params.filters,
                published: publishedOnly ? types_1.Publicity.PUBLISHED : undefined,
                unread: showUnreadOnly ? types_1.ReadStatus.UNREAD : undefined
            }
        }).then();
        return () => { isMounted.current = false; };
    }, [showUnreadOnly]);
    return {
        state,
        navigatePagination,
        selectPerPage,
        debouncedSearch,
        deleteAlert,
        deleteNews,
        toDeleteId,
        showDeleteAlert,
        keyword,
        getNewses,
        toggleMenu,
        showMenu,
        showUnreadOnly,
        setShowUnreadOnly,
        markSingleNewsAsRead,
        markAllNewsAsRead,
        loadMoreNews
    };
};
exports.default = useNewses;
