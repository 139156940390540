"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformDonorFilterParamsToUrl = exports.transformDonorParamsToFilterParams = exports.transformUrlToDonorFilterParams = exports.transformDonorParams = void 0;
const queryString_1 = require("core/helpers/queryString");
const transformDonorParams = (params) => {
    return { name: params.name };
};
exports.transformDonorParams = transformDonorParams;
const transformUrlToDonorFilterParams = (url) => {
    const values = (0, queryString_1.parse)(url);
    return {
        keyword: values.keyword,
        page: values.page,
        perPage: values.perPage
    };
};
exports.transformUrlToDonorFilterParams = transformUrlToDonorFilterParams;
const transformDonorParamsToFilterParams = (params) => {
    const filters = {};
    const paginate = {};
    const filterParams = {
        keyword: params.keyword,
        page: params.page,
        perPage: params.perPage
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if (value && (key !== 'page' && key !== 'perPage'))
                filters[key] = value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(filters).length === 0)
            return { ...paginate };
        return { ...paginate, filters };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformDonorParamsToFilterParams = transformDonorParamsToFilterParams;
const transformDonorFilterParamsToUrl = (values) => {
    const { page, perPage, keyword } = values;
    return {
        page,
        perPage,
        keyword
    };
};
exports.transformDonorFilterParamsToUrl = transformDonorFilterParamsToUrl;
