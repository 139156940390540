"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const budgetingRecord_1 = require("../store/budgetingRecord");
const index_1 = require("api/index");
const react_1 = require("react");
const useBudgetingRecord = (props) => {
    const [state, dispatch] = (0, budgetingRecord_1.useBudgetingRecordListReducer)();
    const getBudgetingInfo = async (contractId) => {
        dispatch({ type: 'SEND_GET_RECORD_REQUEST' });
        const response = await index_1.ContractSigningApi.getBudgetingRecord(contractId);
        dispatch({ type: 'DONE_GET_RECORD_REQUEST', payload: response });
    };
    const budgetingComment = async (contractId, comment) => {
        dispatch({ type: 'SEND_COMMENTING_REQUEST' });
        const response = await index_1.ContractSigningApi.budgetingRecordCommentOrStatus(contractId, { comment });
        dispatch({ type: 'DONE_COMMENTING_REQUEST', payload: response });
        return response;
    };
    const budgetingRecord = async (contractId, subStatus) => {
        dispatch({ type: 'SEND_STATUS_CHANGE_REQUEST' });
        const response = await index_1.ContractSigningApi.budgetingRecordCommentOrStatus(contractId, { subStatus });
        dispatch({ type: 'DONE_STATUS_CHANGE_REQUEST', payload: response });
        return response;
    };
    (0, react_1.useEffect)(() => {
        if (props.contractId)
            getBudgetingInfo(props.contractId).then();
    }, [props.contractId]);
    return {
        state,
        budgetingComment,
        budgetingRecord
    };
};
exports.default = useBudgetingRecord;
