"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformPositionTypeConfigFilterParams = exports.transformPositionTypeConfigParams = exports.getParamsFromUrl = void 0;
const queryString_1 = require("core/helpers/queryString");
const getParamsFromUrl = (url) => {
    try {
        const params = (0, queryString_1.parse)(url);
        return {
            page: params.page,
            perPage: params.perPage,
            filters: params.filters?.keyword ? { keyword: params.filters.keyword } : undefined
        };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.getParamsFromUrl = getParamsFromUrl;
const transformPositionTypeConfigParams = (params) => {
    return {
        data: params.positionNames.map(el => el),
        positionTypeId: params.positionType.id,
        positionSubTypeId: params.positionSubType?.id || null
    };
};
exports.transformPositionTypeConfigParams = transformPositionTypeConfigParams;
const transformPositionTypeConfigFilterParams = (params) => {
    const filters = {};
    const paginate = {};
    const filterParams = {
        keyword: params.keyword,
        positionTypeId: params.positionTypeId?.id,
        positionSubTypeId: params.positionSubTypeId?.id,
        page: params.page,
        perPage: params.perPage
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if (value && key !== 'page' && key !== 'perPage')
                filters[key] = value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(filters).length === 0)
            return { ...paginate };
        return { ...paginate, filters };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformPositionTypeConfigFilterParams = transformPositionTypeConfigFilterParams;
