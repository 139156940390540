"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updatePositionTypeConfig = exports.createPositionTypeConfig = exports.getPositionTypeConfig = exports.deletePositionTypeConfigs = exports.getPositionTypeConfigs = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/references/humanResources/PositionTypeConfigs/parsers");
const getPositionTypeConfigs = async (params) => {
    const response = await Api.get('references/human-resources/position-type-configs', params);
    return (0, parsers_1.parsePositionTypeConfigs)(response);
};
exports.getPositionTypeConfigs = getPositionTypeConfigs;
const deletePositionTypeConfigs = async (id) => {
    return await Api.deleteItem('references/human-resources/position-type-configs/' + id);
};
exports.deletePositionTypeConfigs = deletePositionTypeConfigs;
const getPositionTypeConfig = async (id) => {
    const response = await Api.get('references/human-resources/position-type-configs/' + id);
    return (0, parsers_1.parsePositionTypeConfig)(response);
};
exports.getPositionTypeConfig = getPositionTypeConfig;
const createPositionTypeConfig = async (params) => {
    const response = await Api.post('references/human-resources/position-type-configs', params);
    return (0, parsers_1.parsePositionTypeConfig)(response);
};
exports.createPositionTypeConfig = createPositionTypeConfig;
const updatePositionTypeConfig = async (id, params) => {
    const response = await Api.patch('references/human-resources/position-type-configs/' + id, params);
    return (0, parsers_1.parsePositionTypeConfig)(response);
};
exports.updatePositionTypeConfig = updatePositionTypeConfig;
