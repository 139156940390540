"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseEmployeeContract = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseEmployeeContract = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const contract = response.content;
    const combinedPDF = contract.data.relationships.combinedPdfMediaFile.data;
    const signature = contract.data.relationships.user.data.relationships.activeSignatoryFiles.data[0]?.relationships.signature?.data;
    const latestPdfFile = contract.data.relationships.latestSignatory;
    const signatoryIDs = contract.data.relationships.signatories.data.map(el => el.relationships.userSignatory.data.relationships.forUnAuthorizeUser.data.id);
    try {
        return {
            data: {
                id: contract.data.id,
                userId: contract.data.relationships.user.data.id,
                commentCount: contract.data.attributes.comment_quantity,
                hash: contract.data.attributes.hash,
                signatureFile: (0, parsers_1.parseFile)(signature),
                contractStatus: contract.data.attributes.status,
                contractFileUid: latestPdfFile ? latestPdfFile.data.relationships.pdfMediaFile.data.id : combinedPDF.id,
                signatoryIDs: signatoryIDs,
                signedByMe: signatoryIDs.includes(contract.data.relationships.user.data.id)
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseEmployeeContract = parseEmployeeContract;
// export const parseEmployeeSign = (response: GlobalResponse): EmployeeContractSignData => {
//   if (response.content === null) return { data: null, errors: response.errors, status: response.status }
//   const contract = response.content as ResponseContractSignData
//   const latestPdfFile = contract.data.relationships.latestSignatory
//   try {
//     return {
//       data: {
//         contractStatus: contract.data.attributes.status,
//         contractFileUid: latestPdfFile.data.relationships.pdfMediaFile.data.id,
//       },
//       status: response.status,
//       errors: null
//     }
//   } catch (err) {
//     throwException(err)
//     return errorObject
//   }
// }
