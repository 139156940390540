"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const invoiceFormItems_1 = require("../store/invoiceFormItems");
const index_1 = require("api/index");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helper_1 = require("../helper");
const queryString_1 = require("core/helpers/queryString");
const react_toastify_1 = require("react-toastify");
const react_router_dom_1 = require("react-router-dom");
const usePurchasedItemsHook = (props) => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [keyword, setKeyword] = (0, react_1.useState)('');
    const [isDeletingItemsLoading, setIsDeletingItemsLoading] = (0, react_1.useState)(false);
    const [deletingItems, setDeletingItems] = (0, react_1.useState)([]);
    const [state, dispatch] = (0, invoiceFormItems_1.useInvoiceFormItemsReducer)();
    const [showAlert, setShowAlert] = (0, react_1.useState)(null);
    const getInvoiceItems = async (params) => {
        const itemParams = (0, helper_1.transformItemFilterParamsToUrl)(params);
        const invoiceParams = (0, helper_1.transformFilterParamsToUrl)((0, helper_1.getParamsFromUrl)(location.search));
        dispatch({ type: 'SEND_INVOICE_ITEMS_FORM_REQUEST' });
        const invoiceItems = await index_1.InvoicesApi.getInvoiceItemsForm(props.invoiceId, itemParams); //TODO @Keti gasarkvevia
        navigate((0, queryString_1.stringify)({ ...params, ...invoiceParams }));
        dispatch({ type: 'DONE_INVOICE_ITEMS_FORM_REQUEST', payload: invoiceItems });
        if (invoiceItems.data && invoiceItems.data.length > 0) {
            getUserSignatories(invoiceItems.data).then();
        }
    };
    const debouncedSearch = (value) => {
        setKeyword(value);
        const searchParams = (0, queryString_1.parse)(location.search);
        const params = value
            ? { perPage: searchParams.perPage, filters: { keyword: value } }
            : { page: searchParams.page };
        (0, debouncedMethods_1.debouncedOnFunc)(params, getInvoiceItems);
    };
    const getUserSignatories = async (items) => {
        if (items.length === 0)
            return;
        const params = { filters: { procurementItemIds: items.map(el => el.procurementItemId) } };
        const userSignatories = await index_1.InvoicesApi.getUserSignatories(params);
        props.setSignatories(userSignatories.data);
    };
    const updateInvoiceItemsList = async (id, values, invoiceCreate = false) => {
        const params = (0, helper_1.transformInvoiceItemsListParams)(values);
        dispatch({ type: 'SEND_ITEMS_LIST_UPDATE_REQUEST' });
        const invoiceItems = await index_1.InvoicesApi.updateInvoiceItem(id, params);
        dispatch({ type: 'DONE_ITEMS_LIST_UPDATE_REQUEST', payload: invoiceItems });
        if (invoiceItems.data !== null && !invoiceCreate) {
            react_toastify_1.toast.success('შესყიდვის დოკუმენტის საგანი/საგნები განახლდა წარმატებით');
        }
        return invoiceItems.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS;
    };
    const selectPerPage = (itemPerPage) => {
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'CHANGE_PER_PAGE', payload: itemPerPage });
        getInvoiceItems({ ...params, itemPerPage, itemPage: undefined }).then();
    };
    const navigatePagination = (itemPage) => {
        const params = (0, queryString_1.parse)(location.search);
        getInvoiceItems({ ...params, itemPage }).then();
    };
    const deleteInvoiceItem = async (ids, id) => {
        setIsDeletingItemsLoading(true);
        const response = await index_1.InvoicesApi.deleteInvoiceItem(id, { ids });
        if (response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            setDeletingItems([]);
        setIsDeletingItemsLoading(false);
        return response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS;
    };
    (0, react_1.useEffect)(() => {
        const params = (0, helper_1.getItemsParamsFromUrl)(location.search);
        const paginatedStorage = JSON.parse(localStorage.getItem('paginated') || '""');
        const storagePerPage = paginatedStorage?.invoiceItems?.perPage;
        params.itemPerPage = params.itemPerPage || Number(storagePerPage) || 50;
        dispatch({ type: 'CHANGE_PER_PAGE', payload: params.itemPerPage });
        setKeyword(params.itemsKeyword || '');
        if (props.invoiceId)
            getInvoiceItems(params).then();
    }, [props.invoiceId]);
    return {
        isDeletingItemsLoading,
        deleteInvoiceItem,
        setDeletingItems,
        deletingItems,
        state,
        getUserSignatories,
        debouncedSearch,
        keyword,
        updateInvoiceItemsList,
        selectPerPage,
        navigatePagination,
        setShowAlert,
        showAlert
    };
};
exports.default = usePurchasedItemsHook;
