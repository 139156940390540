"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userReducer = exports.useUserReducer = exports.initialUserState = void 0;
const react_1 = require("react");
const types_1 = require("api/references/employees/types");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const initialUserValue = {
    firstname: '',
    lastname: '',
    fathersName: '',
    identityNumber: '',
    idCardValidTo: '',
    passportNumber: '',
    gender: types_1.EmployeeGender.FEMALE,
    country: null,
    birthDate: '',
    address: '',
    mobileNumber: '',
    workPhoneNumber: '',
    personalEmail: '',
    email: '',
    status: apiGlobalTypes_1.SystemStatus.ACTIVE,
    edocUser: false,
    password: null,
    password_confirmation: null
    //systemUser:  null
};
exports.initialUserState = {
    data: initialUserValue,
    status: null,
    errors: null,
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isLoading: false
};
const useUserReducer = () => {
    return (0, react_1.useReducer)(exports.userReducer, exports.initialUserState);
};
exports.useUserReducer = useUserReducer;
const userReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_USER_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_USER_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isFetching: false,
                isLoading: state.isCreating || state.isUpdating
            };
        case 'SEND_USER_CREATE':
            return {
                ...state,
                errors: null,
                isCreating: true,
                isLoading: true
            };
        case 'DONE_USER_CREATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isCreating: false,
                isLoading: state.isFetching || state.isUpdating
            };
        case 'SEND_USER_UPDATE':
            return {
                ...state,
                errors: null,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_USER_UPDATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isUpdating: false,
                isLoading: state.isCreating || state.isFetching
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.userReducer = userReducer;
