"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const types_1 = require("api/references/employees/types");
const OrderNumberLabel = (props) => {
    return ((0, jsx_runtime_1.jsxs)("label", { className: 'form-control-label required m-0', htmlFor: 'documentNumber', children: [props.dismissalReasonId === String(types_1.DismissalReasonType.OWN_WILL)
                || props.dismissalReasonId === String(types_1.DismissalReasonType.INABILITY_TO_WORK)
                || props.dismissalReasonId === String(types_1.DismissalReasonType.INCOMPATIBILITY_WORK)
                || props.dismissalReasonId === String(types_1.DismissalReasonType.COUNT_VERDICT)
                || props.dismissalReasonId === String(types_1.DismissalReasonType.DEATH)
                || props.dismissalReasonId === String(types_1.DismissalReasonType.OTHER_OBJECTIVE_CIRCUMSTANCE)
                || props.dismissalReasonId === String(types_1.DismissalReasonType.REDUCING_NECESSARY_WORKFORCE)
                || props.dismissalReasonId === String(types_1.DismissalReasonType.COURT_RECOGNITION_AS_LIMITED_CAPACITY)
                || props.dismissalReasonId === String(types_1.DismissalReasonType.REACHING_65_ADMINISTRATIVE_POSITION)
                || props.dismissalReasonId === String(types_1.DismissalReasonType.PREREQUISITE_FOR_ADMINISTRATIVE_POSITION)
                || props.dismissalReasonId === String(types_1.DismissalReasonType.NON_FULFILMENT_CERTIFICATION_CONDITIONS)
                || props.dismissalReasonId === String(types_1.DismissalReasonType.INITIATION_OF_UNIVERSITY_LIQUIDATION_PROCEEDING)
                || props.dismissalReasonId === String(types_1.DismissalReasonType.VIOLATION_OBLIGATIONS)
                || props.dismissalReasonId === String(types_1.DismissalReasonType.GROSS_VIOLATION_OBLIGATIONS)
                ? 'ბრძანება'
                : props.dismissalReasonId === String(types_1.DismissalReasonType.TERMINATION_OF_FUNDING)
                    || props.dismissalReasonId === String(types_1.DismissalReasonType.ABOLITION_OF_SCIENTIFIC_RESEARCH_UNIT)
                    ? 'გადაწყვეტილება'
                    : 'შეთანხმების', " N"] }));
};
exports.default = OrderNumberLabel;
