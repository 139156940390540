"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parsePurchasingMessage = exports.parseBudgetingMessage = exports.parseSubmissionVerifyItemStatusName = exports.parseGroupedCpvCodes = exports.parseVerifySubmission = exports.parseSubmissionVerifiesItemsActions = exports.parseSubmissionVerifiesItemsV2 = exports.parseSubmissionVerifies = void 0;
const types_1 = require("api/documents/purchases/submissionVerifies/types");
const parsers_1 = require("api/parsers/parsers");
const helper_1 = require("api/helper");
const parsers_2 = require("api/references/funding/projects/parsers");
const parsers_3 = require("api/documents/purchases/procurements/parsers");
const parseSubmissionVerifies = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const submissionVerifies = response.content;
    try {
        return {
            data: submissionVerifies.data.map(el => {
                const structuralUnit = el.relationships.submission.data.relationships.account.data.relationships.contract.data.relationships.structuralUnit;
                const project = el.relationships.submission.data.relationships.account.data.relationships.contract.data.relationships.project;
                return {
                    id: el.id,
                    moduleType: el.type,
                    createdBy: el.relationships.createdBy.data.relationships.user.data.attributes.fullname,
                    number: el.relationships.submission?.data.attributes.number,
                    structuralUnit: structuralUnit ? structuralUnit.data.attributes.fullName : 'პროექტის "' + project?.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი',
                    author: el.relationships.submission?.data.relationships.account.data.relationships.user.data.attributes.fullname,
                    procurementStatus: el.attributes.procurementStatus,
                    procurementVisualStatus: parseSubmissionVerifyPurchaseVisualStatus(el.attributes.procurementStatus),
                    date: (0, parsers_1.transformDate)(el.relationships.submission?.data.attributes.date),
                    dateTime: (0, parsers_1.transformDateTime)(el.relationships.submission?.data.attributes.date),
                    executionDate: (0, parsers_1.transformDate)(el.relationships.submission?.data.attributes.executionDate),
                    executionDateTime: (0, parsers_1.transformDateTime)(el.relationships.submission?.data.attributes.executionDate),
                    expirationDate: (0, parsers_1.transformDate)(el.relationships.submission?.data.attributes.expirationDate),
                    expirationDateTime: (0, parsers_1.transformDateTime)(el.relationships.submission?.data.attributes.expirationDate),
                    visualStatus: parseSubmissionVerifyVisualStatus(el.attributes.status),
                    status: el.attributes.status,
                    hasForceMajeureComment: el.attributes.hasForceMajeureComment,
                    sumPrices: el.attributes.priceData?.map(item => {
                        return {
                            sumPrice: item.sumPrice,
                            activeSumPrice: item.activeSumPrice,
                            code: item.code
                        };
                    }) || []
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(submissionVerifies.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseSubmissionVerifies = parseSubmissionVerifies;
const parseSubmissionVerifiesItemsV2 = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const items = response.content;
    try {
        return {
            data: parseSubmissionVerifiesItemsData(items.data),
            meta: items.meta ? (0, parsers_1.transformPagination)(items.meta) : undefined,
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseSubmissionVerifiesItemsV2 = parseSubmissionVerifiesItemsV2;
const parseSubmissionVerifiesItemsActions = (response) => {
    //TODO @Merab აქშენების დროს მოდის სხვანაირად (მოდის წარდგინების გადამოწმება და რეაციად მოყვება აითემები). კარგი იქნება ბექს თუ გადავაკეთებინებთ
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const verifySubmission = response.content.data;
    try {
        return {
            data: parseSubmissionVerifiesItemsData(verifySubmission.relationships.items.data),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseSubmissionVerifiesItemsActions = parseSubmissionVerifiesItemsActions;
const parseSubmissionVerifiesItemsData = (items) => {
    return items.map(el => {
        const procurementItem = el.relationships.procurementItem;
        const currency = el.relationships.currency.data;
        const project = el.relationships.project.data;
        const transformedRate = currency.attributes.rate
            ? currency.attributes.rate / (currency.attributes.quantity || 1)
            : 1;
        const purchaseSubject = {
            subjectName: el.relationships.inventoryConfiguration.data.attributes.itemTitle,
            specificationName: el.relationships.inventoryConfiguration.data.attributes.specificationTitle || '',
            categoryName: el.relationships.inventoryConfiguration.data.attributes.categoryTitle || '',
            verified: el.relationships.inventoryConfiguration.data.attributes.verified
        };
        const budgetArticle = el.relationships.budgetArticle?.data;
        return {
            purchaseSubject,
            id: el.id,
            parentId: el.relationships.parent && el.relationships.parent.data.id || null,
            procurement: (procurementItem && {
                id: procurementItem.data.relationships.document.data.id,
                number: procurementItem.data.relationships.document.data.attributes.signedDocumentNumber
                    || procurementItem.data.relationships.document.data.attributes.number,
                itemPrice: procurementItem.data.attributes.price || 0,
                status: procurementItem.data.relationships.document.data.attributes.status
            }) || null,
            procurementTerminationReason: el.attributes.procurementTerminationReason,
            status: el.relationships.submission?.data.attributes.status,
            currencyExchangeRate: el.attributes.currencyExchangeRate,
            quantity: el.attributes.quantity,
            originalQuantity: el.relationships.submissionItem && el.relationships.submissionItem.data.attributes.quantity || null,
            price: el.attributes.price,
            characteristics: el.attributes.characteristics,
            assignment: el.attributes.assignment,
            purchaseDeclineComment: el.attributes.purchaseDeclineComment,
            purchaseSuspendComment: el.attributes.purchaseSuspendComment,
            budgetingSuspendComment: el.attributes.budgetingSuspendComment,
            budgetingDeclineComment: el.attributes.budgetingDeclineComment,
            budgetingStatusMessage: (0, exports.parseBudgetingMessage)(el),
            purchasingStatusMessage: (0, exports.parsePurchasingMessage)(el),
            purchaseStatus: el.attributes.purchaseStatus,
            budgetingStatus: el.attributes.budgetingStatus,
            hasLogs: el.attributes.hasLogs,
            budgetingVisualStatus: (0, exports.parseSubmissionVerifyItemStatusName)(el.attributes.budgetingStatus),
            purchaseVisualStatus: (0, exports.parseSubmissionVerifyItemStatusName)(el.attributes.purchaseStatus),
            inPurchase: el.attributes.inPurchase,
            actuallyDoneComment: el.attributes.purchaseActuallyDoneComment,
            budgetingStatusUpdatedAt: (0, parsers_1.nullableTransformDate)(el.attributes.budgetingStatusUpdatedAt),
            deletedAt: (0, parsers_1.nullableTransformDate)(el.attributes.deletedAt),
            budgetingStatusUpdatedBy: el.relationships.budgetingStatusAccount?.data.relationships.user.data.attributes.fullname,
            purchaseStatusUpdatedAt: (0, parsers_1.nullableTransformDate)(el.attributes.purchaseStatusUpdatedAt),
            purchaseStatusUpdatedBy: el.relationships.purchaseStatusAccount?.data.relationships.user.data.attributes.fullname,
            inventoryConfigurationId: el.relationships.inventoryConfiguration.data.id,
            cpvCode: {
                id: el.relationships.cpvCode.data.id,
                label: el.relationships.cpvCode.data.attributes.code + ' - ' + el.relationships.cpvCode.data.attributes.name,
                name: el.relationships.cpvCode.data.attributes.name,
                code: el.relationships.cpvCode.data.attributes.code
            },
            currency: {
                id: currency.id,
                label: currency.attributes.code,
                currentExchangeRate: currency.attributes.rate,
                quantity: currency.attributes.quantity,
                transformedRate
            },
            gelSummery: el.relationships.currency.data.attributes.code !== 'GEL'
                ? currency.attributes.quantity
                    ? el.attributes.quantity * el.attributes.price * (el.attributes.currencyExchangeRate / currency.attributes.quantity)
                    : null
                : el.attributes.quantity * el.attributes.price,
            dimensionUnit: {
                id: el.relationships.dimensionUnit.data.id,
                label: el.relationships.dimensionUnit.data.attributes.name
            },
            project: (0, parsers_2.parseProjectSelectType)(project),
            budgetArticle: budgetArticle && {
                id: budgetArticle.id,
                name: budgetArticle.attributes.name,
                code: budgetArticle.attributes.code,
                label: budgetArticle.attributes.code + ' - ' + budgetArticle.attributes.name
            } || null,
            templateType: parsers_3.templateTypes.find(type => Number(type.id) === el.attributes.templateType) || null,
            bookLink: el.attributes.bookLink,
            bookTitle: el.attributes.bookTitle,
            bookIsbnCode: el.attributes.bookIsbnCode,
            bookAuthor: el.attributes.bookAuthor,
            inventoryConfigurationType: el.relationships.inventoryConfiguration.data.attributes.type
        };
    });
};
const parseVerifySubmission = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const verifySubmission = response.content.data;
    try {
        const structuralUnit = verifySubmission.relationships.submission.data.relationships.account.data.relationships.contract.data.relationships.structuralUnit;
        const project = verifySubmission.relationships.submission.data.relationships.account.data.relationships.contract.data.relationships.project;
        const verifyAction = verifySubmission.relationships.verifyActions.data.pop();
        return {
            data: {
                id: verifySubmission.id,
                hasForceMajeureComment: verifySubmission.attributes.hasForceMajeureComment,
                actionType: parseSubmissionVerifyActionType(verifyAction?.attributes.structuralUnitType),
                comment: verifySubmission.attributes.comment || '',
                modify: verifySubmission.attributes.modify,
                createdBy: verifySubmission.relationships.createdBy.data.relationships.user.data.attributes.fullname,
                number: verifySubmission.relationships.submission.data.attributes.number,
                date: (0, parsers_1.transformDate)(verifySubmission.relationships.submission.data.attributes.date),
                status: verifySubmission.attributes.status,
                expirationDate: (0, parsers_1.transformDate)(verifySubmission.relationships.submission.data.attributes.expirationDate),
                executionDate: (0, parsers_1.transformDate)(verifySubmission.relationships.submission.data.attributes.executionDate),
                alert: (0, parsers_1.checkSubmissionAlert)(verifySubmission.relationships.submission.data.attributes.executionDate),
                employee: {
                    id: verifySubmission.id,
                    label: verifySubmission.relationships.submission.data.relationships.account.data.relationships.user.data.attributes.fullname +
                        `${verifySubmission.relationships.submission.data.relationships.account.data.relationships.user.data.attributes.email
                            ? ` (${verifySubmission.relationships.submission.data.relationships.account.data.relationships.user.data.attributes.email})` : ''}`
                },
                structuralUnit: {
                    id: verifySubmission.id,
                    label: structuralUnit ? structuralUnit.data.attributes.name : 'პროექტის "' + project?.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი'
                },
                visualStatus: parseSubmissionVerifyVisualStatus(verifySubmission.attributes.status),
                subVisualStatus: parseSubmissionVerifyPurchaseVisualStatus(verifySubmission.attributes.procurementStatus),
                files: verifySubmission.relationships.submission.data.relationships.mediaFiles?.data.map(file => {
                    return {
                        id: file.id,
                        name: file.attributes.name,
                        status: file.attributes.status,
                        originalName: file.attributes.originalName,
                        link: file.attributes.downloadUrl,
                        extension: file.attributes.extension
                    };
                })
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseVerifySubmission = parseVerifySubmission;
const parseGroupedCpvCodes = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors };
    const groupedCpvCodes = response.content;
    try {
        return {
            data: groupedCpvCodes.data.attributes.map(el => {
                return {
                    name: el.code + ' - ' + el.name,
                    sumPrice: el.sumPrice
                };
            }),
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseGroupedCpvCodes = parseGroupedCpvCodes;
const parseSubmissionVerifyVisualStatus = (status) => {
    switch (status) {
        case types_1.VerifySubmissionStatus.CHECKING_PROCESS:
            return { name: 'გადამოწმების პროცესი', color: 'secondary' };
        case types_1.VerifySubmissionStatus.VERIFICATION_PROCESS:
            return { name: 'ვერიფიკაციის პროცესი', color: 'info' };
        case types_1.VerifySubmissionStatus.CONFIRMATION_PROCESS:
            return { name: 'დადასტურების პროცესი', color: 'sky-color' };
        case types_1.VerifySubmissionStatus.CONFIRMED:
            return { name: 'დადასტურებული', color: 'success' };
        case types_1.VerifySubmissionStatus.PARTIALLY_CONFIRMED:
            return { name: 'ნაწილობრივ დადასტურებული', color: 'primary' };
        case types_1.VerifySubmissionStatus.DECLINED:
            return { name: 'უარყოფილი', color: 'danger' };
        case types_1.VerifySubmissionStatus.SUSPENDED:
            return { name: 'შეჩერებული', color: 'warning' };
        default:
            return { name: 'გადამოწმების პროცესი', color: 'secondary' };
    }
};
const parseSubmissionVerifyPurchaseVisualStatus = (status) => {
    switch (status) {
        case types_1.VerifySubmissionProcurementStatus.NOT_IN_PROCESS:
            return { name: 'არ არის შესყიდვის პროცესში', color: 'secondary' };
        case types_1.VerifySubmissionProcurementStatus.IN_PROCESS:
            return { name: 'შესყიდვის პროცესში', color: 'success' };
        case types_1.VerifySubmissionProcurementStatus.PARTIALLY_IN_PROCESS:
            return { name: 'ნაწილობრივ შესყიდვის პროცესში', color: 'primary' };
        default:
            return { name: 'გადამოწმების პროცესი', color: 'secondary' };
    }
};
const parseSubmissionVerifyItemStatusName = (status) => {
    switch (status) {
        case types_1.SubmissionVerifyItemStatus.DRAFT:
            return { name: 'პროექტი', color: 'secondary' };
        case types_1.SubmissionVerifyItemStatus.SUSPENDED:
            return { name: 'შეჩერებული', color: 'warning' };
        case types_1.SubmissionVerifyItemStatus.APPROVED:
            return { name: 'დადასტურებული', color: 'success' };
        case types_1.SubmissionVerifyItemStatus.DECLINED:
            return { name: 'უარყოფილი', color: 'danger' };
        default:
            return { name: 'პროექტი', color: 'secondary' };
    }
};
exports.parseSubmissionVerifyItemStatusName = parseSubmissionVerifyItemStatusName;
const parseBudgetingMessage = (item) => {
    const name = item.relationships.budgetingStatusAccount?.data.relationships.user.data.attributes.fullname;
    const updatedBy = name && item.attributes.budgetingStatusUpdatedAt
        ? name + ' - ' + (0, parsers_1.transformDate)(item.attributes.budgetingStatusUpdatedAt)
        : 'System';
    switch (item.attributes.budgetingStatus) {
        case types_1.SubmissionVerifyItemStatus.DRAFT:
            return updatedBy;
        case types_1.SubmissionVerifyItemStatus.APPROVED:
            return updatedBy;
        case types_1.SubmissionVerifyItemStatus.DECLINED:
            return item.attributes.budgetingDeclineComment && (item.attributes.budgetingDeclineComment + ' - ' + updatedBy) || updatedBy;
        case types_1.SubmissionVerifyItemStatus.SUSPENDED:
            return item.attributes.budgetingSuspendComment && (item.attributes.budgetingSuspendComment + ' - ' + updatedBy) || updatedBy;
        default:
            return updatedBy;
    }
};
exports.parseBudgetingMessage = parseBudgetingMessage;
const parsePurchasingMessage = (item) => {
    const name = item.relationships.purchaseStatusAccount?.data.relationships.user.data.attributes.fullname;
    const updatedBy = name && item.attributes.purchaseStatusUpdatedAt
        ? name + ' - ' + (0, parsers_1.transformDate)(item.attributes.purchaseStatusUpdatedAt)
        : 'System';
    switch (item.attributes.purchaseStatus) {
        case types_1.SubmissionVerifyItemStatus.DRAFT:
            return updatedBy;
        case types_1.SubmissionVerifyItemStatus.APPROVED:
            return updatedBy;
        case types_1.SubmissionVerifyItemStatus.DECLINED:
            return item.attributes.purchaseDeclineComment && (item.attributes.purchaseDeclineComment + ' - ' + updatedBy) || updatedBy;
        case types_1.SubmissionVerifyItemStatus.SUSPENDED:
            return item.attributes.purchaseSuspendComment && (item.attributes.purchaseSuspendComment + ' - ' + updatedBy) || updatedBy;
        default:
            return updatedBy;
    }
};
exports.parsePurchasingMessage = parsePurchasingMessage;
const parseSubmissionVerifyActionType = (status) => {
    switch (status) {
        case 'budgeting':
            return types_1.VerifyActionType.PURCHASES; // როცა ბიუჯეტი იყო ბოლო მაშინ არის შესყიდვები და პირიქით
        case 'purchases':
            return types_1.VerifyActionType.BUDGETING;
        default:
            return types_1.VerifyActionType.PURCHASES;
    }
};
