"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const authHistories_1 = require("../store/authHistories");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const queryString_1 = require("core/helpers/queryString");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useAuthHistories = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, authHistories_1.useAuthHistoriesReducer)();
    const isMounted = (0, react_1.useRef)(false);
    const [showFilter, setShowFilter] = (0, react_1.useState)(false);
    const navigatePagination = (page, filterValues) => {
        getAuthHistories({ ...filterValues, page }).then();
    };
    const initializeFilterForm = async () => {
        const params = (0, helpers_1.transformUrlToFilterParams)(location.search);
        if (params.user || params.ip)
            setShowFilter(true);
        dispatch({ type: 'INITIALIZE_FILTER_FORM', payload: params });
        getAuthHistories(params).then();
    };
    const filter = (name, value, filterValues) => {
        getAuthHistories({ ...filterValues, [name]: value, page: 1 }).then();
    };
    const selectPerPage = (perPage, filterValues) => {
        getAuthHistories({ ...filterValues, perPage, page: 1 }).then();
    };
    const debouncedSearch = (name, value, filterValues) => {
        (0, debouncedMethods_1.debouncedOnFunc)({ ...filterValues, [name]: value, page: 1 }, getAuthHistories);
    };
    const getAuthHistories = async (values) => {
        dispatch({ type: 'SEND_AUTH_HISTORIES_REQUEST' });
        const filterParams = (0, helpers_1.transformParamsToFilterParams)(values);
        const authHistories = await index_1.AuthHistoriesApi.getAuthHistories(filterParams);
        if (authHistories.data !== null)
            (0, queryString_1.stringify)((0, helpers_1.transformFilterParamsToUrl)(values));
        if (isMounted?.current) {
            navigate('/administer/auth-histories' + (0, queryString_1.stringify)((0, helpers_1.transformFilterParamsToUrl)(values)), { replace: true });
            dispatch({ type: 'DONE_AUTH_HISTORIES_REQUEST', payload: authHistories });
        }
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        initializeFilterForm().then();
        return () => { isMounted.current = false; };
    }, []);
    return {
        state,
        navigatePagination,
        selectPerPage,
        debouncedSearch,
        getAuthHistories,
        setShowFilter,
        showFilter,
        filter
    };
};
exports.default = useAuthHistories;
