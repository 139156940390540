"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const constants_1 = require("./helpers/constants");
const MediaFileIcon = (props) => {
    const item = constants_1.fileExtensions.find(el => el.extension === props.extension);
    {
        return item ? (0, jsx_runtime_1.jsx)("i", { className: `far fa-file-${item.className} mr-2` }) : (0, jsx_runtime_1.jsx)("i", { className: 'far fa-file mr-2' });
    }
};
exports.default = MediaFileIcon;
