"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateCountry = exports.createCountry = exports.getCountry = exports.deleteCountry = exports.getCountries = exports.getLanguagesForSelect = exports.getCountriesForSelect = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/references/addresses/countries/parsers");
const getCountriesForSelect = async (params) => {
    const response = await Api.get('references/geography/countries', params);
    return (0, parsers_1.parseCountriesForSelect)(response);
};
exports.getCountriesForSelect = getCountriesForSelect;
const getLanguagesForSelect = async (params) => {
    const response = await Api.get('references/geography/languages', params);
    return (0, parsers_1.parseLanguagesForSelect)(response);
};
exports.getLanguagesForSelect = getLanguagesForSelect;
const getCountries = async (params) => {
    const response = await Api.get('references/geography/countries', params);
    return (0, parsers_1.parseCountries)(response);
};
exports.getCountries = getCountries;
const deleteCountry = async (id) => {
    return await Api.deleteItem('references/geography/countries/' + id);
};
exports.deleteCountry = deleteCountry;
const getCountry = async (id) => {
    const response = await Api.get('references/geography/countries/' + id);
    return (0, parsers_1.parseCountry)(response);
};
exports.getCountry = getCountry;
const createCountry = async (params) => {
    const response = await Api.post('references/geography/countries', params);
    return (0, parsers_1.parseCountry)(response);
};
exports.createCountry = createCountry;
const updateCountry = async (id, params) => {
    const response = await Api.patch('references/geography/countries/' + id, params);
    return (0, parsers_1.parseCountry)(response);
};
exports.updateCountry = updateCountry;
