"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSubmissionVerifyItems = void 0;
const index_1 = require("api/index");
const submissionVerifyItems_1 = require("../store/submissionVerifyItems");
const react_1 = require("react");
const useSubmissionVerifyItems = (id) => {
    const [state, dispatch] = (0, submissionVerifyItems_1.useSubmissionVerifyItemsReducer)();
    const getSubmissionVerifyItems = async (page) => {
        const params = page ? { page } : {};
        dispatch({ type: 'SEND_SUBMISSION_VERIFY_ITEMS_REQUEST' });
        const items = await index_1.SubmissionVerifiesApi.getSubmissionVerifyItemsV2(id, params);
        dispatch({ type: 'DONE_SUBMISSION_VERIFY_ITEMS_REQUEST', payload: items });
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => {
        getSubmissionVerifyItems().then();
    }, []);
    return { state, getSubmissionVerifyItems, closeErrorMessage };
};
exports.useSubmissionVerifyItems = useSubmissionVerifyItems;
