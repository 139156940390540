"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.languageReducer = exports.useLanguageReducer = exports.initialLanguageState = exports.initialLanguageValue = exports.initialLanguageFormValue = void 0;
const react_1 = require("react");
exports.initialLanguageFormValue = {
    id: null,
    language: null,
    level: null
};
exports.initialLanguageValue = {
    languages: [],
    language: exports.initialLanguageFormValue
};
exports.initialLanguageState = {
    data: exports.initialLanguageValue,
    status: null,
    errors: null,
    isFetching: false,
    isLoading: false,
    isCreatingOrUpdatingLanguage: false,
    deletingLanguage: false
};
const useLanguageReducer = () => {
    return (0, react_1.useReducer)(exports.languageReducer, exports.initialLanguageState);
};
exports.useLanguageReducer = useLanguageReducer;
const languageReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_LANGUAGES_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_LANGUAGES_REQUEST':
            return {
                ...state,
                data: {
                    ...state.data,
                    languages: action.payload.data
                },
                status: action.payload.status,
                errors: action.payload.errors,
                isFetching: false,
                isLoading: state.isCreatingOrUpdatingLanguage
            };
        case 'SEND_LANGUAGE_CREATE_OR_UPDATE':
            return {
                ...state,
                isCreatingOrUpdatingLanguage: true,
                isLoading: true
            };
        case 'DONE_LANGUAGE_CREATE_OR_UPDATE':
            return {
                ...state,
                data: {
                    ...state.data,
                    languages: action.payload.data || state.data.languages
                },
                errors: action.payload.errors,
                isCreatingOrUpdatingLanguage: false,
                isLoading: state.isFetching
            };
        case 'SEND_DELETE_LANGUAGE':
            return {
                ...state,
                deletingLanguage: true
            };
        case 'DONE_DELETE_LANGUAGE':
            return {
                ...state,
                deletingLanguage: false,
                errors: action.payload.errors
            };
        case 'HIGHLIGHT_LANGUAGE':
            return {
                ...state,
                data: {
                    ...state.data,
                    languages: state.data.languages
                        ? state.data.languages.map(item => ({
                            ...item,
                            language: {
                                ...item.language,
                                alert: item.language?.id === action.payload.languageId ? 'bg-warning-animation' : undefined
                            }
                        }))
                        : state.data.languages
                }
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.languageReducer = languageReducer;
