"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const index_1 = require("api/index");
const vacations_1 = require("../store/vacations");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const queryString_1 = require("core/helpers/queryString");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useVacations = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, vacations_1.useVacationsReducer)();
    const isMounted = (0, react_1.useRef)(false);
    const [showDeleteAlert, setShowDeleteAlert] = (0, react_1.useState)(false);
    const [toDeleteId, setToDeleteId] = (0, react_1.useState)(null);
    const [showFilter, setShowFilter] = (0, react_1.useState)(false);
    const getVacations = async (values) => {
        dispatch({ type: 'SEND_VACATIONS_REQUEST' });
        const filterParams = (0, helpers_1.transformVacationParamsToFilterParams)(values);
        const vacations = await index_1.VacationsApi.getVacations(filterParams);
        if (isMounted?.current) {
            navigate('/documents/human-resources/vacations' + (0, queryString_1.stringify)((0, helpers_1.transformFilterParamsToUrl)(values)), { replace: true });
            dispatch({ type: 'DONE_VACATIONS_REQUEST', payload: vacations });
        }
    };
    const selectPerPage = (perPage, values) => {
        dispatch({ type: 'CHANGE_PER_PAGE', payload: { ...values, perPage } });
        getVacations({ ...values, perPage, page: 1 }).then();
    };
    const deleteAlert = (id) => {
        setShowDeleteAlert(true);
        setToDeleteId(id);
    };
    const debouncedSearch = (name, value, filterValues) => {
        (0, debouncedMethods_1.debouncedOnFunc)({ ...filterValues, [name]: value, page: 1 }, getVacations);
    };
    const deleteVacation = async (confirm) => {
        setShowDeleteAlert(false);
        if (!toDeleteId || !confirm)
            return setToDeleteId(null);
        dispatch({ type: 'SEND_VACATION_DELETE_REQUEST' });
        const vacations = await index_1.VacationsApi.deleteVacation(toDeleteId);
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'DONE_VACATION_DELETE_REQUEST', payload: { errors: vacations.errors } });
        if (vacations.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            getVacations(params).then();
        setToDeleteId(null);
    };
    const navigatePagination = (page, values) => {
        getVacations({ ...values, page }).then();
    };
    const filter = (name, value, filterValues) => {
        getVacations({ ...filterValues, [name]: value, page: 1 }).then();
    };
    const initializeFilterForm = async () => {
        const params = (0, helpers_1.transformUrlToFilterParams)(location.search);
        if (params.user || params.structuralUnit || params.startDate || params.endDate)
            setShowFilter(true);
        dispatch({ type: 'INITIALIZE_FILTER_FORM', payload: params });
        getVacations(params).then();
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        initializeFilterForm().then();
        return () => { isMounted.current = false; };
    }, []);
    return {
        state,
        getVacations,
        deleteVacation,
        navigatePagination,
        selectPerPage,
        debouncedSearch,
        deleteAlert,
        showDeleteAlert,
        toDeleteId,
        filter,
        setShowFilter,
        showFilter
    };
};
exports.default = useVacations;
