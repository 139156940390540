"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const regions_1 = require("../store/regions");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helper_1 = require("../helper");
const queryString_1 = require("core/helpers/queryString");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useRegions = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, regions_1.useRegionsReducer)();
    const [keyword, setKeyword] = (0, react_1.useState)('');
    const isMounted = (0, react_1.useRef)(false);
    const navigatePagination = (page) => {
        const params = (0, queryString_1.parse)(location.search);
        getRegions({ ...params, page }).then();
    };
    const selectPerPage = (perPage) => {
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'CHANGE_PER_PAGE', payload: perPage });
        getRegions({ ...params, perPage, page: undefined }).then();
    };
    const debouncedSearch = (value) => {
        setKeyword(value);
        const searchParams = (0, queryString_1.parse)(location.search);
        const params = value
            ? { perPage: searchParams.perPage, filters: { keyword: value } }
            : { page: searchParams.page };
        (0, debouncedMethods_1.debouncedOnFunc)(params, getRegions);
    };
    const getRegions = async (params) => {
        dispatch({ type: 'SEND_REGIONS_REQUEST' });
        const regions = await index_1.RegionsApi.getRegions(params);
        if (isMounted?.current) {
            navigate('/references/addresses/regions' + (0, queryString_1.stringify)(params));
            dispatch({ type: 'DONE_REGIONS_REQUEST', payload: regions });
        }
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        const params = (0, helper_1.getParamsFromUrl)(location.search);
        if (params.perPage)
            dispatch({ type: 'CHANGE_PER_PAGE', payload: params.perPage });
        setKeyword(params.filters?.keyword || '');
        getRegions(params).then();
        return () => { isMounted.current = false; };
    }, []);
    return {
        state,
        getRegions,
        keyword,
        debouncedSearch,
        navigatePagination,
        selectPerPage
    };
};
exports.default = useRegions;
