"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseMyFile = exports.parseMyFiles = void 0;
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const helper_1 = require("api/helper");
const constants_1 = require("src/components/files/helpers/constants");
const parsers_1 = require("api/parsers/parsers");
const parseMyFiles = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const myFiles = response.content;
    try {
        return {
            data: myFiles.data.map(el => parseMyFileData(el)),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(myFiles.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseMyFiles = parseMyFiles;
const parseMyFile = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const myFile = response.content;
    try {
        return {
            data: parseMyFileData(myFile.data),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseMyFile = parseMyFile;
const parseMyFileData = (mediaFile) => {
    return {
        id: mediaFile.id,
        status: mediaFile.attributes.status,
        name: mediaFile.attributes.name,
        originalName: mediaFile.attributes.originalName,
        link: mediaFile.attributes.downloadUrl,
        size: (mediaFile.attributes.size / (1024 * 1024)).toFixed(2) + ' MB',
        disk: mediaFile.attributes.disk,
        userFullName: mediaFile.relationships.creator
            ? mediaFile.relationships.creator.data.relationships.user.data.attributes.fullname
            : null,
        extension: mediaFile.attributes.extension,
        createdAt: (0, parsers_1.transformDate)(mediaFile.attributes.createdAt),
        createdDateTimeAt: (0, parsers_1.transformDateTime)(mediaFile.attributes.createdAt),
        updatedAt: (0, parsers_1.transformDate)(mediaFile.attributes.updatedAt),
        updatedDateTimeAt: (0, parsers_1.transformDateTime)(mediaFile.attributes.updatedAt),
        canPreview: !!constants_1.fileExtensions.find(file => file.extension === mediaFile.attributes.extension && !file.allowDownload),
        visualStatus: parseMyFileVisualStatuses(mediaFile.attributes.status)
    };
};
const parseMyFileVisualStatuses = (status) => {
    switch (status) {
        case apiGlobalTypes_1.FileDownloadStatus.UNCONFIRMED:
            return { name: 'დაუდასტურებელი', color: 'danger' };
        case apiGlobalTypes_1.FileDownloadStatus.CONFIRMED:
            return { name: 'დადასტურებული', color: 'success' };
        case apiGlobalTypes_1.FileDownloadStatus.TEMPORARY:
            return { name: 'დროებითი', color: 'secondary' };
        case apiGlobalTypes_1.FileDownloadStatus.TRANSFERRING:
            return { name: 'გადაცემის პროცესში', color: 'warning' };
    }
};
