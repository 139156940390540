"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const api_1 = require("src/api");
const useMainHeader = (props) => {
    const [headerCascadeMenu, setHeaderCascadeMenu] = (0, react_1.useState)(null);
    const [profileHeight, setProfileHeight] = (0, react_1.useState)(200);
    const [unreadQuantities, setUnreadQuantities] = (0, react_1.useState)({ notifications: 0, news: 0 });
    let unreadReFetchInterval;
    const asideMenuRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        updateWindowDimensions();
    }, []);
    const updateWindowDimensions = () => {
        setProfileHeight(window.innerHeight - 120);
    };
    const cascadeMenu = (name) => {
        const newHeaderCascadeMenu = name !== headerCascadeMenu ? name : null;
        setHeaderCascadeMenu(newHeaderCascadeMenu);
        asideMenuRef.current = newHeaderCascadeMenu;
        if (newHeaderCascadeMenu)
            window.addEventListener('click', closeCascadeMenu, true);
    };
    const closeCascadeMenu = (event) => {
        const isOpenCascade = asideMenuRef.current
            ? document.querySelector(`#${asideMenuRef.current}`)?.contains(event.target)
            : false;
        if (isOpenCascade && !event.target.id.includes('close'))
            return;
        window.removeEventListener('click', closeCascadeMenu, true);
        asideMenuRef.current = null;
        setHeaderCascadeMenu(null);
    };
    const getUnreadQuantities = async () => {
        const response = await api_1.HeaderApi.getUnreadQuantities();
        if (response.data)
            setUnreadQuantities(response.data);
    };
    (0, react_1.useEffect)(() => {
        if (props.authUserId) {
            setTimeout(() => getUnreadQuantities().then(), 5000);
            unreadReFetchInterval = setInterval(() => getUnreadQuantities().then(), 5 * 60 * 1000);
        }
        else {
            clearInterval(unreadReFetchInterval);
        }
        return () => clearInterval(unreadReFetchInterval);
    }, [props.authUserId]);
    return {
        headerCascadeMenu,
        profileHeight,
        cascadeMenu,
        setHeaderCascadeMenu,
        unreadQuantities
    };
};
exports.default = useMainHeader;
