"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseTaxOptions = exports.parseTaxValues = void 0;
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const helper_1 = require("api/helper");
const parseTaxValues = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const taxes = response.content;
    try {
        return {
            data: {
                taxReliefBenefit: taxes.data.attributes.taxReliefBenefit ? apiGlobalTypes_1.YesNoStatus.YES : apiGlobalTypes_1.YesNoStatus.NO,
                useOfTaxRelief: taxes.data.attributes.useOfTaxRelief,
                taxRelief: taxes.data.relationships.taxRelief ? taxes.data.relationships.taxRelief.data.id : null,
                taxReliefAmount: taxes.data.attributes.taxReliefAmount ?? null
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseTaxValues = parseTaxValues;
const parseTaxOptions = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const options = response.content;
    try {
        return {
            data: options.data.map(option => {
                return {
                    id: option.id,
                    name: option.attributes.name,
                    type: option.attributes.type,
                    checked: false
                };
            }),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseTaxOptions = parseTaxOptions;
