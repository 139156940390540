"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.warehouseReducer = exports.useWarehouseReducer = exports.initialWarehouseState = void 0;
const react_1 = require("react");
const initialWarehouseValue = { city: '', address: '' };
exports.initialWarehouseState = {
    data: initialWarehouseValue,
    status: null,
    errors: null,
    isFetching: false,
    isCreating: false,
    isLoading: false,
    isUpdating: false
};
const useWarehouseReducer = () => {
    return (0, react_1.useReducer)(exports.warehouseReducer, exports.initialWarehouseState);
};
exports.useWarehouseReducer = useWarehouseReducer;
const warehouseReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_WAREHOUSE_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_WAREHOUSE_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isFetching: false,
                isLoading: state.isCreating || state.isUpdating
            };
        case 'SEND_WAREHOUSE_CREATE':
            return {
                ...state,
                errors: null,
                isCreating: true,
                isLoading: true
            };
        case 'DONE_WAREHOUSE_CREATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isCreating: false,
                isLoading: state.isFetching || state.isUpdating
            };
        case 'SEND_WAREHOUSE_UPDATE':
            return {
                ...state,
                errors: null,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_WAREHOUSE_UPDATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isUpdating: false,
                isLoading: state.isCreating || state.isFetching
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.warehouseReducer = warehouseReducer;
