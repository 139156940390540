"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getParamsFromUrl = exports.transformFundingSourceParams = void 0;
const types_1 = require("api/references/funding/funding-sources/types");
const queryString_1 = require("core/helpers/queryString");
const transformFundingSourceParams = (params) => {
    return {
        name: params.name,
        budgetingCheck: params.budgetingCheck ? types_1.BudgetCheck.APPROVED : types_1.BudgetCheck.NOT_APPROVED,
        positionStatus: {
            staff: params.staff,
            freelance: params.freelance
        }
    };
};
exports.transformFundingSourceParams = transformFundingSourceParams;
const getParamsFromUrl = (url) => {
    try {
        const params = (0, queryString_1.parse)(url);
        return {
            page: params.page,
            perPage: params.perPage,
            filters: params.filters?.keyword ? { keyword: params.filters.keyword } : undefined
        };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.getParamsFromUrl = getParamsFromUrl;
