"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const employee_1 = require("../store/employee");
const index_1 = require("api/index");
const store_1 = require("core/store");
const queryString_1 = require("core/helpers/queryString");
const helper_1 = require("../helper");
const react_router_dom_1 = require("react-router-dom");
const useEmployee = (props) => {
    const [deleteFamilyMemberAlert, setDeleteFamilyMemberAlert] = (0, react_1.useState)({ showAlert: false, id: null });
    const [state, dispatch] = (0, employee_1.useEmployeeReducer)();
    const [buttonNavigate, setButtonNavigate] = (0, react_1.useState)(employee_1.initialEmployeeNavigate);
    const [employeeParamsIds, setEmployeeParamsIds] = (0, react_1.useState)({ employeeId: [], meta: store_1.initialMeta });
    const [listingParams, setListingParams] = (0, react_1.useState)('');
    const [shouldDocumentFetch, setShouldDocumentFetch] = (0, react_1.useState)(0);
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const getEmployee = async (id) => {
        dispatch({ type: 'SEND_EMPLOYEE_REQUEST' });
        const employee = await index_1.EmployeesApi.getEmployee(id);
        dispatch({ type: 'DONE_EMPLOYEE_REQUEST', payload: employee });
    };
    const createEmployee = async (values) => {
        const params = (0, helper_1.transformEmployeeParams)(values);
        dispatch({ type: 'SEND_EMPLOYEE_CREATE' });
        const employee = await index_1.EmployeesApi.createEmployee(params);
        dispatch({ type: 'DONE_EMPLOYEE_CREATE', payload: employee });
        if (employee.data !== null) {
            navigate(`/references/employees/${employee.data.id}/edit`);
        }
        return employee;
    };
    const updateEmployee = async (id, values) => {
        const params = (0, helper_1.transformEmployeeParams)(values);
        dispatch({ type: 'SEND_EMPLOYEE_UPDATE' });
        const employee = await index_1.EmployeesApi.updateEmployee(id, params);
        dispatch({ type: 'DONE_EMPLOYEE_UPDATE', payload: employee });
        return employee;
    };
    const addFamilyMember = async (values, id) => {
        const params = (0, helper_1.transformEmployeeFamilyMemberParams)(values);
        dispatch({ type: 'SEND_ADD_EMPLOYEE_FAMILY_MEMBER' });
        const employeeFamilyMemberData = await index_1.EmployeesApi.addFamilyMember(params, id);
        dispatch({ type: 'DONE_ADD_EMPLOYEE_FAMILY_MEMBER', payload: employeeFamilyMemberData });
        return employeeFamilyMemberData;
    };
    const deleteFamilyMember = async (confirm) => {
        if (!confirm || !deleteFamilyMemberAlert.id)
            return setDeleteFamilyMemberAlert({ showAlert: false, id: null });
        setDeleteFamilyMemberAlert({ ...deleteFamilyMemberAlert, showAlert: false });
        dispatch({ type: 'SEND_DELETE_EMPLOYEE_FAMILY_MEMBER' });
        const employee = await index_1.EmployeesApi.deleteFamilyMember(props.id, deleteFamilyMemberAlert.id);
        dispatch({ type: 'DONE_DELETE_EMPLOYEE_FAMILY_MEMBER', payload: { employee, id: deleteFamilyMemberAlert.id } });
        setDeleteFamilyMemberAlert({ showAlert: false, id: null });
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    const getEmployeeIds = async (value) => {
        const employeesParams = (0, helper_1.transformEmployeeParamsToFilterParams)({ ...value }, props.accountId);
        const employees = await index_1.EmployeesApi.getEmployees(employeesParams);
        if (employees.data !== null && employees.meta) {
            const responseIds = {
                employeeId: employees.data.map(el => el.id), meta: employees.meta
            };
            const searchParams = (0, queryString_1.stringify)({ ...(0, helper_1.transformEmployeeFilterParamsToUrl)(value, props.accountId) });
            setListingParams(searchParams || '?');
            setEmployeeParamsIds(responseIds);
            return responseIds;
        }
        return null;
    };
    const nextEmployee = async () => {
        setButtonNavigate(prevState => ({ ...prevState, next: true, isLoading: true }));
        const currentIndex = employeeParamsIds.employeeId.indexOf(props.id);
        const nextEmployee = employeeParamsIds.employeeId[currentIndex + 1];
        const { pagination } = employeeParamsIds.meta;
        const params = (0, helper_1.transformEmployeeFilterParamsToUrl)((0, helper_1.transformUrlToEmployeeFilterParams)(location.search), props.accountId);
        if (nextEmployee) {
            navigate('/references/employees/' + nextEmployee + '/edit' + (0, queryString_1.stringify)({ ...params }));
            const lastElement = pagination.currentPage === pagination.totalPages && employeeParamsIds.employeeId.length === currentIndex + 2;
            setButtonNavigate(prevState => ({ ...prevState, next: false, firstElement: false, lastElement, isLoading: false }));
        }
        else {
            if (pagination.currentPage === pagination.totalPages) {
                setButtonNavigate(prevState => ({ ...prevState, next: false, lastElement: true, isLoading: false }));
                return;
            }
            const formValues = (0, helper_1.transformUrlToEmployeeFilterParams)(location.search);
            const response = await getEmployeeIds({ ...formValues, page: pagination.currentPage + 1 });
            if (response && response.employeeId[0]) {
                formValues.page = response.meta.pagination.currentPage;
                const urlParams = { ...(0, helper_1.transformEmployeeFilterParamsToUrl)(formValues, props.accountId), page: response.meta.pagination.currentPage };
                navigate('/references/employees/' + response.employeeId[0] + '/edit' + (0, queryString_1.stringify)(urlParams));
                const lastElement = response.meta.pagination.currentPage === response.meta.pagination.totalPages && response.employeeId.length === 1;
                setButtonNavigate(prevState => ({ ...prevState, next: false, firstElement: false, lastElement, isLoading: false }));
            }
        }
    };
    const previousEmployee = async () => {
        setButtonNavigate(prevState => ({ ...prevState, previous: true, isLoading: true }));
        const currentIndex = employeeParamsIds.employeeId.indexOf(props.id);
        const previousEmployee = employeeParamsIds.employeeId[currentIndex - 1];
        const { pagination } = employeeParamsIds.meta;
        const params = (0, helper_1.transformEmployeeFilterParamsToUrl)((0, helper_1.transformUrlToEmployeeFilterParams)(location.search), props.accountId);
        if (previousEmployee) {
            navigate('/references/employees/' + previousEmployee + '/edit' + (0, queryString_1.stringify)({ ...params }));
            const firstElement = pagination.currentPage === 1 && currentIndex === 1;
            setButtonNavigate(prevState => ({ ...prevState, previous: false, lastElement: false, firstElement, isLoading: false }));
        }
        else {
            if (pagination.currentPage === 1) {
                setButtonNavigate(prevState => ({ ...prevState, previous: false, firstElement: true, isLoading: false }));
                return;
            }
            const formValues = (0, helper_1.transformUrlToEmployeeFilterParams)(location.search);
            const response = await getEmployeeIds({ ...formValues, page: pagination.currentPage - 1 });
            if (response) {
                const prevEmployees = response.employeeId[response.employeeId.length - 1];
                if (prevEmployees) {
                    formValues.page = response.meta.pagination.currentPage;
                    const urlParams = { ...(0, helper_1.transformEmployeeFilterParamsToUrl)(formValues, props.accountId), page: response.meta.pagination.currentPage };
                    navigate('/references/employees/' + prevEmployees + '/edit' + (0, queryString_1.stringify)(urlParams));
                    const lastElement = response.meta.pagination.currentPage === response.meta.pagination.totalPages && response.employeeId.length === 1;
                    setButtonNavigate(prevState => ({ ...prevState, previous: false, firstElement: false, lastElement, isLoading: false }));
                }
            }
        }
    };
    (0, react_1.useEffect)(() => {
        const params = (0, helper_1.transformUrlToEmployeeFilterParams)(location.search);
        getEmployeeIds(params).then(response => {
            if (response) {
                const { currentPage, totalPages } = response.meta.pagination;
                const employeeIndex = response.employeeId.indexOf(props.id);
                if (employeeIndex === 0 && currentPage === 1)
                    setButtonNavigate(prevState => ({ ...prevState, firstElement: true }));
                if (currentPage === totalPages && employeeIndex === response.employeeId.length - 1) {
                    setButtonNavigate(prevState => ({ ...prevState, lastElement: true }));
                }
            }
        });
    }, []);
    (0, react_1.useEffect)(() => {
        if (props.id)
            getEmployee(props.id).then();
    }, [props.id]);
    return {
        state,
        createEmployee,
        updateEmployee,
        getEmployee,
        closeErrorMessage,
        addFamilyMember,
        deleteFamilyMember,
        setDeleteFamilyMemberAlert,
        deleteFamilyMemberAlert,
        listingParams,
        buttonNavigate,
        nextEmployee,
        previousEmployee,
        setShouldDocumentFetch,
        shouldDocumentFetch
    };
};
exports.default = useEmployee;
