"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotifyModalType = exports.ContractAndAnnexSubStatuses = exports.ContractAndAnnexStatuses = exports.ContractStatuses = exports.TAX_RELIEF_TYPE = exports.Funding = exports.Citizen = exports.AssignTypeForEmployee = exports.DismissalReasonType = exports.EdocUserStatus = exports.LanguageLevel = exports.AcademicDegree = exports.Assign = exports.EducationType = exports.EmployeeGender = exports.EmployeeEducationFileTypes = exports.EmployeeDocumentFileTypes = exports.SignatoryFileStatus = exports.FileStatus = void 0;
var FileStatus;
(function (FileStatus) {
    FileStatus[FileStatus["PASSIVE"] = 0] = "PASSIVE";
    FileStatus[FileStatus["ACTIVE"] = 1] = "ACTIVE";
})(FileStatus || (exports.FileStatus = FileStatus = {}));
var SignatoryFileStatus;
(function (SignatoryFileStatus) {
    SignatoryFileStatus[SignatoryFileStatus["PASSIVE"] = 0] = "PASSIVE";
    SignatoryFileStatus[SignatoryFileStatus["ACTIVE"] = 1] = "ACTIVE";
    SignatoryFileStatus[SignatoryFileStatus["PENDING"] = 2] = "PENDING";
})(SignatoryFileStatus || (exports.SignatoryFileStatus = SignatoryFileStatus = {}));
var EmployeeDocumentFileTypes;
(function (EmployeeDocumentFileTypes) {
    EmployeeDocumentFileTypes[EmployeeDocumentFileTypes["CV"] = 1] = "CV";
    EmployeeDocumentFileTypes[EmployeeDocumentFileTypes["IDENTITY_PASSPORT_RESIDENCE"] = 2] = "IDENTITY_PASSPORT_RESIDENCE";
    EmployeeDocumentFileTypes[EmployeeDocumentFileTypes["CONVICTION"] = 3] = "CONVICTION";
    EmployeeDocumentFileTypes[EmployeeDocumentFileTypes["TAX_BENEFIT"] = 4] = "TAX_BENEFIT";
    EmployeeDocumentFileTypes[EmployeeDocumentFileTypes["HEALTH_NOTICE"] = 5] = "HEALTH_NOTICE";
    EmployeeDocumentFileTypes[EmployeeDocumentFileTypes["DRIVING_LICENSE"] = 6] = "DRIVING_LICENSE";
    EmployeeDocumentFileTypes[EmployeeDocumentFileTypes["PWD_NOTICE_TYPE"] = 7] = "PWD_NOTICE_TYPE";
    EmployeeDocumentFileTypes[EmployeeDocumentFileTypes["OTHER"] = 8] = "OTHER";
})(EmployeeDocumentFileTypes || (exports.EmployeeDocumentFileTypes = EmployeeDocumentFileTypes = {}));
var EmployeeEducationFileTypes;
(function (EmployeeEducationFileTypes) {
    EmployeeEducationFileTypes[EmployeeEducationFileTypes["DIPLOMA"] = 1] = "DIPLOMA";
    EmployeeEducationFileTypes[EmployeeEducationFileTypes["OTHER"] = 2] = "OTHER";
})(EmployeeEducationFileTypes || (exports.EmployeeEducationFileTypes = EmployeeEducationFileTypes = {}));
var EmployeeGender;
(function (EmployeeGender) {
    EmployeeGender[EmployeeGender["MALE"] = 1] = "MALE";
    EmployeeGender[EmployeeGender["FEMALE"] = 2] = "FEMALE";
})(EmployeeGender || (exports.EmployeeGender = EmployeeGender = {}));
var EducationType;
(function (EducationType) {
    EducationType[EducationType["COMPLETE"] = 1] = "COMPLETE";
    EducationType[EducationType["INCOMPLETE"] = 2] = "INCOMPLETE";
})(EducationType || (exports.EducationType = EducationType = {}));
var Assign;
(function (Assign) {
    Assign[Assign["QUALIFICATION"] = 1] = "QUALIFICATION";
    Assign[Assign["ACADEMIC_DEGREE"] = 2] = "ACADEMIC_DEGREE";
})(Assign || (exports.Assign = Assign = {}));
var AcademicDegree;
(function (AcademicDegree) {
    AcademicDegree[AcademicDegree["BACHELOR"] = 1] = "BACHELOR";
    AcademicDegree[AcademicDegree["MASTER"] = 2] = "MASTER";
    AcademicDegree[AcademicDegree["EQ_MASTER"] = 3] = "EQ_MASTER";
    AcademicDegree[AcademicDegree["DOCTOR"] = 4] = "DOCTOR";
    AcademicDegree[AcademicDegree["EQ_DOCTOR"] = 5] = "EQ_DOCTOR"; //equivalent to doctor
})(AcademicDegree || (exports.AcademicDegree = AcademicDegree = {}));
var LanguageLevel;
(function (LanguageLevel) {
    LanguageLevel[LanguageLevel["A1"] = 1] = "A1";
    LanguageLevel[LanguageLevel["A2"] = 2] = "A2";
    LanguageLevel[LanguageLevel["B1"] = 3] = "B1";
    LanguageLevel[LanguageLevel["B2"] = 4] = "B2";
    LanguageLevel[LanguageLevel["C1"] = 5] = "C1";
    LanguageLevel[LanguageLevel["C2"] = 6] = "C2";
    LanguageLevel[LanguageLevel["NATIVE"] = 7] = "NATIVE";
})(LanguageLevel || (exports.LanguageLevel = LanguageLevel = {}));
var EdocUserStatus;
(function (EdocUserStatus) {
    EdocUserStatus[EdocUserStatus["NOT_USER"] = 0] = "NOT_USER";
    EdocUserStatus[EdocUserStatus["USER"] = 1] = "USER";
})(EdocUserStatus || (exports.EdocUserStatus = EdocUserStatus = {}));
var DismissalReasonType;
(function (DismissalReasonType) {
    DismissalReasonType[DismissalReasonType["END_DATE"] = 1] = "END_DATE";
    DismissalReasonType[DismissalReasonType["JOB_PERFORMANCE"] = 2] = "JOB_PERFORMANCE";
    DismissalReasonType[DismissalReasonType["OWN_WILL"] = 3] = "OWN_WILL";
    DismissalReasonType[DismissalReasonType["AGREEMENT"] = 4] = "AGREEMENT";
    DismissalReasonType[DismissalReasonType["GROSS_VIOLATION_OBLIGATIONS"] = 5] = "GROSS_VIOLATION_OBLIGATIONS";
    DismissalReasonType[DismissalReasonType["VIOLATION_ETHIC_CODE"] = 6] = "VIOLATION_ETHIC_CODE";
    DismissalReasonType[DismissalReasonType["INABILITY_TO_WORK"] = 7] = "INABILITY_TO_WORK";
    DismissalReasonType[DismissalReasonType["INCOMPATIBILITY_WORK"] = 8] = "INCOMPATIBILITY_WORK";
    DismissalReasonType[DismissalReasonType["COUNT_VERDICT"] = 9] = "COUNT_VERDICT";
    DismissalReasonType[DismissalReasonType["DEATH"] = 10] = "DEATH";
    DismissalReasonType[DismissalReasonType["OTHER_OBJECTIVE_CIRCUMSTANCE"] = 11] = "OTHER_OBJECTIVE_CIRCUMSTANCE";
    DismissalReasonType[DismissalReasonType["VIOLATION_OBLIGATIONS"] = 12] = "VIOLATION_OBLIGATIONS";
    DismissalReasonType[DismissalReasonType["REDUCING_NECESSARY_WORKFORCE"] = 13] = "REDUCING_NECESSARY_WORKFORCE";
    DismissalReasonType[DismissalReasonType["COURT_RECOGNITION_AS_LIMITED_CAPACITY"] = 14] = "COURT_RECOGNITION_AS_LIMITED_CAPACITY";
    DismissalReasonType[DismissalReasonType["REACHING_65_ADMINISTRATIVE_POSITION"] = 15] = "REACHING_65_ADMINISTRATIVE_POSITION";
    DismissalReasonType[DismissalReasonType["PREREQUISITE_FOR_ADMINISTRATIVE_POSITION"] = 16] = "PREREQUISITE_FOR_ADMINISTRATIVE_POSITION";
    DismissalReasonType[DismissalReasonType["OPTION_EXPIRATION"] = 17] = "OPTION_EXPIRATION";
    DismissalReasonType[DismissalReasonType["AWARDING_EMERITUS"] = 18] = "AWARDING_EMERITUS";
    DismissalReasonType[DismissalReasonType["NON_FULFILMENT_CERTIFICATION_CONDITIONS"] = 19] = "NON_FULFILMENT_CERTIFICATION_CONDITIONS";
    DismissalReasonType[DismissalReasonType["FIGHT_AGAINST_CRIMES_AGAINST_SEXUAL_FREEDOM_AND_INTEGRITY"] = 20] = "FIGHT_AGAINST_CRIMES_AGAINST_SEXUAL_FREEDOM_AND_INTEGRITY";
    DismissalReasonType[DismissalReasonType["TERMINATION_OF_FUNDING"] = 21] = "TERMINATION_OF_FUNDING";
    DismissalReasonType[DismissalReasonType["ABOLITION_OF_SCIENTIFIC_RESEARCH_UNIT"] = 22] = "ABOLITION_OF_SCIENTIFIC_RESEARCH_UNIT";
    DismissalReasonType[DismissalReasonType["INITIATION_OF_UNIVERSITY_LIQUIDATION_PROCEEDING"] = 23] = "INITIATION_OF_UNIVERSITY_LIQUIDATION_PROCEEDING";
    DismissalReasonType[DismissalReasonType["CHANGE_POSITION"] = 24] = "CHANGE_POSITION";
})(DismissalReasonType || (exports.DismissalReasonType = DismissalReasonType = {}));
var AssignTypeForEmployee;
(function (AssignTypeForEmployee) {
    AssignTypeForEmployee[AssignTypeForEmployee["CONTRACT"] = 1] = "CONTRACT";
    AssignTypeForEmployee[AssignTypeForEmployee["ORDER"] = 2] = "ORDER";
    AssignTypeForEmployee[AssignTypeForEmployee["ORDER_CONTRACT"] = 3] = "ORDER_CONTRACT";
    AssignTypeForEmployee[AssignTypeForEmployee["EXTENSION_CONTRACT_PERIOD"] = 4] = "EXTENSION_CONTRACT_PERIOD";
})(AssignTypeForEmployee || (exports.AssignTypeForEmployee = AssignTypeForEmployee = {}));
var Citizen;
(function (Citizen) {
    Citizen[Citizen["GEORGIAN"] = 1] = "GEORGIAN";
    Citizen[Citizen["FOREIGN"] = 2] = "FOREIGN";
})(Citizen || (exports.Citizen = Citizen = {}));
var Funding;
(function (Funding) {
    Funding[Funding["NO"] = 0] = "NO";
    Funding[Funding["YES"] = 1] = "YES";
})(Funding || (exports.Funding = Funding = {}));
var TAX_RELIEF_TYPE;
(function (TAX_RELIEF_TYPE) {
    TAX_RELIEF_TYPE[TAX_RELIEF_TYPE["THREE_THOUSAND_INCOME"] = 1] = "THREE_THOUSAND_INCOME";
    TAX_RELIEF_TYPE[TAX_RELIEF_TYPE["SIX_THOUSAND_INCOME"] = 2] = "SIX_THOUSAND_INCOME";
})(TAX_RELIEF_TYPE || (exports.TAX_RELIEF_TYPE = TAX_RELIEF_TYPE = {}));
var ContractStatuses;
(function (ContractStatuses) {
    ContractStatuses[ContractStatuses["ACTIVE"] = 1] = "ACTIVE";
    ContractStatuses[ContractStatuses["PASSIVE"] = 2] = "PASSIVE";
    ContractStatuses[ContractStatuses["EXPIRED"] = 3] = "EXPIRED";
    ContractStatuses[ContractStatuses["CORRECTED"] = 4] = "CORRECTED";
})(ContractStatuses || (exports.ContractStatuses = ContractStatuses = {}));
var ContractAndAnnexStatuses;
(function (ContractAndAnnexStatuses) {
    ContractAndAnnexStatuses[ContractAndAnnexStatuses["DRAFT"] = 1] = "DRAFT";
    ContractAndAnnexStatuses[ContractAndAnnexStatuses["PENDING"] = 2] = "PENDING";
    ContractAndAnnexStatuses[ContractAndAnnexStatuses["SENT"] = 3] = "SENT";
    ContractAndAnnexStatuses[ContractAndAnnexStatuses["UNILATERALLY_SIGNED"] = 4] = "UNILATERALLY_SIGNED";
    ContractAndAnnexStatuses[ContractAndAnnexStatuses["SIGNED"] = 5] = "SIGNED";
    ContractAndAnnexStatuses[ContractAndAnnexStatuses["STATUS_FAULTED"] = 6] = "STATUS_FAULTED";
    ContractAndAnnexStatuses[ContractAndAnnexStatuses["PARTIALLY_SIGNED"] = 7] = "PARTIALLY_SIGNED";
    ContractAndAnnexStatuses[ContractAndAnnexStatuses["CORRECTED"] = 8] = "CORRECTED";
})(ContractAndAnnexStatuses || (exports.ContractAndAnnexStatuses = ContractAndAnnexStatuses = {}));
var ContractAndAnnexSubStatuses;
(function (ContractAndAnnexSubStatuses) {
    ContractAndAnnexSubStatuses[ContractAndAnnexSubStatuses["PENDING"] = 1] = "PENDING";
    ContractAndAnnexSubStatuses[ContractAndAnnexSubStatuses["RECORDED"] = 2] = "RECORDED";
})(ContractAndAnnexSubStatuses || (exports.ContractAndAnnexSubStatuses = ContractAndAnnexSubStatuses = {}));
var NotifyModalType;
(function (NotifyModalType) {
    NotifyModalType[NotifyModalType["ALL_CONTRACTS"] = 1] = "ALL_CONTRACTS";
    NotifyModalType[NotifyModalType["NOT_ALL_CONTRACTS"] = 2] = "NOT_ALL_CONTRACTS";
})(NotifyModalType || (exports.NotifyModalType = NotifyModalType = {}));
//TODO @KOTE TO BE DECIDED FROM THE BACKEND
// export interface responseBudgetingRecord {
//   readonly data: ResponseBudgetingRecordType
//   readonly message: string
//   readonly errors: Array<Error> | null
// }
//
// export interface ResponseBudgetingRecordType {
//   attributes: ResponseContractFilesTypeAttributes
//   id: ID,
//   relationships: ResponseBudgetingRecordTypeRelationships
//   type: string
// }
//
// interface ResponseBudgetingRecordTypeRelationships {
//   contractFileRecordedItems: { data: Array<ResponseContractFileRecordedItem> }
// }
