"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const index_1 = require("api/index");
const useProcurementItems = (props) => {
    const [showVerifiedItems, setShowVerifiedItems] = (0, react_1.useState)(false);
    const [showAlert, setShowAlert] = (0, react_1.useState)(null);
    const [isExporting, setIsExporting] = (0, react_1.useState)(false);
    const removeProcurementItem = (id) => {
        props.form.setFieldValue('procurementItemsValues', (props.form.values.procurementItemsValues.filter(item => item.id !== id)));
    };
    const addRemoveProcurementItems = (checked, verifiedItems) => {
        if (checked) {
            const newVerifiedItems = verifiedItems.reduce((accumulator, currentValue) => {
                if (!props.form.values.procurementItemsValues.find(el => currentValue.id === el.id))
                    accumulator.push(currentValue);
                return accumulator;
            }, []);
            props.form.setFieldValue('procurementItemsValues', [...props.form.values.procurementItemsValues, ...newVerifiedItems]);
        }
        else {
            const items = props.form.values.procurementItemsValues.reduce((accumulator, currentValue) => {
                if (!verifiedItems.find(el => currentValue.id === el.id))
                    accumulator.push(currentValue);
                return accumulator;
            }, []);
            props.form.setFieldValue('procurementItemsValues', items);
        }
    };
    const ExcelFileDownload = async (id) => {
        setIsExporting(true);
        const file = await index_1.ProcurementsApi.exportProcurementItems(id);
        if (file)
            open(file.data?.link);
        setIsExporting(false);
    };
    const calculateSumPrice = (0, react_1.useCallback)(() => {
        const sumOnPage = props.form.values.procurementItemsValues.reduce((sum, el) => {
            const currencyExchangeRate = el.currencyExchangeRate || 1;
            return sum + (el.price * el.quantity * (currencyExchangeRate / el.currencyRateQuantity));
        }, 0);
        return sumOnPage;
    }, [props.form.values.procurementItemsValues]);
    return {
        removeProcurementItem,
        addRemoveProcurementItems,
        calculateSumPrice,
        ExcelFileDownload,
        setShowVerifiedItems,
        showVerifiedItems,
        setShowAlert,
        showAlert,
        isExporting
    };
};
exports.default = useProcurementItems;
