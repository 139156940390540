"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAddComment = void 0;
const comment_1 = require("../store/comment");
const index_1 = require("api/index");
const react_1 = require("react");
const useAddComment = (props) => {
    const [state, dispatch] = (0, comment_1.useCommentsReducer)();
    const [comment, setComment] = (0, react_1.useState)('');
    const getDocumentComments = async (documentId, name) => {
        dispatch({ type: 'SEND_SUBMISSION_VERIFY_COMMENT_REQUEST' });
        const comments = await index_1.CommonApi.getComments(documentId, name);
        dispatch({ type: 'DONE_SUBMISSION_VERIFY_COMMENT_REQUEST', payload: comments });
    };
    const addDocumentComment = async (name) => {
        dispatch({ type: 'SEND_SUBMISSION_VERIFY_ADD_COMMENT' });
        const responseComment = await index_1.CommonApi.addComment(props.verifySubmissionId ? props.verifySubmissionId : props.conditionalSubmissionId, { comment }, name);
        if (responseComment.data !== null)
            setComment('');
        dispatch({ type: 'DONE_SUBMISSION_VERIFY_ADD_COMMENT', payload: responseComment });
        return { errors: responseComment.errors, status: responseComment.status };
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => {
        if (props.verifySubmissionId || props.conditionalSubmissionId) {
            getDocumentComments(props.verifySubmissionId ? props.verifySubmissionId : props.conditionalSubmissionId, props.verifySubmissionId ? 'submission-verifies' : 'conditional-submission').then();
        }
    }, [props.verifySubmissionId]);
    return {
        addDocumentComment,
        comment,
        setComment,
        state,
        closeErrorMessage
    };
};
exports.useAddComment = useAddComment;
