"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const cpvCodes_1 = require("../store/cpvCodes");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helpers_1 = require("../helpers");
const queryString_1 = require("core/helpers/queryString");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useCpvCodes = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, cpvCodes_1.useCpvCodesReducer)();
    const [keyword, setKeyword] = (0, react_1.useState)('');
    const isMounted = (0, react_1.useRef)(false);
    const getCpvCodes = async (params) => {
        dispatch({ type: 'SEND_CPV_CODES_REQUEST' });
        const cpvCodes = await index_1.CpvCodesApi.getCpvCodes(params);
        if (isMounted?.current) {
            navigate('/references/cpv-codes' + (0, queryString_1.stringify)(params));
            dispatch({ type: 'DONE_CPV_CODES_REQUEST', payload: cpvCodes });
        }
    };
    const handleOpenClose = async (cpvCode) => {
        if (!cpvCode.hasChildren)
            return { status: apiGlobalTypes_1.ResponseStatuses.SUCCESS, errors: null };
        const newStateData = [...state.data];
        const foundItem = (0, helpers_1.findNestedItem)(newStateData, cpvCode);
        if (!foundItem)
            return { status: apiGlobalTypes_1.ResponseStatuses.SUCCESS, errors: null };
        if (foundItem.children.length > 0) {
            foundItem.closed = !foundItem.closed;
            dispatch({ type: 'UPDATE_STATE', payload: newStateData });
            return { status: apiGlobalTypes_1.ResponseStatuses.SUCCESS, errors: null };
        }
        const params = { filters: { parentId: cpvCode.id }, perPage: 100 };
        dispatch({ type: 'SET_LOADING_ITEM', payload: foundItem });
        const cpvCodes = await index_1.CpvCodesApi.getCpvCodes(params);
        dispatch({ type: 'UN_SET_LOADING_ITEM', payload: foundItem });
        foundItem.closed = !foundItem.closed;
        foundItem.children = cpvCodes.data?.map(child => {
            child.level = foundItem.level + 1;
            return child;
        }) || [];
        dispatch({ type: 'UPDATE_STATE', payload: newStateData });
        return { errors: cpvCodes.errors, status: cpvCodes.status };
    };
    const debouncedSearch = (value) => {
        setKeyword(value);
        const params = value ? { filters: { keyword: value }, perPage: 300 } : { filters: { parentId: '0' }, perPage: 1000 };
        (0, debouncedMethods_1.debouncedOnFunc)(params, getCpvCodes);
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        const params = (0, helpers_1.getParamsFromUrl)(location.search);
        setKeyword(params.filters?.keyword || '');
        getCpvCodes({ ...params, perPage: 1000, filters: { parentId: '0' } }).then();
        return () => { isMounted.current = false; };
    }, []);
    return { state, keyword, debouncedSearch, handleOpenClose, getCpvCodes };
};
exports.default = useCpvCodes;
