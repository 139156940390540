"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateUserSettings = exports.getUserSettings = exports.getLastNotifications = exports.setAllNotificationsAsRead = exports.setNotificationAsRead = exports.getNotifications = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/notifications/parsers");
const getNotifications = async (params) => {
    const response = await Api.get('notifications', params);
    return (0, parsers_1.parseNotifications)(response);
};
exports.getNotifications = getNotifications;
const setNotificationAsRead = async (id) => {
    return await Api.patch(`notifications/${id}/mark-as-read`, {});
};
exports.setNotificationAsRead = setNotificationAsRead;
const setAllNotificationsAsRead = async () => {
    return await Api.patch('notifications/mark-all-as-read', {});
};
exports.setAllNotificationsAsRead = setAllNotificationsAsRead;
const getLastNotifications = async () => {
    const response = await Api.get('notifications/unread');
    return (0, parsers_1.parseLastNotifications)(response);
};
exports.getLastNotifications = getLastNotifications;
const getUserSettings = async () => {
    const response = await Api.get('user/settings');
    return (0, parsers_1.parseUserSettings)(response);
};
exports.getUserSettings = getUserSettings;
const updateUserSettings = async (params) => {
    const response = await Api.patch('user/settings/notifications', params);
    return (0, parsers_1.parseUserSettings)(response);
};
exports.updateUserSettings = updateUserSettings;
