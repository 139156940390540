"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VerifySubmissionItemsType = exports.VerifySubmissionItemsTypes = void 0;
exports.VerifySubmissionItemsTypes = [
    { id: '1', label: 'წაშლილების გარეშე' },
    { id: '2', label: 'ყველა' },
    { id: '3', label: 'მხოლოდ წაშლილები' }
];
var VerifySubmissionItemsType;
(function (VerifySubmissionItemsType) {
    VerifySubmissionItemsType[VerifySubmissionItemsType["WITHOUT_DELETED"] = 1] = "WITHOUT_DELETED";
    VerifySubmissionItemsType[VerifySubmissionItemsType["WITH_DELETED"] = 2] = "WITH_DELETED";
    VerifySubmissionItemsType[VerifySubmissionItemsType["ONLY_DELETED"] = 3] = "ONLY_DELETED";
})(VerifySubmissionItemsType || (exports.VerifySubmissionItemsType = VerifySubmissionItemsType = {}));
