"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WarrantyTermReducer = exports.useWarrantyTermReducer = exports.initialWarrantyTermState = void 0;
const react_1 = require("react");
const initialWarrantyTermValue = {
    number: '',
    duration: null
};
exports.initialWarrantyTermState = {
    data: initialWarrantyTermValue,
    status: null,
    errors: null,
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isLoading: false
};
const useWarrantyTermReducer = () => {
    return (0, react_1.useReducer)(exports.WarrantyTermReducer, exports.initialWarrantyTermState);
};
exports.useWarrantyTermReducer = useWarrantyTermReducer;
const WarrantyTermReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_WARRANTY_TERM_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_WARRANTY_TERM_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isFetching: false,
                isLoading: state.isCreating || state.isUpdating
            };
        case 'SEND_WARRANTY_TERM_CREATE':
            return {
                ...state,
                errors: null,
                isCreating: true,
                isLoading: true
            };
        case 'DONE_WARRANTY_TERM_CREATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isCreating: false,
                isLoading: state.isFetching || state.isUpdating
            };
        case 'SEND_WARRANTY_TERM_UPDATE':
            return {
                ...state,
                errors: null,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_WARRANTY_TERM_UPDATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isUpdating: false,
                isLoading: state.isCreating || state.isFetching
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.WarrantyTermReducer = WarrantyTermReducer;
