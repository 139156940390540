"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectReducer = exports.useProjectReducer = exports.initialProjectNavigate = exports.initialProjectState = void 0;
const react_1 = require("react");
const types_1 = require("api/references/funding/projects/types");
exports.initialProjectState = {
    data: {
        id: '',
        additionalInfo: '',
        headOfDirection: null,
        headOfDirectionId: null,
        coordinatorName: null,
        coordinatorId: null,
        responsibles: [],
        name: '',
        fundingSource: null,
        needsAssignVerify: types_1.NeedVerificationStatus.DOES_NOT_NEED_VERIFICATION,
        budgetSource: null,
        donor: null,
        number: null,
        startDate: null,
        endDate: null,
        isExpired: false
    },
    status: null,
    errors: null,
    isCreating: false,
    isFetching: false,
    isUpdating: false,
    isLoading: false
};
exports.initialProjectNavigate = {
    next: false,
    previous: false,
    isLoading: false,
    lastElement: false,
    firstElement: false
};
const useProjectReducer = () => {
    return (0, react_1.useReducer)(exports.ProjectReducer, exports.initialProjectState);
};
exports.useProjectReducer = useProjectReducer;
const ProjectReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_PROJECT_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_PROJECT_REQUEST':
            return {
                ...state,
                data: action.payload.data || exports.initialProjectState.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isFetching: false,
                isLoading: state.isCreating || state.isUpdating
            };
        case 'SEND_PROJECT_UPDATE':
            return {
                ...state,
                errors: null,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_PROJECT_UPDATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isUpdating: false,
                isLoading: state.isCreating || state.isFetching
            };
        case 'SEND_CREATE_PROJECT':
            return {
                ...state,
                errors: null,
                isCreating: true,
                isLoading: true
            };
        case 'DONE_CREATE_PROJECT':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isCreating: false,
                isLoading: state.isFetching || state.isUpdating
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.ProjectReducer = ProjectReducer;
