"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mediatorBanksReducer = exports.useMediatorBanksReducer = exports.initialMediatorBanksState = void 0;
const react_1 = require("react");
exports.initialMediatorBanksState = {
    data: [],
    errors: null,
    isCreateOrUpdating: false,
    isFetching: false,
    status: null
};
const useMediatorBanksReducer = () => {
    return (0, react_1.useReducer)(exports.mediatorBanksReducer, exports.initialMediatorBanksState);
};
exports.useMediatorBanksReducer = useMediatorBanksReducer;
const mediatorBanksReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_MEDIATOR_BANKS_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true
            };
        case 'DONE_MEDIATOR_BANKS_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isFetching: false
            };
        case 'SEND_MEDIATOR_BANKS_CREATE_OR_UPDATE':
            return {
                ...state,
                errors: null,
                isCreateOrUpdating: true
            };
        case 'DONE_MEDIATOR_BANKS_CREATE_OR_UPDATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                isCreateOrUpdating: false,
                status: action.payload.status
            };
    }
};
exports.mediatorBanksReducer = mediatorBanksReducer;
