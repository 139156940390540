"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const MediaFilePreviewModal = (props) => {
    const pdfExpansion = props.openedFilePreview.data?.extension === 'pdf' || props.openedFilePreview.data?.extension === 'PDF';
    (0, react_1.useEffect)(() => {
        const outsideClick = (e) => {
            if (!e.target.closest('#media-file-preview-modal'))
                props.setOpenedFilePreview(null);
        };
        document.body.addEventListener('click', outsideClick);
        return () => {
            document.body.removeEventListener('click', outsideClick);
        };
    }, []);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: props.openedFilePreview.data?.link ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("div", { className: 'modal fade show modal-dialog-scrollable', style: { display: 'block' }, children: (0, jsx_runtime_1.jsx)("div", { className: 'modal-dialog modal-dialog-centered modal-xl', children: (0, jsx_runtime_1.jsxs)("div", { id: 'media-file-preview-modal', className: 'modal-content position-relative', children: [(0, jsx_runtime_1.jsx)("i", { className: 'position-absolute fas fa-times cursor-pointer', style: { zIndex: 9999, right: pdfExpansion ? 10 : 25, top: 9 }, onClick: () => props.setOpenedFilePreview(null) }), (0, jsx_runtime_1.jsx)("div", { className: `modal-body p-0 ps__thumb-y d-flex flex-column${pdfExpansion ? ' overflow-hidden' : ''}`, style: { height: pdfExpansion ? '100vh' : 'auto' }, children: (0, jsx_runtime_1.jsx)("object", { style: { pointerEvents: props.preventDownload ? 'none' : undefined }, data: props.openedFilePreview.data.link, height: '100%', width: '100%' }) })] }) }) }), (0, jsx_runtime_1.jsx)("div", { className: 'modal-backdrop fade show' })] })) : null }));
};
exports.default = MediaFilePreviewModal;
