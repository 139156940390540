"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parsePublicComment = exports.parsePublicComments = exports.parseComment = exports.parseComments = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseComments = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const comments = response.content;
    try {
        return {
            data: comments.data.map(el => {
                const account = el.relationships.account;
                const user = el.relationships.user;
                return {
                    id: el.id,
                    comment: el.attributes.comment,
                    status: el.attributes.pivots.status,
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt),
                    userId: account
                        ? account.data.relationships.user.data.id
                        : user?.data.id,
                    userFullName: account
                        ? account.data.relationships.user.data.attributes.fullname
                        : user?.data.attributes.fullname,
                    userEmail: account
                        ? account.data.relationships.user.data.attributes.email
                        : user?.data.attributes.email
                };
            }),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseComments = parseComments;
const parseComment = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const comment = response.content;
    const account = comment.data.relationships.account;
    const user = comment.data.relationships.user;
    try {
        return {
            data: {
                id: comment.data.id,
                comment: comment.data.attributes.comment,
                createdAt: (0, parsers_1.transformDate)(comment.data.attributes.createdAt),
                updatedAt: (0, parsers_1.transformDate)(comment.data.attributes.updatedAt),
                userId: account ? account.data.relationships.user.data.id : user.data.id,
                userFullName: account ? account.data.relationships.user.data.attributes.fullname : user.data.attributes.fullname,
                userEmail: account ? account.data.relationships.user.data.attributes.email : user.data.attributes.email
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseComment = parseComment;
const parsePublicComments = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const comments = response.content;
    try {
        return {
            data: comments.data.map(el => {
                const account = el.relationships.account;
                const user = el.relationships.user;
                return {
                    id: el.id,
                    comment: el.attributes.comment,
                    status: el.attributes.pivots.status,
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt),
                    userId: account
                        ? account.data.relationships.forUnAuthorizeUser.data.id
                        : user?.data.id,
                    userFullName: account
                        ? account.data.relationships.forUnAuthorizeUser.data.attributes.fullname
                        : user?.data.attributes.fullname,
                    userEmail: account
                        ? account.data.relationships.forUnAuthorizeUser.data.attributes.email
                        : user?.data.attributes.email
                };
            }),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parsePublicComments = parsePublicComments;
const parsePublicComment = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const comment = response.content;
    const account = comment.data.relationships.account;
    const user = comment.data.relationships.user;
    try {
        return {
            data: {
                id: comment.data.id,
                comment: comment.data.attributes.comment,
                createdAt: (0, parsers_1.transformDate)(comment.data.attributes.createdAt),
                updatedAt: (0, parsers_1.transformDate)(comment.data.attributes.updatedAt),
                userId: account ? account.data.relationships.forUnAuthorizeUser.data.id : user.data.id,
                userFullName: account ? account.data.relationships.forUnAuthorizeUser.data.attributes.fullname : user.data.attributes.fullname,
                userEmail: account ? account.data.relationships.forUnAuthorizeUser.data.attributes.email : user.data.attributes.email
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parsePublicComment = parsePublicComment;
