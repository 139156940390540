"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const index_1 = require("api/index");
const UserBudjets_1 = require("../stores/UserBudjets");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const react_1 = require("react");
const useUserProjects = (userId) => {
    const [state, dispatch] = (0, UserBudjets_1.useReducerUserProjects)();
    const [keyword, setKeyword] = (0, react_1.useState)('');
    const getUserProjects = async (id) => {
        dispatch({ type: 'SEND_USER_PROJECTS_REQUEST' });
        const userProjects = await index_1.ProjectsApi.getUserProjects({ filters: { filterByUserId: id } });
        dispatch({ type: 'DONE_USER_PROJECTS_REQUEST', payload: userProjects });
    };
    const getProjects = async (values) => {
        const params = { ...values, perPage: 15, filters: { ...values.filters, sortByUserId: userId } };
        dispatch({ type: 'SEND_PROJECTS_DATA_REQUEST' });
        const projects = await index_1.ProjectsApi.getProjects(params);
        dispatch({ type: 'DONE_PROJECTS_DATA_REQUEST', payload: projects });
    };
    const attachProjectsToUser = async (userID, projectId) => {
        dispatch({ type: 'SEND_USER_PROJECTS_ATTACH_REQUEST', payload: projectId });
        const attachedProject = await index_1.ProjectsApi.attacheProjectsToUser(userID, { projectIds: [projectId] });
        dispatch({
            type: 'DONE_USER_PROJECTS_ATTACH_REQUEST',
            payload: { status: attachedProject.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS, projectId }
        });
        return { status: attachedProject.status, errors: attachedProject.errors };
    };
    const detachProjectsFromUser = async (userId, projectId) => {
        dispatch({ type: 'SEND_USER_PROJECTS_DETACH_REQUEST', payload: projectId });
        const detachProject = await index_1.ProjectsApi.detachProjectsFromUser(userId, { projectIds: [projectId] });
        dispatch({
            type: 'DONE_USER_PROJECTS_DETACH_REQUEST',
            payload: { status: detachProject.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS, projectId }
        });
        return { status: detachProject.status, errors: detachProject.errors };
    };
    const debouncedSearch = (value) => {
        setKeyword(value);
        (0, debouncedMethods_1.debouncedOnFunc)({ filters: { keyword: value } }, getProjects);
    };
    const navigatePagination = (page) => {
        getProjects({ page, filters: { keyword, sortByUserId: userId } }).then();
    };
    (0, react_1.useEffect)(() => {
        getUserProjects(userId).then(() => {
            getProjects({ page: 1 }).then();
        });
    }, []);
    return {
        state,
        attachProjectsToUser,
        detachProjectsFromUser,
        debouncedSearch,
        navigatePagination,
        keyword
    };
};
exports.default = useUserProjects;
