"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const react_1 = require("react");
const createOrEditProject_1 = require("../stores/createOrEditProject");
const store_1 = require("core/store");
const queryString_1 = require("core/helpers/queryString");
const helper_1 = require("../helper");
const react_router_dom_1 = require("react-router-dom");
const useProject = (props) => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, createOrEditProject_1.useProjectReducer)();
    const [budgetSourceOptions, setBudgetSourceOptions] = (0, react_1.useState)([]);
    const [structuralUnits, setStructuralUnits] = (0, react_1.useState)([]);
    const [isFetchingBudgetingSource, setIsFetchingBudgetingSource] = (0, react_1.useState)(false);
    const [isLoadingBudgetingSource, setIsLoadingBudgetingSource] = (0, react_1.useState)(false);
    const [headOfDirectionId, setHeadOfDirectionId] = (0, react_1.useState)(null);
    const [coordinatorId, setCoordinatorId] = (0, react_1.useState)(null);
    const [buttonNavigate, setButtonNavigate] = (0, react_1.useState)(createOrEditProject_1.initialProjectNavigate);
    const [projectParamsIds, setProjectParamsIds] = (0, react_1.useState)({ projectId: [], meta: store_1.initialMeta });
    const [listingParams, setListingParams] = (0, react_1.useState)('');
    const getProject = async (id) => {
        dispatch({ type: 'SEND_PROJECT_REQUEST' });
        const projectData = await index_1.ProjectsApi.getProject(id);
        dispatch({ type: 'DONE_PROJECT_REQUEST', payload: projectData });
        if (projectData.data) {
            setHeadOfDirectionId(projectData.data.headOfDirectionId);
            setCoordinatorId(projectData.data.coordinatorId);
        }
    };
    const createProject = async (values) => {
        const params = (0, helper_1.transformProjectParams)(values, headOfDirectionId, coordinatorId);
        dispatch({ type: 'SEND_CREATE_PROJECT' });
        const projectData = await index_1.ProjectsApi.createProject(params);
        dispatch({ type: 'DONE_CREATE_PROJECT', payload: projectData });
        if (projectData.data !== null) {
            navigate(`/references/funding/projects/${projectData.data.id}/edit`);
        }
        return projectData;
    };
    const updateProject = async (id, values) => {
        const params = (0, helper_1.transformProjectParams)(values, headOfDirectionId, coordinatorId);
        dispatch({ type: 'SEND_PROJECT_UPDATE' });
        const projectData = await index_1.ProjectsApi.updateProject(id, params);
        dispatch({ type: 'DONE_PROJECT_UPDATE', payload: projectData });
        return projectData;
    };
    const filterResponsibleAccounts = (responsibles) => {
        const filteredAccounts = [];
        structuralUnits.forEach(el => {
            if (!responsibles.find(item => item.accountId === el.accountId))
                filteredAccounts.push(el);
        });
        setStructuralUnits(filteredAccounts);
    };
    const getBudgetSourcesForSelect = async (fundingSourceId, fromFundingSource = false) => {
        fromFundingSource ? setIsLoadingBudgetingSource(true) : setIsFetchingBudgetingSource(true);
        const budgetSources = await index_1.BudgetSourcesApi.getBudgetSourcesForSelect({
            perPage: 300,
            filters: { fundingSourceId: fundingSourceId }
        });
        setBudgetSourceOptions(budgetSources);
        fromFundingSource ? setIsLoadingBudgetingSource(false) : setIsFetchingBudgetingSource(false);
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    const setStructuralUnit = (value) => {
        if (value === null)
            return setStructuralUnits([]);
        setStructuralUnits(value.structuralUnits);
    };
    const getProjectIds = async (value) => {
        const projectsParams = (0, helper_1.transformProjectParamsToFilterParams)({ ...value });
        const projects = await index_1.ProjectsApi.getProjects(projectsParams);
        if (projects.data !== null && projects.meta) {
            const responseIds = {
                projectId: projects.data.map(el => el.id), meta: projects.meta
            };
            const searchParams = (0, queryString_1.stringify)({ ...(0, helper_1.transformProjectFilterParamsToUrl)(value) });
            setListingParams(searchParams || '?');
            setProjectParamsIds(responseIds);
            return responseIds;
        }
        return null;
    };
    const nextProject = async () => {
        setButtonNavigate(prevState => ({ ...prevState, next: true, isLoading: true }));
        const currentIndex = projectParamsIds.projectId.indexOf(props.id);
        const nextProject = projectParamsIds.projectId[currentIndex + 1];
        const { pagination } = projectParamsIds.meta;
        const params = (0, helper_1.transformProjectFilterParamsToUrl)((0, helper_1.transformUrlToProjectFilterParams)(location.search));
        if (nextProject) {
            navigate('/references/funding/projects/' + nextProject + '/edit' + (0, queryString_1.stringify)({ ...params }));
            const lastElement = pagination.currentPage === pagination.totalPages && projectParamsIds.projectId.length === currentIndex + 2;
            setButtonNavigate(prevState => ({ ...prevState, next: false, firstElement: false, lastElement, isLoading: false }));
        }
        else {
            if (pagination.currentPage === pagination.totalPages) {
                setButtonNavigate(prevState => ({ ...prevState, next: false, lastElement: true, isLoading: false }));
                return;
            }
            const formValues = (0, helper_1.transformUrlToProjectFilterParams)(location.search);
            const response = await getProjectIds({ ...formValues, page: pagination.currentPage + 1 });
            if (response && response.projectId[0]) {
                formValues.page = response.meta.pagination.currentPage;
                const urlParams = { ...(0, helper_1.transformProjectFilterParamsToUrl)(formValues), page: response.meta.pagination.currentPage };
                navigate('/references/funding/projects/' + response.projectId[0] + '/edit' + (0, queryString_1.stringify)(urlParams));
                const lastElement = response.meta.pagination.currentPage === response.meta.pagination.totalPages && response.projectId.length === 1;
                setButtonNavigate(prevState => ({ ...prevState, next: false, firstElement: false, lastElement, isLoading: false }));
            }
        }
    };
    const previousProject = async () => {
        setButtonNavigate(prevState => ({ ...prevState, previous: true, isLoading: true }));
        const currentIndex = projectParamsIds.projectId.indexOf(props.id);
        const previousProject = projectParamsIds.projectId[currentIndex - 1];
        const { pagination } = projectParamsIds.meta;
        const params = (0, helper_1.transformProjectFilterParamsToUrl)((0, helper_1.transformUrlToProjectFilterParams)(location.search));
        if (previousProject) {
            navigate('/references/funding/projects/' + previousProject + '/edit' + (0, queryString_1.stringify)({ ...params }));
            const firstElement = pagination.currentPage === 1 && currentIndex === 1;
            setButtonNavigate(prevState => ({ ...prevState, previous: false, lastElement: false, firstElement, isLoading: false }));
        }
        else {
            if (pagination.currentPage === 1) {
                setButtonNavigate(prevState => ({ ...prevState, previous: false, firstElement: true, isLoading: false }));
                return;
            }
            const formValues = (0, helper_1.transformUrlToProjectFilterParams)(location.search);
            const response = await getProjectIds({ ...formValues, page: pagination.currentPage - 1 });
            if (response) {
                const prevProjects = response.projectId[response.projectId.length - 1];
                if (prevProjects) {
                    formValues.page = response.meta.pagination.currentPage;
                    const urlParams = { ...(0, helper_1.transformProjectFilterParamsToUrl)(formValues), page: response.meta.pagination.currentPage };
                    navigate('/references/funding/projects/' + prevProjects + '/edit' + (0, queryString_1.stringify)(urlParams));
                    const lastElement = response.meta.pagination.currentPage === response.meta.pagination.totalPages && response.projectId.length === 1;
                    setButtonNavigate(prevState => ({ ...prevState, previous: false, firstElement: false, lastElement, isLoading: false }));
                }
            }
        }
    };
    (0, react_1.useEffect)(() => {
        const params = (0, helper_1.transformUrlToProjectFilterParams)(location.search);
        getProjectIds(params).then(response => {
            if (response) {
                const { currentPage, totalPages } = response.meta.pagination;
                const projectIndex = response.projectId.indexOf(props.id);
                if (projectIndex === 0 && currentPage === 1)
                    setButtonNavigate(prevState => ({ ...prevState, firstElement: true }));
                if (currentPage === totalPages && projectIndex === response.projectId.length - 1) {
                    setButtonNavigate(prevState => ({ ...prevState, lastElement: true }));
                }
            }
        });
    }, []);
    (0, react_1.useEffect)(() => { if (props.id)
        getProject(props.id).then(); }, [props.id]);
    return {
        state,
        createProject,
        updateProject,
        getProject,
        setBudgetSourceOptions,
        budgetSourceOptions,
        setIsFetchingBudgetingSource,
        isFetchingBudgetingSource,
        isLoadingBudgetingSource,
        closeErrorMessage,
        getBudgetSourcesForSelect,
        setHeadOfDirectionId,
        setCoordinatorId,
        setStructuralUnit,
        structuralUnits,
        filterResponsibleAccounts,
        listingParams,
        buttonNavigate,
        nextProject,
        previousProject
    };
};
exports.default = useProject;
