"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = tslib_1.__importStar(require("react"));
const CustomScrollBar = (props) => {
    const [fakeDivWidth, setFakeDivWidth] = (0, react_1.useState)();
    const [windowWidth, setWindowWidth] = (0, react_1.useState)();
    const tableContainerRef = (0, react_1.useRef)(null);
    const fakeContainerRef = (0, react_1.useRef)(null);
    const fakeDivRef = (0, react_1.useRef)(null);
    const tableToFakeScrollHandler = () => {
        if (fakeContainerRef.current && tableContainerRef.current)
            fakeContainerRef.current.scrollLeft = tableContainerRef.current.scrollLeft;
    };
    const fakeToTableScrollHandler = () => {
        if (fakeContainerRef.current && tableContainerRef.current)
            tableContainerRef.current.scrollLeft = fakeContainerRef.current.scrollLeft;
    };
    (0, react_1.useEffect)(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    (0, react_1.useEffect)(() => {
        if (props.widthRef.current?.clientWidth) {
            setFakeDivWidth(props.widthRef.current.clientWidth);
        }
    }, [props.widthRef, windowWidth]);
    return ((0, jsx_runtime_1.jsxs)(react_1.default.Fragment, { children: [(0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)("div", { className: 'fake-scroll-container', ref: fakeContainerRef, onScroll: fakeToTableScrollHandler, children: fakeDivWidth && (0, jsx_runtime_1.jsx)("div", { ref: fakeDivRef, style: { width: fakeDivWidth }, children: '\u00A0' }) }) }), (0, jsx_runtime_1.jsx)("div", { className: 'table-container', ref: tableContainerRef, onScroll: tableToFakeScrollHandler, children: props.children })] }));
};
exports.default = CustomScrollBar;
