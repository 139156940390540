"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDonorsForSelect = exports.updateDonor = exports.createDonor = exports.getDonor = exports.deleteDonor = exports.getDonors = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/references/funding/donors/parsers");
const react_toastify_1 = require("react-toastify");
const getDonors = async (params) => {
    const response = await Api.get('references/funding/donors', params);
    return (0, parsers_1.parseDonors)(response);
};
exports.getDonors = getDonors;
const deleteDonor = async (id) => {
    return await Api.deleteItem('references/funding/donors/' + id);
};
exports.deleteDonor = deleteDonor;
const getDonor = async (id) => {
    const response = await Api.get('references/funding/donors/' + id);
    return (0, parsers_1.parseDonor)(response);
};
exports.getDonor = getDonor;
const createDonor = async (params) => {
    const response = await Api.post('references/funding/donors', params);
    return (0, parsers_1.parseDonor)(response);
};
exports.createDonor = createDonor;
const updateDonor = async (id, params) => {
    const response = await Api.patch('references/funding/donors/' + id, params);
    return (0, parsers_1.parseDonor)(response);
};
exports.updateDonor = updateDonor;
const getDonorsForSelect = async (params) => {
    const response = await Api.get('references/funding/donors', params);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, parsers_1.parseDonorsForSelect)(response);
};
exports.getDonorsForSelect = getDonorsForSelect;
