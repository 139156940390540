"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteLocation = exports.updateLocation = exports.createLocation = exports.getLocation = exports.getLocations = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("../../../privateRequest"));
const parsers_1 = require("api/references/addresses/locations/parsers");
const getLocations = async (params) => {
    const response = await Api.get('references/geography/locations', params);
    return (0, parsers_1.parseLocations)(response);
};
exports.getLocations = getLocations;
const getLocation = async (id) => {
    const response = await Api.get('references/geography/locations/' + id);
    return (0, parsers_1.parseLocation)(response);
};
exports.getLocation = getLocation;
const createLocation = async (params) => {
    const response = await Api.post('references/geography/locations', params);
    return (0, parsers_1.parseLocation)(response);
};
exports.createLocation = createLocation;
const updateLocation = async (id, params) => {
    const response = await Api.patch('references/geography/locations/' + id, params);
    return (0, parsers_1.parseLocation)(response);
};
exports.updateLocation = updateLocation;
const deleteLocation = async (id) => {
    return await Api.deleteItem(`references/geography/locations/${id}`);
};
exports.deleteLocation = deleteLocation;
