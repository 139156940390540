"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInvoiceItemsReducer = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
const initialInvoiceItemsStore = {
    data: [],
    errors: null,
    meta: store_1.initialMeta,
    isFetching: true
};
const useInvoiceItemsReducer = () => {
    return (0, react_1.useReducer)(InvoiceItemsReducer, initialInvoiceItemsStore);
};
exports.useInvoiceItemsReducer = useInvoiceItemsReducer;
const InvoiceItemsReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_INVOICE_ITEMS_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_INVOICE_ITEMS_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                meta: action.payload.meta || store_1.initialMeta,
                errors: action.payload.errors || null,
                isFetching: false
            };
        default:
            return state;
    }
};
