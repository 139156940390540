"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFundingSourcesForSelect = exports.updateFundingSource = exports.createFundingSource = exports.getFundingSource = exports.deleteFundingSource = exports.getFundingSources = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/references/funding/funding-sources/parsers");
const react_toastify_1 = require("react-toastify");
const getFundingSources = async (params) => {
    const response = await Api.get('references/funding/funding-sources', params);
    return (0, parsers_1.parseFundingSources)(response);
};
exports.getFundingSources = getFundingSources;
const deleteFundingSource = async (id) => {
    return await Api.deleteItem('references/funding/funding-sources/' + id);
};
exports.deleteFundingSource = deleteFundingSource;
const getFundingSource = async (id) => {
    const response = await Api.get('references/funding/funding-sources/' + id);
    return (0, parsers_1.parseFundingSource)(response);
};
exports.getFundingSource = getFundingSource;
const createFundingSource = async (params) => {
    const response = await Api.post('references/funding/funding-sources', params);
    return (0, parsers_1.parseFundingSource)(response);
};
exports.createFundingSource = createFundingSource;
const updateFundingSource = async (id, params) => {
    const response = await Api.patch('references/funding/funding-sources/' + id, params);
    return (0, parsers_1.parseFundingSource)(response);
};
exports.updateFundingSource = updateFundingSource;
const getFundingSourcesForSelect = async (params) => {
    const response = await Api.get('references/funding/funding-sources', params);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, parsers_1.parseFundingSourcesForSelect)(response);
};
exports.getFundingSourcesForSelect = getFundingSourcesForSelect;
