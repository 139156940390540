"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformAdditionalParams = void 0;
const transformAdditionalParams = (params) => {
    return {
        tenderLink: params.tenderLink || null,
        cmr: params.cmr || null,
        spa: params.spa || null,
        con: params.con || null
    };
};
exports.transformAdditionalParams = transformAdditionalParams;
