"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSubmissionVerifyItemV2Reducer = exports.initialSubmissionVerifyItemV2Store = void 0;
const react_1 = require("react");
exports.initialSubmissionVerifyItemV2Store = {
    data: [],
    isUpdatingOrCreating: false
};
const useSubmissionVerifyItemV2Reducer = () => {
    return (0, react_1.useReducer)(submissionVerifyItemV2Reducer, exports.initialSubmissionVerifyItemV2Store);
};
exports.useSubmissionVerifyItemV2Reducer = useSubmissionVerifyItemV2Reducer;
const submissionVerifyItemV2Reducer = (state, action) => {
    switch (action.type) {
        case 'SEND_SUBMISSION_VERIFY_ITEM_UPDATE_OR_CREATE':
            return {
                ...state,
                isUpdatingOrCreating: true
            };
        case 'DONE_SUBMISSION_VERIFY_ITEM_UPDATE_OR_CREATE':
            return {
                ...state,
                isUpdatingOrCreating: false
            };
    }
};
