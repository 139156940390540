"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dimensionUnitReducer = exports.usePurchaseSubjectReducer = exports.initialPurchaseSubjectState = void 0;
const react_1 = require("react");
const types_1 = require("api/references/purchase-subject/types");
const initialPurchaseSubjectValue = {
    subjectTitle: '',
    verified: types_1.VerifiedStatus.VERIFIED,
    type: null,
    specification: null,
    category: null,
    subject: null
};
exports.initialPurchaseSubjectState = {
    data: initialPurchaseSubjectValue,
    status: null,
    errors: null,
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isLoading: false
};
const usePurchaseSubjectReducer = () => {
    return (0, react_1.useReducer)(exports.dimensionUnitReducer, exports.initialPurchaseSubjectState);
};
exports.usePurchaseSubjectReducer = usePurchaseSubjectReducer;
const dimensionUnitReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_PURCHASE_SUBJECT_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_PURCHASE_SUBJECT_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isFetching: false,
                isLoading: state.isCreating || state.isUpdating
            };
        case 'SEND_PURCHASE_SUBJECT_CREATE':
            return {
                ...state,
                errors: null,
                isCreating: true,
                isLoading: true
            };
        case 'DONE_PURCHASE_SUBJECT_CREATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isCreating: false,
                isLoading: state.isFetching || state.isUpdating
            };
        case 'SEND_PURCHASE_SUBJECT_UPDATE':
            return {
                ...state,
                errors: null,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_PURCHASE_SUBJECT_UPDATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isUpdating: false,
                isLoading: state.isCreating || state.isFetching
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.dimensionUnitReducer = dimensionUnitReducer;
