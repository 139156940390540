"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseItemFile = exports.parseEmployeePositionInfos = exports.parseEmployeeAssignmentCorrection = exports.parseEmployeeAssignment = exports.parseEmployeeAssignments = void 0;
const types_1 = require("api/documents/human-resources/assign-submissions/types");
const types_2 = require("api/references/employees/types");
const types_3 = require("src/modules/references/employees/types");
const employee_1 = require("api/parsers/employee");
const dismissalParser_1 = require("api/references/employees/dismissalParser");
const helper_1 = require("api/helper");
const parsers_1 = require("api/files/parsers");
const parsers_2 = require("api/parsers/parsers");
const parsers_3 = require("api/documents/human-resources/assign-submissions/parsers");
const parseEmployeeAssignments = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const positions = response.content;
    try {
        return {
            data: positions.data.map(position => {
                return {
                    ...parseAssignment(position, null),
                    workEndDate: (0, parsers_2.nullableTransformDate)(position.relationships.correctedItems.data[0]?.relationships.positionInfo.data[0]?.attributes.startDate || null),
                    correctedItems: position.relationships.correctedItems ? position.relationships.correctedItems.data.map((el, index) => {
                        const nextCorrection = position.relationships.correctedItems.data[index + 1];
                        return {
                            ...parseAssignment(el, position.id),
                            workEndDate: (0, parsers_2.nullableTransformDate)(nextCorrection?.relationships.positionInfo.data[0]?.attributes.startDate || null)
                        };
                    }) : []
                };
            }),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        console.error({ err });
        return helper_1.errorObject;
    }
};
exports.parseEmployeeAssignments = parseEmployeeAssignments;
const parseEmployeeAssignment = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const positions = response.content;
    try {
        const contract = positions.data.relationships.mediaFiles.data.filter(file => file.attributes.pivots.type === types_3.EmployeeAssignmentFileTypes.CONTRACT).map(file => (0, exports.parseItemFile)(file));
        const order = positions.data.relationships.mediaFiles.data.filter(file => file.attributes.pivots.type === types_3.EmployeeAssignmentFileTypes.ORDER).map(file => (0, exports.parseItemFile)(file));
        const orderWithContract_Order = positions.data.relationships.mediaFiles.data.filter(file => file.attributes.pivots.type === types_3.EmployeeAssignmentFileTypes.CONTRACT_ORDER_ORDER).map(file => (0, exports.parseItemFile)(file));
        const orderWithContract_Contract = positions.data.relationships.mediaFiles.data.filter(file => file.attributes.pivots.type === types_3.EmployeeAssignmentFileTypes.CONTRACT_ORDER_CONTRACT).map(file => (0, exports.parseItemFile)(file));
        const contractExt = positions.data.relationships.mediaFiles.data.filter(file => file.attributes.pivots.type === types_3.EmployeeAssignmentFileTypes.CONTRACT_EXT).map(file => (0, exports.parseItemFile)(file));
        const basicFunctionDuties = positions.data.relationships.mediaFiles.data.filter(file => file.attributes.pivots.type === types_3.EmployeeAssignmentFileTypes.BASIC_FUNCTION_DUTIES).map(file => (0, exports.parseItemFile)(file));
        const dismissalReason = positions.data.relationships.dismissal ? dismissalParser_1.dismissalReasonOptions.find(el => el.id === String(positions.data.relationships.dismissal?.data.attributes.reason)) : null;
        const contractType = positions.data.relationships.contractType && {
            id: positions.data.relationships.contractType.data.id,
            label: positions.data.relationships.contractType.data.attributes.name,
            index: positions.data.relationships.contractType.data.attributes.index,
            type: positions.data.relationships.contractType.data.attributes.type
        };
        return {
            data: {
                assignId: positions.data.id,
                originalId: null,
                assignCategory: positions.data.attributes.assignCategory,
                contractExtContinuationType: positions.data.attributes.assignType === types_1.AssignType.CONTRACT_EXT ? positions.data.attributes.contractContinuationType : null,
                employmentContractType: positions.data.attributes.assignType === types_1.AssignType.CONTRACT ? positions.data.attributes.employmentContractType : null,
                recordNumber: positions.data.attributes.recordNumber,
                recordDate: (0, parsers_2.nullableTransformDate)(positions.data.attributes.recordDate),
                title: positions.data.attributes.title,
                effectiveDate: (0, parsers_2.nullableTransformDate)(positions.data.attributes.effectiveDate),
                assignType: employee_1.assignTypeOptions.find(el => el.id === String(positions.data.attributes.assignType)),
                positionInfos: positions.data.relationships.positionInfo.data.map(el => (0, exports.parseEmployeePositionInfos)(el)),
                minStartDate: null,
                correctedItems: positions.data.relationships.correctedItems ? positions.data.relationships.correctedItems.data.map((el, index) => {
                    const nextCorrection = positions.data.relationships.correctedItems.data[index + 1];
                    return {
                        ...parseAssignment(el, positions.data.id),
                        workEndDate: (0, parsers_2.nullableTransformDate)(nextCorrection?.relationships.positionInfo.data[0]?.attributes.startDate || null)
                    };
                }) : [],
                files: {
                    contract: contract.length > 0 ? contract : [{ ...parsers_1.initialFile, type: types_3.EmployeeAssignmentFileTypes.CONTRACT }],
                    order: order.length > 0 ? order : [{ ...parsers_1.initialFile, type: types_3.EmployeeAssignmentFileTypes.ORDER }],
                    orderWithContract_Order: orderWithContract_Order.length > 0 ? orderWithContract_Order : [{ ...parsers_1.initialFile, type: types_3.EmployeeAssignmentFileTypes.CONTRACT_ORDER_ORDER }],
                    orderWithContract_Contract: orderWithContract_Contract.length > 0 ? orderWithContract_Contract : [{ ...parsers_1.initialFile, type: types_3.EmployeeAssignmentFileTypes.CONTRACT_ORDER_CONTRACT }],
                    contractExt: contractExt.length > 0 ? contractExt : [{ ...parsers_1.initialFile, type: types_3.EmployeeAssignmentFileTypes.CONTRACT_EXT }],
                    basicFunctionDutiesFiles: basicFunctionDuties.length > 0 ? basicFunctionDuties : [{ ...parsers_1.initialFile, type: types_3.EmployeeAssignmentFileTypes.BASIC_FUNCTION_DUTIES }]
                },
                dismissalDate: (0, parsers_2.nullableTransformDate)(positions.data.relationships.dismissal?.data.attributes.dismissalDate || null),
                dismissalReason,
                dismissalReasonShortName: dismissalReason ? (0, parsers_2.parseShortName)(dismissalReason.label) : null,
                workEndDate: (0, parsers_2.nullableTransformDate)(positions.data.relationships.correctedItems.data[0]?.relationships.positionInfo.data[0]?.attributes.startDate || null),
                visualStatus: parseEmployeeAssignmentVisualStatuses(positions.data.attributes.status),
                contractContractNumber: positions.data.attributes.assignType === types_1.AssignType.CONTRACT ? positions.data.attributes.contractNumber : null,
                contractContractDate: positions.data.attributes.assignType === types_1.AssignType.CONTRACT ? (0, parsers_2.nullableTransformDate)(positions.data.attributes.contractDate) : null,
                contractContractType: positions.data.attributes.assignType === types_1.AssignType.CONTRACT ? contractType : null,
                contractExtContractNumber: positions.data.attributes.assignType === types_1.AssignType.CONTRACT_EXT ? positions.data.attributes.contractNumber : null,
                contractExtContractDate: positions.data.attributes.assignType === types_1.AssignType.CONTRACT_EXT ? (0, parsers_2.nullableTransformDate)(positions.data.attributes.contractDate) : null,
                contractExtContractType: positions.data.attributes.assignType === types_1.AssignType.CONTRACT_EXT ? contractType : null,
                orderOrderNumber: positions.data.attributes.assignType === types_1.AssignType.ORDER ? positions.data.attributes.orderNumber : null,
                orderOrderDate: positions.data.attributes.assignType === types_1.AssignType.ORDER ? (0, parsers_2.nullableTransformDate)(positions.data.attributes.orderDate) : null,
                orderOrderType: positions.data.attributes.assignType === types_1.AssignType.ORDER ? positions.data.attributes.orderTitle : null,
                ocOrderNumber: positions.data.attributes.assignType === types_1.AssignType.ORDER_CONTRACT ? positions.data.attributes.orderNumber : null,
                ocOrderDate: positions.data.attributes.assignType === types_1.AssignType.ORDER_CONTRACT ? (0, parsers_2.nullableTransformDate)(positions.data.attributes.orderDate) : null,
                ocOrderType: positions.data.attributes.assignType === types_1.AssignType.ORDER_CONTRACT ? positions.data.attributes.orderTitle : null,
                ocContractNumber: positions.data.attributes.assignType === types_1.AssignType.ORDER_CONTRACT ? positions.data.attributes.contractNumber : null,
                ocContractDate: positions.data.attributes.assignType === types_1.AssignType.ORDER_CONTRACT ? (0, parsers_2.nullableTransformDate)(positions.data.attributes.contractDate) : null,
                ocContractType: positions.data.attributes.assignType === types_1.AssignType.ORDER_CONTRACT ? contractType : null
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        console.error({ err });
        return helper_1.errorObject;
    }
};
exports.parseEmployeeAssignment = parseEmployeeAssignment;
const parseEmployeeAssignmentCorrection = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const positions = response.content;
    try {
        return {
            data: positions.data.map(el => parseAssignment(el, null)),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        console.error({ err });
        return helper_1.errorObject;
    }
};
exports.parseEmployeeAssignmentCorrection = parseEmployeeAssignmentCorrection;
const parseAssignment = (position, originalId) => {
    const contract = position.relationships.mediaFiles.data.filter(file => file.attributes.pivots.type === types_3.EmployeeAssignmentFileTypes.CONTRACT).map(file => (0, exports.parseItemFile)(file));
    const order = position.relationships.mediaFiles.data.filter(file => file.attributes.pivots.type === types_3.EmployeeAssignmentFileTypes.ORDER).map(file => (0, exports.parseItemFile)(file));
    const orderWithContract_Order = position.relationships.mediaFiles.data.filter(file => file.attributes.pivots.type === types_3.EmployeeAssignmentFileTypes.CONTRACT_ORDER_ORDER).map(file => (0, exports.parseItemFile)(file));
    const orderWithContract_Contract = position.relationships.mediaFiles.data.filter(file => file.attributes.pivots.type === types_3.EmployeeAssignmentFileTypes.CONTRACT_ORDER_CONTRACT).map(file => (0, exports.parseItemFile)(file));
    const contractExt = position.relationships.mediaFiles.data.filter(file => file.attributes.pivots.type === types_3.EmployeeAssignmentFileTypes.CONTRACT_EXT).map(file => (0, exports.parseItemFile)(file));
    const basicFunctionDuties = position.relationships.mediaFiles.data.filter(file => file.attributes.pivots.type === types_3.EmployeeAssignmentFileTypes.BASIC_FUNCTION_DUTIES).map(file => (0, exports.parseItemFile)(file));
    const dismissalReason = position.relationships.dismissal ? dismissalParser_1.dismissalReasonOptions.find(el => el.id === String(position.relationships.dismissal?.data.attributes.reason)) : null;
    const contractType = position.relationships.contractType && {
        id: position.relationships.contractType.data.id,
        label: position.relationships.contractType.data.attributes.name,
        index: position.relationships.contractType.data.attributes.index,
        type: position.relationships.contractType.data.attributes.type
    };
    return {
        assignId: position.id,
        assignCategory: position.attributes.assignCategory,
        originalId: originalId,
        contractExtContinuationType: position.attributes.assignType === types_1.AssignType.CONTRACT_EXT ? position.attributes.contractContinuationType : null,
        employmentContractType: position.attributes.assignType === types_1.AssignType.CONTRACT ? position.attributes.employmentContractType : null,
        recordNumber: position.attributes.recordNumber,
        recordDate: (0, parsers_2.nullableTransformDate)(position.attributes.recordDate),
        title: position.attributes.title,
        effectiveDate: (0, parsers_2.nullableTransformDate)(position.attributes.effectiveDate),
        assignType: employee_1.assignTypeOptions.find(el => el.id === String(position.attributes.assignType)),
        positionInfos: position.relationships.positionInfo.data.map(el => (0, exports.parseEmployeePositionInfos)(el)),
        minStartDate: null,
        dismissalDate: (0, parsers_2.nullableTransformDate)(position.relationships.dismissal?.data.attributes.dismissalDate || null),
        dismissalReason,
        dismissalReasonShortName: dismissalReason ? (0, parsers_2.parseShortName)(dismissalReason.label) : null,
        files: {
            contract: contract.length > 0 ? contract : [{ ...parsers_1.initialFile, type: types_3.EmployeeAssignmentFileTypes.CONTRACT }],
            order: order.length > 0 ? order : [{ ...parsers_1.initialFile, type: types_3.EmployeeAssignmentFileTypes.ORDER }],
            orderWithContract_Order: orderWithContract_Order.length > 0 ? orderWithContract_Order : [{
                    ...parsers_1.initialFile,
                    type: types_3.EmployeeAssignmentFileTypes.CONTRACT_ORDER_ORDER
                }],
            orderWithContract_Contract: orderWithContract_Contract.length > 0 ? orderWithContract_Contract : [{
                    ...parsers_1.initialFile,
                    type: types_3.EmployeeAssignmentFileTypes.CONTRACT_ORDER_CONTRACT
                }],
            contractExt: contractExt.length > 0 ? contractExt : [{
                    ...parsers_1.initialFile,
                    type: types_3.EmployeeAssignmentFileTypes.CONTRACT_EXT
                }],
            basicFunctionDutiesFiles: basicFunctionDuties.length > 0 ? basicFunctionDuties : [{
                    ...parsers_1.initialFile,
                    type: types_3.EmployeeAssignmentFileTypes.BASIC_FUNCTION_DUTIES
                }]
        },
        visualStatus: parseEmployeeAssignmentVisualStatuses(position.attributes.status),
        contractContractNumber: position.attributes.assignType === types_1.AssignType.CONTRACT ? position.attributes.contractNumber : null,
        contractContractDate: position.attributes.assignType === types_1.AssignType.CONTRACT ? (0, parsers_2.nullableTransformDate)(position.attributes.contractDate) : null,
        contractContractType: position.attributes.assignType === types_1.AssignType.CONTRACT ? contractType : null,
        contractExtContractNumber: position.attributes.assignType === types_1.AssignType.CONTRACT_EXT ? position.attributes.contractNumber : null,
        contractExtContractDate: position.attributes.assignType === types_1.AssignType.CONTRACT_EXT ? (0, parsers_2.nullableTransformDate)(position.attributes.contractDate) : null,
        contractExtContractType: position.attributes.assignType === types_1.AssignType.CONTRACT_EXT ? contractType : null,
        orderOrderNumber: position.attributes.assignType === types_1.AssignType.ORDER ? position.attributes.orderNumber : null,
        orderOrderDate: position.attributes.assignType === types_1.AssignType.ORDER ? (0, parsers_2.nullableTransformDate)(position.attributes.orderDate) : null,
        orderOrderType: position.attributes.assignType === types_1.AssignType.ORDER ? position.attributes.orderTitle : null,
        ocOrderNumber: position.attributes.assignType === types_1.AssignType.ORDER_CONTRACT ? position.attributes.orderNumber : null,
        ocOrderDate: position.attributes.assignType === types_1.AssignType.ORDER_CONTRACT ? (0, parsers_2.nullableTransformDate)(position.attributes.orderDate) : null,
        ocOrderType: position.attributes.assignType === types_1.AssignType.ORDER_CONTRACT ? position.attributes.orderTitle : null,
        ocContractNumber: position.attributes.assignType === types_1.AssignType.ORDER_CONTRACT ? position.attributes.contractNumber : null,
        ocContractDate: position.attributes.assignType === types_1.AssignType.ORDER_CONTRACT ? (0, parsers_2.nullableTransformDate)(position.attributes.contractDate) : null,
        ocContractType: position.attributes.assignType === types_1.AssignType.ORDER_CONTRACT ? contractType : null
    };
};
const parseEmployeePositionInfos = (positionInfo) => {
    const firstFoundedProject = positionInfo.relationships.projects.data
        .find(project => {
        return project.relationships.workInfos.data
            .find(workInfo => workInfo.attributes.salaryType === types_1.SalaryType.FIXED);
    });
    return {
        ...(0, parsers_3.parseEmployeePosition)(positionInfo),
        fixedSalaryAmount: (firstFoundedProject && firstFoundedProject.relationships.workInfos.data[0]?.attributes.salaryAmount) || null,
        hasOtherCompensation: positionInfo.relationships.projects.data.some(project => {
            return project.relationships.workInfos.data.some(workInfo => {
                const hasOtherAmount = workInfo.relationships.items.data.some(item => item.attributes.salaryAmount);
                return hasOtherAmount
                    ? firstFoundedProject && hasOtherAmount
                    : firstFoundedProject && firstFoundedProject.relationships.workInfos.data[0]?.id !== workInfo.id;
            });
        })
    };
};
exports.parseEmployeePositionInfos = parseEmployeePositionInfos;
const parseItemFile = (file) => {
    return {
        id: file.id,
        type: file.attributes.pivots.type,
        name: file.attributes.name,
        link: file.attributes.downloadUrl,
        status: file.attributes.status,
        originalName: file.attributes.originalName,
        extension: file.attributes.extension
    };
};
exports.parseItemFile = parseItemFile;
const parseEmployeeAssignmentVisualStatuses = (status) => {
    switch (status) {
        case types_2.ContractStatuses.ACTIVE:
            return { name: 'აქტიური', color: 'success' };
        case types_2.ContractStatuses.PASSIVE:
            return { name: 'პასიური', color: 'secondary' };
        case types_2.ContractStatuses.CORRECTED:
            return { name: 'ცვლილება', color: 'warning' };
        case types_2.ContractStatuses.EXPIRED:
            return { name: 'ვადაგასული', color: 'danger' };
    }
};
