"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyLegalStatuses = exports.legalStatusOptions = exports.legalStatuses = void 0;
exports.legalStatuses = [
    { id: 1, label: 'ინდივიდუალური მეწარმე - (ი.მ)' },
    { id: 2, label: 'სოლიდარული პასუხისმგებლობის საზოგადოება (სპს)' },
    { id: 3, label: 'კომანდიტური საზოგადოება (კს)' },
    { id: 4, label: 'შეზღუდული პასუხისმგებლობის საზოგადოება (შპს)' },
    { id: 5, label: 'სააქციო საზოგადოება (სს, კორპორაცია)' },
    { id: 6, label: 'კოოპერატივი' },
    { id: 7, label: 'არასამეწარმეო (არაკომერციული) იურიდიული პირი( ა(ა)იპ)' },
    { id: 8, label: 'საჯარო სამართლის იურიდიული პირი (სსიპ))' },
    { id: 9, label: 'არარეგისტრირებული ფიზიკური პირი არარეგ. ფ/პ' },
    { id: 10, label: 'რეგისტრირებული ფიზიკური პირი რეგ. ფ/პ' },
    { id: 11, label: 'მცირე ბიზნესის სტატუსის მქონე ფიზიკური პირი მც. ბიზ. სტ. ფ/პ' },
    { id: 12, label: 'მიკრო ბიზნესის სტატუსის მქონე ფიზიკური პირი მიკრ. ბიზ. სტ. ფ/პ' },
    { id: 13, label: 'ამხანაგობა' }
];
exports.legalStatusOptions = [
    { id: '1', label: 'ინდივიდუალური მეწარმე - (ი.მ)' },
    { id: '2', label: 'სოლიდარული პასუხისმგებლობის საზოგადოება (სპს)' },
    { id: '3', label: 'კომანდიტური საზოგადოება (კს)' },
    { id: '4', label: 'შეზღუდული პასუხისმგებლობის საზოგადოება (შპს)' },
    { id: '5', label: 'სააქციო საზოგადოება (სს, კორპორაცია)' },
    { id: '6', label: 'კოოპერატივი' },
    { id: '7', label: 'არასამეწარმეო (არაკომერციული) იურიდიული პირი( ა(ა)იპ)' },
    { id: '8', label: 'საჯარო სამართლის იურიდიული პირი (სსიპ))' },
    { id: '9', label: 'არარეგისტრირებული ფიზიკური პირი არარეგ. ფ/პ' },
    { id: '10', label: 'რეგისტრირებული ფიზიკური პირი რეგ. ფ/პ' },
    { id: '11', label: 'მცირე ბიზნესის სტატუსის მქონე ფიზიკური პირი მც. ბიზ. სტ. ფ/პ' },
    { id: '12', label: 'მიკრო ბიზნესის სტატუსის მქონე ფიზიკური პირი მიკრ. ბიზ. სტ. ფ/პ' },
    { id: '13', label: 'ამხანაგობა' },
    { id: '14', label: 'სხვა' }
];
exports.CompanyLegalStatuses = {
    1: 'ინდივიდუალური მეწარმე - (ი.მ)',
    2: 'სოლიდარული პასუხისმგებლობის საზოგადოება (სპს)',
    3: 'კომანდიტური საზოგადოება (კს)',
    4: 'შეზღუდული პასუხისმგებლობის საზოგადოება (შპს)',
    5: 'სააქციო საზოგადოება (სს, კორპორაცია)',
    6: 'კოოპერატივი',
    7: 'არასამეწარმეო (არაკომერციული) იურიდიული პირი( ა(ა)იპ)',
    8: 'საჯარო სამართლის იურიდიული პირი (სსიპ))',
    9: 'არარეგისტრირებული ფიზიკური პირი არარეგ. ფ/პ',
    10: 'რეგისტრირებული ფიზიკური პირი რეგ. ფ/პ',
    11: 'მცირე ბიზნესის სტატუსის მქონე ფიზიკური პირი მც. ბიზ. სტ. ფ/პ',
    12: 'მიკრო ბიზნესის სტატუსის მქონე ფიზიკური პირი მიკრ. ბიზ. სტ. ფ/პ',
    13: 'ამხანაგობა'
};
