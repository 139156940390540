"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnitNames = exports.RemunerationWorkPerformed = exports.StructuralUnitType = exports.PositionStatus = exports.StructuralUnitStatuses = void 0;
var StructuralUnitStatuses;
(function (StructuralUnitStatuses) {
    StructuralUnitStatuses[StructuralUnitStatuses["ACTIVE"] = 1] = "ACTIVE";
    StructuralUnitStatuses[StructuralUnitStatuses["PASSIVE"] = 2] = "PASSIVE";
})(StructuralUnitStatuses || (exports.StructuralUnitStatuses = StructuralUnitStatuses = {}));
var PositionStatus;
(function (PositionStatus) {
    PositionStatus[PositionStatus["STUFF_MEMBER"] = 1] = "STUFF_MEMBER";
    PositionStatus[PositionStatus["FREELANCE"] = 2] = "FREELANCE";
})(PositionStatus || (exports.PositionStatus = PositionStatus = {}));
var StructuralUnitType;
(function (StructuralUnitType) {
    StructuralUnitType[StructuralUnitType["STRUCTURAL_UNIT"] = 1] = "STRUCTURAL_UNIT";
    StructuralUnitType[StructuralUnitType["POSITION"] = 2] = "POSITION";
})(StructuralUnitType || (exports.StructuralUnitType = StructuralUnitType = {}));
var RemunerationWorkPerformed;
(function (RemunerationWorkPerformed) {
    RemunerationWorkPerformed[RemunerationWorkPerformed["FIXED_AMOUNT"] = 1] = "FIXED_AMOUNT";
    RemunerationWorkPerformed[RemunerationWorkPerformed["WORK_SUMMERY"] = 2] = "WORK_SUMMERY";
    RemunerationWorkPerformed[RemunerationWorkPerformed["UNIT_FEE"] = 3] = "UNIT_FEE";
})(RemunerationWorkPerformed || (exports.RemunerationWorkPerformed = RemunerationWorkPerformed = {}));
var UnitNames;
(function (UnitNames) {
    UnitNames[UnitNames["PAGE"] = 1] = "PAGE";
    UnitNames[UnitNames["PERFORMANCE"] = 2] = "PERFORMANCE";
    UnitNames[UnitNames["DAY"] = 3] = "DAY";
    UnitNames[UnitNames["HOURS"] = 4] = "HOURS";
    // VIA_PRICE_LIST = 5,  // პრეისკურანტით
    UnitNames[UnitNames["ORDER_OR_IA_PRICE_LIST"] = 6] = "ORDER_OR_IA_PRICE_LIST";
})(UnitNames || (exports.UnitNames = UnitNames = {}));
