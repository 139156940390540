"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.administrativeBuildingReducer = exports.useAdministrativeBuildingReducer = exports.initialAdministrativeBuildingState = void 0;
const react_1 = require("react");
const initialAdministrativeBuildingValue = {
    name: '',
    address: '',
    coordinates: null,
    lat: null,
    lng: null,
    district: null
};
exports.initialAdministrativeBuildingState = {
    data: initialAdministrativeBuildingValue,
    status: null,
    errors: null,
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isLoading: false,
    isMapLoading: false
};
const useAdministrativeBuildingReducer = () => {
    return (0, react_1.useReducer)(exports.administrativeBuildingReducer, exports.initialAdministrativeBuildingState);
};
exports.useAdministrativeBuildingReducer = useAdministrativeBuildingReducer;
const administrativeBuildingReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_ADMINISTRATIVE_BUILDING_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true,
                isMapLoading: true
            };
        case 'DONE_ADMINISTRATIVE_BUILDING_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isFetching: false,
                isMapLoading: false,
                isLoading: state.isCreating || state.isUpdating
            };
        case 'SEND_ADMINISTRATIVE_BUILDING_CREATE':
            return {
                ...state,
                errors: null,
                isCreating: true,
                isLoading: true
            };
        case 'DONE_ADMINISTRATIVE_BUILDING_CREATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isCreating: false,
                isLoading: state.isFetching || state.isUpdating || state.isMapLoading
            };
        case 'SEND_ADMINISTRATIVE_BUILDING_UPDATE':
            return {
                ...state,
                errors: null,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_ADMINISTRATIVE_BUILDING_UPDATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isUpdating: false,
                isLoading: state.isCreating || state.isFetching || state.isMapLoading
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.administrativeBuildingReducer = administrativeBuildingReducer;
