"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformStatisticFilterParamsToUrl = exports.getFilterValuesFromUrl = exports.ProcurementTypeOptions = exports.transformStatisticParamsToFilterParams = exports.transformStatisticParams = void 0;
const types_1 = require("../types");
const queryString_1 = require("core/helpers/queryString");
const helper_1 = require("src/modules/common/helper");
const transformStatisticParams = (values) => {
    return {
        filters: {
            type: Number(values.type?.id),
            createdAtTo: values.createdAtTo ? (0, helper_1.transformParamsDate)(values.createdAtTo) : undefined,
            createdAtFrom: values.createdAtFrom ? (0, helper_1.transformParamsDate)(values.createdAtFrom) : undefined
        },
        page: values.page,
        perPage: values.perPage
    };
};
exports.transformStatisticParams = transformStatisticParams;
const transformStatisticParamsToFilterParams = (params) => {
    const filters = { type: types_1.StatisticProcurementTypes.SUMMERY_OF_PURCHASE };
    const paginate = {};
    const filterParams = {
        type: Number(params.type?.id),
        createdAtTo: params.createdAtTo,
        createdAtFrom: params.createdAtFrom,
        page: params.page,
        perPage: params.perPage
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if (value && (key !== 'page' && key !== 'perPage'))
                filters[key] = value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(filters).length === 0)
            return { ...paginate };
        return { ...paginate, filters };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformStatisticParamsToFilterParams = transformStatisticParamsToFilterParams;
exports.ProcurementTypeOptions = [
    { id: String(types_1.StatisticProcurementTypes.SUMMERY_OF_PURCHASE), label: 'ხელშეკრულების ჯამური ღირებულების მიხედვით' },
    { id: String(types_1.StatisticProcurementTypes.CATEGORIES), label: 'კატეგორიების მიხედვით' },
    { id: String(types_1.StatisticProcurementTypes.BUDGET_ARTICLES), label: 'საბიუჯეტო მუხლების მიხედვით' },
    { id: String(types_1.StatisticProcurementTypes.PURCHASE_SUBJECTS), label: 'შესყიდვების საგნების მიხედვით' },
    { id: String(types_1.StatisticProcurementTypes.PROJECTS), label: 'დაფინანსების წყაროს მიხედვით' }
];
const getFilterValuesFromUrl = (url) => {
    try {
        const params = (0, queryString_1.parse)(url);
        return {
            page: params.page,
            perPage: params.perPage,
            createdAtFrom: params.createdAtFrom ? (0, helper_1.transformParamsDate)(params.createdAtFrom) : undefined,
            createdAtTo: params.createdAtTo ? (0, helper_1.transformParamsDate)(params.createdAtTo) : undefined,
            type: params.typeId && params.typeLabel
                ? { id: params.typeId, label: params.typeLabel }
                : { id: String(types_1.StatisticProcurementTypes.SUMMERY_OF_PURCHASE), label: 'ხელშეკრულების ჯამური ღირებულების მიხედვით' }
        };
    }
    catch (err) {
        console.error({ err });
        return {
            type: { id: String(types_1.StatisticProcurementTypes.SUMMERY_OF_PURCHASE), label: 'ხელშეკრულების ჯამური ღირებულების მიხედვით' }
        };
    }
};
exports.getFilterValuesFromUrl = getFilterValuesFromUrl;
const transformStatisticFilterParamsToUrl = (values) => {
    const { page, perPage, createdAtFrom, createdAtTo, type } = values;
    return {
        page,
        perPage,
        createdAtFrom,
        createdAtTo,
        typeId: type && String(type.id) || undefined,
        typeLabel: type && type.label || undefined
    };
};
exports.transformStatisticFilterParamsToUrl = transformStatisticFilterParamsToUrl;
