"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const helper_1 = require("../helper");
const react_1 = require("react");
const useNotifyDepartment = () => {
    const [loading, setLoading] = (0, react_1.useState)(false);
    const updateNotifyDepartments = async (id, values) => {
        setLoading(true);
        const params = (0, helper_1.transformInvoiceSendStatusParams)(values);
        const invoice = await index_1.InvoicesApi.notifyDepartments(id, params);
        setLoading(false);
        return invoice;
    };
    return { updateNotifyDepartments, loading };
};
exports.default = useNotifyDepartment;
