"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const useHRSubmissionVerifyItem_1 = require("../store/useHRSubmissionVerifyItem");
const react_1 = require("react");
const index_1 = require("api/index");
const useAssignSubmissionVerifyItem = () => {
    const [state, dispatch] = (0, useHRSubmissionVerifyItem_1.useAssignSubmissionVerifyItemReducer)();
    const [startTransition, setStartTransition] = (0, react_1.useState)(false);
    const handleCreateWorkType = async (value) => {
        dispatch({ type: 'SEND_CREATE_WORK_TYPE_REQUEST' });
        const workType = await index_1.WorkTypesApi.createWorkType({ name: value });
        dispatch({ type: 'DONE_CREATE_WORK_TYPE_REQUEST' });
        return workType;
    };
    return {
        startTransition,
        setStartTransition,
        handleCreateWorkType,
        state
    };
};
exports.default = useAssignSubmissionVerifyItem;
