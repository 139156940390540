"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const assignSubmissionItem_1 = require("../store/assignSubmissionItem");
const react_1 = require("react");
const index_1 = require("api/index");
const useAssignSubmissionItem = () => {
    const [state, dispatch] = (0, assignSubmissionItem_1.useAssignSubmissionItemReducer)();
    const [startTransition, setStartTransition] = (0, react_1.useState)(false);
    const handleCreateWorkType = async (value) => {
        dispatch({ type: 'SEND_CREATE_WORK_TYPE_REQUEST' });
        const workType = await index_1.WorkTypesApi.createWorkType({ name: value });
        dispatch({ type: 'DONE_CREATE_WORK_TYPE_REQUEST' });
        return workType;
    };
    return {
        state,
        handleCreateWorkType,
        startTransition,
        setStartTransition
    };
};
exports.default = useAssignSubmissionItem;
