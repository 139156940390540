"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcurementStatus = exports.submissionVerifyItemStatusColor = exports.purchaseStatusColor = exports.purchaseStatus = exports.submissionVerifyItemStatus = exports.submissionStatusColor = exports.submissionVerifyStatus = exports.submissionStatus = exports.conditionalSubmissionStatus = void 0;
exports.conditionalSubmissionStatus = {
    1: 'პროექტი',
    // 2: 'E-doc-ით გაგზავნილი',
    // 3: 'კანცელარიაში გაგზავნილი',
    4: 'შესყიდვის პროცესში',
    5: 'გაგზავნილი',
    6: 'დასრულებული',
    7: 'უარყოფილი',
    8: 'შეჩერებული'
};
exports.submissionStatus = {
    1: 'პროექტი',
    2: 'გადამოწმების პროცესი',
    3: 'ვერიფიკაციის პროცესი',
    4: 'დადასტურების პროცესი',
    5: 'დადასტურებული',
    6: 'ნაწილობრივ დადასტურებული',
    7: 'უარყოფილი',
    8: 'შეჩერებული',
    9: 'ცვლილებისთვის'
};
exports.submissionVerifyStatus = {
    1: 'გადამოწმების პროცესი',
    2: 'ვერიფიკაციის პროცესი',
    3: 'დადასტურების პროცესი',
    4: 'დადასტურებული',
    5: 'ნაწილობრივ დადასტურებული',
    6: 'უარყოფილი',
    7: 'შეჩერებული'
};
exports.submissionStatusColor = {
    1: 'secondary',
    2: 'secondary',
    3: 'info',
    4: 'sky-color',
    5: 'success',
    6: 'primary',
    7: 'danger',
    8: 'warning',
    9: 'warning'
};
exports.submissionVerifyItemStatus = {
    1: 'პროექტი',
    2: 'დადასტურებული',
    3: 'უარყოფილი',
    4: 'შეჩერებული'
};
exports.purchaseStatus = {
    1: 'პროექტი',
    2: 'ვიზირების პროცესში',
    3: 'ხელმოწერის პროცესში',
    4: 'უარყოფილი',
    5: 'ხელმოწერილი',
    6: 'კორექტირებული',
    7: 'პროექტი',
    8: 'ხელმოწერილი',
    9: 'ვიზირების პროცესში',
    10: 'შეჩერებული'
};
exports.purchaseStatusColor = {
    1: 'secondary',
    2: 'info',
    3: 'primary',
    4: 'danger',
    5: 'success',
    6: 'warning',
    7: 'secondary',
    8: 'success',
    9: 'info',
    10: 'warning'
};
exports.submissionVerifyItemStatusColor = {
    1: 'secondary',
    2: 'success',
    3: 'danger',
    4: 'warning'
};
exports.ProcurementStatus = {
    1: 'პროექტი',
    2: 'ვიზირების პროცესში',
    3: 'ხელმოწერის პროცესში',
    4: 'უარყოფილი',
    5: 'ხელმოწერილი',
    6: 'კორექტირებული',
    7: 'კორექტირების პროცესის დაწყება',
    8: 'კორექტირების პროცესი',
    9: 'დაბრუნებული',
    10: 'შეჩერებული'
};
