"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSentEmail = exports.sendEmail = exports.getSentEmails = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/administer/sentEmails/parsers");
const getSentEmails = async (params) => {
    const response = await Api.get('emails', params);
    return (0, parsers_1.parseSentEmails)(response);
};
exports.getSentEmails = getSentEmails;
const sendEmail = async (id) => {
    const response = await Api.patch(`emails/${id}`, {});
    return (0, parsers_1.parseSentEmail)(response);
};
exports.sendEmail = sendEmail;
const getSentEmail = async (id) => {
    const response = await Api.get('emails/' + id);
    return (0, parsers_1.parseSentEmail)(response);
};
exports.getSentEmail = getSentEmail;
