"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankFormValidation = void 0;
const types_1 = require("api/references/banks/types");
const validates_1 = require("core/helpers/validates");
const BankFormValidation = (values) => {
    const formErrors = {
        title: (0, validates_1.required)(values.title),
        code: (0, validates_1.required)(values.code),
        iban: values.type === types_1.BANK_TYPE.TYPE_GEORGIAN ? (0, validates_1.required)(values.iban) : undefined
    };
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.BankFormValidation = BankFormValidation;
