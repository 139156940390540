"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RefreshTokenStatus = void 0;
var RefreshTokenStatus;
(function (RefreshTokenStatus) {
    RefreshTokenStatus[RefreshTokenStatus["LOCKED"] = 1] = "LOCKED";
    RefreshTokenStatus[RefreshTokenStatus["SUCCESS"] = 2] = "SUCCESS";
    RefreshTokenStatus[RefreshTokenStatus["EMPTY_TOKEN"] = 3] = "EMPTY_TOKEN";
    RefreshTokenStatus[RefreshTokenStatus["FAILED"] = 4] = "FAILED";
})(RefreshTokenStatus || (exports.RefreshTokenStatus = RefreshTokenStatus = {}));
