"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const purchaseStatistic_1 = require("src/modules/reports/purchases/statistics/store/purchaseStatistic");
const api_1 = require("src/api");
const types_1 = require("../types");
const helpers_1 = require("../helpers");
const queryString_1 = require("core/helpers/queryString");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const react_router_1 = require("react-router");
const usePurchaseStatistics = () => {
    const navigate = (0, react_router_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, purchaseStatistic_1.useProcurementStaticsReducer)();
    const isMounted = (0, react_1.useRef)(false);
    const navigatePagination = (page, values) => {
        handleFilter({ ...values, page }).then();
    };
    const selectPerPage = (perPage, values) => {
        handleFilter({ ...values, perPage, page: undefined }).then();
    };
    const handleFilter = async (values) => {
        const params = (0, helpers_1.transformStatisticParamsToFilterParams)(values);
        dispatch({ type: 'SEND_STATISTICS_REQUEST' });
        navigate('/reports/purchases/statistics' + (0, queryString_1.stringify)((0, helpers_1.transformStatisticFilterParamsToUrl)(values)));
        if (!values.type)
            return { errors: [{ name: 'catch', message: 'unexpected' }], status: apiGlobalTypes_1.ResponseStatuses.UNEXPECTED };
        if (Number(values.type.id) === types_1.StatisticProcurementTypes.SUMMERY_OF_PURCHASE) {
            const response = await api_1.ProcurementStatisticsApi.getProcurementStatistics(params);
            dispatch({ type: 'DONE_PROCUREMENTS_STATISTICS_REQUEST', payload: response });
            return { errors: response.errors, status: response.status };
        }
        if (Number(values.type.id) === types_1.StatisticProcurementTypes.PURCHASE_SUBJECTS) {
            const response = await api_1.ProcurementStatisticsApi.getProcurementStatisticsByItems(params);
            dispatch({ type: 'DONE_PROCUREMENT_ITEMS_STATISTICS_REQUEST', payload: response });
            return { errors: response.errors, status: response.status };
        }
        if (Number(values.type.id) === types_1.StatisticProcurementTypes.CATEGORIES) {
            const response = await api_1.ProcurementStatisticsApi.getProcurementStatisticsByItemCategories(params);
            dispatch({ type: 'DONE_PROCUREMENT_ITEMS_CATEGORIES_REQUEST', payload: response });
            return { errors: response.errors, status: response.status };
        }
        if (Number(values.type.id) === types_1.StatisticProcurementTypes.BUDGET_ARTICLES) {
            const response = await api_1.ProcurementStatisticsApi.getProcurementStatisticsByItemBudgetArticles(params);
            dispatch({ type: 'DONE_PROCUREMENT_ITEMS_BUDGET_REQUEST', payload: response });
            return { errors: response.errors, status: response.status };
        }
        if (Number(values.type.id) === types_1.StatisticProcurementTypes.PROJECTS) {
            const response = await api_1.ProcurementStatisticsApi.getProcurementStatisticsByItemProjects(params);
            dispatch({ type: 'DONE_PROCUREMENT_ITEMS_PROJECT_REQUEST', payload: response });
            return { errors: response.errors, status: response.status };
        }
        return { errors: null, status: apiGlobalTypes_1.ResponseStatuses.SUCCESS };
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    const exportStatisticFile = async (values) => {
        const params = (0, helpers_1.transformStatisticParams)(values);
        dispatch({ type: 'SEND_FILE_REQUEST' });
        const procurementStatisticFile = await api_1.ProcurementStatisticsApi.exportProcurementStatistics(params);
        if (procurementStatisticFile.data !== null)
            open(procurementStatisticFile.data.link);
        dispatch({ type: 'DONE_FILE_REQUEST' });
        return { errors: procurementStatisticFile.errors, status: procurementStatisticFile.status };
    };
    const initializeFilterForm = async () => {
        const values = (0, helpers_1.getFilterValuesFromUrl)(location.search);
        dispatch({ type: 'INITIAL_FILTER_DATA', payload: values });
        handleFilter(values).then();
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        initializeFilterForm().then();
        return () => { isMounted.current = false; };
    }, []);
    return {
        handleFilter,
        exportStatisticFile,
        state,
        closeErrorMessage,
        navigatePagination,
        selectPerPage
    };
};
exports.default = usePurchaseStatistics;
