"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.correctionReducer = exports.useCorrectionReducer = exports.initialCorrectionState = void 0;
const react_1 = require("react");
const initialCorrectionValue = {
    number: '',
    publicationDate: '',
    name: '',
    createdBy: '',
    files: [],
    deletedAt: null,
    createdAt: '',
    updatedAt: '',
    articleNumber: '',
    correction: []
};
exports.initialCorrectionState = {
    data: initialCorrectionValue,
    errors: null,
    status: null,
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isLoading: false
};
const useCorrectionReducer = () => {
    return (0, react_1.useReducer)(exports.correctionReducer, exports.initialCorrectionState);
};
exports.useCorrectionReducer = useCorrectionReducer;
const correctionReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_CORRECTION_CREATE':
            return {
                ...state,
                isCreating: true,
                isLoading: true
            };
        case 'DONE_CORRECTION_CREATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                isCreating: false,
                status: action.payload.status,
                isLoading: state.isFetching || state.isUpdating
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.correctionReducer = correctionReducer;
