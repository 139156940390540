"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const user_1 = require("../store/user");
const index_1 = require("api/index");
const queryString_1 = require("core/helpers/queryString");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useUser = (props) => {
    const [state, dispatch] = (0, user_1.useUserReducer)();
    const [listingParams, setListingParams] = (0, react_1.useState)('');
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const getUser = async (id) => {
        dispatch({ type: 'SEND_USER_REQUEST' });
        const user = await index_1.UsersApi.getUser(id);
        dispatch({ type: 'DONE_USER_REQUEST', payload: user });
    };
    const createUser = async (values) => {
        const params = (0, helpers_1.transformUserParams)(values);
        dispatch({ type: 'SEND_USER_CREATE' });
        const user = await index_1.UsersApi.createUser(params);
        dispatch({ type: 'DONE_USER_CREATE', payload: user });
        if (user.data !== null)
            navigate(`/administer/users/${user.data.id}/edit`);
        return user;
    };
    const updateUser = async (id, values) => {
        const params = (0, helpers_1.transformUserParams)(values);
        dispatch({ type: 'SEND_USER_UPDATE' });
        const user = await index_1.UsersApi.updateUser(id, params);
        dispatch({ type: 'DONE_USER_UPDATE', payload: user });
        return user;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => {
        const params = (0, helpers_1.transformUserFilterParamsToUrl)((0, helpers_1.transformUrlToUserFilterParams)(location.search));
        const searchParams = (0, queryString_1.stringify)({ ...params });
        setListingParams(searchParams || '?');
        if (props.id)
            getUser(props.id).then();
    }, [props.id]);
    return { state, createUser, updateUser, getUser, closeErrorMessage, listingParams };
};
exports.default = useUser;
