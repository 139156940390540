"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.employeeReducer = exports.useEmployeeReducer = exports.initialEmployeeNavigate = exports.initialEmployeeState = exports.initialEmployeeValue = void 0;
const types_1 = require("api/references/employees/types");
const react_1 = require("react");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
exports.initialEmployeeValue = {
    contactInfo: {
        firstname: '',
        lastname: '',
        identityNumber: null,
        idCardValidTo: null,
        passportNumber: null,
        gender: types_1.EmployeeGender.FEMALE,
        birthDate: null,
        actualAddress: null,
        mobileNumber: null,
        workPhoneNumber: null,
        personalEmail: null,
        email: null,
        personalCaseNumber: null,
        passportValidTo: null,
        firstnameLatin: null,
        lastnameLatin: null,
        age: null,
        legalAddress: null,
        postIndex: null,
        country: null,
        foreignCitizen: types_1.Citizen.GEORGIAN,
        residenceCertificateNumber: null,
        residenceCertificateEndDate: null,
        resident: false,
        administrativeBuilding: null,
        noWorkPlace: false,
        administrativeBuildingRoom: null
    },
    familyMembers: []
};
exports.initialEmployeeState = {
    data: exports.initialEmployeeValue,
    status: null,
    errors: null,
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isLoading: false,
    addingCitizenship: false,
    addingFamilyMember: false,
    deletingFamilyMember: false,
    updatingEducationHigh: false,
    updatingEducationProf: false,
    updatingEducationSecondary: false,
    loadingItems: []
};
exports.initialEmployeeNavigate = {
    next: false,
    previous: false,
    isLoading: false,
    lastElement: false,
    firstElement: false
};
const useEmployeeReducer = () => {
    return (0, react_1.useReducer)(exports.employeeReducer, exports.initialEmployeeState);
};
exports.useEmployeeReducer = useEmployeeReducer;
const employeeReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_EMPLOYEE_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_EMPLOYEE_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isFetching: false,
                isLoading: state.isCreating || state.isUpdating
            };
        case 'SEND_EMPLOYEE_CREATE':
            return {
                ...state,
                errors: null,
                isCreating: true,
                isLoading: true
            };
        case 'DONE_EMPLOYEE_CREATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isCreating: false,
                isLoading: state.isFetching || state.isUpdating
            };
        case 'SEND_EMPLOYEE_UPDATE':
            return {
                ...state,
                errors: null,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_EMPLOYEE_UPDATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isUpdating: false,
                isLoading: state.isCreating || state.isFetching
            };
        case 'SEND_EDUCATION_PROF_UPDATE':
            return {
                ...state,
                updatingEducationProf: true
            };
        case 'SEND_EDUCATION_SECONDARY_UPDATE':
            return {
                ...state,
                updatingEducationProf: true
            };
        case 'DONE_EDUCATION_SECONDARY_UPDATE':
            return {
                ...state,
                data: {
                    ...state.data
                }
            };
        case 'SET_LOADING_ITEM':
            return {
                ...state,
                loadingItems: [...state.loadingItems, action.payload]
            };
        case 'UN_SET_LOADING_ITEM':
            return {
                ...state,
                loadingItems: state.loadingItems.filter(id => id !== action.payload)
            };
        case 'SEND_ADD_EMPLOYEE_FAMILY_MEMBER':
            return {
                ...state,
                addingFamilyMember: true
            };
        case 'DONE_ADD_EMPLOYEE_FAMILY_MEMBER':
            return {
                ...state,
                data: {
                    ...state.data,
                    familyMembers: action.payload.data || state.data.familyMembers
                },
                errors: action.payload.errors,
                addingFamilyMember: false
            };
        case 'SEND_DELETE_EMPLOYEE_FAMILY_MEMBER':
            return {
                ...state,
                deletingFamilyMember: true
            };
        case 'DONE_DELETE_EMPLOYEE_FAMILY_MEMBER':
            return {
                ...state,
                deletingFamilyMember: false,
                errors: action.payload.employee.errors,
                data: {
                    ...state.data,
                    familyMembers: action.payload.employee.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS
                        ? state.data.familyMembers.filter(el => el.id !== action.payload.id)
                        : state.data.familyMembers
                }
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.employeeReducer = employeeReducer;
