"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.typeOptions = exports.positionStatuses = void 0;
exports.positionStatuses = [
    { id: 1, label: 'შტატიანი პერსონალი' },
    { id: 2, label: 'შტატგარეშე პერსონალი' }
];
exports.typeOptions = [
    { id: 1, label: 'სტრუქტურული ერთეული' },
    { id: 2, label: 'თანამდებობა' }
];
