"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.holidayReducer = exports.useHolidayReducer = exports.initialHolidayState = void 0;
const react_1 = require("react");
const initialHolidayValue = { date: '', title: '' };
exports.initialHolidayState = {
    data: initialHolidayValue,
    status: null,
    errors: null,
    isCreating: false,
    isLoading: false,
    isFetching: false,
    isUpdating: false
};
const useHolidayReducer = () => {
    return (0, react_1.useReducer)(exports.holidayReducer, exports.initialHolidayState);
};
exports.useHolidayReducer = useHolidayReducer;
const holidayReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_HOLIDAY_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_HOLIDAY_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isFetching: false,
                isLoading: state.isCreating || state.isUpdating
            };
        case 'SEND_HOLIDAY_CREATE':
            return {
                ...state,
                errors: null,
                isCreating: true,
                isLoading: true
            };
        case 'DONE_HOLIDAY_CREATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                isCreating: false,
                status: action.payload.status,
                isLoading: state.isFetching || state.isUpdating
            };
        case 'SEND_HOLIDAY_UPDATE':
            return {
                ...state,
                errors: null,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_HOLIDAY_UPDATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                isUpdating: false,
                status: action.payload.status,
                isLoading: state.isCreating || state.isFetching
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.holidayReducer = holidayReducer;
