"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.commentsReducer = exports.useCommentsReducer = void 0;
const react_1 = require("react");
const initialCommentsState = {
    data: [],
    errors: null,
    isFetching: false,
    isCreating: false,
    isLoading: false
};
const useCommentsReducer = () => {
    return (0, react_1.useReducer)(exports.commentsReducer, initialCommentsState);
};
exports.useCommentsReducer = useCommentsReducer;
const commentsReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_SUBMISSION_VERIFY_COMMENT_REQUEST':
            return {
                ...state,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_SUBMISSION_VERIFY_COMMENT_REQUEST':
            return {
                ...state,
                errors: action.payload.errors,
                data: action.payload.data || state.data,
                isFetching: false,
                isLoading: state.isCreating
            };
        case 'SEND_SUBMISSION_VERIFY_ADD_COMMENT':
            return {
                ...state,
                isCreating: true,
                isLoading: true
            };
        case 'DONE_SUBMISSION_VERIFY_ADD_COMMENT':
            return {
                ...state,
                data: action.payload.data ? [...state.data, action.payload.data] : state.data,
                isCreating: false,
                isLoading: state.isFetching
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.commentsReducer = commentsReducer;
