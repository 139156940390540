"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createWorkType = exports.getWorkTypesForSelect = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/documents/human-resources/work-types/parsers");
const react_toastify_1 = require("react-toastify");
const getWorkTypesForSelect = async (params) => {
    const response = await Api.get('references/human-resources/work-types', params);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, parsers_1.parseWorkTypesForSelect)(response);
};
exports.getWorkTypesForSelect = getWorkTypesForSelect;
const createWorkType = async (params) => {
    const response = await Api.post('references/human-resources/work-types', params);
    return (0, parsers_1.parseWorkType)(response);
};
exports.createWorkType = createWorkType;
