"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const holiday_1 = require("../store/holiday");
const index_1 = require("api/index");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useHoliday = (props) => {
    const [state, dispatch] = (0, holiday_1.useHolidayReducer)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const getHoliday = async (id) => {
        dispatch({ type: 'SEND_HOLIDAY_REQUEST' });
        const holiday = await index_1.HolidaysApi.getHoliday(id);
        dispatch({ type: 'DONE_HOLIDAY_REQUEST', payload: holiday });
    };
    const createHoliday = async (values) => {
        const params = (0, helpers_1.transformHolidayParams)(values);
        dispatch({ type: 'SEND_HOLIDAY_CREATE' });
        const holiday = await index_1.HolidaysApi.createHoliday(params);
        dispatch({ type: 'DONE_HOLIDAY_CREATE', payload: holiday });
        if (holiday.data !== null) {
            navigate(`/references/holidays/${holiday.data.id}/edit`);
        }
        return holiday;
    };
    const updateHoliday = async (id, values) => {
        const params = (0, helpers_1.transformHolidayParams)(values);
        dispatch({ type: 'SEND_HOLIDAY_UPDATE' });
        const holiday = await index_1.HolidaysApi.updateHoliday(id, params);
        dispatch({ type: 'DONE_HOLIDAY_UPDATE', payload: holiday });
        return holiday;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getHoliday(props.id).then(); }, [props.id]);
    return {
        closeErrorMessage,
        createHoliday,
        getHoliday,
        updateHoliday,
        state
    };
};
exports.default = useHoliday;
