"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHumanResourcesSubmissionsItems = void 0;
const useCascadeItemsReducer_1 = require("../store/useCascadeItemsReducer");
const index_1 = require("api/index");
const react_1 = require("react");
const useHumanResourcesSubmissionsItems = (id) => {
    const [state, dispatch] = (0, useCascadeItemsReducer_1.useHumanResourceSubmissionsItemsReducer)();
    const getHumanResourcesSubmissionsItems = async (page) => {
        const params = page ? { page } : {};
        dispatch({ type: 'SEND_HUMAN_RESOURCES_SUBMISSIONS_ITEMS_REQUEST' });
        const items = await index_1.HumanResourcesSubmissionsApi.getHRAssignSubmissionItems(id, params);
        dispatch({ type: 'DONE_HUMAN_RESOURCES_SUBMISSIONS_ITEMS_REQUEST', payload: items });
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { getHumanResourcesSubmissionsItems().then(); }, []);
    return { state, getHumanResourcesSubmissionsItems, closeErrorMessage };
};
exports.useHumanResourcesSubmissionsItems = useHumanResourcesSubmissionsItems;
