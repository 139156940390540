"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.administrativeOrderArticlesReducer = exports.useAdministrativeOrderArticlesReducer = exports.initialAdministrativeOrderArticlesState = exports.initialAdministrativeOrderArticle = void 0;
const react_1 = require("react");
exports.initialAdministrativeOrderArticle = {
    id: '',
    administrativeOrdersArticle: null,
    name: '',
    number: '',
    price: null
};
exports.initialAdministrativeOrderArticlesState = {
    data: [],
    article: exports.initialAdministrativeOrderArticle,
    status: null,
    loadingItems: [],
    errors: null,
    isFetching: true,
    isUpdating: false,
    isLoading: false,
    isInserting: false,
    isDeleting: false
};
const useAdministrativeOrderArticlesReducer = () => {
    return (0, react_1.useReducer)(exports.administrativeOrderArticlesReducer, exports.initialAdministrativeOrderArticlesState);
};
exports.useAdministrativeOrderArticlesReducer = useAdministrativeOrderArticlesReducer;
const administrativeOrderArticlesReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_ARTICLES_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true
            };
        case 'DONE_ARTICLES_REQUEST':
            return {
                ...state,
                data: action.payload.data?.map(el => transformArticle(el)) || [],
                status: action.payload.status,
                errors: action.payload.errors,
                isFetching: false
            };
        case 'SEND_CREATE_OR_UPDATE_ARTICLES':
            return {
                ...state,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_CREATE_OR_UPDATE_ARTICLES':
            return {
                ...state,
                data: action.payload.articles.data && action.payload.articles.data[0]
                    ? updateTreeForUpdatedNode(state.data, action.payload.articles.data[0])
                    : state.data,
                article: transformArticleArticleFormValue(action.payload.articles.data) || state.article,
                isUpdating: false,
                isLoading: state.isInserting || state.isFetching
            };
        case 'DONE_DELETE_ARTICLE':
            return {
                ...state,
                data: removeDeletedArticle(state.data, action.payload),
                isDeleting: false
            };
        case 'CLOSE_ARTICLE':
            return {
                ...state,
                data: transformCloseArticle(state.data, action.payload)
            };
        case 'SEND_ARTICLE_CHILDREN_REQUEST':
            return {
                ...state,
                loadingItems: [...state.loadingItems, action.payload]
            };
        case 'DONE_ARTICLE_CHILDREN_REQUEST':
            return {
                ...state,
                data: action.payload.articles.data
                    ? transformArticles(state.data, action.payload.articles.data, action.payload.loadingItem)
                    : state.data,
                errors: action.payload.articles.errors,
                loadingItems: state.loadingItems.filter(el => el.id !== action.payload.loadingItem.id)
            };
        case 'SET_LOADING_ITEM':
            return {
                ...state,
                loadingItems: [...state.loadingItems, action.payload]
            };
        case 'UNSET_LOADING_ITEM':
            return {
                ...state,
                loadingItems: state.loadingItems.filter(el => !(el.id === action.payload.id && el.level === action.payload.level))
            };
        case 'SET_ARTICLE_FORM':
            return {
                ...state,
                article: action.payload || exports.initialAdministrativeOrderArticle
            };
        case 'ADD_ARTICLE_FORM':
            return {
                ...state,
                article: action.payload ? { ...exports.initialAdministrativeOrderArticle, administrativeOrdersArticle: action.payload } : exports.initialAdministrativeOrderArticle
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.administrativeOrderArticlesReducer = administrativeOrderArticlesReducer;
const transformArticles = (stateData, payloadData, loadingItem) => {
    return stateData.map(el => {
        if (loadingItem.id === el.id && loadingItem.level === el.level) {
            return {
                ...el,
                isOpen: !loadingItem.isOpen,
                children: payloadData.map(item => transformArticle(item))
            };
        }
        if (el.children.length > 0)
            el.children = transformArticles(el.children, payloadData, loadingItem);
        return el;
    });
};
const updateTreeForUpdatedNode = (stateData, node, level = 0) => {
    if (level === 0 && node.parent === null && !stateData.find(el => el.id === node.id))
        stateData.push(transformArticle(node));
    const newState = stateData.map(el => {
        if (el.children.length > 0) {
            const outElement = el.children.find(item => item.id === node.id && item.parent?.id !== node.parent?.id);
            const children = outElement ? el.children.filter(item => outElement.id !== item.id) : el.children;
            el.hasChildren = outElement ? children.length > 0 : el.hasChildren;
            el.children = updateTreeForUpdatedNode(children, node, level + 1);
        }
        if (node.id === el.id)
            return transformArticle(node, el.level, el.isOpen, el.children);
        if (node.parent?.id === el.id && !el.children.find(el => node.id === el.id)) {
            return {
                ...el,
                hasChildren: true,
                isOpen: true,
                children: [...el.children, transformArticle(node)]
            };
        }
        return el;
    });
    if (level === 0)
        return newState.filter(el => !el.parent);
    return newState;
};
const transformArticleArticleFormValue = (articles) => {
    const article = articles && articles[0];
    if (article) {
        return {
            id: article.id,
            administrativeOrdersArticle: article.parent,
            name: article.name,
            number: article.number,
            price: article.price
        };
    }
    return null;
};
const removeDeletedArticle = (stateData, article) => {
    const filteredState = stateData.filter(el => {
        if (el.children.length > 0)
            el.children = removeDeletedArticle(el.children, article);
        return el.id !== article.id;
    });
    return filteredState.map(el => {
        return {
            ...el,
            hasChildren: article.parent && el.id === article.parent.id ? el.children.length !== 0 : el.hasChildren
        };
    });
};
const transformArticle = (article, level = 0, isOpen = false, children = []) => ({ ...article, level, isOpen, children });
const transformCloseArticle = (stateData, payloadData) => {
    return stateData.map(el => {
        if (el.children.length > 0)
            el.children = transformCloseArticle(el.children, payloadData);
        return { ...el, isOpen: payloadData.id === el.id && payloadData.level === el.level ? false : el.isOpen };
    });
};
