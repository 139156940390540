"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useItems = () => {
    const [selectedAction, setSelectedAction] = (0, react_1.useState)(null);
    const [cascadedItems, setCascadedItems] = (0, react_1.useState)([]);
    const selectAction = (id) => {
        setSelectedAction(prevState => {
            if (prevState !== null && prevState === id)
                return null;
            return id;
        });
    };
    const handleCascadeItem = (id) => {
        cascadedItems.includes(id)
            ? setCascadedItems(cascadedItems.filter(el => el !== id))
            : setCascadedItems([...cascadedItems, id]);
    };
    return { selectedAction, selectAction, cascadedItems, handleCascadeItem };
};
exports.default = useItems;
