"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useReducerUserProjects = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
const initialUserProjects = {
    userProjects: [],
    projects: [],
    loadingCheckbox: [],
    meta: store_1.initialMeta,
    errors: null,
    isFetching: true,
    isDeleting: false
};
const useReducerUserProjects = () => {
    return (0, react_1.useReducer)(UserProjectsReducer, initialUserProjects);
};
exports.useReducerUserProjects = useReducerUserProjects;
const UserProjectsReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_USER_PROJECTS_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_USER_PROJECTS_REQUEST':
            return {
                ...state,
                userProjects: action.payload.data || state.userProjects,
                errors: action.payload.errors,
                isFetching: false
            };
        case 'SEND_PROJECTS_DATA_REQUEST':
            return {
                ...state,
                isFetching: true,
                errors: null
            };
        case 'DONE_PROJECTS_DATA_REQUEST':
            return {
                ...state,
                projects: setSelectedProjects(action.payload.data || state.projects, state.userProjects),
                meta: action.payload.meta || state.meta,
                isFetching: false,
                errors: action.payload.errors
            };
        case 'SEND_USER_PROJECTS_ATTACH_REQUEST':
            return {
                ...state,
                loadingCheckbox: [...state.loadingCheckbox, action.payload]
            };
        case 'DONE_USER_PROJECTS_ATTACH_REQUEST':
            return {
                ...state,
                userProjects: action.payload.status ? [...state.userProjects, action.payload.projectId] : state.userProjects,
                projects: action.payload.status ? setSelectedProject(state.projects, action.payload.projectId, true) : state.projects,
                loadingCheckbox: state.loadingCheckbox.filter(el => el !== action.payload.projectId)
            };
        case 'SEND_USER_PROJECTS_DETACH_REQUEST':
            return {
                ...state,
                loadingCheckbox: [...state.loadingCheckbox, action.payload]
            };
        case 'DONE_USER_PROJECTS_DETACH_REQUEST':
            return {
                ...state,
                userProjects: action.payload.status
                    ? state.userProjects.filter(selectedId => selectedId !== action.payload.projectId)
                    : state.userProjects,
                projects: action.payload.status ? setSelectedProject(state.projects, action.payload.projectId) : state.projects,
                loadingCheckbox: state.loadingCheckbox.filter(el => el !== action.payload.projectId)
            };
        default:
            return state;
    }
};
const setSelectedProject = (projects, currentId, attach = false) => {
    return projects.map(el => {
        return {
            ...el,
            selected: attach
                ? el.id === currentId ? true : el.selected
                : el.id === currentId ? false : el.selected
        };
    });
};
const setSelectedProjects = (projects, selectedProjects) => {
    return projects.map(el => {
        return {
            ...el,
            selected: !!selectedProjects.find(selectedId => selectedId === el.id)
        };
    });
};
