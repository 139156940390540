"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMetadata = void 0;
const helpers_1 = require("core/helpers");
const getMetadata = async () => {
    try {
        const response = await fetch(process.env.BASE_URL + '/metadata.json?var=' + (0, helpers_1.getHash)());
        return await response.json();
    }
    catch (error) {
        return {
            revision: null,
            environment: null,
            updatedAt: null
        };
    }
};
exports.getMetadata = getMetadata;
