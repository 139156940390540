"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useDistrictSidebar = () => {
    const [openedDistrictSidebar, setOpenedDistrictSideBar] = (0, react_1.useState)(false);
    const [name, setName] = (0, react_1.useState)('');
    const [workPlaces, setWorkPlaces] = (0, react_1.useState)([]);
    const [index, setIndex] = (0, react_1.useState)(null);
    const setDistrictSideBar = (isOpen) => {
        setOpenedDistrictSideBar(() => {
            if (isOpen)
                window.addEventListener('click', closeDistrictSideBar, true);
            return isOpen;
        });
    };
    const closeDistrictSideBar = (event) => {
        const isDistrict = document.querySelector('#district-menu')?.contains(event.target);
        if (isDistrict)
            return;
        window.removeEventListener('click', closeDistrictSideBar, true);
        setOpenedDistrictSideBar(false);
    };
    return {
        setDistrictSideBar,
        openedDistrictSidebar,
        setName,
        name,
        setWorkPlaces,
        workPlaces,
        index,
        setIndex
    };
};
exports.default = useDistrictSidebar;
