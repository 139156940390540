"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvoicesReducer = exports.useInvoicesReducer = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
const initialFilterForm = {
    keyword: '',
    createdBy: null,
    company: null
};
const initialInvoices = {
    data: [],
    status: null,
    errors: null,
    filterForm: initialFilterForm,
    meta: store_1.initialMeta,
    isFetching: true,
    isDeleting: false,
    isFetched: false
};
const useInvoicesReducer = () => {
    return (0, react_1.useReducer)(exports.InvoicesReducer, initialInvoices);
};
exports.useInvoicesReducer = useInvoicesReducer;
const InvoicesReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_INVOICES_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_INVOICES_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                status: action.payload.status,
                errors: action.payload.errors,
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'SEND_DELETE_REQUEST':
            return {
                ...state,
                isDeleting: true
            };
        case 'DONE_DELETE_REQUEST':
            return {
                ...state,
                isDeleting: false
            };
        case 'INITIALIZE_FILTER_FORM':
            return {
                ...state,
                filterForm: action.payload,
                isFetched: true
            };
        default:
            return state;
    }
};
exports.InvoicesReducer = InvoicesReducer;
