"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseLocation = exports.parseLocations = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseLocations = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const locations = response.content;
    try {
        return {
            data: locations.data.map(el => {
                return {
                    id: el.id,
                    nameKa: el.attributes.nameKa,
                    nameEn: el.attributes.nameEn,
                    district: el.relationships.district.data.attributes.nameEn,
                    region: el.relationships.district.data.relationships.region.data.attributes.nameEn,
                    country: el.relationships.district.data.relationships.region.data.relationships.country.data.attributes.nameEn
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(locations.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseLocations = parseLocations;
const parseLocation = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const location = response.content;
    try {
        return {
            data: {
                id: location.data.id,
                nameKa: location.data.attributes.nameKa,
                nameEn: location.data.attributes.nameEn,
                district: {
                    id: location.data.relationships.district.data.id,
                    label: location.data.relationships.district.data.attributes.nameEn
                }
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseLocation = parseLocation;
