"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmployeeAssignmentFileTypes = exports.EmployeeEducationType = void 0;
var EmployeeEducationType;
(function (EmployeeEducationType) {
    EmployeeEducationType[EmployeeEducationType["HIGH"] = 1] = "HIGH";
    EmployeeEducationType[EmployeeEducationType["PROFESSIONAL"] = 2] = "PROFESSIONAL";
    EmployeeEducationType[EmployeeEducationType["SECONDARY"] = 3] = "SECONDARY";
})(EmployeeEducationType || (exports.EmployeeEducationType = EmployeeEducationType = {}));
var EmployeeAssignmentFileTypes;
(function (EmployeeAssignmentFileTypes) {
    EmployeeAssignmentFileTypes[EmployeeAssignmentFileTypes["CONTRACT"] = 1] = "CONTRACT";
    EmployeeAssignmentFileTypes[EmployeeAssignmentFileTypes["ORDER"] = 2] = "ORDER";
    EmployeeAssignmentFileTypes[EmployeeAssignmentFileTypes["CONTRACT_ORDER_ORDER"] = 3] = "CONTRACT_ORDER_ORDER";
    EmployeeAssignmentFileTypes[EmployeeAssignmentFileTypes["CONTRACT_EXT"] = 4] = "CONTRACT_EXT";
    EmployeeAssignmentFileTypes[EmployeeAssignmentFileTypes["CONTRACT_ORDER_CONTRACT"] = 5] = "CONTRACT_ORDER_CONTRACT";
    EmployeeAssignmentFileTypes[EmployeeAssignmentFileTypes["BASIC_FUNCTION_DUTIES"] = 6] = "BASIC_FUNCTION_DUTIES";
})(EmployeeAssignmentFileTypes || (exports.EmployeeAssignmentFileTypes = EmployeeAssignmentFileTypes = {}));
