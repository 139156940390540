"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const types_1 = require("api/documents/purchases/submissions/types");
const submission_1 = require("../store/submission");
const index_1 = require("api/index");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const useSubmission = (props) => {
    const [state, dispatch] = (0, submission_1.useSubmissionReducer)();
    const [showSweetAlert, setShowSweetAlert] = (0, react_1.useState)(false);
    const [showSubmissionDateMessage, setShowSubmissionDateMessage] = (0, react_1.useState)(false);
    const [submissionDateMessage, setSubmissionDateMessage] = (0, react_1.useState)('');
    const [showExpirationDateHistory, setShowExpirationDateHistory] = (0, react_1.useState)(false);
    const [showDeleteFileAlert, setShowDeleteFileAlert] = (0, react_1.useState)(null);
    const [deletingFiles, setDeletingFiles] = (0, react_1.useState)([]);
    const [showSendButton, setShowSendButton] = (0, react_1.useState)(false);
    const [showConfirmAlert, setShowConfirmAlert] = (0, react_1.useState)(false);
    const [showDeclineAlert, setShowDeclineAlert] = (0, react_1.useState)(false);
    const [showStatusToCorrectionAlert, setShowStatusToCorrectionAlert] = (0, react_1.useState)(false);
    const changeStatus = async (id) => {
        dispatch({ type: 'SEND_SUBMISSION_CHANGE_STATUS' });
        const submission = await index_1.SubmissionsApi.changeStatus(id, { status: types_1.SubmissionsStatuses.CHECKING_PROCESS });
        dispatch({ type: 'DONE_SUBMISSION_CHANGE_STATUS', payload: submission });
        return submission;
    };
    const getSubmission = async (id) => {
        dispatch({ type: 'SEND_SUBMISSION_REQUEST' });
        const submission = await index_1.SubmissionsApi.getSubmission(id);
        dispatch({ type: 'DONE_SUBMISSION_REQUEST', payload: submission });
    };
    const updateSubmission = async (id, values) => {
        const params = (0, helpers_1.transformSubmissionParams)(values);
        dispatch({ type: 'SEND_SUBMISSION_UPDATE' });
        const submission = await index_1.SubmissionsApi.updateSubmission(id, params);
        dispatch({ type: 'DONE_SUBMISSION_UPDATE', payload: submission });
        return submission;
    };
    const changeExpirationDate = async (id, values) => {
        const params = (0, helpers_1.transformChangeExpirationDateParams)(values);
        dispatch({ type: 'SEND_CHANGE_EXPIRATION_DATE_REQUEST' });
        const submission = await index_1.SubmissionsApi.changeExpirationDate(id, params);
        dispatch({ type: 'DONE_CHANGE_EXPIRATION_DATE_REQUEST', payload: submission });
        return submission;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    const deleteSubmissionFile = async (id) => {
        setDeletingFiles(prev => [...prev, id]);
        const submissionFileResponse = await index_1.SubmissionsApi.deleteSubmissionFile(props.id, { fileUids: [id] });
        setDeletingFiles(prev => prev.filter(el => el !== id));
        return submissionFileResponse;
    };
    const confirmExpirationDate = async (id) => {
        dispatch({ type: 'SEND_CONFIRM_EXPIRATION_DATE_REQUEST' });
        const confirm = await index_1.SubmissionsApi.confirmExpirationDate(id);
        dispatch({ type: 'DONE_CONFIRM_EXPIRATION_DATE_REQUEST', payload: confirm });
        return confirm;
    };
    const declineExpirationDate = async (id, declineComment) => {
        dispatch({ type: 'SEND_DECLINE_EXPIRATION_DATE_REQUEST' });
        const decline = await index_1.SubmissionsApi.declineExpirationDate(id, { declineComment });
        dispatch({ type: 'DONE_DECLINE_EXPIRATION_DATE_REQUEST', payload: decline });
        return decline;
    };
    const sendStatusToCorrection = async (submissionId) => {
        dispatch({ type: 'SEND_STATUS_TO_CORRECTION_REQUEST' });
        const submission = await index_1.SubmissionsApi.sendStatusToCorrection(submissionId);
        dispatch({ type: 'DONE_STATUS_TO_CORRECTION_REQUEST', payload: submission });
        return submission;
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getSubmission(props.id).then(); }, [props.id]);
    return {
        state,
        showSubmissionDateMessage,
        setShowSubmissionDateMessage,
        submissionDateMessage,
        setSubmissionDateMessage,
        showExpirationDateHistory,
        setShowExpirationDateHistory,
        updateSubmission,
        changeExpirationDate,
        setShowSweetAlert,
        showSweetAlert,
        getSubmission,
        changeStatus,
        closeErrorMessage,
        setShowDeleteFileAlert,
        showDeleteFileAlert,
        deletingFiles,
        deleteSubmissionFile,
        setShowSendButton,
        showSendButton,
        setShowConfirmAlert,
        showConfirmAlert,
        confirmExpirationDate,
        setShowDeclineAlert,
        showDeclineAlert,
        declineExpirationDate,
        showStatusToCorrectionAlert,
        setShowStatusToCorrectionAlert,
        sendStatusToCorrection
    };
};
exports.default = useSubmission;
