"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useConditionalSubmissionItems = void 0;
const conditionalSubmissionItems_1 = require("../store/conditionalSubmissionItems");
const index_1 = require("api/index");
const react_1 = require("react");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const helpers_1 = require("core/helpers");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helpers_2 = require("../helpers");
const queryString_1 = require("core/helpers/queryString");
const react_router_dom_1 = require("react-router-dom");
const useConditionalSubmissionItems = (props) => {
    const [state, dispatch] = (0, conditionalSubmissionItems_1.useConditionalSubmissionItemsReducer)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [keyword, setKeyword] = (0, react_1.useState)('');
    const [isSticky, setSticky] = (0, react_1.useState)(false);
    const [width, setWidth] = (0, react_1.useState)(undefined);
    const ref = (0, react_1.useRef)(null);
    const [elRefs, setElRefs] = (0, react_1.useState)([]);
    const [selectedItems, setSelectedItems] = (0, react_1.useState)([]);
    const [cascadedItems, setCascadedItems] = (0, react_1.useState)([]);
    const [deletingItem, setDeletingItem] = (0, react_1.useState)({ showAlert: false, ids: null });
    const [showCreateSubmissionAlert, setShowCreateSubmissionAlert] = (0, react_1.useState)(false);
    const getConditionalSubmissionItems = async (params, sorted = true) => {
        // const itemsParams = transformItemsParams({ ...params, sort: '+sort' })
        const itemsParams = sorted ? { ...(0, helpers_2.transformItemsParams)(params), sort: '+sort' } : (0, helpers_2.transformItemsParams)(params);
        const conditionalParams = (0, helpers_2.transformFilterParamsToUrl)((0, helpers_2.transformUrlToFilterParams)(location.search));
        dispatch({ type: 'SEND_CONDITIONAL_SUBMISSION_ITEMS_REQUEST' });
        const conditionalSubmissionItems = await index_1.ConditionalSubmissionsApi.getConditionalSubmissionItems(props.id, itemsParams);
        const newConditionalSubmissionItems = {
            ...conditionalSubmissionItems,
            data: conditionalSubmissionItems.data?.map(el => {
                return {
                    ...el,
                    checked: selectedItems.includes(el.id)
                };
            }) || null
        };
        if (conditionalSubmissionItems.data && conditionalSubmissionItems.data.length > 0)
            props.setShowSendButton(true);
        navigate((0, queryString_1.stringify)({ ...params, ...conditionalParams }), { replace: true });
        dispatch({ type: 'DONE_CONDITIONAL_SUBMISSION_ITEMS_REQUEST', payload: newConditionalSubmissionItems });
    };
    const deleteSubmissionItems = async (ids) => {
        setDeletingItem({ ...deletingItem, showAlert: false });
        dispatch({ type: 'SEND_CONDITIONAL_SUBMISSION_ITEM_DELETE_REQUEST' });
        const response = await index_1.ConditionalSubmissionsApi.deleteConditionalSubmissionItems(props.id, { ids });
        const itemPage = (0, helpers_1.calculateCurrentPage)(state.meta.pagination, ids.length);
        if (itemPage && response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS) {
            const params = (0, queryString_1.parse)(location.search);
            getConditionalSubmissionItems({ ...params, itemPage }).then();
        }
        else {
            dispatch({ type: 'DONE_CONDITIONAL_SUBMISSION_ITEM_DELETE_REQUEST', payload: response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS ? ids : [] });
        }
        return { errors: response.errors, status: response.status };
    };
    const updateConditionalSubmissionItemsList = async (id, values) => {
        const params = (0, helpers_2.transformConditionalSubmissionItemsListParams)(values, state.data.length === 0);
        const newItemsLength = values.filter(item => item.id === null).length;
        dispatch({ type: 'SEND_ITEMS_LIST_UPDATE_REQUEST' });
        const conditionalSubmissionItems = await index_1.ConditionalSubmissionsApi.updateOrCreateConditionalSubmissionItem(id, params);
        if (state.data.some(item => item.id === null) && conditionalSubmissionItems.data !== null)
            setCascadedItems(conditionalSubmissionItems.data.map(item => item.id));
        dispatch({ type: 'DONE_ITEMS_LIST_UPDATE_REQUEST', payload: { conditionalSubmissionItems, newItemsLength } });
        if (conditionalSubmissionItems.data !== null)
            props.setShowSendButton(true);
        return conditionalSubmissionItems;
    };
    const selectAllItem = (checked, itemIds) => {
        if (checked) {
            // ეს ლოგიკა წერია რადგან პეგინეითიდ დროს არ უნდა დამეკარგოს პინა გვერძე მონიშნული საგნები
            const filteredItems = itemIds.filter(el => !selectedItems.includes(el));
            setSelectedItems(prevState => ([...prevState, ...filteredItems]));
        }
        else {
            setSelectedItems(prevState => prevState.filter(el => !itemIds.includes(el)));
        }
    };
    const handleScroll = () => {
        if (ref.current) {
            setWidth(ref.current.clientWidth);
            setSticky(ref.current.getBoundingClientRect().top <= 122);
        }
    };
    const handleCascadeItem = (id) => {
        cascadedItems.includes(id)
            ? setCascadedItems(cascadedItems.filter(el => el !== id))
            : setCascadedItems([...cascadedItems, id]);
    };
    const selectPerPage = (itemPerPage) => {
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'CHANGE_PER_PAGE', payload: itemPerPage });
        getConditionalSubmissionItems({ ...params, itemPerPage, itemPage: undefined }).then();
    };
    const navigatePagination = async (page) => {
        const itemPage = Math.ceil(state.meta.pagination.total / state.meta.pagination.perPage) >= page ? page : page - 1;
        const params = (0, queryString_1.parse)(location.search);
        await getConditionalSubmissionItems({ ...params, itemPage });
        return;
    };
    const debouncedSearch = (value) => {
        setKeyword(value);
        const searchParams = (0, queryString_1.parse)(location.search);
        const params = value
            ? { perPage: searchParams.perPage, filters: { keyword: value } }
            : { page: searchParams.page };
        (0, debouncedMethods_1.debouncedOnFunc)(params, getConditionalSubmissionItems);
    };
    const createSubmission = async () => {
        const params = (0, helpers_2.transformCreateSubmissionParams)(selectedItems);
        dispatch({ type: 'SEND_CREATE_SUBMISSION_REQUEST' });
        const submission = await index_1.ConditionalSubmissionsApi.createSubmission(params);
        dispatch({ type: 'DONE_CREATE_SUBMISSION_REQUEST', payload: { errors: submission.errors } });
        if (submission.data !== null) {
            navigate(`/documents/purchases/submissions/${submission.data.submissionId}/edit`, { replace: true });
        }
        return submission;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', () => handleScroll);
        };
    }, []);
    (0, react_1.useEffect)(() => {
        const params = (0, helpers_2.getConditionalSubmissionItemsParamsFromUrl)(location.search);
        const paginatedStorage = JSON.parse(localStorage.getItem('paginated') || '""');
        const storagePerPage = paginatedStorage?.conditionalSubmissionItems?.perPage;
        params.itemPerPage = params.itemPerPage || Number(storagePerPage) || 50;
        dispatch({ type: 'CHANGE_PER_PAGE', payload: params.itemPerPage });
        setKeyword(params.itemsKeyword || '');
        if (props.id)
            getConditionalSubmissionItems(params).then(() => props.setFetchStep(4));
    }, [props.id]);
    return {
        deleteSubmissionItems,
        state,
        elRefs,
        setElRefs,
        handleCascadeItem,
        cascadedItems,
        setCascadedItems,
        setDeletingItem,
        deletingItem,
        selectPerPage,
        navigatePagination,
        updateConditionalSubmissionItemsList,
        selectedItems,
        selectAllItem,
        isSticky,
        width,
        ref,
        keyword,
        debouncedSearch,
        setShowCreateSubmissionAlert,
        showCreateSubmissionAlert,
        createSubmission,
        closeErrorMessage
    };
};
exports.useConditionalSubmissionItems = useConditionalSubmissionItems;
exports.default = exports.useConditionalSubmissionItems;
