"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseHumanResourceSubmissionVerifyStatusName = exports.parseHRAssignSubmissionVerifyProjects = exports.parseHumanResourceSubmissionVerifyItems = exports.parseHRAssignSubmissionVerifyFormItems = exports.parseAssignSubmissionItemsForHrVerify = exports.parseHumanResourceSubmissionVerify = exports.parseHumanResourceSubmissionVerifies = void 0;
const types_1 = require("api/documents/human-resources/assign-submissions/types");
const types_2 = require("api/documents/human-resources/assign-submission-verifies/types");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const employee_1 = require("api/parsers/employee");
const helper_1 = require("api/helper");
const parsers_1 = require("api/files/parsers");
const parsers_2 = require("api/parsers/parsers");
const parsers_3 = require("api/documents/human-resources/assign-submissions/parsers");
const parseHumanResourceSubmissionVerifies = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const humanResourceSubmissions = response.content;
    try {
        return {
            data: humanResourceSubmissions.data.map(el => {
                const structuralUnit = el.relationships.requesterAccount.data.relationships.contract.data.relationships.structuralUnit;
                const project = el.relationships.requesterAccount.data.relationships.contract.data.relationships.project;
                return {
                    id: el.id,
                    type: el.type,
                    number: el.attributes.number,
                    status: el.attributes.status,
                    visualStatus: (0, exports.parseHumanResourceSubmissionVerifyStatusName)(el.attributes.status),
                    requesterFullName: el.relationships.requesterAccount.data.relationships.user.data.attributes.fullname,
                    requesterPosition: structuralUnit ? structuralUnit.data.attributes.fullName : 'პროექტის "' + project?.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი',
                    addressees: el.relationships.addressees.data
                        .filter(units => !!units.relationships.account.data.relationships.contract.data.relationships.structuralUnit)
                        .map(item => {
                        const unitsStructuralUnit = item.relationships.account.data.relationships.contract.data.relationships.structuralUnit;
                        return {
                            name: item.relationships.account.data.relationships.user.data.attributes.fullname,
                            position: unitsStructuralUnit.data.attributes.fullName
                        };
                    }),
                    termDate: (0, parsers_2.nullableTransformDate)(el.attributes.termDate),
                    updatedDateTimeAt: el.attributes.updatedDateTimeAt,
                    createdAt: (0, parsers_2.transformDate)(el.attributes.createdAt),
                    createdDateTimeAt: (0, parsers_2.transformDateTime)(el.attributes.createdAt),
                    updatedAt: (0, parsers_2.transformDate)(el.attributes.updatedAt)
                };
            }),
            errors: null,
            status: response.status,
            meta: (0, parsers_2.transformPagination)(humanResourceSubmissions.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseHumanResourceSubmissionVerifies = parseHumanResourceSubmissionVerifies;
const parseHumanResourceSubmissionVerify = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const humanResourceSubmissionVerify = response.content.data;
    try {
        const project = humanResourceSubmissionVerify.relationships.requesterAccount.data.relationships.contract.data.relationships.project;
        const structuralUnit = humanResourceSubmissionVerify.relationships.requesterAccount.data.relationships.contract.data.relationships.structuralUnit;
        const status = humanResourceSubmissionVerify.attributes.status;
        const creatorStructuralUnit = humanResourceSubmissionVerify.relationships.creator.data.relationships.contract.data.relationships.structuralUnit;
        const creatorProject = humanResourceSubmissionVerify.relationships.creator.data.relationships.contract.data.relationships.project;
        return {
            data: {
                id: humanResourceSubmissionVerify.id,
                declineComment: humanResourceSubmissionVerify.attributes.declineComment,
                number: humanResourceSubmissionVerify.attributes.number,
                status: humanResourceSubmissionVerify.attributes.status,
                isVerifySend: humanResourceSubmissionVerify.attributes.isVerifySend,
                visualStatus: (0, exports.parseHumanResourceSubmissionVerifyStatusName)(humanResourceSubmissionVerify.attributes.status),
                disabledStatus: status !== types_2.HRAssignSubmissionVerifyStatuses.DRAFT,
                usedItemsCount: humanResourceSubmissionVerify.relationships.submission.data.attributes.usedItemsCount,
                itemsCount: humanResourceSubmissionVerify.relationships.submission.data.attributes.itemsCount,
                addressees: humanResourceSubmissionVerify.relationships.addressees.data.map(addressee => (0, parsers_3.parseAddressee)(addressee)),
                submissionAuthor: {
                    id: humanResourceSubmissionVerify.relationships.requesterAccount.data.relationships.user.data.id,
                    label: humanResourceSubmissionVerify.relationships.requesterAccount.data.relationships.user.data.attributes.fullname +
                        `${humanResourceSubmissionVerify.relationships.requesterAccount.data.relationships.user.data.attributes.email
                            ? ` (${humanResourceSubmissionVerify.relationships.requesterAccount.data.relationships.user.data.attributes.email})` : ''}`,
                    structuralUnits: humanResourceSubmissionVerify.relationships.requesterAccount.data.relationships.user.data.relationships.accounts.data.map(unit => {
                        const unitStructuralUnit = unit.relationships.contract.data.relationships.structuralUnit;
                        const unitProject = unit.relationships.contract.data.relationships.project;
                        return {
                            id: unitStructuralUnit ? unitStructuralUnit.data.id : unitProject.data.id,
                            accountId: unit.id,
                            label: structuralUnit ? structuralUnit.data.attributes.fullName : 'პროექტის "' + project?.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი'
                        };
                    })
                },
                authorStructuralUnit: {
                    id: structuralUnit ? structuralUnit.data.id : project.data.id,
                    accountId: humanResourceSubmissionVerify.relationships.requesterAccount.data.id,
                    label: structuralUnit ? structuralUnit.data.attributes.fullName : 'პროექტის "' + project?.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი'
                },
                files: humanResourceSubmissionVerify.relationships.mediaFiles.data.map(file => {
                    return {
                        id: file.id,
                        name: file.attributes.name,
                        status: file.attributes.status,
                        originalName: file.attributes.originalName,
                        link: file.attributes.downloadUrl,
                        extension: file.attributes.extension
                    };
                }),
                purpose: humanResourceSubmissionVerify.attributes.purpose,
                contactPerson: {
                    id: humanResourceSubmissionVerify.relationships.contactPerson.data.id,
                    label: humanResourceSubmissionVerify.relationships.contactPerson.data.attributes.fullname +
                        `${humanResourceSubmissionVerify.relationships.contactPerson.data.attributes.email
                            ? ` (${humanResourceSubmissionVerify.relationships.contactPerson.data.attributes.email})` : ''}`,
                    email: humanResourceSubmissionVerify.relationships.contactPerson.data.attributes.email,
                    phone: humanResourceSubmissionVerify.relationships.contactPerson.data.attributes.workPhoneNumber
                },
                contactPersonEmail: humanResourceSubmissionVerify.attributes.contactPersonEmail,
                phoneNumber: humanResourceSubmissionVerify.attributes.contactPersonPhoneNumber,
                creator: {
                    id: humanResourceSubmissionVerify.relationships.creator.data.relationships.user.data.id,
                    label: humanResourceSubmissionVerify.relationships.creator.data.relationships.user.data.attributes.fullname,
                    accountId: humanResourceSubmissionVerify.relationships.creator.data.id,
                    structuralUnitName: creatorStructuralUnit ? creatorStructuralUnit.data.attributes.fullName : 'პროექტის "' + creatorProject?.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი'
                },
                createdAt: (0, parsers_2.transformDate)(humanResourceSubmissionVerify.attributes.createdAt),
                edocNumber: humanResourceSubmissionVerify.attributes.edocNumber,
                dateOfConclusion: (0, parsers_2.transformDate)(humanResourceSubmissionVerify.attributes.dateOfConclusion),
                termDate: (0, parsers_2.nullableTransformDate)(humanResourceSubmissionVerify.attributes.termDate),
                requestRegistrationDate: (0, parsers_2.nullableTransformDate)(humanResourceSubmissionVerify.attributes.requestRegistrationDate),
                assignSubmissionNumber: humanResourceSubmissionVerify.relationships.submission.data.attributes.number,
                assignSubmissionId: humanResourceSubmissionVerify.relationships.submission.data.id,
                remainingItemCount: humanResourceSubmissionVerify.relationships.submission.data.attributes.itemsCount - humanResourceSubmissionVerify.relationships.submission.data.attributes.usedItemsCount
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseHumanResourceSubmissionVerify = parseHumanResourceSubmissionVerify;
const parseAssignSubmissionItemsForHrVerify = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const assignSubmissionItem = response.content;
    try {
        return {
            data: assignSubmissionItem.data.map(el => {
                const other = (0, parsers_3.parseFileType)(el.relationships.mediaFiles.data, types_1.AssignSubmissionItemFileTypes.OTHER);
                const cv = (0, parsers_3.parseFileType)(el.relationships.mediaFiles.data, types_1.AssignSubmissionItemFileTypes.CV);
                const diploma = (0, parsers_3.parseFileType)(el.relationships.mediaFiles.data, types_1.AssignSubmissionItemFileTypes.DIPLOMA);
                const passport = (0, parsers_3.parseFileType)(el.relationships.mediaFiles.data, types_1.AssignSubmissionItemFileTypes.PASSPORT);
                const bankingRequisites = (0, parsers_3.parseFileType)(el.relationships.mediaFiles.data, types_1.AssignSubmissionItemFileTypes.BANKING_REQUISITES);
                const taxCertificate = (0, parsers_3.parseFileType)(el.relationships.mediaFiles.data, types_1.AssignSubmissionItemFileTypes.TAX_CERTIFICATE);
                const conviction = (0, parsers_3.parseFileType)(el.relationships.mediaFiles.data, types_1.AssignSubmissionItemFileTypes.CONVICTION);
                return {
                    assignSubmissionItemId: el.id,
                    citizen: el.attributes.foreignCitizen ? types_1.Citizen.FOREIGN : types_1.Citizen.GEORGIAN,
                    personalNumber: el.attributes.personalNumber,
                    passportNumber: el.attributes.passportNumber,
                    legalAddress: el.attributes.legalAddress,
                    physicalAddress: el.attributes.actualAddress,
                    isDifferentAddress: el.attributes.legalAddress !== el.attributes.actualAddress,
                    firstname: el.attributes.firstname,
                    lastname: el.attributes.lastname,
                    phoneNumber: el.attributes.phoneNumber,
                    email: el.attributes.email,
                    hasNoEmail: el.attributes.email === null,
                    isEdocUser: el.attributes.isEdocUser,
                    openCorporateEmail: el.attributes.openCorporateEmail,
                    openBankAccount: el.attributes.openBankAccount,
                    createdAt: (0, parsers_2.transformDate)(el.attributes.createdAt),
                    createdDateTimeAt: (0, parsers_2.transformDateTime)(el.attributes.createdAt),
                    updatedAt: (0, parsers_2.transformDate)(el.attributes.updatedAt),
                    checked: false,
                    positionInfos: el.relationships.positionInfo.data.map(position => (0, parsers_3.parseEmployeePosition)(position, el.attributes.status)),
                    files: {
                        cv: cv.length > 0 ? cv : [{ ...parsers_1.initialFile, type: types_1.AssignSubmissionItemFileTypes.CV }],
                        diploma: diploma.length > 0 ? diploma : [{ ...parsers_1.initialFile, type: types_1.AssignSubmissionItemFileTypes.DIPLOMA }],
                        passport: passport.length > 0 ? passport : [],
                        bankingRequisites: bankingRequisites.length > 0 ? bankingRequisites : [{ ...parsers_1.initialFile, type: types_1.AssignSubmissionItemFileTypes.BANKING_REQUISITES }],
                        taxCertificate: taxCertificate.length ? taxCertificate : [{ ...parsers_1.initialFile, type: types_1.AssignSubmissionItemFileTypes.TAX_CERTIFICATE }],
                        conviction: conviction.length ? conviction : [{ ...parsers_1.initialFile, type: types_1.AssignSubmissionItemFileTypes.CONVICTION }],
                        other: other.length > 0 ? other : [{ ...parsers_1.initialFile, type: types_1.AssignSubmissionItemFileTypes.OTHER }]
                    }
                };
            }),
            meta: assignSubmissionItem.meta ? (0, parsers_2.transformPagination)(assignSubmissionItem.meta) : undefined,
            errors: null,
            status: response.status
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseAssignSubmissionItemsForHrVerify = parseAssignSubmissionItemsForHrVerify;
const parseHRAssignSubmissionVerifyFormItems = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const items = response.content;
    try {
        return {
            data: parseHRAssignSubmissionVerifyFormItemData(items.data),
            errors: null,
            status: response.status,
            meta: items.meta ? (0, parsers_2.transformPagination)(items.meta) : undefined
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseHRAssignSubmissionVerifyFormItems = parseHRAssignSubmissionVerifyFormItems;
const parseHumanResourceSubmissionVerifyItems = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors };
    const items = response.content;
    try {
        return {
            data: items.data.map(el => {
                return {
                    id: el.id,
                    fullname: `${el.attributes.firstname} ${el.attributes.lastname}`,
                    structuralUnits: el.relationships.positionInfo.data.map(item => item.relationships.structuralUnit.data.attributes.name) || null,
                    positions: el.relationships.positionInfo.data.map(item => item.attributes.position),
                    createdAt: (0, parsers_2.transformDate)(el.attributes.createdAt),
                    updatedAt: (0, parsers_2.transformDate)(el.attributes.updatedAt)
                };
            }),
            errors: null,
            meta: (0, parsers_2.transformPagination)(items.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseHumanResourceSubmissionVerifyItems = parseHumanResourceSubmissionVerifyItems;
const parseHRAssignSubmissionVerifyFormItemData = (items) => {
    return items.map(el => {
        const submissionItem = el.relationships.submissionItem;
        const other = (0, parsers_3.parseFileType)(el.relationships.mediaFiles.data, types_1.AssignSubmissionItemFileTypes.OTHER);
        const cv = (0, parsers_3.parseFileType)(el.relationships.mediaFiles.data, types_1.AssignSubmissionItemFileTypes.CV);
        const diploma = (0, parsers_3.parseFileType)(el.relationships.mediaFiles.data, types_1.AssignSubmissionItemFileTypes.DIPLOMA);
        const passport = (0, parsers_3.parseFileType)(el.relationships.mediaFiles.data, types_1.AssignSubmissionItemFileTypes.PASSPORT);
        const bankingRequisites = (0, parsers_3.parseFileType)(el.relationships.mediaFiles.data, types_1.AssignSubmissionItemFileTypes.BANKING_REQUISITES);
        const taxCertificate = (0, parsers_3.parseFileType)(el.relationships.mediaFiles.data, types_1.AssignSubmissionItemFileTypes.TAX_CERTIFICATE);
        const conviction = (0, parsers_3.parseFileType)(el.relationships.mediaFiles.data, types_1.AssignSubmissionItemFileTypes.CONVICTION);
        return {
            id: el.id,
            documentId: el.attributes.documentId,
            status: el.attributes.status,
            citizen: el.attributes.foreignCitizen ? types_1.Citizen.FOREIGN : types_1.Citizen.GEORGIAN,
            personalNumber: el.attributes.personalNumber,
            passportNumber: el.attributes.passportNumber,
            legalAddress: el.attributes.legalAddress,
            physicalAddress: el.attributes.actualAddress,
            isDifferentAddress: el.attributes.legalAddress !== el.attributes.actualAddress,
            firstname: el.attributes.firstname,
            lastname: el.attributes.lastname,
            phoneNumber: el.attributes.phoneNumber,
            email: el.attributes.email,
            hasNoEmail: el.attributes.email === null,
            isEdocUser: el.attributes.isEdocUser,
            openCorporateEmail: el.attributes.openCorporateEmail,
            openBankAccount: el.attributes.openBankAccount,
            createdAt: (0, parsers_2.transformDate)(el.attributes.createdAt),
            createdDateTimeAt: (0, parsers_2.transformDateTime)(el.attributes.createdAt),
            updatedAt: (0, parsers_2.transformDate)(el.attributes.updatedAt),
            useOfTaxRelief: el.attributes.useOfTaxRelief,
            taxReliefAmount: el.attributes.taxReliefAmount,
            taxReliefId: el.relationships.taxRelief && el.relationships.taxRelief.data.id,
            taxReliefBenefit: el.attributes.taxReliefBenefit ? apiGlobalTypes_1.YesNoStatus.YES : apiGlobalTypes_1.YesNoStatus.NO,
            country: el.relationships.country ? {
                id: el.relationships.country.data.id,
                label: el.relationships.country.data.attributes.nameKa || el.relationships.country.data.attributes.nameEn
            } : null,
            taxOptions: [],
            resident: el.attributes.resident,
            isDualCitizenship: el.attributes.dualCitizen,
            workEmail: el.attributes.businessEmail,
            residenceCertificateNumber: el.attributes.certificateOfResidenceNumber,
            residenceCertificateDate: el.attributes.nonResidentFrom && el.attributes.nonResidentTo
                ? `${(0, parsers_2.transformDate)(el.attributes.nonResidentFrom)} — ${(0, parsers_2.transformDate)(el.attributes.nonResidentTo)}`
                : null,
            idCardValidityDate: el.attributes.validityOfIdCardFrom && el.attributes.validityOfIdCardTo
                ? `${(0, parsers_2.transformDate)(el.attributes.validityOfIdCardFrom)} — ${(0, parsers_2.transformDate)(el.attributes.validityOfIdCardTo)}`
                : null,
            passportValidityDate: el.attributes.validityOfPassportFrom && el.attributes.validityOfPassportTo
                ? `${(0, parsers_2.transformDate)(el.attributes.validityOfPassportFrom)} — ${(0, parsers_2.transformDate)(el.attributes.validityOfPassportTo)}`
                : null,
            residencyValidityDate: el.attributes.certificateOfResidenceFrom && el.attributes.certificateOfResidenceTo
                ? `${(0, parsers_2.transformDate)(el.attributes.certificateOfResidenceFrom)} — ${(0, parsers_2.transformDate)(el.attributes.certificateOfResidenceTo)}`
                : null,
            assignSubmissionItemId: el.relationships.submissionItem?.data.id || null,
            files: {
                cv: cv.length > 0 ? cv : [{ ...parsers_1.initialFile, type: types_1.AssignSubmissionItemFileTypes.CV }],
                diploma: diploma.length > 0 ? diploma : [{ ...parsers_1.initialFile, type: types_1.AssignSubmissionItemFileTypes.DIPLOMA }],
                passport: passport.length > 0 ? passport : [],
                bankingRequisites: bankingRequisites.length > 0 ? bankingRequisites : [{ ...parsers_1.initialFile, type: types_1.AssignSubmissionItemFileTypes.BANKING_REQUISITES }],
                taxCertificate: taxCertificate.length ? taxCertificate : [{ ...parsers_1.initialFile, type: types_1.AssignSubmissionItemFileTypes.TAX_CERTIFICATE }],
                conviction: conviction.length ? conviction : [{ ...parsers_1.initialFile, type: types_1.AssignSubmissionItemFileTypes.CONVICTION }],
                other: other.length > 0 ? other : [{ ...parsers_1.initialFile, type: types_1.AssignSubmissionItemFileTypes.OTHER }]
            },
            checked: false,
            assignSubmission: submissionItem && {
                id: submissionItem.data.relationships.document.data.id,
                number: submissionItem.data.relationships.document.data.attributes.number,
                status: submissionItem.data.relationships.document.data.attributes.status
            },
            positionInfos: el.relationships.positionInfo.data.map(position => parseVerifyEmployeePosition(position, el.attributes.status))
        };
    });
};
const parseVerifyEmployeePosition = (data, status) => {
    return {
        ...(0, parsers_3.parseEmployeePosition)(data, status),
        projectInfos: data.relationships.projects.data.map(projectInfo => parseVerifyProjectInfos(projectInfo)),
        assignType: employee_1.assignTypeOptions.find(positionType => Number(positionType.id) === data.attributes.assignType),
        employmentContractType: data.attributes.employmentContractType || null,
        contractContinuationType: data.attributes.contractContinuationType || null
    };
};
const parseVerifyProjectInfos = (projectInfo) => {
    return {
        ...(0, parsers_3.parseProjectInfos)(projectInfo),
        budgetingIsSent: projectInfo.relationships.actions.data.length !== 0 && projectInfo.attributes.budgetingStatus !== types_1.AssignSubmissionVerifyItemStatuses.NEEDS_NO_VERIFY,
        status: projectInfo.attributes.status,
        budgetingStatus: projectInfo.attributes.budgetingStatus,
        hrHeadStatus: projectInfo.attributes.hrHeadStatus,
        submissionProjectId: projectInfo.relationships.submissionProject ? projectInfo.relationships.submissionProject.data.id : undefined,
        creatorVisualStatus: (0, parsers_3.parseHumanResourceSubmissionItemStatusName)(projectInfo.attributes.status),
        HRHeadVisualStatus: projectInfo.attributes.hrHeadStatus !== null
            ? (0, parsers_3.parseHumanResourceSubmissionItemStatusName)(projectInfo.attributes.hrHeadStatus)
            : { name: 'პროექტი', color: 'secondary' },
        budgetingVisualStatus: projectInfo.attributes.budgetingStatus !== null
            ? (0, parsers_3.parseHumanResourceSubmissionItemStatusName)(projectInfo.attributes.budgetingStatus)
            : { name: 'პროექტი', color: 'secondary' },
        declineComment: projectInfo.attributes.declineComment,
        suspendComment: projectInfo.attributes.suspendComment,
        countComments: projectInfo.relationships.submissionProject
            ? projectInfo.relationships.submissionProject.data.attributes.countComments
            : 0,
        creatorStatusMessage: parseProjectStatusMessage(projectInfo, types_2.HrAssignStatusBy.CREATOR),
        budgetingStatusMessage: parseProjectStatusMessage(projectInfo, types_2.HrAssignStatusBy.BUDGETING),
        HRHeadStatusMessage: parseProjectStatusMessage(projectInfo, types_2.HrAssignStatusBy.HEAD_OF_HR)
    };
};
const parseProjectStatusMessage = (projectInfo, statusBy) => {
    const hrStatusChanger = 'ჰრ-ის თანამშრომელი: ' + (projectInfo.relationships.hrStatusChanger?.data.relationships.user.data.attributes.fullname || '');
    const budgetingStatusChanger = 'საფინანსო: ' + (projectInfo.relationships.budgetingStatusChanger?.data.relationships.user.data.attributes.fullname || '');
    const hrHeadStatusChanger = 'ჰრ-ის უფროსი: ' + (projectInfo.relationships.hrHeadStatusChanger?.data.relationships.user.data.attributes.fullname || '');
    switch (statusBy) {
        case types_2.HrAssignStatusBy.CREATOR:
            return hrStatusChanger + getStatusComment(projectInfo.attributes.suspendComment, projectInfo.attributes.declineComment, projectInfo.attributes.statusChangeDate, projectInfo.attributes.status);
        case types_2.HrAssignStatusBy.BUDGETING:
            return budgetingStatusChanger + getStatusComment(projectInfo.attributes.suspendComment, projectInfo.attributes.declineComment, projectInfo.attributes.budgetingStatusChangeDate, projectInfo.attributes.budgetingStatus);
        case types_2.HrAssignStatusBy.HEAD_OF_HR:
            return hrHeadStatusChanger + getStatusComment(projectInfo.attributes.suspendComment, projectInfo.attributes.declineComment, projectInfo.attributes.hrHeadStatusChangeDate, projectInfo.attributes.hrHeadStatus);
    }
};
const getStatusComment = (suspendComment, declineComment, date, status) => {
    switch (status) {
        case types_1.AssignSubmissionVerifyItemStatuses.DECLINED:
            return ' - ' + declineComment + ' - ' + (0, parsers_2.nullableTransformDate)(date);
        case types_1.AssignSubmissionVerifyItemStatuses.SUSPENDED:
            return ' - ' + suspendComment + ' - ' + (0, parsers_2.nullableTransformDate)(date);
        default:
            return date ? ' - ' + (0, parsers_2.transformDate)(date) : '';
    }
};
const parseHRAssignSubmissionVerifyProjects = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const projects = response.content.data;
    try {
        return {
            data: projects.map(el => parseVerifyProjectInfos(el)),
            errors: null,
            status: response.status
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseHRAssignSubmissionVerifyProjects = parseHRAssignSubmissionVerifyProjects;
const parseHumanResourceSubmissionVerifyStatusName = (status) => {
    switch (status) {
        case types_2.HRAssignSubmissionVerifyStatuses.DRAFT:
            return { name: 'პროექტი', color: 'secondary' };
        case types_2.HRAssignSubmissionVerifyStatuses.PARTIALLY_CONFIRMED:
            return { name: 'ნაწილობრივ დადასტურებული', color: 'warning' };
        case types_2.HRAssignSubmissionVerifyStatuses.CONFIRMED:
            return { name: 'დადასტურებული', color: 'success' };
        case types_2.HRAssignSubmissionVerifyStatuses.DECLINED:
            return { name: 'უარყოფილი', color: 'danger' };
        case types_2.HRAssignSubmissionVerifyStatuses.SUSPENDED:
            return { name: 'შეჩერებული', color: 'warning' };
    }
};
exports.parseHumanResourceSubmissionVerifyStatusName = parseHumanResourceSubmissionVerifyStatusName;
