"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseAttachedRoles = exports.parseRolePermissions = exports.parseRole = exports.parseRoles = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseRoles = (response) => {
    if (response.content == null)
        return { data: null, errors: response.errors, status: response.status };
    const roles = response.content;
    try {
        return {
            data: roles.data.map(el => {
                return {
                    id: el.id,
                    moduleType: el.type,
                    title: el.attributes.title,
                    name: el.attributes.name
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(roles.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseRoles = parseRoles;
const parseRole = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const role = response.content;
    try {
        return {
            data: {
                id: role.data.id,
                title: role.data.attributes.title,
                name: role.data.attributes.name
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseRole = parseRole;
const parseRolePermissions = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const rolePermissions = response.content;
    try {
        return {
            data: rolePermissions.data.map(el => el.id),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return { ...helper_1.errorObject, status: response.status };
    }
};
exports.parseRolePermissions = parseRolePermissions;
const parseAttachedRoles = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const attachRoles = response.content;
    try {
        return {
            data: attachRoles.data.map(el => el.id),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return { ...helper_1.errorObject, status: response.status };
    }
};
exports.parseAttachedRoles = parseAttachedRoles;
