"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSubmissionItemsReducer = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
const initialSubmissionsStore = {
    data: [],
    errors: null,
    meta: store_1.initialMeta,
    isFetching: true
};
const useSubmissionItemsReducer = () => {
    return (0, react_1.useReducer)(SubmissionItemsReducer, initialSubmissionsStore);
};
exports.useSubmissionItemsReducer = useSubmissionItemsReducer;
const SubmissionItemsReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_SUBMISSION_ITEMS_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_SUBMISSION_ITEMS_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                errors: action.payload.errors || null,
                isFetching: false,
                meta: action.payload.meta || store_1.initialMeta
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
