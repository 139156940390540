"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VacationFormValidation = void 0;
const validates_1 = require("core/helpers/validates");
const helper_1 = require("src/modules/common/helper");
const VacationFormValidation = (values) => {
    const formErrors = {
        user: (0, validates_1.required)(values.user),
        substituteUser: (0, validates_1.required)(values.substituteUser),
        comment: (0, validates_1.required)(values.comment),
        startDate: (0, validates_1.required)(values.startDate),
        endDate: (0, validates_1.required)(values.endDate) || validateEndDate(values.startDate, values.endDate),
        structuralUnit: (0, validates_1.required)(values.structuralUnit)
            || checkDateValidity(values.startDate, values.endDate, values.structuralUnit.deletedAt),
        substituteStructuralUnit: (0, validates_1.required)(values.substituteStructuralUnit)
            || checkDateValidity(values.startDate, values.endDate, (values.substituteStructuralUnit.deletedAt))
    };
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.VacationFormValidation = VacationFormValidation;
const checkDateValidity = (startDate, endDate, deletedAt) => {
    if (!startDate || !endDate)
        return;
    if (deletedAt
        && (new Date((0, helper_1.transformParamsDate)(startDate)) > new Date((0, helper_1.transformParamsDate)(deletedAt))
            || new Date((0, helper_1.transformParamsDate)(endDate)) > new Date((0, helper_1.transformParamsDate)(deletedAt)))) {
        return 'მითითებული თანამდებობიდან თანამშრომელი გათავისუფლებულია. გთხოვთ, დარწმუნდეთ, რომ დაწყების და დასრულების თარიღები არ აღემატება გათავისუფლების თარიღს';
    }
    else {
        return;
    }
};
const validateEndDate = (startDate, endDate) => {
    if (!startDate || !endDate)
        return;
    if (new Date((0, helper_1.transformParamsDate)(startDate)) > new Date((0, helper_1.transformParamsDate)(endDate)))
        return 'დაწყება არ უნდა აღემატებოდეს დასრულებას';
    return;
};
