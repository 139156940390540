"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendForVerify = exports.verifyAtPaymentStage = exports.confirmItems = exports.suspendItems = exports.declineItems = exports.declineHRSubmissionVerify = exports.updateHRAssignSubmissionVerifyItems = exports.getHrAssignSubmissionVerifyItems = exports.getHRAssignSubmissionVerifyFormItems = exports.getAssignSubmissionItemsForHrVerify = exports.getHrAssignSubmissionVerify = exports.getHumanResourceSubmissionVerifies = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/documents/human-resources/assign-submission-verifies/parsers");
const getHumanResourceSubmissionVerifies = async (params) => {
    const response = await Api.get('documents/human-resources/assign/submission-verify', params);
    return (0, parsers_1.parseHumanResourceSubmissionVerifies)(response);
};
exports.getHumanResourceSubmissionVerifies = getHumanResourceSubmissionVerifies;
const getHrAssignSubmissionVerify = async (id) => {
    const response = await Api.get('documents/human-resources/assign/submission-verify/' + id);
    return (0, parsers_1.parseHumanResourceSubmissionVerify)(response);
};
exports.getHrAssignSubmissionVerify = getHrAssignSubmissionVerify;
const getAssignSubmissionItemsForHrVerify = async (id, params) => {
    const response = await Api.get(`documents/human-resources/assign/submissions/${id}/items`, params);
    return (0, parsers_1.parseAssignSubmissionItemsForHrVerify)(response);
};
exports.getAssignSubmissionItemsForHrVerify = getAssignSubmissionItemsForHrVerify;
const getHRAssignSubmissionVerifyFormItems = async (id, params) => {
    const response = await Api.get(`documents/human-resources/assign/submission-verify/${id}/items`, params);
    return (0, parsers_1.parseHRAssignSubmissionVerifyFormItems)(response);
};
exports.getHRAssignSubmissionVerifyFormItems = getHRAssignSubmissionVerifyFormItems;
const getHrAssignSubmissionVerifyItems = async (id, params) => {
    const response = await Api.get(`documents/human-resources/assign/submission-verify/${id}/items`, params);
    return (0, parsers_1.parseHumanResourceSubmissionVerifyItems)(response);
};
exports.getHrAssignSubmissionVerifyItems = getHrAssignSubmissionVerifyItems;
const updateHRAssignSubmissionVerifyItems = async (verifySubmissionId, params) => {
    const response = await Api.patch(`documents/human-resources/assign/submission-verify/${verifySubmissionId}/items`, params);
    return (0, parsers_1.parseHRAssignSubmissionVerifyFormItems)(response);
};
exports.updateHRAssignSubmissionVerifyItems = updateHRAssignSubmissionVerifyItems;
const declineHRSubmissionVerify = async (assignSubmissionVerifyId, params) => {
    const response = await Api.patch('documents/human-resources/assign/submission-verify/decline/' + assignSubmissionVerifyId, params);
    return (0, parsers_1.parseHumanResourceSubmissionVerify)(response);
};
exports.declineHRSubmissionVerify = declineHRSubmissionVerify;
const declineItems = async (params) => {
    const response = await Api.patch('documents/human-resources/assign/submission-verify/projects/decline', params);
    return (0, parsers_1.parseHRAssignSubmissionVerifyProjects)(response);
};
exports.declineItems = declineItems;
const suspendItems = async (params) => {
    const response = await Api.patch('documents/human-resources/assign/submission-verify/projects/suspend', params);
    return (0, parsers_1.parseHRAssignSubmissionVerifyProjects)(response);
};
exports.suspendItems = suspendItems;
const confirmItems = async (params) => {
    const response = await Api.patch('documents/human-resources/assign/submission-verify/projects/confirm', params);
    return (0, parsers_1.parseHRAssignSubmissionVerifyProjects)(response);
};
exports.confirmItems = confirmItems;
const verifyAtPaymentStage = async (params) => {
    const response = await Api.patch('documents/human-resources/assign/submission-verify/projects/verify-at-payment-stage', params);
    return (0, parsers_1.parseHRAssignSubmissionVerifyProjects)(response);
};
exports.verifyAtPaymentStage = verifyAtPaymentStage;
const sendForVerify = async (id) => {
    const response = await Api.patch(`documents/human-resources/assign/submission-verify/send-for-verify/${id}`, {});
    return (0, parsers_1.parseHumanResourceSubmissionVerify)(response);
};
exports.sendForVerify = sendForVerify;
