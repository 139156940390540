"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmployeesReducer = exports.useEmployeesReducer = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
const initialEmployees = {
    data: [],
    status: null,
    errors: null,
    meta: store_1.initialMeta,
    filterForm: {},
    isFetching: false,
    isDeleting: false,
    isNotifying: false,
    isNotifyingAll: false,
    isFetched: false
};
const useEmployeesReducer = () => {
    return (0, react_1.useReducer)(exports.EmployeesReducer, initialEmployees);
};
exports.useEmployeesReducer = useEmployeesReducer;
const EmployeesReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_EMPLOYEES_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_EMPLOYEES_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                status: action.payload.status,
                errors: action.payload.errors,
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'SEND_DELETE_REQUEST':
            return {
                ...state,
                isDeleting: true
            };
        case 'DONE_DELETE_REQUEST':
            return {
                ...state,
                errors: action.payload.errors,
                isDeleting: false
            };
        case 'SEND_NOTIFY_PENDING_CONTRACT_FILES':
            return {
                ...state,
                isNotifying: true
            };
        case 'DONE_NOTIFY_PENDING_CONTRACT_FILES':
            return {
                ...state,
                isNotifying: false
            };
        case 'SEND_NOTIFY_ALL_PENDING_CONTRACT_FILES':
            return {
                ...state,
                isNotifyingAll: true
            };
        case 'DONE_NOTIFY_ALL_PENDING_CONTRACT_FILES':
            return {
                ...state,
                isNotifyingAll: false
            };
        case 'INITIALIZE_FILTER_FORM':
            return {
                ...state,
                filterForm: action.payload,
                isFetched: true
            };
        default:
            return state;
    }
};
exports.EmployeesReducer = EmployeesReducer;
