"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const districts_1 = require("../store/districts");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helpers_1 = require("../helpers");
const queryString_1 = require("core/helpers/queryString");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useDistricts = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, districts_1.useDistrictsReducer)();
    const [keyword, setKeyword] = (0, react_1.useState)('');
    const isMounted = (0, react_1.useRef)(false);
    const navigatePagination = (page) => {
        const params = (0, queryString_1.parse)(location.search);
        getDistricts({ ...params, page }).then();
    };
    const selectPerPage = (perPage) => {
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'CHANGE_PER_PAGE', payload: perPage });
        getDistricts({ ...params, perPage, page: undefined }).then();
    };
    const debouncedSearch = (value) => {
        setKeyword(value);
        const searchParams = (0, queryString_1.parse)(location.search);
        const params = value
            ? { perPage: searchParams.perPage, filters: { keyword: value } }
            : { page: searchParams.page };
        (0, debouncedMethods_1.debouncedOnFunc)(params, getDistricts);
    };
    const getDistricts = async (params) => {
        dispatch({ type: 'SEND_DISTRICTS_REQUEST' });
        const districts = await index_1.DistrictsApi.getDistricts(params);
        if (isMounted?.current) {
            navigate('/references/addresses/districts' + (0, queryString_1.stringify)(params));
            dispatch({ type: 'DONE_DISTRICTS_REQUEST', payload: districts });
        }
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        const params = (0, helpers_1.getParamsFromUrl)(location.search);
        if (params.perPage)
            dispatch({ type: 'CHANGE_PER_PAGE', payload: params.perPage });
        setKeyword(params.filters?.keyword || '');
        getDistricts(params).then();
        return () => { isMounted.current = false; };
    }, []);
    return {
        keyword,
        debouncedSearch,
        state,
        getDistricts,
        selectPerPage,
        navigatePagination
    };
};
exports.default = useDistricts;
