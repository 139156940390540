"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_dom_1 = require("react-dom");
const react_1 = require("react");
const OverlayTooltip = (props) => {
    const { content, children, direction, errorMessage, forSelect } = props;
    const [isHovered, setIsHovered] = (0, react_1.useState)(false);
    const [position, setPosition] = (0, react_1.useState)({ x: 0, y: 0 });
    let hoverTimeout = null;
    const isOver = (0, react_1.useRef)(false);
    (0, react_1.useEffect)(() => { return () => { isOver.current = false; }; }, []);
    const show = (event) => {
        isOver.current = true;
        event.persist(); //TODO @Merab @Kote Must be removed when React version is updated
        hoverTimeout = setTimeout(() => {
            if (isOver.current) {
                const { x, y } = calculatePosition(event);
                setPosition({ x, y });
                setIsHovered(true);
            }
        }, (props.delay || 0.4) * 1000);
    };
    const calculatePosition = (event) => {
        const rect = event.target.getBoundingClientRect();
        const rectWidth = event.target.offsetWidth;
        const rectHeight = event.target.offsetHeight;
        let x = 0;
        let y = 0;
        switch (props.direction) {
            case 'left':
                x = rect.left - (content ? contentWidth(content?.length) : 0);
                y = rect.top + window.scrollY + (rectHeight / 2);
                break;
            case 'right':
                x = rect.left + rectWidth;
                y = rect.top + window.scrollY + (rectHeight / 2);
                break;
            case 'bottom':
                x = rect.left + (rectWidth / 2);
                y = rect.top + window.scrollY - rectHeight;
                break;
            default:
                x = rect.left + (rectWidth / 2);
                y = rect.bottom + window.scrollY;
        }
        return { x, y };
    };
    const hide = () => {
        isOver.current = false;
        if (hoverTimeout) {
            clearTimeout(hoverTimeout);
            hoverTimeout = null;
        }
        setIsHovered(false);
    };
    const contentWidth = (contentLength) => {
        if (!contentLength) {
            return 0;
        }
        else if (contentLength > 75) {
            return 400;
        }
        else if (contentLength < 20) {
            return 130;
        }
        else {
            return 250;
        }
    };
    return ((0, jsx_runtime_1.jsxs)("div", { onMouseEnter: show, onMouseLeave: hide, className: forSelect ? 'tooltip-block' : 'tooltip-inline', style: props.style, children: [children, isHovered && content
                ? (0, react_dom_1.createPortal)((0, jsx_runtime_1.jsx)("div", { className: `tooltip-tip ${direction || 'top'}${errorMessage ? ' text-danger' : ''}`, style: {
                        width: `${contentWidth(content.length)}px`,
                        top: position.y,
                        left: position.x,
                        zIndex: 9999,
                        wordBreak: 'break-all'
                    }, children: content }), document.body)
                : null] }));
};
exports.default = OverlayTooltip;
