"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useMajorPositionType_1 = require("../store/useMajorPositionType");
const index_1 = require("api/index");
const useMajorPositionType = () => {
    const [state, dispatch] = (0, useMajorPositionType_1.useMajorPositionTypeReducer)();
    const [startTransition, setStartTransition] = (0, react_1.useState)(false);
    const [creatingWorkDirections, setCreatingWorkDirections] = (0, react_1.useState)(false);
    const handleCreatePositionType = async (value, type) => {
        dispatch({ type: 'SEND_CREATE_POSITION_TYPE_REQUEST' });
        const positionType = await index_1.PositionTypeApi.createMajorPositionType({ name: value, type });
        dispatch({ type: 'DONE_CREATE_POSITION_TYPE_REQUEST' });
        return positionType;
    };
    const handleCreateWorkDirections = async (value) => {
        setCreatingWorkDirections(true);
        const workDirection = await index_1.WorkDirectionApi.createWorkDirection({ name: value });
        setCreatingWorkDirections(false);
        return workDirection;
    };
    return {
        startTransition,
        setStartTransition,
        handleCreatePositionType,
        state,
        handleCreateWorkDirections,
        creatingWorkDirections
    };
};
exports.default = useMajorPositionType;
