"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getParamsFromUrl = exports.transformDimensionUnitParams = void 0;
const queryString_1 = require("core/helpers/queryString");
const transformDimensionUnitParams = (params) => {
    return { name: params.name };
};
exports.transformDimensionUnitParams = transformDimensionUnitParams;
const getParamsFromUrl = (url) => {
    try {
        const params = (0, queryString_1.parse)(url);
        return {
            page: params.page,
            perPage: params.perPage,
            filters: params.filters?.keyword ? { keyword: params.filters.keyword } : undefined
        };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.getParamsFromUrl = getParamsFromUrl;
