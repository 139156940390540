"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.employeeEmployeeContractReducer = exports.useEmployeeContractReducer = exports.initialEmployeeContract = void 0;
const react_1 = require("react");
exports.initialEmployeeContract = {
    data: null,
    errors: null,
    status: null,
    isLoading: false,
    isSigning: false
};
const useEmployeeContractReducer = () => {
    return (0, react_1.useReducer)(exports.employeeEmployeeContractReducer, exports.initialEmployeeContract);
};
exports.useEmployeeContractReducer = useEmployeeContractReducer;
const employeeEmployeeContractReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_CONTRACT_FILE_REQUEST':
            return {
                ...state,
                errors: null,
                isLoading: true
            };
        case 'DONE_CONTRACT_FILE_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isLoading: false
            };
        case 'SEND_CONTRACT_SIGN_REQUEST':
            return {
                ...state,
                errors: null,
                isSigning: true
            };
        case 'DONE_CONTRACT_SIGN_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isSigning: false
            };
        case 'CLOSE_ERROR':
            return {
                ...state,
                errors: null
            };
        default:
            return state;
    }
};
exports.employeeEmployeeContractReducer = employeeEmployeeContractReducer;
