"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.patch = exports.get = exports.post = exports.sendRequest = void 0;
const helper_1 = require("api/helper");
const queryString_1 = require("core/helpers/queryString");
const baseURL = process.env.API_BASE_URL;
const sendRequest = async (url, method, params, BodyInit, file) => {
    try {
        const urlParams = (0, queryString_1.stringify)(params, { encode: false });
        const headersObject = {
            'Accept-Language': 'ka',
            'Accept': 'application/json'
        };
        const headers = file ? headersObject : { ...headersObject, 'Content-Type': 'application/json' };
        const body = file ? BodyInit : BodyInit && JSON.stringify(BodyInit);
        const response = await fetch(baseURL + url + urlParams, {
            body,
            headers,
            method: method
        });
        if (!response.ok) {
            return {
                status: response.status,
                content: null,
                errors: await (0, helper_1.getErrorMessages)(response)
            };
        }
        const content = await response.json();
        return {
            status: response.status,
            content: content,
            errors: null
        };
    }
    catch (error) {
        (0, helper_1.throwException)(error);
        return {
            status: 1,
            content: null,
            errors: [{ name: 'catch', message: 'unexpected' }]
        };
    }
};
exports.sendRequest = sendRequest;
const post = (url, body, file = false) => {
    return (0, exports.sendRequest)(url, 'POST', {}, body, file);
};
exports.post = post;
const get = (url, params = {}) => {
    return (0, exports.sendRequest)(url, 'GET', params);
};
exports.get = get;
const patch = (url, body) => {
    return (0, exports.sendRequest)(url, 'PATCH', {}, body);
};
exports.patch = patch;
