"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createOrUpdateProjectValidations = void 0;
const validates_1 = require("core/helpers/validates");
const createOrUpdateProjectValidations = (values, oldValues) => {
    const message = 'როცა პროექტი დასრულებულია არ შეიძლება შეიცვალოს ';
    const formErrors = {
        errorResponsibles: !values.isExpired ? (0, validates_1.requiredNotEmptyArray)(values.responsibles) : oldValues.responsibles === values.responsibles ? undefined : message + 'პასუხისმგებელი პირი',
        name: !values.isExpired ? (0, validates_1.required)(values.name) : oldValues.name === values.name ? undefined : message + 'დასახელება',
        fundingSource: !values.isExpired ? (0, validates_1.required)(values.fundingSource) : oldValues.fundingSource === values.fundingSource ? undefined : message + 'დაფინანსების წყაროები',
        budgetSource: !values.isExpired ? (0, validates_1.required)(values.budgetSource) : oldValues.budgetSource === values.budgetSource ? undefined : message + 'ბიუჯეტის წყაროები',
        number: !values.isExpired ? undefined : oldValues.number !== values.number ? message + 'ნომერი' : undefined,
        donor: !values.isExpired ? undefined : oldValues.donor !== values.donor ? message + 'დონორი' : undefined,
        additionalInfo: !values.isExpired ? undefined : oldValues.additionalInfo !== values.additionalInfo ? message + 'დამატებითი ინფორმაცია' : undefined,
        startDate: !values.isExpired ? undefined : oldValues.startDate !== values.startDate ? message + 'დაწყების თარიღი' : undefined,
        endDate: !values.isExpired ? undefined : values.startDate ? !values.endDate ? 'დასრულების თარიღი სავალდებულო ველია როცა დაწყების თარიღი არის მითითებული.' : undefined : undefined
    };
    const errors = {};
    Object.entries(formErrors).map(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.createOrUpdateProjectValidations = createOrUpdateProjectValidations;
