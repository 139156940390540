"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const bankAccount_1 = require("../store/bankAccount");
const react_1 = require("react");
const index_1 = require("api/index");
const helper_1 = require("../helper");
const useBankAccount = (props) => {
    const [state, dispatch] = (0, bankAccount_1.useBankAccountReducer)();
    const [deleteBankAccountAlert, setDeleteBankAccountAlert] = (0, react_1.useState)({
        showAlert: false,
        id: null
    });
    const [loadingItems, setLoadingItems] = (0, react_1.useState)([]);
    const [deleteLoadingItems, setDeleteLoadingItems] = (0, react_1.useState)([]);
    const getBankAccounts = async () => {
        dispatch({ type: 'SEND_BANK_ACCOUNT_REQUEST' });
        const bankAccountData = await index_1.EmployeesApi.getBankAccount(props.userId);
        dispatch({ type: 'DONE_BANK_ACCOUNT_REQUEST', payload: bankAccountData });
    };
    const addBankAccount = async (values, userId) => {
        const params = (0, helper_1.transformEmployeeBankAccountParams)(values);
        dispatch({ type: 'SEND_BANK_ACCOUNT_CREATE_OR_UPDATE', payload: values });
        if (values.id)
            setLoadingItems(prevState => ([...prevState, values.id]));
        const response = await index_1.EmployeesApi.addBankAccount(params, userId);
        if (response.errors === null) {
            const bankAccountData = await index_1.EmployeesApi.getBankAccount(props.userId);
            dispatch({ type: 'DONE_BANK_ACCOUNT_CREATE_OR_UPDATE', payload: bankAccountData });
        }
        else {
            dispatch({
                type: 'DONE_BANK_ACCOUNT_CREATE_OR_UPDATE',
                payload: { data: null, status: response.status, errors: response.errors }
            });
        }
        if (values.id)
            setLoadingItems(prevState => prevState.filter(item => item !== values.id));
        return response.errors;
    };
    const deleteBankAccount = async (id) => {
        setDeleteBankAccountAlert({ ...deleteBankAccountAlert, showAlert: false });
        setDeleteLoadingItems(prevState => ([...prevState, id]));
        dispatch({ type: 'SEND_DELETE_BANK_ACCOUNT' });
        const response = await index_1.EmployeesApi.deleteBankAccount(props.userId, id);
        dispatch({ type: 'DONE_DELETE_BANK_ACCOUNT', payload: { id, status: response.status, errors: response.errors } });
        setDeleteBankAccountAlert({ showAlert: false, id: null });
        setLoadingItems(prevState => prevState.filter(item => item !== id));
        return response;
    };
    (0, react_1.useEffect)(() => { getBankAccounts().then(); }, [props.userId]);
    return {
        state,
        addBankAccount,
        deleteBankAccount,
        deleteBankAccountAlert,
        setDeleteBankAccountAlert,
        getBankAccounts,
        loadingItems,
        deleteLoadingItems
    };
};
exports.default = useBankAccount;
