"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const types_1 = require("api/references/structuralUnitsUpgraded/types");
const createUpdateForm_1 = require("../store/createUpdateForm");
const index_1 = require("api/index");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const useAddEditStructuralUnit = (props) => {
    const [state, dispatch] = (0, createUpdateForm_1.useStructuralUnitFormReducer)();
    const getStructuralUnit = async (id) => {
        dispatch({ type: 'SEND_STRUCTURAL_UNIT_REQUEST' });
        const structuralUnit = await index_1.StructuralUnitsUpgradedApi.getStructuralUnit(id);
        dispatch({ type: 'DONE_STRUCTURAL_UNIT_REQUEST', payload: structuralUnit });
    };
    const updateStructuralUnit = async (params) => {
        if (params.type.id === types_1.StructuralUnitType.STRUCTURAL_UNIT) {
            const structuralUnitParams = (0, helpers_1.transformStructuralUnitParams)(params);
            dispatch({ type: 'SEND_UPDATE_STRUCTURAL_UNIT' });
            const structuralUnit = await index_1.StructuralUnitsUpgradedApi.updateStructuralUnit(params.id, structuralUnitParams);
            dispatch({ type: 'DONE_UPDATE_STRUCTURAL_UNIT', payload: structuralUnit });
            return structuralUnit;
        }
        const positionsParams = (0, helpers_1.transformPositionParams)(params);
        dispatch({ type: 'SEND_UPDATE_STRUCTURAL_UNIT' });
        const structuralUnit = await index_1.StructuralUnitsUpgradedApi.updatePosition(params.id, positionsParams);
        dispatch({ type: 'DONE_UPDATE_STRUCTURAL_UNIT', payload: structuralUnit });
        return structuralUnit;
    };
    const initialCrateForm = (parent) => {
        dispatch({ type: 'INITIALIZE_CREATE_FORM', payload: parent });
    };
    const createStructuralUnit = async (params) => {
        dispatch({ type: 'SEND_CREATE_STRUCTURAL_UNIT' });
        if (params.type.id === types_1.StructuralUnitType.STRUCTURAL_UNIT) {
            const structuralUnitParams = (0, helpers_1.transformStructuralUnitParams)(params);
            dispatch({ type: 'SEND_CREATE_STRUCTURAL_UNIT' });
            const structuralUnit = await index_1.StructuralUnitsUpgradedApi.createStructuralUnit(structuralUnitParams);
            dispatch({ type: 'DONE_CREATE_STRUCTURAL_UNIT', payload: structuralUnit });
            return structuralUnit;
        }
        const positionParams = (0, helpers_1.transformPositionsParams)(params);
        dispatch({ type: 'SEND_CREATE_POSITION_UNIT' });
        const structuralUnit = await index_1.StructuralUnitsUpgradedApi.createPositions(positionParams);
        dispatch({ type: 'DONE_CREATE_POSITION_UNIT', payload: structuralUnit });
        return structuralUnit;
    };
    (0, react_1.useEffect)(() => {
        if (props.nodeId)
            getStructuralUnit(props.nodeId).then();
        if (props.parent?.id)
            initialCrateForm(props.parent);
    }, [props.nodeId]);
    return { updateStructuralUnit, createStructuralUnit, state };
};
exports.default = useAddEditStructuralUnit;
