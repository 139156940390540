"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const notifications_1 = require("../store/notifications");
const index_1 = require("api/index");
const queryString_1 = require("core/helpers/queryString");
const react_router_dom_1 = require("react-router-dom");
const useNotifications = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, notifications_1.useNotificationsReducer)();
    const [showActions, setShowActions] = (0, react_1.useState)(null);
    const [showMenu, setShowMenu] = (0, react_1.useState)(false);
    const hideShowMenuToggle = () => {
        setShowMenu(false);
        window.removeEventListener('click', hideShowMenuToggle, true);
    };
    const showSettingsMenu = () => {
        if (!showMenu)
            window.addEventListener('click', hideShowMenuToggle, true);
        setShowMenu(true);
    };
    const getNotifications = async (params) => {
        dispatch({ type: 'SEND_NOTIFICATIONS_REQUEST' });
        const notifications = await index_1.NotificationsApi.getNotifications(params);
        navigate('/user/notifications/list' + (0, queryString_1.stringify)(params), { replace: true });
        dispatch({ type: 'DONE_NOTIFICATIONS_REQUEST', payload: notifications });
    };
    const getNotificationsAfterAllRead = async (params) => {
        const notifications = await index_1.NotificationsApi.getNotifications(params);
        dispatch({ type: 'GET_NOTIFICATIONS_REQUEST', payload: notifications });
    };
    const setAllHeaderNotificationsAsRead = async () => {
        dispatch({ type: 'SEND_SET_ALL_NOTIFICATIONS_AS_READ' });
        const notification = await index_1.NotificationsApi.setAllNotificationsAsRead();
        dispatch({ type: 'DONE_SET_ALL_NOTIFICATIONS_AS_READ' });
        if (notification.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            await getLastNotifications();
        return { status: notification.status, errors: notification.errors };
    };
    const setAllNotificationsListAsRead = async () => {
        dispatch({ type: 'SEND_SET_ALL_NOTIFICATIONS_AS_READ' });
        const params = (0, queryString_1.parse)(location.search);
        const notification = await index_1.NotificationsApi.setAllNotificationsAsRead();
        dispatch({ type: 'DONE_SET_ALL_NOTIFICATIONS_AS_READ' });
        if (notification.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            await getNotificationsAfterAllRead(params);
        return { status: notification.status, errors: notification.errors };
    };
    const selectPerPage = (perPage) => {
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'CHANGE_PER_PAGE', payload: perPage });
        getNotifications(({ ...params, perPage, page: undefined })).then();
    };
    const navigatePagination = (page) => {
        const params = (0, queryString_1.parse)(location.search);
        getNotifications({ ...params, page }).then();
    };
    const handleNotificationClick = async (item, notificationType) => {
        navigate(item.link);
        if (!item.readAt) {
            const res = await index_1.NotificationsApi.setNotificationAsRead(item.id);
            if (notificationType === 'headerMenu')
                getLastNotifications().then();
            return { status: res.status, errors: res.errors };
        }
        return { status: apiGlobalTypes_1.ResponseStatuses.SUCCESS, errors: null };
    };
    const setUnreadNotification = async (item, notificationType) => {
        const params = (0, queryString_1.parse)(location.search);
        setShowActions(null);
        if (item.readAt === null) {
            dispatch({ type: 'SET_LOADING_ITEM', payload: item.id });
            const globalResponse = await index_1.NotificationsApi.setNotificationAsRead(item.id);
            dispatch({ type: 'UN_SET_LOADING_ITEM', payload: { id: item.id, status: globalResponse.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS } });
            if (globalResponse.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS) {
                notificationType === 'headerMenu'
                    ? await getLastNotifications().then()
                    : await getNotifications(params).then();
            }
            return { status: globalResponse.status, errors: globalResponse.errors };
        }
        return { status: apiGlobalTypes_1.ResponseStatuses.SUCCESS, errors: null };
    };
    const hideShowMenu = () => {
        setShowActions(null);
    };
    const handleShowActionMenu = (id) => {
        if (showActions === id)
            return setShowActions(null);
        setShowActions(id);
        if (id) {
            window.addEventListener('click', hideShowMenu, true);
        }
        else {
            window.removeEventListener('click', hideShowMenu, true);
        }
    };
    const getLastNotifications = async () => {
        const activeStructuralUnitId = localStorage.getItem('activeStructuralUnitId');
        const isCreateDocs = localStorage.getItem('isCreateDocs');
        if (!activeStructuralUnitId || isCreateDocs)
            return { status: apiGlobalTypes_1.ResponseStatuses.UNEXPECTED, errors: null };
        dispatch({ type: 'SEND_UNREAD_NOTIFICATIONS_REQUEST' });
        const notifications = await index_1.NotificationsApi.getLastNotifications();
        dispatch({ type: 'DONE_UNREAD_NOTIFICATIONS_REQUEST', payload: notifications });
        return { status: notifications.status, errors: notifications.errors };
    };
    const handleAllNotificationsRoute = async (tag) => {
        navigate(`/user/notifications/${tag}`, { replace: true });
    };
    return {
        state,
        selectPerPage,
        navigatePagination,
        handleNotificationClick,
        setUnreadNotification,
        showActions,
        setShowActions,
        handleShowActionMenu,
        handleAllNotificationsRoute,
        getNotifications,
        showSettingsMenu,
        showMenu,
        setShowMenu,
        getLastNotifications,
        dispatch,
        setAllHeaderNotificationsAsRead,
        setAllNotificationsListAsRead
    };
};
exports.default = useNotifications;
