"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const bank_1 = require("../store/bank");
const queryString_1 = require("core/helpers/queryString");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const react_router_1 = require("react-router");
const useBank = (props) => {
    const [state, dispatch] = (0, bank_1.useBankReducer)();
    const [listingParams, setListingParams] = (0, react_1.useState)('');
    const navigate = (0, react_router_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const getBank = async (id) => {
        dispatch({ type: 'SEND_BANK_REQUEST' });
        const bank = await index_1.BankApi.getBank(id);
        dispatch({ type: 'DONE_BANK_REQUEST', payload: bank });
    };
    const createBank = async (values) => {
        const params = (0, helpers_1.transformBankParams)(values);
        dispatch({ type: 'SEND_BANK_CREATE' });
        const bank = await index_1.BankApi.createBank(params);
        dispatch({ type: 'DONE_BANK_CREATE', payload: bank });
        if (bank.data !== null) {
            props.closeBankSideBar
                ? props.closeBankSideBar({ ...bank.data, id: bank.data.id, label: bank.data.title + ' - ( ' + bank.data.code + ' )' })
                : navigate(`/references/banks/${bank.data.id}/edit`);
        }
        return bank;
    };
    const updateBank = async (id, values) => {
        const params = (0, helpers_1.transformBankParams)(values);
        dispatch({ type: 'SEND_BANK_UPDATE' });
        const bank = await index_1.BankApi.updateBank(id, params);
        dispatch({ type: 'DONE_BANK_UPDATE', payload: bank });
        return bank;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => {
        const params = (0, helpers_1.transformBankFilterParamsToUrl)((0, helpers_1.transformUrlToBankFilterParams)(location.search));
        const searchParams = (0, queryString_1.stringify)({ ...params });
        setListingParams(searchParams || '?');
        if (props.bankId)
            getBank(props.bankId).then();
    }, [props.bankId]);
    return {
        state,
        createBank,
        updateBank,
        getBank,
        closeErrorMessage,
        listingParams
    };
};
exports.default = useBank;
