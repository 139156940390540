"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findNestedItem = exports.getParamsFromUrl = void 0;
const queryString_1 = require("core/helpers/queryString");
const getParamsFromUrl = (url) => {
    try {
        const params = (0, queryString_1.parse)(url);
        return {
            page: params.page,
            perPage: params?.perPage ? params.perPage : 100,
            filters: params.filters?.keyword ? { keyword: params.filters.keyword, parentId: params.filters.parentId } : { parentId: '0' }
        };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.getParamsFromUrl = getParamsFromUrl;
const findNestedItem = (arr, targetItem) => (arr.reduce((a, item) => {
    if (a)
        return a;
    if (targetItem.level === item.level && targetItem.id === item.id)
        return item;
    if (item.children)
        return (0, exports.findNestedItem)(item.children, targetItem);
    return null;
}, null));
exports.findNestedItem = findNestedItem;
