"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.procurementTypeReducer = exports.useProcurementTypeReducer = exports.initialProcurementType = void 0;
const types_1 = require("api/documents/purchases/procurements/types");
const react_1 = require("react");
const initialProcurementTypeValue = {
    type: types_1.ContractType.GOODS,
    name: '',
    electronicTender: '',
    simplifiedProcurement: ''
};
exports.initialProcurementType = {
    data: initialProcurementTypeValue,
    errors: null,
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isLoading: false
};
const useProcurementTypeReducer = () => {
    return (0, react_1.useReducer)(exports.procurementTypeReducer, exports.initialProcurementType);
};
exports.useProcurementTypeReducer = useProcurementTypeReducer;
const procurementTypeReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_PROCUREMENT_TYPE_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_PROCUREMENT_TYPE_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                isFetching: false,
                isLoading: state.isUpdating
            };
        // case 'SEND_PROCUREMENT_TYPE_CREATE':
        //   return {
        //     ...state,
        //     errors: null,
        //     isCreating: true,
        //     isLoading: true
        //   }
        // case 'DONE_PROCUREMENT_TYPE_CREATE':
        //   return {
        //     ...state,
        //     data: action.payload.data || state.data,
        //     errors: action.payload.errors,
        //     isCreating: false,
        //     isLoading: state.isFetching || state.isUpdating
        //   }
        case 'SEND_PROCUREMENT_TYPE_UPDATE':
            return {
                ...state,
                errors: null,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_PROCUREMENT_TYPE_UPDATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                isUpdating: false,
                isLoading: state.isFetching
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.procurementTypeReducer = procurementTypeReducer;
