"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useConditionalSubmissionsReducer = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
const initialConditionalSubmissions = {
    data: [],
    status: null,
    filterFormValue: {},
    loadingItemId: null,
    itemStatuses: [],
    meta: store_1.initialMeta,
    errors: null,
    isDeleting: false,
    isCopying: false,
    isFetching: true,
    isFetched: false
};
const useConditionalSubmissionsReducer = () => {
    return (0, react_1.useReducer)(ConditionalSubmissionsReducer, initialConditionalSubmissions);
};
exports.useConditionalSubmissionsReducer = useConditionalSubmissionsReducer;
const ConditionalSubmissionsReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_CONDITIONAL_SUBMISSION_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_CONDITIONAL_SUBMISSION_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                status: action.payload.status,
                errors: action.payload.errors,
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'SEND_CONDITIONAL_SUBMISSIONS_ITEMS_STATUS_REQUEST':
            return {
                ...state,
                loadingItemId: action.payload,
                itemStatuses: []
            };
        case 'DONE_CONDITIONAL_SUBMISSIONS_ITEMS_STATUS_REQUEST':
            return {
                ...state,
                errors: action.payload.errors,
                status: action.payload.status,
                itemStatuses: action.payload.data || [],
                loadingItemId: null
            };
        case 'SEND_DELETE_REQUEST':
            return {
                ...state,
                isDeleting: true
            };
        case 'DONE_DELETE_REQUEST':
            return {
                ...state,
                errors: action.payload.errors,
                isDeleting: false
            };
        case 'SEND_COPY_REQUEST':
            return {
                ...state,
                isCopying: true
            };
        case 'DONE_COPY_REQUEST':
            return {
                ...state,
                errors: action.payload.errors,
                isCopying: false
            };
        case 'INITIALIZE_FILTER_FORM':
            return {
                ...state,
                filterFormValue: action.payload,
                isFetched: true
            };
        default:
            return state;
    }
};
