"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.locationReducer = exports.useLocationsReducer = exports.initialLocationsState = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
exports.initialLocationsState = {
    data: [],
    status: null,
    meta: store_1.initialMeta,
    errors: null,
    isFetching: true,
    isDeleting: false
};
const useLocationsReducer = () => {
    return (0, react_1.useReducer)(exports.locationReducer, exports.initialLocationsState);
};
exports.useLocationsReducer = useLocationsReducer;
const locationReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_LOCATIONS_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_LOCATIONS_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                status: action.payload.status,
                errors: action.payload.errors,
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'SEND_LOCATION_DELETE_REQUEST':
            return {
                ...state,
                isDeleting: true
            };
        case 'DONE_LOCATION_DELETE_REQUEST':
            return {
                ...state,
                isDeleting: false
            };
        case 'CHANGE_PER_PAGE':
            return {
                ...state,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        perPage: action.payload
                    }
                }
            };
        default:
            return state;
    }
};
exports.locationReducer = locationReducer;
