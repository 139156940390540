"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HumanResourceSubmissionVerifiesReducer = exports.useHumanResourceSubmissionVerifiesReducer = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
const initialHumanResourceSubmissionVerifies = {
    data: [],
    status: null,
    errors: null,
    meta: store_1.initialMeta,
    filterForm: {},
    isFetching: true,
    isFetched: false
};
const useHumanResourceSubmissionVerifiesReducer = () => {
    return (0, react_1.useReducer)(exports.HumanResourceSubmissionVerifiesReducer, initialHumanResourceSubmissionVerifies);
};
exports.useHumanResourceSubmissionVerifiesReducer = useHumanResourceSubmissionVerifiesReducer;
const HumanResourceSubmissionVerifiesReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_HUMAN_RESOURCE_SUBMISSION_VERIFIES_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_HUMAN_RESOURCE_SUBMISSION_VERIFIES_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                status: action.payload.status,
                errors: action.payload.errors,
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'CHANGE_PER_PAGE':
            return {
                ...state,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        perPage: action.payload.perPage
                    }
                },
                filterForm: action.payload
            };
        case 'INITIALIZE_FILTER_FORM':
            return {
                ...state,
                filterForm: action.payload,
                isFetched: true
            };
        default:
            return state;
    }
};
exports.HumanResourceSubmissionVerifiesReducer = HumanResourceSubmissionVerifiesReducer;
