"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoomFormValidation = void 0;
const validates_1 = require("core/helpers/validates");
const RoomFormValidation = (values) => {
    const formErrors = {
        roomNumber: (0, validates_1.required)(values.roomNumber),
        administrativeBuilding: (0, validates_1.required)(values.administrativeBuilding)
    };
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.RoomFormValidation = RoomFormValidation;
