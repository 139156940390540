"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseWarrantyTerm = exports.parseWarrantyTerms = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseWarrantyTerms = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const warrantyTerms = response.content;
    try {
        return {
            data: warrantyTerms.data.map(el => {
                return {
                    id: el.id,
                    moduleType: el.type,
                    duration: el.attributes.duration,
                    number: el.attributes.number,
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt)
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(warrantyTerms.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseWarrantyTerms = parseWarrantyTerms;
const parseWarrantyTerm = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const warrantyTerm = response.content;
    try {
        return {
            data: {
                id: warrantyTerm.data.id,
                duration: warrantyTerm.data.attributes.duration,
                number: warrantyTerm.data.attributes.number
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseWarrantyTerm = parseWarrantyTerm;
