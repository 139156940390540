"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformOrdersStructuralUnitParams = exports.getParamsFromUrlArticle = exports.getParamsFromUrl = exports.transformArticleArticleFormValue = exports.transformArticlesParams = exports.transformAdministrativeOrderParams = void 0;
const queryString_1 = require("core/helpers/queryString");
const transformAdministrativeOrderParams = (params) => {
    return {
        number: params.number,
        name: params.name,
        publicationDate: params.publicationDate,
        fileUid: params.files[0].id
    };
};
exports.transformAdministrativeOrderParams = transformAdministrativeOrderParams;
const transformArticlesParams = (values) => {
    return {
        articles: [
            {
                parentId: values.administrativeOrdersArticle?.id || null,
                id: values.id,
                name: values.name,
                number: values.number,
                // type: Number((values.articleType as AdministrativeOrderSelectType).id),
                price: values.price || null
            }
        ]
    };
};
exports.transformArticlesParams = transformArticlesParams;
const transformArticleArticleFormValue = (data) => {
    if (data === null)
        return null;
    return {
        id: data.id,
        administrativeOrdersArticle: data.parent,
        name: data.name,
        number: data.number,
        price: data.price
    };
};
exports.transformArticleArticleFormValue = transformArticleArticleFormValue;
const getParamsFromUrl = (url) => {
    try {
        const params = (0, queryString_1.parse)(url);
        return {
            page: params.page,
            perPage: params.perPage,
            filters: params.filters
                ? { publicationDate: params.filters.publicationDate, number: params.filters.number, createdBy: params.filters.createdBy }
                : undefined
        };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.getParamsFromUrl = getParamsFromUrl;
const getParamsFromUrlArticle = (url) => {
    try {
        const params = (0, queryString_1.parse)(url);
        return {
            page: params.page,
            perPage: params?.perPage ? params.perPage : 100,
            filters: params.filters?.keyword
                ? { keyword: params.filters.keyword, parentId: params.filters.parentId, administrativeOrderId: params.filters.administrativeOrderId }
                : { parentId: '0', administrativeOrderId: '0' }
        };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.getParamsFromUrlArticle = getParamsFromUrlArticle;
const transformOrdersStructuralUnitParams = (values) => {
    return {
        ids: values.attachedStructuralUnits.map(el => el.id)
    };
};
exports.transformOrdersStructuralUnitParams = transformOrdersStructuralUnitParams;
