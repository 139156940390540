"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAdministrativeBuildingsForSelect = exports.deleteItem = exports.updateAdministrativeBuilding = exports.createAdministrativeBuilding = exports.getAdministrativeBuilding = exports.getAdministrativeBuildings = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/references/addresses/administrativeBuildings/parsers");
const getAdministrativeBuildings = async (params) => {
    const response = await Api.get('references/administrative-buildings', params);
    return (0, parsers_1.parseAdministrativeBuildings)(response);
};
exports.getAdministrativeBuildings = getAdministrativeBuildings;
const getAdministrativeBuilding = async (id) => {
    const response = await Api.get('references/administrative-buildings/' + id);
    return (0, parsers_1.parseAdministrativeBuilding)(response);
};
exports.getAdministrativeBuilding = getAdministrativeBuilding;
const createAdministrativeBuilding = async (params) => {
    const response = await Api.post('references/administrative-buildings', params);
    return (0, parsers_1.parseAdministrativeBuilding)(response);
};
exports.createAdministrativeBuilding = createAdministrativeBuilding;
const updateAdministrativeBuilding = async (id, params) => {
    const response = await Api.patch('references/administrative-buildings/' + id, params);
    return (0, parsers_1.parseAdministrativeBuilding)(response);
};
exports.updateAdministrativeBuilding = updateAdministrativeBuilding;
const deleteItem = async (id) => {
    return await Api.deleteItem(`references/administrative-buildings/${id}`);
};
exports.deleteItem = deleteItem;
const getAdministrativeBuildingsForSelect = async (params) => {
    const response = await Api.get('references/administrative-buildings', params);
    return (0, parsers_1.parseAdministrativeBuildingsForSelect)(response);
};
exports.getAdministrativeBuildingsForSelect = getAdministrativeBuildingsForSelect;
