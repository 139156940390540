"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.employeeSignatoryFilesReducer = exports.useEmployeeSignatoryFilesReducer = exports.initialEmployeeSignatoryFilesState = void 0;
const react_1 = require("react");
const types_1 = require("api/references/employees/types");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const initialEmployeeSignatoryFiles = {
    pending: {
        isUsed: apiGlobalTypes_1.YesNoStatus.YES,
        id: null,
        signature: null,
        updatedAt: null,
        signatureApprove: null
    },
    active: {
        isUsed: apiGlobalTypes_1.YesNoStatus.YES,
        id: null,
        signature: null,
        updatedAt: null,
        signatureApprove: null
    },
    status: types_1.SignatoryFileStatus.PASSIVE,
    signatureAndSignatureApproveFiles: []
};
exports.initialEmployeeSignatoryFilesState = {
    data: initialEmployeeSignatoryFiles,
    errors: null,
    status: null,
    isFetching: false,
    isUpdating: false,
    isLoading: false,
    isDeleting: false
};
const useEmployeeSignatoryFilesReducer = () => {
    return (0, react_1.useReducer)(exports.employeeSignatoryFilesReducer, exports.initialEmployeeSignatoryFilesState);
};
exports.useEmployeeSignatoryFilesReducer = useEmployeeSignatoryFilesReducer;
const employeeSignatoryFilesReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_EMPLOYEE_SIGNATORY_FILES_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_EMPLOYEE_SIGNATORY_FILES_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isFetching: false,
                isLoading: state.isUpdating
            };
        case 'SEND_EMPLOYEE_SIGNATORY_FILES_CREATE':
            return {
                ...state,
                errors: null,
                isUpdating: true
            };
        case 'DONE_EMPLOYEE_SIGNATORY_FILES_CREATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isUpdating: false
            };
        case 'SEND_EMPLOYEE_SIGNATORY_FILES_UPDATE':
            return {
                ...state,
                errors: null,
                isUpdating: true
            };
        case 'DONE_EMPLOYEE_SIGNATORY_FILES_UPDATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isUpdating: false
            };
        case 'SEND_EMPLOYEE_DELETE_SIGNATORY':
            return {
                ...state,
                errors: null,
                isDeleting: true
            };
        case 'DONE_EMPLOYEE_DELETE_SIGNATORY':
            return {
                ...state,
                data: action.payload.data || state.data,
                isDeleting: false,
                errors: action.payload.errors
            };
        default:
            return state;
    }
};
exports.employeeSignatoryFilesReducer = employeeSignatoryFilesReducer;
