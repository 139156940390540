"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.monthsWithAnnualOptions = exports.monthsOptions = void 0;
exports.monthsOptions = [
    { id: '1', label: 'იანვარი' },
    { id: '2', label: 'თებერვალი' },
    { id: '3', label: 'მარტი' },
    { id: '4', label: 'აპრილი' },
    { id: '5', label: 'მაისი' },
    { id: '6', label: 'ივნისი' },
    { id: '7', label: 'ივლისი' },
    { id: '8', label: 'აგვისტო' },
    { id: '9', label: 'სექტემბერი' },
    { id: '10', label: 'ოქტომბერი' },
    { id: '11', label: 'ნოემბერი' },
    { id: '12', label: 'დეკემბერი' }
];
exports.monthsWithAnnualOptions = [
    { id: '0', label: 'ყოველთვიური სარგო' },
    ...exports.monthsOptions
];
