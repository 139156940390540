"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttachStructuralUnitToAccountReducer = exports.useAttachStructuralUnitToAccountsReducer = void 0;
const react_1 = require("react");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const store_1 = require("core/store");
const AttachStructuralUnitToAccountFormValues = {
    structuralUnitList: [],
    meta: store_1.initialMeta
};
const initialAttachStructuralUnitToAccountReducer = {
    data: AttachStructuralUnitToAccountFormValues,
    errors: null,
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isLoading: false,
    loadingCheckbox: []
};
const useAttachStructuralUnitToAccountsReducer = () => {
    return (0, react_1.useReducer)(exports.AttachStructuralUnitToAccountReducer, initialAttachStructuralUnitToAccountReducer);
};
exports.useAttachStructuralUnitToAccountsReducer = useAttachStructuralUnitToAccountsReducer;
const AttachStructuralUnitToAccountReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_GET_STRUCTURAL_UNITS_REQUEST':
            return {
                ...state,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_GET_STRUCTURAL_UNITS_REQUEST':
            return {
                ...state,
                data: {
                    ...state.data,
                    structuralUnitList: action.payload.structuralUnitList.data?.map(el => {
                        const selectedStructuralUnit = action.payload.attachedStructuralUnits.data?.find(element => el.id === element.structuralUnitId);
                        return {
                            id: el.id,
                            structuralUnit: el.fullName,
                            checked: !!selectedStructuralUnit
                        };
                    }) || state.data.structuralUnitList,
                    meta: action.payload.meta || state.data.meta
                },
                isFetching: false,
                isLoading: state.isUpdating || state.isCreating
            };
        case 'SEND_STRUCTURAL_UNIT_ATTACH_REQUEST':
            return {
                ...state,
                loadingCheckbox: [...state.loadingCheckbox, action.payload],
                isLoading: true
            };
        case 'DONE_STRUCTURAL_UNIT_ATTACH_REQUEST':
            return {
                ...state,
                data: {
                    ...state.data,
                    structuralUnitList: action.payload.attachedStructuralUnits ? state.data.structuralUnitList.map(el => {
                        const selectedStructuralUnit = action.payload.attachedStructuralUnits.data?.find(element => el.id === element.structuralUnitId);
                        return {
                            id: el.id,
                            structuralUnit: el.structuralUnit,
                            checked: !!selectedStructuralUnit
                        };
                    }) : state.data.structuralUnitList
                },
                loadingCheckbox: state.loadingCheckbox.filter(el => el !== action.payload.structuralUnitId),
                isLoading: false
            };
        case 'SEND_STRUCTURAL_UNIT_DETACH_REQUEST':
            return {
                ...state,
                loadingCheckbox: [...state.loadingCheckbox, action.payload],
                isLoading: true
            };
        case 'DONE_STRUCTURAL_UNIT_DETACH_REQUEST':
            return {
                ...state,
                data: {
                    ...state.data,
                    structuralUnitList: action.payload.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS
                        ? state.data.structuralUnitList.map(el => {
                            return {
                                id: el.id,
                                structuralUnit: el.structuralUnit,
                                checked: el.id === action.payload.structuralUnitId ? false : el.checked
                            };
                        })
                        : state.data.structuralUnitList
                },
                loadingCheckbox: state.loadingCheckbox.filter(el => el !== action.payload.structuralUnitId),
                isLoading: false
            };
        default: {
            return state;
        }
    }
};
exports.AttachStructuralUnitToAccountReducer = AttachStructuralUnitToAccountReducer;
