"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseCorrection = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseCorrection = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const correction = response.content;
    try {
        return {
            data: {
                id: correction.data.id,
                number: correction.data.attributes.number,
                publicationDate: (0, parsers_1.transformDate)(correction.data.attributes.publicationDate),
                name: correction.data.attributes.name,
                createdBy: correction.data.relationships.createdBy.data.attributes.fullname,
                createdAt: (0, parsers_1.transformDate)(correction.data.attributes.createdAt),
                deletedAt: (0, parsers_1.nullableTransformDate)(correction.data.attributes.deletedAt),
                correction: [],
                files: correction.data.relationships.mediaFile && [
                    {
                        id: correction.data.relationships.mediaFile.data.id,
                        name: correction.data.relationships.mediaFile.data.attributes.name,
                        status: correction.data.relationships.mediaFile.data.attributes.status,
                        originalName: correction.data.relationships.mediaFile.data.attributes.originalName,
                        link: correction.data.relationships.mediaFile.data.attributes.downloadUrl,
                        extension: correction.data.relationships.mediaFile.data.attributes.extension
                    }
                ] || []
            },
            errors: null,
            status: response.status
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseCorrection = parseCorrection;
