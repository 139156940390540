"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dismissalReducer = exports.useDismissalReducer = exports.initialDismissalState = exports.initialDismissalFormValue = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
exports.initialDismissalFormValue = {
    id: null,
    title: null,
    assign: null,
    positionName: null,
    number: null,
    documentNumber: null,
    dismissalReason: null,
    dismissalDate: null,
    date: null
};
const initialDismissalValue = {
    dismissals: [],
    dismissal: exports.initialDismissalFormValue
};
exports.initialDismissalState = {
    data: initialDismissalValue,
    status: null,
    meta: store_1.initialMeta,
    errors: null,
    isFetching: false,
    isLoading: false,
    isCreating: false,
    isUpdating: false,
    deletingDismissal: false
};
const useDismissalReducer = () => {
    return (0, react_1.useReducer)(exports.dismissalReducer, exports.initialDismissalState);
};
exports.useDismissalReducer = useDismissalReducer;
const dismissalReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_DISMISSAL_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_DISMISSAL_REQUEST':
            return {
                ...state,
                data: {
                    ...state.data,
                    dismissals: action.payload.data || state.data.dismissals
                },
                errors: action.payload.errors,
                status: action.payload.status,
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false,
                isLoading: state.isCreating || state.isUpdating
            };
        case 'SEND_DISMISSAL_CREATE':
            return {
                ...state,
                errors: null,
                isCreating: true,
                isLoading: true
            };
        case 'DONE_DISMISSAL_CREATE':
            return {
                ...state,
                data: {
                    ...state.data,
                    dismissals: action.payload.data && [{ ...action.payload.data }, ...state.data.dismissals]
                        .map(obj => Object.assign({}, obj, { key: obj.id })) || state.data.dismissals
                },
                errors: action.payload.errors,
                isCreating: false,
                status: action.payload.status,
                isLoading: state.isFetching || state.isUpdating
            };
        case 'SEND_DELETE_DISMISSAL':
            return {
                ...state,
                deletingDismissal: true
            };
        case 'DONE_DELETE_DISMISSAL':
            return {
                ...state,
                deletingDismissal: false,
                errors: action.payload.errors
            };
        case 'SEND_DISMISSAL_ITEM_UPDATE':
            return {
                ...state,
                isUpdating: true
            };
        case 'DONE_DISMISSAL_ITEM_UPDATE':
            return {
                ...state,
                data: {
                    ...state.data,
                    dismissals: state.data.dismissals.map(el => action.payload.data?.id === el.id ? { ...el, ...action.payload.data } : el)
                },
                isUpdating: false
            };
        case 'CHANGE_PER_PAGE':
            return {
                ...state,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        perPage: action.payload
                    }
                }
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
    }
};
exports.dismissalReducer = dismissalReducer;
