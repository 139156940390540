"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const usePreventDirtyOnload = (props) => {
    (0, react_1.useEffect)(() => {
        // window.onbeforeunload = () => props.dirty || null
        // return () => { window.onbeforeunload = null }
    }, [props.dirty]);
};
exports.default = usePreventDirtyOnload;
