"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.budgetingRecordCommentOrStatus = exports.getBudgetingRecord = exports.getContractsSigningListForModal = exports.signContractInModal = exports.signContract = exports.getSingleContractSigning = exports.getContractsSigningList = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/documents/human-resources/contract-signing/parsers");
const getContractsSigningList = async (params) => {
    const response = await Api.get('references/employees/contract-files', params);
    return (0, parsers_1.parseContractsSigningList)(response);
};
exports.getContractsSigningList = getContractsSigningList;
const getSingleContractSigning = async (contractId) => {
    const response = await Api.get(`references/employees/contract-files/${contractId}`);
    return (0, parsers_1.parseSingleContractSigning)(response);
};
exports.getSingleContractSigning = getSingleContractSigning;
const signContract = async (contractId) => {
    const response = await Api.patch(`references/employees/contract-files/${contractId}/approval-sign`, {});
    return (0, parsers_1.parseSingleContractSigning)(response);
};
exports.signContract = signContract;
const signContractInModal = async (contractId) => {
    const response = await Api.patch(`references/employees/contract-files/${contractId}/approval-sign`, {});
    return { errors: response.errors, status: response.status };
};
exports.signContractInModal = signContractInModal;
const getContractsSigningListForModal = async (params) => {
    const response = await Api.get('references/employees/contract-files', params);
    return (0, parsers_1.parseContractsSigningListForModal)(response);
};
exports.getContractsSigningListForModal = getContractsSigningListForModal;
const getBudgetingRecord = async (contractId) => {
    const response = await Api.get(`references/employees/contract-files/${contractId}/budgetingRecords`);
    return (0, parsers_1.parseBudgetingRecord)(response);
};
exports.getBudgetingRecord = getBudgetingRecord;
const budgetingRecordCommentOrStatus = async (contractId, params) => {
    const response = await Api.patch(`references/employees/contract-files/budgetingRecord/${contractId}`, params);
    return (0, parsers_1.parseBudgetingRecord)(response);
};
exports.budgetingRecordCommentOrStatus = budgetingRecordCommentOrStatus;
