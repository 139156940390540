"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const location_1 = require("../store/location");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useLocationHook = (props) => {
    const [state, dispatch] = (0, location_1.useLocationReducer)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const getLocation = async (id) => {
        dispatch({ type: 'SEND_LOCATION_REQUEST' });
        const location = await index_1.LocationsApi.getLocation(id);
        dispatch({ type: 'DONE_LOCATION_REQUEST', payload: location });
    };
    const createLocation = async (values) => {
        const params = (0, helpers_1.transformLocationParams)(values);
        dispatch({ type: 'SEND_LOCATION_CREATE' });
        const location = await index_1.LocationsApi.createLocation(params);
        dispatch({ type: 'DONE_LOCATION_CREATE', payload: location });
        if (location.data !== null) {
            navigate(`/references/addresses/locations/${location.data.id}/edit`);
        }
        return location;
    };
    const updateLocation = async (id, values) => {
        const params = (0, helpers_1.transformLocationParams)(values);
        dispatch({ type: 'SEND_LOCATION_UPDATE' });
        const location = await index_1.LocationsApi.updateLocation(id, params);
        dispatch({ type: 'DONE_LOCATION_UPDATE', payload: location });
        return location;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getLocation(props.id).then(); }, [props.id]);
    return {
        getLocation,
        state,
        createLocation,
        updateLocation,
        closeErrorMessage
    };
};
exports.default = useLocationHook;
