"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cpvCodesReducer = exports.useCpvCodesReducer = exports.initialCpvCodesState = void 0;
const react_1 = require("react");
exports.initialCpvCodesState = {
    data: [],
    status: null,
    loadingItems: [],
    errors: null,
    isFetching: true
};
const useCpvCodesReducer = () => {
    return (0, react_1.useReducer)(exports.cpvCodesReducer, exports.initialCpvCodesState);
};
exports.useCpvCodesReducer = useCpvCodesReducer;
const cpvCodesReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_CPV_CODES_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_CPV_CODES_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                status: action.payload.status,
                errors: action.payload.errors,
                isFetching: false
            };
        case 'SEND_SINGLE_NODE_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_SINGLE_NODE_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                status: action.payload.status,
                isFetching: false
            };
        case 'UPDATE_STATE':
            return {
                ...state,
                data: action.payload || []
            };
        case 'SET_LOADING_ITEM':
            return {
                ...state,
                loadingItems: [...state.loadingItems, action.payload]
            };
        case 'UN_SET_LOADING_ITEM':
            return {
                ...state,
                loadingItems: state.loadingItems.filter(el => !(el.id === action.payload.id && el.level === action.payload.level))
            };
        default:
            return state;
    }
};
exports.cpvCodesReducer = cpvCodesReducer;
