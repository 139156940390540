"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const types_1 = require("../types");
const react_1 = require("react");
const useToast = () => {
    const [toastArray, setToastArray] = (0, react_1.useState)([]);
    const throwMessage = (message, type) => {
        setToastArray(prevState => {
            return [
                {
                    id: Date.now(),
                    type,
                    message
                }, ...prevState
            ];
        });
    };
    const success = (message = 'მოქმედება წარმატებით შესრულდა') => {
        setToastArray(prevState => {
            return [
                {
                    id: Date.now(),
                    type: types_1.ToastType.SUCCESS,
                    message
                }, ...prevState
            ];
        });
    };
    const successMessage = (status) => {
        setToastArray(prevState => {
            return [
                {
                    id: Date.now(),
                    type: types_1.ToastType.SUCCESS,
                    message: `მონაცემები ${status === apiGlobalTypes_1.ResponseStatuses.CREATED ? 'შეიქმნა' : 'განახლდა'} წარმატებით`
                }, ...prevState
            ];
        });
    };
    const danger = (message = 'დაფიქსირდა შეცდომა') => {
        setToastArray(prevState => {
            return [
                {
                    id: Date.now(),
                    type: types_1.ToastType.DANGER,
                    message
                }, ...prevState
            ];
        });
    };
    const warning = (message) => {
        setToastArray(prevState => {
            return [
                {
                    id: Date.now(),
                    type: types_1.ToastType.WARNING,
                    message
                }, ...prevState
            ];
        });
    };
    const info = (message) => {
        setToastArray(prevState => {
            return [
                {
                    id: Date.now(),
                    type: types_1.ToastType.INFO,
                    message
                }, ...prevState
            ];
        });
    };
    const deleteMessage = (id) => setToastArray(prevState => prevState.filter(el => el.id !== id));
    return {
        actions: {
            throwMessage,
            success,
            danger,
            warning,
            info,
            deleteMessage,
            successMessage
        },
        toastArray
    };
};
exports.default = useToast;
