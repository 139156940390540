"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseVacation = exports.parseVacations = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseVacations = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const vacations = response.content;
    try {
        return {
            data: vacations.data.filter(item => !!item.relationships.account.data.relationships.contract.data.relationships.structuralUnit).map(el => {
                const structuralUnit = el.relationships.account.data.relationships.contract.data.relationships.structuralUnit;
                const project = el.relationships.account.data.relationships.contract.data.relationships.project;
                return {
                    id: el.id,
                    startDate: (0, parsers_1.transformDate)(el.attributes.startDate),
                    startDateTime: (0, parsers_1.transformDateTime)(el.attributes.startDate),
                    endData: (0, parsers_1.transformDate)(el.attributes.endDate),
                    endDateTime: (0, parsers_1.transformDateTime)(el.attributes.endDate),
                    comment: el.attributes.comment,
                    userFullName: el.relationships.account.data.relationships.user.data.attributes.fullname,
                    substituteUserFullName: el.relationships.substituteAccount.data.relationships.user.data.attributes.fullname,
                    substituteStructuralUnitFullName: structuralUnit ? structuralUnit.data.attributes.fullName : 'პროექტის "' + project?.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი',
                    structuralUnitFullName: structuralUnit ? structuralUnit.data.attributes.fullName : 'პროექტის "' + project?.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი',
                    createdAt: el.attributes.createdAt,
                    updatedAt: el.attributes.updatedAt
                };
            }),
            errors: null,
            meta: (0, parsers_1.transformPagination)(vacations.meta),
            status: response.status
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseVacations = parseVacations;
const parseVacation = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const vacation = response.content;
    try {
        const structuralUnit = vacation.data.relationships.account.data.relationships.contract.data.relationships.structuralUnit;
        const substituteStructuralUnit = vacation.data.relationships.substituteAccount.data.relationships.contract.data.relationships.structuralUnit;
        const project = vacation.data.relationships.account.data.relationships.contract.data.relationships.project;
        return {
            data: {
                id: vacation.data.id,
                user: {
                    id: vacation.data.relationships.account.data.relationships.user.data.id,
                    label: vacation.data.relationships.account.data.relationships.user.data.attributes.fullname +
                        ` (${vacation.data.relationships.account.data.relationships.user.data.attributes.email})`,
                    structuralUnits: vacation.data.relationships.account.data.relationships.user.data.relationships.accounts.data
                        .filter(el => !!el.relationships.contract.data.relationships.structuralUnit)
                        .map(unit => {
                        const structuralUnit = unit.relationships.contract.data.relationships.structuralUnit;
                        return {
                            id: structuralUnit.data.id,
                            accountId: unit.id,
                            label: structuralUnit.data.attributes.fullName
                        };
                    })
                },
                substituteUser: {
                    id: vacation.data.relationships.substituteAccount.data.relationships.user.data.id,
                    label: vacation.data.relationships.substituteAccount.data.relationships.user.data.attributes.fullname +
                        ` (${vacation.data.relationships.substituteAccount.data.relationships.user.data.attributes.email})`,
                    structuralUnits: vacation.data.relationships.substituteAccount.data.relationships.user.data.relationships?.accounts.data.filter(el => !!el.relationships.contract.data.relationships.structuralUnit).map(unit => {
                        const structuralUnit = unit.relationships.contract.data.relationships.structuralUnit;
                        return {
                            id: structuralUnit.data.id,
                            accountId: unit.id,
                            label: structuralUnit.data.attributes.fullName
                        };
                    })
                },
                substituteStructuralUnit: {
                    id: substituteStructuralUnit.data.id,
                    accountId: vacation.data.relationships.substituteAccount.data.id,
                    deletedAt: (0, parsers_1.nullableTransformDate)(vacation.data.relationships.substituteAccount.data.attributes.deletedAt || null),
                    label: substituteStructuralUnit ? substituteStructuralUnit.data.attributes.fullName : 'პროექტის "' + project?.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი'
                },
                structuralUnit: {
                    id: structuralUnit.data.id,
                    accountId: vacation.data.relationships.account.data.id,
                    deletedAt: (0, parsers_1.nullableTransformDate)(vacation.data.relationships.account.data.attributes.deletedAt || null),
                    label: structuralUnit ? structuralUnit.data.attributes.fullName : 'პროექტის "' + project?.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი'
                },
                comment: vacation.data.attributes.comment,
                endDate: (0, parsers_1.transformDate)(vacation.data.attributes.endDate),
                startDate: (0, parsers_1.transformDate)(vacation.data.attributes.startDate)
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseVacation = parseVacation;
