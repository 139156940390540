"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPositionTypesForSelect = exports.updatePositionType = exports.createPositionType = exports.getPositionType = exports.deletePositionTypes = exports.getPositionTypes = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/references/humanResources/positionTypes/parsers");
const getPositionTypes = async (params) => {
    const response = await Api.get('references/human-resources/position-types', params);
    return (0, parsers_1.parsePositionTypes)(response);
};
exports.getPositionTypes = getPositionTypes;
const deletePositionTypes = async (id) => {
    return Api.deleteItem('references/human-resources/position-types/' + id);
};
exports.deletePositionTypes = deletePositionTypes;
const getPositionType = async (id) => {
    const response = await Api.get('references/human-resources/position-types/' + id);
    return (0, parsers_1.parsePositionType)(response);
};
exports.getPositionType = getPositionType;
const createPositionType = async (params) => {
    const response = await Api.post('references/human-resources/position-types', params);
    return (0, parsers_1.parsePositionType)(response);
};
exports.createPositionType = createPositionType;
const updatePositionType = async (id, params) => {
    const response = await Api.patch('references/human-resources/position-types/' + id, params);
    return (0, parsers_1.parsePositionType)(response);
};
exports.updatePositionType = updatePositionType;
const getPositionTypesForSelect = async (params) => {
    const response = await Api.get('references/human-resources/position-types', params);
    return (0, parsers_1.parsePositionTypesForSelect)(response);
};
exports.getPositionTypesForSelect = getPositionTypesForSelect;
