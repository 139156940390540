"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.myFilesStatuses = exports.transformMyFilesFilterParamsToUrl = exports.transformMyFilesParamsToFilterParams = exports.transformUrlToMyFilesFilterParams = void 0;
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const queryString_1 = require("core/helpers/queryString");
const helper_1 = require("src/modules/common/helper");
const transformUrlToMyFilesFilterParams = (url) => {
    const values = (0, queryString_1.parse)(url);
    return {
        page: values.page,
        perPage: values.perPage,
        keyword: values.keyword,
        status: values.statusId && values.statusLabel
            ? { id: values.statusId, label: values.statusLabel }
            : undefined,
        createdAtFrom: values.createdAtFrom,
        createdAtTo: values.createdAtTo,
        user: values.userId && values.userLabel ? { id: values.userId, label: values.userLabel } : undefined
    };
};
exports.transformUrlToMyFilesFilterParams = transformUrlToMyFilesFilterParams;
const transformMyFilesParamsToFilterParams = (params) => {
    const filters = {};
    const paginate = {};
    const filterParams = {
        keyword: params.keyword,
        status: params.status?.id,
        createdAtFrom: params.createdAtFrom && (0, helper_1.transformParamsDate)(params.createdAtFrom),
        createdAtTo: params.createdAtTo && (0, helper_1.transformParamsDate)(params.createdAtTo),
        page: params.page,
        perPage: params.perPage,
        createdBy: params.user?.id
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if (value && key !== 'page' && key !== 'perPage')
                filters[key] = value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(filters).length === 0)
            return { ...paginate };
        return { ...paginate, filters };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformMyFilesParamsToFilterParams = transformMyFilesParamsToFilterParams;
const transformMyFilesFilterParamsToUrl = (filterParams) => {
    const { page, perPage, keyword, status, createdAtFrom, createdAtTo, user } = filterParams;
    return {
        page,
        perPage,
        keyword,
        createdAtFrom,
        createdAtTo,
        userId: user && user.id,
        userLabel: user && user.label,
        statusId: status && status.id,
        statusLabel: status && status.label
    };
};
exports.transformMyFilesFilterParamsToUrl = transformMyFilesFilterParamsToUrl;
exports.myFilesStatuses = [
    { id: String(apiGlobalTypes_1.FileDownloadStatus.UNCONFIRMED), label: 'დაუდასტურებელი' },
    { id: String(apiGlobalTypes_1.FileDownloadStatus.CONFIRMED), label: 'დადასტურებული' },
    { id: String(apiGlobalTypes_1.FileDownloadStatus.TEMPORARY), label: 'დროებითი' },
    { id: String(apiGlobalTypes_1.FileDownloadStatus.TRANSFERRING), label: 'გადაცემის პროცესში' }
];
