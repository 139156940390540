"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.authHistoriesReducer = exports.useAuthHistoriesReducer = exports.initialAuthHistoriesState = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
exports.initialAuthHistoriesState = {
    data: [],
    status: null,
    meta: store_1.initialMeta,
    filterFormValue: {},
    errors: null,
    isFetching: true
};
const useAuthHistoriesReducer = () => {
    return (0, react_1.useReducer)(exports.authHistoriesReducer, exports.initialAuthHistoriesState);
};
exports.useAuthHistoriesReducer = useAuthHistoriesReducer;
const authHistoriesReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_AUTH_HISTORIES_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_AUTH_HISTORIES_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                status: action.payload.status,
                errors: action.payload.errors,
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'INITIALIZE_FILTER_FORM':
            return {
                ...state,
                filterFormValue: action.payload
            };
        default:
            return state;
    }
};
exports.authHistoriesReducer = authHistoriesReducer;
