"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PositionTypesReducer = exports.usePositionTypesReducer = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
const initialPositionTypes = {
    data: [],
    status: null,
    errors: null,
    meta: store_1.initialMeta,
    isFetching: true,
    isDeleting: false
};
const usePositionTypesReducer = () => {
    return (0, react_1.useReducer)(exports.PositionTypesReducer, initialPositionTypes);
};
exports.usePositionTypesReducer = usePositionTypesReducer;
const PositionTypesReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_POSITION_TYPES_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_POSITION_TYPES_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                status: action.payload.status,
                errors: action.payload.errors,
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'SEND_DELETE_POSITION_TYPES_REQUEST':
            return {
                ...state,
                isDeleting: true
            };
        case 'DONE_DELETE_POSITION_TYPES_REQUEST':
            return {
                ...state,
                errors: action.payload.errors,
                isDeleting: false
            };
        case 'CHANGE_PER_PAGE':
            return {
                ...state,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        perPage: action.payload
                    }
                }
            };
        default:
            return state;
    }
};
exports.PositionTypesReducer = PositionTypesReducer;
