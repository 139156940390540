"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseDistrictsForSelect = exports.parseDistrict = exports.parseDistricts = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseDistricts = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const districts = response.content;
    try {
        return {
            data: districts.data.map(el => {
                return {
                    id: el.id,
                    nameKa: el.attributes.nameKa,
                    nameEn: el.attributes.nameEn,
                    region: el.relationships.region.data.attributes.nameKa
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(districts.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseDistricts = parseDistricts;
const parseDistrict = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const district = response.content;
    try {
        const { attributes } = district.data.relationships.region.data;
        const regionName = attributes.nameKa ?? attributes.nameEn;
        return {
            data: {
                id: district.data.id,
                nameKa: district.data.attributes.nameKa,
                nameEn: district.data.attributes.nameEn,
                region: {
                    id: district.data.relationships.region.data.id,
                    label: regionName
                }
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseDistrict = parseDistrict;
const parseDistrictsForSelect = (response) => {
    if (response.content === null)
        return [];
    const districts = response.content;
    try {
        return districts.data.map(el => {
            return {
                id: el.id,
                label: el.attributes.nameKa
            };
        });
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return [];
    }
};
exports.parseDistrictsForSelect = parseDistrictsForSelect;
