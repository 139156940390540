"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAssignSubmissionForm = void 0;
const useCreateOrUpdateAssignSubmission_1 = require("../store/useCreateOrUpdateAssignSubmission");
const index_1 = require("api/index");
const store_1 = require("core/store");
const queryString_1 = require("core/helpers/queryString");
const helpers_1 = require("../helpers");
const react_router_dom_1 = require("react-router-dom");
const react_1 = require("react");
const useAssignSubmissionForm = (props) => {
    const [state, dispatch] = (0, useCreateOrUpdateAssignSubmission_1.useHRAssignSubmissionReducer)();
    const [showSweetAlert, setShowSweetAlert] = (0, react_1.useState)(false);
    const [showSendButton, setShowSendButton] = (0, react_1.useState)(false);
    const [addressees, setAddressees] = (0, react_1.useState)([]);
    const [loadingAddressees, setIsLoadingAddressees] = (0, react_1.useState)(false);
    const [isExportingWord, setIsExportingWord] = (0, react_1.useState)(false);
    const [isExportingExcel, setIsExportingExcel] = (0, react_1.useState)(false);
    const [showDeclineMessage, setShowDeclineMessage] = (0, react_1.useState)(false);
    const [showProgressInVerifyAlert, setShowProgressInVerifyAlert] = (0, react_1.useState)(false);
    const [showDeleteFileAlert, setShowDeleteFileAlert] = (0, react_1.useState)(null);
    const [deletingFiles, setDeletingFiles] = (0, react_1.useState)([]);
    const [listingParams, setListingParams] = (0, react_1.useState)('');
    const [buttonNavigate, setButtonNavigate] = (0, react_1.useState)(useCreateOrUpdateAssignSubmission_1.initialAssignSubmissionNavigate);
    const [assignSubmissionParamsIds, setAssignSubmissionParamsIds] = (0, react_1.useState)({ assignSubmissionId: [], meta: store_1.initialMeta });
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const createHRAssignSubmission = async (values) => {
        const params = (0, helpers_1.transformHRAssignSubmissionParams)(values);
        dispatch({ type: 'SEND_CREATE_HR_SUBMISSION' });
        const submissionData = await index_1.HumanResourcesSubmissionsApi.createHRAssignSubmission(params);
        dispatch({ type: 'DONE_CREATE_HR_SUBMISSION', payload: submissionData });
        if (submissionData.data !== null) {
            navigate(`/documents/human-resources/assign-submissions/${submissionData.data.id}/edit`);
        }
        return submissionData;
    };
    const deleteAssignSubmissionFile = async (id) => {
        setDeletingFiles(prev => [...prev, id]);
        const submissionFile = await index_1.HumanResourcesSubmissionsApi.deleteAssignSubmissionFile(props.id, { fileUids: [id] });
        setDeletingFiles(prev => prev.filter(el => el !== id));
        return { errors: submissionFile.errors, status: submissionFile.status };
    };
    const declineItem = async (declineComment) => {
        setShowDeclineMessage(false);
        dispatch({ type: 'SEND_HR_SUBMISSION_DECLINE_REQUEST' });
        const assignSubmission = await index_1.HumanResourcesSubmissionsApi.declineItem(props.id, { declineComment: declineComment });
        dispatch({ type: 'DONE_HR_SUBMISSION_DECLINE_REQUEST', payload: assignSubmission });
        return assignSubmission;
    };
    const sendProgressInVerify = async (id) => {
        dispatch({ type: 'SEND_PROGRESS_IN_VERIFY_REQUEST' });
        const assignSubmission = await index_1.HumanResourcesSubmissionsApi.sendProgressInVerify(id);
        dispatch({ type: 'DONE_PROGRESS_IN_VERIFY_REQUEST', payload: assignSubmission });
        return assignSubmission;
    };
    const getSubmission = async (id) => {
        dispatch({ type: 'SEND_HR_SUBMISSION_REQUEST' });
        const submissionData = await index_1.HumanResourcesSubmissionsApi.getHRAssignSubmission(id);
        dispatch({ type: 'DONE_HR_SUBMISSION_REQUEST', payload: submissionData });
    };
    const updateHRAssignSubmission = async (values, id) => {
        const params = (0, helpers_1.transformHRAssignSubmissionParams)(values);
        dispatch({ type: 'SEND_HR_SUBMISSION_UPDATE' });
        const submissionData = await index_1.HumanResourcesSubmissionsApi.updateHRAssignSubmission(id, params);
        dispatch({ type: 'DONE_HR_SUBMISSION_UPDATE', payload: submissionData });
        return submissionData;
    };
    const sendAssignSubmission = async (id) => {
        dispatch({ type: 'SEND_HR_SUBMISSION_SEND_REQUEST' });
        const submissionData = await index_1.HumanResourcesSubmissionsApi.sendToChancellery(id);
        dispatch({ type: 'DONE_HR_SUBMISSION_SEND_REQUEST', payload: submissionData });
        return submissionData;
    };
    const fileDownload = async (type, id) => {
        if (type === 'word')
            setIsExportingWord(true);
        if (type === 'excel')
            setIsExportingExcel(true);
        const file = await index_1.HumanResourcesSubmissionsApi.getAssignSubmissionFiles({ type }, id);
        if (file)
            open(file.data?.link);
        if (type === 'word')
            setIsExportingWord(false);
        if (type === 'excel')
            setIsExportingExcel(false);
        return file;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    const getAddresseesAndPosition = async () => {
        setIsLoadingAddressees(true);
        const data = await index_1.HumanResourcesSubmissionsApi.getAddresseesAndPosition();
        setAddressees(data);
        setIsLoadingAddressees(false);
    };
    const getAssignSubmissionIds = async (value) => {
        const assignSubmissionsParams = (0, helpers_1.transformAssignSubmissionParamsToFilterParams)({ ...value });
        const assignSubmissions = await index_1.HumanResourcesSubmissionsApi.getHumanResourceSubmissions(assignSubmissionsParams);
        if (assignSubmissions.data !== null && assignSubmissions.meta) {
            const responseIds = {
                assignSubmissionId: assignSubmissions.data.map(el => el.id), meta: assignSubmissions.meta
            };
            const searchParams = (0, queryString_1.stringify)({ ...(0, helpers_1.transformAssignSubmissionFilterParamsToUrl)(value) });
            setListingParams(searchParams || '?');
            setAssignSubmissionParamsIds(responseIds);
            return responseIds;
        }
        return null;
    };
    const nextAssignSubmission = async () => {
        setButtonNavigate(prevState => ({ ...prevState, next: true, isLoading: true }));
        const currentIndex = assignSubmissionParamsIds.assignSubmissionId.indexOf(props.id);
        const nextAssignSubmission = assignSubmissionParamsIds.assignSubmissionId[currentIndex + 1];
        const { pagination } = assignSubmissionParamsIds.meta;
        const params = (0, helpers_1.transformAssignSubmissionFilterParamsToUrl)((0, helpers_1.transformUrlToAssignSubmissionFilterParams)(location.search));
        if (nextAssignSubmission) {
            navigate('/documents/human-resources/assign-submissions/' + nextAssignSubmission + '/edit' + (0, queryString_1.stringify)({ ...params }));
            const lastElement = pagination.currentPage === pagination.totalPages && assignSubmissionParamsIds.assignSubmissionId.length === currentIndex + 2;
            setButtonNavigate(prevState => ({ ...prevState, next: false, firstElement: false, lastElement, isLoading: false }));
        }
        else {
            if (pagination.currentPage === pagination.totalPages) {
                setButtonNavigate(prevState => ({ ...prevState, next: false, lastElement: true, isLoading: false }));
                return;
            }
            const formValues = (0, helpers_1.transformUrlToAssignSubmissionFilterParams)(location.search);
            const response = await getAssignSubmissionIds({ ...formValues, page: pagination.currentPage + 1 });
            if (response && response.assignSubmissionId[0]) {
                formValues.page = response.meta.pagination.currentPage;
                const urlParams = { ...(0, helpers_1.transformAssignSubmissionFilterParamsToUrl)(formValues), page: response.meta.pagination.currentPage };
                navigate('/documents/human-resources/assign-submissions/' + response.assignSubmissionId[0] + '/edit' + (0, queryString_1.stringify)(urlParams));
                const lastElement = response.meta.pagination.currentPage === response.meta.pagination.totalPages && response.assignSubmissionId.length === 1;
                setButtonNavigate(prevState => ({ ...prevState, next: false, firstElement: false, lastElement, isLoading: false }));
            }
        }
    };
    const previousAssignSubmission = async () => {
        setButtonNavigate(prevState => ({ ...prevState, previous: true, isLoading: true }));
        const currentIndex = assignSubmissionParamsIds.assignSubmissionId.indexOf(props.id);
        const previousAssignSubmission = assignSubmissionParamsIds.assignSubmissionId[currentIndex - 1];
        const { pagination } = assignSubmissionParamsIds.meta;
        const params = (0, helpers_1.transformAssignSubmissionFilterParamsToUrl)((0, helpers_1.transformUrlToAssignSubmissionFilterParams)(location.search));
        if (previousAssignSubmission) {
            navigate('/documents/human-resources/assign-submissions/' + previousAssignSubmission + '/edit' + (0, queryString_1.stringify)({ ...params }));
            const firstElement = pagination.currentPage === 1 && currentIndex === 1;
            setButtonNavigate(prevState => ({ ...prevState, previous: false, lastElement: false, firstElement, isLoading: false }));
        }
        else {
            if (pagination.currentPage === 1) {
                setButtonNavigate(prevState => ({ ...prevState, previous: false, firstElement: true, isLoading: false }));
                return;
            }
            const formValues = (0, helpers_1.transformUrlToAssignSubmissionFilterParams)(location.search);
            const response = await getAssignSubmissionIds({ ...formValues, page: pagination.currentPage - 1 });
            if (response) {
                const prevAssignSubmissions = response.assignSubmissionId[response.assignSubmissionId.length - 1];
                if (prevAssignSubmissions) {
                    formValues.page = response.meta.pagination.currentPage;
                    const urlParams = { ...(0, helpers_1.transformAssignSubmissionFilterParamsToUrl)(formValues), page: response.meta.pagination.currentPage };
                    navigate('/documents/human-resources/assign-submissions/' + prevAssignSubmissions + '/edit' + (0, queryString_1.stringify)(urlParams));
                    const lastElement = response.meta.pagination.currentPage === response.meta.pagination.totalPages && response.assignSubmissionId.length === 1;
                    setButtonNavigate(prevState => ({ ...prevState, previous: false, firstElement: false, lastElement, isLoading: false }));
                }
            }
        }
    };
    (0, react_1.useEffect)(() => {
        const params = (0, helpers_1.transformUrlToAssignSubmissionFilterParams)(location.search);
        getAssignSubmissionIds(params).then(response => {
            if (response) {
                const { currentPage, totalPages } = response.meta.pagination;
                const assignSubmissionIndex = response.assignSubmissionId.indexOf(props.id);
                if (assignSubmissionIndex === 0 && currentPage === 1)
                    setButtonNavigate(prevState => ({ ...prevState, firstElement: true }));
                if (currentPage === totalPages && assignSubmissionIndex === response.assignSubmissionId.length - 1) {
                    setButtonNavigate(prevState => ({ ...prevState, lastElement: true }));
                }
            }
        });
    }, []);
    (0, react_1.useEffect)(() => {
        if (props.id) {
            getSubmission(props.id).then();
        }
    }, [props.id]);
    return {
        createHRAssignSubmission,
        updateHRAssignSubmission,
        state,
        setShowSweetAlert,
        showSweetAlert,
        sendAssignSubmission,
        fileDownload,
        closeErrorMessage,
        showSendButton,
        setShowSendButton,
        addressees,
        getAddresseesAndPosition,
        loadingAddressees,
        setAddressees,
        isExportingWord,
        isExportingExcel,
        setShowDeclineMessage,
        showDeclineMessage,
        declineItem,
        setShowProgressInVerifyAlert,
        showProgressInVerifyAlert,
        sendProgressInVerify,
        deleteAssignSubmissionFile,
        deletingFiles,
        setShowDeleteFileAlert,
        listingParams,
        showDeleteFileAlert,
        buttonNavigate,
        nextAssignSubmission,
        previousAssignSubmission
    };
};
exports.useAssignSubmissionForm = useAssignSubmissionForm;
