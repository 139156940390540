"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformRolePermissionFilterParamsToUrl = exports.transformRolePermissionParamsToFilterParams = exports.transformUrlToRolePermissionFilterParams = exports.getParamsFromUrl = exports.transformRoleParams = exports.parseAttachedRolePermissionParams = exports.parseRolePermissionParams = void 0;
const queryString_1 = require("core/helpers/queryString");
const parseRolePermissionParams = (values) => {
    const permissionIds = [];
    values.forEach(group => {
        group.groupPermissions.forEach(el => {
            if (el.checked)
                permissionIds.push(el.id);
        });
    });
    return { permissions: permissionIds };
};
exports.parseRolePermissionParams = parseRolePermissionParams;
const parseAttachedRolePermissionParams = (values) => {
    const roleIds = [];
    values.forEach(group => {
        group.groupPermissions.forEach(el => {
            if (el.checked)
                roleIds.push(el.id);
        });
    });
    return { roles: roleIds };
};
exports.parseAttachedRolePermissionParams = parseAttachedRolePermissionParams;
const transformRoleParams = (params) => {
    return {
        title: params.title,
        name: params.name
    };
};
exports.transformRoleParams = transformRoleParams;
const getParamsFromUrl = (url) => {
    try {
        const params = (0, queryString_1.parse)(url);
        return {
            page: params.page,
            perPage: params.perPage,
            filters: params.filters?.keyword ? { keyword: params.filters.keyword } : undefined
        };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.getParamsFromUrl = getParamsFromUrl;
const transformUrlToRolePermissionFilterParams = (url) => {
    const values = (0, queryString_1.parse)(url);
    return {
        keyword: values.keyword,
        page: values.page,
        perPage: values.perPage,
        structuralUnit: values.structuralUnitId && values.structuralUnitLabel ? { id: values.structuralUnitId, label: values.structuralUnitLabel } : undefined
    };
};
exports.transformUrlToRolePermissionFilterParams = transformUrlToRolePermissionFilterParams;
const transformRolePermissionParamsToFilterParams = (params) => {
    const filters = {};
    const paginate = {};
    const filterParams = {
        keyword: params.keyword,
        structuralUnitId: params.structuralUnit?.id,
        page: params.page,
        perPage: params.perPage
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if (value && (key !== 'page' && key !== 'perPage'))
                filters[key] = value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(filters).length === 0)
            return { ...paginate };
        return { ...paginate, filters };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformRolePermissionParamsToFilterParams = transformRolePermissionParamsToFilterParams;
const transformRolePermissionFilterParamsToUrl = (values) => {
    const { page, perPage, keyword, structuralUnit } = values;
    return {
        page,
        perPage,
        keyword,
        structuralUnitId: structuralUnit?.id,
        structuralUnitLabel: structuralUnit?.label
    };
};
exports.transformRolePermissionFilterParamsToUrl = transformRolePermissionFilterParamsToUrl;
