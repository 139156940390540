"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SuggestedApprovalsReducer = exports.useSuggestedApprovalsReducer = void 0;
const react_1 = require("react");
const initialSuggestedApprovals = {
    data: [],
    addingApproversIds: [],
    approvalsIds: [],
    errors: null,
    isFetching: true
};
const useSuggestedApprovalsReducer = () => {
    return (0, react_1.useReducer)(exports.SuggestedApprovalsReducer, initialSuggestedApprovals);
};
exports.useSuggestedApprovalsReducer = useSuggestedApprovalsReducer;
const SuggestedApprovalsReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_SUGGESTED_APPROVALS_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_SUGGESTED_APPROVALS_REQUEST':
            return {
                ...state,
                data: action.payload.data?.filter(el => !state.approvalsIds.includes(el.id)) || state.data,
                errors: action.payload.errors,
                isFetching: true
            };
        case 'SEND_ADD_APPROVALS_REQUEST':
            return {
                ...state,
                addingApproversIds: [...state.addingApproversIds, action.payload]
            };
        case 'DONE_ADD_APPROVALS_REQUEST':
            return {
                ...state,
                addingApproversIds: state.addingApproversIds.filter(el => el !== action.payload)
            };
        case 'FILTER_APPROVALS':
            return {
                ...state,
                approvalsIds: action.payload,
                data: state.data.filter(el => !action.payload.includes(el.id))
            };
        default:
            return state;
    }
};
exports.SuggestedApprovalsReducer = SuggestedApprovalsReducer;
