"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserContractFileStatusOptions = exports.UserSystemStatusOptions = void 0;
const types_1 = require("api/references/employees/types");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
exports.UserSystemStatusOptions = [
    { id: String(apiGlobalTypes_1.SystemStatus.PASSIVE), label: 'პასიური' },
    { id: String(apiGlobalTypes_1.SystemStatus.ACTIVE), label: 'აქტიური' }
];
exports.UserContractFileStatusOptions = [
    { id: String(types_1.ContractAndAnnexStatuses.DRAFT), label: 'პროექტი' },
    { id: String(types_1.ContractAndAnnexStatuses.PENDING), label: 'გასაგზავნი' },
    { id: String(types_1.ContractAndAnnexStatuses.SENT), label: 'გაგზავნილი' },
    { id: String(types_1.ContractAndAnnexStatuses.SIGNED), label: 'ხელმოწერილი' }
];
