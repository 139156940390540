"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserFormValidation = void 0;
const validates_1 = require("core/helpers/validates");
const UserFormValidation = (values) => {
    const formErrors = {
        firstname: (0, validates_1.required)(values.firstname),
        lastname: (0, validates_1.required)(values.lastname),
        // passportNumber: required(values.passportNumber),
        status: (0, validates_1.required)(values.status)
        // email: required(values.email)
    };
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.UserFormValidation = UserFormValidation;
