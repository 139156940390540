"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const administrativeOrders_1 = require("../store/administrativeOrders");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helpers_1 = require("../helpers");
const queryString_1 = require("core/helpers/queryString");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useAdministrativeOrders = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, administrativeOrders_1.useAdministrativeOrdersReducer)();
    const [number, setNumber] = (0, react_1.useState)('');
    const [showDeleteAlert, setShowDeleteAlert] = (0, react_1.useState)(false);
    const [toDeleteId, setToDeleteId] = (0, react_1.useState)(null);
    const isMounted = (0, react_1.useRef)(false);
    const navigatePagination = (page) => {
        const params = (0, queryString_1.parse)(location.search);
        getAdministrativeOrders({ ...params, page }).then();
    };
    const selectPerPage = (perPage) => {
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'CHANGE_PER_PAGE', payload: perPage });
        getAdministrativeOrders({ ...params, perPage, page: undefined }).then();
    };
    const deleteAlert = (id) => {
        setShowDeleteAlert(true);
        setToDeleteId(id);
    };
    const debouncedSearch = (value) => {
        setNumber(value);
        const searchParams = (0, queryString_1.parse)(location.search);
        const params = value
            ? { perPage: searchParams.perPage, filters: { number: value } }
            : { page: searchParams.page };
        (0, debouncedMethods_1.debouncedOnFunc)(params, getAdministrativeOrders);
    };
    const deleteAdministrativeOrders = async (confirm) => {
        setShowDeleteAlert(false);
        if (!toDeleteId || !confirm)
            return setToDeleteId(null);
        dispatch({ type: 'SEND_ADMINISTRATIVE_ORDERS_DELETE_REQUEST' });
        const administrativeOrders = await index_1.AdministrativeOrdersApi.deleteAdministrativeOrder(toDeleteId);
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'DONE_ADMINISTRATIVE_ORDERS_DELETE_REQUEST', payload: { errors: administrativeOrders.errors } });
        if (administrativeOrders.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            getAdministrativeOrders(params).then();
        setToDeleteId(null);
    };
    const getAdministrativeOrders = async (params) => {
        dispatch({ type: 'SEND_ADMINISTRATIVE_ORDERS_REQUEST' });
        const administrativeOrders = await index_1.AdministrativeOrdersApi.getAdministrativeOrders(params);
        if (isMounted?.current) {
            navigate('/documents/administrative-orders' + (0, queryString_1.stringify)(params), { replace: true });
            dispatch({ type: 'DONE_ADMINISTRATIVE_ORDERS_REQUEST', payload: administrativeOrders });
        }
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        const params = (0, helpers_1.getParamsFromUrl)(location.search);
        if (params.perPage)
            dispatch({ type: 'CHANGE_PER_PAGE', payload: params.perPage });
        setNumber((params.filters?.publicationDate, params.filters?.number, params.filters?.createdBy) || '');
        getAdministrativeOrders(params).then();
        return () => { isMounted.current = false; };
    }, []);
    return {
        state,
        getAdministrativeOrders,
        navigatePagination,
        selectPerPage,
        deleteAlert,
        debouncedSearch,
        deleteAdministrativeOrders,
        toDeleteId,
        showDeleteAlert,
        number
    };
};
exports.default = useAdministrativeOrders;
