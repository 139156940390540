"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAssignSubmissionVerifyItemsReducer = exports.initialHRAssignSubmissionVerifyEmployee = exports.initialVerifyPositionInfo = exports.initialVerifyProjectInfo = void 0;
const types_1 = require("api/documents/human-resources/assign-submissions/types");
const react_1 = require("react");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const useCreateOrUpdateItems_1 = require("../../assign-submissions/store/useCreateOrUpdateItems");
const store_1 = require("core/store");
const initialAssignSubmissionVerifyItemsState = {
    data: [],
    meta: store_1.initialMeta,
    errors: null,
    isFetching: false,
    isUpdatingItem: false,
    isLoading: false,
    isUpdatingItemsList: false,
    isDecliningItem: false,
    isSuspendingItem: false,
    isConfirmingItem: false,
    isVerifyingAtPaymentStage: false,
    isFetched: false
};
exports.initialVerifyProjectInfo = {
    ...useCreateOrUpdateItems_1.initialProjectInfo,
    budgetingIsSent: false,
    status: types_1.AssignSubmissionVerifyItemStatuses.DRAFT,
    creatorVisualStatus: { name: 'პროექტი', color: 'secondary' },
    budgetingVisualStatus: { name: 'პროექტი', color: 'secondary' },
    HRHeadVisualStatus: { name: 'პროექტი', color: 'secondary' },
    budgetingStatus: null,
    hrHeadStatus: null,
    suspendComment: null,
    declineComment: null,
    creatorStatusMessage: '',
    budgetingStatusMessage: '',
    HRHeadStatusMessage: ''
};
exports.initialVerifyPositionInfo = {
    ...useCreateOrUpdateItems_1.initialPositionInfo,
    assignType: null,
    employmentContractType: null,
    contractContinuationType: null,
    projectInfos: [exports.initialVerifyProjectInfo]
};
exports.initialHRAssignSubmissionVerifyEmployee = {
    ...useCreateOrUpdateItems_1.initialEmployee,
    assignSubmissionItemId: null,
    idCardValidityDate: null,
    passportValidityDate: null,
    workEmail: null,
    // residenceCertificateDate: null,
    residenceCertificateNumber: null,
    residencyValidityDate: null,
    resident: true,
    country: null,
    useOfTaxRelief: false,
    taxReliefBenefit: apiGlobalTypes_1.YesNoStatus.NO,
    taxReliefId: null,
    taxReliefAmount: null,
    status: types_1.AssignSubmissionVerifyItemStatuses.DRAFT,
    assignSubmission: null,
    positionInfos: [exports.initialVerifyPositionInfo]
};
const useAssignSubmissionVerifyItemsReducer = () => {
    return (0, react_1.useReducer)(HRAssignSubmissionVerifyItemsReducer, initialAssignSubmissionVerifyItemsState);
};
exports.useAssignSubmissionVerifyItemsReducer = useAssignSubmissionVerifyItemsReducer;
const HRAssignSubmissionVerifyItemsReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_HR_SUBMISSION_VERIFY_ITEMS_REQUEST':
            return {
                ...state,
                isLoading: true
            };
        case 'DONE_HR_SUBMISSION_VERIFY_ITEMS_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                meta: action.payload.meta || state.meta,
                errors: action.payload.errors,
                isFetching: false,
                isFetched: true,
                isLoading: state.isUpdatingItemsList
                    || state.isDecliningItem
                    || state.isSuspendingItem
                    || state.isConfirmingItem
                    || state.isVerifyingAtPaymentStage
            };
        case 'UPDATE_STATE_ITEMS':
            return {
                ...state,
                data: action.payload
            };
        case 'SEND_ITEMS_LIST_UPDATE_REQUEST':
            return {
                ...state,
                errors: null,
                isLoading: true,
                isUpdatingItemsList: true
            };
        case 'DONE_ITEMS_LIST_UPDATE_REQUEST':
            return {
                ...state,
                data: action.payload.assignSubmissionVerifyItems.data || state.data,
                isUpdatingItemsList: false,
                isLoading: state.isFetching
                    || state.isDecliningItem
                    || state.isSuspendingItem
                    || state.isVerifyingAtPaymentStage
                    || state.isConfirmingItem,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        total: state.meta.pagination.total + action.payload.newItemsLength,
                        count: state.meta.pagination.count + action.payload.newItemsLength
                    }
                }
            };
        case 'SEND_ITEM_UPDATE_REQUEST':
            return {
                ...state,
                errors: null,
                isUpdatingItem: true
            };
        case 'DONE_ITEM_UPDATE_REQUEST':
            return {
                ...state,
                data: action.payload.item ? state.data.map((el, index) => {
                    if (index === action.payload.index) {
                        return action.payload.item || el;
                    }
                    else {
                        return el;
                    }
                }) : state.data,
                isUpdatingItem: false
            };
        case 'DONE_ITEM_CREATE_REQUEST':
            return {
                ...state,
                data: action.payload.item ? insertItemInData(state.data, action.payload.item, action.payload.index) : state.data,
                isUpdatingItem: false
            };
        case 'SEND_HR_SUBMISSION_VERIFY_DECLINE_ITEMS':
            return {
                ...state,
                isDecliningItem: true,
                isLoading: true
            };
        case 'DONE_HR_SUBMISSION_VERIFY_DECLINE_ITEMS':
            return {
                ...state,
                data: action.payload.projectData.data && state.data.map(el => transformPositionFormItem(el, action.payload.projectData.data)) || state.data,
                isDecliningItem: false,
                isLoading: state.isUpdatingItemsList
                    || state.isFetching
                    || state.isSuspendingItem
                    || state.isConfirmingItem
                    || state.isVerifyingAtPaymentStage
            };
        case 'SEND_HR_SUBMISSION_VERIFY_SUSPEND_ITEMS':
            return {
                ...state,
                isSuspendingItem: true,
                isLoading: true
            };
        case 'DONE_HR_SUBMISSION_VERIFY_SUSPEND_ITEMS':
            return {
                ...state,
                data: action.payload.projectData.data && state.data.map(el => transformPositionFormItem(el, action.payload.projectData.data)) || state.data,
                isSuspendingItem: false,
                isLoading: state.isUpdatingItemsList
                    || state.isFetching
                    || state.isDecliningItem
                    || state.isConfirmingItem
                    || state.isVerifyingAtPaymentStage
            };
        case 'SEND_HR_SUBMISSION_VERIFY_CONFIRM_ITEMS':
            return {
                ...state,
                isConfirmingItem: true,
                isLoading: true
            };
        case 'DONE_HR_SUBMISSION_VERIFY_CONFIRM_ITEMS':
            return {
                ...state,
                data: action.payload.projectData.data && state.data.map(el => transformPositionFormItem(el, action.payload.projectData.data)) || state.data,
                isConfirmingItem: false,
                isLoading: state.isUpdatingItemsList
                    || state.isFetching
                    || state.isDecliningItem
                    || state.isSuspendingItem
                    || state.isVerifyingAtPaymentStage
            };
        case 'SEND_HR_SUBMISSION_VERIFY_AT_PAYMENT_STAGE_ITEMS':
            return {
                ...state,
                isVerifyingAtPaymentStage: true,
                isLoading: true
            };
        case 'DONE_HR_SUBMISSION_VERIFY_AT_PAYMENT_STAGE_ITEMS':
            return {
                ...state,
                data: action.payload.projectData.data && state.data.map(el => transformPositionFormItem(el, action.payload.projectData.data)) || state.data,
                isVerifyingAtPaymentStage: false,
                isLoading: state.isUpdatingItemsList
                    || state.isFetching
                    || state.isDecliningItem
                    || state.isSuspendingItem
                    || state.isConfirmingItem
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        case 'CHANGE_PER_PAGE':
            return {
                ...state,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        perPage: action.payload
                    }
                }
            };
    }
};
const transformPositionFormItem = (el, payloadData) => {
    return {
        ...el,
        positionInfos: el.positionInfos.map(positionInfo => {
            return {
                ...positionInfo,
                projectInfos: positionInfo.projectInfos
                    .map(projectInfo => {
                    return payloadData.find(payloadProjectInfo => projectInfo.id === payloadProjectInfo.id) || projectInfo;
                })
            };
        })
    };
};
const insertItemInData = (data, item, index) => {
    const newData = [...data];
    newData[index] = item;
    return newData;
};
