"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLegalBasicsForSelect = exports.updateLegalBasis = exports.createLegalBasis = exports.getLegalBasis = exports.deleteItem = exports.getLegalBasics = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("../../privateRequest"));
const parsers_1 = require("api/references/legalBasics/parsers");
const react_toastify_1 = require("react-toastify");
const getLegalBasics = async (params) => {
    const response = await Api.get('references/legal-basis', params);
    return (0, parsers_1.parseLegalBasics)(response);
};
exports.getLegalBasics = getLegalBasics;
const deleteItem = async (id) => {
    return await Api.deleteItem(`references/legal-basis/${id}`);
};
exports.deleteItem = deleteItem;
const getLegalBasis = async (id) => {
    const response = await Api.get('references/legal-basis/' + id);
    return (0, parsers_1.parseLegalBasis)(response);
};
exports.getLegalBasis = getLegalBasis;
const createLegalBasis = async (params) => {
    const response = await Api.post('references/legal-basis', params);
    return (0, parsers_1.parseLegalBasis)(response);
};
exports.createLegalBasis = createLegalBasis;
const updateLegalBasis = async (id, params) => {
    const response = await Api.patch('references/legal-basis/' + id, params);
    return (0, parsers_1.parseLegalBasis)(response);
};
exports.updateLegalBasis = updateLegalBasis;
const getLegalBasicsForSelect = async (params) => {
    const response = await Api.get('references/legal-basis', params);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, parsers_1.parseLegalBasicsForSelect)(response);
};
exports.getLegalBasicsForSelect = getLegalBasicsForSelect;
