"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const fundingSource_1 = require("../store/fundingSource");
const index_1 = require("api/index");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useFundingSource = (props) => {
    const [state, dispatch] = (0, fundingSource_1.useFundingSourceReducer)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const getFundingSource = async (id) => {
        dispatch({ type: 'SEND_FUNDING_SOURCE_REQUEST' });
        const fundingSource = await index_1.FundingSourcesApi.getFundingSource(id);
        dispatch({ type: 'DONE_FUNDING_SOURCE_REQUEST', payload: fundingSource });
    };
    const createFundingSource = async (values) => {
        const params = (0, helpers_1.transformFundingSourceParams)(values);
        dispatch({ type: 'SEND_FUNDING_SOURCE_CREATE' });
        const fundingSource = await index_1.FundingSourcesApi.createFundingSource(params);
        dispatch({ type: 'DONE_FUNDING_SOURCE_CREATE', payload: fundingSource });
        if (fundingSource.data !== null) {
            navigate(`/references/funding/funding-sources/${fundingSource.data.id}/edit`);
        }
        return fundingSource;
    };
    const updateFundingSource = async (id, values) => {
        const params = (0, helpers_1.transformFundingSourceParams)(values);
        dispatch({ type: 'SEND_FUNDING_SOURCE_UPDATE' });
        const fundingSource = await index_1.FundingSourcesApi.updateFundingSource(id, params);
        dispatch({ type: 'DONE_FUNDING_SOURCE_UPDATE', payload: fundingSource });
        return fundingSource;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getFundingSource(props.id).then(); }, [props.id]);
    return { state, createFundingSource, updateFundingSource, getFundingSource, closeErrorMessage };
};
exports.default = useFundingSource;
