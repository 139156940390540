"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformUpdateProcurementCorrectionParams = exports.transformCreateProcurementCorrectionFromSubmissionParams = exports.transformCreateProcurementCorrectionParams = exports.transformCreateCorrectionParams = void 0;
const types_1 = require("api/documents/purchases/procurementCorrections/types");
const types_2 = require("api/documents/purchases/procurements/types");
const parsers_1 = require("api/parsers/parsers");
const transformCreateCorrectionParams = (params, type) => {
    if (type === types_1.ProcurementCorrectionType.LETTER) {
        return (0, exports.transformCreateProcurementCorrectionParams)(params, types_1.ProcurementCorrectionType.LETTER);
    }
    else {
        return (0, exports.transformCreateProcurementCorrectionFromSubmissionParams)(params, types_1.ProcurementCorrectionType.SUBMISSION);
    }
};
exports.transformCreateCorrectionParams = transformCreateCorrectionParams;
const transformCreateProcurementCorrectionParams = (params, type) => {
    return {
        type,
        letterNumber: params.letterNumber,
        reasons: params.reasons?.map(el => Number(el.id)) || [],
        letterDate: (0, parsers_1.transformDateParams)(params.letterDate),
        fileUids: params.letterOrSubmissionFiles?.map(file => file.id)
    };
};
exports.transformCreateProcurementCorrectionParams = transformCreateProcurementCorrectionParams;
const transformCreateProcurementCorrectionFromSubmissionParams = (params, type) => {
    const submissions = [];
    params.submissions.forEach(submission => {
        if (submission.submissionId) {
            const submissionItems = submission.submissionItems ? submission.submissionItems : [];
            const chosenItems = submission.chosenItems ? submission.chosenItems : [];
            const allItems = [...submissionItems, ...chosenItems];
            allItems?.forEach(item => {
                if (item.checked)
                    submissions.push({ submissionItemId: item.id });
            });
        }
        else {
            submissions.push({
                id: submission.id,
                submissionNumber: submission.submissionNumber,
                submissionAccountId: submission.submissionAuthorPosition.accountId,
                submissionRequestRegistration: (0, parsers_1.transformDateParams)(submission.submissionRequestRegistration),
                submissionDeadline: (0, parsers_1.transformDateParams)(submission.submissionDeadline),
                submissionExecution: (0, parsers_1.transformDateParams)(submission.submissionExecution),
                fileUids: submission.letterOrSubmissionFiles.map(file => file.id)
            });
        }
    });
    return {
        type,
        reasons: params.reasons?.map(el => Number(el.id)) || [],
        submissions
    };
};
exports.transformCreateProcurementCorrectionFromSubmissionParams = transformCreateProcurementCorrectionFromSubmissionParams;
const transformUpdateProcurementCorrectionParams = (params) => {
    const procurementFile = params.procurementFile && {
        uid: params.procurementFile.id,
        type: params.templateType,
        is_other: params.templateType === types_2.TemplateTypes.CONSOLIDATED ? false : params.isOther
    };
    const files = params.files.map(el => ({ uid: el.id, type: types_2.TemplateTypes.ADDITIONAL_FILES, is_other: false }));
    const attachedFiles = params.attachedFiles.map(el => ({ uid: el.id, type: types_2.TemplateTypes.SIGNATURE_DOCUMENT, is_other: false }));
    const attachedSignedFiles = params.attachedSignedFiles.map(el => ({ uid: el.id, type: types_2.TemplateTypes.SIGNED, is_other: false }));
    const fileUids = [...files, ...attachedFiles, ...attachedSignedFiles];
    if (procurementFile)
        fileUids.push(procurementFile);
    return {
        signedDocumentNumber: params.status === types_2.ProcurementStatuses.SIGN_PROGRESS ? params.signedDocumentNumber : undefined,
        dateOfConclusion: params.status === types_2.ProcurementStatuses.SIGN_PROGRESS ? (0, parsers_1.transformDateParams)(params.dateOfConclusion) : undefined,
        deliveryDate: (0, parsers_1.transformDateParams)(params.deliveryDate),
        contractValidityDate: (0, parsers_1.transformDateParams)(params.contractValidityDate),
        advanceGuaranteeValidityPeriod: (0, parsers_1.transformDateParams)(params.advanceGuaranteeValidityPeriod),
        goodsGuaranteeValidityPeriod: (0, parsers_1.transformDateParams)(params.goodsGuaranteeValidityPeriod),
        contractGuaranteeValidityPeriod: (0, parsers_1.transformDateParams)(params.contractGuaranteeValidityPeriod),
        typeId: params.type.id, // it must be presented by form validations
        companyId: params.company.id, // it must be presented by form validations
        companyAddressId: params.companyAddress.id, // it must be presented by form validations
        companyBankAccountId: params.companyBankAccount.id, // it must be presented by form validations
        companySignatoryId: params.companySignatory.id,
        templateType: params.templateType,
        template: params.content,
        fileUids
    };
};
exports.transformUpdateProcurementCorrectionParams = transformUpdateProcurementCorrectionParams;
