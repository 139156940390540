"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const types_1 = require("api/references/banks/types");
const index_1 = require("api/index");
const foreignBanks_1 = require("../store/foreignBanks");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const react_1 = require("react");
const useForeignBankModal = (props) => {
    const [state, dispatch] = (0, foreignBanks_1.useForeignBanksReducer)();
    const [keyword, setKeyword] = (0, react_1.useState)('');
    const getForeignBanks = async (params) => {
        dispatch({ type: 'SEND_FOREIGN_BANKS_REQUEST' });
        const foreignBanks = await index_1.BankApi.getBanks(params);
        if (foreignBanks.data !== null) {
            foreignBanks.data = foreignBanks.data.map(foreignBank => {
                return {
                    ...foreignBank,
                    isAdded: props.mediatorBankIds.includes(foreignBank.id)
                };
            });
        }
        dispatch({ type: 'DONE_FOREIGN_BANKS_REQUEST', payload: foreignBanks });
    };
    const addMediatorBank = (id) => {
        dispatch({ type: 'ADD_MEDIATOR_BANK', payload: id });
    };
    const selectPerPage = (perPage) => {
        dispatch({ type: 'CHANGE_PER_PAGE', payload: perPage });
        const params = {
            filters: { keyword, type: types_1.BANK_TYPE.TYPE_FOREIGN },
            page: state.meta.pagination.currentPage,
            perPage
        };
        getForeignBanks(params).then();
    };
    const debouncedSearch = (debouncedKeyword) => {
        setKeyword(debouncedKeyword);
        const pagination = state.meta.pagination;
        const params = {
            filters: { keyword: debouncedKeyword, type: types_1.BANK_TYPE.TYPE_FOREIGN },
            page: 1,
            perPage: pagination.perPage
        };
        (0, debouncedMethods_1.debouncedOnFunc)(params, getForeignBanks);
    };
    const navigatePagination = (page) => {
        const params = {
            filters: { keyword, type: types_1.BANK_TYPE.TYPE_FOREIGN },
            page,
            perPage: state.meta.pagination.perPage
        };
        getForeignBanks(params).then();
    };
    (0, react_1.useEffect)(() => { getForeignBanks({ filters: { type: types_1.BANK_TYPE.TYPE_FOREIGN } }).then(); }, []);
    return {
        state,
        selectPerPage,
        navigatePagination,
        debouncedSearch,
        keyword,
        addMediatorBank
    };
};
exports.default = useForeignBankModal;
