"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialCurrency = exports.initialMeta = void 0;
exports.initialMeta = {
    pagination: {
        count: 0,
        currentPage: 1,
        perPage: 25,
        total: 0,
        totalPages: 0
    }
};
exports.initialCurrency = { id: '1', label: 'GEL' };
