"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInvoiceItems = void 0;
const invoiceItems_1 = require("../store/invoiceItems");
const index_1 = require("api/index");
const react_1 = require("react");
const useInvoiceItems = (id) => {
    const [state, dispatch] = (0, invoiceItems_1.useInvoiceItemsReducer)();
    const getInvoiceItems = async (page) => {
        dispatch({ type: 'SEND_INVOICE_ITEMS_REQUEST' });
        const items = await index_1.InvoicesApi.getInvoiceItems(id, page);
        dispatch({ type: 'DONE_INVOICE_ITEMS_REQUEST', payload: items });
    };
    (0, react_1.useEffect)(() => {
        getInvoiceItems().then();
    }, []);
    return { state, getInvoiceItems };
};
exports.useInvoiceItems = useInvoiceItems;
