"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.companyBankAccountValidations = exports.companyAddressValidations = exports.companySignatoryValidations = exports.companyAttributesValidation = void 0;
const validates_1 = require("core/helpers/validates");
const companyAttributesValidation = (values) => {
    const formErrors = {
        identificationNumber: !values.foreignCompany ? (0, validates_1.required)(values.identificationNumber) : undefined,
        name: (0, validates_1.required)(values.name),
        legalStatus: (0, validates_1.required)(values.legalStatus)
    };
    const errors = {};
    Object.entries(formErrors).map(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.companyAttributesValidation = companyAttributesValidation;
const companySignatoryValidations = (values) => {
    const formErrors = {
        firstname: (0, validates_1.required)(values.firstname),
        lastname: (0, validates_1.required)(values.lastname)
    };
    const errors = {};
    Object.entries(formErrors).map(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.companySignatoryValidations = companySignatoryValidations;
const companyAddressValidations = (values) => {
    const formErrors = {
        address: (0, validates_1.required)(values.address),
        type: (0, validates_1.required)(values.type)
    };
    const errors = {};
    Object.entries(formErrors).map(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.companyAddressValidations = companyAddressValidations;
const companyBankAccountValidations = (values) => {
    const formErrors = {
        name: (0, validates_1.required)(values.name),
        code: (0, validates_1.required)(values.code),
        accountNumber: (0, validates_1.required)(values.accountNumber)
    };
    const errors = {};
    Object.entries(formErrors).map(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.companyBankAccountValidations = companyBankAccountValidations;
