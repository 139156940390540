"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const index_1 = require("api/index");
const suggestedApprovals_1 = require("../store/suggestedApprovals");
const react_1 = require("react");
const useSuggestedApprovals = (props) => {
    const [state, dispatch] = (0, suggestedApprovals_1.useSuggestedApprovalsReducer)();
    const isMounted = (0, react_1.useRef)(false);
    const getSuggestedApprovals = async () => {
        dispatch({ type: 'SEND_SUGGESTED_APPROVALS_REQUEST' });
        const procurement = await index_1.ProcurementsApi.getProjectsUsers(props.procurementId);
        if (isMounted?.current) {
            dispatch({ type: 'DONE_SUGGESTED_APPROVALS_REQUEST', payload: procurement });
        }
    };
    const addApproval = async (userId, accountId) => {
        dispatch({ type: 'SEND_ADD_APPROVALS_REQUEST', payload: userId });
        const response = await index_1.ProcurementsApi.addApprover(props.procurementCorrectionId || props.procurementId, { accountId });
        dispatch({ type: 'DONE_ADD_APPROVALS_REQUEST', payload: userId });
        if (response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS) {
            props.getProcurement(props.procurementId);
        }
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        getSuggestedApprovals().then();
        return () => { isMounted.current = false; };
    }, []);
    (0, react_1.useEffect)(() => {
        dispatch({ type: 'FILTER_APPROVALS', payload: props.approvals ? props.approvals.map(el => el.id) : [] });
    }, [props.approvals]);
    return { state, addApproval };
};
exports.default = useSuggestedApprovals;
