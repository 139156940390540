"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.structuralUnitsReducer = exports.useStructuralUnitsReducer = exports.initialStructuralUnitsState = void 0;
const react_1 = require("react");
const helpers_1 = require("../helpers");
exports.initialStructuralUnitsState = {
    data: [],
    loadingItems: [],
    errors: null,
    isFetching: false
};
const useStructuralUnitsReducer = () => {
    return (0, react_1.useReducer)(exports.structuralUnitsReducer, exports.initialStructuralUnitsState);
};
exports.useStructuralUnitsReducer = useStructuralUnitsReducer;
const structuralUnitsReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_STRUCTURAL_UNITS_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_STRUCTURAL_UNITS_REQUEST':
            return {
                ...state,
                errors: action.payload.errors,
                data: action.payload.data || [],
                isFetching: false
            };
        case 'SEND_CHILDREN_REQUEST':
            return {
                ...state,
                loadingItems: [...state.loadingItems, action.payload.parentIdentifier]
            };
        case 'DONE_CHILDREN_REQUEST': {
            const { parentId, structuralUnits, parentIdentifier } = action.payload;
            const { errors, data: children } = structuralUnits;
            return {
                ...state,
                errors,
                data: children ? (0, helpers_1.updateChildNodes)(state.data, parentId, parentIdentifier, children) : state.data,
                loadingItems: state.loadingItems.filter(item => item !== parentIdentifier)
            };
        }
        case 'OPEN_FETCHED_CHILDREN':
            return {
                ...state,
                data: (0, helpers_1.openNode)(state.data, action.payload.parentIdentifier)
            };
        case 'CLOSE_STRUCTURAL_UNIT':
            return {
                ...state,
                data: (0, helpers_1.closeNode)(state.data, action.payload.parentIdentifier)
            };
        case 'SEND_DELETE_REQUEST':
            return {
                ...state,
                loadingItems: [...state.loadingItems, action.payload.unitId]
            };
        case 'DONE_DELETE_REQUEST': {
            const { unitId, parentIds, structuredResponse } = action.payload;
            if (structuredResponse.errors === null) {
                return {
                    ...state,
                    data: (0, helpers_1.updateAndRemoveNode)(state.data, unitId, parentIds)
                };
            }
            else {
                return {
                    ...state,
                    errors: structuredResponse.errors
                };
            }
        }
        case 'SEND_FETCH_STRUCTURAL_UNIT':
            return {
                ...state,
                loadingItems: [...state.loadingItems, action.payload.parentUnitId]
            };
        case 'DONE_FETCH_STRUCTURAL_UNIT': {
            const { unitId, parentUnitId, structuralUnits } = action.payload;
            return {
                ...state,
                data: structuralUnits.data
                    ? (0, helpers_1.updateParentNode)(unitId, parentUnitId, structuralUnits.data, state.data)
                    : state.data,
                errors: structuralUnits.errors,
                loadingItems: state.loadingItems.filter(item => item !== parentUnitId)
            };
        }
        default:
            return state;
    }
};
exports.structuralUnitsReducer = structuralUnitsReducer;
