"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseHoliday = exports.parseHolidays = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseHolidays = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const holidays = response.content;
    try {
        return {
            data: holidays.data.map(el => {
                return {
                    id: el.id,
                    title: el.attributes.title,
                    date: (0, parsers_1.transformDate)(el.attributes.date),
                    dateAndTime: (0, parsers_1.transformDateTime)(el.attributes.date)
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(holidays.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseHolidays = parseHolidays;
const parseHoliday = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const holiday = response.content;
    try {
        return {
            data: {
                id: holiday.data.id,
                date: (0, parsers_1.transformDate)(holiday.data.attributes.date),
                title: holiday.data.attributes.title
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseHoliday = parseHoliday;
