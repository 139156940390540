"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSubmissions = void 0;
const react_1 = require("react");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const index_1 = require("api/index");
const submissions_1 = require("../store/submissions");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const queryString_1 = require("core/helpers/queryString");
const purchase_1 = require("../../../../common/helper/purchase");
const helpers_1 = require("../helpers");
const helper_1 = require("../../../../common/helper");
const helpers_2 = require("../helpers");
const react_router_dom_1 = require("react-router-dom");
const useSubmissions = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, submissions_1.useSubmissionsReducer)();
    const [toDeleteId, setToDeleteId] = (0, react_1.useState)(null);
    const [showDeleteAlert, setShowDeleteAlert] = (0, react_1.useState)(false);
    const [showItemStatus, setShowItemStatus] = (0, react_1.useState)(false);
    const [showFilter, setShowFilter] = (0, react_1.useState)(false);
    const isMounted = (0, react_1.useRef)(false);
    const navigatePagination = (page, filterValues) => {
        getSubmissions({ ...filterValues, page }).then();
    };
    const selectPerPage = (perPage, filterValues) => {
        getSubmissions({ ...filterValues, perPage, page: filterValues.page ? 1 : undefined }).then();
    };
    const filter = (name, value, filterValues) => {
        getSubmissions({ ...filterValues, [name]: value, page: filterValues.page ? 1 : undefined }).then();
    };
    const debouncedSearch = (name, value, filterValues) => {
        (0, debouncedMethods_1.debouncedOnFunc)({ ...filterValues, [name]: value, page: filterValues.page ? 1 : undefined }, getSubmissions);
    };
    const deleteAlert = (id) => {
        setShowDeleteAlert(true);
        setToDeleteId(id);
    };
    const deleteSubmission = async (confirm, formValue) => {
        setShowDeleteAlert(false);
        if (!toDeleteId || !confirm)
            return setToDeleteId(null);
        dispatch({ type: 'SEND_DELETE_REQUEST' });
        const submissions = await index_1.SubmissionsApi.deleteSubmission(toDeleteId);
        const pagination = { perPage: state.meta.pagination.perPage, page: state.meta.pagination.currentPage };
        if (submissions.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            getSubmissions({ ...formValue, ...pagination }).then();
        dispatch({ type: 'DONE_DELETE_REQUEST', payload: { errors: submissions.errors } });
        setToDeleteId(null);
    };
    const checkStatus = async (submissionId, itemId) => {
        dispatch({ type: 'SEND_SUBMISSIONS_ITEMS_STATUS_REQUEST', payload: itemId[0] });
        const itemStatuses = await index_1.SubmissionsApi.checkItemStatus(submissionId, { filters: { ids: itemId } });
        dispatch({ type: 'DONE_SUBMISSIONS_ITEMS_STATUS_REQUEST', payload: itemStatuses });
        setShowItemStatus(true);
    };
    const getSubmissions = async (values) => {
        dispatch({ type: 'SEND_SUBMISSIONS_REQUEST' });
        const filterParams = (0, helpers_2.transformParamsToFilterParams)(values);
        const submissions = await index_1.SubmissionsApi.getSubmissions(filterParams);
        if (isMounted?.current) {
            navigate('/documents/purchases/submissions' + (0, queryString_1.stringify)((0, helpers_1.transformFilterParamsToUrl)(values)), { replace: true });
            dispatch({ type: 'DONE_SUBMISSIONS_REQUEST', payload: submissions });
        }
    };
    const submissionStatusOptions = (0, react_1.useMemo)(() => (0, helper_1.transformObjectForSelect)(purchase_1.submissionStatus), []);
    const initializeFilterForm = async () => {
        const params = (0, helpers_1.transformUrlToFilterParams)(location.search);
        const paginatedStorage = JSON.parse(localStorage.getItem('paginated') || '""');
        const storagePerPage = paginatedStorage?.submissionListing?.perPage;
        params.perPage = params.perPage || Number(storagePerPage) || 25;
        const submissionOnlyMine = localStorage.getItem('submissionOnlyMine') || undefined;
        const openFilter = Object.entries(params).some(([key, value]) => {
            if (key === 'perPage' || key === 'page')
                return false;
            return !!value;
        });
        setShowFilter(openFilter);
        params.onlyMine = submissionOnlyMine;
        dispatch({ type: 'INITIALIZE_FILTER_FORM', payload: params });
        getSubmissions(params).then();
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        initializeFilterForm().then();
        return () => { isMounted.current = false; };
    }, []);
    return {
        state,
        navigatePagination,
        selectPerPage,
        filter,
        deleteSubmission,
        deleteAlert,
        setShowItemStatus,
        debouncedSearch,
        showItemStatus,
        submissionStatusOptions,
        setShowFilter,
        showFilter,
        checkStatus,
        toDeleteId,
        showDeleteAlert,
        getSubmissions
    };
};
exports.useSubmissions = useSubmissions;
