"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const legalBasis_1 = require("../store/legalBasis");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useLegalBasis = (props) => {
    const [state, dispatch] = (0, legalBasis_1.useDimensionUnitReducer)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const getLegalBasis = async (id) => {
        dispatch({ type: 'SEND_LEGAL_BASIS_REQUEST' });
        const legalBasis = await index_1.LegalBasicsApi.getLegalBasis(id);
        dispatch({ type: 'DONE_LEGAL_BASIS_REQUEST', payload: legalBasis });
    };
    const createLegalBasis = async (values, allowedToPush = true) => {
        const params = (0, helpers_1.transformLegalBasisParams)(values);
        dispatch({ type: 'SEND_LEGAL_BASIS_CREATE' });
        const legalBasis = await index_1.LegalBasicsApi.createLegalBasis(params);
        dispatch({ type: 'DONE_LEGAL_BASIS_CREATE', payload: legalBasis });
        if (legalBasis.data !== null) {
            if (allowedToPush)
                navigate(`/references/legal-basics/${legalBasis.data.id}/edit`);
        }
        return legalBasis;
    };
    const updateLegalBasis = async (id, values) => {
        const params = (0, helpers_1.transformLegalBasisParams)(values);
        dispatch({ type: 'SEND_LEGAL_BASIS_UPDATE' });
        const legalBasis = await index_1.LegalBasicsApi.updateLegalBasis(id, params);
        dispatch({ type: 'DONE_LEGAL_BASIS_UPDATE', payload: legalBasis });
        return legalBasis;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getLegalBasis(props.id).then(); }, [props.id]);
    return { state, updateLegalBasis, getLegalBasis, closeErrorMessage, createLegalBasis };
};
exports.default = useLegalBasis;
