"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const contract_signing_1 = require("api/documents/human-resources/contract-signing");
const store_1 = require("core/store");
const react_1 = require("react");
const useContractSigning = (props) => {
    const [state, setState] = (0, react_1.useState)({ data: null, errors: null, status: apiGlobalTypes_1.ResponseStatuses.SUCCESS, meta: store_1.initialMeta });
    const [isFetching, setFetching] = (0, react_1.useState)(false);
    const [isSending, setIsSending] = (0, react_1.useState)(false);
    const [isLoading, setIsLoading] = (0, react_1.useState)(null);
    const [signContractStart, setSignContractStart] = (0, react_1.useState)(false);
    const [signed, setSigned] = (0, react_1.useState)(0);
    const stopSigning = (0, react_1.useRef)(false);
    const [page, setPage] = (0, react_1.useState)(1);
    const [total, setTotal] = (0, react_1.useState)(0);
    const [id, setId] = (0, react_1.useState)(null);
    const getSignings = async () => {
        setFetching(true);
        const items = await (0, contract_signing_1.getContractsSigningListForModal)({
            page: 1,
            perPage: 30,
            filters: {
                signAll: props.signatoryAccountId
            }
        });
        setState(items);
        setFetching(false);
        return items;
    };
    const signingLoop = async (array) => {
        let counter = 0;
        for (const el of array.data) {
            if (stopSigning.current) {
                setIsSending(false);
                break;
            }
            setIsLoading(el.id);
            setIsSending(true);
            const result = await index_1.ContractSigningApi.signContractInModal(el.id);
            if (result.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS) {
                el.visualStatus = { name: 'ხელმოწერილი', color: 'success' };
                setSigned(prevState => (prevState + 1));
                setId(el.id);
                counter++;
            }
            else {
                el.visualStatus = { name: 'შეცდომა', color: 'danger' };
                setState(prevState => {
                    return {
                        ...prevState,
                        errors: result.errors
                    };
                });
                setIsLoading(null);
                setIsSending(false);
                break;
            }
            setIsLoading(null);
        }
        return counter;
    };
    const stopSigningProcess = () => {
        stopSigning.current = true;
    };
    const closeErrorMessage = (index) => {
        setState(prevState => {
            return {
                ...prevState,
                errors: prevState.errors ? prevState.errors.filter((_el, indx) => index !== indx) : prevState.errors
            };
        });
    };
    (0, react_1.useEffect)(() => {
        if (signContractStart) {
            signingLoop(state).then(counter => {
                if (counter === state.data?.length && state.data.length > 0) {
                    getSignings().then(() => setPage(prevState => prevState + 1));
                }
                else {
                    stopSigning.current = true;
                    setIsSending(false);
                }
            });
        }
    }, [signContractStart, page]);
    (0, react_1.useEffect)(() => { if (props.openModal)
        getSignings().then(res => setTotal(res.meta?.pagination.total || 0)); }, [props.openModal]);
    return {
        state,
        isFetching,
        isSending,
        signContractStart,
        setSignContractStart,
        isLoading,
        signed,
        page,
        stopSigningProcess,
        total,
        stopSigning,
        closeErrorMessage,
        setSigned,
        id
    };
};
exports.default = useContractSigning;
