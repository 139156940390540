"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseProcurementStatisticFile = exports.parseProcurementStatisticProjects = exports.parseProcurementStatisticBudgetArticles = exports.parseProcurementStatisticCategories = exports.parseProcurementStatisticsItems = exports.parseProcurementStatistics = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parsers_2 = require("api/references/funding/projects/parsers");
const parseProcurementStatistics = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const procurementItems = response.content;
    try {
        return {
            data: procurementItems.data.map(el => {
                return {
                    id: el.id,
                    conditionalNumber: el.attributes.number,
                    contractNumber: el.attributes.signedDocumentNumber,
                    contractType: el.attributes.templateType,
                    registrationDate: (0, parsers_1.nullableTransformDate)(el.attributes.dateOfConclusion),
                    companyName: el.relationships.company.data.attributes.name,
                    sumPrice: el.attributes.sumPrice
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(procurementItems.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseProcurementStatistics = parseProcurementStatistics;
const parseProcurementStatisticsItems = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const procurements = response.content;
    try {
        return {
            data: procurements.data.map(el => {
                const item = el.relationships.verifyItem || el.relationships.submissionItem;
                const subject = item.data.relationships.inventoryConfiguration.data.attributes;
                return {
                    id: el.id,
                    price: el.attributes.price,
                    quantity: el.attributes.quantity,
                    name: subject.itemTitle
                        + (subject.specificationTitle ? ' - ' + subject.specificationTitle : '')
                        + (subject.categoryTitle ? ' - ' + subject.categoryTitle : ''),
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt)
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(procurements.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseProcurementStatisticsItems = parseProcurementStatisticsItems;
const parseProcurementStatisticCategories = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const statistic = response.content;
    try {
        return {
            data: statistic.data.map(el => {
                return {
                    id: el.relationships.category.data.id,
                    categoryName: el.relationships.category.data.attributes.name,
                    totalPrice: el.attributes.totalPrice,
                    itemCount: el.attributes.itemCount
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(statistic.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseProcurementStatisticCategories = parseProcurementStatisticCategories;
const parseProcurementStatisticBudgetArticles = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const statistic = response.content;
    try {
        return {
            data: statistic.data.map(el => {
                return {
                    id: el.relationships.budgetArticle.data.id,
                    budgetArticleName: el.relationships.budgetArticle.data.attributes.name,
                    totalPrice: el.attributes.totalPrice,
                    itemCount: el.attributes.itemCount
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(statistic.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseProcurementStatisticBudgetArticles = parseProcurementStatisticBudgetArticles;
const parseProcurementStatisticProjects = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const statistic = response.content;
    try {
        return {
            data: statistic.data.map(el => {
                return {
                    id: el.relationships.project.data.id,
                    projectName: (0, parsers_2.parseProjectFullName)(el.relationships.project.data),
                    totalPrice: el.attributes.totalPrice,
                    itemCount: el.attributes.itemCount
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(statistic.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseProcurementStatisticProjects = parseProcurementStatisticProjects;
const parseProcurementStatisticFile = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const statisticFile = response.content;
    try {
        return {
            data: {
                id: statisticFile.data.id,
                name: statisticFile.data.attributes.name,
                link: statisticFile.data.attributes.downloadUrl,
                status: statisticFile.data.attributes.status,
                originalName: statisticFile.data.attributes.originalName,
                extension: statisticFile.data.attributes.extension
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseProcurementStatisticFile = parseProcurementStatisticFile;
