"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformFilterParamsToUrl = exports.transformParamsToFilterParams = exports.transformUrlToFilterParams = void 0;
const queryString_1 = require("core/helpers/queryString");
const transformUrlToFilterParams = (url) => {
    const values = (0, queryString_1.parse)(url);
    return {
        page: values.page,
        perPage: values.perPage,
        ip: values.ip,
        user: values.userId && values.userLabel ? { id: values.userId, label: values.userLabel } : undefined
    };
};
exports.transformUrlToFilterParams = transformUrlToFilterParams;
const transformParamsToFilterParams = (params) => {
    const filters = {};
    const paginate = {};
    const filterParams = {
        page: params.page,
        perPage: params.perPage,
        ip: params.ip,
        userId: params.user?.id
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if (value && key !== 'page' && key !== 'perPage')
                filters[key] = value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(filters).length === 0)
            return { ...paginate };
        return { ...paginate, filters };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformParamsToFilterParams = transformParamsToFilterParams;
const transformFilterParamsToUrl = (values) => {
    const { ip, page, perPage, user } = values;
    return {
        page,
        perPage,
        userId: user && user.id,
        userLabel: user && user.label,
        ip
    };
};
exports.transformFilterParamsToUrl = transformFilterParamsToUrl;
