"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseInvoiceVisualStatuses = exports.incomingType2 = exports.incomingType1 = exports.fineAccountOptions = exports.parseUserSignatories = exports.parseInvoiceFiles = exports.parseGenerateInvoiceTemplate = exports.parsePurchasedItems = exports.parseInvoiceItemsForm = exports.parseInvoice = exports.parseInvoiceItems = exports.parseDeleteInvoice = exports.parseInvoices = void 0;
const types_1 = require("api/documents/purchases/invoices/types");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseInvoices = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const invoices = response.content;
    try {
        return {
            data: invoices.data.map(el => {
                return {
                    id: el.id,
                    moduleType: el.type,
                    sentToAccounting: el.attributes.sentToAccounting,
                    sentToWarehouse: el.attributes.sentToWarehouse,
                    creationDate: (0, parsers_1.nullableTransformDate)(el.attributes.creationDate),
                    creationDateTime: (0, parsers_1.transformDateTime)(el.attributes.creationDate),
                    deliveryPlace: el.attributes.deliveryPlace,
                    declineReason: el.attributes.declineReason,
                    fine: el.attributes.fine,
                    receiptDate: (0, parsers_1.nullableTransformDate)(el.attributes.receiptDate),
                    receiptDateTime: (0, parsers_1.transformDateTime)(el.attributes.receiptDate),
                    status: el.attributes.status,
                    subStatus: el.attributes.subStatus,
                    visualStatus: (0, exports.parseInvoiceVisualStatuses)(el.attributes.status),
                    subVisualStatus: parseInvoiceSubStatus(el.attributes.subStatus),
                    template: el.attributes.template,
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt),
                    number: el.relationships.procurement.data.attributes.signedDocumentNumber,
                    company: el.relationships.company.data.attributes.name,
                    warehouse: el.relationships.warehouse?.data.attributes.city,
                    creator: el.relationships.creator.data.relationships.user.data.attributes.fullname,
                    invoiceType: el.attributes.type,
                    invoiceNumber: el.attributes.number
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(invoices.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseInvoices = parseInvoices;
const parseDeleteInvoice = (response) => {
    return response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS;
};
exports.parseDeleteInvoice = parseDeleteInvoice;
const parseInvoiceItems = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const invoiceItems = response.content;
    try {
        return {
            data: invoiceItems.data.map(el => {
                const item = el.relationships.procurementItem.data.relationships.verifyItem || el.relationships.procurementItem.data.relationships.submissionItem;
                const currency = item.data.relationships.currency;
                const currencyRate = el.relationships.procurementItem.data.attributes.currencyExchangeRate || currency.data.attributes.rate;
                const transformedRate = currencyRate
                    ? currencyRate / (currency.data.attributes.quantity || 1)
                    : 1;
                return {
                    id: el.id,
                    moduleType: el.type,
                    quantity: el.attributes.quantity,
                    unpredicted: el.attributes.unpredicted,
                    sumPrice: el.attributes.sumPrice,
                    name: item.data.relationships.inventoryConfiguration.data.attributes.itemTitle,
                    specification: item.data.relationships.inventoryConfiguration.data.attributes.specificationTitle || '',
                    dimensionUnit: item.data.relationships.dimensionUnit.data.attributes.name,
                    price: el.relationships.procurementItem.data.attributes.price,
                    currencyExchangeRate: el.relationships.procurementItem.data.attributes.currencyExchangeRate || 1,
                    currencyRate,
                    currency: {
                        id: currency.data.id,
                        label: currency.data.attributes.code,
                        currentExchangeRate: currency.data.attributes.rate,
                        quantity: currency.data.attributes.quantity,
                        transformedRate
                    },
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt)
                };
            }),
            meta: (0, parsers_1.transformPagination)(invoiceItems.meta),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseInvoiceItems = parseInvoiceItems;
const parseInvoice = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const invoice = response.content;
    try {
        const company = invoice.data.relationships.company.data;
        const companySignatory = invoice.data.relationships.companySignatories?.data[0];
        const companySignatoryPhone = invoice.data.relationships.companySignatoriesPhone?.data[0];
        const procurement = invoice.data.relationships.procurement.data;
        return {
            data: {
                id: invoice.data.id,
                procurement: {
                    id: procurement.id,
                    label: procurement.attributes.signedDocumentNumber || procurement.attributes.number,
                    contractType: {
                        id: procurement.relationships?.type.data.id,
                        label: procurement.relationships?.type.data.attributes.name
                    }
                },
                createdFromProcurementCorrection: procurement.relationships.correctionByCorrectionId ? {
                    id: procurement.relationships.correctionByCorrectionId.data.relationships.parentItem.data.id
                } : undefined,
                company: {
                    id: company.id,
                    label: company.attributes.name,
                    signatories: company.relationships.signatories.data.map(el => {
                        return {
                            id: el.id,
                            label: el.attributes.firstname + ' ' + el.attributes.lastname,
                            phones: el.relationships.phones?.data.map(phone => {
                                return {
                                    id: phone.id,
                                    label: phone.attributes.phone
                                };
                            }) || []
                        };
                    })
                },
                warehouse: invoice.data.relationships.warehouse && {
                    id: invoice.data.relationships.warehouse.data.id,
                    label: invoice.data.relationships.warehouse.data.attributes.city
                },
                visualStatus: (0, exports.parseInvoiceVisualStatuses)(invoice.data.attributes.status),
                subVisualStatus: parseInvoiceSubStatus(invoice.data.attributes.subStatus),
                companySignatory: companySignatory && {
                    id: companySignatory.id,
                    label: companySignatory.attributes.firstname + ' ' + companySignatory.attributes.lastname,
                    phones: company.relationships.signatories?.data
                        .find(el => el.id === companySignatory.id)?.relationships.phones?.data
                        .map(phone => {
                        return {
                            id: phone.id,
                            label: phone.attributes.phone
                        };
                    }) || []
                } || null,
                companySignatoryPhone: companySignatoryPhone && {
                    id: companySignatoryPhone.id,
                    label: companySignatoryPhone.attributes.phone
                } || null,
                status: invoice.data.attributes.status,
                number: invoice.data.attributes.number,
                subStatus: invoice.data.attributes.subStatus,
                receiptDate: (0, parsers_1.nullableTransformDate)(invoice.data.attributes.receiptDate),
                creationDate: (0, parsers_1.nullableTransformDate)(invoice.data.attributes.creationDate),
                deliveryPlace: invoice.data.attributes.deliveryPlace,
                declineReason: invoice.data.attributes.declineReason,
                suspendReason: invoice.data.attributes.suspendReason,
                sentToAccounting: invoice.data.attributes.sentToAccounting,
                sentToWarehouse: invoice.data.attributes.sentToWarehouse,
                creatorId: invoice.data.relationships.creator.data.relationships.user.data.id,
                creatorAccountId: invoice.data.relationships.creator.data.id,
                fine: invoice.data.attributes.fine || null,
                fineAccount: exports.fineAccountOptions.find(el => el.label === invoice.data.attributes.fineAccount) || null,
                incomeType: [...exports.incomingType1, ...exports.incomingType2].find(el => el.id === String(invoice.data.attributes.incomeType)) || null,
                signatories: invoice.data.relationships.userSignatories?.data.map(el => {
                    return {
                        id: el.id,
                        label: el.attributes.fullname,
                        type: el.attributes.pivots.type,
                        userId: el.attributes.pivots.user_id,
                        structuralUnitId: el.attributes.pivots.structural_unit_id
                    };
                }) || null,
                files: (0, exports.parseInvoiceFiles)(invoice.data.relationships.mediaFiles?.data).filter(el => el.type === types_1.InvoiceType.DEFAULT),
                invoiceFile: (0, exports.parseInvoiceFiles)(invoice.data.relationships.mediaFiles?.data).find(el => el.type === types_1.InvoiceType.ONLY_FILE) || null,
                invoiceType: invoice.data.attributes.type,
                template: invoice.data.attributes.template,
                procurementChanged: false
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseInvoice = parseInvoice;
const parseInvoiceItemsForm = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const items = response.content;
    try {
        return {
            data: items.data.map(item => parseInvoiceItemForm(item)),
            meta: items.meta ? (0, parsers_1.transformPagination)(items.meta) : undefined,
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseInvoiceItemsForm = parseInvoiceItemsForm;
const parseInvoiceItemForm = (item) => {
    const procurementItem = item.relationships.procurementItem.data;
    const verifyItem = (procurementItem.relationships.verifyItem && procurementItem.relationships.verifyItem.data)
        || procurementItem.relationships.submissionItem.data;
    const purchaseSubjectAttributes = verifyItem.relationships.inventoryConfiguration.data.attributes;
    const specification = verifyItem.relationships.specification?.data.attributes.specification;
    const subjectName = purchaseSubjectAttributes.itemTitle
        + (purchaseSubjectAttributes.specificationTitle ? ' / ' + purchaseSubjectAttributes.specificationTitle : '')
        + (specification ? ' - ' + specification : '')
        + (verifyItem.attributes.characteristics ? ' / ' + verifyItem.attributes.characteristics : '');
    const cpvCode = item.relationships.procurementItem.data.relationships.cpvCode;
    const dimensionUnitName = verifyItem.relationships.dimensionUnit.data.attributes.name;
    return {
        id: item.id,
        procurementItemId: procurementItem.id,
        name: subjectName,
        cpvCode: cpvCode
            ? {
                code: cpvCode.data.attributes.code,
                name: cpvCode.data.attributes.name,
                label: cpvCode.data.attributes.code + ' - ' + cpvCode.data.attributes.name
            }
            : {
                name: verifyItem.relationships.cpvCode.data.attributes.name,
                code: verifyItem.relationships.cpvCode.data.attributes.code,
                label: verifyItem.relationships.cpvCode.data.attributes.code + ' - '
                    + verifyItem.relationships.cpvCode.data.attributes.name
            },
        currencyExchangeRate: verifyItem.attributes.currencyExchangeRate || 1,
        price: item.attributes.unpredicted ? item.attributes.sumPrice : procurementItem.attributes.price,
        currency: verifyItem.relationships.currency.data.attributes.code,
        quantity: item.attributes.quantity,
        lockedUnpredictedService: !!(procurementItem.attributes.remainingPrice
            && item.attributes.unpredicted
            && (procurementItem.attributes.remainingPrice + item.attributes.sumPrice) !== procurementItem.attributes.price),
        possibleServices: (dimensionUnitName === 'მომსახურება' || dimensionUnitName === 'სამუშაო') && procurementItem.attributes.quantity === 1,
        dimensionUnit: verifyItem.relationships.dimensionUnit.data.attributes.name,
        currencyQuantity: verifyItem.relationships.currency.data.attributes.quantity || 1,
        purchaseSubject: subjectName,
        purchaseSubjectShortName: (0, parsers_1.parseShortName)(subjectName),
        restQuantity: procurementItem.attributes.remainingQuantity,
        remainingPrice: procurementItem.attributes.remainingPrice,
        unpredicted: item.attributes.unpredicted,
        submissionNumber: item.relationships.procurementItem.data.relationships.submissionItem
            ? item.relationships.procurementItem.data.relationships.submissionItem.data.relationships.document.data.attributes.number
            : item.relationships.procurementItem.data.relationships.verifyItem.data.relationships.submission.data.attributes.number,
        submissionId: item.relationships.procurementItem.data.relationships.submissionItem
            ? item.relationships.procurementItem.data.relationships.submissionItem.data.relationships.document.data.id
            : item.relationships.procurementItem.data.relationships.verifyItem.data.relationships.submission.data.id
    };
};
const parsePurchasedItems = (response) => {
    const purchasedItems = response.content;
    if (purchasedItems === null)
        return { data: null, errors: response.errors, status: response.status };
    try {
        return {
            data: purchasedItems.data.map(item => {
                const verifyItem = (item.relationships.verifyItem && item.relationships.verifyItem.data)
                    || item.relationships.submissionItem.data;
                const purchaseSubject = verifyItem.relationships.inventoryConfiguration.data.attributes;
                const specification = verifyItem.relationships.specification?.data.attributes.specification;
                const purchaseSubjectName = purchaseSubject.itemTitle + (purchaseSubject.specificationTitle ? ' / ' + purchaseSubject.specificationTitle : '')
                    + (specification ? ' - ' + specification : '')
                    + (verifyItem.attributes.characteristics ? ' / ' + verifyItem.attributes.characteristics : '');
                const usedQuantity = item.relationships.invoiceItems?.data.reduce((a, curr) => a + curr.attributes.quantity, 0) || 0;
                const dimensionUnitName = verifyItem.relationships.dimensionUnit.data.attributes.name;
                const cpvCode = {
                    id: verifyItem.relationships.cpvCode.data.id,
                    name: verifyItem.relationships.cpvCode.data.attributes.name,
                    label: verifyItem.relationships.cpvCode.data.attributes.code
                        + ' - ' + verifyItem.relationships.cpvCode.data.attributes.name,
                    code: verifyItem.relationships.cpvCode.data.attributes.code
                };
                return {
                    name: purchaseSubjectName,
                    currencyExchangeRate: verifyItem.attributes.currencyExchangeRate || 1,
                    price: item.attributes.remainingPrice || item.attributes.price,
                    restQuantity: item.attributes.remainingQuantity || (item.attributes.quantity - usedQuantity),
                    currency: verifyItem.relationships.currency.data.attributes.code,
                    quantity: item.attributes.quantity,
                    maxQuantity: item.attributes.quantity,
                    possibleServices: (dimensionUnitName === 'მომსახურება' || dimensionUnitName === 'სამუშაო') && item.attributes.quantity === 1,
                    dimensionUnit: verifyItem.relationships.dimensionUnit.data.attributes.name,
                    project: verifyItem.relationships.project.data.attributes.name,
                    currencyQuantity: verifyItem.relationships.currency.data.attributes.quantity || 1,
                    cpvCode,
                    submissionNumber: item.relationships.submissionItem
                        ? item.relationships.submissionItem.data.relationships.document.data.attributes.number
                        : item.relationships.verifyItem.data.relationships.submission.data.attributes.number,
                    submissionId: item.relationships.submissionItem
                        ? item.relationships.submissionItem.data.relationships.document.data.id
                        : item.relationships.verifyItem.data.relationships.submission.data.id,
                    purchaseSubject: purchaseSubjectName + (specification ? ' - ' + specification : ''),
                    purchaseSubjectShortName: (0, parsers_1.parseShortName)(purchaseSubjectName),
                    procurementItemId: item.id,
                    unpredicted: item.attributes.unpredicted,
                    lockedUnpredictedService: item.attributes.unpredicted,
                    sumPrice: item.attributes.sumPrice
                };
            }),
            meta: (0, parsers_1.transformPagination)(purchasedItems.meta),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parsePurchasedItems = parsePurchasedItems;
const parseGenerateInvoiceTemplate = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const invoice = response.content;
    try {
        return {
            data: invoice.data.attributes.template,
            errors: response.errors,
            status: response.status
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseGenerateInvoiceTemplate = parseGenerateInvoiceTemplate;
const parseInvoiceFiles = (files) => {
    if (!files)
        return [];
    return files.map(el => {
        return {
            id: el.id,
            name: el.attributes.name,
            link: el.attributes.downloadUrl,
            status: el.attributes.status,
            type: el.attributes.pivots.type,
            originalName: el.attributes.originalName,
            extension: el.attributes.extension
        };
    });
};
exports.parseInvoiceFiles = parseInvoiceFiles;
const parseUserSignatories = (response) => {
    const userSignatories = response.content;
    if (userSignatories === null)
        return { data: [], errors: response.errors, status: response.status };
    try {
        return {
            data: userSignatories.data.map(el => {
                return {
                    id: el.id,
                    userId: el.id,
                    label: el.attributes.fullname + ' - ' + el.attributes.pivots.structural_unit_name,
                    type: el.attributes.pivots.signatory_type,
                    structuralUnitId: el.attributes.pivots.structural_unit_id
                };
            }),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return { ...helper_1.errorObject, data: [] };
    }
};
exports.parseUserSignatories = parseUserSignatories;
exports.fineAccountOptions = [
    { id: '1', label: '708997520 - ბანკის კოდი_TRESGE22' },
    { id: '2', label: '302003287 - ბანკის კოდი_TRESGE22' }
];
exports.incomingType1 = [
    { id: String(types_1.IncomeType.ECONOMICAL_ACTIVITIES), label: 'ეკონომიკური საქმიანობით მიღებული შემოსავლების ფარგლებში' },
    { id: String(types_1.IncomeType.NOT_REGISTERED_GRANT), label: 'არარეგისტრირებული საერთაშორისო გრანტების ფარგლებში' },
    { id: String(types_1.IncomeType.RUSTAVELI_FUND), label: 'გრანტი - რუსთაველის ეროვნული ფონდი' }
];
exports.incomingType2 = [
    { id: String(types_1.IncomeType.STATE_BUDGET_FUNDING), label: 'სახელმწიფო ბიუჯეტიდან მიღებული დაფინანსების ფარგლებში' },
    { id: String(types_1.IncomeType.REGISTERED_GRANT_FUNDING), label: 'რეგისტრირებული საერთაშიორისო გრანტის (მიზნობრივი გრანტის) დაფინანსების ფარგლებში' },
    { id: String(types_1.IncomeType.STATE_BUDGET_PROGRAM_FUNDING), label: 'სახელმწიფო ბიუჯეტიდან მიღებული პროგრამული დაფინანსების ფრაგლებში' },
    { id: String(types_1.IncomeType.FUNDS_BY_RUSTAVELI_FONDS), label: 'რუსთაველის ფონდების მიერ დაფინანსებული გრანტის ფარგლებში' }
];
const parseInvoiceVisualStatuses = (status) => {
    switch (status) {
        case types_1.InvoiceStatuses.DRAFT:
            return { name: 'პროექტი', color: 'secondary' };
        case types_1.InvoiceStatuses.CONFIRM_PROCESS:
            return { name: 'დადასტურების პროცესში', color: 'primary' };
        case types_1.InvoiceStatuses.CONFIRMED:
            return { name: 'დადასტურებული', color: 'info' };
        case types_1.InvoiceStatuses.DISMISSED:
            return { name: 'უარყოფილი', color: 'danger' };
        case types_1.InvoiceStatuses.SIGNED:
            return { name: 'ხელმოწერილი', color: 'success' };
        case types_1.InvoiceStatuses.SUSPENDED:
            return { name: 'შეჩერებული', color: 'warning' };
    }
};
exports.parseInvoiceVisualStatuses = parseInvoiceVisualStatuses;
const parseInvoiceSubStatus = (status) => {
    switch (status) {
        case types_1.InvoiceSubStatuses.SUB_STATUS_UNCOMPLETED:
            return { name: 'შეუსრულებელი', color: 'secondary' };
        case types_1.InvoiceSubStatuses.SUB_STATUS_COMPLETED:
            return { name: 'შესრულებული', color: 'success' };
    }
};
