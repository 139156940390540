"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeterminationsReducer = void 0;
const react_1 = require("react");
const initialDeterminationsStore = {
    data: [],
    errors: null,
    isFetching: false
};
const useDeterminationsReducer = () => {
    return (0, react_1.useReducer)(determinationsReducer, initialDeterminationsStore);
};
exports.useDeterminationsReducer = useDeterminationsReducer;
const determinationsReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_DETERMINATIONS_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_DETERMINATIONS_REQUEST':
            return {
                ...state,
                errors: action.payload.errors,
                data: action.payload.data || [],
                isFetching: false
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
