"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.districtReducer = exports.useDistrictReducer = exports.initialDistrictState = void 0;
const react_1 = require("react");
const initialDistrictValue = {
    nameKa: '',
    nameEn: '',
    region: null
};
exports.initialDistrictState = {
    data: initialDistrictValue,
    status: null,
    errors: null,
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isLoading: false
};
const useDistrictReducer = () => {
    return (0, react_1.useReducer)(exports.districtReducer, exports.initialDistrictState);
};
exports.useDistrictReducer = useDistrictReducer;
const districtReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_DISTRICT_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_DISTRICT_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isFetching: false,
                isLoading: state.isCreating || state.isUpdating
            };
        case 'SEND_DISTRICT_CREATE':
            return {
                ...state,
                errors: null,
                isCreating: true,
                isLoading: true
            };
        case 'DONE_DISTRICT_CREATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isCreating: false,
                isLoading: state.isFetching || state.isUpdating
            };
        case 'SEND_DISTRICT_UPDATE':
            return {
                ...state,
                errors: null,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_DISTRICT_UPDATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isUpdating: false,
                isLoading: state.isCreating || state.isFetching
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.districtReducer = districtReducer;
