"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcurementsReducer = exports.useProcurementsReducer = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
const initialProcurements = {
    data: [],
    status: null,
    errors: null,
    filterForm: {},
    meta: store_1.initialMeta,
    isFetching: true,
    isDeleting: false,
    isFetched: false
};
const useProcurementsReducer = () => {
    return (0, react_1.useReducer)(exports.ProcurementsReducer, initialProcurements);
};
exports.useProcurementsReducer = useProcurementsReducer;
const ProcurementsReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_PROCUREMENTS_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_PROCUREMENTS_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                status: action.payload.status,
                errors: action.payload.errors,
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'SEND_DELETE_REQUEST':
            return {
                ...state,
                isDeleting: true
            };
        case 'DONE_DELETE_REQUEST':
            return {
                ...state,
                errors: action.payload.errors,
                isDeleting: false
            };
        case 'INITIALIZE_FILTER_FORM':
            return {
                ...state,
                filterForm: action.payload,
                isFetched: true
            };
        default:
            return state;
    }
};
exports.ProcurementsReducer = ProcurementsReducer;
