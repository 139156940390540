"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.moduleLink = exports.parseNotificationAsRead = exports.parseUserSettings = exports.notifications = exports.channels = exports.parseNotifications = exports.parseLastNotifications = void 0;
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseLastNotifications = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const notifications = response.content;
    try {
        return {
            data: {
                notifications: notifications.data.relationships.notifications.data.map(el => getNotification(el)),
                unreadQuantity: notifications.data.attributes.quantity <= 99
                    ? notifications.data.attributes.quantity ? String(notifications.data.attributes.quantity) : null
                    : '99+'
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseLastNotifications = parseLastNotifications;
const parseNotifications = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const notifications = response.content;
    try {
        return {
            data: {
                notifications: notifications.data.map(el => {
                    return getNotification(el);
                }),
                unreadQuantity: null
            },
            status: response.status,
            errors: null,
            meta: notifications.meta
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseNotifications = parseNotifications;
exports.channels = [
    {
        channel: 'App\\Notifications\\Channels\\SystemChannel',
        name: 'SystemChannel',
        title: 'სისტემური შეტყობინება',
        key: 2
    },
    {
        channel: 'App\\Notifications\\Channels\\MailChannel',
        name: 'MailChannel',
        title: 'მეილით შეტყობინებები',
        key: 4
    }
];
exports.notifications = [
    // შესყიდვები
    // პირობითი წარდგინება 6
    {
        title: 'პირობითი წარდგინების შეჩერება',
        className: 'App\\Notifications\\Documents\\Purchases\\ConditionalSubmissionSuspendNotification'
    },
    {
        title: 'პირობითი წარდგინების უარყოფა',
        className: 'App\\Notifications\\Documents\\Purchases\\ConditionalSubmissionDeclineNotification'
    },
    {
        title: 'ვერიფიცირებული საგნები',
        className: 'App\\Notifications\\References\\InventoryConfigurationVerifiedNotification'
    },
    {
        title: 'პირობითი წარდგინების კომენტარი ',
        className: 'App\\Notifications\\Documents\\Purchases\\ConditionalSubmissionAddCommentNotification'
    },
    {
        title: 'ვადის დადასტურება ',
        className: 'App\\Notifications\\Documents\\Purchases\\ConfirmSubmissionExpirationDateNotification'
    },
    {
        title: 'ვადის უარყოფა ',
        className: 'App\\Notifications\\Documents\\Purchases\\DeclineSubmissionExpirationDateNotification'
    },
    // {
    //   title: 'შესრულების ვადა ',
    //   className: 'App\\Notifications\\Documents\\Purchases\\SubmissionChangeExpirationDateNotification'
    // },
    // წარდგინება 6 + 3
    {
        title: 'წარდგინების სტატუსის შეცვლა',
        className: 'App\\Notifications\\Documents\\Purchases\\SubmissionStatusChangeNotification'
    },
    {
        title: 'წარდგინებაში შესყიდვის საგნის მახასიათებლის დამატება',
        className: 'App\\Notifications\\Documents\\Purchases\\SubmissionItemCharacteristicsNotification'
    },
    {
        title: 'შესყიდვის წარდგინების ვადის ცვლილება',
        className: 'App\\Notifications\\Documents\\Purchases\\SubmissionNewExpirationDateNotification'
    },
    // წარდგინების გადამოწმება 9 + 6
    {
        title: 'შესყიდვის საგნის დამატება',
        className: 'App\\Notifications\\References\\InventoryConfigurationVerifyNotification'
    },
    {
        title: 'წარდგინების გადამოწმების სტატუსის შეცვლა',
        className: 'App\\Notifications\\Documents\\Purchases\\SubmissionVerifyStatusChangeNotification'
    },
    {
        title: 'წარდგინების გადამოწმების საგნების სტატუსის შეცვლა',
        className: 'App\\Notifications\\Documents\\Purchases\\VerifyItemStatusChangeNotification'
    },
    {
        title: 'წარდგინების გადამოწმების საგნ(ებ)ის უარყოფა',
        className: 'App\\Notifications\\Documents\\Purchases\\VerifyItemDeclineStatusNotification'
    },
    {
        title: 'წარდგინების გადამოწმების უარყოფა',
        className: 'App\\Notifications\\Documents\\Purchases\\SubmissionVerifyDeclineNotification'
    },
    {
        title: 'საბიუჯეტო მუხლის ცვლილება',
        className: 'App\\Notifications\\Documents\\Purchases\\VerifyItemFinanceItemsChangeNotification'
    },
    // შესყიდვის ხელშეკრულება 15 + 15
    {
        title: 'შესყიდვის ხელშეკრულების ვიზირების პროცესის სტატუსი',
        className: 'App\\Notifications\\Documents\\Purchases\\ProcurementVerifyStatusNotification'
    },
    {
        title: 'შესყიდვის ხელშეკრულების ვიზირების პროცესში დაბრუნება',
        className: 'App\\Notifications\\Documents\\Purchases\\ProcurementChangeStatusToRevertNotification'
    },
    {
        title: 'შესყიდვის ხელშეკრულების დადასტურებული სტატუსი',
        className: 'App\\Notifications\\Documents\\Purchases\\ProcurementApprovedStatusNotification'
    },
    {
        title: 'შესყიდვის ხელშეკრულება ატვირთულია ხელმოსაწერად (ნახვა)',
        className: 'App\\Notifications\\Documents\\Purchases\\ProcurementSignedStatusAndCmrSetNotification'
    },
    {
        title: 'ხელმოწერილი შესყიდვის ხელშეკრულების გაგზავნა',
        className: 'App\\Notifications\\Documents\\Purchases\\ProcurementSendSignatureDocumentNotification'
    },
    {
        title: 'შესყიდვის ხელშეკრულების ხელმოწერილი სტატუსი',
        className: 'App\\Notifications\\Documents\\Purchases\\ProcurementSignedStatusNotification'
    },
    {
        title: 'შესყიდვის ხელშეკრულების შეჩერება',
        className: 'App\\Notifications\\Documents\\Purchases\\ProcurementSuspendedStatusNotification'
    },
    {
        title: 'შესყიდვის ხელშეკრულების უარყოფა',
        className: 'App\\Notifications\\Documents\\Purchases\\ProcurementDeclinedStatusNotification'
    },
    {
        title: 'შესყიდვის ხელშეკრულების ცვლილება',
        className: 'App\\Notifications\\Documents\\Purchases\\ProcurementChangeNotification'
    },
    {
        title: 'შესყიდვის ხელშეკრულებაში შესყიდვის საგნის წაშლა',
        className: 'App\\Notifications\\Documents\\Purchases\\ProcurementItemRemoveFromDocumentNotification'
    },
    {
        title: 'ფაქტობრივად შესრულებული',
        className: 'App\\Notifications\\Documents\\Purchases\\ProcurementActuallyDoneNotification'
    },
    {
        title: 'შესყიდვის ხელშეკრულების შესრულების ვადის გასვლის მოახლოება',
        className: 'App\\Notifications\\Documents\\Purchases\\ProcurementAlarmNotification'
    },
    {
        title: 'პროექტის სტატუსში დაბრუნება',
        className: 'App\\Notifications\\Documents\\Purchases\\ProcurementReturnToProjectStatusNotification'
    },
    {
        title: 'ხელშეკრულების ცალმხრივად შეწყვეტა',
        className: 'App\\Notifications\\Documents\\Purchases\\ProcurementUnilateralTerminationNotification'
    },
    {
        title: 'ხელშეკრულების ორმხრივად შეწყვეტა',
        className: 'App\\Notifications\\Documents\\Purchases\\ProcurementBilateralTerminationNotification'
    },
    // მიღება-ჩაბარება 30 + 7
    {
        title: 'მიღება-ჩაბარების საგნ(ებ)ის უარყოფა ',
        className: 'App\\Notifications\\Documents\\Purchases\\InvoiceDeclineNotification'
    },
    {
        title: 'მიღება-ჩაბარების სტატუსის ცვლილება',
        className: 'App\\Notifications\\Documents\\Purchases\\InvoiceStatusChangeNotification'
    },
    {
        title: 'მიღება-ჩაბარების შეჩერება ',
        className: 'App\\Notifications\\Documents\\Purchases\\InvoiceSuspendStatusNotification'
    },
    {
        title: 'მიღება-ჩაბარების დასრულება ',
        className: 'App\\Notifications\\Documents\\Purchases\\InvoiceCompleteStatusNotification'
    },
    {
        title: 'მიღება-ჩაბარების ავტომატური დაბლოკვა ',
        className: 'App\\Notifications\\Documents\\Purchases\\InvoiceAutoBlockNotification'
    },
    {
        title: 'ხელმოწერილი მიღება-ჩაბარების ნახვა',
        className: 'App\\Notifications\\Documents\\Purchases\\InvoiceNotifyDepsNotification'
    },
    {
        title: 'ინვოისის პროექტის სტატუსში დაბრუნება',
        className: 'App\\Notifications\\Documents\\Purchases\\InvoiceReturnToProjectStatusNotification'
    },
    // საწყობი 37 + 1
    {
        title: 'არავერიფიცირებული საგნის დოკუმენტზე მიმაგრება',
        className: 'App\\Notifications\\Documents\\Purchases\\SaveUnverifiedItemNotification'
    },
    // HR 38 + 16
    {
        title: 'პროექტის შენახვა',
        className: 'App\\Notifications\\Documents\\Purchases\\ProjectSaveNotification'
    },
    {
        title: 'სტრუქტურული ერთეულის სახელის ცვლილება პროექტის მიხედვით',
        className: 'App\\Notifications\\References\\StructuralUnit\\StructuralUnitNameDynamicChangeNotification'
    },
    {
        title: 'პროექტის ბიუჯეტის წყაროს ცვლილება',
        className: 'App\\Notifications\\References\\StructuralUnit\\StructuralUnitBudgetSourceDynamicChangedNotification'
    },
    {
        title: 'სტრუქტურული ერთეულების შექმნა პროექტის მიხედვით',
        className: 'App\\Notifications\\References\\StructuralUnit\\StructuralUnitDynamicCreateNotification'
    },
    {
        title: 'წარდგინების გადამოწმების ვერიფიკაციის პროცესის დაწყება',
        className: 'App\\Notifications\\Documents\\HumanResources\\Assign\\SubmissionVerifyItemsNeedsVerifyNotification'
    },
    {
        title: 'წარდგინების უარყოფა',
        className: 'App\\Notifications\\Documents\\HumanResources\\Assign\\SubmissionDeclineNotification'
    },
    {
        title: 'წარდგინების გადამოწმების შეჩერება',
        className: 'App\\Notifications\\Documents\\HumanResources\\Assign\\SubmissionVerifySuspendNotification'
    },
    {
        title: 'წარდგინების გადამოწმების პროექტის უარყოფა',
        className: 'App\\Notifications\\Documents\\HumanResources\\Assign\\SubmissionVerifyPositionProjectDeclineNotification'
    },
    {
        title: 'წარდგინების გადამოწმების გაგზავნა',
        className: 'App\\Notifications\\Documents\\HumanResources\\Assign\\SubmissionVerifySendNotification'
    },
    {
        title: 'წარდგინება გაგზავნილია გადასამოწმებლად',
        className: 'App\\Notifications\\Documents\\HumanResources\\Assign\\SubmissionSentToVerifyNotification'
    },
    {
        title: 'წარდგინების პოზიციის ინფორმაციის პროექტის კომენტარის დამატება',
        className: 'App\\Notifications\\Documents\\HumanResources\\Assign\\SubmissionPositionInfoProjectAddCommentNotification'
    },
    {
        title: '10 დღით ადრე შრომითი ხელშეკრულების ვადის გასვლა',
        className: 'App\\Notifications\\Documents\\HumanResources\\Assign\\NotifyRequesterAndHeadOfHrExpireContractNotification'
    },
    {
        title: 'შრომითი ხელშეკრულების ვადის გასვლა',
        className: 'App\\Notifications\\Documents\\HumanResources\\Assign\\ExpireContractNotification'
    },
    {
        title: 'ცალმხრივად ხელმოწერილი შრომითი ხელშეკრულება',
        className: 'App\\Notifications\\Documents\\ContractFiles\\ApprovalContractFileSignNotification'
    },
    {
        title: 'ხელმოწერილი შრომითი ხელშეკრულება',
        className: 'App\\Notifications\\Documents\\ContractFiles\\EmployeeContractFileSignNotification'
    },
    {
        title: 'კომენტარები',
        className: 'App\\Notifications\\Documents\\ContractFiles\\UserCommentOnContractFileNotification'
    },
    // ზოგადი 54 + 3
    {
        title: 'ვადაგასული საგრანტო პროექტი',
        className: 'App\\Notifications\\References\\Project\\ExpiredGrantProjectNotification'
    },
    {
        title: 'შვებულებაში გასვლის ნოტიფიკაცია დეპარტამენტის უფროსს',
        className: 'App\\Notifications\\Documents\\HumanResources\\Vacation\\HeadOfDepartmentNotification'
    },
    {
        title: 'შვებულებაში გასვლის ნოტიფიკაცია შემცვლელ თანამშრომელს',
        className: 'App\\Notifications\\Documents\\HumanResources\\Vacation\\SubstituteUserNotification'
    }
];
const parseUserSettings = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const userSettings = response.content;
    try {
        const settings = exports.notifications.map((notification, index) => {
            const userNotification = userSettings.data.attributes.notify?.find((el) => el.className === notification.className);
            return {
                notificationType: exports.channels.map(channel => {
                    return {
                        [channel.name + '-' + index]: (userNotification && Boolean(userNotification.channels & channel.key)) || false
                    };
                }).flat().reduce((value, obj) => {
                    return { ...value, ...obj };
                }),
                title: notification.title,
                channelType: exports.channels.map(el => {
                    return {
                        name: el.name,
                        modifiedName: el.name + '-' + index,
                        key: el.key
                    };
                })
            };
        });
        return {
            data: {
                locale: userSettings.data.attributes.locale,
                settings,
                selectMails: settings.every(el => Object.values(el.notificationType)[1]),
                selectSystems: settings.every(el => Object.values(el.notificationType)[0])
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseUserSettings = parseUserSettings;
const parseNotificationAsRead = (response) => {
    return response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS;
};
exports.parseNotificationAsRead = parseNotificationAsRead;
const getNotification = (el) => {
    const moduleBaseLink = exports.moduleLink[el.attributes.type];
    const linkTail = (() => {
        if (el.attributes.data.isCorrection)
            return el.attributes.data.id + '/corrections';
        if (el.attributes.data.name)
            return `?filters[keyword]=${el.attributes.data.name.split(' ').join('+')}`;
        return el.attributes.data.id + '/edit';
    })();
    return {
        id: el.id,
        title: el.attributes.title,
        text: el.attributes.text,
        link: moduleBaseLink + linkTail,
        readAt: el.attributes.readAt,
        read: !!el.attributes.readAt,
        time: (0, parsers_1.transformDate)(el.attributes.createdAt, 'dd-MM-yyyy HH:mm:ss')
    };
};
exports.moduleLink = {
    'TestNotification': '/',
    'App\\Notifications\\BasicNotification': '/',
    'App\\Notifications\\Documents\\Purchases\\SubmissionStatusChangeNotification': '/documents/purchases/submission-verifies/',
    'App\\Notifications\\Documents\\Purchases\\SubmissionVerifyStatusChangeNotification': '/documents/purchases/submission-verifies/',
    'App\\Notifications\\Documents\\Purchases\\ProcurementReturnToProjectStatusNotification': '/documents/purchases/procurements/',
    'App\\Notifications\\Documents\\Purchases\\VerifyItemStatusChangeNotification': '/documents/purchases/submission-verifies/',
    'App\\Notifications\\Documents\\Purchases\\VerifyItemFinanceItemsChangeNotification': '/documents/purchases/submission-verifies/',
    'App\\Notifications\\Documents\\Purchases\\ProcurementChangeStatusToRevertNotification': '/documents/purchases/procurements/',
    'App\\Notifications\\Documents\\Purchases\\ProcurementApprovedStatusNotification': '/documents/purchases/procurements/',
    'App\\Notifications\\Documents\\Purchases\\ProcurementChangeNotification': '/documents/purchases/procurements/',
    'App\\Notifications\\Documents\\Purchases\\ProcurementSignedStatusNotification': '/documents/purchases/procurements/',
    'App\\Notifications\\Documents\\Purchases\\ProcurementVerifyStatusNotification': '/documents/purchases/procurements/',
    'App\\Notifications\\Documents\\Purchases\\ProcurementActuallyDoneNotification': '/documents/purchases/procurements/',
    'App\\Notifications\\Documents\\Purchases\\ConditionalSubmissionSuspendNotification': '/documents/purchases/conditional-submissions/',
    'App\\Notifications\\Documents\\Purchases\\ConditionalSubmissionDeclineNotification': '/documents/purchases/conditional-submissions/',
    'App\\Notifications\\Documents\\Purchases\\SubmissionItemCharacteristicsNotification': '/documents/purchases/submissions/',
    'App\\Notifications\\Documents\\Purchases\\ProcurementSuspendedStatusNotification': '/documents/purchases/procurements/',
    'App\\Notifications\\Documents\\Purchases\\ProcurementDeclinedStatusNotification': '/documents/purchases/procurements/',
    'App\\Notifications\\Documents\\Purchases\\InvoiceStatusChangeNotification': '/documents/purchases/invoices/',
    'App\\Notifications\\Documents\\Purchases\\InvoiceReturnToProjectStatusNotification': '/documents/purchases/invoices/',
    'App\\Notifications\\Documents\\Purchases\\ProcurementSignedStatusAndCmrSetNotification': '/documents/purchases/procurements/',
    'App\\Notifications\\Documents\\Purchases\\InvoiceNotifyDepsNotification': '/documents/purchases/invoices/',
    'App\\Notifications\\Documents\\Purchases\\SubmissionNewExpirationDateNotification': '/documents/purchases/submissions/',
    'App\\Notifications\\Documents\\Purchases\\ProcurementAlarmNotification': '/documents/purchases/procurements/',
    'App\\Notifications\\References\\InventoryConfigurationVerifyNotification': '/references/purchases/purchase-subjects/',
    'App\\Notifications\\Documents\\Purchases\\ProcurementSendSignatureDocumentNotification': '/documents/purchases/procurements/',
    'App\\Notifications\\Documents\\Purchases\\ProcurementItemRemoveFromDocumentNotification': '/documents/purchases/procurements/',
    'App\\Notifications\\Documents\\Purchases\\InvoiceCompleteStatusNotification': '/documents/purchases/invoices/',
    'App\\Notifications\\Documents\\Purchases\\InvoiceSuspendStatusNotification': '/documents/purchases/invoices/',
    'App\\Notifications\\Documents\\HumanResources\\Vacation\\SubstituteUserNotification': '/documents/human-resources/vacations/',
    'App\\Notifications\\Documents\\HumanResources\\Vacation\\HeadOfDepartmentNotification': '/documents/human-resources/vacations/',
    'App\\Notifications\\Documents\\Purchases\\ConditionalSubmissionAddCommentNotification': '/documents/purchases/conditional-submissions/',
    'App\\Notifications\\Documents\\Purchases\\VerifyItemDeclineStatusNotification': '/documents/purchases/submission-verifies/',
    'App\\Notifications\\Documents\\Purchases\\InvoiceDeclineNotification': '/documents/purchases/invoices/',
    'App\\Notifications\\Documents\\Purchases\\ConfirmSubmissionExpirationDateNotification': '/documents/purchases/submissions/',
    'App\\Notifications\\Documents\\Purchases\\DeclineSubmissionExpirationDateNotification': '/documents/purchases/submissions/',
    'App\\Notifications\\Documents\\Purchases\\SubmissionVerifyDeclineNotification': '/documents/purchases/submission-verifies/',
    'App\\Notifications\\Documents\\Purchases\\ProjectSaveNotification': '/references/funding/projects/',
    'App\\Notifications\\References\\InventoryConfigurationVerifiedNotification': '/documents/purchases/conditional-submissions/',
    'App\\Notifications\\Documents\\Purchases\\SaveUnverifiedItemNotification': '/documents/purchases/conditional-submissions/',
    'App\\Notifications\\References\\StructuralUnit\\StructuralUnitBudgetSourceDynamicChangedNotification': '/references/structural-units/',
    'App\\Notifications\\References\\StructuralUnit\\StructuralUnitNameDynamicChangeNotification': '/references/structural-units/',
    'App\\Notifications\\References\\StructuralUnit\\StructuralUnitDynamicCreateNotification': '/references/structural-units/',
    'App\\Notifications\\Documents\\Purchases\\InvoiceAutoBlockNotification': '/documents/purchases/invoices/',
    'App\\Notifications\\Documents\\HumanResources\\Assign\\SubmissionVerifyItemsNeedsVerifyNotification': '/documents/human-resources/assign-submission-verifies/',
    'App\\Notifications\\Documents\\HumanResources\\Assign\\SubmissionDeclineNotification': '/documents/human-resources/assign-submissions/',
    'App\\Notifications\\Documents\\HumanResources\\Assign\\ExpireContractNotification': '/documents/human-resources/assign-contracts/',
    'App\\Notifications\\Documents\\HumanResources\\Assign\\SubmissionVerifySuspendNotification': '/documents/human-resources/assign-submissions/',
    'App\\Notifications\\Documents\\HumanResources\\Assign\\SubmissionVerifyPositionProjectDeclineNotification': '/documents/human-resources/assign-submission-verifies/',
    'App\\Notifications\\Documents\\HumanResources\\Assign\\SubmissionVerifySendNotification': '/documents/human-resources/assign-submission-verifies/',
    'App\\Notifications\\Documents\\HumanResources\\Assign\\SubmissionSentToVerifyNotification': '/documents/human-resources/assign-submissions/',
    'App\\Notifications\\Documents\\HumanResources\\Assign\\SubmissionPositionInfoProjectAddCommentNotification': '/documents/human-resources/assign-submission-verifies/',
    'App\\Notifications\\References\\Project\\ExpiredGrantProjectNotification': '/references/funding/projects/',
    'App\\Notifications\\Documents\\HumanResources\\Assign\\NotifyRequesterAndHeadOfHrExpireContractNotification': '/documents/human-resources/assign/contracts/',
    'App\\Notifications\\Documents\\ContractFiles\\ApprovalContractFileSignNotification': '/documents/human-resources/assign-contracts/',
    'App\\Notifications\\Documents\\ContractFiles\\EmployeeContractFileSignNotification': '/documents/human-resources/assign-contracts/',
    'App\\Notifications\\Documents\\ContractFiles\\UserCommentOnContractFileNotification': '/documents/human-resources/assign-contracts/',
    'App\\Notifications\\Documents\\Purchases\\ProcurementUnilateralTerminationNotification': '/documents/purchases/procurements/',
    'App\\Notifications\\Documents\\Purchases\\ProcurementBilateralTerminationNotification': '/documents/purchases/procurements/'
};
