"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const contractType_1 = require("../store/contractType");
const index_1 = require("api/index");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useContractType = (props) => {
    const [state, dispatch] = (0, contractType_1.useContractTypeReducer)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const getContractType = async (id) => {
        dispatch({ type: 'SEND_CONTRACT_TYPE_REQUEST' });
        const contractType = await index_1.HRContractTypesApi.getContractType(id);
        dispatch(({ type: 'DONE_CONTRACT_TYPE_REQUEST', payload: contractType }));
    };
    const createContractType = async (values) => {
        const params = (0, helpers_1.transformContractTypeParams)(values);
        dispatch({ type: 'SEND_CONTRACT_TYPE_CREATE' });
        const contractType = await index_1.HRContractTypesApi.createContractType(params);
        dispatch({ type: 'DONE_CONTRACT_TYPE_CREATE', payload: contractType });
        if (contractType.data !== null) {
            navigate(`/references/human-resources/contract-types/${contractType.data.id}/edit`);
        }
        return contractType;
    };
    const updateContractType = async (id, values) => {
        const params = (0, helpers_1.transformContractTypeParams)(values);
        dispatch({ type: 'SEND_CONTRACT_TYPE_UPDATE' });
        const contractType = await index_1.HRContractTypesApi.updateContractType(id, params);
        dispatch({ type: 'DONE_CONTRACT_TYPE_UPDATE', payload: contractType });
        return contractType;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getContractType(props.id).then(); }, [props.id]);
    return {
        state,
        createContractType,
        updateContractType,
        closeErrorMessage
    };
};
exports.default = useContractType;
