"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dimensionUnitReducer = exports.useDimensionUnitReducer = exports.initialDimensionUnitState = void 0;
const react_1 = require("react");
const initialDimensionUnitValue = { name: '' };
exports.initialDimensionUnitState = {
    data: initialDimensionUnitValue,
    errors: null,
    status: null,
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isLoading: false
};
const useDimensionUnitReducer = () => {
    return (0, react_1.useReducer)(exports.dimensionUnitReducer, exports.initialDimensionUnitState);
};
exports.useDimensionUnitReducer = useDimensionUnitReducer;
const dimensionUnitReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_DIMENSION_UNIT_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_DIMENSION_UNIT_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isFetching: false,
                isLoading: state.isCreating || state.isUpdating
            };
        case 'SEND_DIMENSION_UNIT_CREATE':
            return {
                ...state,
                errors: null,
                isCreating: true,
                isLoading: true
            };
        case 'DONE_DIMENSION_UNIT_CREATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                isCreating: false,
                status: action.payload.status,
                isLoading: state.isFetching || state.isUpdating
            };
        case 'SEND_DIMENSION_UNIT_UPDATE':
            return {
                ...state,
                errors: null,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_DIMENSION_UNIT_UPDATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                isUpdating: false,
                status: action.payload.status,
                isLoading: state.isCreating || state.isFetching
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.dimensionUnitReducer = dimensionUnitReducer;
