"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHumanResourcesSubmissionsVerifyItems = void 0;
const useCascadeItemsReducrer_1 = require("../store/useCascadeItemsReducrer");
const index_1 = require("api/index");
const react_1 = require("react");
const useHumanResourcesSubmissionsVerifyItems = (id) => {
    const [state, dispatch] = (0, useCascadeItemsReducrer_1.useHumanResourceSubmissionsVerifyItemsReducer)();
    const getHumanResourcesSubmissionsVerifyItems = async (page) => {
        const params = page ? { page } : {};
        dispatch({ type: 'SEND_HUMAN_RESOURCES_SUBMISSIONS_VERIFY_ITEMS_REQUEST' });
        const items = await index_1.HumanResourcesSubmissionVerifiesApi.getHrAssignSubmissionVerifyItems(id, params);
        dispatch({ type: 'DONE_HUMAN_RESOURCES_SUBMISSIONS_VERIFY_ITEMS_REQUEST', payload: items });
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { getHumanResourcesSubmissionsVerifyItems().then(); }, []);
    return { state, getHumanResourcesSubmissionsVerifyItems, closeErrorMessage };
};
exports.useHumanResourcesSubmissionsVerifyItems = useHumanResourcesSubmissionsVerifyItems;
