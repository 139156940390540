"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseLanguagesForSelect = exports.parseCountriesForSelect = exports.parseCountry = exports.parseCountries = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseCountries = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const countries = response.content;
    try {
        return {
            data: countries.data.map(el => {
                return {
                    id: el.id,
                    moduleType: el.type,
                    abbrev: el.attributes.abbrev,
                    callingCode: el.attributes.callingCode,
                    capital: el.attributes.capital,
                    currency: el.attributes.currency,
                    lat: el.attributes.lat,
                    lon: el.attributes.lon,
                    // name: el.attributes.name,
                    nameEn: el.attributes.nameEn,
                    nameKa: el.attributes.nameKa,
                    region: el.attributes.region
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(countries.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseCountries = parseCountries;
const parseCountry = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const country = response.content;
    try {
        return {
            data: {
                id: country.data.id,
                abbrev: country.data.attributes.abbrev,
                callingCode: country.data.attributes.callingCode,
                capital: country.data.attributes.capital,
                currency: country.data.attributes.currency,
                nameEn: country.data.attributes.nameEn,
                nameKa: country.data.attributes.nameKa,
                region: country.data.attributes.region
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseCountry = parseCountry;
const parseCountriesForSelect = (response) => {
    if (response.content === null)
        return [];
    const countries = response.content;
    try {
        return countries.data.map(el => ({
            id: el.id,
            label: el.attributes.nameKa || el.attributes.nameEn
        }));
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return [];
    }
};
exports.parseCountriesForSelect = parseCountriesForSelect;
const parseLanguagesForSelect = (response) => {
    if (response.content === null)
        return [];
    const languages = response.content;
    try {
        return languages.data.map(language => {
            return {
                id: language.id,
                label: language.attributes.nameKa ?? language.attributes.nameEn
            };
        });
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return [];
    }
};
exports.parseLanguagesForSelect = parseLanguagesForSelect;
