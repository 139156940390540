"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.vacationReducer = exports.useVacationReducer = exports.initialVacationState = void 0;
const react_1 = require("react");
const initialVacationValue = {
    user: null,
    structuralUnit: null,
    substituteUser: null,
    substituteStructuralUnit: null,
    comment: '',
    startDate: null,
    endDate: null
};
exports.initialVacationState = {
    data: initialVacationValue,
    errors: null,
    status: null,
    isFetching: false,
    isCreating: false,
    isLoading: false,
    isUpdating: false
};
const useVacationReducer = () => {
    return (0, react_1.useReducer)(exports.vacationReducer, exports.initialVacationState);
};
exports.useVacationReducer = useVacationReducer;
const vacationReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_VACATION_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_VACATION_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isFetching: false,
                isLoading: state.isCreating || state.isUpdating
            };
        case 'SEND_VACATION_CREATE':
            return {
                ...state,
                errors: null,
                isCreating: true,
                isLoading: true
            };
        case 'DONE_VACATION_CREATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                isCreating: false,
                status: action.payload.status,
                isLoading: state.isFetching || state.isUpdating
            };
        case 'SEND_VACATION_UPDATE':
            return {
                ...state,
                errors: null,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_VACATION_UPDATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                isUpdating: false,
                status: action.payload.status,
                isLoading: state.isCreating || state.isFetching
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.vacationReducer = vacationReducer;
