"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInputWidth = void 0;
const react_1 = require("react");
const useInputWidth = (ref) => {
    const [windowWidth, setWindowWidth] = (0, react_1.useState)(window.innerWidth);
    const [refWidth, setRefWidth] = (0, react_1.useState)(0);
    (0, react_1.useEffect)(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    (0, react_1.useEffect)(() => { setRefWidth(ref.current?.clientWidth); }, [windowWidth]);
    return { refWidth };
};
exports.useInputWidth = useInputWidth;
