"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InventoryConfigurationType = exports.VerifiedStatus = void 0;
var VerifiedStatus;
(function (VerifiedStatus) {
    VerifiedStatus[VerifiedStatus["UNVERIFIED"] = 0] = "UNVERIFIED";
    VerifiedStatus[VerifiedStatus["VERIFIED"] = 1] = "VERIFIED";
})(VerifiedStatus || (exports.VerifiedStatus = VerifiedStatus = {}));
var InventoryConfigurationType;
(function (InventoryConfigurationType) {
    InventoryConfigurationType[InventoryConfigurationType["BOOK"] = 1] = "BOOK";
    InventoryConfigurationType[InventoryConfigurationType["NATIONWIDE"] = 2] = "NATIONWIDE";
})(InventoryConfigurationType || (exports.InventoryConfigurationType = InventoryConfigurationType = {}));
