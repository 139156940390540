"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.administrativeOrderReducer = exports.useAdministrativeOrderReducer = exports.initialAdministrativeOrderState = void 0;
const react_1 = require("react");
const initialAdministrativeOrderValue = {
    number: '',
    name: '',
    publicationDate: '',
    createdBy: '',
    files: [],
    deletedAt: null,
    createdAt: '',
    createdDateTimeAt: '',
    articleNumber: ''
};
exports.initialAdministrativeOrderState = {
    data: initialAdministrativeOrderValue,
    errors: null,
    status: null,
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isLoading: false
};
const useAdministrativeOrderReducer = () => {
    return (0, react_1.useReducer)(exports.administrativeOrderReducer, exports.initialAdministrativeOrderState);
};
exports.useAdministrativeOrderReducer = useAdministrativeOrderReducer;
const administrativeOrderReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_ADMINISTRATIVE_ORDER_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_ADMINISTRATIVE_ORDER_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isFetching: false,
                isLoading: state.isCreating || state.isUpdating
            };
        case 'SEND_ADMINISTRATIVE_ORDER_CREATE':
            return {
                ...state,
                isCreating: true,
                isLoading: true
            };
        case 'DONE_ADMINISTRATIVE_ORDER_CREATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                isCreating: false,
                status: action.payload.status,
                isLoading: state.isFetching || state.isUpdating
            };
        case 'SEND_ADMINISTRATIVE_ORDER_UPDATE':
            return {
                ...state,
                errors: null,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_ADMINISTRATIVE_ORDER_UPDATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                isUpdating: false,
                status: action.payload.status,
                isLoading: state.isCreating || state.isFetching
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.administrativeOrderReducer = administrativeOrderReducer;
