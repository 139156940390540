"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.taxReducer = exports.useTaxReducer = exports.initialTaxesState = exports.initialTaxesValue = void 0;
const react_1 = require("react");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
exports.initialTaxesValue = {
    taxOptions: [],
    taxReliefBenefit: apiGlobalTypes_1.YesNoStatus.NO,
    useOfTaxRelief: false,
    taxReliefAmount: null,
    taxReliefId: null
};
exports.initialTaxesState = {
    data: exports.initialTaxesValue,
    status: null,
    errors: null,
    isFetching: false,
    isUpdating: false,
    loadingItems: [],
    isOptionFetching: false,
    isLoading: false
};
const useTaxReducer = () => {
    return (0, react_1.useReducer)(exports.taxReducer, exports.initialTaxesState);
};
exports.useTaxReducer = useTaxReducer;
const taxReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_TAX_VALUE_REQUEST':
            return {
                ...state,
                errors: null,
                isLoading: true,
                isFetching: true
            };
        case 'DONE_TAX_VALUE_REQUEST':
            return {
                ...state,
                data: {
                    ...state.data,
                    taxReliefAmount: action.payload.data?.taxReliefAmount || state.data.taxReliefAmount,
                    taxReliefId: action.payload.data !== null ? action.payload.data.taxRelief : state.data.taxReliefId,
                    taxReliefBenefit: action.payload.data !== null ? action.payload.data.taxReliefBenefit : state.data.taxReliefBenefit,
                    useOfTaxRelief: action.payload.data !== null ? action.payload.data.useOfTaxRelief : state.data.useOfTaxRelief
                },
                status: action.payload.status,
                errors: action.payload.errors,
                isFetching: false,
                isLoading: state.isUpdating || state.isOptionFetching
            };
        case 'SEND_TAX_UPDATE':
            return {
                ...state,
                errors: null,
                isLoading: true,
                isUpdating: true
            };
        case 'DONE_TAX_UPDATE':
            return {
                ...state,
                data: {
                    ...state.data,
                    taxReliefAmount: action.payload.data?.taxReliefAmount || state.data.taxReliefAmount,
                    taxReliefId: action.payload.data !== null ? action.payload.data.taxRelief : state.data.taxReliefId,
                    taxReliefBenefit: action.payload.data !== null ? action.payload.data.taxReliefBenefit : state.data.taxReliefBenefit,
                    useOfTaxRelief: action.payload.data !== null ? action.payload.data.useOfTaxRelief : state.data.useOfTaxRelief
                },
                status: action.payload.status,
                errors: action.payload.errors,
                isUpdating: false,
                isLoading: state.isFetching || state.isOptionFetching
            };
        case 'SEND_TAX_OPTIONS_REQUEST':
            return {
                ...state,
                errors: null,
                isLoading: true,
                isOptionFetching: true
            };
        case 'DONE_TAX_OPTIONS_REQUEST':
            return {
                ...state,
                data: {
                    ...state.data,
                    taxOptions: action.payload.data || state.data.taxOptions
                },
                status: action.payload.status,
                errors: action.payload.errors,
                isOptionFetching: false,
                isLoading: state.isFetching || state.isUpdating
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.taxReducer = taxReducer;
