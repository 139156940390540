"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.invoiceReducer = exports.useInvoiceReducer = exports.initialInvoiceNavigate = exports.initialInvoiceState = exports.initialInvoiceValue = void 0;
const react_1 = require("react");
const types_1 = require("api/documents/purchases/invoices/types");
exports.initialInvoiceValue = {
    incomeType: null,
    procurement: null,
    creationDate: null,
    company: null,
    deliveryPlace: '',
    number: '',
    declineReason: '',
    suspendReason: '',
    warehouse: null,
    receiptDate: null,
    companySignatory: null,
    companySignatoryPhone: null,
    sentToWarehouse: false,
    sentToAccounting: false,
    creatorId: null,
    creatorAccountId: null,
    status: types_1.InvoiceStatuses.DRAFT,
    subStatus: types_1.InvoiceSubStatuses.SUB_STATUS_COMPLETED,
    invoiceType: types_1.InvoiceType.DEFAULT,
    fine: null,
    fineAccount: null,
    signatories: null,
    template: null,
    files: [],
    invoiceFile: null,
    visualStatus: { name: 'პროექტი', color: 'secondary' },
    subVisualStatus: { name: 'შეუსრულებელი', color: 'secondary' }
};
exports.initialInvoiceState = {
    data: exports.initialInvoiceValue,
    status: null,
    errors: null,
    isFetching: false,
    isLoading: false,
    isCreating: false,
    isUpdating: false,
    isDeclining: false,
    isSuspending: false,
    isNotifyingDepartments: false,
    isChangingStatus: false,
    isChangingSubStatus: false
};
exports.initialInvoiceNavigate = {
    next: false,
    previous: false,
    isLoading: false,
    lastElement: false,
    firstElement: false
};
const useInvoiceReducer = () => {
    return (0, react_1.useReducer)(exports.invoiceReducer, exports.initialInvoiceState);
};
exports.useInvoiceReducer = useInvoiceReducer;
const invoiceReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_INVOICE_REQUEST':
            return {
                ...state,
                isFetching: true,
                isLoading: true,
                errors: null
            };
        case 'DONE_INVOICE_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isFetching: false,
                isLoading: state.isCreating
                    || state.isUpdating
                    || state.isChangingStatus
                    || state.isChangingSubStatus
                    || state.isDeclining
                    || state.isSuspending
                    || state.isNotifyingDepartments
            };
        case 'SEND_INVOICE_CREATE':
            return {
                ...state,
                errors: null,
                isCreating: true,
                isLoading: true
            };
        case 'DONE_INVOICE_CREATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isCreating: false,
                isLoading: state.isFetching
                    || state.isUpdating
                    || state.isChangingStatus
                    || state.isChangingSubStatus
                    || state.isDeclining
                    || state.isSuspending
                    || state.isNotifyingDepartments
            };
        case 'SEND_INVOICE_UPDATE':
            return {
                ...state,
                errors: null,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_INVOICE_UPDATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isUpdating: false,
                isLoading: state.isFetching
                    || state.isCreating
                    || state.isChangingStatus
                    || state.isChangingSubStatus
                    || state.isDeclining
                    || state.isSuspending
                    || state.isNotifyingDepartments
            };
        case 'SEND_CHANGE_STATUS':
            return {
                ...state,
                isChangingStatus: true,
                isLoading: true
            };
        case 'DONE_CHANGE_STATUS':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isChangingStatus: false,
                isLoading: state.isFetching
                    || state.isUpdating
                    || state.isCreating
                    || state.isChangingSubStatus
                    || state.isDeclining
                    || state.isSuspending
                    || state.isNotifyingDepartments
            };
        case 'SEND_CHANGE_SUB_STATUS':
            return {
                ...state,
                isChangingSubStatus: true,
                isLoading: true
            };
        case 'DONE_CHANGE_SUB_STATUS':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isChangingSubStatus: false,
                isLoading: state.isFetching
                    || state.isUpdating
                    || state.isCreating
                    || state.isChangingStatus
                    || state.isDeclining
                    || state.isSuspending
                    || state.isNotifyingDepartments
            };
        case 'SEND_INVOICE_DECLINE':
            return {
                ...state,
                isDeclining: true,
                isLoading: true
            };
        case 'DONE_INVOICE_DECLINE':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isDeclining: false,
                isLoading: state.isFetching
                    || state.isUpdating
                    || state.isCreating
                    || state.isChangingStatus
                    || state.isChangingSubStatus
                    || state.isSuspending
                    || state.isNotifyingDepartments
            };
        case 'SEND_INVOICE_SUSPEND':
            return {
                ...state,
                isSuspending: true,
                isLoading: true
            };
        case 'DONE_INVOICE_SUSPEND':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isSuspending: false,
                isLoading: state.isFetching
                    || state.isUpdating
                    || state.isCreating
                    || state.isChangingStatus
                    || state.isChangingSubStatus
                    || state.isDeclining
                    || state.isNotifyingDepartments
            };
        case 'SEND_NOTIFY_DEPARTMENTS':
            return {
                ...state,
                isNotifyingDepartments: true,
                isLoading: true
            };
        case 'DONE_NOTIFY_DEPARTMENTS':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isNotifyingDepartments: false,
                isLoading: state.isFetching
                    || state.isUpdating
                    || state.isCreating
                    || state.isChangingStatus
                    || state.isChangingSubStatus
                    || state.isDeclining
                    || state.isSuspending
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.invoiceReducer = invoiceReducer;
