"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAssignSubmissionItemsForm = void 0;
const index_1 = require("api/index");
const react_1 = require("react");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const useCreateOrUpdateItems_1 = require("../store/useCreateOrUpdateItems");
const helpers_1 = require("core/helpers");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helpers_2 = require("../helpers");
const queryString_1 = require("core/helpers/queryString");
const react_router_dom_1 = require("react-router-dom");
const useAssignSubmissionItemsForm = (props) => {
    const [state, dispatch] = (0, useCreateOrUpdateItems_1.useCreateOrUpdateItemsReducer)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [keyword, setKeyword] = (0, react_1.useState)('');
    const [currencies, setCurrencies] = (0, react_1.useState)([]);
    const [cascadedItems, setCascadedItems] = (0, react_1.useState)([]);
    const [deletingItem, setDeletingItem] = (0, react_1.useState)({ showAlert: false, ids: [] });
    const [elRefs, setElRefs] = (0, react_1.useState)([]);
    const [dirtyForms, setDirtyForms] = (0, react_1.useState)([]);
    const [selectedItems, setSelectedItems] = (0, react_1.useState)([]);
    const [showCreateHRVerifyAlert, setShowCreateHRVerifyAlert] = (0, react_1.useState)(false);
    const getItems = async (params) => {
        dispatch({ type: 'SEND_HR_SUBMISSION_ITEMS_REQUEST' });
        const submissionItemData = await index_1.HumanResourcesSubmissionsApi.getHRAssignSubmissionFormItems(props.id, params);
        const newSubmissionItemData = {
            ...submissionItemData,
            data: submissionItemData.data?.map(el => {
                return {
                    ...el,
                    checked: selectedItems.includes(el.id)
                };
            }) || null
        };
        if (submissionItemData.data && submissionItemData.data.length > 0)
            props.setShowSendButton(true);
        navigate(`/documents/human-resources/assign-submissions/${props.id}/edit` + (0, queryString_1.stringify)(params), { replace: true });
        dispatch({ type: 'DONE_HR_SUBMISSION_ITEMS_REQUEST', payload: newSubmissionItemData });
    };
    const createHRVerify = async () => {
        const params = (0, helpers_2.transformCreateHRVerifyParams)(selectedItems);
        dispatch({ type: 'SEND_CREATE_HR_VERIFY_REQUEST' });
        const hrVerify = await index_1.HumanResourcesSubmissionsApi.createHRVerify(params);
        dispatch({ type: 'DONE_CREATE_HR_VERIFY_REQUEST', payload: { errors: hrVerify.errors } });
        if (hrVerify.data !== null)
            navigate(`/documents/human-resources/assign-submission-verifies/${hrVerify.data.id}/edit`, { replace: true });
        return hrVerify;
    };
    const selectAllItem = (checked, itemIds) => {
        if (checked) {
            // ეს ლოგიკა წერია რადგან პეგინეითიდ დროს არ უნდა დამეკარგოს პინა გვერდზე მონიშნული საგნები
            const filteredItems = itemIds.filter(el => !selectedItems.includes(el));
            setSelectedItems(prevState => ([...prevState, ...filteredItems]));
        }
        else {
            setSelectedItems(prevState => prevState.filter(el => !itemIds.includes(el)));
        }
    };
    const deleteAssignSubmissionItem = async () => {
        setDeletingItem(({ ...deletingItem, showAlert: false }));
        const page = (0, helpers_1.calculateCurrentPage)(state.meta.pagination, deletingItem.ids.length);
        dispatch({ type: 'SEND_HR_SUBMISSION_ITEM_DELETE_REQUEST' });
        const response = await index_1.HumanResourcesSubmissionsApi.deleteHRAssignSubmissionItem(props.id, { ids: deletingItem.ids });
        if (page && response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS) {
            const params = (0, queryString_1.parse)(location.search);
            getItems({ ...params, page }).then();
        }
        else {
            dispatch({ type: 'DONE_HR_SUBMISSION_ITEM_DELETE_REQUEST', payload: response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS ? deletingItem.ids : [] });
        }
        return { errors: response.errors, status: response.status };
    };
    const selectPerPage = (perPage) => {
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'CHANGE_PER_PAGE', payload: perPage });
        getItems({ ...params, perPage, page: undefined }).then();
    };
    const handleCopyAssignSubmissionItem = (items, item, itemIndex) => {
        const newArray = [...items];
        newArray.splice(itemIndex + 1, 0, { ...item,
            id: null,
            positionInfos: item.positionInfos.map(position => ({
                ...position,
                id: null,
                projectInfos: position.projectInfos.map(el => ({
                    ...el,
                    id: null,
                    workInfos: el.workInfos.map(workInfo => ({
                        ...workInfo,
                        id: null,
                        remunerationWorks: workInfo.remunerationWorks.map(work => ({ ...work, id: null })),
                        fixedReservations: workInfo.fixedReservations.map(reservation => ({ ...reservation, id: null })),
                        supplements: workInfo.supplements.map(supplement => ({ ...supplement, id: null })),
                        unitFees: workInfo.unitFees.map(unitFee => ({ ...unitFee, id: null }))
                    }))
                }))
            })) });
        return newArray;
    };
    const debouncedSearch = (value) => {
        setKeyword(value);
        const searchParams = (0, queryString_1.parse)(location.search);
        const params = value
            ? { perPage: searchParams.perPage, filters: { keyword: value } }
            : { page: searchParams.page };
        (0, debouncedMethods_1.debouncedOnFunc)(params, getItems);
    };
    const getCurrenciesForSelect = async (params) => {
        const data = await index_1.CurrenciesApi.getCurrencyForSelect(params);
        setCurrencies(data);
    };
    const navigatePagination = (page) => {
        const itemPage = Math.ceil(state.meta.pagination.total / state.meta.pagination.perPage) >= page ? page : page - 1;
        const params = (0, queryString_1.parse)(location.search);
        getItems({ ...params, page: itemPage }).then();
    };
    const handleCascadeItem = (id) => {
        cascadedItems.includes(id)
            ? setCascadedItems(cascadedItems.filter(el => el !== id))
            : setCascadedItems([...cascadedItems, id]);
    };
    const updateAssignSubmissionItemsList = async (id, values) => {
        const params = (0, helpers_2.transformAssignSubmissionItemsListParams)(values);
        const newItemsLength = values.filter(item => item.id === null).length;
        dispatch({ type: 'SEND_ITEMS_LIST_UPDATE_REQUEST' });
        const assignSubmissionItems = await index_1.HumanResourcesSubmissionsApi.updateHRAssignSubmissionItems(id, params);
        dispatch({ type: 'DONE_ITEMS_LIST_UPDATE_REQUEST', payload: { assignSubmissionItems, newItemsLength } });
        if (assignSubmissionItems.data !== null)
            props.setShowSendButton(true);
        return assignSubmissionItems;
    };
    const updateAssignSubmissionItem = async (id, value, index) => {
        const params = (0, helpers_2.transformAssignSubmissionItemsListParams)([value]);
        dispatch({ type: 'SEND_ITEM_UPDATE_REQUEST' });
        const assignSubmissionItems = await index_1.HumanResourcesSubmissionsApi.updateHRAssignSubmissionItems(id, params);
        if (value.id) {
            dispatch({ type: 'DONE_ITEM_UPDATE_REQUEST', payload: { item: assignSubmissionItems.data ? assignSubmissionItems.data[0] : null, index } });
        }
        else {
            dispatch({ type: 'DONE_ITEM_CREATE_REQUEST', payload: { item: assignSubmissionItems.data ? assignSubmissionItems.data[0] : null, index } });
        }
        if (assignSubmissionItems.data !== null)
            props.setShowSendButton(true);
        return assignSubmissionItems;
    };
    const updateStateItems = (items) => {
        dispatch({ type: 'UPDATE_STATE_ITEMS', payload: items });
    };
    (0, react_1.useEffect)(() => {
        if (!props.id)
            return;
        const params = (0, helpers_2.getItemsParamsFromUrl)(location.search);
        if (params.perPage)
            dispatch({ type: 'CHANGE_PER_PAGE', payload: params.perPage });
        setKeyword(params.filters?.keyword || '');
        getItems(params).then();
    }, [props.id]);
    (0, react_1.useEffect)(() => {
        getCurrenciesForSelect({ perPage: 300, sort: '+sort,-id' }).then();
    }, []);
    return {
        state,
        currencies,
        navigatePagination,
        cascadedItems,
        setCascadedItems,
        handleCascadeItem,
        deleteAssignSubmissionItem,
        deletingItem,
        setDeletingItem,
        selectPerPage,
        keyword,
        debouncedSearch,
        dirtyForms,
        setDirtyForms,
        elRefs,
        setElRefs,
        updateAssignSubmissionItemsList,
        updateStateItems,
        selectAllItem,
        selectedItems,
        createHRVerify,
        showCreateHRVerifyAlert,
        setShowCreateHRVerifyAlert,
        updateAssignSubmissionItem,
        handleCopyAssignSubmissionItem
    };
};
exports.useAssignSubmissionItemsForm = useAssignSubmissionItemsForm;
