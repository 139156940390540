"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteAssignSubmissionFile = exports.createHRVerify = exports.sendProgressInVerify = exports.declineItem = exports.getAddresseesAndPosition = exports.getAssignSubmissionFiles = exports.sendToChancellery = exports.updateHRAssignSubmissionItems = exports.updateHRAssignSubmission = exports.createHRAssignSubmission = exports.deleteHRAssignSubmissionItem = exports.getHRAssignSubmissionFormItems = exports.getHRAssignSubmissionItems = exports.deleteHRAssignSubmission = exports.getHRAssignSubmission = exports.getHumanResourceSubmissions = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("./parsers");
const getHumanResourceSubmissions = async (params) => {
    const response = await Api.get('documents/human-resources/assign/submissions', params);
    return (0, parsers_1.parseHumanResourceSubmissions)(response);
};
exports.getHumanResourceSubmissions = getHumanResourceSubmissions;
const getHRAssignSubmission = async (id) => {
    const response = await Api.get('documents/human-resources/assign/submissions/' + id);
    return (0, parsers_1.parseHumanResourceSubmission)(response);
};
exports.getHRAssignSubmission = getHRAssignSubmission;
const deleteHRAssignSubmission = async (id) => {
    return await Api.deleteItem('documents/human-resources/assign/submissions/' + id);
};
exports.deleteHRAssignSubmission = deleteHRAssignSubmission;
const getHRAssignSubmissionItems = async (id, params) => {
    const response = await Api.get(`documents/human-resources/assign/submissions/${id}/items`, params);
    return (0, parsers_1.parseHumanResourceSubmissionItems)(response);
};
exports.getHRAssignSubmissionItems = getHRAssignSubmissionItems;
const getHRAssignSubmissionFormItems = async (id, params) => {
    const response = await Api.get(`documents/human-resources/assign/submissions/${id}/items`, params);
    return (0, parsers_1.parseHRAssignSubmissionFormItems)(response);
};
exports.getHRAssignSubmissionFormItems = getHRAssignSubmissionFormItems;
const deleteHRAssignSubmissionItem = async (submissionId, params) => {
    return await Api.deleteItem(`documents/human-resources/assign/submissions/${submissionId}/items`, params);
};
exports.deleteHRAssignSubmissionItem = deleteHRAssignSubmissionItem;
const createHRAssignSubmission = async (params) => {
    const response = await Api.post('documents/human-resources/assign/submissions', params);
    return (0, parsers_1.parseHumanResourceSubmission)(response);
};
exports.createHRAssignSubmission = createHRAssignSubmission;
const updateHRAssignSubmission = async (id, params) => {
    const response = await Api.patch(`documents/human-resources/assign/submissions/${id}`, params);
    return (0, parsers_1.parseHumanResourceSubmission)(response);
};
exports.updateHRAssignSubmission = updateHRAssignSubmission;
const updateHRAssignSubmissionItems = async (submissionId, params) => {
    const response = await Api.patch(`documents/human-resources/assign/submissions/${submissionId}/items`, params);
    return (0, parsers_1.parseHRAssignSubmissionFormItem)(response);
};
exports.updateHRAssignSubmissionItems = updateHRAssignSubmissionItems;
const sendToChancellery = async (id) => {
    const response = await Api.patch(`documents/human-resources/assign/submissions/${id}/send`, {});
    return (0, parsers_1.parseHumanResourceSubmission)(response);
};
exports.sendToChancellery = sendToChancellery;
const getAssignSubmissionFiles = async (params, id) => {
    const response = await Api.get(`documents/human-resources/assign/submissions/${id}/export`, params);
    return (0, parsers_1.parseAssignSubmissionFiles)(response);
};
exports.getAssignSubmissionFiles = getAssignSubmissionFiles;
const getAddresseesAndPosition = async () => {
    const response = await Api.get('documents/human-resources/assign/submissions/addresses-and-positions');
    return (0, parsers_1.parseAddresseesAndPosition)(response);
};
exports.getAddresseesAndPosition = getAddresseesAndPosition;
const declineItem = async (assignSubmissionId, params) => {
    const response = await Api.patch(`documents/human-resources/assign/submissions/${assignSubmissionId}/decline`, params);
    return (0, parsers_1.parseHumanResourceSubmission)(response);
};
exports.declineItem = declineItem;
const sendProgressInVerify = async (id) => {
    const response = await Api.patch(`documents/human-resources/assign/submissions/${id}/send-for-verify`, {});
    return (0, parsers_1.parseHumanResourceSubmission)(response);
};
exports.sendProgressInVerify = sendProgressInVerify;
const createHRVerify = async (params) => {
    const response = await Api.post('documents/human-resources/assign/submission-verify', params);
    return (0, parsers_1.parseHumanResourceSubmission)(response);
};
exports.createHRVerify = createHRVerify;
const deleteAssignSubmissionFile = async (id, params) => {
    return await Api.patch('documents/human-resources/assign/submissions/remove-files/' + id, params);
};
exports.deleteAssignSubmissionFile = deleteAssignSubmissionFile;
