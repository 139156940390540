"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const index_1 = require("api/index");
const language_1 = require("../store/language");
const helper_1 = require("../helper");
const useLanguage = (props) => {
    const [state, dispatch] = (0, language_1.useLanguageReducer)();
    const [deleteLanguageAlert, setDeleteLanguageAlert] = (0, react_1.useState)({ showAlert: false, id: null });
    const getLanguages = async (id) => {
        dispatch({ type: 'SEND_LANGUAGES_REQUEST' });
        const languages = await index_1.EmployeesApi.getLanguages(id);
        dispatch({ type: 'DONE_LANGUAGES_REQUEST', payload: languages });
    };
    const addLanguage = async (values, id) => {
        const params = (0, helper_1.transformEmployeeLanguageParams)(values);
        dispatch({ type: 'SEND_LANGUAGE_CREATE_OR_UPDATE' });
        const employeeLanguagesData = await index_1.EmployeesApi.addLanguage(params, id);
        dispatch({ type: 'DONE_LANGUAGE_CREATE_OR_UPDATE', payload: employeeLanguagesData });
        return employeeLanguagesData;
    };
    const deleteLanguage = async (confirm) => {
        if (!confirm || !deleteLanguageAlert.id)
            return setDeleteLanguageAlert({ showAlert: false, id: null });
        setDeleteLanguageAlert({ ...deleteLanguageAlert, showAlert: false });
        dispatch({ type: 'SEND_DELETE_LANGUAGE' });
        const employee = await index_1.EmployeesApi.deleteLanguage(props.id, deleteLanguageAlert.id);
        dispatch({ type: 'DONE_DELETE_LANGUAGE', payload: { errors: employee.errors } });
        setDeleteLanguageAlert({ showAlert: false, id: null });
        if (props.id)
            getLanguages(props.id).then();
    };
    const highlightLanguage = (languageId) => {
        dispatch({ type: 'HIGHLIGHT_LANGUAGE', payload: { languageId } });
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getLanguages(props.id).then(); }, [props.id]);
    return {
        addLanguage,
        closeErrorMessage,
        state,
        deleteLanguageAlert,
        setDeleteLanguageAlert,
        deleteLanguage,
        highlightLanguage
    };
};
exports.default = useLanguage;
