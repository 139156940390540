"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.languageReducer = exports.useTrainingReducer = exports.initialTrainingState = exports.initialTrainingFormValue = void 0;
const react_1 = require("react");
const types_1 = require("api/references/employees/types");
const parsers_1 = require("api/files/parsers");
exports.initialTrainingFormValue = {
    id: '',
    withFunding: types_1.Funding.YES,
    name: '',
    dateFrom: '',
    dateTo: null,
    trainingHoursSelected: false,
    hour: null,
    trainingCreditsSelected: false,
    credit: null,
    files: [parsers_1.initialFile]
};
const initialTrainingValue = {
    trainings: [],
    training: exports.initialTrainingFormValue
};
exports.initialTrainingState = {
    data: initialTrainingValue,
    status: null,
    errors: null,
    isFetching: true,
    isLoading: false,
    isCreatingOrUpdatingTraining: false,
    deletingTraining: false
};
const useTrainingReducer = () => {
    return (0, react_1.useReducer)(exports.languageReducer, exports.initialTrainingState);
};
exports.useTrainingReducer = useTrainingReducer;
const languageReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_TRAININGS_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_TRAININGS_REQUEST':
            return {
                ...state,
                data: {
                    ...state.data,
                    trainings: action.payload.data
                },
                errors: action.payload.errors,
                status: action.payload.status,
                isFetching: false,
                isLoading: state.isCreatingOrUpdatingTraining
            };
        case 'SEND_TRAINING_CREATE_OR_UPDATE':
            return {
                ...state,
                errors: null,
                isCreatingOrUpdatingTraining: true,
                isLoading: true
            };
        case 'DONE_TRAINING_CREATE_OR_UPDATE':
            return {
                ...state,
                data: {
                    ...state.data,
                    trainings: action.payload.data || state.data.trainings
                },
                errors: action.payload.errors,
                status: action.payload.status,
                isCreatingOrUpdatingTraining: false,
                isLoading: state.isFetching
            };
        case 'SEND_DELETE_TRAINING':
            return {
                ...state,
                deletingTraining: true
            };
        case 'DONE_DELETE_TRAINING':
            return {
                ...state,
                deletingTraining: false,
                errors: action.payload.errors
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.languageReducer = languageReducer;
