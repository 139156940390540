"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseDocumentLockIn = void 0;
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseDocumentLockIn = (response) => {
    if (response.content === null && response.status !== apiGlobalTypes_1.ResponseStatuses.CONFLICT) {
        return { data: null, errors: response.errors, status: response.status };
    }
    try {
        if (response.status === apiGlobalTypes_1.ResponseStatuses.CONFLICT) {
            return {
                data: {
                    status: false,
                    message: (response.errors && response.errors[0]?.message) || ''
                },
                status: response.status,
                errors: null
            };
        }
        const lockIn = response.content;
        return {
            data: {
                message: 'მიმდინარე დოკუმენტს არედაქტირებთ თქვენ',
                status: true,
                userId: lockIn.data.relationships.user.data.id,
                fullname: lockIn.data.relationships.user.data.attributes.fullname,
                lockInTime: (0, parsers_1.transformDate)(lockIn.data.attributes.lockInTime, 'dd-MM-yyyy'),
                lockOutTime: (0, parsers_1.transformDate)(lockIn.data.attributes.lockOutTime, 'dd-MM-yyyy'),
                timeDistance: (0, parsers_1.gerDistanceInMilliseconds)(lockIn.data.attributes.lockInTime, lockIn.data.attributes.lockOutTime)
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseDocumentLockIn = parseDocumentLockIn;
