"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseProcurementTypes = exports.parseProcurementTypeData = exports.parseProcurementTypesForSelect = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseProcurementTypesForSelect = (response) => {
    if (response.content === null)
        return [];
    const procurementTypes = response.content;
    try {
        return procurementTypes.data.map(el => ({ id: el.id, label: el.attributes.name })) || [];
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return [];
    }
};
exports.parseProcurementTypesForSelect = parseProcurementTypesForSelect;
const parseProcurementTypeData = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const procurementType = response.content;
    try {
        return {
            data: {
                electronicTender: procurementType.data.attributes.electronicTender,
                name: procurementType.data.attributes.name,
                type: procurementType.data.attributes.type,
                simplifiedProcurement: procurementType.data.attributes.simplifiedProcurement
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseProcurementTypeData = parseProcurementTypeData;
const parseProcurementTypes = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors };
    const procurementTypes = response.content;
    try {
        return {
            data: procurementTypes.data.map(el => {
                return {
                    id: el.id,
                    moduleType: el.type,
                    simplifiedProcurement: el.attributes.simplifiedProcurement,
                    name: el.attributes.name,
                    electronicTender: el.attributes.electronicTender,
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    createdDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt),
                    updatedDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.updatedAt)
                };
            }),
            errors: null,
            meta: (0, parsers_1.transformPagination)(procurementTypes.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseProcurementTypes = parseProcurementTypes;
