"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.submissionVerifyReducer = exports.useSubmissionVerifyReducer = exports.initialVerifySubmissionNavigate = exports.initialSubmissionVerifyState = exports.initialSubmissionVerifyValue = void 0;
const react_1 = require("react");
const types_1 = require("api/documents/purchases/submissionVerifies/types");
exports.initialSubmissionVerifyValue = {
    comment: '',
    modify: false,
    number: '',
    actionType: types_1.VerifyActionType.PURCHASES,
    date: '',
    status: types_1.VerifySubmissionStatus.CHECKING_PROCESS,
    executionDate: '',
    expirationDate: '',
    alert: false,
    employee: null,
    structuralUnit: null,
    createdBy: '',
    files: [],
    // verifySubmissionItems: [],
    subVisualStatus: null,
    visualStatus: null,
    hasForceMajeureComment: false
};
exports.initialSubmissionVerifyState = {
    data: exports.initialSubmissionVerifyValue,
    status: null,
    errors: null,
    isFetching: true,
    isUpdating: false,
    isLoading: false,
    isCreating: false,
    isDeclining: false,
    isRequestingChange: false
};
exports.initialVerifySubmissionNavigate = {
    next: false,
    previous: false,
    isLoading: false,
    lastElement: false,
    firstElement: false
};
const useSubmissionVerifyReducer = () => {
    return (0, react_1.useReducer)(exports.submissionVerifyReducer, exports.initialSubmissionVerifyState);
};
exports.useSubmissionVerifyReducer = useSubmissionVerifyReducer;
const submissionVerifyReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_SUBMISSION_VERIFY_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_SUBMISSION_VERIFY_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isFetching: false,
                isLoading: state.isCreating
                    || state.isUpdating
                    || state.isDeclining
                    || state.isRequestingChange
            };
        case 'SEND_SUBMISSION_VERIFY_DECLINE':
            return {
                ...state,
                errors: null,
                isDeclining: true,
                isLoading: true
            };
        case 'DONE_SUBMISSION_VERIFY_DECLINE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isDeclining: false,
                isLoading: state.isCreating
                    || state.isUpdating
                    || state.isFetching
                    || state.isRequestingChange
            };
        case 'SEND_SUBMISSION_VERIFY_REQUEST_CHANGE':
            return {
                ...state,
                isRequestingChange: true,
                isLoading: true
            };
        case 'DONE_SUBMISSION_VERIFY_REQUEST_CHANGE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isRequestingChange: false,
                isLoading: state.isCreating
                    || state.isFetching
                    || state.isDeclining
                    || state.isUpdating
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.submissionVerifyReducer = submissionVerifyReducer;
