"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDimensionUnitsForSelect = exports.updateDimensionUnit = exports.createDimensionUnit = exports.getDimensionUnit = exports.deleteItem = exports.getDimensionUnits = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("../../privateRequest"));
const parsers_1 = require("./parsers");
const parsers_2 = require("api/references/dimensionUnits/parsers");
const getDimensionUnits = async (params) => {
    const response = await Api.get('references/dimension-units', params);
    return (0, parsers_1.parseDimensionUnits)(response);
};
exports.getDimensionUnits = getDimensionUnits;
const deleteItem = async (id) => {
    return await Api.deleteItem(`references/dimension-units/${id}`);
};
exports.deleteItem = deleteItem;
const getDimensionUnit = async (id) => {
    const response = await Api.get('references/dimension-units/' + id);
    return (0, parsers_1.parseDimensionUnit)(response);
};
exports.getDimensionUnit = getDimensionUnit;
const createDimensionUnit = async (params) => {
    const response = await Api.post('references/dimension-units', params);
    return (0, parsers_1.parseDimensionUnit)(response);
};
exports.createDimensionUnit = createDimensionUnit;
const updateDimensionUnit = async (id, params) => {
    const response = await Api.patch('references/dimension-units/' + id, params);
    return (0, parsers_1.parseDimensionUnit)(response);
};
exports.updateDimensionUnit = updateDimensionUnit;
const getDimensionUnitsForSelect = async (params) => {
    const response = await Api.get('references/dimension-units', params);
    return (0, parsers_2.parseDimensionUnitsForSelect)(response);
};
exports.getDimensionUnitsForSelect = getDimensionUnitsForSelect;
