"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseBudgetSourcesForSelect = exports.parseBudgetSource = exports.parseBudgetSources = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseBudgetSources = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const budgetSources = response.content;
    try {
        return {
            data: budgetSources.data.map(el => {
                return {
                    id: el.id,
                    moduleType: el.type,
                    budgetingCheck: el.attributes.budgetingCheck,
                    fundingSource: el.relationships.fundingSource?.data.attributes.name || null,
                    name: el.attributes.name,
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    createdDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt),
                    updatedDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.updatedAt)
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(budgetSources.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseBudgetSources = parseBudgetSources;
const parseBudgetSource = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const budgetSource = response.content;
    try {
        return {
            data: {
                id: budgetSource.data.id,
                fundingSource: budgetSource.data.relationships.fundingSource && {
                    id: budgetSource.data.relationships.fundingSource.data.id,
                    label: budgetSource.data.relationships.fundingSource.data.attributes.name
                },
                name: budgetSource.data.attributes.name,
                structuralUnit: budgetSource.data.relationships.structuralUnit ? {
                    id: budgetSource.data.relationships.structuralUnit.data.id,
                    label: budgetSource.data.relationships.structuralUnit.data.attributes.fullName
                } : null
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseBudgetSource = parseBudgetSource;
const parseBudgetSourcesForSelect = (response) => {
    if (response.content === null)
        return [];
    const budgetSources = response.content;
    try {
        return budgetSources.data.map(el => {
            return {
                id: el.id,
                label: el.attributes.name
            };
        });
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return [];
    }
};
exports.parseBudgetSourcesForSelect = parseBudgetSourcesForSelect;
