"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useAccountStructuralUnit = (props) => {
    const [accounts, setAccounts] = (0, react_1.useState)([]);
    const [activeAccount, setActiveAccount] = (0, react_1.useState)(null);
    const changeActiveAccount = async (el) => {
        setAccounts(accounts.map(account => ({ ...account, active: account.id === el.id })));
        setActiveAccount(el);
        const structuralUnitArr = el.id ? JSON.stringify(el.id) : '';
        localStorage.setItem('activeStructuralUnitId', structuralUnitArr);
    };
    (0, react_1.useEffect)(() => {
        setAccounts(props.user.accounts);
        setActiveAccount(props.user.accounts.find(account => account.active) || null);
    }, [props.user.id]);
    return {
        accounts,
        activeAccount,
        changeActiveAccount,
        setAccounts
    };
};
exports.default = useAccountStructuralUnit;
